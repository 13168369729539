import { ePrefix } from './configs';
// all functions in this class are treaded as Immediately Invoked Functions
// the index within each function acts similar to a static variable only within the specific function

const scenarioSeq = (() => {
  let index = 1;
  return (getPrevious, tabNavs) => {
    if (tabNavs) {
      // Iterate through tabNavs i.e. [{id: Scenario.1},{id: Scenario.2}]
      // and get the largest tabNavIndex, if index is less than it, set index to tabNavIndex.
      // This is to protect again index being reset to one causing duplicate scenarios on browser refreshes
      const tabNavSet = new Set();
      for (const tab of tabNavs) {
        tabNavSet.add(Number(tab.id.slice(-1)));
      }
      // get the largest scenario id and increment it to ensure their isn't multiple,
      // scenarios pointing to the same scenario id
      const largestTabNavIndex = Math.max.apply(this, [...tabNavSet]) + 1;
      index = index < largestTabNavIndex ? largestTabNavIndex : index;
    }
    return `${ePrefix.scenario}.${getPrevious ? index - 1 : index++}`;
  };
})();

const coverageSeq = (() => {
  let index = 1;
  return (getPrevious, inforce) => {
    if (inforce) {
      index = 1;
    }
    return `${ePrefix.coverage}.${getPrevious ? index - 1 : index++}`;
  };
})();

const riderSeq = (() => {
  let index = 1;
  return (getPrevious, inforce) => {
    if (inforce) {
      index = 1;
    }
    return `${ePrefix.rider}.${getPrevious ? index - 1 : index++}`;
  };
})();

const ownerSeq = (() => {
  let index = 1;
  return (getPrevious) => `${ePrefix.owner}.${getPrevious ? index - 1 : index++}`;
})();

const payorSeq = (() => {
  let index = 1;
  return (getPrevious) => `${ePrefix.payor}.${getPrevious ? index - 1 : index++}`;
})();

const advisorSeq = (() => {
  let index = 1;
  return (getPrevious) => `${ePrefix.advisor}.${getPrevious ? index - 1 : index++}`;
})();

const clientSeq = (() => {
  let index = 1;
  return (getPrevious, inforce) => {
    if (inforce) {
      index = 1;
    }
    return `${ePrefix.client}.${getPrevious ? index - 1 : index++}`;
  };
})();

const childSeq = (() => {
  let index = 1;
  return (getPrevious) => `${ePrefix.child}.${getPrevious ? index - 1 : index++}`;
})();

const vitalityStatusSeq = (() => {
  let index = 1;
  return (getPrevious) => `${ePrefix.vitality}.${getPrevious ? index - 1 : index++}`;
})();

export { scenarioSeq, coverageSeq, riderSeq, ownerSeq, payorSeq, advisorSeq, clientSeq, childSeq, vitalityStatusSeq };
