/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { dropDownOptions } from '../../dropdown-config';
import SpecialQuote from '../special-quote';
import {
  eField,
  eLocale,
  coverageConfig,
  eUserType,
  ePremiumDuration,
  eDefault,
  eJuvenileAgeLimit,
  eCoverageType,
  eSpecialQuote,
  eProduct,
} from '../../../core/configs';
import MWIDropdown from '../../components/mwi-dropdown';
import MWINumberField from '../../components/mwi-calculated-number-field';
import PremiumFrequency from '../../../core/components/premium-frequency';
import { CoverageSettings } from '../../../custom-mode/coverage/coverage-settings';

const WholeLifeCoveragePropType = {
  locale: PropTypes.string,
  product: PropTypes.string,
  intl: PropTypes.object,
  coverageTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired
  ),
  toggleOutOfDate: PropTypes.func,
  coverage: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  userType: PropTypes.string,
  effectiveDate: PropTypes.string,
  illustrationDate: PropTypes.string,
  inforce: PropTypes.bool,
  premiumDuration: PropTypes.string,
};

const specialQuoteOptionPARFilter = (premiumDuration, specialQuoteOptionPAR, specialQuoteNoneLabel, intl) => {
  let specialQuoteOptions;
  if (premiumDuration === ePremiumDuration.Pay90) {
    specialQuoteOptions = specialQuoteOptionPAR.filter(function (el) {
      return (
        el.label === intl.formatMessage({ id: 'specialQuote.opt.S1' }) ||
        el.label === intl.formatMessage({ id: specialQuoteNoneLabel })
      );
    });
  } else if (premiumDuration === ePremiumDuration.Pay100) {
    specialQuoteOptions = specialQuoteOptionPAR.filter(function (el) {
      return el.label === intl.formatMessage({ id: specialQuoteNoneLabel });
    });
  } else {
    specialQuoteOptions = specialQuoteOptionPAR;
  }
  return specialQuoteOptions;
};

const DropDowns = (props) => {
  const {
    userType,
    coverage: { coverageType, premiumDuration, specialQuoteOption, isVitalityPlusSelected, costDuration },
    product,
    coverageTypes,
    intl,
  } = props;
  const isPG = product === eProduct.PG;
  const isPar = product === eProduct.PAR;
  const isPreformax = product === eProduct.Performax;
  const isSB = product === eProduct.SB;
  const { premiumDurations, specialQuoteOptionPAR, costDurations, performaxDropDowns } = dropDownOptions(intl);
  const specialQuoteNoneLabel = 'specialQuote.opt.none';

  return (
    <div className="p-grid p-grid-no-margin-all p-col-12 mwi-margin-top18">
      <MWIDropdown
        name="coverageType"
        label={<FormattedMessage id="coverage.par.type" />}
        value={coverageType}
        options={coverageTypes}
        onChange={(e) => props.onChange(eField.covType, e.target.value, coverageType)}
        containerStyle={`space-right ${'p-col-7 p-lg-8 p-xl-6'}`}
        role="coverageType"
        disabled={isVitalityPlusSelected || props.inforce}
      />
      {isPar && (
        <MWIDropdown
          name="premiumDuration"
          label={<FormattedMessage id="coverage.premiumduration" />}
          value={premiumDuration}
          options={premiumDurations}
          onChange={(e) =>
            props.product === eProduct.PAR && props.premiumDuration === ePremiumDuration.Pay100
              ? props.onChange(eField.premiumDuration, e.target.value, 'scenario')
              : props.onChange(eField.premiumDuration, e.target.value)
          }
          containerStyle="p-col-4 p-lg-4 p-xl-3 p-sm-6 space-right"
          role="premiumDuration"
          disabled={props.inforce}
        />
      )}
      {isPG && (
        <MWIDropdown
          name="costDuration"
          label={<FormattedMessage id="coverage.costduration" />}
          value={costDuration}
          options={costDurations}
          onChange={(e) => props.onChange(eField.costDuration, e.target.value)}
          containerStyle="p-col-4 p-lg-4 p-xl-3 p-sm-6 space-right"
          role="costDuration"
          disabled={props.inforce}
        />
      )}
      {isPreformax && (
        <MWIDropdown
          data-testid="performax-plan-type-drop"
          name="planType"
          isPlaceholderAnOption={false}
          label={<FormattedMessage id="coverage.planType" />}
          placeholder={'performax plan type not listed'}
          value={costDuration}
          options={performaxDropDowns}
          onChange={(e) => props.onChange(eField.performaxPlanType, e.target.value)}
          containerStyle="p-col-4 p-lg-4 p-xl-3 p-sm-6 space-right"
          disabled
        />
      )}
      {isSB && (
        <MWIDropdown
          data-testid="securitybuilder-plan-type-drop"
          name="planType"
          isPlaceholderAnOption={false}
          label={<FormattedMessage id="coverage.planType" />}
          placeholder={'security builder plan type not listed'}
          value={costDuration}
          options={performaxDropDowns}
          onChange={(e) => props.onChange(eField.performaxPlanType, e.target.value)}
          containerStyle="p-col-4 p-lg-4 p-xl-3 p-sm-6 space-right"
          disabled
        />
      )}
      {userType === eUserType.headOffice && !props.inforce && isPar && (
        <SpecialQuote
          specialQuoteValue={
            premiumDuration === ePremiumDuration.Pay100
              ? intl.formatMessage({ id: specialQuoteNoneLabel })
              : specialQuoteOption
          }
          specialQuoteOptions={specialQuoteOptionPARFilter(
            premiumDuration,
            specialQuoteOptionPAR,
            specialQuoteNoneLabel,
            intl
          )}
          onChange={props.onChange}
          containerStyle={`p-col-4 p-lg-4 p-xl-3 space-right top-margin-24`}
          dropdownStyle=""
          isDisabled={isVitalityPlusSelected || premiumDuration === ePremiumDuration.Pay100}
          role="special-quote"
        />
      )}
    </div>
  );
};

DropDowns.propTypes = WholeLifeCoveragePropType;

export class WholeLifeCoverageContainer extends PureComponent {
  static propTypes = WholeLifeCoveragePropType;

  componentDidUpdate(prevProps) {
    // make a call to the calc service if the min client age is updated from juvenile (< 16) to non juvenile (>= 16)
    // to force the update of cov amount,the rule for calculating cov amount min value: see coverageAmountMin
    if (
      prevProps.coverage.coverageAmount < eDefault.coverageAmount &&
      prevProps.coverage.minClientAge < eJuvenileAgeLimit &&
      this.props.coverage.minClientAge >= eJuvenileAgeLimit
    ) {
      this.props.toggleOutOfDate(true);
    }
  }

  render = () => {
    const {
      locale,
      userType,
      coverage: { coverageAmount, isCoverageSolve, coverageType, premiumDuration, errors, specialQuoteOption },
      intl,
      product,
      effectiveDate,
      illustrationDate,
      inforce,
    } = this.props;
    const hasSpecialQuote = specialQuoteOption === eSpecialQuote.S1 || specialQuoteOption === eSpecialQuote.S2;
    const isJointLife = coverageType === eCoverageType.jointLastPayLastDeath;
    const isHO = userType === eUserType.headOffice;
    const isPar = product === eProduct.PAR;
    const isParInforce = isPar && inforce;
    const isPG = product === eProduct.PG;
    const isPay100 = premiumDuration === ePremiumDuration.Pay100;
    const isHoSpecialQuoteNotPay100 = hasSpecialQuote && isHO && !isPay100;
    const disableVitalityPlus = isPG || isJointLife || isHoSpecialQuoteNotPay100 || isParInforce;

    return (
      <div className="flexdisplay">
        {isPar && (
          <CoverageSettings
            intl={intl}
            product={product}
            locale={locale}
            effectiveDate={effectiveDate}
            illustrationDate={illustrationDate}
            userType={userType}
            isVitalityPlusDisable={disableVitalityPlus}
            inforce={inforce}
          />
        )}
        <DropDowns {...this.props} />
        <div className="p-grid p-grid-no-margin-all p-col-12">
          <div
            className={`space-right mwi-control-wrapper mwi-margin-top18 ${
              locale === eLocale.fr ? 'p-col-7 p-lg-6 p-sm-6' : 'p-col-6 p-lg-7 p-xl-5'
            }`}
          >
            <MWINumberField
              id={eField.coverageAmount}
              containerClass={`mwi-widget mwi-input number-data ${!errors.coverageAmount ? '' : 'mwi-error-field'}`}
              name={eField.coverageAmount}
              label={<FormattedMessage id="coverage.par.coverageAmount" />}
              onBlur={this.props.onBlur}
              value={coverageAmount || 0}
              disabled={isCoverageSolve || this.props.inforce}
              thousandSeparator={locale === eLocale.en ? ',' : ' '}
              prefix={locale === eLocale.en ? '$ ' : ''}
              suffix={locale === eLocale.fr ? ' $' : ''}
              minValue={coverageConfig.minAmount}
              maxValue={coverageConfig.maxAmount}
              locale={locale}
              role="container-mwinumberfield"
            />
          </div>
          {isPar && (
            <div className={`p-col-4 p-lg-4 p-xl-3 p-sm-6 space-right11 mwi-margin-top18`} role="premium-frequency">
              <PremiumFrequency />
            </div>
          )}
        </div>
      </div>
    );
  };
}

export const mapStateToProps = ({ app, scenarioTabNavs }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const illustrationDate = app.inforceData?.illustrationDate ? app.inforceData.illustrationDate : '';
  return {
    locale: app.locale,
    userType: app.userType,
    product: activeScenario.product,
    effectiveDate: activeScenario.effectiveDate,
    illustrationDate,
    inforce: app.inforcePolicy,
  };
};

export default connect(mapStateToProps)(injectIntl(WholeLifeCoverageContainer));
