import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { eDefault, eProduct, eRider, eLocale, eMaxVal, ePrefix, eActionEvent, eField } from '../../core/configs';
import ChildProtectionRider from './cpr';
import TDWPayorRider from './payor';
import './riders.css';
import { trackUserActions } from '../../core/utils';
import { ProductConfig } from '../product-config';
import _ from 'lodash';
import { dropDownOptions } from '../../core/dropdown-config';
import MWIDropdown from '../../core/components/mwi-dropdown';
import { FormatDropDownOfNumbers } from './riderHelper';

const getLanguageConfigs = (locale) => {
  const englishPrefix = locale === eLocale.en ? '$ ' : '';
  const frenchSuffix = locale === eLocale.fr ? ' $' : '';
  const thousandSep = locale === eLocale.en ? ',' : ' ';
  return { englishPrefix, frenchSuffix, thousandSep };
};

const isSelectedAndIsAmount = (riderClient, riderConfig) => riderClient.isSelected && riderConfig.isAmount;

const riderAmount = function (riderClient, riderConfig, riderId, clientId) {
  const { locale, product, udmEnabled, intl } = this.props;
  const { lcRiderChildrensOptions } = dropDownOptions(intl);
  const { englishPrefix, frenchSuffix, thousandSep } = getLanguageConfigs(locale);
  const checkProduct = product === eProduct.LC && eRider.clc;
  const amount =
    [eProduct.PG, eProduct.Performax, eProduct.SB, eProduct.BT].includes(product) ||
    (this.props.inforce && product === eProduct.PAR) ||
    (this.props.inforce && product === eProduct.FT)
      ? riderClient.coverageAmount
      : riderClient.amount;

  return checkProduct && isSelectedAndIsAmount(riderClient, riderConfig) ? (
    <div className="p-col-4 p-lg-4 p-xl-4 p-sm-4" tabIndex="-1">
      <MWIDropdown
        options={FormatDropDownOfNumbers(lcRiderChildrensOptions, locale)}
        containerStyle="p-col-12 p-lg-12 p-xl-12 p-sm-12"
        onChange={(e) => this.onChangeClcDropdown(e.target.value, riderId, clientId)}
        value={riderClient.amount}
        disabled={this.props.inforce}
        name={eField.lcChildLifeCheque}
        label={null}
      />
    </div>
  ) : (
    isSelectedAndIsAmount(riderClient, riderConfig) && (
      <div className="p-col-4 rider-field ratings-label rider-text-box">
        <NumberFormat
          className={`mwi-widget mwi-input number-data`}
          value={amount}
          disabled={this.props.inforce}
          prefix={englishPrefix}
          suffix={frenchSuffix}
          thousandSeparator={thousandSep}
          allowNegative={false}
          isAllowed={(values) => +values.value >= 0 && +values.value <= eMaxVal(product)[riderConfig.id]}
          onBlur={(e) => this.onBlur('amount', riderId, clientId, e.target.value)}
          aria-labelledby="riderAmount"
          autoComplete="off"
          onFocus={this.handleFocus}
        />
        {udmEnabled && <div className="udm-id">{riderClient.riderId}</div>}
      </div>
    )
  );
};

const riderRating = function (riderClient, riderId, riderConfig, clientId) {
  const { locale, product, udmEnabled } = this.props;
  const { englishPrefix, frenchSuffix, thousandSep } = getLanguageConfigs(locale);
  const maximumIsAllowed = 750;
  const isParInforce = this.props.inforce && product === eProduct.PAR;
  const isFTBTInforce = this.props.inforce && (product === eProduct.FT || product === eProduct.BT);

  return (
    riderClient.isSelected &&
    riderConfig.isRating && (
      <React.Fragment>
        {!riderConfig.isAmount && <div className="p-col-4 mwi-h25" />}
        {![eProduct.Performax, eProduct.SB].includes(product) && (
          <div className="p-col-3 rider-field ratings-label rider-text-box">
            <NumberFormat
              className={`mwi-widget mwi-input number-data`}
              value={
                [eProduct.PG, eProduct.Performax, eProduct.SB].includes(product) || isParInforce || isFTBTInforce
                  ? riderClient.permRatingPercent
                  : riderClient.rating
              }
              disabled={this.props.inforce}
              suffix={' %'}
              fixedDecimalScale
              allowNegative={false}
              isAllowed={(values) => +values.value >= 0 && +values.value <= maximumIsAllowed}
              onBlur={(e) => this.onBlur('rating', riderId, clientId, e.target.value)}
              aria-labelledby="riderRating"
              autoComplete="off"
              onFocus={this.handleFocus}
            />
          </div>
        )}
        {[eProduct.Performax, eProduct.SB].includes(product) && (
          <div className="p-col-3 rider-field ratings-label rider-text-box">
            <NumberFormat
              className={`mwi-widget mwi-input number-data`}
              value={riderClient.coverageAmount}
              disabled={this.props.inforce}
              prefix={englishPrefix}
              suffix={frenchSuffix}
              thousandSeparator={thousandSep}
              allowNegative={false}
              isAllowed={(values) => +values.value >= 0 && +values.value <= eMaxVal(product)[riderConfig.id]}
              onBlur={(e) => this.onBlur('amount', riderId, clientId, e.target.value)}
              aria-labelledby="riderAmount"
              autoComplete="off"
              onFocus={this.handleFocus}
            />
            {udmEnabled && <div className="udm-id">{riderClient.riderId}</div>}
          </div>
        )}
      </React.Fragment>
    )
  );
};

const setChildProtectionRiderUdm = function (riderId, riders, udmEnabled) {
  return riderId === eRider.cpr && riders[riderId].isSelected && <ChildProtectionRider udmEnabled={udmEnabled} />;
};

const setPayorRiderUdm = function (product, riderId, riders, udmEnabled) {
  const checkProduct = product === eProduct.PAR || product === eProduct.LC || product === eProduct.PG;

  if (checkProduct && riderId === eRider.tdwpayor && riders[riderId].isSelected) {
    return <TDWPayorRider udmEnabled={udmEnabled} riderId={riderId} />;
  }

  return <div />;
};

const toggleClientCheckbox = function (riderClient, riderId, clientId, checkboxName, udmEnabled) {
  const columnSize = riderClient.isSelected ? '5' : '12';
  return (
    <div className={`p-col-${columnSize} rider-checkbox-wrapper rider-text-box`} tabIndex="-1">
      <input
        className="rider-checkbox focusOutline"
        type="checkbox"
        id={`${riderId}-${clientId}`}
        value={clientId}
        disabled={this.props.inforce}
        onChange={(e) => this.onRiderClientToggle(e.target.value, riderId, e.target.checked)}
        checked={this.props.product === eProduct.PG ? true : riderClient.isSelected || false}
        name={checkboxName}
        aria-label={checkboxName}
        aria-describedby="ridersClients"
      />
      <label htmlFor={`${riderId}-${clientId}`}>{checkboxName}</label>
      {udmEnabled && <div className="udm-id">{riderClient.riderId}</div>}
    </div>
  );
};

// riders is a state objects
export class RiderList extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    product: PropTypes.string,
    riderList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        abbr: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }).isRequired
    ),
    udmEnabled: PropTypes.bool,
    isRiderOpened: PropTypes.bool,
    riders: PropTypes.object.isRequired,
    clients: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    appActions: PropTypes.object,
    riderActions: PropTypes.object,
    sceActions: PropTypes.object,
    lcToggleClcCheckbox: PropTypes.bool,
    intl: PropTypes.object,
  };

  static defaultProps = {
    clients: [],
  };

  constructor(props) {
    super(props);

    this.onRiderToggle = this.onRiderToggle.bind(this);
    this.onRiderClientToggle = this.onRiderClientToggle.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  //handle manual refresh request
  handleParManualRefresh() {
    if (this.props.product === eProduct.PAR) {
      this.props.sceActions.performManualRefresh(true);
    }
  }

  // checking values for non-CPR riders
  onBlur(type, riderName, clientId, value) {
    const defaultValue = eDefault.rider[type === 'rating' ? 'rating' : `${riderName}Amount`];
    let formattedValue = +value.replace(/[$,%\s]/g, '');

    if (formattedValue < defaultValue && !(this.props.product === eProduct.PAR && riderName === eRider.gio)) {
      formattedValue = defaultValue;
    }

    this.props.appActions.dispatchMWIOnChange({
      target: 'rider',
      field: type,
      value: formattedValue,
      riderName,
      clientId,
    });

    this.props.sceActions.toggleOutOfDate(true);
    this.handleParManualRefresh();
  }

  onRiderToggle(product, e) {
    const { value, checked } = e.target;
    trackUserActions(eActionEvent.selection, value, checked);
    this.props.riderActions.toggleRider({
      riderName: value,
      isSelected: checked,
      allClients: this.props.clients.allClients,
      product,
    });

    const checkRider = value === eRider.cpr || value === eRider.tdwpayor;
    if (checkRider || this.props.clients.allClients.length === 1 || !checked) {
      this.props.sceActions.toggleOutOfDate(true);
    }
    this.handleParManualRefresh();
  }

  onRiderClientToggle(clientId, riderName, checked) {
    if (this.props.clients.allClients.length !== 1) {
      this.props.riderActions.toggleRiderClient({
        riderName,
        clientId,
        isSelected: checked,
      });

      this.props.sceActions.toggleOutOfDate(true);
      this.handleParManualRefresh();
    }
  }

  onChangeClcDropdown(value, riderName, clientId) {
    this.props.appActions.dispatchMWIOnChange({
      target: 'rider',
      field: 'amount',
      value,
      riderName,
      clientId,
    });

    this.props.sceActions.toggleOutOfDate(true);
    this.handleManualRefresh();
  }

  //Select the number format value once get focused
  handleFocus = (event) => event.target.select();

  render() {
    const { product, riders, clients, udmEnabled, riderList, lcToggleClcCheckbox, intl } = this.props;
    return ProductConfig[product].riderSections.riderList.map((riderConfig) => {
      const allClients = clients.allClients;
      const riderId = riderConfig.id;
      let checkboxName =
        product === eProduct.LC && riderId === eRider.tdwpayor
          ? intl.formatMessage({ id: 'lc.rider.prw' })
          : _.find(riderList, { id: riderId }).label;

      let index = 0;
      return (
        <React.Fragment key={riderId}>
          <div className={`p-grid p-grid-no-margin rider ${riderId}`}>
            <div
              className={`${
                riderId !== eRider.cpr && riderId !== eRider.tdwpayor ? 'p-col-5' : 'p-col-12'
              } rider-checkbox-wrapper`}
              tabIndex="-1"
            >
              <input
                className="rider-checkbox focusOutline"
                type="checkbox"
                id={`rcb-${riderId}`}
                value={riderId}
                onChange={(event) => this.onRiderToggle(product, event)}
                checked={riders[riderId].isSelected}
                name={checkboxName}
                aria-label={checkboxName}
                aria-describedby="ridersHeader"
                disabled={(riderId === eRider.clc && !lcToggleClcCheckbox) || this.props.inforce}
              />
              <label htmlFor={`rcb-${riderId}`}>{checkboxName}</label>
            </div>
            {riders[riderId].isSelected && (
              <div className="p-col-7 select-client">
                {riderId !== eRider.cpr &&
                  riderId !== eRider.tdwpayor &&
                  riderId !== eRider.pwdd &&
                  allClients.map((clientId) => {
                    const baseClient = clients[clientId];
                    // associated client with a riderConfig. It has own ratings like amount and percent
                    const riderClient = riders[riderId][clientId];
                    ++index;
                    checkboxName = baseClient.fullName || `${ePrefix.client} ${index}`;
                    return (
                      riderClient && (
                        <React.Fragment key={clientId}>
                          <div className="p-grid p-grid-no-margin-all p-col-12">
                            {toggleClientCheckbox.call(
                              this,
                              riderClient,
                              riderId,
                              clientId,
                              checkboxName,
                              udmEnabled,
                              product
                            )}
                            {riderAmount.call(this, riderClient, riderConfig, riderId, clientId)}
                            {riderRating.call(this, riderClient, riderId, riderConfig, clientId)}
                          </div>
                        </React.Fragment>
                      )
                    );
                  })}
              </div>
            )}
          </div>

          {setChildProtectionRiderUdm(riderId, riders, udmEnabled)}
          {setPayorRiderUdm(product, riderId, riders, udmEnabled)}
        </React.Fragment>
      );
    });
  }
}

export default RiderList;
