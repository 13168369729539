import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { TabView, TabPanel } from 'primereact/components/tabview/TabView';
import { requestValidation } from '../../core/udm-mapper/sideEffect';
import { configs } from '../../core/configs';
import JSONPretty from 'react-json-pretty';
import * as ScenarioActions from '../../custom-mode/scenario-tab-navs/actions';
import UDMGenerator from './udmGenerator';
import _ from 'lodash';
import './udm-mapper.css';

export class UDMMapper extends PureComponent {
  static propTypes = {
    app: PropTypes.object,
    scenarioTabId: PropTypes.string,
    udmResponse: PropTypes.object,
    udmEnabled: PropTypes.bool,
    isOutOfDate: PropTypes.bool,
    isForceUpdate: PropTypes.bool,
    policyData: PropTypes.object,
    clients: PropTypes.object,
    coverages: PropTypes.object,
    riders: PropTypes.object,
    vitalityStatus: PropTypes.array,
    report: PropTypes.object,
    advisorProfile: PropTypes.object,
    withdrawal: PropTypes.object,
    concepts: PropTypes.object,
    manualRefresh: PropTypes.bool,
    sceActions: PropTypes.object,
    deposits: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.udm = {};
  }

  componentDidUpdate() {
    if (this.props.udmEnabled) {
      this.stateWatcher();
    }
  }

  setUDM = () => {
    // to show raw UDM JSON
    if (this.props.udmEnabled && this.props.isOutOfDate) {
      const udmGenerator = new UDMGenerator();
      this.udm = udmGenerator.setProps(this.props).getUDM();
    }
  };

  stateWatcher = () => {
    if (this.props.isOutOfDate && this.props.isForceUpdate) {
      this.props.sceActions.toggleOutOfDate(false);
      this.props.sceActions.toggleForceUpdate(false);

      // TODO if there is an error then don't call API
      //    set another global flag so that if there is an error set it to true before actions
      //    and onChange or onBlur check again if no error then set it to false
      const requestUrl = this.props.manualRefresh ? configs.endPoints.summary : configs.endPoints.validation;
      this.props.sceActions.loadingUDMResponse();
      requestValidation(this.udm, requestUrl)
        .then((response) => {
          this.props.sceActions.loadedUDMResponse({ response, scenarioTabId: this.props.scenarioTabId });
        })
        .catch((errResponse) => {
          this.props.sceActions.loadedUDMResponse({ response: errResponse, scenarioTabId: this.props.scenarioTabId });
        });
    }
  };

  render() {
    this.setUDM();
    return (
      <div>
        <TabView>
          <TabPanel header="Request">
            <ReactJson
              src={this.udm}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
              theme="monokai"
              iconStyle="circle"
              collapsed={false}
            />
          </TabPanel>
          <TabPanel header="Response">
            <ReactJson
              src={this.props.udmResponse}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
              theme="solarized"
              iconStyle="circle"
              collapsed={false}
            />
          </TabPanel>
          <TabPanel header="Raw UDM">
            <div className="udm-tab">
              <JSONPretty id="json-pretty" json={this.udm} />
            </div>
          </TabPanel>
        </TabView>
      </div>
    );
  }
}

export const mapStateToProps = ({
  app,
  udmResponse,
  scenarioTabNavs,
  deposits,
  coverageTabNavs,
  conceptTabNavs,
  clients,
  riders,
  termRiders,
  vitalityStatus,
  report,
  advisorProfile,
  withdrawals,
}) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const coverages = coverageTabNavs[scenarioTabId];
  const activewithdrawals = withdrawals[scenarioTabId];
  const concepts = conceptTabNavs[scenarioTabId];
  // TODO clients, riders and vitalityStatus should be taken by curScenario

  // for the UDM viewer, show UDM illustration (true response whether there is an error or not)
  const response = _.cloneDeep(udmResponse);
  response.illustration = response.udmIllustration;
  delete response.udmIllustration;

  return {
    app,
    advisorProfile,
    concepts,
    scenarioTabId,
    coverages,
    deposits,
    udmResponse: response,
    isOutOfDate: scenarioTabNavs.isOutOfDate,
    manualRefresh: scenarioTabNavs.manualRefresh,
    isForceUpdate: scenarioTabNavs.isForceUpdate,
    udmEnabled: scenarioTabNavs.udmEnabled,
    policyData: scenarioTabNavs.topBars[scenarioTabId],
    clients: clients[scenarioTabId],
    riders: riders[scenarioTabId],
    termRiders: termRiders[scenarioTabId],
    vitalityStatus: vitalityStatus[scenarioTabId],
    report: report[scenarioTabNavs.activeTabId],
    withdrawal: activewithdrawals,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // udmActions: bindActionCreators(UDMActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UDMMapper));
