import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  openSaveCase,
  saveOpenCaseName,
  dispatchMWIUpdateError,
  dispatchMWIOnChange,
  showRatesNotification,
  saveCaseError,
  saveCaseStarted,
  saveCaseFinished,
  showStatusDialog,
} from '../../../core/actions';
import { configs, rateChangeProducts, eProduct } from '../../../core/configs';
import { openSavedCase, createCase } from '../../../core/udm-mapper/sideEffect';
import ReactModal from 'react-modal';
import { withRouter } from 'react-router-dom';
import { performManualRefresh, requestUDMResponse } from '../../../custom-mode/scenario-tab-navs/actions';
import moment from 'moment';
import { checkToShowRatesNotification } from '../../../helper/rates-notification-helper';

export class ShareLink extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    partyId: PropTypes.string,
    userName: PropTypes.string,
    userID: PropTypes.string,
    history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    currentGuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    match: PropTypes.object,
    role: PropTypes.string,
    userType: PropTypes.string,
    advisorProfile: PropTypes.object,
    performManualRefreshSceAction: PropTypes.func,
    requestUDMResponseSceAction: PropTypes.func,
    openSaveCaseAppAction: PropTypes.func,
    saveOpenCaseNameAppAction: PropTypes.func,
    dispatchMWIUpdateErrorAppAction: PropTypes.func,
    dispatchMWIOnChangeAppAction: PropTypes.func,
    showRatesNotificationAppAction: PropTypes.func,
    showStatusDialogAppAction: PropTypes.func,
    saveCaseStartedAppAction: PropTypes.func,
    saveCaseFinishedAppAction: PropTypes.func,
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCaseNotFound: false,
      isShareCaseLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.role !== '' && !this.state.isShareCaseLoading) {
      this.fetchData();
    } else {
      this.dispatchAction('isRequesting', true, 'app');
    }
  }

  componentDidUpdate() {
    if (this.props.role !== '' && !this.state.isShareCaseLoading) {
      this.fetchData();
    }
  }

  fetchData() {
    this.setState({ isShareCaseLoading: true });
    const guid = this.props.match.params.guid;
    this.openCase(guid);
    if (process.env.NODE_ENV !== 'test') {
      ReactModal.setAppElement('#mwi-root');
    }
  }

  dispatchRatesNotification(boolShowRatesNotification, isMUL) {
    const { intl } = this.props;
    if (boolShowRatesNotification && isMUL) {
      this.dispatchNotification('showStatusDialog', null, intl.formatMessage({ id: 'common.rateChange' }));
    } else {
      if (boolShowRatesNotification) {
        this.dispatchNotification('showRatesNotification');
      }
    }
  }

  checkToShowRatesNotification(loadedCase) {
    // Determine if rate change content should be displayed or not
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const lastOpenedCaseAt = new Date(loadedCase.updatedAt).setHours(0, 0, 0, 0);
    let boolShowRatesNotification = false;
    let isMUL = false;

    loadedCase.model.scenarioTabNavs.tabNavs.forEach((s) => {
      const scenario = loadedCase.model.scenarioTabNavs.topBars[s.id];
      if (!boolShowRatesNotification && rateChangeProducts[scenario.product]) {
        const lastRateChange = this.formatDate(rateChangeProducts[scenario.product]);
        const effectiveDate = this.formatDate(scenario.effectiveDate);
        if (
          lastRateChange <= currentDate &&
          (lastOpenedCaseAt < lastRateChange ||
            (this.props.userType === 'HeadOffice' && effectiveDate < lastRateChange))
        ) {
          boolShowRatesNotification = true;
          isMUL = scenario.product === eProduct.MUL;
        }
      }
    });

    this.dispatchRatesNotification(boolShowRatesNotification, isMUL);
  }

  updateModel(model) {
    //update effective date in model to be current date for non-HO userType
    if (this.props.userType !== 'HeadOffice') {
      model.scenarioTabNavs.tabNavs.forEach((s) => {
        model.scenarioTabNavs.topBars[s.id].effectiveDate = moment().format('DD/MM/YYYY');
      });
    }
    return model;
  }

  openCase(guid) {
    openSavedCase(guid)
      .then((response) => {
        const loadedCase = response.items[0];
        if (loadedCase === undefined || !loadedCase.isSnapshot) {
          this.setState({ isCaseNotFound: true });
        } else {
          loadedCase.model = this.updateModel(loadedCase.model);
          const savedSharedCase = this.saveSharedCase(loadedCase);
          const model = savedSharedCase.model;
          this.props.openSaveCaseAppAction({ model });
          this.props.saveOpenCaseNameAppAction({ name: savedSharedCase.name, autoSaveCaseLoaded: false });
          this.props.performManualRefreshSceAction();
          this.props.requestUDMResponseSceAction();
          checkToShowRatesNotification(savedSharedCase, this.props);
          this.gotoIllustrations();
        }
      })
      .catch((errResponse) => {
        this.dispatchAction('errorMessage', errResponse, 'app', true);
      })
      .finally(() => {
        this.dispatchAction('isRequesting', false, 'app');
      });
  }

  saveSharedCase(sharedCase) {
    this.props.saveCaseStartedAppAction();
    const englishSuffix = ' - Shared';
    const frenchSuffix = ' - Partagé';
    const sharedSuffix = this.props.locale === 'en' ? englishSuffix : frenchSuffix;
    const maxLength = 60;
    const sharedCaseName =
      sharedCase.name.length === maxLength
        ? sharedCase.name.substring(0, sharedCase.name.length - sharedSuffix.length)
        : sharedCase.name;

    //set profiles of logged in user for any shared case
    sharedCase.model.advisorProfile = this.props.advisorProfile;

    const caseData = {
      name: sharedCaseName.concat(sharedSuffix),
      autoSave: false,
      model: sharedCase.model,
      udm: sharedCase.udm,
      product: sharedCase.product,
      createdForPartyId: this.props.partyId,
      createdForName: this.props.userName,
    };
    createCase(caseData).then((response) => {
      if (response === undefined || response.items === undefined || response.isError) {
        this.props.openSaveCaseAppAction(response);
        return;
      }
      this.props.saveCaseFinishedAppAction();
      this.dispatchAction('guid', response.items[0].guid, 'app');
    });
    return caseData;
  }

  dispatchAction(field, value, target = 'sharelink', isError = false) {
    if (isError) {
      this.props.dispatchMWIUpdateErrorAppAction({
        target,
        field,
        value,
      });
    } else {
      this.props.dispatchMWIOnChangeAppAction({
        target,
        field,
        value,
      });
    }
  }

  dispatchNotification(type, field, value) {
    if (type === 'showRatesNotification') {
      this.props.showRatesNotificationAppAction({
        field,
        value,
      });
    } else {
      if (type === 'showStatusDialog') {
        this.props.showStatusDialogAppAction({
          field,
          value,
        });
      }
    }
  }

  formatDate = (date) => {
    const yearIndex = 2,
      monthIndex = 1,
      dayIndex = 0;
    const splitDate = date.split('/');
    return new Date(splitDate[yearIndex], splitDate[monthIndex] - 1, splitDate[dayIndex]).setHours(0, 0, 0, 0);
  };

  gotoIllustrations() {
    this.props.history.push(configs.routes.clientCoverage);
  }
  render() {
    const isCaseNotFound = this.state.isCaseNotFound;

    if (isCaseNotFound) {
      return (
        <div className="content-wrapper">
          <span id="testNode" role="testNode">
            <h1>404 Page Not Found</h1>
          </span>
        </div>
      );
    } else {
      return <span />;
    }
  }
}

export const mapStateToProps = ({ app, advisorProfile }) => {
  return {
    advisorProfile,
    partyId: app.partyId,
    userName: app.userName,
    currentGuid: app.guid,
    userID: app.userID,
    role: app.role,
    userType: app.userType,
    locale: app.locale,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openSaveCaseAppAction: bindActionCreators(openSaveCase, dispatch),
  saveOpenCaseNameAppAction: bindActionCreators(saveOpenCaseName, dispatch),
  dispatchMWIUpdateErrorAppAction: bindActionCreators(dispatchMWIUpdateError, dispatch),
  dispatchMWIOnChangeAppAction: bindActionCreators(dispatchMWIOnChange, dispatch),
  showRatesNotificationAppAction: bindActionCreators(showRatesNotification, dispatch),
  performManualRefreshSceAction: bindActionCreators(performManualRefresh, dispatch),
  requestUDMResponseSceAction: bindActionCreators(requestUDMResponse, dispatch),
  saveCaseErrorAppAction: bindActionCreators(saveCaseError, dispatch),
  saveCaseStartedAppAction: bindActionCreators(saveCaseStarted, dispatch),
  saveCaseFinishedAppAction: bindActionCreators(saveCaseFinished, dispatch),
  showStatusDialogAppAction: bindActionCreators(showStatusDialog, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShareLink)));
