import React, { PureComponent } from 'react';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import '../landing-page.css';
import PropTypes from 'prop-types';

export class TermInforceDescription extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    locale: PropTypes.string,
  };
  render() {
    const productBullets = Array.from({ length: 2 }, (v, i) => i).map((index) => (
      <li key={index}>
        <FormattedMessage id={`landing.ftbtinforce.productBullet${index}`} />
      </li>
    ));
    const toolBullets = Array.from({ length: 7 }, (v, i) => i).map((index) => (
      <li key={index}>
        <FormattedHTMLMessage id={`landing.ftbtinforce.toolBullet${index}`} />
      </li>
    ));
    return (
      <React.Fragment>
        <div className="product-description margin-bottom10">
          <FormattedHTMLMessage id="landing.ftbtinforce.productDescription1" />
          <ul className="details-list">{productBullets}</ul>
        </div>
        <div className="product-description margin-bottom10">
          <FormattedHTMLMessage id="landing.ftbtinforce.productDescription2" />
          <ul className="details-list">{toolBullets}</ul>
        </div>
        <div className="product-description margin-bottom10">
          <FormattedMessage
            id="landing.ftbtinforce.productDescription3"
            defaultMessage={`{part1}{mailTo}{part3}`}
            values={{
              part1: <FormattedHTMLMessage id="landing.ftbtinforce.productDescription3A" />,
              mailTo: (
                <a
                  href={this.props.intl.formatMessage({ id: 'utilnav.contactus.prod.link' })}
                  target={'_blank'}
                  rel="noopener noreferrer"
                  className="url-link"
                >
                  <FormattedHTMLMessage id="landing.ftbtinforce.productDescription3B" />
                </a>
              ),
              part3: <FormattedHTMLMessage id="landing.ftbtinforce.productDescription3C" />,
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(TermInforceDescription);
