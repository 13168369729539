import React from 'react';
import MWINumberField from '../../core/components/mwi-calculated-number-field';
import {
  PerformaxPlanTypeKeys,
  eField,
  eLocale,
  ePerformaxDividendOption,
  eProduct,
  performaxCoverageConfig,
} from '../../core/configs';
import { FormattedMessage } from 'react-intl';
import MWIDropdown from '../../core/components/mwi-dropdown';
import { MINIMUM_TO_FACE_AMOUNT } from '../../core/constants';

const classNameDividendEn = 'p-col-6 p-lg-7 p-xl-6 p-sm-9';

export const createPerformaxDividends = (props, onBlur) => {
  const {
    product,
    locale,
    coverage: { dividendOption, coverageAmount, costDuration, toFaceAmount, topFaceAmount },
    intl,
  } = props;

  const shouldRender =
    (product === eProduct.Performax || product === eProduct.SB) &&
    costDuration !== PerformaxPlanTypeKeys.SecurityBuilder;

  return {
    ToFaceAmount: () => {
      return (
        shouldRender &&
        dividendOption === ePerformaxDividendOption.termOption && (
          <MWINumberField
            id={eField.toFaceAmount}
            containerClass={`mwi-widget mwi-input number-data`}
            styleClass="p-col-2"
            role="container-mwinumberfield"
            name={eField.toFaceAmount}
            label={<FormattedMessage id="coverage.toFaceAmount" />}
            value={toFaceAmount}
            thousandSeparator={locale === eLocale.en ? ',' : ' '}
            prefix={locale === eLocale.en ? '$ ' : ''}
            suffix={locale === eLocale.fr ? ' $' : ''}
            locale={locale}
            decimalScale={2}
            fixedDecimalScale
            decimalSeparator={locale === eLocale.en ? '.' : ','}
            onBlur={(id, value) => {
              onBlur(id, value);
            }}
            minValue={MINIMUM_TO_FACE_AMOUNT}
          />
        )
      );
    },
    TermOptionPlus: () => {
      const topTitle = intl.formatMessage({ id: 'coverage.option.termOptionPlus' });
      //need an extra check to determine if termOptionPlus should render
      return (
        shouldRender &&
        dividendOption === ePerformaxDividendOption.termOption && (
          <div className="dividendContainer">
            <MWIDropdown
              name="blank"
              label={<FormattedMessage id="coverage.blank" />}
              disabled
              options={[{ value: topTitle, label: topTitle }]}
              containerStyle={`${locale === eLocale.en ? classNameDividendEn : 'p-col-6 p-lg-7 p-xl-7 p-sm-12'}`}
            />
            <MWINumberField
              id={eField.topFaceAmount}
              styleClass="p-col-2"
              containerClass={`mwi-widget mwi-input number-data`}
              role="container-mwinumberfield"
              onBlur={(id, value) => {
                onBlur(id, value);
              }}
              label={<FormattedMessage id="coverage.topFaceAmount" />}
              value={topFaceAmount}
              decimalScale={2}
              prefix={locale === eLocale.en ? '$ ' : ''}
              suffix={locale === eLocale.fr ? ' $' : ''}
              thousandSeparator={locale === eLocale.en ? ',' : ' '}
              minValue={performaxCoverageConfig.termOptionPlus.minAmount(coverageAmount)}
              locale={locale}
              decimalSeparator={locale === eLocale.en ? '.' : ','}
              fixedDecimalScale
            />
          </div>
        )
      );
    },
  };
};
