import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InputMask } from 'primereact/components/inputmask/InputMask';
import { eLocale } from '../../../core/configs';
import { isIOS } from '../../../core/utils';

export class MWIDob extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locale: PropTypes.string,
    errors: PropTypes.object,
    grid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    styleClass: PropTypes.string,
    containerClass: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    labelId: PropTypes.string,
    ariaLabel: PropTypes.string,
    labelStyle: PropTypes.string,
    topBarFieldStyle: PropTypes.string,
    ariaLive: PropTypes.bool,
  };

  static defaultProps = {
    grid: '2',
    locale: eLocale.en,
    isRequired: false,
    labelId: '',
  };

  constructor(props) {
    super(props);
    this.mwiDobRef = React.createRef();
  }
  // componentDidMount() {
  //   this.mwiDobRef.current.input.setAttribute('aria-describedby', 'dobErrorMessage');
  // }
  componentDidUpdate(prevProps) {
    const { locale } = this.props;

    /*This logic to fix placeholder issue which is not updated when the language gets changed*/
    if (prevProps.locale !== locale) {
      this.mwiDobRef.current.forceUpdate();
    }
  }

  render() {
    const {
      name,
      label,
      value,
      locale,
      placeholder,
      onChange,
      onBlur,
      grid,
      disabled,
      errors,
      isRequired,
      styleClass,
      labelId,
      ariaLabel,
      containerClass,
      labelStyle,
      topBarFieldStyle,
      ariaLive,
    } = this.props;

    return (
      <div
        className={`p-col-${grid} ${containerClass} mwi-control-wrapper ${locale}`}
        aria-live={ariaLive ? 'polite' : 'off'}
        aria-atomic={ariaLive}
      >
        <div className={`mwi-label ${labelStyle}`}>
          <label htmlFor={`txt-${name}${labelId}`}>{label}</label>
          {!!isRequired && <span className="mwi-mandatory-ind">*</span>}
        </div>

        <div className={`space-right ${styleClass}`}>
          <InputMask
            id={`txt-${name}${labelId}`}
            mask="99/99/9999"
            slotChar={placeholder || label}
            value={value}
            disabled={disabled}
            className={`mwi-widget mwi-input ${topBarFieldStyle} ${styleClass} ${
              !errors[name] ? '' : 'mwi-error-field'
            }`}
            placeholder={placeholder || label}
            onComplete={(e) => {
              // Because of mask copy paste issue we are using the reference value
              if (e.originalEvent.target) {
                onBlur(name, e.originalEvent.target.value);
              } else {
                onBlur(name, this.mwiDobRef.current.input.value);
              }
            }}
            onChange={(e) => onChange(name, e.value)}
            aria-label={ariaLabel}
            ref={this.mwiDobRef}
            aria-describedby="dobErrorMessage"
          />
        </div>

        <div
          id="dobErrorMessage"
          className="mwi-error-msg"
          role={errors[name] || isIOS ? 'alert' : ''}
          aria-atomic="true"
        >
          {errors[name]}
        </div>
      </div>
    );
  }
}

export default MWIDob;
