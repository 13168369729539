import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getFormattedValue } from '../../utils';

export function closeErrorModal(okCall, isOpenSpreadsheet, maxWithdrawal, appActions) {
  appActions.dispatchMWIOnChange({
    target: 'app',
    field: 'isUdmIllustrationError',
    value: false,
  });
  appActions.dispatchMWIOnChange({
    target: 'app',
    field: 'warningPopUp',
    value: typeof okCall == 'boolean' ? false : undefined,
  });

  // Close Spreadsheet on Max Withdrawal Case
  if (isOpenSpreadsheet && maxWithdrawal && typeof okCall != 'boolean') {
    appActions.dispatchMWIOnChange({
      target: 'app',
      field: 'closeSpreadsheet',
      value: true,
    });
  }
}

// You can generate any table with this function
export const generateTable = (table) => {
  // render header titles
  const tableHeaderPopulated = table.headerData.map((element, index) => {
    return (
      <th key={index} className={`${element.className}`}>
        {element.dataText}
      </th>
    );
  });

  // render body data
  const tableBodyPopulated = [];
  for (let i = 0; i < table.bodyData.rows.length; i++) {
    const rowData = [];
    for (let j = 0; j < table.bodyData.rows[i].length; j++) {
      rowData.push(
        <td key={`${table.bodyData.uniqueKey} + ${i}-${j}`} className={`${table.bodyData.classNames[j]}`}>
          {table.bodyData.rowsFormat[j]
            ? getFormattedValue(table.language, table.bodyData.rows[i][j])
            : table.bodyData.rows[i][j]}
        </td>
      );
    }

    tableBodyPopulated.push(<tr key={`${table.bodyData.uniqueKey} + ${i}`}>{rowData}</tr>);
  }

  return (
    <>
      <table id={`${table.id}`}>
        <thead>
          <tr>{tableHeaderPopulated}</tr>
        </thead>
        <tbody>{tableBodyPopulated}</tbody>
      </table>
    </>
  );
};

// custom error ok button handler
export const getOnClickOk = ({
  errorId,
  errorData,
  isOpenSpreadsheet,
  appActions,
  changleFirstLine,
  column,
  rowData
}) => {
  const maxWithdrawal = errorId === 'MaxWithdrawal'; // Max withdrawal custom check
  switch (errorId) {
    case 'MinFirstYearCustomDepositAmt':
      return () => {
        changleFirstLine((errorData?.tip.toString()), rowData, column);
        closeErrorModal(true, isOpenSpreadsheet, maxWithdrawal, appActions);
      };
    case 'MaxWithdrawal':
      return () => {
        closeErrorModal(true, isOpenSpreadsheet, maxWithdrawal, appActions);
      };
    default:
      return () => {
        /* return nothing if error */
      };
  }
};

// Generating proper data for table function
export const getTableDataMaxWithdrawal = (validationErrors, language) => {
  // rows data for table
  const rows = validationErrors.map((element) => {
    const { year, tip } = element.message[0];
    return [year, tip[0], tip[1]];
  });
  const rowLength = 8;

  return {
    language,
    id: 'maxWithdrawalTable',
    headerData: [
      // Year
      {
        className: 'yearColHeader',
        dataText: <FormattedMessage id="common.error.table.year" />,
      },
      // Req withdrawal
      {
        className: 'reqWithdrawalColHeader',
        dataText: <FormattedMessage id="common.error.table.reqWithdrwal" />,
      },
      // Max withdrawal
      {
        className: `${language === 'fr' && 'reqWithdrawalColHeaderFr'}`,
        dataText: <FormattedMessage id="common.error.table.maxWithdrwal" />,
      },
    ],
    bodyData: {
      uniqueKey: 'maxWithdrawalYear',
      classNames: [
        'yearColData',
        'reqWithdrawalCol',
        rows.length < rowLength ? 'overflowTableWithdrawalCol' : 'maxWithdrawalCol',
      ],
      rowsFormat: [false, true, true],
      rows,
    },
  };
};

export function setLabels(id) {
  if (id === 'MaxWithdrawal') {
    return {
      contentLabel: 'common.warning',
      exitLabel: 'common.cancel',
    };
  } else {
    return {
      contentLabel: 'common.error',
      exitLabel: 'common.close',
    };
  }
}

export const getErrorMessage = (app, udmResponse) => {
  let errorMessage;
  if (app.isUdmIllustrationError) {
    errorMessage = udmResponse.validationErrors && udmResponse.validationErrors[0].message[0].text;
  } else if (app.warningPopUp) {
    errorMessage = app.errorMessage ?? '';
  } else {
    errorMessage = '';
  }

  return errorMessage;
};
