import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import Spreadsheet from '../../../spreadsheet';
import './spreadsheet-fullmode.css';
import { Fullscreen, CircleX } from '@manulife/mux-cds-icons';
import ScenarioTabNavs from '../../../custom-mode/scenario-tab-navs';

export class SpreadsheetFullScreen extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showStatuses: false,
    };

    this.hideStatuses = this.hideStatuses.bind(this);
    this.openStatuses = this.openStatuses.bind(this);
    this.CustVBtnRef = React.createRef();
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      ReactModal.setAppElement('#mwi-root');
    }
  }

  hideStatuses() {
    const timeOutDuration = 100;
    this.setState({ showStatuses: false });
    //Fix iPad focus lost
    setTimeout(this.setCustVBtnFocus, timeOutDuration, this.CustVBtnRef);
  }

  openStatuses() {
    this.setState({ showStatuses: true });
  }

  getStatuses() {
    return this.state.showStatuses;
  }

  render = () => {
    return (
      <div className="button" ref={this.CustVBtnRef}>
        <div
          className="fullscreen-button"
          onClick={this.openStatuses}
          aria-label={this.props.intl.formatMessage({ id: 'spreadsheet.fullscreen' })}
          role="button"
        >
          <div className="line" />
          <Fullscreen />
          <FormattedMessage id="spreadsheet.fullscreen" />
        </div>

        <ReactModal
          className="button fullscreen-fullmode"
          contentLabel={this.props.intl.formatMessage({ id: 'spreadsheet.fullscreen' })}
          isOpen={this.state.showStatuses}
          shouldCloseOnOverlayClick
          onRequestClose={this.hideStatuses}
          role="dialog"
        >
          <ScenarioTabNavs isSpreadsheetFullScreen />
          <div
            className="exit fullscreen-button"
            onClick={this.hideStatuses}
            aria-label={this.props.intl.formatMessage({ id: 'spreadsheet.exitfullscreen' })}
            role="button"
            style = {{width: '120px'}}
          >
            <div className="line" />
            <CircleX />
            <FormattedMessage id="spreadsheet.exitfullscreen" />
          </div>
          <Spreadsheet isSpreadsheetFullScreen />
        </ReactModal>
      </div>
    );
  };
}

export default injectIntl(SpreadsheetFullScreen);
