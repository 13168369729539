import {
  eProduct,
  eRider,
  eCoverageType,
  eCoverageOption,
  eDefault,
  eCalcField,
  eLocale,
  eCostOfInsuranceType,
} from '../../core/configs';
import { getFormattedValue, isJoint, getFullName } from '../../core/utils';
import moment from 'moment';
import React from 'react';

export function isRiderSelected(riders, clients) {
  let riderSelected = false;
  Object.entries(riders).forEach((r) => {
    const rider = r[1];
    if (!riderSelected && rider.isSelected) {
      if (rider.payor) {
        riderSelected = true;
      } else {
        riderSelected = clients.allClients.some((clientId) => rider[clientId] && rider[clientId].isSelected);
      }
    }
  });

  return riderSelected;
}

export function ESLA(targetUDMCoverage) {
  return (targetUDMCoverage && targetUDMCoverage[eCalcField.esla]) || '';
}

export function coveragePremium(targetUDMCoverage, locale) {
  return getFormattedValue(locale, (targetUDMCoverage && targetUDMCoverage[eCalcField.covPremium]) || '0');
}

export function coverageAmount(targetUDMCoverage, locale) {
  return getFormattedValue(locale, (targetUDMCoverage && targetUDMCoverage[eCalcField.covAmount]) || '', 0);
}

export function getReturnOfPremium(locale, returnOfPremium) {
  const prem = getFormattedValue(locale, (returnOfPremium && returnOfPremium[eCalcField.covPremium]) || '0');
  return prem;
}

export function coverageAge(client1) {
  return (client1 && client1.age) || '';
}

export function isRated(client1Ratings) {
  return (
    client1Ratings.permRatingAmount !== eDefault.permRatingAmount ||
    client1Ratings.permRatingPercent !== eDefault.permRatingPercent ||
    client1Ratings.tempRatingAmount !== eDefault.tempRatingAmount ||
    client1Ratings.tempRatingYear !== eDefault.tempRatingYear
  );
}

export function isRatedPMax(client1Ratings) {
  return (
    client1Ratings.permRatingAmount !== eDefault.permRatingAmount ||
    client1Ratings.permRatingPercent !== eDefault.permRatingPercent ||
    client1Ratings.tempRatingAmount !== eDefault.tempRatingAmount ||
    client1Ratings.tempRatingYear !== eDefault.tempRatingYearPMax
  );
}

export function isTermRiderRated(riderRatings, clients) {
  const rated = clients.some((client) => isRated(riderRatings[client.id]));
  return rated;
}

export function isRiderRated(rider) {
  return rider && rider.isSelected && rider.rating && rider.rating !== eDefault.rider.rating;
}

export function isRiderRatedMoreThan100(rider) {
  return rider.rating > eDefault.rider.rating;
}

export function isPar(product) {
  return product === eProduct.PAR;
}

export function isLC(product) {
  return product === eProduct.LC;
}

export function isPGold(product) {
  return product === eProduct.PG;
}

export function isPerformax(product) {
  return product === eProduct.Performax;
}

export function isSB(product) {
  return product === eProduct.SB;
}

export function isMUL(product) {
  return product === eProduct.MUL;
}

export function isMULOrPG(product) {
  return product === eProduct.MUL || product === eProduct.PG;
}

export function isPmaxOrSB(product) {
  return product === eProduct.Performax || product === eProduct.SB;
}

export function isTermRider(rider) {
  return (
    rider === eCoverageOption.t05 ||
    rider === eCoverageOption.t10 ||
    rider === eCoverageOption.t20 ||
    rider === eCoverageOption.t65
  );
}

export const ratingsMessage = (isJointType, intl) =>
  isJointType
    ? intl.formatMessage({ id: 'common.jointTypeRatings' })
    : intl.formatMessage({ id: 'common.regularRatings' });

// Generate ratings per user
export function generateRatings(ratings, intl, isJointType, combined = '') {
  const { permRatingAmount, permRatingPercent, tempRatingAmount, tempRatingYear } = ratings;

  const { locale, formatMessage } = intl;

  const numCharsToBreak = 3;
  const permRatingPercentPresent = permRatingPercent > eDefault.permRatingPercent;
  const permRatingAmountPresent = permRatingAmount > eDefault.permRatingAmount;
  const tempRatingYearPresent = tempRatingAmount > eDefault.tempRatingAmount;

  const separator = locale === 'en' ? ', ' : '; ';
  const showPermSeparator = () => (permRatingAmountPresent || tempRatingYearPresent) && `${separator}`;
  const showPermEndBreak = () => (permRatingAmountPresent || tempRatingYearPresent) && locale === 'fr' && <br />;
  const showTempSeparator = () => tempRatingYearPresent && `${separator}`;
  const showTempEndBreak = () => tempRatingYearPresent && (!combined || combined.length <= numCharsToBreak) && <br />;

  const clientRatings = (
    <>
      {combined ? combined + formatMessage({ id: 'common.ratingsSmall' }) : ratingsMessage(isJointType, intl)}
      {locale === 'fr' && ` `}
      {`: `}
      {permRatingPercentPresent && (
        <>
          {getFormattedValue(locale, permRatingPercent, 0, true)}
          {showPermSeparator()}
          {showPermEndBreak()}
        </>
      )}

      {permRatingAmountPresent && (
        <>
          {`${getFormattedValue(locale, permRatingAmount)} ${formatMessage({ id: 'common.forLife' })}`}
          {showTempSeparator()}
          {showTempEndBreak()}
        </>
      )}

      {tempRatingYearPresent && (
        <>
          {`${getFormattedValue(locale, tempRatingAmount)} ${formatMessage({
            id: 'common.for',
          })} ${tempRatingYear} ${formatMessage({ id: 'common.yearss' })}`}
        </>
      )}
    </>
  );
  return clientRatings;
}

// Generate PGold rating
export function generatePGRatings(ratings, intl) {
  const permRatingPercent = ratings;
  const pgRatingGreaterThan100 = permRatingPercent > eDefault.permRatingPercent;
  const { locale } = intl;

  const clientPGRatings = (
    <>
      {pgRatingGreaterThan100 && (
        <>
          {`, `}
          {getFormattedValue(locale, permRatingPercent, 0, true)}
        </>
      )}
    </>
  );
  return clientPGRatings;
}

export function isFamilyTermVitality(product) {
  return product === eProduct.FTV;
}

export function isFamilyTermRenewalCalc(product) {
  return product === eProduct.FTRC;
}

export function showRated(client, product, coverage) {
  const ratings = coverage.ratings[client.id];
  return ratings && !isJoint(product, coverage.coverageType) && isRated(ratings);
}

export function showCoverageEffectiveDate(effectiveDate, locale, defaultDate) {
  if (effectiveDate) {
    moment.locale(locale);

    return moment(effectiveDate, 'DD/MM/YYYY').format(locale === eLocale.en ? 'MMM D, YYYY' : 'D MMM YYYY');
  } else {
    return defaultDate;
  }
}

export function getClientRatings(coverage, client) {
  return coverage.ratings[client?.id] || '';
}

export function getCoverageOption(coverage, product, configs) {
  let covOption;
  if (product === eProduct.PAR) {
    const premiumDuration = configs.premiumDurations.find(
      (premDuration) => premDuration.value === coverage.premiumDuration
    );
    covOption = premiumDuration.label;
  } else if (product === eProduct.PG) {
    const costDuration = configs.costDurations.find((costDurationPG) => costDurationPG.value === coverage.costDuration);
    covOption = costDuration.label.toLowerCase();
  } else if (product === eProduct.LC) {
    const lcCoverageDuration = configs.lcCoverageOptions.find((covOpt) => covOpt.value === coverage.lcCoverageOption);
    covOption = lcCoverageDuration.abbr;
  } else {
    const coverageDuration = configs.coverageOptions.find((covOpt) => covOpt.value === coverage.coverageOption);
    covOption = coverageDuration.abbr;
  }

  return covOption;
}

// this method builds a client-centric array of coverages for use with the summary display
export function buildClientSummary(allCoverages, udmCoverages, udmResponse, clients, product, configs) {
  const clientSummary = [];

  allCoverages.tabNavs.forEach((coverageId) => {
    const coverage = allCoverages[coverageId];
    const clientIdsInCov = Object.keys(coverage.ratings);
    const client1 = clients[clientIdsInCov[0]];

    //Term inforce combined coverage, client 2 is the client from associated coverage
    const client2 = clients[clientIdsInCov[1]];
    const client1Ratings = getClientRatings(coverage, client1);
    const client2Ratings = getClientRatings(coverage, client2);

    const covOption = getCoverageOption(coverage, product, configs);
    let targetUDMCoverage = udmCoverages.find((cov) => cov.coverageId === coverageId);
    // if there is a response error or target coverage not found or coverage amount is calculated as 0
    // then use the actual coverage amount entered by the user
    if (udmResponse.isError || !targetUDMCoverage || targetUDMCoverage.coverageAmount === 0) {
      targetUDMCoverage = coverage;
    }

    let clientIndex = clientSummary.findIndex(
      (summary) => summary && summary.client1 === client1 && summary.client2 === client2
    );
    if (clientIndex < 0) {
      // check to see that clients do exist
      // but appear switched due to client selections for non-single coverage
      const client2Index = clientSummary.findIndex(
        (summary) => summary && summary.client1 === client2 && summary.client2 === client1
      );
      clientIndex = client2Index >= 0 ? client2Index : clientIndex;
    }

    const info = { coverage, client1Ratings, client2Ratings, covOption, targetUDMCoverage };
    clientIndex < 0
      ? clientSummary.push({ client1, client2, covInfo: [info] })
      : clientSummary[clientIndex].covInfo.push(info);
  });

  return clientSummary;
}

const keyBy = (array, key) => (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});

function processTdwPayor(rider, udmCoverages, name, configs, riderPayorPlaceholder, riderSummary) {
  const payorId = rider.payor.id;
  const riderClient = rider[payorId];
  const targetUDMCoverage = udmCoverages.find((coverage) => coverage.coverageId === riderClient.riderId);
  const info = {
    isSelected: rider.isSelected,
    rating: rider.ratings[payorId].permRatingPercent,
    name,
    targetUDMCoverage,
  };
  info.abbr = configs.riders.find((r) => r.id === info.name).abbr;
  riderClient.fullName = getFullName(riderClient);
  // there's always only one payor so for display purposes, always show it as 1
  riderClient.placeholder = riderPayorPlaceholder;
  riderSummary.tdwOnPayor = { client: riderClient, covInfo: info };
}

const getSummaryClientInformation = (clients, rider, udmCoverages, name, configs, riderSummary, product) => {
  clients.allClients.forEach((clientId) => {
    const riderClient = rider[clientId];
    if (!riderClient || !riderClient.isSelected) {
      return;
    }

    const targetUDMCoverage = udmCoverages.find((coverage) => coverage.coverageId === riderClient.riderId);
    const info = { isSelected: riderClient.isSelected, rating: riderClient.rating, name, targetUDMCoverage };
    info.abbr = configs.riders.find((r) => r.id === info.name).abbr;
    if (!riderClient.rating) {
      info.rating = riderClient.permRatingPercent;
    }
    const clientIndex = findIndexInSummary(riderSummary, [clients[clientId]]);
    if (clientIndex < 0) {
      riderSummary.rider.push({
        clients: isPmaxOrSB(product) && name === eRider.pwdd ? [] : [clients[clientId]],
        covInfo: [info],
      });
    } else {
      riderSummary.rider[clientIndex].covInfo.push(info);
    }
  });
};

const findIndexInSummary = (riderSummary, clientInfo) => {
  // this method returns true if it finds all of clientInfo in the rider summary
  // this means that to be true:
  //   clientInfo must only have client 1 if an entry in riderSummary contains only client 1;
  //   clientInfo must only have client 2 if an entry in riderSummary contains only client 2;
  //   clientInfo must have both client 1 and 2 if an entry in riderSummary contains both clients
  return riderSummary.rider.findIndex((summary) => {
    const summaryClients = summary && summary.clients;
    const client1Exists = summaryClients && summaryClients.includes(clientInfo[0]),
      client2Exists = summaryClients && summaryClients.includes(clientInfo[1]);
    const has2Clients = summaryClients.length > 1 || clientInfo.length > 1;
    return summaryClients && has2Clients ? client1Exists && client2Exists : client1Exists;
  });
};

// this method builds a client-centric array of riders for use with the summary display
export function buildRiderSummary(
  termRiders,
  riders,
  clients,
  udmCoverages,
  ridersByClient,
  riderPayorPlaceholder,
  termOtherRider,
  spousalRider,
  product,
  configs
) {
  const riderSummary = { rider: [] };

  const getRiderName = (info) => {
    switch (info.costOfInsurance) {
      case eCostOfInsuranceType.r10:
        return eCoverageOption.t10;
      case eCostOfInsuranceType.r05:
        return eCoverageOption.t05;
      case eCostOfInsuranceType.level65:
        return eCoverageOption.t65;
      default:
        return '';
    }
  };

  termRiders.allRiders.forEach((riderId) => {
    const rider = termRiders[riderId];
    const clientIds = rider.coverageType === eCoverageType.single ? [rider.clientId] : rider.clientIds;
    const targetUDMCoverage = udmCoverages.find((coverage) => coverage.coverageId === rider.id);
    const info = {
      name: rider.coverageOption,
      type: rider.coverageType,
      amount: rider.coverageAmount,
      costOfInsurance: rider.costOfInsurance,
      duration: rider.coverageDuration,
      isSelected: true,
      rating: rider.ratings,
      targetUDMCoverage,
    };
    if (isPerformax(product) || isSB(product)) {
      info.abbr = configs.performaxTermRiderOptions.find((option) => option.value === getRiderName(info)).abbr;
    } else {
      info.abbr = configs.termRiderOptions.find((option) => option.value === info.name).abbr;
    }
    const clientInfo = [];
    clientIds.forEach((id) => {
      clientInfo.push(clients[id]);
    });
    const clientIndex = findIndexInSummary(riderSummary, clientInfo);
    clientIndex < 0
      ? riderSummary.rider.push({
          clients: clientInfo,
          covInfo: [info],
        })
      : riderSummary.rider[clientIndex].covInfo.push(info);
  });
  isPmaxOrSB(product) &&
    termOtherRider.allRiders.forEach((riderId) => {
      const rider = termOtherRider[riderId];
      const clientIds = rider.coverageType === eCoverageType.single ? [rider.clientId] : rider.clientIds;
      const targetUDMCoverage = udmCoverages.find((coverage) => coverage.coverageId === rider.id);
      const info = {
        name: rider.coverageOption,
        type: rider.coverageType,
        amount: rider.coverageAmount,
        costOfInsurance: rider.costOfInsurance,
        duration: rider.coverageDuration,
        isSelected: true,
        rating: rider.ratings,
        targetUDMCoverage,
        riderProduct: eRider.tor,
      };
      info.abbr = configs.performaxTermOtherRiderOptions.find((option) => option.value === getRiderName(info)).abbr;
      const clientInfo = [];
      clientIds.forEach((id) => {
        clientInfo.push(clients[id]);
      });
      const clientIndex = findIndexInSummary(riderSummary, clientInfo);
      clientIndex < 0
        ? riderSummary.rider.push({
            clients: clientInfo,
            covInfo: [info],
          })
        : riderSummary.rider[clientIndex].covInfo.push(info);
    });
  isPmaxOrSB(product) &&
    spousalRider.allRiders.forEach((riderId) => {
      const rider = spousalRider[riderId];
      const clientIds = rider.coverageType === eCoverageType.single ? [rider.clientId] : rider.clientIds;
      const targetUDMCoverage = udmCoverages.find((coverage) => coverage.coverageId === rider.id);
      const info = {
        name: rider.coverageOption,
        type: rider.coverageType,
        amount: rider.coverageAmount,
        costOfInsurance: rider.costOfInsurance,
        duration: rider.coverageDuration,
        isSelected: true,
        rating: rider.ratings,
        targetUDMCoverage,
        riderProduct: eRider.spr,
      };
      info.abbr = configs.performaxTermSpousalRiderOptions.find((option) => option.value === getRiderName(info)).abbr;
      const clientInfo = [];
      clientIds.forEach((id) => {
        clientInfo.push(clients[id]);
      });
      const clientIndex = findIndexInSummary(riderSummary, clientInfo);
      clientIndex < 0
        ? riderSummary.rider.push({
            clients: clientInfo,
            covInfo: [info],
          })
        : riderSummary.rider[clientIndex].covInfo.push(info);
    });

  const mappedRiders = keyBy(ridersByClient, 'partyId');

  function processCpr(rider) {
    if (isPmaxOrSB(product)) {
      riderSummary.cpr = { childCount: rider.childCount };
      return;
    }

    const children = [];
    let rated = false;
    let sumOfCPR = 0;
    if (rider.allChildren.length === 0) {
      return;
    }

    rider.allChildren.forEach((childId) => {
      children.push(rider[childId]);
      if (!rated) {
        rated = isRated(rider[childId]);
      }
      if (mappedRiders[childId]) {
        const riderCoverageSummary = mappedRiders[childId].coverageSummary;
        if (riderCoverageSummary) {
          sumOfCPR += !riderCoverageSummary.length ? 0 : riderCoverageSummary[0].totalMinimumModalPremium;
        }
      }
    });

    riderSummary.cpr = { children, rated, totalPremium: sumOfCPR };
  }

  Object.keys(riders).forEach((name) => {
    const rider = riders[name];
    if (!rider.isSelected) {
      return;
    }
    if (name === eRider.cpr) {
      processCpr(rider);
      return;
    }
    if (name === eRider.tdwpayor) {
      processTdwPayor(rider, udmCoverages, name, configs, riderPayorPlaceholder, riderSummary);
      return;
    }
    getSummaryClientInformation(clients, rider, udmCoverages, name, configs, riderSummary, product);
  });

  return riderSummary;
}

export function isLevelCOI(allCoverages) {
  return allCoverages[allCoverages.activeTabId].costOfInsurance === 'Level100' ? true : false;
}

export function isVitalityPlus(allCoverages) {
  return allCoverages[allCoverages.activeTabId].isVitalityPlusSelected;
}

/**
 * Returns a value safely without throwing an object undefined error
 *
 * @param {Array<Record<string, unknown>>} array
 * @param {unknown} searchValue
 * @param {string} searchKey
 * @param {string} resultKey
 * @returns {unknown?}
 */
export function safeFind(array, searchValue, searchKey, resultKey) {
  const resultObject = array.find((item) => item[searchKey] === searchValue);

  if (typeof resultObject === 'object' && resultKey in resultObject) {
    return resultObject[resultKey];
  }
}

export const isSummaryAmountsHidden = (product, inforce) => {
  // hide summary amounts for FT/BT Inforce
  return inforce && [eProduct.FT, eProduct.BT].includes(product);
};
