import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import './openedcase.css';
import ProfileCard from '../profile-card';

export class OpenedSaveModel extends PureComponent {
  static propTypes = {
    openedCaseName: PropTypes.string,
    saveCaseStarted: PropTypes.bool,
    inforce: PropTypes.bool,
    saveCaseFinished: PropTypes.bool,
    saveFailure: PropTypes.bool,
    intl: PropTypes.object,
    history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };

  render() {
    const { intl } = this.props;

    let stateIcon;
    let stateText;
    let stateTextClass = 'openedcase-state-text ';

    if (this.props.saveCaseFinished) {
      stateIcon = <i className="material-icons case-state-icon">done</i>;
      stateText = intl.formatMessage({ id: 'common.saved' });
    }

    if (this.props.saveCaseStarted) {
      stateIcon = <i className="material-icons case-state-icon mwi-spin">refresh</i>;
      stateText = intl.formatMessage({ id: 'common.saving' });
    }

    if (this.props.saveFailure) {
      stateIcon = <i className="material-icons case-state-icon-error case-state-error">highlight_off</i>;
      stateText = intl.formatMessage({ id: 'common.save.error' });
      stateTextClass += 'case-state-error';
    }

    const marginChangeLength = 35;

    const getTitle = (openedCaseName, inforce) => {
      if (openedCaseName === '') {
        return intl.formatMessage({ id: 'common.newIllustration' });
      } else if (inforce) {
        return intl.formatMessage({ id: 'common.newInforceIllustration' });
      } else {
        return openedCaseName;
      }
    };

    return (
      <div className="container" role="opencase-container">
        <div className="p-grid p-col-6 p-lg-8 p-xl-6 opened-case-container">
          <h2 className="openedcase-header">{getTitle(this.props.openedCaseName, this.props.inforce)}</h2>
          {this.props.inforce && (
            <div className="p-grid p-col-6 p-lg-8 p-xl-6 opened-case-container">
              <span className="openedcase-subheader">
                {intl.formatMessage({ id: 'common.policyNumber' }) + this.props.openedCaseName}
              </span>
            </div>
          )}
          {!this.props.inforce && stateText && (
            <div
              className="openedcase-state"
              style={{ marginLeft: this.props.openedCaseName.length >= marginChangeLength ? '80px' : '0px' }}
            >
              {stateIcon}
              <span className={stateTextClass}>{stateText}</span>
            </div>
          )}
        </div>
        <ProfileCard />
      </div>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    openedCaseName: app.openedCaseName,
    saveCaseStarted: app.saveCaseStarted,
    saveCaseFinished: app.saveCaseFinished,
    saveFailure: app.saveFailure,
    inforce: app.inforcePolicy,
  };
};

export default connect(mapStateToProps)(injectIntl(OpenedSaveModel));
