import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as AppActions from '../../core/actions';
import SpousalRider from './spousal-rider';
import './spousal-riders.css';
import { eField } from '../../core/configs';

export class SpousalRiders extends PureComponent {
  static propTypes = {
    udmEnabled: PropTypes.bool,
    spousalRiders: PropTypes.object.isRequired,
    appActions: PropTypes.object,
    locale: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.lastRider = React.createRef();
    this.state = { newRider: false };
  }

  componentDidUpdate() {
    if (this.state.newRider && this.lastRider !== null) {
      this.setState({ newRider: false }); // react permits this when wrapped in condition to prevent infinite loop
      if (typeof this.lastRider.focus === 'function') {
        this.lastRider.focus();
      }
    }
  }

  dispatchAction(data, target = 'spousalRiders', isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      ...data,
    });
  }

  toggleRiderFold(value, riderId) {
    this.dispatchAction({ field: eField.isFolded, value, riderId }, 'spousalRider');
  }

  render() {
    const { spousalRiders } = this.props;
    const hasRider = spousalRiders?.allRiders.length > 0;
    return (
      <div className="spousal-riders-wrapper">
        {hasRider && (
          <div>
            {spousalRiders.allRiders.map((riderId, index) => (
              <div className="p-col-12" key={riderId}>
                <MWIAccordion
                  ref={(el) => (this.lastRider = el)}
                  className={`accordion mwi-reverse-margin15 mwi-lightGreen ${index === 0 ? 'first-child' : ''}`}
                  title={
                    <span>
                      <FormattedMessage id="rider.spr.title" />
                    </span>
                  }
                  removeBtn
                  folded={spousalRiders[riderId].isFolded}
                  onClickChange={() => this.toggleRiderFold(!spousalRiders[riderId].isFolded, riderId)}
                >
                  {this.props.udmEnabled && <div className="udm-id">{`${riderId}`}</div>}
                  <SpousalRider riderInfo={spousalRiders[riderId]} riderIndex={index} />
                </MWIAccordion>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export const mapStateToProps = ({ scenarioTabNavs, spousalRiders, app }) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;

  return {
    udmEnabled: scenarioTabNavs.udmEnabled,
    spousalRiders: spousalRiders[scenarioTabId],
    locale: app.locale,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SpousalRiders));
