import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MWIButton from '../mwi-button';
import './delete-dialog.css';

export class DeleteDialog extends PureComponent {
  static propTypes = {
    delete: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    locale: PropTypes.string,
    intl: PropTypes.object,
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  delete() {
    this.props.delete();
  }

  cancel() {
    this.props.cancel();
  }

  render() {
    const { intl, title, message } = this.props;

    return (
      <div className="p-grid p-grid-no-margin-side delete-dialog-wrapper" role="delete-dialog">
        <h2 className="p-col-12 title-model" role="delete-title">
          {title}
        </h2>
        <div className="p-col-12 row-model" role="row-model">
          {message}
        </div>
        <div className="p-col-12 dialog-footer flexdisplay" role="dialog-footer">
          <div className="p-col-3" />
          <MWIButton
            name="cancel"
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={this.cancel}
            styleClass="mwi-w80 reset-cancel-button cancel-btn oneflex p-col-4"
            title="cancel-btn"
          />
          <MWIButton
            name="delete"
            label={intl.formatMessage({ id: 'common.delete' })}
            onClick={this.delete}
            styleClass="mwi-w80 save-button oneflex p-col-4"
            title="delete-btn"
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(DeleteDialog);
