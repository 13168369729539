import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { eField } from '../../core/configs';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';

const SummaryAdditionalCostSection = (props) => {
  const {
    isAdditionalCostsFolded,
    isFamilyTermVitality,
    dispatchAction,
    policyFee,
    vitalityFee,
    isVitalityPlusSelected,
    isPar,
  } = props;
  return (
    <MWIAccordion
      folded={isAdditionalCostsFolded}
      title={
        <span className="summary-accordion-font">
          <FormattedMessage id="summary.additionalcost" />
        </span>
      }
      onClickChange={() => dispatchAction(eField.isAdditionalCostsFolded, !isAdditionalCostsFolded, 'scenario')}
      className={'summary-accordion-no-border'}
    >
      <div className="additional-costs mwi-padding-top12 mwi-padding-bottom12" tabIndex="0" role="additional-costs">
        <div className="p-grid p-grid-no-margin  mwi-padding-right12">
          {!isPar && (
            <React.Fragment>
              <div className="p-col-8 label mwi-padding-left12 ">
                <FormattedMessage id="summary.policyfee" />
              </div>
              <div className="p-col-4 amount" role="policyfee">
                {policyFee}
              </div>
            </React.Fragment>
          )}

          {isVitalityPlusSelected && (
            <React.Fragment>
              <div className="p-col-8 label mwi-padding-left12 ">
                <FormattedMessage id="summary.vitalitycharge" />
              </div>
              <div className="p-col-4 amount" role="vitalitycharge">
                {vitalityFee}
              </div>
            </React.Fragment>
          )}

          {isFamilyTermVitality && (
            <React.Fragment>
              <div className="p-col-8 label mwi-padding-left12">
                <FormattedMessage id="summary.vitalitypremium2" />
              </div>
              <div className="p-col-4 amount" role="vitalitypremium2">
                {vitalityFee}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </MWIAccordion>
  );
};

SummaryAdditionalCostSection.propTypes = {
  isAdditionalCostsFolded: PropTypes.bool,
  isFamilyTermVitality: PropTypes.bool,
  dispatchAction: PropTypes.func,
  policyFee: PropTypes.string,
  vitalityFee: PropTypes.string,
  isVitalityPlusSelected: PropTypes.bool,
  isPar: PropTypes.bool,
};

export default SummaryAdditionalCostSection;
