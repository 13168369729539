import * as actionTypes from '../../core/actionTypes';
import {
  coverageConfig,
  eField,
  eDefault,
  eGoodRatings,
  eCoverageType,
  eCoverageOption,
  eDefaultRatings,
  eProduct,
  ePremiumDuration,
  eSpecialQuote,
  eLifecheque,
  eDepositType,
  eBusinessType,
  eEnhancerType,
} from '../../core/configs';
import {
  coverageIndexHelper,
  scenarioIndexHelper,
  assignDef,
  getMinClientAge,
  getDefaultCoverageAmount,
  calculateMWINumberFieldValue,
  getPolicyYear,
  isUDMResponseValidationExist,
  getValidStatusForInforce,
} from '../../core/utils';
import _ from 'lodash';
import * as reducerHelper from '../../helper/reducer-helper';

const firstCoverageTabId = coverageIndexHelper(1);
const firstScenarioTabId = scenarioIndexHelper(1);

const defaultCoverage = {
  switchYear: eDefault.switchYear,
  costOfInsuranceSwitchYear: eDefault.costOfInsuranceSwitchYear,
  deathBenefit: eDefault.deathBenefit,
  coverageType: eDefault.coverageType,
  coverageOption: eDefault.coverageOption,
  amountOfInsuranceType: eDefault.amountOfInsuranceType,
  specialQuoteOption: eDefault.specialQuote,
  premiumAmount: eDefault.premiumAmount,
  premiumDuration: eDefault.premiumDuration,
  isCoverageSolve: eDefault.isCoverageSolve,
  dividendOption: eDefault.dividendOption,
  performanceCreditOption: eDefault.performanceCreditOption,
  switchToCashDividends: eDefault.switchToCashDividends,
  switchToCashDividendsYear: eDefault.switchToCashDividendsYear,
  ratings: {},
  deathBenefitType: eDefault.deathBenefitType,
  costOfInsurance: eDefault.costOfInsurance,
  errors: {},
  isExpandedInSummary: false,
  minClientAge: eDefault.clientAge,
  isVitalityPlusSelected: false,
  isChangeToBronze: true,
  changeToBronzeAge: eDefault.changeToBronzeAge,
  isDefaultChangeToBronzeAge: true,
  lcCoverageOption: eLifecheque.lcCoverageOptions.lc10,
  lcPremiumDuration: eLifecheque.lcPremiumDurations.lcPayToAge100,
  lcReturnPremiums: {
    ropd: true,
    rops: false,
    ropx: false,
  },
  costDuration: eDefault.costDuration,
  shouldDisplayTOP: false,
  topFaceAmount: 0,
  toFaceAmount: 0,
  toFaceAmountRaw: 0,
  toFaceAmountFromPS: 0,
  topFaceAmountFromPS: 0,
  associatedCoverageId: 0,
};

const getDefaultCoverage = (defaultCov, product) => {
  if (product === eProduct.LC) {
    defaultCov.minClientAge = 40;
  }
  return defaultCov;
};

const getDefaultCoverageState = (defaultCovState, product) => {
  if (product === eProduct.LC) {
    defaultCovState['Coverage.1'].minClientAge = 40;
  }
  return defaultCovState;
};

const defaultCoverageState = {
  activeTabId: firstCoverageTabId,
  coverageIndex: 1,
  isMultiCov: false,
  tabNavs: [firstCoverageTabId],
  [firstCoverageTabId]: {
    id: firstCoverageTabId,
    ...defaultCoverage,
  },
  inforce: false,
};

const initialState = {
  activeTabId: firstScenarioTabId,
  [firstScenarioTabId]: {
    scenarioId: firstScenarioTabId,
    ...defaultCoverageState,
    inforce: false,
  },
};

const getNewCoverage = (state, action, data, isErrorUpdate = false) => {
  const scenarioTabId = action.payload.scenarioTabId;
  const coveragesState = state[scenarioTabId];
  const coverageTabId = data.coverageTabId || coveragesState.activeTabId;
  let changedData;

  if (isErrorUpdate) {
    changedData = {
      errors: {
        ...coveragesState[coverageTabId].errors,
        ...data,
      },
    };
  } else {
    changedData = { ...data };
  }

  return {
    ...coveragesState,
    [coverageTabId]: {
      ...coveragesState[coverageTabId],
      ...changedData,
    },
  };
};

const getCoverageWithNewRatings = (state, scenarioTabId, coverageTabId, newRatings) => {
  return {
    ...state,
    [scenarioTabId]: {
      ...state[scenarioTabId],
      [coverageTabId]: {
        ...state[scenarioTabId][coverageTabId],
        ratings: newRatings,
      },
    },
  };
};

const isGoodRate = (healthstyle) => eGoodRatings.indexOf(healthstyle) >= 0;

const isTermJoint = (covType) => [eCoverageType.jointFirst, eCoverageType.jointLast].indexOf(covType) >= 0;

const coverageTabNavsReducer = (state, action) => {
  state = state === undefined ? initialState : state;
  switch (action.type) {
    case actionTypes.MWI_UPDATE_ERROR: {
      const payload = action.payload;

      if (payload.target === eField.coverage) {
        const scenarioTabId = payload.scenarioTabId;
        const changedData = { [payload.field]: payload.value };

        return {
          ...state,
          [scenarioTabId]: getNewCoverage(state, action, changedData, true),
        };
      }

      return state;
    }

    case actionTypes.ADD_SCENARIO_TABNAVS: {
      return reducerHelper.getAddScenarioTabNavsState(state, action);
    }
    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return reducerHelper.getClickScenarioTabNavsState(state, action);
    }
    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      return reducerHelper.getRemoveScenarioTabNavsState(state, action);
    }

    default:
      return coverageTabNavsReducer2(state, action);
  }
};

const coverageTabNavsReducer2 = (state, action) => {
  switch (action.type) {
    // add ratings to the coverage
    case actionTypes.ADD_CLIENT: {
      if (state.inforce) {
        return {
          ...state,
          inforce: false,
        };
      }
      const { scenarioTabId, coverageTabId, clientId } = action.payload;
      let newRatings = _.cloneDeep(state[scenarioTabId][coverageTabId].ratings);
      newRatings = { ...newRatings, [clientId]: eDefaultRatings };

      return getCoverageWithNewRatings(state, scenarioTabId, coverageTabId, newRatings);
    }
    case actionTypes.SELECT_CLIENT: {
      const { scenarioTabId, coverageTabId, selectedClientId, prevClientId } = action.payload;
      const ratings = state[scenarioTabId][coverageTabId].ratings;
      const newRatings = {};

      _.forEach(_.keys(ratings), (clientId) => {
        if (clientId === prevClientId) {
          // replace with new clientId
          newRatings[selectedClientId] = eDefaultRatings;
        } else {
          // keep the current one
          newRatings[clientId] = ratings[clientId];
        }
      });

      return getCoverageWithNewRatings(state, scenarioTabId, coverageTabId, newRatings);
    }
    // remove ratings from the coverage
    case actionTypes.REMOVE_CLIENT: {
      const { scenarioTabId, coverageTabId, clientId } = action.payload;
      const newRatings = _.omit(state[scenarioTabId][coverageTabId].ratings, clientId);

      return getCoverageWithNewRatings(state, scenarioTabId, coverageTabId, newRatings);
    }
    default:
      return coverageTabNavsReducer3(state, action);
  }
};

const coverageTabNavsReducer3 = (state, action) => {
  switch (action.type) {
    case actionTypes.OPEN_SAVEDCASE: {
      const { scenarioTabNavs, coverageTabNavs } = action.payload.model;
      //This logic to assign all the missing propertied from the
      // initial/default to the saved old object with default value.
      const targetState = coverageTabNavs;
      assignDef(targetState, initialState);
      scenarioTabNavs.tabNavs.forEach((Scenario) => {
        // condition to update default coverageIndex to 1000(a least likely coverage number to get to in a session)
        if (targetState[Scenario.id].coverageIndex === undefined) {
          // Set to 1000 which is a least likely coverageID to get to in a session
          targetState[Scenario.id].coverageIndex = 1000;
        }
        assignDef(targetState[Scenario.id], defaultCoverageState);
        coverageTabNavs[Scenario.id].tabNavs.forEach((coverage) => {
          assignDef(targetState[Scenario.id][coverage], defaultCoverage);

          // this is a hack to make it backwards compatible with the old schema, do not remove
          const current = targetState[Scenario.id][coverage];
          if (current.coverageType === 'JointLtdLastDeath') {
            current.coverageType = eCoverageType.jointLastPayLastDeath;
          }
        });
      });
      return { ...targetState };
    }

    case actionTypes.CLICK_COVERAGE_TABNAVS: {
      const { scenarioTabId, coverageTabId } = action.payload;
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          activeTabId: coverageTabId,
        },
      };
    }

    case actionTypes.ADD_COVERAGE_TABNAVS: {
      const { scenarioTabId, coverageTabId, clientId, isVitalityPlusSelected } = action.payload;
      const coverageState = state[scenarioTabId];
      const coverage = _.clone(defaultCoverage);
      const newCoverageIndex = coverageState.coverageIndex + 1;

      // for vitality plus, ensure additional coverage points to the same client as initial coverage
      coverage.ratings = isVitalityPlusSelected
        ? coverageState[coverageState.activeTabId].ratings
        : { [clientId]: eDefaultRatings };

      // vitality plus is on or off for all coverages
      // so make sure additional coverage matches initial coverage
      coverage.isVitalityPlusSelected = isVitalityPlusSelected;

      const newState = {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          activeTabId: coverageTabId,
          isMultiCov: true,
          coverageIndex: newCoverageIndex,
          tabNavs: [...coverageState.tabNavs, coverageTabId],
          [coverageTabId]: {
            id: coverageTabId,
            coverageAmount: getDefaultCoverageAmount(state.product),
            ...coverage,
            isCoverageSolve: false,
          },
        },
      };

      // solve for coverage is only available in a single coverage
      // so if it is multi-coverages, then it should be deselected
      //newState[scenarioTabId]['Coverage.1'].isCoverageSolve = false;
      newState[scenarioTabId][newState[scenarioTabId].tabNavs[0]].isCoverageSolve = false;
      return {
        ...newState,
      };
    }

    case actionTypes.SELECT_PRODUCT: {
      // Set Default values per product
      const product = action.payload;
      const newCoverageIndex = defaultCoverageState.coverageIndex + 1;
      // When a Product is selected set the coverageAmount based on selected product
      return {
        product,
        activeTabId: firstScenarioTabId,
        [firstScenarioTabId]: {
          scenarioId: firstScenarioTabId,
          ...getDefaultCoverageState(defaultCoverageState, product),
          coverageIndex: newCoverageIndex,
          [firstCoverageTabId]: {
            id: firstCoverageTabId,
            ...getDefaultCoverage(defaultCoverage, product),
            coverageAmount: getDefaultCoverageAmount(product),
          },
        },
      };
    }

    case actionTypes.REMOVE_COVERAGE_TABNAVS: {
      const { scenarioTabId, coverageTabId } = action.payload;
      const coverageState = state[scenarioTabId];
      const newCoverageState = _.omit(coverageState, coverageTabId);
      const newTabNavs = _.filter(newCoverageState.tabNavs, (tabId) => tabId !== coverageTabId);

      const newActiveTabId =
        newTabNavs.length === 1 || coverageTabId === coverageState.activeTabId
          ? newTabNavs[0]
          : coverageState.activeTabId;

      newCoverageState.activeTabId = newActiveTabId;
      newCoverageState.tabNavs = newTabNavs;
      newCoverageState.isMultiCov = newTabNavs.length > 1;

      return {
        ...state,
        [scenarioTabId]: newCoverageState,
      };
    }
    default:
      return coverageTabNavsReducer4(state, action);
  }
};

const loadInforceCoverages = (coverages, policyProduct, policyYear) => {
  const newState = {
    ...initialState,
    inforce: true,
    activeTabId: firstScenarioTabId,
    [firstScenarioTabId]: {
      scenarioId: firstScenarioTabId,
      coverageIndex: defaultCoverageState.coverageIndex + 1,
      inforce: true,
      isMultiCov: false,
      tabNavs: [],
    },
  };

  const baseCoverage = coverages.filter(
    (cvg) =>
      cvg.indicatorCode === eField.indicatorCode.base && getValidStatusForInforce(policyProduct).includes(cvg.status)

  );
  if (baseCoverage.length < 1) {
    throw new Error('Missing Base Coverage');
  }

  baseCoverage.forEach((coverage) => {
    const product = eProduct[coverage.product] ? eProduct[coverage.product] : 'Mul';
    const clonedCoverage = JSON.parse(JSON.stringify(defaultCoverage));
    let inforceCoverage = getDefaultCoverage(clonedCoverage, product);
    let ratings = { ...eDefaultRatings };
    newState.product = product;
    inforceCoverage.id = coverage.coverageId;
    newState[firstScenarioTabId].activeTabId = coverage.coverageId;
    newState[firstScenarioTabId].tabNavs.push(coverage.coverageId);
    inforceCoverage = loadInforceCoverage(inforceCoverage, coverage, policyYear, coverages, policyProduct);

    coverage.life.forEach((life) => {
      if (coverage.lifeRating) {
       ratings = reducerHelper.getInforceRatingsFromCoverage(ratings, coverage, policyProduct, life.partyId);
      }
      inforceCoverage.ratings[life.partyId] = ratings;
      ratings = (coverage?.coverageType === eCoverageType.combined)? { ...eDefaultRatings } : ratings;
    });
    newState[firstScenarioTabId][inforceCoverage.coverageId] = inforceCoverage;
  });
  return newState;
};

const getTermOptionFaceAmount = (coverage, allCoverages) => {
  const ytiCoverage = allCoverages.find(
    (cvg) =>
      cvg.parentCoverageId === coverage.coverageId &&
      cvg.status === eBusinessType.IF &&
      cvg.product === eEnhancerType.YTI
  );

  const puiCoverage = allCoverages.find(
    (cvg) =>
      cvg.parentCoverageId === coverage.coverageId &&
      cvg.status === eBusinessType.IF &&
      cvg.product === eEnhancerType.PUI
  );

  return (ytiCoverage?.coverageAmount ?? 0) + (puiCoverage?.coverageAmount ?? 0);
};

const getTermOptionFaceAmountRaw = (coverage, allCoverages) => {
  const ytiCoverage = allCoverages.find(
    (cvg) =>
      cvg.parentCoverageId === coverage.coverageId &&
      cvg.status === eBusinessType.IF &&
      cvg.product === eEnhancerType.YTI
  );
  return ytiCoverage?.coverageAmount ?? 0;
};

const getTermOptionPlusFaceAmount = (coverage, allCoverages) => {
  const ytiPlusCoverage = allCoverages.find(
    (cvg) =>
      cvg.parentCoverageId === coverage.coverageId &&
      cvg.status === eBusinessType.IF &&
      cvg.product === eEnhancerType.YTIPlus
  );

  return ytiPlusCoverage?.coverageAmount ?? 0;
};

const shouldDisplayTOP = (coverage, allCoverages) => {
  return allCoverages.find(
    (cvg) =>
      cvg.parentCoverageId === coverage.coverageId &&
      cvg.status === eBusinessType.IF &&
      cvg.product === eEnhancerType.YTIPlus
  );
};

const loadInforceCoverage = (inforceCoverage, coverage, policyYear, allCoverages, policyProduct) => {
  inforceCoverage.coverageId = coverage.coverageId;
  inforceCoverage.coverageType = coverage.coverageType;
  inforceCoverage.coverageAmount = coverage.coverageAmount;
  inforceCoverage.effectiveDate = coverage.effectiveDate;
  inforceCoverage.equivalentAge = coverage.equivalentAge;
  inforceCoverage.healthstyle = coverage.healthstyle;
  inforceCoverage.costDuration = coverage.product;
  inforceCoverage.premiumDuration = coverage.product;
  inforceCoverage.life = coverage.life;
  inforceCoverage.dividendOption = coverage?.dividendOption?.type
    ? coverage?.dividendOption?.type
    : defaultCoverage?.dividendOption;
  if ([eProduct.FT, eProduct.BT].includes(policyProduct)) {
    inforceCoverage.coverageOption = coverage.product; //used for term policies
  }

  inforceCoverage.topFaceAmount = getTermOptionPlusFaceAmount(coverage, allCoverages);
  inforceCoverage.shouldDisplayTOP = shouldDisplayTOP(coverage, allCoverages);
  inforceCoverage.associatedCoverageId = coverage?.associatedCoverageId;
  inforceCoverage.toFaceAmount = getTermOptionFaceAmount(coverage, allCoverages);
  inforceCoverage.toFaceAmountRaw = getTermOptionFaceAmountRaw(coverage, allCoverages);
  inforceCoverage.toFaceAmountFromPS = inforceCoverage.toFaceAmount; // client cannot exceed the original amount
  inforceCoverage.topFaceAmountFromPS = inforceCoverage.topFaceAmount; // client cannot exceed the original amount

  inforceCoverage.performanceCreditOption = coverage.dividendOption?.type;
  inforceCoverage.switchToCashDividendsYear = policyYear;

  if (coverage.costOfInsurance) {
    inforceCoverage.costOfInsurance = coverage.costOfInsurance.type;
  }
  if (coverage.deathBenefit) {
    inforceCoverage.deathBenefitType = coverage.deathBenefit.type;
    inforceCoverage.deathBenefit = coverage.deathBenefit.percentage;
  }
  if (coverage.amountOfInsuranceType) {
    inforceCoverage.amountOfInsuranceType = coverage.amountOfInsuranceType;
  } else {
    inforceCoverage.amountOfInsuranceType = eDepositType.specified;
  }
  // Setting values to work with UI
  if (inforceCoverage.coverageType === eCoverageType.jointLast) {
    inforceCoverage.coverageType = eCoverageType.jointLastPayLastDeath;
  }
  return inforceCoverage;
};

const getCoverage = (curCovType, singleType, combinedType, prevCovType) =>
  isTermJoint(curCovType) && [singleType, combinedType].indexOf(prevCovType) >= 0;

const setCoverageType = (curCovType, combinedType, prevCovType, singleType, product) => {
  // reset the ratings if:
  // - non-PAR AND going from joint to combined
  //   OR going from combined to single
  //   OR going from (single, combined) to joint
  // - PAR AND going from single to joint
  const currentCombinedJointCoverage = curCovType === combinedType && isTermJoint(prevCovType);
  const currentSinglePreviousCombinedCoverage = curCovType === singleType && prevCovType === combinedType;
  const currentJointLastDeathParPreviousSingleCoverage =
    product === eProduct.PAR && curCovType === eCoverageType.jointLastPayLastDeath && prevCovType === singleType;
  const nonParCoverage =
    product !== eProduct.PAR &&
    (currentCombinedJointCoverage ||
      currentSinglePreviousCombinedCoverage ||
      getCoverage(curCovType, singleType, combinedType, prevCovType));
  return { currentJointLastDeathParPreviousSingleCoverage, nonParCoverage };
};

const getCoverageTypeInformation = (state, scenarioTabId, coverageTabId, value, newCoverage, product, newState) => {
  const prevState = state[scenarioTabId][coverageTabId];
  const prevCovType = prevState.coverageType;
  const curCovType = value;
  const combinedType = eCoverageType.combined;
  const singleType = eCoverageType.single;
  const newRatings = _.cloneDeep(newCoverage.ratings);

  if (product !== eProduct.PAR) {
    if (isTermJoint(curCovType)) {
      newCoverage[eField.covOption] = eCoverageOption.tlife;
    }
    // combined type doesn't have Term Life option so set to t10
    if (curCovType === combinedType && prevState.coverageOption === eCoverageOption.tlife) {
      newCoverage[eField.covOption] = eCoverageOption.t10;
    }
  }
  const { currentJointLastDeathParPreviousSingleCoverage, nonParCoverage } = setCoverageType(
    curCovType,
    combinedType,
    prevCovType,
    singleType,
    product
  );
  if (nonParCoverage || currentJointLastDeathParPreviousSingleCoverage) {
    _.forOwn(newRatings, (ratings, key) => {
      newRatings[key] = eDefaultRatings;
    });
    return getCoverageWithNewRatings(newState, scenarioTabId, coverageTabId, newRatings);
  } else {
    return newState;
  }
};

const coverageTabNavsReducer4 = (state, action) => {
  switch (action.type) {
    case actionTypes.MWI_ON_CHANGE: {
      const { target, scenarioTabId, coverageTabId, field, value } = action.payload;

      if (target === eField.coverage) {
        const newState = {
          ...state,
          [scenarioTabId]: getNewCoverage(state, action, { [field]: value, coverageTabId }),
        };
        const newCoverage = newState[scenarioTabId][coverageTabId];
        return updateCoverageFields(action, newCoverage, newState, state);
      }
      return state;
    }
    case actionTypes.COVERAGE_INITIALIZE: {
      return { ...initialState };
    }

    case actionTypes.LOADED_INFORCE_POLICY: {
      const illustration = action.payload.policyData.illustration;
      const coverages = illustration.policy.coverage;
      const product = illustration.policy.product;
      const policyYear = getPolicyYear(illustration.policy.effectiveDate);
      const newState = loadInforceCoverages(coverages, product, policyYear);

      return {
        ...newState,
      };
    }

    default:
      return coverageTabNavsReducer5(state, action);
  }
};

const updateCoverageFields = (action, newCoverage, newState, state) => {
  const { scenarioTabId, coverageTabId, product, field, value } = action.payload;
  if (field === eField.coverageAmount || field === eField.premiumAmount) {
    newCoverage[field] = +newCoverage[field];
    return newState;
  } else if (field === eField.covType) {
    return getCoverageTypeInformation(state, scenarioTabId, coverageTabId, value, newCoverage, product, newState);
  } else if (
    field === eField.premiumDuration &&
    value === ePremiumDuration.Pay90 &&
    newCoverage[eField.quoteOption] === eSpecialQuote.S2
  ) {
    newCoverage[eField.quoteOption] = eSpecialQuote.none;
    return newState;
  } else if (field === eLifecheque.lcCoverageOption || field === eLifecheque.lcPremiumDuration) {
    return updateLifechequeCoverageFields(action, newCoverage, newState, state);
  } else if (field === eField.isVitalityPlusSelected) {
    // make sure all coverages have the same value
    const newScenario = newState[newState.activeTabId];
    newScenario.tabNavs.forEach((covNav) => {
      newScenario[covNav].isVitalityPlusSelected = value;
    });
    return newState;
  } else {
    return newState;
  }
};

const updateLifechequeCoverageFields = (action, newCoverage, newState, state) => {
  const { scenarioTabId, coverageTabId, field, value } = action.payload;
  if (field === eLifecheque.lcCoverageOption) {
    newCoverage[eField.covOption] = newCoverage[field];

    // On change of coverage option set all the return on premiums to: true, false, false
    const lcReturnPremiums = {
      ropd: true,
      rops: false,
      ropx: false,
    };
    newCoverage[eLifecheque.lcReturnPremium] = lcReturnPremiums;

    // Initialize premium durations at PayTo100 when Permanent is not selected
    if (value === eLifecheque.lcCoverageOptions.lcPermanent) {
      if (value !== state[scenarioTabId][coverageTabId][eLifecheque.lcCoverageOption]) {
        newCoverage[eLifecheque.lcPremiumDuration] = eLifecheque.lcPremiumDurations.lcPayToAge100;
      }
    } else {
      newCoverage[eLifecheque.lcPremiumDuration] = eLifecheque.lcPremiumDurations.lcPayToAge100;
    }
    return newState;
  } else if (field === eLifecheque.lcPremiumDuration && value === eLifecheque.lcPremiumDurations.lcPayFor15) {
    // For Lifecheque if Pay15 is selected, set all coverages to it
    const newScenario = newState[newState.activeTabId];
    newScenario.tabNavs.forEach((covNav) => {
      newScenario[covNav].lcCoverageOption = eLifecheque.lcCoverageOptions.lcPermanent;
      newScenario[covNav].lcReturnPremiums.ropx = false;
      newScenario[covNav].lcPremiumDuration = value;
    });
    return newState;
  } else {
    return newState;
  }
};

const getLoadedUdmParameters = (action, state) => {
  const { response, scenarioTabId } = action.payload;
  const activeScenario = state[scenarioTabId];
  const activeCoverage = activeScenario[activeScenario.activeTabId];
  const udmParty = response && response.illustration && response.illustration.party;
  const udmPolicy = response && response.illustration && response.illustration.policy;
  return { scenarioTabId, activeCoverage, udmParty, udmPolicy };
};

const getEquivalentAgeFromUdm = (udmPolicy, activeCoverage) => {
  // Inforce policies (Not-FT) can re-use the existing equivalent age.
  // This may change in the future, but not today.
  if (
    udmPolicy.businessType === eBusinessType.IF &&
    udmPolicy.product !== eProduct.FT &&
    activeCoverage.equivalentAge
  ) {
    return activeCoverage.equivalentAge;
  }
  return udmPolicy && udmPolicy.coverage[0] && udmPolicy.coverage[0].equivalentAge;
};

const getPremiumAmount = (udmPolicy, state, activeCoverage, action) => {
  if (state.product === eProduct.PAR && udmPolicy.solve) {
    if (isUDMResponseValidationExist(action.payload.response, 'CoverageSolveMinimumPremium')) {
      // Par Cvg Solve falls below minimum requirement, update with backend
      return udmPolicy.initialModalPremium;
    } else {
      // Par Cvg Solve is above minimum requirement, preserve user entered value to prevent diminishing amount (CDTWIPO-8292)
      return activeCoverage.premiumAmount;
    }
  } else {
    // Keep existing logic for non cvg solve cases
    return udmPolicy.minimumModalPremium || activeCoverage.premiumAmount;
  }
};

const coverageTabNavsReducer5 = (state, action) => {
  switch (action.type) {
    // If solve type is Coverage, coverage and premium amount should be updated with calculated values
    // Premium amount can be different from what an advisor enters after calculation
    case actionTypes.LOADED_UDM_RESPONSE: {
      const { scenarioTabId, activeCoverage, udmParty, udmPolicy } = getLoadedUdmParameters(action, state);

      if (!udmPolicy) {
        return state;
      }
      const equivalentAge = getEquivalentAgeFromUdm(udmPolicy, activeCoverage);

      const minClientAge = getMinClientAge(udmParty);

      const activeUDMCoverage = _.find(
        udmPolicy.coverage,
        (coverage) => coverage.coverageId === state[scenarioTabId].activeTabId
      );
      const calcCoverageAmount = calculateMWINumberFieldValue(
        activeUDMCoverage.coverageAmount || activeCoverage.coverageAmount,
        coverageConfig.minAmount,
        coverageConfig.maxAmount
      );
      const calcPremiumAmount = getPremiumAmount(udmPolicy, state, activeCoverage, action);
      const newState = {
        ...state,
        [scenarioTabId]: getNewCoverage(state, action, {
          [eField.coverageAmount]: calcCoverageAmount,
          [eField.premiumAmount]: calcPremiumAmount,
          minClientAge,
          equivalentAge,
        }),
      };

      if (udmPolicy.product === eProduct.PAR) {
        const newCoverage = newState[scenarioTabId][newState[scenarioTabId].activeTabId];
        if (newCoverage.switchToCashDividendsYear > udmPolicy.duration) {
          newCoverage.switchToCashDividendsYear = udmPolicy.duration;
        }
      }

      return newState;
    }

    case actionTypes.LOAD_COVERAGE_TABNAVS: {
      return { ...state };
    }

    default:
      return coverageTabNavsReducer6(state, action);
  }
};

const coverageTabNavsReducer6 = (state, action) => {
  switch (action.type) {
    // TODO should update ratings for all coverages
    case actionTypes.RESET_RATINGS: {
      const { scenarioTabId, thisClient, healthstyle, otherClient } = action.payload;
      const coverageState = state[scenarioTabId];
      const coverageType = coverageState[coverageState.activeTabId].coverageType;

      if (isGoodRate(healthstyle)) {
        let newRatings = _.cloneDeep(coverageState[coverageState.activeTabId].ratings);

        if (!isTermJoint(coverageType)) {
          newRatings = { ...newRatings, [thisClient.id]: eDefaultRatings };
        } else if (isGoodRate(otherClient.healthstyle)) {
          newRatings = {
            ...newRatings,
            [thisClient.id]: eDefaultRatings,
            [otherClient.id]: eDefaultRatings,
          };
        } else {
          return state;
        }

        return getCoverageWithNewRatings(state, scenarioTabId, coverageState.activeTabId, newRatings);
      } else {
        return state;
      }
    }
    case actionTypes.SAVE_RATINGS: {
      const { scenarioTabId, coverageTabId, clientId, ratings } = action.payload;

      let newRatings = _.cloneDeep(state[scenarioTabId][coverageTabId].ratings);
      newRatings = { ...newRatings, [clientId]: ratings };

      return getCoverageWithNewRatings(state, scenarioTabId, coverageTabId, newRatings);
    }
    case actionTypes.INITIALIZE_TESTS: {
      const { scenarioTabId, CoverageTabId, isVitality } = action.payload;
      return {
        activeTabId: scenarioTabId,
        [scenarioTabId]: {
          scenarioId: scenarioTabId,
          activeTabId: CoverageTabId,
          coverageIndex: 1,
          isMultiCov: false,
          tabNavs: [CoverageTabId],
          [CoverageTabId]: {
            id: CoverageTabId,
            ...defaultCoverage,
            isVitalityPlusSelected: isVitality,
          },
        },
      };
    }
    case actionTypes.COMMON_INITIALIZE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default coverageTabNavsReducer;
export const getTabNavs = (state) => state.tabNavs;
export const getActiveTab = (state) => state.activeTab;
