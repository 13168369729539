import { eProduct, eCoverageType, eCOIType, withdrawalMaxPolicyEndYear } from '../../core/configs';
import { calculateMWINumberFieldValue} from '../../core/utils';

//AGE CALCULATION

export const ageFromMinimum = (product, costOfInsurance, selectedClientAge) => {
  const minimumClientAgeTo100 = 2;
  const minimumClientAge = 3;
  if (product === eProduct.MUL) {
    return costOfInsurance === eCOIType.levelToAge100 ?
        +(selectedClientAge + minimumClientAgeTo100) : +(selectedClientAge + minimumClientAge);
  } else {
    return +(selectedClientAge + minimumClientAgeTo100);
  }
};

export const ageFromMaximimun = (coverageType, equivalentAge, selectedClientAge) => {
  const maxCoverageAge = 100;
  return coverageType === eCoverageType.single ?
      maxCoverageAge : +(maxCoverageAge - equivalentAge + selectedClientAge);
};

export const ageToMinimum = (ageFromValue) => +ageFromValue;

export const ageToMaximimun = (coverageType, equivalentAge, selectedClientAge) => {
  const maxCoverageAge = 100;
  return coverageType === eCoverageType.single ?
      +maxCoverageAge : +(maxCoverageAge - equivalentAge + selectedClientAge);
};

//YEAR CALCULATION

export const yearFromMinimum = (product, costOfInsurance) => {
  const minimumInsuranceAgeTo100 = 2;
  const minimumInsuranceAge = 3;
  if (product === eProduct.MUL) {
    return costOfInsurance === eCOIType.levelToAge100 ? +minimumInsuranceAgeTo100 : +minimumInsuranceAge;
  } else {
    return +minimumInsuranceAgeTo100;
  }
};

export const YearFromMaximimun = (duration) => +duration;

export const yearToMinimum = (yearFromValue) => +yearFromValue;

export const yearToMaximimun = (duration) => +duration;

export const getAgeFromValue = (product, costOfInsurance, coverageType, equivalentAge, selectedClientAge, value) => {
  return calculateMWINumberFieldValue(
    value,
    ageFromMinimum(product, costOfInsurance, selectedClientAge),
    ageFromMaximimun(coverageType, equivalentAge, selectedClientAge)
  );
};

export const getAgeToValue = (coverageType, equivalentAge, selectedClientAge, value, ageFromValue) => {
  return calculateMWINumberFieldValue(
    value,
    ageToMinimum(ageFromValue),
    ageToMaximimun(coverageType, equivalentAge, selectedClientAge),
    true
  );
};

export const getYearFromValue = (product, costOfInsurance, duration, value) =>
    calculateMWINumberFieldValue(value, yearFromMinimum(product, costOfInsurance), YearFromMaximimun(duration));

export const getYearToValue = (duration, value, yearFromValue) =>
    calculateMWINumberFieldValue(value, yearToMinimum(yearFromValue), yearToMaximimun(duration), true);

export const getPGYearFromToValue = (product, policyYear, equivalentAge, value) =>
    calculateMWINumberFieldValue(value, policyYear, withdrawalMaxPolicyEndYear[product]-equivalentAge);
