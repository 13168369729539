import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { dropDownOptions } from '../../dropdown-config';
import MWINumberField from '../../components/mwi-calculated-number-field';
import { eCoverageType, coverageConfig, eField, eProduct, eLocale, eUserType } from '../../../core/configs';
import MWIDropdown from '../../../core/components/mwi-dropdown';
import SpecialQuote from '../special-quote';
import PremiumFrequency from '../../../core/components/premium-frequency';
import './term-coverage-container.css';

export class TermCoverageContainer extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    coverageTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }).isRequired
    ),
    product: PropTypes.string,
    effectiveDate: PropTypes.string,
    userType: PropTypes.string,
    coverage: PropTypes.object,
    firstCoverageTab: PropTypes.object,
    activeCoverageId: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    isVitalityPlusSelected: PropTypes.bool,
    inforce: PropTypes.bool,
  };

  isJoint(coverageType) {
    return [eCoverageType.jointFirst, eCoverageType.jointLast].indexOf(coverageType) >= 0;
  }

  //Select the number format value once get focused
  handleFocus = (event) => event.target.select();

  setInforceJointCoverageType = (coverageType) => {
    return ([eCoverageType.jointLastPayLastDeath, eCoverageType.jointLastPayFirstDeath].includes(coverageType)) ? eCoverageType.jointLast : coverageType;
  };

  renderCoverageType = () => {
    const {
      locale,
      product,
      coverage: { coverageType, isVitalityPlusSelected },
      coverageTypes,
      inforce,
    } = this.props;
    return (
      <MWIDropdown
        name="coverageType"
        label={<FormattedMessage id="coverage.type" />}
        value={inforce ? this.setInforceJointCoverageType(coverageType) : coverageType}
        options={coverageTypes}
        disabled={product === eProduct.FTV || isVitalityPlusSelected || inforce}
        onChange={(e) => this.props.onChange(eField.covType, e.target.value, coverageType)}
        containerStyle={`space-right ${
          locale === eLocale.fr ? 'p-col-8 p-lg-6 p-sm-6' : 'p-col-6 p-lg-7 p-xl-5 p-sm-6'
        }`}
        role="coverageType-dropdown"
      />
    );
  };

  rendercoverageOption = () => {
    const {
      intl,
      coverage: { coverageType, coverageOption },
      inforce,
    } = this.props;
    const { coverageOptions } = dropDownOptions(intl);
    const filteredCoverageOptions = coverageOptions.filter(
      (option) => option.coverageTypes.indexOf(this.props.coverage.coverageType) >= 0
    );
    return (
      <MWIDropdown
        name="coverageOption"
        label={<FormattedMessage id="coverage.option" />}
        value={coverageOption}
        options={filteredCoverageOptions}
        disabled={this.isJoint(coverageType) || inforce}
        onChange={(e) => this.props.onChange(eField.covOption, e.target.value)}
        containerStyle={`${
          inforce ? 'p-col-4 p-lg-4 p-xl-5 p-sm-6' : 'p-col-4 p-lg-4 p-xl-3 p-sm-6'
        } space-right`}
        role="coverageOption-dropdown"
      />
    );
  };

  renderSpecialQuote = () => {
    const {
      intl,
      userType,
      coverage: { specialQuoteOption, isVitalityPlusSelected },
      inforce,
    } = this.props;
    const { specialQuoteOptionTerm } = dropDownOptions(intl);

    return (
      userType === eUserType.headOffice && !inforce && (
        <SpecialQuote
          specialQuoteValue={specialQuoteOption}
          specialQuoteOptions={specialQuoteOptionTerm}
          onChange={this.props.onChange}
          containerStyle={`p-col-4 p-lg-4 p-xl-3 p-sm-6 space-right top-margin-24`}
          dropdownStyle=""
          isDisabled={isVitalityPlusSelected}
          role="coverage-specialquote"
        />
      )
    );
  };

  rendercoverageAmount = () => {
    const {
      locale,
      coverage: { coverageAmount, isCoverageSolve, errors },
      inforce,
    } = this.props;
    return (
      <MWINumberField
        id={eField.coverageAmount}
        name={eField.coverageAmount}
        label={<FormattedMessage id="coverage.coverageAmount" />}
        containerClass={`mwi-widget mwi-input number-data ${!errors.coverageAmount ? '' : 'mwi-error-field'}`}
        onBlur={this.props.onBlur}
        value={coverageAmount}
        prefix={locale === eLocale.en ? '$ ' : ''}
        suffix={locale === eLocale.fr ? ' $' : ''}
        thousandSeparator={locale === eLocale.en ? ',' : ' '}
        disabled={isCoverageSolve || inforce}
        locale={locale}
        minValue={coverageConfig.minAmount}
        maxValue={coverageConfig.maxAmount}
      />
    );
  };

  render = () => {
    const { locale, coverage, firstCoverageTab, inforce } = this.props;

    return (
      <React.Fragment>
        <div className="flexdisplay">
          <div className="p-grid p-grid-no-margin-all p-col-12">
            {this.renderCoverageType()}
            {this.rendercoverageOption()}
            {this.renderSpecialQuote()}
          </div>

          <div className="p-grid p-grid-no-margin-all p-col-12">
            <div
              className={`space-right mwi-control-wrapper mwi-margin-top18 ${
                locale === eLocale.fr ? 'p-col-8 p-lg-6 p-sm-6' : 'p-col-6 p-lg-7 p-xl-5 p-sm-6'
              }`}
            >
              {this.rendercoverageAmount()}
            </div>
            {(!inforce || (inforce && coverage.coverageId === firstCoverageTab.coverageId)) && (
            <div className={`p-col-4 mwi-margin-top18 p-lg-4 p-xl-3 p-sm-6 space-right11`}>
              <PremiumFrequency />
            </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const firstCoverageId = coverageState.tabNavs[0];
  const activeCoverageId = coverageState.activeTabId;
  const firstCoverageTab = coverageState[firstCoverageId];

  return {
    locale: app.locale,
    product: activeScenario.product,
    userType: app.userType,
    effectiveDate: activeScenario.effectiveDate,
    firstCoverageTab,
    activeCoverageId,
    inforce: app.inforcePolicy,
  };
};

export default connect(mapStateToProps)(injectIntl(TermCoverageContainer));