import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ViewReportButton from '../view-report';
import MWIButton from '../mwi-button';
import ReactModal from 'react-modal';
import ErrorPanel from './error-panel';
import {
  configs,
  eLocale,
  eDefault,
  eReqStatus,
  eProduct,
  eLang,
  getAdvisorPortalEndpoint,
  getContactUsEndpoint,
  eBusinessType,
} from '../../configs';
import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import * as ProfileActions from '../../../profile/actions';
import {
  isIOS,
  headerLinkValidator,
  trackPageView,
  isIE,
  getProdCoverageOption,
  getDataLayerProductInfoFromState,
  prepareInforceRequestFormPayload,
} from '../../utils';
import CaseSaveModel from '../save-case-model';
import _ from 'lodash';
import './header.css';
import enLogo from '../../../../src/assets/images/manulife-logo-en.jpg';
import frLogo from '../../../../src/assets/images/manulife-logo-fr.jpg';
import { MWILink } from '../mwi-link';
import { logout, createInforceRequestForm } from '../../udm-mapper/sideEffect';
import OpenedSaveModel from '../opened-case';
import { UtilityHeader, ContactUs, Lang, Search, SignIn, SignInMenuItem } from '@manulife/mux';
import { Compare } from '@manulife/mux-cds-icons';
import ShareIllustrationModel from '../share-illustration-model';
import { isSummaryAmountsHidden } from '../../../custom-mode/summary/utils';

export class Header extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    app: PropTypes.object,
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    product: PropTypes.string,
    report: PropTypes.object,
    myCases: PropTypes.object,
    udmResponse: PropTypes.object,
    advisorProfile: PropTypes.object,
    productInfo: PropTypes.object,
    userID: PropTypes.string,
    userType: PropTypes.string,
    ciamId: PropTypes.string,

    history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    profileActions: PropTypes.object,
    intl: PropTypes.object,
    location: PropTypes.object,
    selectedProduct: PropTypes.string,
    accessible: PropTypes.bool,
    coverage: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      isProfileMenuOpen: false,
      showCaseSave: false,
      isSaveAs: false,
      isDuplicate: false,
    };

    this.profileMenuNode = React.createRef();

    this.refPageTitle = React.createRef();
    this.gotoIllustrations = this.gotoIllustrations.bind(this);
    this.gotoCalculator = this.gotoCalculator.bind(this);
    this.gotoHome = this.gotoHome.bind(this);
    this.gotoMyCases = this.gotoMyCases.bind(this);
    this.toggleLang = this.toggleLang.bind(this);
    this.openReportDesign = this.openReportDesign.bind(this);
    this.openSpreadsheet = this.openSpreadsheet.bind(this);
    this.openCustomModeDesign = this.openCustomModeDesign.bind(this);
    this.openAdvisorProfile = this.openAdvisorProfile.bind(this);
    this.toggleProfileMenu = this.toggleProfileMenu.bind(this);
    this.handleClickForProfileMenu = this.handleClickForProfileMenu.bind(this);
    this.showSaveDialog = this.showSaveDialog.bind(this);
    this.hidemySaveCase = this.hidemySaveCase.bind(this);
    this.setDuplicate = this.setDuplicate.bind(this);
    this.showShareIllustrationDialog = this.showShareIllustrationDialog.bind(this);
    this.hideMyShareIllustrationDialog = this.hideMyShareIllustrationDialog.bind(this);
    this.openInforceRequestForm = this.openInforceRequestForm.bind(this);
  }

  componentDidMount() {
    const {
      locale,
      location,
      app: { ciamId, role },
    } = this.props;
    window.addEventListener('resize', this.updateWindowDimensions);
    if (process.env.NODE_ENV !== 'test') {
      ReactModal.setAppElement('#mwi-root');
    }
    // Track the page for the first time
    const currentPage = _.findKey(configs.routes, (routes) => routes === location.pathname);
    trackPageView(
      currentPage,
      locale,
      {},
      {
        id: ciamId,
        type: role,
      }
    );
  }

  componentDidUpdate(prevProps) {
    const {
      locale,
      location,
      productInfo,
      app: { ciamId, role, inforcePolicy },
    } = this.props;
    productInfo.coverageOption = getProdCoverageOption(productInfo.product, this.props.coverage);
    productInfo.producttype = inforcePolicy ? eBusinessType.IF : eBusinessType.NB;
    if (location.pathname !== prevProps.location.pathname) {
      //Track the page every time the location changed
      const currentPage = _.findKey(configs.routes, (routes) => routes === location.pathname);
      trackPageView(currentPage, locale, productInfo, {
        id: ciamId,
        type: role,
      });
      const iOsDelay = 550;
      const delay = 300;
      //TO DO : Enhancement
      // delay to make sure the page title changed because children component render after.
      setTimeout(this.setPageTitleFocus, isIOS() ? iOsDelay : delay, this.refPageTitle);

      // Ensure the viewport returns to the top of the document window
      window.scrollTo(0, 0);
    }

    if (prevProps.locale !== locale) {
      //Track the page every time the location changed
      const currentPage = _.findKey(configs.routes, (routes) => routes === location.pathname);
      trackPageView(currentPage, locale, productInfo, {
        id: ciamId,
        type: 'NA',
      });
    }
    if (ciamId !== '' || role === 'ADV') {
      const currentPage = _.findKey(configs.routes, (routes) => routes === location.pathname);
      trackPageView(currentPage, locale, productInfo, {
        id: ciamId,
        type: role,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickForProfileMenu, false);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  dispatchAction(field, value, target = 'header', isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
      userId: this.props.userID,
    });
  }

  setPageTitleFocus(refComponent) {
    // Set focus to the content container
    refComponent.current.innerHTML = document.title + ' page';
    refComponent.current.focus();
  }

  mainContentSkipReference() {
    const curPath = this.props.location.pathname;

    switch (curPath) {
      // client coverage and spreadsheet page have the same main-content element
      case configs.routes.home: {
        return 'landing-main-content';
      }

      case configs.routes.clientCoverage:
      case configs.routes.spreadsheet: {
        return 'main-content-clientCoverage';
      }

      case configs.routes.designReport:
      case configs.routes.profile: {
        return 'main-content-titlebar';
      }
      default: {
        return 'main-content-home';
      }
    }
  }

  gotoIllustrations() {
    //TODO CHECK WHAT THE DIFFERENT BETWEEN REDIRECT THE USER TO CLIENCOVERAGE DIRECTLY OR CUSTOM
    this.props.history.push(configs.routes.clientCoverage);
  }

  gotoCalculator() {
    this.props.history.push(configs.routes.termRenewalCalc);
  }

  gotoHome() {
    this.props.history.push(configs.routes.home);
  }

  gotoMyCases() {
    this.props.history.push(configs.routes.myCases);
  }

  async performLogout(locale = eLocale.en) {
    await logout(locale);
  }

  toggleLang() {
    const { locale, location, appActions, sceActions, selectedProduct, product, inforce, coverage } = this.props;
    const lang = locale === eLocale.en ? eLocale.fr : eLocale.en;
    appActions.toggleLanguage(lang);
    this.dispatchAction('languageChange', true, 'app');

    if (!isSummaryAmountsHidden(product, inforce) && selectedProduct !== eProduct.FTRC) {
      // for coverage solve, request summary as validations depend on PX results
      sceActions.performManualRefresh(coverage.isCoverageSolve);
      if (
        location.pathname !== configs.routes.home &&
        location.pathname !== configs.routes.myCases &&
        location.pathname !== configs.routes.spreadsheet
      ) {
        // Do not make a network call on the landing page
        sceActions.requestUDMResponse();
      }
    }
  }

  openSpreadsheet() {
    this.props.history.push(configs.routes.spreadsheet);
  }

  openInforceRequestForm() {
    const inforceRequestFormURL = configs.endPoints.inforceRequestForm.create;
    const irfData = { ...this.props.app };
    const payload = prepareInforceRequestFormPayload(irfData);
    // Make Call - Set IsLoading
    this.props.appActions.loadingInforceRequestForm({ ...this.state });
    // Get Response
    createInforceRequestForm(payload, inforceRequestFormURL)
      .then(this.processInforceRequestForm)
      .catch((error) => {
        this.props.appActions.errorInforceRequestForm({ ...this.state, error });
      });
  }

  processInforceRequestForm = (response) => {
    if (response?.indexOf('Error') !== -1 || !response?.toLocaleLowerCase().startsWith('http', 0)) {
      throw response;
    }

    this.props.appActions.loadedInforceRequestForm(response);
    window.open(response);
  };

  openReportDesign() {
    this.props.history.push(configs.routes.designReport);
  }

  openCustomModeDesign() {
    this.props.history.push(configs.routes.clientCoverage);
  }

  openAdvisorProfile(e) {
    const menu = e.target.getAttribute('data-menu');

    this.props.profileActions.openAdvisorProfile({
      menu,
    });
    this.props.history.push(configs.routes.profile);
  }

  handleClickForProfileMenu(e) {
    if (!this.profileMenuNode) {
      return;
    }

    if (this.profileMenuNode.contains(e.target)) {
      this.openAdvisorProfile(e);
    }

    this.toggleProfileMenu();
  }

  toggleProfileMenu() {
    if (!this.state.isProfileMenuOpen) {
      document.addEventListener('click', this.handleClickForProfileMenu, false);
    } else {
      document.removeEventListener('click', this.handleClickForProfileMenu, false);
    }

    this.setState((prevState) => {
      return { isProfileMenuOpen: !prevState.isProfileMenuOpen };
    });
  }

  showSaveDialog(isSaveAs = false) {
    this.setState({ showCaseSave: true, isSaveAs });
  }

  hidemySaveCase() {
    this.setState({ showCaseSave: false, isDuplicate: false });
  }

  showShareIllustrationDialog() {
    this.setState({ showShareIllustration: true });
  }

  hideMyShareIllustrationDialog() {
    this.setState({ showShareIllustration: false });
  }

  shortenName(name, length) {
    return name && name.length > length + 1 ? name.substring(0, length) + '...' : name;
  }

  getLanguageText(text) {
    let value = text;
    if (value === eDefault.advisorProfile) {
      value = _.find(configs.defaultProfile, ['id', this.props.locale]).value;
    }
    return value;
  }

  setDuplicate(value) {
    this.setState({ isDuplicate: value });
  }

  getHeaderState() {
    return {
      isDuplicate: this.state.isDuplicate,
      showCaseSave: this.state.showCaseSave,
      showShareIllustration: this.state.showShareIllustration,
    };
  }

  getSaveAsLink(currentPage, isValidationError) {
    const { intl } = this.props;
    return (
      headerLinkValidator(currentPage, 'saveas') && (
        <MWILink
          label={intl.formatMessage({ id: 'common.saveAs' })}
          onClick={() => {
            this.showSaveDialog(true);
          }}
          styleClass="p-col-4 header-link"
          disabled={isValidationError}
          intl={intl}
        />
      )
    );
  }

  getShareillustrationLink(currentPage, isValidationError) {
    const { intl } = this.props;
    return (
      headerLinkValidator(currentPage, 'shareillustration') && (
        <MWILink
          label={intl.formatMessage({ id: 'common.shareIllustration' })}
          onClick={() => {
            this.showShareIllustrationDialog();
          }}
          styleClass="p-col-4 header-link"
          disabled={isValidationError}
          intl={intl}
        />
      )
    );
  }

  getSpreadsheetLink(currentPage, isValidationError) {
    const { intl } = this.props;
    return (
      headerLinkValidator(currentPage, 'spreadsheet') && (
        <MWILink
          label={intl.formatMessage({ id: 'topbar.spreadsheet' })}
          onClick={this.openSpreadsheet}
          styleClass="p-col-4 header-link"
          disabled={isValidationError}
          openNewTab={false}
          intl={intl}
        />
      )
    );
  }

  getInforceRequestFormLink(currentPage, isValidationError) {
    const { intl } = this.props;
    return (
      headerLinkValidator(currentPage, 'inforcerequestform') && (
        <MWILink
          label={intl.formatMessage({ id: 'topbar.inforceRequestForm' })}
          onClick={this.openInforceRequestForm}
          styleClass="p-col-4 header-link"
          disabled={isValidationError}
          openNewTab
          intl={intl}
        />
      )
    );
  }

  getNeedHelpLink(currentPage, isValidationError) {
    const { intl, product } = this.props;
    const helpLink =
      (!this.props.inforce && product === eProduct.PAR) ||
      (this.props.inforce && [eProduct.FT, eProduct.BT].includes(product))
        ? 'topbar.help.link.par'
        : 'topbar.help.link';
    return (
      headerLinkValidator(currentPage, 'needhelp') && (
        <MWILink
          label={intl.formatMessage({ id: 'topbar.help' })}
          link={intl.formatMessage({ id: helpLink })}
          styleClass="p-col-4 header-link"
          disabled={isValidationError}
          openNewTab
          intl={intl}
        />
      )
    );
  }

  getCustomizeReportLink(currentPage, isValidationError) {
    const { intl } = this.props;
    return (
      headerLinkValidator(currentPage, 'customizereport') && (
        <MWILink
          label={intl.formatMessage({ id: 'common.customizereport' })}
          onClick={this.openReportDesign}
          styleClass="p-col-4 header-link"
          disabled={isValidationError}
          intl={intl}
        />
      )
    );
  }

  getViewReportButton(currentPage, isValidationError) {
    const { intl, locale } = this.props;
    return (
      headerLinkValidator(currentPage, 'viewreport') && (
        <ViewReportButton
          label={intl.formatMessage({ id: 'common.viewreport' })}
          styleClass={locale === eLang.en ? 'p-col-4 view-report' : ''}
          isDirectDownload
          disabled={isValidationError}
        />
      )
    );
  }

  getViewReportDropdownButton(currentPage, isValidationError) {
    const { locale, history } = this.props;
    return (
      headerLinkValidator(currentPage, 'viewreportdropdown') && (
        <ViewReportButton
          styleClass={locale === eLang.en ? 'p-col-4 view-report' : ''}
          isDirectDownload
          disabled={isValidationError}
          history={history}
          displayReportDropdown
        />
      )
    );
  }

  renderBaseHeader(currentPage) {
    const { locale, app, intl } = this.props;
    const enCurrentPageClassName = currentPage === 'designReport' ? 'p-col-6 p-lg-9 p-xl-6' : 'p-col-6 p-lg-8 p-xl-6';
    const frCurrentPageClassName = currentPage === 'designReport' ? 'p-col-7 p-lg-9 p-xl-7' : 'p-col-7 p-lg-8 p-xl-7';

    return (
      <div className="logo-bar p-grid" data-testid="logo-bar">
        <img
          className="logo"
          data-testid="logo-img"
          src={locale === eLocale.en ? enLogo : frLogo}
          aria-label={intl.formatMessage({ id: 'common.logo' })}
          alt={intl.formatMessage({ id: 'common.logo' })}
        />

        <div
          className={`${currentPage} ${locale === eLocale.en ? enCurrentPageClassName : frCurrentPageClassName} ${
            app.errorHappened ? 'tool-bar-error' : 'tool-bar'
          } ${isIE() ? 'ie' : ''}`}
          data-testid="tool-bar-links"
        >
          {this.renderHeaderLinks(currentPage)}
        </div>
      </div>
    );
  }

  renderHeaderLinks(currentPage) {
    const { intl, udmResponse } = this.props;
    const isValidationError = udmResponse.validationStatus.toUpperCase() === eReqStatus.error;
    const displaySpecialRequestLink =
      this.props.inforce && ![eProduct.MUL, eProduct.BT, eProduct.FT].includes(this.props.product);
    const displaySpreadsheet =
      (!this.props.inforce && ![eProduct.LC].includes(this.props.product)) ||
      (this.props.inforce && ![eProduct.BT, eProduct.FT].includes(this.props.product));
    return (
      <React.Fragment>
        {!this.props.inforce && this.getSaveAsLink(currentPage, isValidationError)}
        {!this.props.inforce && this.getShareillustrationLink(currentPage, isValidationError)}
        {displaySpecialRequestLink && this.getInforceRequestFormLink(currentPage, isValidationError)}
        {this.props.inforce && this.getNeedHelpLink(currentPage, isValidationError)}
        {displaySpreadsheet && this.getSpreadsheetLink(currentPage, isValidationError)}
        {this.getCustomizeReportLink(currentPage, isValidationError)}
        {headerLinkValidator(currentPage, 'report.back') && (
          <MWIButton
            label={intl.formatMessage({ id: 'report.back' })}
            onClick={this.openCustomModeDesign}
            styleClass="mwi-white-button p-col-4"
          />
        )}
        {this.getViewReportButton(currentPage, isValidationError)}
        {this.getViewReportDropdownButton(currentPage, isValidationError)}
      </React.Fragment>
    );
  }

  render() {
    const { locale, location, app, intl } = this.props;
    const { isSaveAs, isDuplicate } = this.state;
    const advisorPortalLabel = intl.formatMessage({ id: 'utilnav.advisorportal' });
    const advisorPortalLink = getAdvisorPortalEndpoint(intl);
    const contactUsLabel = intl.formatMessage({ id: 'utilnav.contactus' });
    const contactUsLink = getContactUsEndpoint(intl);
    const profileLabel = intl.formatMessage({ id: 'utilnav.profile' });
    const currentPage = _.findKey(configs.routes, (routes) => routes === location.pathname) || '';
    const saveIllustrationLabel = intl.formatMessage({ id: 'header.model.save.title' });
    const shareIllustrationLabel = intl.formatMessage({ id: 'header.model.share.title' });

    return (
      <div className={`${app.errorHappened ? 'header-error ' : ''}header`} role="header-test">
        {/* a Page title for the reader only, and we will set the focus here when a new page called */}
        <div className="sr-only" data-testid="sr-only" ref={this.refPageTitle} tabIndex="-1">
          {`${document.title} page`}
        </div>
        {/* UtilityHeader */}
        <div className="utility-header-container" data-testid="utility-header-container">
          <UtilityHeader id="utilnav-header" data-testid="utility-header">
            {/* repurpose search for advisor portal */}
            <Search
              id="utilnav.advisorportal"
              label={advisorPortalLabel}
              icon={<Compare />}
              onClick={() => window.open(advisorPortalLink)}
            />
            <ContactUs id="utilnav.contactus" label={contactUsLabel} onClick={() => window.open(contactUsLink)} />
            <SignIn style={{ backgroundColor: 'black', fontColor: 'white' }} id="utilnav.signin" label={profileLabel}>
              <SignInMenuItem
                id="utilnav.profile.signout"
                label={<FormattedMessage id="utilnav.profile.signout" />}
                onClick={() => {
                  this.performLogout();
                }}
              />
            </SignIn>
            <Lang
              id="utilnav-togglelang"
              data-testid="utilnav-togglelang"
              abbreviation={locale === eLocale.en ? eLocale.fr : eLocale.en}
              onClick={() => {
                this.toggleLang();
              }}
            />
          </UtilityHeader>
        </div>

        <div aria-live="assertive">{app.errorHappened && <ErrorPanel openHelp={this.openHelp} />}</div>

        {location.pathname !== configs.routes.myCases &&
          location.pathname !== configs.routes.home &&
          this.renderBaseHeader(currentPage)}

        <div className={`${currentPage}`}>
          <React.Fragment>
            {headerLinkValidator(currentPage, 'casename') && (
              <OpenedSaveModel title={`${saveIllustrationLabel} `} locale={locale} {...this.props} />
            )}
          </React.Fragment>
        </div>

        <ReactModal
          className={`caseSave-wrapper`}
          contentLabel={`${saveIllustrationLabel}`}
          isOpen={this.state.showCaseSave}
          shouldCloseOnOverlayClick={false}
          shouldFocusAfterRender
          shouldReturnFocusAfterClose
          onRequestClose={this.hidemySaveCase}
          role="dialog"
          aria={{ modal: 'true' }}
          ariaHideApp
        >
          <CaseSaveModel
            title={`${saveIllustrationLabel} `}
            save={this.hidemySaveCase}
            cancel={this.hidemySaveCase}
            locale={locale}
            isSaveAs={isSaveAs}
            isDuplicate={isDuplicate}
            setDuplicate={this.setDuplicate}
          />
        </ReactModal>
        <ReactModal
          id="shareIllustration-wrapper-id"
          className={`shareIllustration-wrapper`}
          contentLabel={`${shareIllustrationLabel}`}
          isOpen={this.state.showShareIllustration}
          shouldCloseOnOverlayClick={false}
          shouldFocusAfterRender
          shouldReturnFocusAfterClose
          onRequestClose={this.hideMyShareIllustrationDialog}
          role="dialog"
          aria={{ modal: 'true' }}
          ariaHideApp
        >
          <ShareIllustrationModel
            title={`${shareIllustrationLabel} `}
            locale={locale}
            cancel={this.hideMyShareIllustrationDialog}
          />
        </ReactModal>
      </div>
    );
  }
}

export const mapStateToProps = ({
  app,
  report,
  udmResponse,
  advisorProfile,
  myCases,
  scenarioTabNavs,
  coverageTabNavs,
  vitalityStatus,
}) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const product = activeScenario.product;
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const inforce = app.inforcePolicy;

  return {
    app,
    report,
    myCases,
    udmResponse,
    advisorProfile,
    coverage,
    product,
    inforce,
    userID: app.userID,
    selectedProduct: app.model ? app.model.scenarioTabNavs.topBars['Scenario.1'].product : '',
    accessible: app.accessible,
    locale: app.locale,
    productInfo: getDataLayerProductInfoFromState(coverage, vitalityStatus, product, inforce, activeScenario),
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  profileActions: bindActionCreators(ProfileActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header)));
