import { combineReducers } from 'redux';

import app from './appReducer';
import udmResponse from './udm-mapper/udmReducer';
import landingPage from '../landing-page/landingPageReducer';
import scenarioTabNavs from '../custom-mode/scenario-tab-navs/scenarioTabNavReducer';
import coverageTabNavs from '../custom-mode/coverage-tab-navs/coverageTabNavsReducer';
import conceptTabNavs from '../custom-mode/concepts/conceptReducer';
import riders from '../custom-mode/riders/riderReducer';
import clients from '../services/reducers/components/client/clientReducers';
import termRiders from '../custom-mode/term-riders/termRiderReducer';
import termOtherRiders from '../custom-mode/termOther-riders/termOtherRiderReducer';
import spousalRiders from '../custom-mode/spousal-riders/spousalRiderReducer';
import vitalityStatus from '../custom-mode/vitality-status/vitalityStatusReducer';
import report from '../report/reportReducer';
import advisorProfile from '../profile/profileReducer';
import spreadsheet from '../spreadsheet/spreadsheetReducer';
import myCases from '../mycases/myCasesReducer';
import withdrawals from '../custom-mode/withdrawal/withdrawalReducer';
import deposits from '../custom-mode/deposit/depositReducer';

const reducers = combineReducers({
  app,
  udmResponse,
  landingPage,
  scenarioTabNavs,
  coverageTabNavs,
  conceptTabNavs,
  clients,
  riders,
  termRiders,
  spousalRiders,
  termOtherRiders,
  vitalityStatus,
  report,
  spreadsheet,
  advisorProfile,
  myCases,
  withdrawals,
  deposits,
});

export default reducers;
