import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { dropDownOptions } from '../../dropdown-config';
import MWINumberField from '../mwi-calculated-number-field';
import { coverageConfig, eField, eLifecheque, eLocale, eProduct, eUserType } from '../../configs';
import MWIDropdown from '../mwi-dropdown';
import PremiumFrequency from '../premium-frequency';
import { LifechequeCheckboxContainer } from './lifecheque-checkbox-container';
import lc_learn_more_en from '../../../assets/images/lifecheque_learn_more_coverage_options_en.png';
import lc_learn_more_fr from '../../../assets/images/lifecheque_learn_more_coverage_options_fr.png';
import { bindActionCreators } from 'redux';
import * as RiderActions from '../../../custom-mode/riders/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import { eRider, eTabName } from '../../../core/configs';
import { trackActiveTab, getDataLayerProductInfoFromState } from '../../../core/utils';

export const LifechequeCoverageContainer = injectIntl((props) => {
  LifechequeCoverageContainer.propTypes = {
    activeCoverageId: PropTypes.string,
    coverage: PropTypes.object,
    firstCoverageTab: PropTypes.object,
    lcToggleClcCheckbox: PropTypes.bool,
    intl: PropTypes.object,
    locale: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    product: PropTypes.string,
    activeScenario: PropTypes.object,
  };

  // #region Function Variables & State
  const {
    activeCoverageId,
    activeScenarioTabId,
    activeScenario,
    activeTabViewTab,
    clients,
    coverage,
    inforce,
    intl,
    userType,
    coverage: {
      coverageAmount,
      errors,
      lcCoverageOption,
      lcPremiumDuration,
      lcReturnPremiums: { ropd, rops, ropx },
    },
  } = props;
  const { lcCoverageOptions, lcPremiumDurations } = dropDownOptions(intl);
  const locale = intl.locale;
  const maxAge = 60;

  // Set state to control enabling checkboxes and toggling checkboxes, true means they are disabled, false means enabled
  const [disableRopx, setDisableRopx] = useState(
    lcCoverageOption !== eLifecheque.lcCoverageOptions.lc65 && lcCoverageOption !== eLifecheque.lcCoverageOptions.lc75
  );
  const [disableRops, setDisableRops] = useState(
    lcCoverageOption !== eLifecheque.lcCoverageOptions.lc75 &&
      lcCoverageOption !== eLifecheque.lcCoverageOptions.lcPermanent
  );
  const [showPremiumDuration, setShowPremiumDuration] = useState(
    lcCoverageOption === eLifecheque.lcCoverageOptions.lcPermanent
  );
  // #endregion

  // #region UseEffects Functions, called when the array parameter is updated
  useEffect(() => {
    // When a scenario is changed, ensure the correct the ROPs are displaying correctly
    saveCheckBoxesReturnPremiums(ropd, rops, ropx);
    toggleCheckBoxes(lcCoverageOption);

    if (lcCoverageOption === eLifecheque.lcCoverageOptions.lc75) {
      if (ropx) {
        setDisableRops(ropx);
      }
      if (rops) {
        setDisableRopx(rops);
      }
    }
    togglePremiumDuration(lcCoverageOption);
    toggleChildrensLifechequeRider(lcPremiumDuration);
  }, [activeScenarioTabId]);

  useEffect(() => {
    toggleChildrensLifechequeRider(lcPremiumDuration);
  }, [lcPremiumDuration]);

  useEffect(() => {
    trackTabInDataLayer();
  }, [coverage]);

  // #endregion

  // #region Local Functions
  const getCoverageAmount = () => {
    return (
      <MWINumberField
        id={eField.coverageAmount}
        name={eField.coverageAmount}
        label={<FormattedMessage id="coverage.coverageAmount" />}
        containerClass={`mwi-widget mwi-input number-data ${!errors.coverageAmount ? '' : 'mwi-error-field'}`}
        onBlur={props.onBlur}
        value={coverageAmount}
        prefix={locale === eLocale.en ? '$ ' : ''}
        suffix={locale === eLocale.fr ? ' $' : ''}
        thousandSeparator={locale === eLocale.en ? ',' : ' '}
        locale={locale}
        minValue={coverageConfig.minAmount}
        maxValue={coverageConfig.maxAmount}
        styleClass={'p-col-6 p-lg-6 p-xl-4 p-sm-12 space-right'}
      />
    );
  };

  const getCoverageClient = () => {
    let covClient;
    for (const clientId of clients.allClients) {
      const client1 = clients[clientId];
      if (
        client1 &&
        client1.coverageTabId.length > 0 &&
        client1.coverageTabId.some((covId) => covId === activeCoverageId)
      ) {
        covClient = client1;
        break;
      }
    }
    return covClient;
  };

  const getProductInfo = (returnPremiums) => {
    if (returnPremiums) {
      coverage.lcReturnPremiums = returnPremiums;
    }
    return getDataLayerProductInfoFromState(coverage, '', eProduct.LC, inforce, activeScenario);
  };

  const onChange = (field) => (event) => {
    // Determine which value to use : If it is a dropdown use value, checkbox use checked
    const eventValue =
      field === eLifecheque.lcCoverageOption || field === eLifecheque.lcPremiumDuration
        ? event.target.value
        : event.target.checked;
    // Check which field was changed in dropdown, based on the field, toggle the checkboxes
    if (field === eLifecheque.lcCoverageOption) {
      // Update Rops checkboxes and toggle Premium Duration if Permanent is selected
      toggleCheckBoxes(eventValue);
      togglePremiumDuration(eventValue);
      props.onChange(field, eventValue);
    } else if (field === eLifecheque.lcPremiumDuration) {
      // If you change PremiumDuration to Pay15, disable CLC Rider and update all coverages to Pay 15
      props.onChange(field, eventValue);
    } else {
      toggleRopCheckBoxes(field, eventValue);
    }
    toggleChildrensLifechequeRider(eventValue);
  };

  const saveCheckBoxesReturnPremiums = (checkedRopd = true, checkedRops = false, checkedRopx = false) => {
    // If the dropdown is changed, set rops/ropx checkboxes to unchecked and ropd to checked
    const lcReturnPremiums = {
      ropd: checkedRopd,
      rops: checkedRops,
      ropx: checkedRopx,
    };
    props.onChange(eLifecheque.lcReturnPremium, lcReturnPremiums);
  };

  const setRopForHeadOfficeUser = (eventValue) => {
    if (userType === eUserType.headOffice && eventValue === eLifecheque.lcCoverageOptions.lc75) {
      const covClient = getCoverageClient();
      if (covClient && covClient.age > maxAge) {
        setDisableRops(true);
        setDisableRopx(true);
        const lcReturnPremiums = {
          ropd,
          rops: false,
          ropx: false,
        };
        props.onChange(eLifecheque.lcReturnPremium, lcReturnPremiums);
      }

      if (covClient && covClient.age <= maxAge && ropx !== true && rops !== true) {
        setDisableRops(false);
        setDisableRopx(false);
      }
    }
  };

  const toggleCheckBoxes = (eventValue) => {
    // If the selected dropdown is not Primary or Level, disable Ropx Checkbox
    const toggleRopxCheckbox =
      eventValue !== eLifecheque.lcCoverageOptions.lc65 && eventValue !== eLifecheque.lcCoverageOptions.lc75;
    setDisableRopx(toggleRopxCheckbox);

    // If the current dropdown is not Permanent or Level, disable Rops Checkbox
    const toggleRopsCheckbox =
      eventValue !== eLifecheque.lcCoverageOptions.lcPermanent && eventValue !== eLifecheque.lcCoverageOptions.lc75;
    setDisableRops(toggleRopsCheckbox);
    setRopForHeadOfficeUser(eventValue);
  };

  const toggleChildrensLifechequeRider = (eventValue) => {
    // Toggle the Children's Lifecheque Rider if Permanent Pay15 is selected
    if (eventValue === eLifecheque.lcPremiumDurations.lcPayFor15) {
      props.onChange('lcToggleClcCheckbox', false);
      props.riderActions.toggleRider({
        riderName: eRider.clc,
        isSelected: false,
        allClients: props.clients.allClients,
      });
    } else {
      props.onChange('lcToggleClcCheckbox', true);
    }
  };

  const toggleRopCheckBoxes = (field, checked) => {
    // If the checkboxes are enabled and clicked, toggle them
    const lcReturnPremiums = {
      ropd,
      rops,
      ropx,
    };

    if (field === eLifecheque.lcReturnPremiums.ropd) {
      lcReturnPremiums.ropd = checked;
    } else if (field === eLifecheque.lcReturnPremiums.ropx) {
      lcReturnPremiums.ropx = checked;
      if (
        lcCoverageOption === eLifecheque.lcCoverageOptions.lc75 ||
        lcCoverageOption === eLifecheque.lcCoverageOptions.lcPermanent
      ) {
        setDisableRops(checked);
      }
    } else {
      lcReturnPremiums.rops = checked;
      if (
        lcCoverageOption === eLifecheque.lcCoverageOptions.lc75 ||
        lcCoverageOption === eLifecheque.lcCoverageOptions.lc65
      ) {
        setDisableRopx(checked);
      }
    }
    props.onChange(eLifecheque.lcReturnPremium, lcReturnPremiums);
  };

  const togglePremiumDuration = (eventValue) => {
    // if coverage option is permanent, display premium duration, else hide it
    eventValue === eLifecheque.lcCoverageOptions.lcPermanent
      ? setShowPremiumDuration(true)
      : setShowPremiumDuration(false);
  };

  const trackTabInDataLayer = function (returnPremiums = false) {
    trackActiveTab(activeScenarioTabId, eTabName[activeTabViewTab], getProductInfo(returnPremiums));
  };
  // #endregion

  return (
    <React.Fragment>
      <div className="flexdisplay" id="lifechequeContainer" role="lifechequeContainer">
        <div className="p-grid p-col-12">
          <MWIDropdown
            name={eLifecheque.lcCoverageOption}
            options={lcCoverageOptions}
            value={lcCoverageOption}
            label={<FormattedMessage id="coverage.option" />}
            locale={locale}
            onChange={onChange(eLifecheque.lcCoverageOption)}
            intl={intl}
            containerStyle="p-col-6 p-lg-6 p-xl-4 p-sm-12 space-right"
            helpText
            imageAdress={locale === eLocale.fr ? lc_learn_more_fr : lc_learn_more_en}
            helpTextBanner={'lc.learn.more.coverage.options'}
          />
          {showPremiumDuration && (
            <MWIDropdown
              name={eLifecheque.lcPremiumDuration}
              label={<FormattedMessage id="coverage.premiumduration" />}
              options={lcPremiumDurations}
              value={lcPremiumDuration}
              onChange={onChange(eLifecheque.lcPremiumDuration)}
              containerStyle="p-col-6 p-lg-6 p-xl-4 p-sm-12 space-right"
            />
          )}
        </div>
        <div className="p-grid p-col-12 mwi-margin-top18">
          {getCoverageAmount()}
          <div className={`p-col-6 p-lg-6 p-xl-4 p-sm-12`}>
            <PremiumFrequency />
          </div>
        </div>
        <LifechequeCheckboxContainer
          onChange={onChange(eLifecheque.lcReturnPremiums.ropd)}
          checked={ropd}
          intl={intl}
          onChange1={onChange(eLifecheque.lcReturnPremiums.ropx)}
          checked1={ropx}
          disabled={disableRopx}
          onChange2={onChange(eLifecheque.lcReturnPremiums.rops)}
          checked2={rops}
          disabled1={disableRops}
        />
      </div>
    </React.Fragment>
  );
});

const mapDispatchToProps = (dispatch) => ({
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  riderActions: bindActionCreators(RiderActions, dispatch),
});

const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs, clients }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const firstCoverageId = coverageState.tabNavs[0];
  const activeCoverageId = coverageState.activeTabId;
  const firstCoverageTab = coverageState[firstCoverageId];

  return {
    coverageState,
    firstCoverageTab,
    activeCoverageId,
    locale: app.locale,
    product: activeScenario.product,
    activeScenarioTabId: scenarioTabNavs.activeTabId,
    activeTabViewTab: activeScenario.activeTabViewTab,
    clients: clients[scenarioTabNavs.activeTabId],
    userType: app.userType,
    effectiveDate: activeScenario.effectiveDate,
    inforce: app.inforcePolicy,
    activeScenario,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LifechequeCoverageContainer);
