import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import '../mwi-accordion/mwi-accordion.css';

export class MWIAccordion extends PureComponent {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    folded: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    rightContent: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    onClickChange: PropTypes.func,
    removeBtn: PropTypes.bool,
    removeArrowWhenExpanded: PropTypes.bool,
    headingLevel: PropTypes.number,
  };

  static defaultProps = {
    folded: true,
    title: '',
    className: '',
  };

  constructor(props) {
    super(props);

    this.mwiAccordionRef = React.createRef();
    this.focus = this.focus.bind(this);
  }

  focus() {
    this.mwiAccordionRef.focus();
  }
  // componentDidMount() {
  //   //window.scrollTo(0, 0);
  //   //TODO SET FOCUS TO THE NEW COVERAGE
  //   // setTimeout(() => {
  //   //   this.mwiAccordionRef.focus();
  //   // }, 700);
  // }

  render() {
    const {
      title,
      children,
      className,
      folded,
      removeArrowWhenExpanded,
      onClickChange,
      rightContent,
      removeBtn,
      headingLevel,
    } = this.props;

    return (
      <React.Fragment>
        <div className={`accordion ${className}`} role="container-accordion">
          <div className="title mwi-weight-500 short-form" role="heading" aria-level={headingLevel || '2'}>
            <div className="p-col-12">
              <div
                className={!removeArrowWhenExpanded ? 'title-group p-col-10' : 'title-group-when-expanded p-col-10'}
                id="accordion-title-div"
                onClick={() => onClickChange(1)}
                ref={(el) => (this.mwiAccordionRef = el)}
                role="button"
                aria-expanded={!folded}
                tabIndex="0"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    onClickChange(1);
                  }
                }}
              >
                {!removeArrowWhenExpanded && (
                  <i className="material-icons" aria-hidden="true">
                    {!folded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                  </i>
                )}
                {title}
              </div>
              <div className={`${removeBtn ? 'rightContent-remove' : 'rightContent'}`}>{rightContent}</div>
            </div>
          </div>
        </div>
        {!folded && children}
      </React.Fragment>
    );
  }
}

export default MWIAccordion;
