import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { coverageIndexHelper, clientIndexHelper } from '../../core/utils';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import * as Actions from './actions';
import _ from 'lodash';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';
import MWIPanel from '../../core/components/mwi-panel';
import CoverageTab from '../coverage/coverage-tab';
import CoverageSettings from '../coverage/coverage-settings';
import { eProduct, eCoverageType, eSpecialQuote } from '../../core/configs';
export class CoverageTabNavs extends PureComponent {
  static propTypes = {
    tabNavs: PropTypes.arrayOf(PropTypes.string).isRequired,
    udmEnabled: PropTypes.bool,
    allCoverages: PropTypes.object,
    coverage: PropTypes.object,
    clientIndex: PropTypes.number,
    coverageIndex: PropTypes.number,
    activeScenarioTabId: PropTypes.string.isRequired,
    activeCoverageTabId: PropTypes.string.isRequired,
    selectedProduct: PropTypes.string,
    actions: PropTypes.object,
    sceActions: PropTypes.object,
    intl: PropTypes.object,
    product: PropTypes.string,
    effectiveDate: PropTypes.string,
    userType: PropTypes.string,
    locale: PropTypes.string,
    coverageType: PropTypes.string,
    specialQuoteOption: PropTypes.string,
    isMultiCov: PropTypes.bool,
    numUniqueClients: PropTypes.number,
    inforce: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.onTabChange = this.onTabChange.bind(this);
    this.onAccordionChange = this.onAccordionChange.bind(this);
    this.lastCoverage = React.createRef();
    this.state = { newCoverage: false, activeTab: [] };
  }

  componentDidUpdate() {
    if (this.state.newCoverage && this.lastCoverage !== null) {
      this.setState({ newCoverage: false, ...this.state }); // react permits this when wrapped in condition to prevent infinite loop
      if (typeof this.lastCoverage.focus === 'function') {
        this.lastCoverage.focus();
      }
    }
  }

  addCoverage = () => {
    const { actions, activeScenarioTabId, clientIndex, coverage, coverageIndex, product } = this.props;
    this.onTabChange('add');
    actions.addCoverageTabNavs({
      scenarioTabId: activeScenarioTabId,
      coverageTabId: coverageIndexHelper(coverageIndex),
      clientId: clientIndexHelper(clientIndex),
      isVitalityPlusSelected: coverage.isVitalityPlusSelected,
      product,
    });
    this.setState({ ...this.state, newCoverage: true });
  };
  onTabChange() {
    const { sceActions } = this.props;
    sceActions.toggleOutOfDate(true);
  }
  onAccordionChange(selected) {
    const fold = this.props.activeCoverageTabId !== selected;
    const isActive = this.state.activeTab.find((i) => i === selected);
    if (isActive) {
      this.setState((prev) => ({
        ...prev,
        activeTab: [...this.state.activeTab.filter((i) => i !== selected)],
      }));
    } else {
      this.setState((prev) => ({
        ...prev,
        activeTab: [...this.state.activeTab, selected],
      }));
    }
    if (fold) {
      this.props.actions.clickCoverageTabNavs({
        coverageTabId: selected,
        scenarioTabId: this.props.activeScenarioTabId,
      });
    }
  }
  removeCoverage = (removedCoverageId) => {
    if (this.props.tabNavs.length === 1) {
      return;
    }
    // IMPORTANT:
    // Don't use this.props.coverage in order to get associated clients.
    // It will return wrong clients when a user clicks remove icon on non-active coverages.
    // e.g: the current active tab is 'Coverage.3' and click the remove icon on the 'Coverage.2' tab
    this.props.actions.removeCoverageTabNavs({
      scenarioTabId: this.props.activeScenarioTabId,
      coverageTabId: removedCoverageId,
      clientIds: _.keys(this.props.allCoverages[removedCoverageId].ratings),
      numTabs: this.props.tabNavs.length,
    });
    this.onTabChange('remove');
  };
  actionTemplate(tabId) {
    const { product, inforce } = this.props;
    const displayRemoveCoverage = product !== eProduct.PG && !([eProduct.BT, eProduct.FT].includes(product) && inforce);
    return (
      <React.Fragment>
        {displayRemoveCoverage && (
          <div
            tabIndex={0}
            onClick={() => this.removeCoverage(tabId)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.removeCoverage(tabId);
              }
            }}
            aria-label={this.props.intl.formatMessage({ id: 'common.remove' })}
            role="button"
          >
            <FormattedMessage id="common.remove" />
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    const {
      intl,
      tabNavs,
      activeCoverageTabId,
      product,
      locale,
      effectiveDate,
      userType,
      coverage: { coverageType, specialQuoteOption },
      isMultiCov,
      numUniqueClients,
      inforce,
    } = this.props;

    const numTabs = tabNavs.length;
    let disableVitalityPlus =
      coverageType !== eCoverageType.single ||
      specialQuoteOption === eSpecialQuote.H1 ||
      specialQuoteOption === eSpecialQuote.S1;
    disableVitalityPlus = disableVitalityPlus || (isMultiCov && numUniqueClients > 1);
    const tabNumber = 6;
    let covIdx = 0;

    return (
      <React.Fragment>
        {[eProduct.BT, eProduct.FT, eProduct.LC, eProduct.PG, eProduct.Performax, eProduct.SB].includes(product) && (
          <div className="flexdisplay">
            <CoverageSettings
              intl={intl}
              product={product}
              locale={locale}
              effectiveDate={effectiveDate}
              userType={userType}
              isVitalityPlusDisable={disableVitalityPlus}
            />
          </div>
        )}
        {tabNavs.map((tabId) => {
          const accordionIndex = 5;
          const isActiveTab = activeCoverageTabId === tabId ? true : false;
          const isTermInforce = inforce && [eProduct.BT, eProduct.FT]?.includes(product);
          return (
            <div key={tabId} data-testid="coverTab">
              <MWIAccordion
                ref={(el) => (this.lastCoverage = el)}
                className={`mwi-reverse-margin15 mwi-lightGreen ${covIdx === 0 ? 'first-child' : ''} ${
                  covIdx === accordionIndex && activeCoverageTabId !== tabId && !isTermInforce ? 'last-child' : ''
                }`}
                title={`${intl.formatMessage({ id: 'common.coverage' })} ${++covIdx}`}
                aria-label={`${intl.formatMessage({ id: 'common.coverage' })} ${covIdx}`}
                folded={
                  ![eProduct.PG, eProduct.Performax, eProduct.SB]?.includes(product) && !isTermInforce
                    ? activeCoverageTabId !== tabId
                    : this.state.activeTab.includes(tabId)
                }
                rightContent={numTabs > 1 ? this.actionTemplate(tabId) : ''}
                onClickChange={() => this.onAccordionChange(tabId)}
                removeArrowWhenExpanded={
                  ![eProduct.PG, eProduct.Performax, eProduct.SB]?.includes(product) && !isTermInforce
                    ? isActiveTab
                    : false
                }
              >
                <div className="concept-component mwi-margin-bottom24">
                  <CoverageTab coverageId={tabId} />
                </div>
              </MWIAccordion>
            </div>
          );
        })}
        {!this.props.inforce && numTabs !== tabNumber && (
          <MWIPanel skipTab className="mwi-reverse-margin15 mwi-lightGreen last-child" contentClassName="addCov">
            <div
              tabIndex="0"
              className="title-group"
              onClick={() => this.addCoverage()}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.addCoverage();
                }
              }}
              aria-label={this.props.intl.formatMessage({ id: 'common.addCoverage' })}
              role="button"
            >
              <i className="material-icons">add_circle_outline</i>
              <FormattedMessage id="common.addCoverage" />
            </div>
          </MWIPanel>
        )}
      </React.Fragment>
    );
  }
}
export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs, clients }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const coverages = coverageTabNavs[scenarioTabNavs.activeTabId];
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const firstCoverageId = coverageState.tabNavs[0];
  return {
    inforce: app.inforcePolicy,
    locale: app.locale,
    tabNavs: coverages.tabNavs,
    udmEnabled: scenarioTabNavs.udmEnabled,
    activeScenarioTabId: scenarioTabNavs.activeTabId,
    activeCoverageTabId: coverages.activeTabId,
    allCoverages: coverages,
    clientIndex: clients.clientIndex,
    coverageIndex: coverages.coverageIndex,
    coverage: coverages[coverages.activeTabId],
    product: activeScenario.product,
    userType: app.userType,
    effectiveDate: activeScenario.effectiveDate,
    firstCoverageTab: coverageState[firstCoverageId],
    isMultiCov: coverageState.isMultiCov,
    numUniqueClients: clients[clients.activeTabId].allClients.length,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CoverageTabNavs));
