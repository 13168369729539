/* eslint-disable react/no-multi-comp */
import * as AppActions from '../../core/actions';
import * as ClientActions from '../../services/reducers/components/client/actions';
import * as ConceptActions from '../concepts/actions';
import * as CoverageActions from '../coverage-tab-navs/actions';
import * as DepositActions from '../deposit/actions';
import * as RiderActions from '../../custom-mode/riders/actions';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { PureComponent } from 'react';
import {
  calculateMWINumberFieldValue,
  clientIndexHelper,
  getAge,
  getLoanToValueMax,
  handleFocus,
  isFeatureFlagSet,
  isJoint,
  trackUserActions,
  setVitalityYes,
  setActiveVitalityStatus,
  setChangeToBronzeYes,
  setCoverageSolveToYes,
  setIncludeDepositOptionToYes,
  checkShouldRenderDividendOption,
  filterDividendOptions,
  getPolicyYear,
} from '../../core/utils';
import {
  eActionEvent,
  eCoverageType,
  eDefault,
  eDepositOwnerResidence,
  eDividendOption,
  ePerformaxDividendOption,
  eDurationType,
  eField,
  eLifecheque,
  eLocale,
  ePremiumDuration,
  eProduct,
  eUserType,
  appLaunchDatePay100,
  performaxCoverageConfig,
} from '../../core/configs';
import {
  getAgeFromValue,
  getDefaultAgeToValue,
  getDefaultYearToValue,
  getYearFromValue,
} from '../deposit/duration-logic';

import CoverageClient from '../coverage-client';
import DividendScales from '../../core/components/dividend-scales';
import HelpQuestionMark from '../../core/components/help-question-mark';
import MWIDropdown from '../../core/components/mwi-dropdown';
import MWINumberField from '../../core/components/mwi-calculated-number-field';
import MWISelect from '../../core/components/mwi-select';
import NumberFormat from 'react-number-format';
import { ProductConfig } from '../product-config';
import ProductCoverageContainer from './product-coverage-container';
import PropTypes from 'prop-types';
import VitalityBronzeAfterAge from '../../core/components/vitality-bronze-after-age';
import VitalityStatus from '../vitality-status';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { dropDownOptions } from '../../core/dropdown-config';
import moment from 'moment';
import { DATE_FORMAT, MINIMUM_TO_FACE_AMOUNT } from '../../core/constants';
import { createPerformaxDividends } from './performax-dividends';
const classNameDividendPerformaxEn = 'p-col-6 p-lg-7 p-xl-6 p-sm-9';
const classNameDividendEn = 'p-col-4 p-lg-4 p-xl-3 p-sm-6';
const classNameDividendPerformaxFr = 'p-col-6 p-lg-7 p-xl-7 p-sm-12';
const classNameDividendFr = 'p-col-6 p-lg-6 p-xl-5 p-sm-6';
const containerStyle = 'containerStyle';

const isValidCoverageTypesForLC = (type) =>
  type === eLifecheque.lcCoverageOption || type === eLifecheque.lcPremiumDuration;

const isTermInforce = (product, inforce) => {
  return inforce && [eProduct.BT, eProduct.FT].includes(product);
};

const setCoverageTypeOptionDuration = function (type, value) {
  const { product, coverage, activeVitalityStatus, activeScenario } = this.props;
  const vitality = setVitalityYes(coverage);
  let coverageOptionValue = '';
  if (
    type === eField.covOption ||
    type === eField.premiumDuration ||
    isValidCoverageTypesForLC(type) ||
    type === eField.costDuration
  ) {
    coverageOptionValue = value;
  } else {
    if (product === eProduct.PAR) {
      coverageOptionValue = coverage.premiumDuration;
    } else {
      coverageOptionValue = coverage.coverageOption;
    }
  }

  trackUserActions(eActionEvent.selection, type, value, '', {
    product,
    vitality,
    coverageType: type === eField.covType ? value : coverage.coverageType,
    coverageOption: coverageOptionValue,
    vitalityStatus: setActiveVitalityStatus(coverage.isVitalityPlusSelected, activeVitalityStatus),
    changeToBronze: setChangeToBronzeYes(
      product,
      coverage.isChangeToBronze,
      coverage.isVitalityPlusSelected,
      activeVitalityStatus
    ),
    coverageSolve: setCoverageSolveToYes(coverage),
    includeDepositOption: setIncludeDepositOptionToYes(activeScenario, coverage),
  });
};

const isCoverageSolveEnabled = (product) => {
  // enable in lower regions OR only for Par in prod
  return !isFeatureFlagSet() || product === eProduct.PAR;
};

const isCoverageSolveShowPremiumAmount = function (
  locale,
  product,
  coverageTabVariables,
  premiumDuration,
  intl,
  includeMaxLevelDepositOption
) {
  return (
    <React.Fragment>
      <MWINumberField
        styleClass={`${locale === eLocale.en ? 'p-col-2 p-xl-3 p-sm-4' : 'p-col-3 p-sm-4  p-xl-3'} space-right`}
        id={eField.premiumAmount}
        label={<FormattedMessage id="coverage.premiumAmount" />}
        containerClass={'mwi-widget mwi-input number-data mwi-w100'}
        value={+this.props.coverage.premiumAmount}
        decimalScale={2}
        fixedDecimalScale
        prefix={locale === eLocale.en ? '$ ' : ''}
        suffix={locale === eLocale.fr ? ' $' : ''}
        thousandSeparator={locale === eLocale.en ? ',' : ' '}
        decimalSeparator={locale === eLocale.en ? '.' : ','}
        locale={locale}
        allowNegative={false}
        minValue={product === eProduct.PAR ? coverageTabVariables.minValuePar : coverageTabVariables.minValueNonPar}
        maxValue={coverageTabVariables.maxValueCoveragePremiumAmount}
        onBlur={this.onBlur}
        aria-labelledby="premiumAmount"
        aria-required="true"
        autoComplete="off"
      />
      {product === eProduct.PAR && premiumDuration !== ePremiumDuration.pay10 && (
        <div
          className={`${
            locale === eLocale.en ? 'p-col-3 p-sm-6 p-xl-4' : 'p-col-6 p-sm-9 p-xl-6'
          } top-margin-24 width-25-percent`}
        >
          <div className="mwi-label" id="includeMaxDepositOptionLabel">
            <span>
              <FormattedMessage id="coverage.includeMaxDepositOption" />
            </span>
          </div>
          <MWISelect
            containerStyle="p-col-12"
            ariaDescribedBy="includeMaxDepositOptionLabel"
            labelL={intl.formatMessage({ id: coverageTabVariables.commonYes })}
            labelR={intl.formatMessage({ id: coverageTabVariables.commonNo })}
            onClickL={() => this.onChange(eField.includeMaxLevelDepositOption, true, undefined, 'scenario')}
            onClickR={() => this.onChange(eField.includeMaxLevelDepositOption, false, undefined, 'scenario')}
            selectedL={includeMaxLevelDepositOption}
            selectedR={!includeMaxLevelDepositOption}
          />
        </div>
      )}
    </React.Fragment>
  );
};

const isFeatureFlagCoverageHelp = function (product, intl, coverageTabVariables, isCoverageSolve) {
  return (
    <React.Fragment>
      <div
        className={`p-col-10 mwi-label ${this.props.isMultiCov ? 'is-multi-cov' : ''}`}
        htmlFor="premiumCheckbox"
        id="premumCheckboxLabel"
      >
        <span>
          <FormattedMessage id="coverage.solveForCoverage" />
        </span>
      </div>
      <div className="help-icon-coverage right-icon p-col-1" id="coverage-help">
        <HelpQuestionMark
          containerClass=""
          overlayId="premumTip"
          overlayContent={() => {
            const solveForCoverageProduct = product === eProduct.PAR ? `.${product}` : '';
            return (
              <>
                {product !== eProduct.PAR && (
                  <>
                    <FormattedMessage id="help.comingSoon" />
                    <br />
                  </>
                )}
                <FormattedMessage
                  id={`help.solveForCoverage${solveForCoverageProduct}`}
                  values={{
                    coverageSolve: (
                      <b>
                        <FormattedMessage id="help.coverageSolve" />
                      </b>
                    ),
                  }}
                />
              </>
            );
          }}
        />
      </div>
      <MWISelect
        containerStyle="p-col-10"
        ariaDescribedBy="premumCheckboxLabel"
        labelL={intl.formatMessage({ id: coverageTabVariables.commonYes })}
        labelR={intl.formatMessage({ id: coverageTabVariables.commonNo })}
        onClickL={() => this.onChange(eField.isCoverageSolve, true)}
        onClickR={() => this.onChange(eField.isCoverageSolve, false)}
        selectedL={isCoverageSolve}
        selectedR={!isCoverageSolve}
        disabled={!isCoverageSolveEnabled(product)}
      />
    </React.Fragment>
  );
};

const showSwitchToCashDividends = function (
  locale,
  intl,
  coverageTabVariables,
  switchToCashDividends,
  isParInforce,
  policyYear
) {
  let switchToCashYearMin = 2;
  let switchToCashYearDefaultValue = 2;
  if (isParInforce) {
    switchToCashYearMin = policyYear;
    switchToCashYearDefaultValue = policyYear;
  }
  return (
    <div className="p-grid p-grid-no-margin p-col-12 switch-to-cash">
      <div className={`${locale === eLocale.en ? classNameDividendEn : 'p-col-5 p-lg-6 p-xl-4 p-sm-6'}`}>
        <label className="mwi-label" id="switchToCashDividendsLabel">
          <FormattedMessage id="coverage.switchtocash" />
        </label>
        <MWISelect
          containerStyle="div-option-value"
          ariaDescribedBy="switchToCashDividendsLabel"
          labelL={intl.formatMessage({ id: coverageTabVariables.commonYes })}
          labelR={intl.formatMessage({ id: coverageTabVariables.commonNo })}
          onClickL={() => this.onChange(eField.switchToCashDividends, true)}
          onClickR={() => this.onChange(eField.switchToCashDividends, false)}
          selectedL={switchToCashDividends}
          selectedR={!switchToCashDividends}
        />
      </div>
      {switchToCashDividends && (
        <div className="p-col-1 div-option-year">
          <label className="mwi-label div-option-label" id="switchToCashYearLabel">
            <FormattedMessage id="coverage.switchtocashyear" />
          </label>
          <NumberFormat
            className="mwi-widget mwi-input number-data div-option-value"
            value={+this.props.coverage.switchToCashDividendsYear}
            decimalScale={0}
            allowNegative={false}
            onValueChange={(values) => this.dispatchAction(eField.switchToCashDividendsYear, values.value)}
            onBlur={(e) =>
              this.onBlur(
                eField.switchToCashDividendsYear,
                calculateMWINumberFieldValue(
                  e.target.value || switchToCashYearDefaultValue,
                  switchToCashYearMin,
                  this.props.duration
                )
              )
            }
            aria-labelledby="switchToCashYearLabel"
            aria-required="true"
            autoComplete="off"
            onFocus={handleFocus}
          />
        </div>
      )}
    </div>
  );
};

const setCoverageTypeAndClientInformation = function (type, oldValue, value) {
  if (type === eField.covType) {
    const { coverage, activeScenarioTabId, clientIndex } = this.props;
    this.props.coverageActions.coveragetype({
      scenarioTabId: activeScenarioTabId,
      coverageTabId: coverage.id,
      clients: this.props.clients.allClients,
      oldValue,
      value,
    });
    if (oldValue === eCoverageType.single) {
      this.props.clientActions.addClient({
        scenarioTabId: activeScenarioTabId,
        coverageTabId: coverage.id,
        clientId: clientIndexHelper(clientIndex),
        isFemale: true,
      });
    } else {
      if (value === eCoverageType.single) {
        this.props.clientActions.removeClient({
          scenarioTabId: activeScenarioTabId,
          coverageTabId: coverage.id,
          clientId: _.last(_.keys(coverage.ratings)),
        });
      }
    }
  }
};

const getCoverageSolveDiv = function (coverageTabVariables, isCoverageSolve, premiumDuration) {
  const { product, locale, intl, isMultiCov, includeMaxLevelDepositOption, inforce } = this.props;
  return (
    <div className="p-grid p-col-12 solve-col mwi-margin-top24">
      {!isMultiCov && (
        <div className={`p-grid p-col-12`}>
          <div
            className={`${
              locale === eLocale.en ? 'p-col-5 p-sm-8 p-md-6 p-lg-6 p-xl-4' : 'p-col-8 p-sm-8 p-md-5 p-lg-6'
            }`}
            id="solveCoverageWrapper"
            role="solveCoverageWrapper"
          >
            {isCoverageSolveEnabled(product) &&
              !isTermInforce(product, inforce) &&
              isFeatureFlagCoverageHelp.call(this, product, intl, coverageTabVariables, isCoverageSolve)}
          </div>
          {isCoverageSolve &&
            isCoverageSolveShowPremiumAmount.call(
              this,
              locale,
              product,
              coverageTabVariables,
              premiumDuration,
              intl,
              includeMaxLevelDepositOption
            )}
        </div>
      )}
      {product === eProduct.FTV && this.props.client1 && (
        <VitalityStatus
          coverageId={this.props.activeCoverageTabId}
          isCoverageSolve={this.props.coverage.isCoverageSolve}
        />
      )}
    </div>
  );
};

function checkProductMulFTParVitality(product) {
  return product === eProduct.MUL || product === eProduct.FT || product === eProduct.PAR;
}

export class CoverageTab extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    activeScenarioTabId: PropTypes.string,
    activeCoverageTabId: PropTypes.string,
    coverageTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }).isRequired
    ),
    depositOptionType: PropTypes.string,
    defaultResidence: PropTypes.string,
    defaultDividendOption: PropTypes.string,
    advisorProfile: PropTypes.object,
    clients: PropTypes.object,
    clientIndex: PropTypes.number,
    coverage: PropTypes.object,
    isMultiCov: PropTypes.bool,
    product: PropTypes.string,
    premiumDuration: PropTypes.string,
    udmPolicy: PropTypes.object,
    udmCoverages: PropTypes.array,
    duration: PropTypes.number,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    clientActions: PropTypes.object,
    client1: PropTypes.object,
    client2: PropTypes.object,
    conceptActions: PropTypes.object,
    deathAtAge: PropTypes.number,
    durationType: PropTypes.string,
    riderActions: PropTypes.object,
    coverageActions: PropTypes.object,
    includeMaxLevelDepositOption: PropTypes.bool,
    intl: PropTypes.object,
    deposit: PropTypes.object,
    depositOwnerResidence: PropTypes.string,
    depositActions: PropTypes.object,
    clientAge: PropTypes.number,
    coverageType: PropTypes.string,
    equivalentAge: PropTypes.number,
    costOfInsurance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isOutOfDate: PropTypes.bool,
    activeVitalityStatus: PropTypes.string,
    inforce: PropTypes.bool,
    coverageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    coverages: PropTypes.object,
    effectiveDate: PropTypes.string,
    userType: PropTypes.string,
    performanceCreditOption: PropTypes.string,
    activeScenario: PropTypes.object,
    policyDate: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.help = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onClientSelection = this.onClientSelection.bind(this);
  }

  componentDidMount() {
    const { client1, clientActions, activeScenarioTabId, coverage, clientIndex, deposit, advisorProfile, product } =
      this.props;
    const { depositOwnerResidence } = deposit;
    this.dispatchAction('activeTopBarIndex', 1, 'scenario');

    if (this.validateResidence(advisorProfile, client1, depositOwnerResidence)) {
      this.onChange(eField.depositOwnerResidence, advisorProfile[advisorProfile.currentProfileId].residence);
    }

    // if client.1 does not exist make a request to create
    if (!client1) {
      clientActions.addClient({
        scenarioTabId: activeScenarioTabId,
        coverageTabId: coverage.id,
        clientId: clientIndexHelper(clientIndex),
        isVitalityPlusSelected: coverage.isVitalityPlusSelected,
        product,
      });
    }

    if (!deposit[eField.durationTo]) {
      this.setDefaultValuesDEP();
    }

    if (!this.props.isOutOfDate) {
      return;
    }

    if (product === eProduct.MUL && depositOwnerResidence === eDepositOwnerResidence.select) {
      this.props.appActions.dispatchMWIOnChange({
        target: 'deposit',
        scenarioTabId: this.props.activeScenarioTabId,
        field: eField.selectClicked,
        value: true,
      });
      return;
    }
    this.props.sceActions.performManualRefresh();
    this.props.sceActions.requestUDMResponse();
  }

  dispatchNotification(type, field, value) {
    this.props?.appActions[type]({
      field,
      value,
    });
  }

  dispatchAction(field, value, target = 'coverage', isError = false, refresh = true) {
    if (field === eField.depositOwnerResidence || field === eField.durationTo) {
      this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
        target: 'deposit',
        scenarioTabId: this.props.activeScenarioTabId,
        field,
        value,
      });
      if (refresh) {
        this.props.sceActions.performManualRefresh();
      }
      this.props.sceActions.toggleOutOfDate(true);
      return;
    }
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      scenarioTabId: this.props.activeScenarioTabId,
      coverageTabId: this.props.activeCoverageTabId,
      product: this.props.product,
      premiumDuration: this.props.coverage.premiumDuration,
      isCoverageSolve: this.props.coverage.isCoverageSolve,
      clients: this.props.clients,
      target,
      field,
      value,
    });
  }

  handlePay100(type, value, target) {
    const { effectiveDate, intl, premiumDuration, userType } = this.props;
    if (
      userType === eUserType.headOffice &&
      value === ePremiumDuration.Pay100 &&
      !moment(effectiveDate, DATE_FORMAT).isSameOrAfter(moment(effectiveDateChangeDate(), DATE_FORMAT))
    ) {
      this.dispatchAction(eField.effectiveDate, effectiveDateChangeDate(), 'scenario');
      this.dispatchNotification('showStatusDialog', null, [
        intl.formatMessage({ id: 'common.importantInformation' }),
        intl.formatMessage({ id: 'common.information.pay100' }),
      ]);
    } else if (
      userType === eUserType.advisor &&
      value === ePremiumDuration.Pay100 &&
      !moment(effectiveDate, DATE_FORMAT).isSameOrAfter(moment(effectiveDateChangeDate(), DATE_FORMAT))
    ) {
      this.dispatchAction(eField.effectiveDate, effectiveDateChangeDate(), 'scenario');
    } else {
      //do nothing
    }
    this.dispatchAction(type, value, target);
    function effectiveDateChangeDate() {
      return premiumDuration === ePremiumDuration.Pay100 ? appLaunchDatePay100.Par : appLaunchDatePay100.ParVitality;
    }
  }

  onChange(type, value, oldValue, target = 'coverage') {
    this.dispatchAction(type, value, target);
    setCoverageTypeAndClientInformation.call(this, type, oldValue, value);
    if (
      (type === eField.divOption && value === eDividendOption.cash) ||
      (type === eField.switchToCashDividends && value)
    ) {
      const age = getAge(
        this.props.client1.age,
        this.props.client2 && this.props.client2.age,
        this.props.coverage.coverageType
      );
      const toValue = getLoanToValueMax(this.props.durationType, this.props.deathAtAge, age);
      this.setConceptFieldValue(eField.durationTo, toValue, eField.loanInformation);
    }

    if (type === eField.covType || type === eField.covOption || type === eField.premiumDuration) {
      setCoverageTypeOptionDuration.call(this, type, value);
    }

    if (
      [
        eField.covType,
        eField.covOption,
        eField.divOption,
        eField.premiumDuration,
        eField.quoteOption,
        eField.amountOfInsuranceType,
        eField.switchToCashDividends,
        eField.includeMaxLevelDepositOption,
        eField.isCoverageSolve,
        eField.deathBenefitType,
        eField.costOfInsurance,
        eField.isVitalityPlusSelected,
        eField.depositOwnerResidence,
        eField.costDuration,
        eField.toFaceAmount,
        eField.topFaceAmount,
        eLifecheque.lcCoverageOption,
        eLifecheque.lcPremiumDuration,
        eLifecheque.lcReturnPremium,
      ].indexOf(type) >= 0
    ) {
      this.props.sceActions.toggleOutOfDate(true);
      if ([eProduct.PAR, eProduct.SB, eProduct.Performax].includes(this.props.product)) {
        this.props.sceActions.performManualRefresh(true);
      }
    }

    if (this.props.product === eProduct.PAR && value === ePremiumDuration.Pay100) {
      this.handlePay100(type, value, target);
    }

    if (this.props.product === eProduct.PAR && value === ePremiumDuration.pay10) {
      this.dispatchAction(eField.includeMaxLevelDepositOption, false, 'scenario');
    }
  }

  getToFaceValue(value) {
    const {
      coverage: { toFaceAmountFromPS },
    } = this.props;
    let toFaceValue;
    if (value < MINIMUM_TO_FACE_AMOUNT) {
      toFaceValue = MINIMUM_TO_FACE_AMOUNT;
    } else if (value > toFaceAmountFromPS) {
      toFaceValue = toFaceAmountFromPS;
    } else {
      toFaceValue = value;
    }
    return toFaceValue;
  }

  getTopFaceValue(value) {
    const {
      coverage: { toFaceAmountFromPS, topFaceAmountFromPS },
    } = this.props;
    const topMaxValue = topFaceAmountFromPS;
    const topMinValue = performaxCoverageConfig.termOptionPlus.minAmount(toFaceAmountFromPS);

    let topFaceValue;
    if (value < topMinValue) {
      topFaceValue = topMinValue;
    } else if (value > topMaxValue) {
      topFaceValue = topMaxValue;
    } else {
      topFaceValue = value;
    }
    return topFaceValue;
  }

  onBlur(type, value) {
    if (type === eField.premiumAmount && !value) {
      this.dispatchAction(type, eDefault.premiumAmount);
      return false;
    }

    if ([eField.coverageAmount, eField.premiumAmount, eField.deathBenefit, eField.switchYear]?.includes(type)) {
      this.dispatchAction(type, value);
      if (eField.premiumAmount === type) {
        this.props.sceActions.performManualRefresh(true);
      }
    }

    if (type === eField.switchToCashDividendsYear) {
      this.dispatchAction(type, value);
    }

    if (type === eField.toFaceAmount) {
      this.dispatchAction(type, this.getToFaceValue(value));
      this.props.sceActions.performManualRefresh(true);
    }

    if (type === eField.topFaceAmount) {
      this.dispatchAction(type, this.getTopFaceValue(value));
      this.props.sceActions.performManualRefresh(true);
    }
    this.props.sceActions.toggleOutOfDate(true);
    return true;
  }

  onClientSelection(selectedClientId, prevClientId) {
    this.props.clientActions.selectClient({
      scenarioTabId: this.props.activeScenarioTabId,
      coverageTabId: this.props.coverage.id,
      selectedClientId,
      prevClientId,
    });
    this.props.sceActions.toggleOutOfDate(true);
  }

  get coverageTypes() {
    const { intl, product } = this.props;
    const { coverageTypeOptions, coverageTypeOptionsMUL, coverageTypeOptionsPG, coverageTypeOptionsPMax } =
      dropDownOptions(intl);

    if (product === eProduct.PAR) {
      return _.filter(
        coverageTypeOptions,
        (type) => type.value === eCoverageType.single || type.value === eCoverageType.jointLastPayLastDeath
      );
    } else if (product === eProduct.PG) {
      return coverageTypeOptionsPG;
    } else if (product === eProduct.Performax || product === eProduct.SB) {
      return coverageTypeOptionsPMax;
    } else if (product === eProduct.MUL || product === eProduct.IV) {
      return coverageTypeOptionsMUL;
    } else {
      return _.filter(coverageTypeOptions, (type) => type.value !== eCoverageType.jointLastPayLastDeath);
    }
  }

  setDefaultValuesDEP() {
    const { deposit, clientAge, activeScenarioTabId, coverageType, equivalentAge, costOfInsurance, duration } =
      this.props;
    if (deposit[eField.duration] === eDurationType.age) {
      const valueFrom = getAgeFromValue(coverageType, equivalentAge, clientAge, '');
      this.props.depositActions.setDefaultDuration({
        defaultValueFrom: valueFrom,
        defaultValueTo: getDefaultAgeToValue(coverageType, equivalentAge, clientAge, costOfInsurance),
        defaultActiveScenarioTabId: activeScenarioTabId,
      });
    } else {
      if (deposit[eField.duration] === eDurationType.year) {
        const fromValue = getYearFromValue(duration, '');
        this.props.depositActions.setDefaultDuration({
          defaultvalueFrom: fromValue,
          defaultvalueTo: getDefaultYearToValue(coverageType, equivalentAge, clientAge, costOfInsurance),
          defaultactiveScenarioTabId: activeScenarioTabId,
        });
      }
    }
  }

  setConceptFieldValue(fieldName, fieldValue, parent, refresh = true) {
    this.props.conceptActions.setConceptFieldValue({
      scenarioTabId: this.props.activeScenarioTabId,
      fieldParent: parent,
      fieldName,
      fieldValue,
    });
    if (refresh) {
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  isMULOrFTOrPARVitality(product, isVitalityPlusSelected) {
    return !!(checkProductMulFTParVitality(product) && isVitalityPlusSelected);
  }

  isPay100(premiumDuration) {
    return premiumDuration === ePremiumDuration.Pay100;
  }

  isMULParVitality(product, isVitalityPlusSelected) {
    return (product === eProduct.MUL || product === eProduct.PAR) && isVitalityPlusSelected;
  }

  renderdepositOwnerResidence = () => {
    const {
      product,
      intl,
      deposit,
      coverage: { coverageType, ratings },
    } = this.props;
    const { depositOwnerResidences } = dropDownOptions(intl);
    const { depositOwnerResidence, selectOwnersResidenceClicked } = deposit;
    const coverageTabVariables = coverageTabVar(
      product,
      coverageType,
      ratings,
      selectOwnersResidenceClicked,
      depositOwnerResidence
    );
    return (
      <MWIDropdown
        name="depositOwnerResidence"
        label={<FormattedMessage id="deposit.ownerresidence" />}
        value={depositOwnerResidence}
        options={depositOwnerResidences}
        disabled={this.props.inforce}
        onChange={(e) => {
          this.dispatchAction(eField.depositOwnerResidence, e.target.value);
        }}
        containerStyle="p-col-4 p-lg-5 p-xl-4 p-sm-6 space-right"
        dropdownStyle="mwi-w100"
        errorState={coverageTabVariables.checkOwnersError()}
      />
    );
  };

  renderVitalityStatus = () => {
    const {
      product,
      activeCoverageTabId,
      coverage: { premiumDuration, isCoverageSolve, isVitalityPlusSelected },
      client1,
    } = this.props;
    if (!this.isPay100(premiumDuration)) {
      return (
        this.isMULOrFTOrPARVitality(product, isVitalityPlusSelected) &&
        client1 && <VitalityStatus coverageId={activeCoverageTabId} isCoverageSolve={isCoverageSolve} />
      );
    }
    return <></>;
  };

  renderBronzeAfterAge = () => {
    const {
      product,
      coverage: { premiumDuration, isVitalityPlusSelected },
      activeVitalityStatus,
      client1,
      intl,
    } = this.props;
    if (!this.isPay100(premiumDuration)) {
      return (
        this.isMULParVitality(product, isVitalityPlusSelected) &&
        client1 &&
        activeVitalityStatus !== 'Bronze' && <VitalityBronzeAfterAge intl={intl} />
      );
    }
    return <></>;
  };

  validateResidence(advisorProfile, client1, depositOwnerResidence) {
    // Confirm there has been no residence selected yet
    // And that client1 is Not set.  This means it is a fresh case!
    if (depositOwnerResidence !== eDepositOwnerResidence.select || client1) {
      return false;
    }

    // Confirm there's a currently selected profile
    // Confirm that the current profile belongs to the currently logged in user.
    if (!advisorProfile.currentProfileId || !advisorProfile[advisorProfile.currentProfileId]) {
      return false;
    }

    // Confirm the currently selected profile has a residence.
    // Confirm the residence is not the default value.
    return !(
      !advisorProfile[advisorProfile.currentProfileId].residence ||
      advisorProfile[advisorProfile.currentProfileId].residence === eDepositOwnerResidence.select
    );
  }

  getVariablesToUse(coverageType, ratings, product, coverageId) {
    let coverageTypeToUse = coverageType;
    let ratingsToUse = ratings;
    let coverageToUse = this.props.coverage;

    if (product === eProduct.PG || isTermInforce(product, this.props.inforce)) {
      const currentCoverage = this.props.coverages[coverageId];
      coverageTypeToUse = currentCoverage.coverageType;
      ratingsToUse = currentCoverage.ratings;
      coverageToUse = currentCoverage;
    }

    return {
      coverageTypeToUse,
      ratingsToUse,
      coverageToUse,
    };
  }

  createDividendOptions(product, PerformaxdividendOptions, defaultDividendOption, dividendOptions) {
    return [eProduct.Performax, eProduct.SB].includes(product)
      ? filterDividendOptions(PerformaxdividendOptions, defaultDividendOption)
      : dividendOptions;
  }

  render() {
    const {
      product,
      locale,
      intl,
      isMultiCov,
      defaultDividendOption,
      deposit,
      coverage: {
        coverageType,
        dividendOption,
        isCoverageSolve,
        ratings,
        switchToCashDividends,
        premiumDuration,
        shouldDisplayTOP,
      },
      coverageId,
      activeScenario,
      policyDate,
      inforce,
    } = this.props;
    const { dividendOptions, PerformaxdividendOptions } = dropDownOptions(intl);
    const { depositOwnerResidence, selectOwnersResidenceClicked } = deposit;
    const { coverageTypeToUse, ratingsToUse, coverageToUse } = this.getVariablesToUse(
      coverageType,
      ratings,
      product,
      coverageId
    );

    const coverageTabVariables = coverageTabVar(
      product,
      coverageTypeToUse,
      ratingsToUse,
      selectOwnersResidenceClicked,
      depositOwnerResidence
    );
    const showClientSelectDropdown =
      product !== eProduct.PG &&
      !isTermInforce(product, inforce) &&
      isMultiCov &&
      !coverageToUse.isVitalityPlusSelected;
    const { performanceCreditOptions } = dropDownOptions(intl);

    const filteredDividendOptions = this.createDividendOptions(
      product,
      PerformaxdividendOptions,
      defaultDividendOption,
      dividendOptions
    );

    const { ToFaceAmount, TermOptionPlus } = createPerformaxDividends({ ...this.props }, (...args) =>
      this.onBlur(...args)
    );
    const isParInforce = eProduct.PAR === product && this.props.inforce;
    const policyYear = getPolicyYear(policyDate);

    return (
      <div className="form-main">
        <div className="p-grid p-grid-no-margin">
          {ProductConfig[product].coverageSections.coverageDetails && (
            <ProductCoverageContainer
              product={product}
              coverageTypes={this.coverageTypes}
              coverage={coverageToUse}
              onChange={this.onChange}
              toggleOutOfDate={this.props.sceActions.toggleOutOfDate}
              onBlur={this.onBlur}
              locale={locale}
            />
          )}
          {ProductConfig[product].coverageSections.coverageSolve &&
            !isParInforce &&
            getCoverageSolveDiv.call(this, coverageTabVariables, isCoverageSolve, premiumDuration)}
          <div className="p-grid p-grid-no-margin-all p-col-12 mwi-margin-top18 align-items-center">
            {this.renderVitalityStatus()}
            {this.renderBronzeAfterAge()}
          </div>

          <hr className="splitter client-splitter" />
          {product === eProduct.MUL && (
            <div
              className={`p-grid p-grid-no-margin p-col-12
                                mwi-margin-bottom10 owner-residence flexdisplay`}
              role="owner-residence"
            >
              {this.renderdepositOwnerResidence()}
            </div>
          )}

          {coverageTabVariables.checkOwnersError() && (
            <div className="mwi-margin-bottom14" style={{ color: 'red' }}>
              <FormattedMessage id="deposit.ownerresidence.error" />
            </div>
          )}

          {ProductConfig[product].coverageSections.clientSection && (
            <div className="p-grid coverage-client">
              <CoverageClient
                showClientIcon
                showClientLabel={!showClientSelectDropdown}
                showClientSelect={showClientSelectDropdown}
                showMultiClients={coverageTypeToUse !== eCoverageType.single}
                selectedClient={coverageTabVariables.selectedClientId}
                onChange={this.onClientSelection}
                showClientRatings={!coverageTabVariables.isJointCoverage}
                showJointRatings={coverageTabVariables.isJointCoverage}
                uniqueId={coverageTabVariables.selectedClientId}
                uniqueCoverageId={coverageToUse.coverageId}
              />
            </div>
          )}
        </div>
        {ProductConfig[product].coverageSections.dividendOptions && (
          <div className="dividend-option">
            <hr className="splitter" />
            {checkShouldRenderDividendOption(product, activeScenario?.premiumFrequency, dividendOption) && (
              <div className="dividendContainer">
                <MWIDropdown
                  name="dividendOption"
                  label={<FormattedMessage id="coverage.divoption" />}
                  value={dividendOption}
                  options={filteredDividendOptions}
                  disabled={
                    dividendOption === ePerformaxDividendOption.PremiumReduction ||
                    (this.props.inforce && product === eProduct.PAR)
                  }
                  onChange={(e) => this.onChange(eField.divOption, e.target.value, dividendOption)}
                  containerStyle={getDividendOptionContainerStyle(product, locale)}
                />
                <ToFaceAmount />
              </div>
            )}
            {shouldDisplayTOP && <TermOptionPlus />}
            {dividendOption === eDividendOption.pui &&
              ![eProduct.Performax, eProduct.SB].includes(product) &&
              showSwitchToCashDividends.call(
                this,
                locale,
                intl,
                coverageTabVariables,
                switchToCashDividends,
                isParInforce,
                policyYear
              )}
            <DividendScales />
          </div>
        )}
        {product === eProduct.PG && (
          <div className="pc-option">
            <hr className="splitter" />
            <div className="p-grid p-grid-no-margin p-col-12 flexdisplay">
              <MWIDropdown
                name="pcOption"
                label={<FormattedMessage id="coverage.pcoption" />}
                value={coverageToUse.performanceCreditOption}
                options={performanceCreditOptions}
                onChange={(e) => this.onChange(eField.performanceCreditOption, e.target.value)}
                disabled={this.props.inforce}
                containerStyle={`${locale === eLocale.en ? classNameDividendEn : containerStyle}`}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const getDividendOptionContainerStyle = (product, locale) => {
  let dividendOptionStyle = '';
  if (locale === eLocale.en) {
    dividendOptionStyle = [eProduct.Performax, eProduct.SB].includes(product)
      ? classNameDividendPerformaxEn
      : classNameDividendEn;
  } else {
    dividendOptionStyle = [eProduct.Performax, eProduct.SB].includes(product)
      ? classNameDividendPerformaxFr
      : classNameDividendFr;
  }
  return dividendOptionStyle;
};

export const mapStateToProps = ({
  app,
  udmResponse,
  scenarioTabNavs,
  coverageTabNavs,
  conceptTabNavs,
  clients,
  deposits,
  vitalityStatus,
  advisorProfile,
}) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const coverageState = coverageTabNavs[scenarioTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const clientIdsInCov = _.keys(coverage.ratings);
  const illustration = udmResponse.illustration;
  const udmPolicy = (illustration && illustration.policy) || {};
  const activeScenario = scenarioTabNavs.topBars[scenarioTabId];
  const product = scenarioTabNavs.topBars[scenarioTabId].product;
  const userType = app.userType;
  const deathAtAge = conceptTabNavs[scenarioTabId].lifeExpectancyAssumption[eField.deathAtAge];
  const durationType = conceptTabNavs[scenarioTabId].loanInformation[eField.duration];

  // make sure coverage types are product-specific

  const isMultiCov = coverageState.tabNavs.length > 1;

  const activeScenarioTabId = scenarioTabNavs.activeTabId;
  const deposit = deposits[activeScenarioTabId];
  const { udmIllustration } = udmResponse;
  const partyBasedOnClient =
    udmIllustration &&
    udmIllustration.party &&
    udmIllustration.party.find((party) => party.partyId === deposit[eField.basedonClient]);
  const clientAge = partyBasedOnClient && partyBasedOnClient.age;

  const equivalentAge = coverageState[coverageState.activeTabId].equivalentAge;
  const activeVitalityStatus = vitalityStatus[vitalityStatus.activeTabId][0].vitalityStatus;
  const effectiveDate = activeScenario.effectiveDate;
  const defaultDividendOption = app.inforceData?.policy?.coverage
    ? app.inforceData.policy.coverage[0].dividendOption?.type
    : '';

  return {
    locale: app.locale,
    activeScenarioTabId: scenarioTabId,
    activeCoverageTabId: coverageState.activeTabId,
    clients: clients[scenarioTabId],
    clientIndex: clients.clientIndex,
    client1: clients[scenarioTabId][clientIdsInCov[0]],
    client2: clients[scenarioTabId][clientIdsInCov[1]],
    depositOptionType: activeScenario.depositOptionType,
    includeMaxLevelDepositOption: activeScenario.includeMaxLevelDepositOption,
    udmCoverages: udmPolicy.coverage,
    duration: udmPolicy.duration,
    clientAge: +clientAge,
    coverageType: coverageState[coverageState.activeTabId].coverageType,
    costOfInsurance: coverageState[coverageState.activeTabId].costOfInsurance,
    isOutOfDate: scenarioTabNavs.isOutOfDate,
    inforce: app.inforcePolicy,
    coverages: coverageState,
    advisorProfile,
    coverage,
    isMultiCov,
    product,
    udmPolicy,
    equivalentAge,
    deposit,
    deathAtAge,
    durationType,
    activeVitalityStatus,
    defaultDividendOption,
    userType,
    effectiveDate,
    activeScenario,
    policyDate: app.inforceData?.policy?.effectiveDate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  clientActions: bindActionCreators(ClientActions, dispatch),
  conceptActions: bindActionCreators(ConceptActions, dispatch),
  riderActions: bindActionCreators(RiderActions, dispatch),
  coverageActions: bindActionCreators(CoverageActions, dispatch),
  depositActions: bindActionCreators(DepositActions, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CoverageTab));

const coverageTabVar = (product, coverageType, ratings, selectOwnersResidenceClicked, depositOwnerResidence) => {
  return {
    isJointCoverage: isJoint(product, coverageType),
    selectedClientId: _.keys(ratings)[0],
    checkOwnersError: () => selectOwnersResidenceClicked && depositOwnerResidence === eDepositOwnerResidence.select,
    minValuePar: 100,
    minValueNonPar: 0,
    maxValueCoveragePremiumAmount: 99999999,
    commonYes: 'common.yes',
    commonNo: 'common.no',
  };
};
