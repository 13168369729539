import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { dispatchMWIUpdateError, dispatchMWIOnChange } from '../../../core/actions';
import { bindActionCreators } from 'redux';
import HelpQuestionMark from '../help-question-mark';
import './profile-card.css';
import _ from 'lodash';
import { eDefault, eField, configs } from '../../configs';
import MWIDropdownSearch from '../mwi-dropdown-search';
import { toggleOutOfDate } from '../../../custom-mode/scenario-tab-navs/actions';

export class ProfileCard extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    dispatchMWIUpdateErrorAppAction: PropTypes.func,
    dispatchMWIOnChangeAppAction: PropTypes.func,
    dispatchAutoSave: PropTypes.func,
    advisorProfile: PropTypes.object,
    app: PropTypes.object,
    myCases: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    const { app, myCases } = props;

    const stateProfiles = this.state?.profiles;
    if (!stateProfiles) {
      if (myCases?.isOpen && app?.model?.advisorProfile) {
        this.state = {
          profiles: app.model.advisorProfile,
        };
      } else {
        this.state = {
          profiles: props.advisorProfile,
        };
      }
    }
  }

  shortenName(name, length) {
    return name && name.length > length + 1 ? name.substring(0, length) + '...' : name;
  }

  getLanguageText(text) {
    let value = text;
    if (value === eDefault.advisorProfile) {
      value = _.find(configs.defaultProfile, ['id', this.props.locale]).value;
    }
    return value;
  }

  dispatchAction(field, value, isError = false) {
    if (isError) {
      this.props.dispatchMWIUpdateErrorAppAction({
        target: 'report',
        field,
        value,
      });
    } else {
      this.props.dispatchMWIOnChangeAppAction({
        target: 'report',
        field,
        value,
      });
    }
  }

  onChange(e) {
    this.dispatchAction(eField.advisorProfile, e.value);
    this.props.dispatchAutoSave();
    const currentProfiles = this.state.profiles;
    currentProfiles.currentProfileId = e.value;
    currentProfiles.workingProfile = currentProfiles[e.value];
    this.setState({
      profiles: currentProfiles,
    });
  }

  compareAdvisorNames(valA, valB) {
    if (valA.label.toUpperCase() < valB.label.toUpperCase()) {
      return -1;
    }
    if (valA.label.toUpperCase() > valB.label.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  render() {
    const { intl } = this.props;
    let advisorNames;
    const profiles = this.state.profiles;
    if (profiles) {
      advisorNames = _.map(profiles.allProfiles, (guid) => {
        const maxNameLength = 15;
        return {
          label: profiles[guid].advisorName
            ? this.shortenName(this.getLanguageText(profiles[guid].advisorName), maxNameLength)
            : '-',
          value: guid,
        };
      });
      advisorNames = advisorNames.sort(this.compareAdvisorNames);
    }
    return (
      <div className="business-card-row" role="business-card">
        <div className="business-card-lbl">{<FormattedMessage id="profile.businessCard" />}</div>
        <div>
          <HelpQuestionMark
            containerClass="help business-card-help"
            overlayId="businessCardTip"
            overlayContent={() => (
              <div>
                <FormattedMessage id={'profile.businessCard.tooltip.text1'} />
                <br />
                <FormattedMessage id={'profile.businessCard.tooltip.text2'} />
                <br />
                <FormattedMessage id={'profile.businessCard.tooltip.text3'} />
                <br />
                <FormattedMessage id={'profile.businessCard.tooltip.text4'} />
                <br />
                <FormattedMessage id={'profile.businessCard.tooltip.text5'} />
                <br />
                <FormattedHTMLMessage id={'profile.businessCard.tooltip.text6'} />
                <br />
                <FormattedHTMLMessage id={'profile.businessCard.tooltip.text7'} />
                <br />
                <FormattedMessage id={'profile.businessCard.tooltip.text8'} />
              </div>
            )}
          />
        </div>
        <div className="profile-card p-col-9">
          <MWIDropdownSearch
            label=""
            name="businessCard"
            advisorProfile={profiles.currentProfileId}
            placeholder={intl.formatMessage({ id: 'profile.businessCard.select' })}
            value={
              profiles === undefined || profiles.currentProfileId === null
                ? intl.formatMessage({ id: 'profile.businessCard.select' })
                : profiles.currentProfileId
            }
            options={advisorNames}
            onChange={this.onChange}
            dropdownStyle="dd-profile"
            isRequired={false}
            grid=""
            role="profile-card-dropdown"
          />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, myCases, advisorProfile }) => {
  return {
    app,
    myCases,
    advisorProfile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchMWIUpdateErrorAppAction: bindActionCreators(dispatchMWIUpdateError, dispatch),
  dispatchMWIOnChangeAppAction: bindActionCreators(dispatchMWIOnChange, dispatch),
  dispatchAutoSave: bindActionCreators(toggleOutOfDate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProfileCard));
