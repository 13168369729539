import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import './error-panel.css';
import * as AppActions from '../../../core/actions';
import MWISROnly from '../mwi-sr-only';

export class ErrorPanel extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    errorMessage: PropTypes.string,
    appActions: PropTypes.object,
    openHelp: PropTypes.func,
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.hideErrorMsg = this.hideErrorMsg.bind(this);
    this.showErrorMsg = this.showErrorMsg.bind(this);
  }

  showErrorMsg() {
    let errorMsg = 'common.system.error';
    if (this.props.errorMessage === 'common.specialRequestForm.error') {
      errorMsg = 'common.specialRequestForm.error';
    }
    return errorMsg;
  }

  hideErrorMsg() {
    this.props.appActions.dispatchMWIOnChange({
      target: 'app',
      field: 'errorMessage',
      value: '',
    });
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="error-panel p-grid p-col-12 p-grid-no-margin" data-testid="error-panel">
        <div style={{ margin: 'auto 0', width: '100%' }}>
          <div className="mwi-content-margin">
            <div style={{ display: 'flex' }}>
              <div className="p-col-1 error-icons">
                <div style={{ margin: 'auto 0', display: 'flex' }}>
                  {/* caution icon */}
                  <i aria-hidden className="material-icons priority-icon">
                    priority_high
                  </i>
                </div>
              </div>
              <div className="p-col-10 error-msg" data-testid="error-msg">
                <div id="error-msg-wrapper" data-testid="error-msg-wrapper">
                  <FormattedMessage id={intl.formatMessage({ id: this.showErrorMsg() })} />
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.openHelp();
                    }}
                  >
                    <FormattedMessage id="common.system.error.link" />
                  </a>
                </div>
              </div>
              <div className="p-col-1 error-icons" role="button" onClick={this.hideErrorMsg} tabIndex="0">
                <div id="cancel-button-wrapper" style={{ margin: 'auto 0 auto auto', display: 'flex' }}>
                  <i aria-hidden className="material-icons priority-icon">
                    cancel
                  </i>
                  <MWISROnly label={intl.formatMessage({ id: 'common.close' })} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export const mapStateToProps = ({ app }) => {
  return { locale: app.locale, errorMessage: app.errorMessage };
};
const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ErrorPanel));
