import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './tab-view.css';

export class TabView extends Component {
  static propTypes = {
    id: PropTypes.string,
    activeIndex: PropTypes.number,
    style: PropTypes.object,
    navStyle: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node,
    renderActiveOnly: PropTypes.bool,
    onTabChange: PropTypes.func,
  };

  static defaultProps = {
    id: null,
    activeIndex: 0,
    style: null,
    navStyle: null,
    className: null,
    renderActiveOnly: true,
    onTabChange: null,
  };

  constructor(props) {
    super(props);
    if (!this.props.onTabChange) {
      this.state = {
        activeIndex: this.props.activeIndex,
      };
    }

    this.id = this.props.id;
  }

  isSelected(index) {
    const activeIndex = this.props.onTabChange ? this.props.activeIndex : this.state.activeIndex;

    return activeIndex === index;
  }

  onTabHeaderClick(event, tab, index) {
    if (!tab.props.disabled) {
      if (this.props.onTabChange) {
        this.props.onTabChange({ originalEvent: event, index });
      } else {
        this.setState({
          activeIndex: index,
        });
      }
    }

    event.preventDefault();
  }

  renderTabHeader(tab, index) {
    const selected = this.isSelected(index);
    const className = classNames(tab.props.headerClassName, 'p-unselectable-text', {
      'p-tabview-selected p-highlight': selected,
      'p-disabled': tab.props.disabled,
    });
    const id = `${this.id}_header_${index}`;
    const ariaControls = `${this.id}_content_${index}`;
    const tabIndex = tab.props.disabled ? '-1' : null;

    return (
      <li className={className} style={tab.props.headerStyle} role="presentation">
        <a
          role="tab"
          href={'#' + ariaControls}
          onClick={(event) => this.onTabHeaderClick(event, tab, index)}
          id={id}
          aria-controls={ariaControls}
          aria-selected={selected}
          tabIndex={tabIndex}
        >
          {tab.props.leftIcon && <span className={classNames('p-tabview-left-icon ', tab.props.leftIcon)} />}
          <span className="p-tabview-title">{tab.props.header}</span>
          {tab.props.rightIcon && <span className={classNames('p-tabview-right-icon ', tab.props.rightIcon)} />}
        </a>
      </li>
    );
  }

  renderTabHeaders() {
    return React.Children.map(this.props.children, (tab, index) => this.renderTabHeader(tab, index));
  }

  renderNavigator() {
    const headers = this.renderTabHeaders();

    return (
      <ul className="p-tabview-nav p-reset" style={this.props.navStyle} role="tablist">
        {headers}
      </ul>
    );
  }

  renderContent() {
    const contents = [];

    React.Children.forEach(this.props.children, (tab, index) => {
      if (!this.props.renderActiveOnly || this.isSelected(index)) {
        contents.push(this.createContent(tab, index));
      }
    });

    return <div className="p-tabview-panels">{contents}</div>;
  }

  createContent(tab, index) {
    const selected = this.isSelected(index);
    const className = classNames(tab.props.contentClassName, 'p-tabview-panel', { 'p-hidden': !selected });
    const id = `${this.id}_content_${index}`;
    const ariaLabelledBy = `${this.id}_header_${index}`;

    return (
      <div
        key={index}
        id={id}
        aria-labelledby={ariaLabelledBy}
        aria-hidden={!selected}
        className={className}
        style={tab.props.contentStyle}
        role="tabpanel"
      >
        {!this.props.renderActiveOnly ? tab.props.children : selected && tab.props.children}
      </div>
    );
  }

  render() {
    const className = classNames('p-tabview p-component p-tabview-top', this.props.className);
    const navigator = this.renderNavigator();
    const content = this.renderContent();

    return (
      <div id={this.props.id} className={className} style={this.props.style} role="container-tabview">
        {navigator}
        {content}
      </div>
    );
  }
}
