import { calculateMWINumberFieldValue, formatNumberValue, handleFocus } from '../../utils';

import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import React from 'react';

const MWINumberField = ({
  id,
  label,
  styleClass,
  onBlur,
  onChange,
  value,
  minValue,
  maxValue,
  disabled,
  getMax,
  containerClass,
  suffix,
  prefix,
  decimalSeparator,
  decimalScale,
  isCustom,
  placeholder,
  locale,
  fixedDecimalScale,
  thousandSeparator,
  allowNegative,
  autoComplete,
  role,
  name,
}) => (
  <div className={`mwi-control-wrapper ${styleClass}`} role="container-mwinumberfield">
    {label && (
      <div className="mwi-label" role="container-mwinumberfieldlabel">
        <label htmlFor={id} aria-required="true">
          {label}
        </label>
      </div>
    )}
    <div>
      <NumberFormat
        className={`mwi-widget mwi-input number-data ${containerClass}`}
        //logic to check the value will not change
        value={isCustom ? '' : calculateMWINumberFieldValue(value, minValue, maxValue, getMax)}
        allowNegative={allowNegative ? allowNegative : false}
        decimalSeparator={decimalSeparator}
        onValueChange={(values) => (onChange ? onChange(id, values.value) : '')}
        onBlur={(e) => {
          onBlur(
            id,
            calculateMWINumberFieldValue(formatNumberValue(e.target.value, locale), minValue, maxValue, getMax)
          );
        }}
        id={id}
        autoComplete={autoComplete ? autoComplete : 'off'}
        aria-required="true"
        disabled={disabled}
        onFocus={handleFocus}
        fixedDecimalScale={fixedDecimalScale}
        suffix={suffix}
        prefix={prefix}
        decimalScale={decimalScale}
        thousandSeparator={thousandSeparator}
        placeholder={placeholder}
        role={role}
        name={name}
      />
      {/* <div className="mwi-error-msg">{errors[eField.coverageAmount]}</div> */}
    </div>
  </div>
);

export default MWINumberField;

MWINumberField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  styleClass: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  disabled: PropTypes.bool,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  getMax: PropTypes.bool,
  containerClass: PropTypes.string,
  decimalSeparator: PropTypes.string,
  decimalScale: PropTypes.number,
  isCustom: PropTypes.bool,
  placeholder: PropTypes.string,
  locale: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
  thousandSeparator: PropTypes.string,
  allowNegative: PropTypes.bool,
  autoComplete: PropTypes.string,
  role: PropTypes.string,
  name: PropTypes.string,
};

MWINumberField.defaultProps = {
  disabled: false,
  getMax: false,
  containerClass: 'mwi-w90',
  decimalSeparator: 'false',
  decimalScale: 0,
  fixedDecimalScale: false,
  suffix: '',
  prefix: '',
};
