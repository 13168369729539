import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { eProduct, eRider } from '../../../core/configs';
import Child from '../../child';
import RatingsButton from '../../../core/components/ratings-button';
import * as AppActions from '../../../core/actions';
import * as RiderActions from '../actions';
import * as ScenarioActions from '../../scenario-tab-navs/actions';
import './cpr.css';

export class ChildProtectionRider extends PureComponent {
  static propTypes = {
    app: PropTypes.object,
    inforce: PropTypes.bool,
    udmEnabled: PropTypes.bool,
    cpr: PropTypes.object,
    product: PropTypes.string,
    appActions: PropTypes.object,
    riderActions: PropTypes.object,
    sceActions: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.addChild = this.addChild.bind(this);
    this.removeChild = this.removeChild.bind(this);
    this.saveRatings = this.saveRatings.bind(this);
  }

  saveRatings(childId, ratings) {
    this.props.riderActions.updateChildRatings({ childId, ratings });
    this.props.sceActions.toggleOutOfDate(true);
  }

  addChild() {
    // TODO pass scenario id for multiple scenarios
    this.props.riderActions.addChild();
    this.props.sceActions.toggleOutOfDate(true);
  }

  removeChild(childId) {
    this.props.riderActions.removeChild(childId);
    this.props.sceActions.toggleOutOfDate(true);
  }

  // getRatings(childId) {
  //   return {
  //     permRatingAmount: this.props.cpr[childId][`permRatingAmount`],
  //     permRatingPercent: this.props.cpr[childId][`permRatingPercent`],
  //     tempRatingAmount: this.props.cpr[childId][`tempRatingAmount`],
  //     tempRatingYear: this.props.cpr[childId][`tempRatingYear`]
  //   };
  // }

  render() {
    const { app, cpr, product, udmEnabled, inforce } = this.props;
    const maximumChildren = 4;
    return (
      ![eProduct.Performax, eProduct.SB].includes(product) &&
      <div className="p-grid p-grid-no-margin cpr-wrapper" style={{ overflow: 'hidden' }} role="container-cpr">
        {cpr.allChildren.map((childId, index) => (
          <div className="p-col-12 childDivider" key={childId}>
            <div className="rider-label" role="heading" aria-level="3">
              <span className="label-group">
                <i className="material-icons" aria-hidden="true">
                  account_circle
                </i>
                <FormattedMessage id="rider.child" /> {index + 1}
                {/* <div className="riderChildBorder" /> */}
                {!inforce && cpr.allChildren.length !== 1 && (
                  <div
                    role="button"
                    className={`delete-wrapper ${app.locale}`}
                    onClick={() => this.removeChild(childId)}
                  >
                    <i className="material-icons delete-child">remove_circle_outline</i>
                    {/* <FormattedMessage id="rider.remove" /> */}
                  </div>
                )}
              </span>
            </div>
            {udmEnabled && <div className="udm-id">{`${childId} - ${cpr[childId].riderId}`}</div>}

            <Child app={app} child={cpr[childId]}>
              {product !== eProduct.PAR && product !== eProduct.MUL && product !== eProduct.PG && (
                <RatingsButton
                  onSave={(ratings) => this.saveRatings(childId, ratings)}
                  // ratingData={this.getRatings(childId)}
                  ratings={cpr}
                  partyId={childId}
                  containerStyle="ratings"
                  buttonStyle="button-width"
                  locale={app.locale}
                  inforce={inforce}
                  product={product}
                />
              )}
            </Child>
          </div>
        ))}
        {!this.props.inforce && cpr.allChildren.length < maximumChildren && (
          <div className="p-col-12 childDivider">
            <div tabIndex="0" className="add-wrapper" onClick={this.addChild} role="container-addcpr">
              <i className="material-icons mwi-icon add-child">add_circle_outline</i>
              <span>
                <FormattedMessage id="rider.addchild" />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// TODO inject scenarioTabNavs to get scenario id
const mapStateToProps = ({ app, scenarioTabNavs, riders }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  return {
    app,
    inforce: app.inforcePolicy,
    cpr: riders[scenarioTabNavs.activeTabId][eRider.cpr],
    product: activeScenario.product,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  riderActions: bindActionCreators(RiderActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChildProtectionRider);
