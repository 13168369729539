import React from 'react';
import PropTypes from 'prop-types';
import MWIButton from '../mwi-button';
import { injectIntl } from 'react-intl';
import ReactModal from 'react-modal';
import './status-dialog.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AppActions from '../../../core/actions';

export const StatusDialog = ({ intl, isOpen, statusMessage, appActions, contentLabel}) => {

  return (
    <ReactModal
      isOpen={isOpen}
      className="status-dialog-wrapper"
      contentLabel={contentLabel}
      onRequestClose={() => appActions.hideStatusDialog()}
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      role="dialog"
      aria={{
        modal: 'true',
      }}
      ariaHideApp
    >
      <div className="p-grid p-grid-no-margin-side">
        <h2 className="p-col-12 title-model title-border">{contentLabel}</h2>
        <div className="p-col-12 row-model" role="container-statusmessage">
          {statusMessage}
        </div>
        <div className="p-col-12 dialog-footer flexdisplay">
          <div className="p-col-3">
            <MWIButton
              name="okay"
              label={intl.formatMessage({ id: 'common.ok' })}
              onClick={() => appActions.hideStatusDialog()}
              styleClass="mwi-w100 ok-button oneflex"
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

StatusDialog.propTypes = {
  intl: PropTypes.object,
  isOpen: PropTypes.bool,
  statusMessage: PropTypes.string,
  appActions: PropTypes.object,
  contentLabel: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch)
});

export default connect(null, mapDispatchToProps)(injectIntl(StatusDialog));
