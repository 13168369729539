import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import './landing-page.css';

export class LifechequeDescription extends PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className="product-description margin-bottom10">
          <FormattedMessage id="landing.lc.productDescription" />
        </div>
          <div>
              <ul className="details-list">
                  {
                      Array.from({length: 5}, (v, i) => i).map((index) => (
                          <li key={index}><FormattedMessage id={`landing.lc.productBullet${index}`}/></li>))
                  }
              </ul>
          </div>
        <div className="product-description">
          <FormattedMessage id="landing.lc.productDescription2" />
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(LifechequeDescription));
