import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Growl } from 'primereact/growl';
import {
  appLaunchDate,
  appLaunchDatePay100,
  eField,
  eUserType,
  rateChangeProducts,
  eProduct,
  ePremiumDuration,
} from '../../configs';
import MWIButton from '../mwi-button';
import { isDevFeatureFlagSet, screenValidator } from '../../utils';
import MWIDob from '../mwi-dob';
import * as AppActions from '../../actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import MWISrOnly from '../mwi-sr-only';
//HEADOFFICE TEMP SOLUTION
import moment from 'moment';
import { DATE_FORMAT } from '../../constants';

export class EffectiveDateModal extends PureComponent {
  static propTypes = {
    configs: PropTypes.object,
    locale: PropTypes.string,
    product: PropTypes.string,
    effectiveDate: PropTypes.string,
    scenarioTabId: PropTypes.string,
    topBarErrors: PropTypes.object,
    intl: PropTypes.object,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    userType: PropTypes.string,
    closeEffectiveDateModal: PropTypes.func,
    title: PropTypes.string,
    isVitalityPlusSelected: PropTypes.bool,
    isPremiumDurationPay100: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      effectiveDate: this.props.effectiveDate,
      isErrorOnSave: false,
    };

    this.onChangeEffectiveDate = this.onChangeEffectiveDate.bind(this);
    this.onBlurEffectiveDate = this.onBlurEffectiveDate.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isValidEffectiveDate = this.isValidEffectiveDate.bind(this);
    this.isPremiumDurationPay100 = this.isPremiumDurationPay100.bind(this);
    this.compareEffectiveAndLaunchDates = this.compareEffectiveAndLaunchDates.bind(this);
    this.getAppLaunchDate = this.getAppLaunchDate.bind(this);
  }

  isPremiumDurationPay100() {
    // Pay 100 only
    const effectiveDateChangeDate =
      this.props.isPremiumDurationPay100 && this.props.isVitalityPlusSelected
        ? appLaunchDatePay100.ParVitality
        : appLaunchDatePay100.Par;
    return effectiveDateChangeDate;
  }

  isValidEffectiveDate() {
    let validEffectiveDate = false;
    validEffectiveDate = this.compareEffectiveAndLaunchDates(this.getAppLaunchDate());
    if (this.props.product === eProduct.LC && this.props.userType === eUserType.headOffice) {
      validEffectiveDate = this.effectiveDateForLifechequeHeadOffice(appLaunchDate.Lifecheque);
    }
    if (this.props.isPremiumDurationPay100) {
      validEffectiveDate = this.compareEffectiveAndLaunchDates(this.isPremiumDurationPay100());
    }
    return validEffectiveDate;
  }

  getAppLaunchDate() {
    const product = this.props.isVitalityPlusSelected ? this.props.product + 'Vitality' : this.props.product;
    return !appLaunchDate[product] ? '' : appLaunchDate[product];
  }

  compareEffectiveAndLaunchDates(launchDate) {
    return moment(this.state.effectiveDate, DATE_FORMAT).isSameOrAfter(moment(launchDate, DATE_FORMAT));
  }

  // Now does the same as above. Revisit specific requirement for HO users and remove this
  // if no longer a factor.
  effectiveDateForLifechequeHeadOffice(launchDate) {
    return moment(this.state.effectiveDate, DATE_FORMAT).isSameOrAfter(moment(launchDate, DATE_FORMAT));
  }

  onChangeEffectiveDate(name, value) {
    this.setState({ effectiveDate: value });
  }

  onBlurEffectiveDate(name, value) {
    this.setState({ effectiveDate: value });
  }

  handleMULRatesNotification(value, currentDate, lastRateChange) {
    const { intl } = this.props;
    if (this.formatDate(value) <= lastRateChange && lastRateChange <= currentDate) {
      this.dispatchNotification('showStatusDialog', null, [
        intl.formatMessage({ id: 'common.information' }),
        intl.formatMessage({ id: 'common.rateChange' }),
      ]);
    } else {
      this.dispatchNotification('hideStatusDialog');
    }
  }

  save(type, value) {
    if (!this.isValidEffectiveDate()) {
      this.setState({
        isErrorOnSave: true,
        effectiveDate: this.props.isPremiumDurationPay100 ? this.isPremiumDurationPay100() : this.getAppLaunchDate(),
      });
      return;
    }
    this.dispatchAction(type, value);
    //HEADOFFICE TEMP SOLUTION
    if (
      isDevFeatureFlagSet() &&
      moment(value, DATE_FORMAT).format(DATE_FORMAT) === moment('01/01/2019', DATE_FORMAT).format(DATE_FORMAT)
    ) {
      this.dispatchAction('userType', eUserType.advisor, 'app');
    }
    if (rateChangeProducts[this.props.product]) {
      const currentDate = new Date().setHours(0, 0, 0, 0);
      const lastRateChange = this.formatDate(rateChangeProducts[this.props.product]);
      if (this.props.product === eProduct.MUL) {
        this.handleMULRatesNotification(value, currentDate, lastRateChange);
      } else if (this.formatDate(value) <= lastRateChange && lastRateChange <= currentDate) {
        this.dispatchNotification('showRatesNotification');
      } else {
        this.dispatchNotification('hideRatesNotification');
      }
    }
    this.props.sceActions.toggleOutOfDate(true);
    this.props.sceActions.toggleManualRefresh(true);
    this.props.closeEffectiveDateModal();
  }

  formatDate = (date) => {
    const splitDate = date.split('/');
    const yearPos = 2;
    return new Date(splitDate[yearPos], splitDate[1] - 1, splitDate[0]).setHours(0, 0, 0, 0);
  };

  cancel() {
    this.props.closeEffectiveDateModal();
  }
  dispatchNotification(type, field, value) {
    this.props?.appActions[type]({
      field,
      value,
    });
  }

  dispatchAction(field, value, target = 'scenario') {
    this.props.appActions.dispatchMWIOnChange({
      target,
      field,
      value,
      scenarioTabId: this.props.scenarioTabId,
    });
  }

  render() {
    const { intl, title, scenarioTabId, locale, product, userType, topBarErrors } = this.props;
    return (
      <div role="container-effectivedatemodal">
        <Growl ref={(el) => (this.growl = el)} />
        <h2 className="p-col-12 title-model ">{title}</h2>
        <div className="p-col-12 row-model">
          {screenValidator(product).effDateAvailable && userType === eUserType.headOffice && (
            <MWIDob
              containerClass="p-col-12 dropdown-container"
              name="effectiveDate"
              labelId={scenarioTabId}
              label=""
              value={this.state.effectiveDate}
              locale={locale}
              placeholder={intl.formatMessage({ id: 'common.date.placeholder' })}
              errors={topBarErrors}
              onBlur={this.onBlurEffectiveDate}
              onChange={this.onChangeEffectiveDate}
              ariaLabel={`${intl.formatMessage({ id: 'topbar.effectivedate' })} 
                      ${intl.formatMessage({ id: 'common.date.placeholder' })}`}
              topBarFieldStyle="dropdown-text"
              labelStyle="remove-label-margin"
              styleClass="no-margin"
            />
          )}
        </div>
        {this.state.isErrorOnSave && (
          <div className="p-grid p-grid-no-margin change-error-box">
            <div className="message-box" role="alert">
              <div className="message error" role="alert">
                <div style={{ width: '7%', display: 'inline-flex' }}>
                  <i aria-hidden className="material-icons msg-icon">
                    {' '}
                    cancel{' '}
                  </i>
                  <MWISrOnly label={intl.formatMessage({ id: 'common.error' })} />
                </div>
                <div className="out-of-date" style={{ width: '93%', display: 'inline-flex' }}>
                  <FormattedMessage id="change-product-effectivedate-backdated" />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="p-col-12 mwi-w89 modal-footer flexdisplayCenter">
          <MWIButton
            name="cancel"
            title="cancel"
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={this.cancel}
            styleClass="mwi-w50 cancel-modal cancel-btn oneflex p-col-4"
          />
          <MWIButton
            name="save"
            title="save"
            label={intl.formatMessage({ id: 'common.save' })}
            onClick={() => this.save(eField.effectiveDate, this.state.effectiveDate)}
            styleClass="mwi-w50 save-modal save-button oneflex p-col-4"
          />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs }) => {
  const topbar = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const isVitalityPlusSelected = coverageState[coverageState.activeTabId].isVitalityPlusSelected;
  const isPremiumDurationPay100 = coverageState[coverageState.activeTabId].premiumDuration === ePremiumDuration.Pay100;

  return {
    locale: app.locale,
    scenarioTabId: scenarioTabNavs.activeTabId,
    product: topbar.product,
    effectiveDate: topbar.effectiveDate,
    topBarErrors: topbar.errors,
    userType: app.userType,
    isVitalityPlusSelected,
    isPremiumDurationPay100,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EffectiveDateModal));
