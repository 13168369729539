import {
  LOAD_COVERAGE_TABNAVS,
  ADD_COVERAGE_TABNAVS,
  REMOVE_COVERAGE_TABNAVS,
  CLICK_COVERAGE_TABNAVS,
  RESET_RATINGS,
  SAVE_RATINGS,
  COVERAGE_INITIALIZE,
  COVERAGE_TYPE
} from '../../core/actionTypes';
import { makeActionCreator } from '../../core/utils';

export const loadCoverageTabNavs = makeActionCreator(LOAD_COVERAGE_TABNAVS);
export const clickCoverageTabNavs = makeActionCreator(CLICK_COVERAGE_TABNAVS);
export const addCoverageTabNavs = makeActionCreator(ADD_COVERAGE_TABNAVS);
export const removeCoverageTabNavs = makeActionCreator(REMOVE_COVERAGE_TABNAVS);
export const resetRatings = makeActionCreator(RESET_RATINGS);
export const saveRatings = makeActionCreator(SAVE_RATINGS);
export const initilaizeCoverage = makeActionCreator(COVERAGE_INITIALIZE);
export const coveragetype = makeActionCreator(COVERAGE_TYPE);
