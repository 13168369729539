import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  eField,
  eDefault,
  eSex,
  eProduct,
  eDurationType,
  eCoverageType,
  changeToBronzeClientAgeDifference,
} from '../../configs';
import MWIInput from '../../../core/components/mwi-input';
import MWIDob from '../../../core/components/mwi-dob';
import MWISelect from '../../../core/components/mwi-select';
import { validate } from './validator';
import { getFullName, getAdvancedAge, calculateMWINumberFieldValue } from '../../../core/utils';

export class Person extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    person: PropTypes.object.isRequired,
    isChild: PropTypes.bool,
    personId: PropTypes.string,
    uniqueId: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    deposits: PropTypes.object,
    validate: PropTypes.func,
    intl: PropTypes.object,
    effectiveDate: PropTypes.string,
    clients: PropTypes.object,
    coverageTabNavs: PropTypes.object,
    coverage: PropTypes.string,
    changeToBronzeAge: PropTypes.number,
    product: PropTypes.string,
    isDefaultChangeToBronzeAge: PropTypes.bool,
  };

  static defaultProps = {
    isChild: false,
    personId: '',
  };

  constructor(props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetDOB = this.resetDOB.bind(this);

    this.state = { ageUpdated: false };
  }

  updateDurationsByAgeSingle(newAge) {
    const { deposits, clients } = this.props;
    const { activeTabId } = deposits;
    const { durationEnd, durationType } = deposits[activeTabId];
    const deposit = deposits[activeTabId];
    const clientAge =
      clients[activeTabId] &&
      deposit[eField.basedonClient] &&
      clients[activeTabId][deposit[eField.basedonClient]] &&
      clients[activeTabId][deposit[eField.basedonClient]].age;

    if (durationType === eDurationType.age && newAge >= durationEnd) {
      this.props.onBlur(eField.durationFrom, newAge + 1);
      this.props.onBlur(eField.durationTo, newAge + 1);
    } else if (durationType === eDurationType.year && newAge >= durationEnd + clientAge) {
      this.props.onBlur(eField.durationFrom, 1);
      this.props.onBlur(eField.durationTo, 1);
    } else {
      /* skip */
    }
  }

  updateDurationsByAgeJoint(newAge) {
    const { deposits, clients } = this.props;
    const { activeTabId } = deposits;
    const { durationEnd, durationType } = deposits[activeTabId];
    const deposit = deposits[activeTabId];
    const clientAge =
      clients[activeTabId] &&
      deposit[eField.basedonClient] &&
      clients[activeTabId][deposit[eField.basedonClient]] &&
      clients[activeTabId][deposit[eField.basedonClient]].age;

    if (
      (durationType === eDurationType.age && newAge >= durationEnd) ||
      (durationType === eDurationType.year && newAge >= durationEnd + clientAge)
    ) {
      this.props.onBlur(eField.durationTo, null);
    }
    !deposit.isDurationSet && this.props.onBlur(eField.durationTo, null);
  }

  onChangeValidation(type, value) {
    return (
      (type === eField.fname || type === eField.lname) &&
      (/[0-9]/.test(value) || this.onChangeTypeValidations(type, value))
    );
  }
  onChangeTypeValidations(type, value) {
    const fNameMaxLength = 20;
    const lNameMaxLength = 25;
    if (
      (type === eField.fname && value.length > fNameMaxLength) ||
      (type === eField.lname && value.length > lNameMaxLength)
    ) {
      return true;
    }
    return false;
  }

  onChangeValueValidation(value) {
    if (value < 0 || value % 1 !== 0 || (typeof value === 'string' && value.indexOf('.') !== -1)) {
      return true;
    }
    return false;
  }

  onChange(type, value) {
    const error = validate(type, value, this.props.locale);

    if (this.onChangeValidation(type, value)) {
      return false;
    }

    // selected button is clicked
    if (type === eField.sex && !value) {
      return false;
    }

    if (type === eField.age) {
      this.setState({ ageUpdated: true });
      this.props.onChange(type, '', true); // reset error for age
      this.props.onChange(eField.dob, ' '); // reset dob using space to bypass input mask
      this.props.onChange(eField.dob, '', true); // reset error for dob
      this.updateBronzeAfterAge(value);

      const maxAge = 99;
      // this check can be a part of validation but showing error message after onBlur would be bad UX
      // so just block non-number
      if (isNaN(+value) || value > maxAge || this.onChangeValueValidation(value)) {
        return false;
      }
    }
    if (type === eField.dob && !value) {
      this.props.onChange(eField.dob, '', true); // reset error for dob
    }
    this.performOnChange(type, value, error);
    return null;
  }

  updateBronzeAfterAge(clientAge) {
    const { changeToBronzeAge, isDefaultChangeToBronzeAge } = this.props;
    const { product } = this.props.coverageTabNavs;
    const minAgeIncrease =
      product === eProduct.PAR ? changeToBronzeClientAgeDifference.PAR : changeToBronzeClientAgeDifference.MUL;
    const defaultMinAgeIncrease = 20;
    const minAge = +clientAge + minAgeIncrease;
    const maxAge = 100;
    let updatedChangeToBronzeAge;
    if (isDefaultChangeToBronzeAge && this.isMULorPar(product)) {
      updatedChangeToBronzeAge = calculateMWINumberFieldValue(+clientAge + defaultMinAgeIncrease, minAge, maxAge);
      this.props.onChange(eField.changeToBronzeAge, updatedChangeToBronzeAge, 'coverage', false);
    } else {
      if (changeToBronzeAge < +clientAge + minAgeIncrease && this.isMULorPar(product)) {
        updatedChangeToBronzeAge = calculateMWINumberFieldValue(+clientAge + minAgeIncrease, minAge, maxAge);
        this.props.onChange(eField.changeToBronzeAge, updatedChangeToBronzeAge, 'coverage', false);
      }
    }
  }

  isMULorPar(product) {
    return product === eProduct.MUL || product === eProduct.PAR;
  }

  performOnChange(type, value, error) {
    if (type === eField.dob && !error && value.length) {
      this.props.onBlur(type, value);

      const ageFromDob = getAdvancedAge(value, this.props.effectiveDate);

      this.props.onBlur(eField.age, ageFromDob);
      this.updateBronzeAfterAge(ageFromDob);
      const { product } = this.props.coverageTabNavs;
      const { coverage } = this.props;
      product === eProduct.MUL && coverage === eCoverageType.single && this.updateDurationsByAgeSingle(ageFromDob);
      product === eProduct.MUL && coverage !== eCoverageType.single && this.updateDurationsByAgeJoint(ageFromDob);
    }
    this.props.onChange(type, value);
  }

  onBlurName(type) {
    if (!(type === eField.fname || type === eField.lname)) {
      return;
    }
    if (this.props.person.errors.firstName || this.props.person.errors.lastName) {
      return;
    }

    const fullName = getFullName(this.props.person);
    this.props.onBlur(eField.fullName, fullName);
  }

  onBlurAge(type, value) {
    if (type !== eField.age) {
      return true;
    }
    if (!value) {
      this.props.onBlur(eField.age, eDefault[this.props.isChild ? 'childAge' : 'clientAge']);
      return false;
    }

    const { product } = this.props.coverageTabNavs;
    const { coverage } = this.props;
    product === eProduct.MUL && coverage === eCoverageType.single && this.updateDurationsByAgeSingle(+value);
    product === eProduct.MUL && coverage !== eCoverageType.single && this.updateDurationsByAgeJoint(+value);
    this.props.onBlur(type, value);

    return true;
  }

  onBlur(type, value) {
    // if no error, it returns empty string so that it can reset error message
    let error = validate(type, value, this.props.locale);
    // custom validator
    if (!error && this.props.validate) {
      error = this.props.validate(type, value);
    }

    if (!this.onBlurAge(type, value)) {
      return false;
    }

    this.props.onBlur(type, error, true);
    this.onBlurName(type);
    return null;
  }

  resetDOB() {
    if (this.state.ageUpdated) {
      this.props.onChange(eField.dob, '');
    }
    this.setState({ ageUpdated: false });
  }

  render = () => {
    const { intl, person, personId, uniqueId } = this.props;

    return (
      <React.Fragment>
        <MWIInput
          styleClass="first-name mwi-margin-bottom24 mwi-w100"
          name="firstName"
          containerClass="p-sm-4"
          disabled={this.props.inforce}
          labelId={`${personId}.${uniqueId}`}
          label={<FormattedMessage id="party.firstName" />}
          placeholder={intl.formatMessage({ id: 'party.firstName' })}
          value={person.firstName}
          errors={person.errors}
          onBlur={this.onBlur}
          onChange={this.onChange}
          grid="4"
        />

        <MWIInput
          styleClass="last-name mwi-margin-bottom24 mwi-w100"
          name="lastName"
          containerClass="p-sm-4"
          disabled={this.props.inforce}
          labelId={`${personId}.${uniqueId}`}
          label={<FormattedMessage id="party.lastName" />}
          placeholder={intl.formatMessage({ id: 'party.lastName' })}
          value={person.lastName}
          errors={person.errors}
          onBlur={this.onBlur}
          onChange={this.onChange}
          grid="4"
        />
        <div className="p-col-4 p-lg-4 p-xl-3 p-sm-4 mwi-control-wrapper">
          <div className="mwi-label" id="personSex">
            <span>
              <FormattedMessage id="party.sex" />
            </span>
          </div>
          <MWISelect
            containerStyle="p-col-10 p-lg-10 p-sm-12"
            disabled={this.props.inforce}
            ariaDescribedBy="personSex"
            labelL={intl.formatMessage({ id: 'common.male' })}
            labelR={intl.formatMessage({ id: 'common.female' })}
            onClickL={() => this.onChange('sex', eSex.male)}
            onClickR={() => this.onChange('sex', eSex.female)}
            selectedL={eSex.male === person.sex}
            selectedR={eSex.female === person.sex}
          />
        </div>

        {/* eslint-disable */}
        {!this.props.inforce && (
          <MWIDob
            containerClass="p-xl-3 p-lg-3 p-sm-6"
            grid="2"
            name="dob"
            labelId={`${personId}.${uniqueId}`}
            label={intl.formatMessage({ id: 'party.dob' })}
            value={person.dob}
            locale={this.props.locale}
            placeholder={intl.formatMessage({ id: 'common.date.placeholder' })}
            errors={person.errors}
            onBlur={this.onBlur}
            onChange={this.onChange}
            ariaLabel={`${intl.formatMessage({ id: 'party.dob' })} DD MM YYYY`}
            ariaLive
          />
        )}
        {/* eslint-enable */}
        <MWIInput
          name="age"
          labelId={`${personId}.${uniqueId}`}
          label={intl.formatMessage({ id: 'party.age' })}
          value={person.age}
          locale={this.props.locale}
          disabled={this.props.inforce}
          isRequired
          styleClass="number-data"
          containerClass="p-lg-1 p-sm-6"
          grid="2"
          errors={person.errors}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onComplete={this.resetDOB}
          ariaLive
        />
      </React.Fragment>
    );
  };
}

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs, deposits, clients }) => {
  const topbar = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];

  const activeTabId = coverageTabNavs.activeTabId;
  const activeScenario = coverageTabNavs[activeTabId];

  const activeCoverageId = activeScenario.activeTabId;
  const activeCoverageScenario = activeScenario[activeCoverageId];
  const coverage = coverageTabNavs[activeTabId][activeCoverageId];
  const { changeToBronzeAge, isDefaultChangeToBronzeAge } = coverage;

  return {
    locale: app.locale,
    inforce: app.inforcePolicy,
    coverage: activeCoverageScenario.coverageType,
    effectiveDate: topbar.effectiveDate,
    coverageTabNavs,
    deposits,
    clients,
    changeToBronzeAge,
    isDefaultChangeToBronzeAge,
  };
};

export default withRouter(connect(mapStateToProps)(injectIntl(Person)));
