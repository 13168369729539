import React, { PureComponent } from 'react';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getVitalityStatusSimulatorUrl } from '../core/utils';

export class VitalityLinks extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
  };

  render() {
    return (
      <>
        <div className="p-col-12 product-description">
          <a
            href={this.props.intl.formatMessage({ id: 'landing.announcement.link2' })}
            target={'_blank'}
            rel="noopener noreferrer"
            className="url-link"
          >
            <FormattedHTMLMessage id="landing.all.learnVitality" />
          </a>
        </div>

        <div className="p-col-12 product-description">
          <a
            href={getVitalityStatusSimulatorUrl(this.props.locale)}
            target="_blank"
            rel="noopener noreferrer"
            className="url-link"
          >
            <FormattedHTMLMessage id="landing.all.vitalitySimulator" />
          </a>
        </div>
      </>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(VitalityLinks));
