import * as actionTypes from '../../../../core/actionTypes';
import {
  eSex,
  eDefaultClient,
  ePrefix,
  eProduct,
  eHS,
  eField,
  eDefaultRatings,
  eBusinessType,
  eSmokingStatus,
  eCoverageType,
} from '../../../../core/configs';
import { assignDef, getValidStatusForInforce } from '../../../../core/utils';
import _ from 'lodash';
import { scenarioSeq } from '../../../../core/sequenceUtils';
import { client1Mock, client2Mock } from '../../../../core/mocks/stateMock';
import * as reducerHelper from '../../../../../src/helper/reducer-helper';

const defaultClient = { ...eDefaultClient };

// TODO multiple scenario case like coverage-tab-navs
const initialState = {
  activeTabId: scenarioSeq(true),
  clientIndex: 1,
  inforce: false,
  [scenarioSeq(true)]: { allClients: [] },
};

const getNewClientState = (state, payload, newClientIndex, product = null) => {
  const { coverageTabId, clientId, isFemale } = payload;
  const activeTabId = state.activeTabId;
  const activeState = state[activeTabId];
  const sex = isFemale ? eSex.female : eSex.male;
  const index = activeState.allClients.length;
  const placeholder = `${ePrefix.client} ${index + 1}`;

  if (product === eProduct.LC) {
    defaultClient.age = 40;
  } else {
    defaultClient.age = eDefaultClient.age;
  }

  return {
    ...state,
    clientIndex: newClientIndex,
    [activeTabId]: {
      ...activeState,
      allClients: [...activeState.allClients, clientId],
      [clientId]: {
        ...defaultClient,
        id: clientId,
        coverageTabId: [coverageTabId],
        sex,
        placeholder,
      },
    },
  };
};

const removeAssociatedClient = (newState, targetClientId, removedCoverageId) => {
  if (newState[targetClientId].coverageTabId.length === 1) {
    newState = _.omit(newState, targetClientId);
    newState.allClients = _.filter(newState.allClients, (clientId) => clientId !== targetClientId);
  } else {
    if (newState[targetClientId].coverageTabId.length > 1) {
      _.remove(newState[targetClientId].coverageTabId, (covId) => covId === removedCoverageId);
    }
  }
  return newState;
};

const reduceOpenSavedCase = (targetState) => {
  // Logic for previously saved cases before we added clientIndex to the state
  if (targetState.clientIndex === undefined) {
    targetState.clientIndex = 1000; // Set to 1000 which is a least likely clientID to get to in a session
  }
  assignDef(targetState, initialState);
  return {
    ...targetState,
  };
};

const reduceRemoveSelectClient = (state, payload) => {
  // when select a client from insured dropdown
  // removed client from the current coverage
  const targetClientId = payload.clientId || payload.prevClientId;
  const { coverageTabId, selectedClientId } = payload;

  // 1. check if targetClientId is not associated with another coverages
  // 2. if targetClient is associated with mulitple coverages, remove this coverageTabId
  // 3. add this coverageTabId to the selectedClientId
  const newState = removeAssociatedClient(_.cloneDeep(state[state.activeTabId]), targetClientId, coverageTabId);

  // no selectedClientId for remove_client action
  selectedClientId && newState[selectedClientId].coverageTabId.push(coverageTabId);

  // return newState;
  return {
    ...state,
    [state.activeTabId]: newState,
  };
};

const reduceRemoveCoverageTabnavs = (state, payload) => {
  // when coverage is removed
  const removedCoverageId = payload.coverageTabId;
  const clientsBeingRemoved = payload.clientIds;

  let newState = _.cloneDeep(state[state.activeTabId]);

  _.forEach(clientsBeingRemoved, (targetClientId) => {
    newState = removeAssociatedClient(newState, targetClientId, removedCoverageId);
  });

  return {
    ...state,
    [state.activeTabId]: newState,
  };
};

const reduceOnChange = (state, payload) => {
  if (payload.target === 'client') {
    const { clientId, data } = payload;
    const changedData = data ? data : { [payload.field]: payload.value };

    return {
      ...state,
      [state.activeTabId]: {
        ...state[state.activeTabId],
        [clientId]: {
          ...state[state.activeTabId][clientId],
          ...changedData,
        },
      },
    };
  }

  return state;
};

const reduceUpdateError = (state, payload) => {
  if (payload.target === 'client' || payload.target === 'scenario') {
    const { clientId, field, value } = payload;

    return {
      ...state,
      [state.activeTabId]: {
        ...state[state.activeTabId],
        [clientId]: {
          ...state[state.activeTabId][clientId],
          errors: {
            ...state[state.activeTabId][clientId].errors,
            [field]: value,
          },
        },
      },
    };
  }

  return state;
};

const reduceLoadedUdmResponse = (state, illustration) => {
  const newClients = _.cloneDeep(state[state.activeTabId]);

  // Update each client age from UDM response
  if (illustration) {
    if (
      illustration.party &&
      illustration.policy &&
      (illustration.policy.businessType !== eBusinessType.IF || illustration.policy.product === eProduct.FT)
    ) {
      _.forEach(state[state.activeTabId].allClients, (clientId) => {
        newClients[clientId].age = illustration.party.find((party) => party.partyId === clientId).age;
      });
    }

    // return newClients;
    return {
      ...state,
      [state.activeTabId]: newClients,
    };
  }

  return {
    ...state,
  };
};

const clientReducer3 = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_SCENARIO_TABNAVS: {
      return reducerHelper.getAddScenarioTabNavsState(state, action);
    }

    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return reducerHelper.getClickScenarioTabNavsState(state, action);
    }

    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      return reducerHelper.getRemoveScenarioTabNavsState(state, action);
    }

    case actionTypes.LOADED_INFORCE_POLICY: {
      const inforceIllustration = action.payload.policyData.illustration;
      const coverages = inforceIllustration.policy.coverage;
      const parties = inforceIllustration.party;
      const product = inforceIllustration.policy.product;
      const newState = loadInforceClients(coverages, parties, product);

      return {
        ...newState,
      };
    }

    default:
      return state;
  }
};

const loadInforceClients = (coverages, parties, product) => {
  const newState = {
    activeTabId: scenarioSeq(true),
    clientIndex: 1,
    inforce: false,
    [scenarioSeq(true)]: { allClients: [] },
  };
  const activeTabId = newState.activeTabId;
  const baseCoverageIndex = coverages.findIndex(
    (cvg) => cvg.indicatorCode === eField.indicatorCode.base && getValidStatusForInforce(product).includes(cvg.status)
  );
  newState.inforce = true;
  parties.forEach((party) => {
    const client = loadInforceClient(party, coverages, baseCoverageIndex, product);

    newState[activeTabId].allClients.push(client.id);
    newState[activeTabId][client.id] = client;
    newState.clientIndex = newState.clientIndex + 1;
  });
  return newState;
};

const getInforceClientBasicInfo = (client, party) => {
  client.firstName = party.firstName ? party.firstName : '';
  client.lastName = party.lastName ? party.lastName : '';
  client.fullName = client.firstName + ' ' + client.lastName;
  client.sex = party.gender ? party.gender : eSex.male;
  return client;
};

const loadInforceClient = (party, coverages, baseCoverageIndex, product) => {
  let client = { ...defaultClient };
  const clientId = party.partyId;
  const coverageTabId = coverages[baseCoverageIndex].coverageId;
  const ratings = { ...eDefaultRatings };
  client.id = clientId;
  client = getInforceClientBasicInfo(client, party);
  client.dob = party.dob;
  client.coverageTabId = [coverageTabId];
  client.placeholder = clientId;
  client.partyId = party.partyId;
  client.ratings = ratings;
  client.deceased = party.deceased ? party.deceased : false;

  getInforceClientAgeAndHealthStyle(party, coverages, baseCoverageIndex, client, product);
  if (client.healthstyle === eHS.hs5 || client.healthstyle === eSmokingStatus.smoker.toLowerCase()) {
    client.smokingstatus = eSmokingStatus.smoker;
  } else {
    client.smokingstatus = eSmokingStatus.nonsmoker;
  }

  return client;
};

const getInforceClientAgeAndHealthStyle = (party, coverages, baseCoverageIndex, client, product) => {
  let coverageIndex = baseCoverageIndex;
  let lifeIndex = -1;

  if (coverageIndex !== -1) {
    lifeIndex = coverages[coverageIndex].life.findIndex((life) => life.partyId === party.partyId);
    if (lifeIndex === -1) {
      // If there's a rider for a client, their age
      // is within the life object of that rider.
      coverageIndex = coverages.findIndex(
        (cvg) => cvg.life && cvg.life[0].partyId === party.partyId && cvg.indicatorCode !== eField.indicatorCode.base
      );
      if (coverageIndex !== -1) {
        lifeIndex = coverages[coverageIndex].life.findIndex((life) => life.partyId === party.partyId);
      }
    }
  }

  if (lifeIndex !== -1) {
    const clientLife = coverages[coverageIndex].life[lifeIndex];
    const healthstyle = getInforceHealthStyle(coverages[coverageIndex], clientLife, product);
    client.age = clientLife.age;
    client.healthstyle = [eProduct.Performax, eProduct.SB].includes(product) ? healthstyle : eHS[healthstyle];
    client.smokingstatus = clientLife.smokingstatus ? clientLife.smokingstatus : client.smokingstatus;
    if (coverages[coverageIndex].lifeRating) {
      client.ratings = reducerHelper.getInforceRatingsFromCoverage(client.ratings, coverages[coverageIndex], product);
    }
  }
};

const getInforceHealthStyle = (coverage, clientLife, product) => {
  if ([eProduct.PG, eProduct.PAR].includes(product) && coverage.coverageType === eCoverageType.single) {
    return coverage.healthstyle ? coverage.healthstyle.toLowerCase() : eHS.hs3;
  } else {
    return clientLife.healthstyle ? clientLife.healthstyle.toLowerCase() : eHS.hs3;
  }
};

const clientReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.OPEN_SAVEDCASE: {
      // This logic to assign all the missing properties from the initial/default to the
      // saved old object with default value.
      return reduceOpenSavedCase(action.payload.model.clients);
    }

    case actionTypes.LOAD_CLIENTS: {
      return {
        ...initialState,
        [scenarioSeq(true)]: {
          allClients: ['client.1', 'client.2'],
          'client.1': { ...client1Mock, dob: '01/01/1990' },
          'client.2': { ...client2Mock, dob: '01/01/1990' },
        },
      };
    }

    case actionTypes.REMOVE_CLIENT: // when coverageType is changed
    case actionTypes.SELECT_CLIENT: {
      return reduceRemoveSelectClient(state, action.payload);
    }

    case actionTypes.REMOVE_COVERAGE_TABNAVS: {
      return reduceRemoveCoverageTabnavs(state, action.payload);
    }

    case actionTypes.MWI_ON_CHANGE: {
      return reduceOnChange(state, action.payload);
    }

    case actionTypes.MWI_UPDATE_ERROR: {
      return reduceUpdateError(state, action.payload);
    }

    case actionTypes.LOADED_UDM_RESPONSE: {
      return reduceLoadedUdmResponse(state, action.payload.response.illustration);
    }

    default:
      return clientReducer3(state, action);
  }
};

const getClientStateFromInitialCoverage = (state, payload, newClientIndex) => {
  const { coverageTabId } = payload;
  const activeTabId = state.activeTabId;
  const activeState = state[activeTabId];
  if (activeState.allClients.length > 0) {
    const newCoverageTabId = activeState[activeState.allClients[0]].coverageTabId;
    if (!newCoverageTabId.includes(coverageTabId)) {
      newCoverageTabId.push(coverageTabId);
    }
    return {
      ...state,
      clientIndex: newClientIndex,
      [activeTabId]: {
        ...activeState,
        [activeState.allClients[0]]: {
          ...activeState[activeState.allClients[0]],
          coverageTabId: newCoverageTabId,
        },
      },
    };
  }
  return getNewClientState(state, payload, newClientIndex);
};

export default function clientReducers(state, action) {
  state = state === undefined ? { ...initialState } : { ...state };
  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      return initialState;
    }

    case actionTypes.ADD_CLIENT:
    case actionTypes.ADD_COVERAGE_TABNAVS: {
      const { isVitalityPlusSelected, product } = action.payload;
      const newClientIndex = state.clientIndex + 1;

      // for vitality plus, only single life is allowed and with one client only
      // so make sure to use the client for the initial coverage
      if (isVitalityPlusSelected) {
        return getClientStateFromInitialCoverage(state, action.payload, newClientIndex);
      }
      // When an inforce policy is loaded, the client is setup so we don't need to touch.
      if (state.inforce) {
        return {
          ...state,
        };
      }
      return getNewClientState(state, action.payload, newClientIndex, product);
    }

    default:
      return clientReducer(state, action);
  }
}
