import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { eDefaultCustomVitality, eReqStatus, eActionEvent, eProduct } from '../../core/configs';
import {
  getToYear,
  trackUserActions,
  getProdCoverageOption,
  setChangeToBronzeYes,
  getVitalityStatusSimulatorUrl,
  setCoverageSolveToYes,
  setIncludeDepositOptionToYes,
} from '../../core/utils';
import VitalityButton from '../../core/components/vitality-button';
import MWIDropdown from '../../core/components/mwi-dropdown';
import * as AppActions from '../../core/actions';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import * as StatusActions from './actions';
import _ from 'lodash';
import './vitality-status.css';
import { dropDownOptions } from '../../core/dropdown-config';
import * as LearnMore from '../../core/learn-more-config';
import { MWILink } from '../../core/components/mwi-link';

export class VitalityStatus extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    clients: PropTypes.object.isRequired,
    vitalityStatus: PropTypes.array.isRequired,
    scenarioTabId: PropTypes.string,
    coverageOption: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    coverageId: PropTypes.string.isRequired,
    intl: PropTypes.object,
    udmResponse: PropTypes.object,

    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    statusActions: PropTypes.object,
    isCoverageSolve: PropTypes.bool,
    product: PropTypes.string,
    isForceUpdate: PropTypes.bool,
    coverage: PropTypes.object,
    activeScenario: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.saveCustomStatuses = this.saveCustomStatuses.bind(this);
  }

  // clientId in the current coverage tab
  get clientIdInTheCoverage() {
    const { clients, coverageId } = this.props;

    return _.find(clients.allClients, (clientId) => _.indexOf(clients[clientId].coverageTabId, coverageId) >= 0);
  }

  dispatchAction(type, value, statusId, isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target: type,
      field: type,
      scenarioTabId: this.props.scenarioTabId,
      statusId,
      value,
    });
  }

  onChange(value, statusId) {
    const { product, coverage, activeScenario } = this.props;
    trackUserActions(eActionEvent.selection, 'vitalityStatus', value, '', {
      product,
      coverageType: coverage.coverageType,
      coverageOption: getProdCoverageOption(product, coverage),
      vitality: 'Yes',
      vitalityStatus: value,
      changeToBronze: setChangeToBronzeYes(product, coverage.isChangeToBronze, true, value),
      coverageSolve: setCoverageSolveToYes(coverage),
      includeDepositOption: setIncludeDepositOptionToYes(activeScenario, coverage),
    });

    this.dispatchAction('vitalityStatus', value, statusId);
    this.props.sceActions.toggleOutOfDate(true);
  }

  saveCustomStatuses(statuses) {
    const { product, coverage, activeScenario } = this.props;
    trackUserActions(
      eActionEvent.selection,
      'vitalityStatus',
      statuses.length === 1 ? statuses[0].vitalityStatus : 'custom',
      '',
      {
        product,
        coverageType: coverage.coverageType,
        coverageOption: getProdCoverageOption(product, coverage),
        vitality: 'Yes',
        vitalityStatus: 'Custom',
        changeToBronze: setChangeToBronzeYes(product, coverage.isChangeToBronze, true, 'Custom'),
        coverageSolve: setCoverageSolveToYes(coverage),
        includeDepositOption: setIncludeDepositOptionToYes(activeScenario, coverage),
      }
    );

    this.props.statusActions.save({ scenarioTabId: this.props.scenarioTabId, vitalityStatus: statuses });
    this.props.sceActions.toggleOutOfDate(true);
  }

  getHelpTextBanner = (product) => {
    if (product === eProduct.MUL) {
      return 'coverage.mul.vitalityStatus.learnmore.row-1';
    } else if (product === eProduct.PAR) {
      return '';
    } else {
      return 'coverage.ft.vitalityStatus.learnmore.row-1';
    }
  };

  vitalityStatusLearnMoreContent = () => {
    const tableRows = LearnMore.VitalityStatusLearnMoreMatrix.map((row, index) => {
      return (
        <tr key={index}>
          <td>
            <FormattedMessage id={row.header1.id} />
          </td>
          <td className="text-align-right">
            <FormattedHTMLMessage id={row.header2.id} />
          </td>
        </tr>
      );
    });

    const { intl, product, locale } = this.props;

    return (
      <div>
        {(product === eProduct.FT || product === eProduct.MUL) && (
          <table className="mwi-margin-bottom14">{tableRows}</table>
        )}
        {product === eProduct.MUL && (
          <div>
            <div className="mwi-margin-bottom14">
              <FormattedMessage id="coverage.mul.vitalityStatus.learnmore.row-2" />
            </div>
            <div className="mwi-margin-bottom14">
              <FormattedMessage id="coverage.mul.vitalityStatus.learnmore.row-3" />
            </div>
          </div>
        )}
        {product === eProduct.FT && (
          <div>
            <div>
              <FormattedMessage id="coverage.ft.vitalityStatus.learnmore.row-2" />
            </div>
            <div className="mwi-margin-bottom14">
              <FormattedMessage id="coverage.ft.vitalityStatus.learnmore.row-2-2" />
            </div>
            <div className="mwi-margin-bottom14">
              <FormattedMessage id="coverage.ft.vitalityStatus.learnmore.row-3" />
            </div>
          </div>
        )}
        <div>
          <span className="display-inline">
            <FormattedMessage id="coverage.mul.vitalityStatus.learnmore.row-4" />
            <MWILink
              label={<FormattedHTMLMessage id="coverage.mul.vitalityStatus.learnmore.healthyLiving" />}
              link={getVitalityStatusSimulatorUrl(locale)}
              intl={intl}
              openNewTab
              styleClass="display-inline healthy-living-link"
            />
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { intl, locale, clients, coverageOption, udmResponse, vitalityStatus, product, appActions } = this.props;
    const customStatuses = vitalityStatus || eDefaultCustomVitality;
    const isValidationError = udmResponse.validationStatus.toUpperCase() === eReqStatus.error;
    const custom = intl.formatMessage({ id: 'common.custom' });
    const age = clients[this.clientIdInTheCoverage].age;
    // TODO: this should be set to props.duration but need to wait until calcs. are complete
    const duration = getToYear(coverageOption, age);
    const vitalityStatuses = dropDownOptions(intl).vitalityStatus;

    return (
      <React.Fragment>
        <MWIDropdown
          name="vitalityStatus"
          label={
            <span id="customizeVitalityButton">{intl.formatMessage({ id: 'coverage.vitality.nosuperscript' })}</span>
          }
          value={customStatuses.length === 1 ? customStatuses[0].vitalityStatus : custom}
          placeholder={customStatuses.length > 1 ? custom : ''}
          options={vitalityStatuses}
          locale={locale}
          onChange={(e) => this.onChange(e.target.value, undefined)}
          containerStyle="p-col-4 p-md-5 p-lg-4 p-sm-6"
          dropdownStyle="mwi-w90"
          disabled={isValidationError}
          isTM
          helpText
          helpTextBanner={this.getHelpTextBanner(product)}
          helpTextContent={this.vitalityStatusLearnMoreContent()}
          helpTextContainer={`vitality-status-helptext-size-${locale}`}
          product={product}
          appActions={appActions}
          intl={intl}
        />

        {product === eProduct.FT && (
          <div className="p-col-2 p-md-3 p-lg-2 p-sm-4" id="vitalityBtnWrapper">
            <VitalityButton
              onSave={this.saveCustomStatuses}
              customStatuses={customStatuses}
              age={age}
              duration={duration}
              locale={locale}
              containerStyle="statuses"
              buttonStyle="button-style"
              disabled={isValidationError}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app, clients, udmResponse, scenarioTabNavs, coverageTabNavs, vitalityStatus }) => {
  const defaultDuration = 20;
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const coverageState = coverageTabNavs[scenarioTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const illustration = udmResponse.illustration;
  const udmCoverages = (illustration && illustration.policy && illustration.policy.coverage) || [{}];
  const activeScenario = scenarioTabNavs.topBars[scenarioTabId];
  const product = activeScenario.product;

  return {
    locale: app.locale,
    clients: clients[scenarioTabId],
    vitalityStatus: vitalityStatus[scenarioTabId],
    coverageOption: coverage.coverageOption,
    duration: (udmCoverages[0].vitality && udmCoverages[0].vitality.duration) || defaultDuration,
    isForceUpdate: scenarioTabNavs.isForceUpdate,
    scenarioTabId,
    udmResponse,
    product,
    coverage,
    activeScenario,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  statusActions: bindActionCreators(StatusActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VitalityStatus));
