import React from 'react';
import PropTypes from 'prop-types';
import MWIButton from '../mwi-button';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactModal from 'react-modal';
import './error-dialog.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as AppActions from '../../../core/actions';
import { eField, eProduct } from '../../configs';
import {
  closeErrorModal,
  generateTable,
  getErrorMessage,
  getOnClickOk,
  getTableDataMaxWithdrawal,
  setLabels,
} from './support-functions';

export const ErrorDialog = ({
  isOpenSpreadsheet,
  customWarning,
  appActions,
  intl,
  isOpen,
  product,
  error_data,
  errorMessage,
  changleFirstLine,
  column,
  rowData,
  additionalDeposit,
  validationErrors,
}) => {
  const id = error_data?.id;

  // ? Add here id you custom checks to display dynamicly
  // ? data in form window
  const maxWithdrawal = id === 'MaxWithdrawal'; // Max withdrawal custom check
  const MinFirstYearCustomDepositAmt = id === 'MinFirstYearCustomDepositAmt';

  // ? Add conditions with "or" in case you want to hide regular structure
  // ? and display custom "Error" or "Warning" with table
  const otherMessages = !maxWithdrawal;

  // ? Add conditions with "or" in case you want to show ok button
  const showOkButton = maxWithdrawal || MinFirstYearCustomDepositAmt;

  // ? You can specify here your type of id of message
  // ? and show customly it as a "Warning" or "Error"
  const { contentLabel, exitLabel } = setLabels(id);

  return (
    <ReactModal
      isOpen={isOpen || customWarning}
      className={`${maxWithdrawal && 'warning-max-withdrawal-width'} error-dialog-wrapper delete-dialog-wrapper`}
      contentLabel={intl.formatMessage({ id: contentLabel })}
      onRequestClose={(okCall) => closeErrorModal(okCall, isOpenSpreadsheet, maxWithdrawal, appActions)}
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      role="dialog"
      aria={{
        modal: 'true',
      }}
      ariaHideApp
    >
      <div className="p-grid p-grid-no-margin-side">
        <h2 className="p-col-12 title-model title-border">{intl.formatMessage({ id: contentLabel })}</h2>

        {/* Warning: MaxWithdrawal(product MUL) */}
        {product === eProduct.MUL && maxWithdrawal && (
          <>
            <div className="p-col-12 row-model" role="container-rowmodel">
              <FormattedMessage id="common.error.maxWithdrawal.part1" />
              <br />
              <FormattedMessage id="common.error.maxWithdrawal.part2" />
            </div>
            <div className="p-col-12 row-model">
              {generateTable(getTableDataMaxWithdrawal(validationErrors, intl.locale))}
            </div>
          </>
        )}

        {/* Display regular error messages */}
        {otherMessages && <div className="p-col-12 row-model">{errorMessage}</div>}

        <div className="p-col-12 dialog-footer flexdisplay" role="container-dialogfooter">
          <div className="p-col-3" style={{ marginLeft: 'auto' }}>
            <MWIButton
              name="cancel"
              label={intl.formatMessage({ id: exitLabel })}
              onClick={(okCall) => closeErrorModal(okCall, isOpenSpreadsheet, maxWithdrawal, appActions)}
              styleClass={`${showOkButton && 'inverseBtn'} mwi-w100 save-button oneflex`}
            />
          </div>

          {/*Custom Ok button*/}
          {product === eProduct.MUL && showOkButton && (
            <div className="p-col-3" style={{ marginLeft: '32px' }}>
              <MWIButton
                name="okay"
                label={intl.formatMessage({ id: 'common.ok' })}
                onClick={getOnClickOk({
                  errorData: error_data,
                  errorId: id,
                  isOpenSpreadsheet,
                  appActions,
                  changleFirstLine,
                  column,
                  rowData,
                  additionalDeposit,
                })}
                styleClass="mwi-w100 ok-button oneflex"
              />
            </div>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

ErrorDialog.propTypes = {
  intl: PropTypes.object,
  isOpen: PropTypes.bool,
  errorMessage: PropTypes.string,
  appActions: PropTypes.object,
  product: PropTypes.string,
  error_data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  updateSpreadsheet: PropTypes.func,
  from: PropTypes.string,
  changleFirstLine: PropTypes.func,
  column: PropTypes.object,
  rowData: PropTypes.object,
  additionalDeposit: PropTypes.number,
  validationErrors: PropTypes.array,
  sceActions: PropTypes.object,
  activeScenarioTabId: PropTypes.string,
  customWarning: PropTypes.bool,
  isOpenSpreadsheet: PropTypes.bool,
};

export const mapStateToProps = ({ app, udmResponse, coverageTabNavs, deposits, scenarioTabNavs, spreadsheet }) => {
  const activeScenarioTabId = scenarioTabNavs.activeTabId;

  // // ? Custom data passed by error object
  let errorData;
  if (app.isUdmIllustrationError) {
    errorData = udmResponse.validationErrors && udmResponse.validationErrors[0].message[0];
  } else {
    errorData = app.warningPopUp ? udmResponse.validationWarnings[0] : '';
  }

  // ? Error Message that should be printed
  const errorMessage = getErrorMessage(app, udmResponse);

  const deposit = deposits[deposits.activeTabId] || 0;

  return {
    isOpenSpreadsheet: spreadsheet && spreadsheet[activeScenarioTabId].isOpen,
    customWarning: app.warningPopUp,
    validationErrors: udmResponse && udmResponse.validationWarnings.filter((e) => e.id === 'MaxWithdrawal'),
    additionalDeposit: deposit.additionalDeposit,
    error_data: errorData,
    product: coverageTabNavs[eField.product],
    isOpen: app.isUdmIllustrationError,
    activeScenarioTabId,
    errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ErrorDialog));
