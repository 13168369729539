import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ReactModal from 'react-modal';
import './progress-dialog.css';
import { ProgressBar } from 'primereact/progressbar';

export class ProgressDialog extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    isOpen: PropTypes.bool,
    contentLabel: PropTypes.string,
    progressMessage: PropTypes.string,
  };
  render() {
    const { intl, isOpen, contentLabel, progressMessage } = this.props;
    return (
      <ReactModal
        isOpen={isOpen}
        className={'progress-dialog-wrapper delete-dialog-wrapper'}
        contentLabel={intl.formatMessage({ id: contentLabel })}
        shouldCloseOnOverlayClick={false}
        onRequestClose={this.closeErrorModal}
        role="dialog"
        ariaHideApp={false}
      >
        <div className="p-grid p-grid-no-margin-side" role="alert">
          <h2 className="p-col-12 title-model">{intl.formatMessage({ id: contentLabel })}</h2>
          <div className="p-col-12 row-model">{intl.formatMessage({ id: progressMessage })} </div>
          <div className="p-col-12 row-model">
            <ProgressBar mode="indeterminate" className="mwi-progress-bar backdrop" />
          </div>
          <div className="p-col-12 dialog-footer" role="dialog-footer" />
        </div>
      </ReactModal>
    );
  }
}

export default injectIntl(ProgressDialog);
