import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { eField } from '../../configs';
import { MWIDob } from '../mwi-dob';
import { validate } from '../person/validator';

import './dob.css';

export class Dob extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    person: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    validate: PropTypes.func,
    locale: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(type, value) {
    const error = validate(type, value, this.props.locale);

    if (type === eField.dob && !value) {
      this.props.onChange(eField.dob, '', true); // reset error for dob
    }

    if (type === eField.dob && !error && value && value.length) {
      this.props.onBlur(type, value);
    }

    this.props.onChange(type, value);
  }

  onBlur(type, value) {
    // if no error, it returns empty string so that it can reset error message
    let error = validate(type, value, this.props.locale);
    // custom validator
    if (!error && this.props.validate) {
      error = this.props.validate(type, value);
    }

    this.props.onBlur(type, error, true);
  }

  render = () => {
    const { intl, person } = this.props;

    return (
      <React.Fragment>
        <div className="p-col-12 dob-calendar" role="container-dob">
          {person && (
            <MWIDob
              containerClass="p-col-12"
              grid="2"
              name="dob"
              labelId={`${person.id}.trc`}
              label={intl.formatMessage({ id: 'party.dob' })}
              value={person.dob}
              locale={this.props.locale}
              placeholder={intl.formatMessage({ id: 'common.date.placeholder' })}
              errors={person.errors}
              onBlur={this.onBlur}
              onChange={this.onChange}
              ariaLabel={`${intl.formatMessage({ id: 'party.dob' })} DD MM YYYY`}
            />
          )}
        </div>
      </React.Fragment>
    );
  };
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps)(injectIntl(Dob));
