import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import MarginalTaxRate from '../../components/marginal-tax-rate';
import { eLocale, eField } from '../../../core/configs';
import MWIButton from '../../components/mwi-button';
import './tax-information-rates.css';
import { handleFocus } from '../../utils';
import PersonalDividendTaxRate from '../../components/personal-dividend-tax-rate';

export class TaxInformationRates extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    taxInformationRates: PropTypes.object,
    setConceptFieldValue: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  onClick() {
    const url = this.props.intl.formatMessage({ id: 'concept.taxRatesUrl' });
    window.open(url);
  }

  onValueChange(id, val) {
    this.props.setConceptFieldValue(id, val, eField.taxInformationRates);
  }

  render() {
    const { locale, intl, taxInformationRates } = this.props;
    const maxTaxRate = 10;
    return (
      <React.Fragment>
        <div id="tax-information-rates" role="tax-information-rates">
          <div className="p-grid p-grid-no-margin p-col-12">
            <div className={'p-col-5 p-lg-5 p-xl-5 p-sm-6'} id="marginal-tax-wrapper">
              <MarginalTaxRate />
            </div>
            <div className={locale === eLocale.en ? `p-col-2` : `p-col-3`} id="tax-rates-btn">
              <MWIButton
                label={intl.formatMessage({ id: 'concept.taxRates' })}
                styleClass="button-width"
                onClick={this.onClick}
              />
            </div>
          </div>
          <div className="p-grid p-grid-no-margin p-col-12">
            <PersonalDividendTaxRate />

            <div className={`p-col-4 p-lg-4`}>
              <div className="mwi-label" id="presentValueDiscountRateLabel">
                <span>
                  <FormattedMessage id="concept.presentdiscountrate" />
                </span>
              </div>
              <div className="p-col-12 ui-input">
                <NumberFormat
                  className={'mwi-widget mwi-input number-data'}
                  value={+taxInformationRates.presentValueDiscountRate}
                  decimalScale={2}
                  name={eField.presentValueDiscountRate}
                  required
                  id={eField.presentValueDiscountRate}
                  fixedDecimalScale
                  suffix=" %"
                  decimalSeparator={locale === eLocale.en ? '.' : ','}
                  onValueChange={(values) => this.onValueChange(eField.presentValueDiscountRate, values.value)}
                  allowNegative={false}
                  isAllowed={(values) => +values.value >= 0 && +values.value <= maxTaxRate}
                  aria-labelledby="presentValueDiscountRateLabel"
                  aria-required="true"
                  autoComplete="off"
                  onFocus={handleFocus}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(TaxInformationRates));
