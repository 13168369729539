import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import MWILink from '../../core/components/mwi-link';
import { eProduct, eLocale, eUserType } from '../../core/configs';
import { isInforceVitalityApplicable } from '../../core/utils';
import ReactModal from 'react-modal';
import HelpQuestionMark from '../../core/components/help-question-mark';
//HEADOFFICE TEMP SOLUTION
import './coverage.css';
import '../../core/components/change-modals/change-product.css';
import EffectiveDateModal from '../../core/components/change-modals/effective-date-modal';
import VitalityCheckbox from '../../core/components/vitality-checkbox';
import HelpText from '../../core/components/help-text';
import moment from 'moment';

export class CoverageSettings extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    product: PropTypes.string,
    effectiveDate: PropTypes.string,
    illustrationDate: PropTypes.string,
    intl: PropTypes.object,
    userType: PropTypes.string,
    isVitalityPlusDisable: PropTypes.bool,
    inforce: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      showEffectiveDate: false,
    };

    this.openEffectiveDateModal = this.openEffectiveDateModal.bind(this);
    this.closeEffectiveDateModal = this.closeEffectiveDateModal.bind(this);
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      ReactModal.setAppElement('#mwi-root');
    }
  }

  openEffectiveDateModal() {
    this.setState({ showEffectiveDate: true });
  }

  closeEffectiveDateModal() {
    this.setState({ showEffectiveDate: false });
  }

  getProduct(product) {
    switch (product) {
      case eProduct.MUL: {
        return 'product.mul';
      }
      case eProduct.PAR: {
        return 'product.par';
      }
      case eProduct.PG: {
        return 'product.pg';
      }
      case eProduct.Performax: {
        return 'product.performax';
      }
      case eProduct.LC: {
        return 'product.lc';
      }
      case eProduct.FTRC: {
        return 'product.ftrc';
      }
      case eProduct.SB: {
        return 'product.securitybuilder';
      }
      default:
        return this.getProduct2(product);
    }
  }

  getProduct2(product) {
    switch (product) {
      case eProduct.BT: {
        return 'product.bt';
      }
      case eProduct.FT: {
        return 'product.ft';
      }
      case eProduct.IV: {
        return 'product.iv';
      }
      default:
        return undefined;
    }
  }

  mwiLink(intl) {
    return (
      <span className="change-margin-left">
        <MWILink
          label={intl.formatMessage({ id: 'common.change' })}
          link=""
          onClick={(e) => {
            e.preventDefault();
            this.openProductChangeModal();
          }}
          styleClass="change-label"
        />
      </span>
    );
  }

  isMULFTPar(product) {
    return product === eProduct.MUL || product === eProduct.FT || product === eProduct.PAR;
  }

  renderVitalityPlus = () => {
    const { product, isVitalityPlusDisable, intl } = this.props;
    const inforceVitalityApplicable = isInforceVitalityApplicable(product, this.props.inforce);
    return (
      (!this.props.inforce || inforceVitalityApplicable) &&
      this.isMULFTPar(product) && (
        <div
          className={`p-grid mwi-margin-top-10 p-col-12 align-items-center${
            product === eProduct.FT ? ' mwi-margin-bottom18' : ''
          }`}
        >
          <VitalityCheckbox isVitalityPlusDisable={isVitalityPlusDisable} intl={intl} />
          {this.renderLearnMoreVitality()}
        </div>
      )
    );
  };

  renderLearnMoreVitality = () => {
    const { product, locale, inforce } = this.props;
    const isParInforce = product === eProduct.PAR && inforce;
    return (
      !isParInforce && (
        <div className="label-link" style={{ paddingLeft: '14px' }}>
          <HelpText
            containerClass={`trc-help ${locale}`}
            overlayId="mulVitalityPlusTip"
            overlayClass={`vitalityplus-helptext-size-${locale}`}
            overlayContent={() => {
              return (
                <div>
                  <div style={{ paddingBottom: 14, display: 'flex', justifyContent: 'space-between' }}>
                    {product === eProduct.MUL && (
                      <div>
                        <FormattedHTMLMessage id="coverage.mul.vitalityPlus.learnmore" />
                      </div>
                    )}
                    {product === eProduct.FT && (
                      <div>
                        <FormattedHTMLMessage id="coverage.ft.vitalityPlus.learnmore" />
                      </div>
                    )}
                    {product === eProduct.PAR && (
                      <div>
                        <FormattedHTMLMessage id="coverage.par.vitalityPlus.learnmore" />
                      </div>
                    )}
                    <i className="material-icons" style={{ fontSize: '1em' }}>
                      clear
                    </i>
                  </div>
                  {(product === eProduct.MUL || product === eProduct.PAR) && (
                    <div>
                      <FormattedHTMLMessage id={'coverage.mul.vitalityPlus.learnmore2'} />
                    </div>
                  )}
                  {product === eProduct.FT && (
                    <div>
                      <FormattedHTMLMessage id={'coverage.ft.vitalityPlus.learnmore2'} />
                    </div>
                  )}
                </div>
              );
            }}
          />
        </div>
      )
    );
  };

  getCurrentDate = (inforce, illustrationDate) => {
    if (inforce && illustrationDate) {
      return moment(illustrationDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    } else {
      return moment().format('DD/MM/YYYY');
    }
  };

  render() {
    const { product, effectiveDate, illustrationDate, inforce, intl, locale, userType } = this.props;
    const productName = this.getProduct(product);
    const currentDate = this.getCurrentDate(inforce, illustrationDate);

    return (
      <React.Fragment>
        <div
          className="p-grid p-grid-no-margin-all p-col-12"
          id="coverage-product-label"
          role="container-coverageproductlabel"
        >
          <div
            className={`display-flex p-col ${
              (locale === eLocale.fr && product !== eProduct.MUL) || eProduct.IV
                ? 'p-col-8 p-lg-6'
                : 'p-col-6 p-lg-7 p-xl-5'
            }`}
            id="product-label"
          >
            <span className="product-label-margin-top">
              {intl.formatMessage({ id: 'product.label' })}: {intl.formatMessage({ id: productName })}
            </span>
          </div>
          {inforce && (
            <div>
              <span className="product-label-margin-top">
                {intl.formatMessage({ id: 'coverage.mul.dateOfIllustration' }) + currentDate}
              </span>
              <div className="break" />
              <span className="product-label-margin-top">
                {intl.formatMessage({ id: 'coverage.mul.policyDate' }) + effectiveDate}
              </span>
            </div>
          )}
          {!inforce && userType === eUserType.headOffice && (
            <div
              className={`p-col effective-align-Center effective-date 
              ${(locale === eLocale.fr && product !== eProduct.MUL) || eProduct.IV ? 'p-lg-6' : 'p-lg-6 p-xl-5'}`}
            >
              <span className="product-font-label">
                {intl.formatMessage({ id: 'topbar.effectivedate' })}: {effectiveDate}
              </span>
              {
                <HelpQuestionMark
                  containerClass="help"
                  overlayId="effectiveTip"
                  overlayContent={() => (
                    <div>
                      <FormattedMessage id={'coverage.EffectiveDate.tooltip.text'} />
                    </div>
                  )}
                />
              }
              <span>
                <MWILink
                  label={intl.formatMessage({ id: 'common.change' })}
                  link=""
                  onClick={(e) => {
                    e.preventDefault();
                    this.openEffectiveDateModal();
                  }}
                  styleClass="change-label"
                />
              </span>
            </div>
          )}
        </div>
        {this.renderVitalityPlus()}
        <ReactModal
          className={`change-product-wrapper ${locale} not-duplicate`}
          isOpen={this.state.showEffectiveDate}
          shouldCloseOnOverlayClick={false}
          shouldFocusAfterRender
          shouldReturnFocusAfterClose
          onRequestClose={this.closeEffectiveDateModal}
          role="dialog"
          aria={{
            modal: 'true',
          }}
          ariaHideApp
        >
          <EffectiveDateModal
            title={`${intl.formatMessage({ id: 'topbar.effectivedate' })}`}
            intl={intl}
            product={product}
            userType={userType}
            closeEffectiveDateModal={this.closeEffectiveDateModal}
          />
        </ReactModal>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs }) => {
  const topbar = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  return {
    product: topbar.product,
    inforce: app.inforcePolicy,
  };
};

export default connect(mapStateToProps)(injectIntl(CoverageSettings));
