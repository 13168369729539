import {
  ADD_TERM_RIDER,
  REMOVE_TERM_RIDER,
  SELECT_TERM_RIDER_CLIENT,
  UPDATE_TERM_RIDER_RATING,
} from '../../core/actionTypes';
import { makeActionCreator } from '../../core/utils';

export const addTermRider = makeActionCreator(ADD_TERM_RIDER);
export const removeTermRider = makeActionCreator(REMOVE_TERM_RIDER);
export const selectTermRiderClient = makeActionCreator(SELECT_TERM_RIDER_CLIENT);
export const saveRatingsTerm = makeActionCreator(UPDATE_TERM_RIDER_RATING);
