import moment from 'moment';

export const validate = (type, value, locale, intl) => {
  const businessMonthNumDays = 28;
  // if there is no error it should return empty string so that it can remove error message from the UI
  const errorMessage = '';

  if (type === 'effectiveDate' && value && value.length) {
    // if year is greater length of 4 then error: 12Jan1990112
    // const dobSubStr = value.substr(-5);
    // if (/^[0-9]+$/.test(dobSubStr.substring(0, 1))) {
    //   return 'Invalid date';
    // }

    moment.locale(locale);

    // Moment's parser is very forgiving so it allows some different format from DD/MMM/YYYY
    // if you want to make Moment use strict parsing, specify 'true' for the last arument like
    // moment(value, 'DD/MMM/YYYY', true)
    // then length doens't need to be checked. Empty DOB should be fine.
    // ex: 11Jun2011, 9Jun2011, 01-Jun-2011 those cases are all valid.
    // 		 But DD and MMM shouldn't be switched so Jun012011 is not valid.
    const covMoment = moment(value, 'DD/MM/YYYY', true);

    // Check if DATE is NOT between May 1, 2010 and October 4, 2013
    if (!covMoment.isValid()) {
      return 'Invalid date';
    }

    if (covMoment.isAfter('2013-10-04') || covMoment.isBefore('2010-05-01')) {
      return intl.formatMessage({ id: 'error.coverageDate.outOfRange' });
    }

    // Check if DAY is 29, 30 or 31
    if (covMoment.date() > businessMonthNumDays) {
      return intl.formatMessage({ id: 'error.coverageDate.invalidDay' });
    }
  }

  return errorMessage;
};
