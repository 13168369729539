import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as Utils from './utils';
import { eField, eCoverageType, eCoverageOption, eProduct, eRider } from '../../core/configs';
import { getFormattedValue } from '../../core/utils';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';

const mwiTopPadding = 'mwi-padding-top12 ';
const mwiBottomPadding = 'mwi-padding-bottom12';
const client1Text = 'common.ratings.Client1';
const client2Text = 'common.ratings.Client2';

const renderRiderRatings = (product, info, clients, duration, intl, rider, inforce) => {
  return (
    <>
      {!Utils.isMULOrPG(product) && !Utils.isPmaxOrSB(product) && renderRatings(info, rider.clients)}
      {(Utils.isMUL(product) || Utils.isPGold(product) || Utils.isPmaxOrSB(product)) &&
        Utils.isTermRider(info.name) &&
        renderMulPGRatings(product, info, clients, duration, intl, rider, inforce)}
      {Utils.isPGold(product) && !Utils.isTermRider(info.name) && renderPGNonTirRatings(info, intl)}
    </>
  );
};

const renderRatings = (info, clients) => {
  const { name, rating } = info;
  return (
    <>
      {(name === eCoverageOption.t10 || name === eCoverageOption.t20) && Utils.isTermRiderRated(rating, clients) && (
        <>
          {`, `}
          <FormattedMessage id="common.rated" />
        </>
      )}
      {name !== eCoverageOption.t10 && name !== eCoverageOption.t20 && Utils.isRiderRated(info) && (
        <>
          {`, `}
          <FormattedMessage id="common.rated" />
        </>
      )}
    </>
  );
};

const renderPGNonTirRatings = (info, intl) => {
  const { rating } = info;
  return <>{Utils.generatePGRatings(rating, intl)}</>;
};

const renderPTDWRatings = (product, intl, riderSummary) => {
  return (
    <>
      {!Utils.isPGold(product) &&
        !Utils.isPerformax(product) &&
        Utils.isRiderRated(riderSummary.tdwOnPayor.covInfo) && (
          <>
            {`, `}
            <FormattedMessage id="common.rated" />
          </>
        )}
      {(Utils.isPGold(product) || Utils.isPerformax(product)) &&
        Utils.isRiderRated(riderSummary.tdwOnPayor.covInfo) && (
          <>{Utils.generatePGRatings(riderSummary.tdwOnPayor.covInfo.rating, intl)}</>
        )}
    </>
  );
};

const renderMulPGRatings = (product, info, clients, duration, intl, rider, inforce) => {
  let client1 = 'Client.1';
  let client2 = 'Client.2';
  if (inforce) {
    client1 = rider.clients[0].id;
    client2 = rider.clients[1] ? rider.clients[1].id : false;
  }
  return (
    <>
      {` `}
      <FormattedMessage id="common.for.term" />
      {duration + ` `}
      <FormattedMessage id="common.years" />
      {generateMulPGRating(product, client1, info, clients, intl, client1Text)}
      {generateMulPGRating(product, client2, info, clients, intl, client2Text)}
    </>
  );
};

const isClientRated = (product, rating, client) => {
  const isPmaxOrSB = Utils.isPmaxOrSB(product);

  return isPmaxOrSB ? Utils.isRatedPMax(rating[client]) : Utils.isRated(rating[client]);
};

const generateValidClient = (product, client, info, staticText, rating, type) => {
  if (staticText === client1Text) {
    return info.rating[client] ? isClientRated(product, rating, client) : false;
  } else {
    return client && rating[client] && type !== eCoverageType.single ? isClientRated(product, rating, client) : false;
  }
};

const generateMulPGRating = (product, client, info, clients, intl, staticText) => {
  const { rating, type } = info;
  const validClient = generateValidClient(product, client, info, staticText, rating, type);

  if (validClient) {
    return (
      <>
        <br />
        {Utils.generateRatings(
          rating[client],
          intl,
          false,
          type === eCoverageType.single ? '' : clients[client].fullName || intl.formatMessage({ id: staticText })
        )}
      </>
    );
  }
  return <></>;
};

const renderChildRider = (props) => {
  const { locale, isRiderSelected, numChildren, product, riderSummary, inforce } = props;

  const renderCpr = () => {
    return (
      <>
        <span role="cprAbbr">
          <FormattedMessage id="rider.cpr.abbr" /> (x{numChildren})
        </span>
        {riderSummary.cpr.isRated && (
          <>
            {`, `}
            <FormattedMessage id="common.rated" />
          </>
        )}
      </>
    );
  };

  return (
    <div
      className={`p-grid p-grid-no-margin no-margin child-rider ${
        !isRiderSelected || riderSummary.rider.length === 0 ? mwiTopPadding : ''
      }${mwiBottomPadding}`}
      role="container-summaryridersection"
    >
      <div className="p-col-8 label" role="renderCpr">
        {product === eProduct.MUL && <b>{renderCpr()}</b>}
        {product !== eProduct.MUL && renderCpr()}
      </div>
      <div className="p-col-4 amount" role="renderCprAMount">
        {!Utils.isMULOrPG(product) &&
          !Utils.isPerformax(product) &&
          !Utils.isSummaryAmountsHidden(product, inforce) && (
            <span>{getFormattedValue(locale, riderSummary.cpr.totalPremium || '0')}</span>
          )}
      </div>
    </div>
  );
};

const renderTdwOnPayor = (props) => {
  const { locale, isUDMLoading, numChildren, riderSummary, product, intl } = props;
  return (
    <div
      id="payor_rider"
      className={`p-grid p-grid-no-margin no-margin ${
        riderSummary.length === 0 && numChildren === 0 ? mwiTopPadding : ''
      }${mwiBottomPadding}`}
    >
      <div className="p-col-12 info" role="payorInfo">
        {riderSummary.tdwOnPayor.client.fullName || riderSummary.tdwOnPayor.client.placeholder}
      </div>
      <div className="p-col-8 label">
        <span role="abbrPayor">
          {product === eProduct.LC ? (
            <FormattedMessage id="lc.rider.tdw.payor" />
          ) : (
            riderSummary.tdwOnPayor.covInfo.abbr
          )}
        </span>
        {riderSummary.tdwOnPayor.covInfo.amount &&
          `, ${getFormattedValue(locale, riderSummary.tdwOnPayor.covInfo.amount, 0)}`}
        {renderPTDWRatings(product, intl, riderSummary)}
      </div>
      <div className="p-col-4 amount">
        <span role="tdwAmount">
          {!isUDMLoading &&
            !Utils.isPGold(product) &&
            Utils.coveragePremium(riderSummary.tdwOnPayor.covInfo.targetUDMCoverage, locale)}
        </span>
      </div>
    </div>
  );
};

const renderPrwOnPayor = (props) => {
  const { locale, isUDMLoading, numChildren, riderSummary } = props;
  return (
    <div
      id="payor_rider"
      className={`p-grid p-grid-no-margin no-margin ${
        riderSummary.length === 0 && numChildren === 0 ? mwiTopPadding : ''
      }${mwiBottomPadding}`}
    >
      <div className="p-col-12 info">
        {riderSummary.prwOnPayor.client.fullName || riderSummary.prwOnPayor.client.placeholder}
      </div>
      <div className="p-col-8 label">
        {riderSummary.prwOnPayor.covInfo.abbr}
        {riderSummary.prwOnPayor.covInfo.amount &&
          `, ${getFormattedValue(locale, riderSummary.prwOnPayor.covInfo.amount, 0)}`}
        {Utils.isRiderRated(riderSummary.prwOnPayor.covInfo) && (
          <>
            {`, `}
            <FormattedMessage id="common.rated" />
          </>
        )}
      </div>
      <div className="p-col-4 amount">
        <span>{!isUDMLoading && Utils.coveragePremium(riderSummary.prwOnPayor.covInfo.targetUDMCoverage, locale)}</span>
      </div>
    </div>
  );
};

const displayCoveragePremium = (product, isUDMLoading, info, locale, inforce) => {
  return (
    <>
      {!Utils.isMULOrPG(product) && !Utils.isPmaxOrSB(product) && !Utils.isSummaryAmountsHidden(product, inforce) && (
        <span role="amount">{!isUDMLoading && Utils.coveragePremium(info.targetUDMCoverage, locale)}</span>
      )}
    </>
  );
};

const SummaryRiderSection = (props) => {
  const {
    isRiderSummaryFolded,
    riderSummary,
    dispatchAction,
    locale,
    isUDMLoading,
    numChildren,
    intl,
    product,
    clients,
    inforce,
  } = props;
  return (
    <MWIAccordion
      folded={isRiderSummaryFolded}
      title={
        <span className="summary-accordion-font" role="riderTitle">
          {product === eProduct.LC ? (
            <FormattedMessage id="lc.riders.label" />
          ) : (
            <FormattedMessage id="summary.ridersummary" />
          )}
        </span>
      }
      onClickChange={() => dispatchAction(eField.isRiderSummaryFolded, !isRiderSummaryFolded, 'scenario')}
      className={'summary-accordion-no-border'}
    >
      <div className="rider-group" tabIndex="0">
        {riderSummary?.rider?.map((rider, riderIndex) => {
          return (
            <div
              className={`p-grid p-grid-no-margin no-margin ${
                riderIndex === 0 ? mwiTopPadding : ''
              }${mwiBottomPadding}`}
              key={riderIndex}
            >
              {![eRider.spr, eRider.tor].includes(
                rider.covInfo[0].targetUDMCoverage?.product ?? rider.covInfo[0].riderProduct
              ) &&
                rider.clients.map((client, clientIndex) => {
                  return (
                    <div className="p-col-12 info" key={clientIndex} role="client">
                      {client.fullName || client.placeholder}
                    </div>
                  );
                })}
              {rider.covInfo.map((info, index) => {
                const duration = (info.targetUDMCoverage && info.targetUDMCoverage.duration) || 0;
                return (
                  <React.Fragment key={index}>
                    <div className="p-col-8 label">
                      <span role="abbr">{info.abbr}</span>
                      {info.type && info.type === eCoverageType.combined && (
                        <>
                          {`, `}
                          <FormattedMessage id="covType.combined" />
                        </>
                      )}
                      {info.amount && `, ${getFormattedValue(locale, info.amount, 0)}`}
                      {renderRiderRatings(product, info, clients, duration, intl, rider, inforce)}
                    </div>
                    <div className="p-col-4 amount">
                      {displayCoveragePremium(product, isUDMLoading, info, locale, inforce)}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
        {numChildren > 0 && renderChildRider(props)}
        {riderSummary && riderSummary.tdwOnPayor && renderTdwOnPayor(props)}
        {riderSummary && riderSummary.prwOnPayor && renderPrwOnPayor(props)}
      </div>
    </MWIAccordion>
  );
};

SummaryRiderSection.propTypes = {
  isRiderSummaryFolded: PropTypes.bool,
  riderSummary: PropTypes.object,
  dispatchAction: PropTypes.func,
  locale: PropTypes.string,
  isUDMLoading: PropTypes.bool,
  numChildren: PropTypes.number,
  isRiderSelected: PropTypes.bool,
  inforce: PropTypes.bool,
  intl: PropTypes.object,
  product: PropTypes.string,
  clients: PropTypes.object,
};

export default SummaryRiderSection;
