import React, { PureComponent } from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './landing-page.css';
import _ from 'lodash';
import VitalityLinks from './vitality-links';

export class ManulifeParDescription extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    locale: PropTypes.string,
  };

  render() {
    const numTextMessages = 5;
    return (
      <React.Fragment>
        <div className="p-col-10">
          <FormattedMessage id="landing.par.productDescription" />
        </div>
        <div className="p-col-10" style={{ marginTop: '10px' }}>
          <FormattedMessage id="landing.par.productDescription2" />
        </div>
        <div className="p-col-10">
          <ul className="details-list">
            {_.map(_.range(1, numTextMessages, 1), (index) => (
              <li key={index}>
                <FormattedMessage id={`landing.par.newtext${index}`} />
              </li>
            ))}
          </ul>
        </div>
        <div className="p-col-10 product-description">
          <br />
          <FormattedHTMLMessage id="landing.par.productDescription3" />
        </div>
        <VitalityLinks />
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(ManulifeParDescription));
