import UDMGenerator from '../../udm-mapper/udmGenerator';

const removePayerInformartion = (shareModel, x, clientList) => {
  if (shareModel.riders[x.id].PTDW.payor.id) {
    const payorId = shareModel.riders[x.id].PTDW.payor.id;
    if (shareModel.riders[x.id].PTDW[payorId].firstName) {
      clientList.push(shareModel.riders[x.id].PTDW[payorId].firstName);
      shareModel.riders[x.id].PTDW[payorId].firstName = '';
    }
    if (shareModel.riders[x.id].PTDW[payorId].lastName) {
      clientList.push(shareModel.riders[x.id].PTDW[payorId].lastName);
      shareModel.riders[x.id].PTDW[payorId].lastName = '';
    }
    if (shareModel.riders[x.id].PTDW[payorId].fullName) {
      clientList.push(shareModel.riders[x.id].PTDW[payorId].fullName);
      shareModel.riders[x.id].PTDW[payorId].fullName = '';
    }
  }
};

const removeChildrenNames = (shareModel, x, clientList) => {
  shareModel.riders[x.id].CPR.allChildren.forEach((c) => {
    if (shareModel.riders[x.id].CPR[c].firstName) {
      clientList.push(shareModel.riders[x.id].CPR[c].firstName);
      shareModel.riders[x.id].CPR[c].firstName = '';
    }

    if (shareModel.riders[x.id].CPR[c].lastName) {
      clientList.push(shareModel.riders[x.id].CPR[c].lastName);
      shareModel.riders[x.id].CPR[c].lastName = '';
    }

    if (shareModel.riders[x.id].CPR[c].fullName) {
      clientList.push(shareModel.riders[x.id].CPR[c].fullName);
      shareModel.riders[x.id].CPR[c].fullName = '';
    }
  });
};

const removeModelPi = (shareModel) => {
  const clientList = [];

  // Get a list of how many scenario tabs there are and there ids
  shareModel.scenarioTabNavs.tabNavs.forEach((x) => {
    // Get all clients in specific scenario and iterate through them
    shareModel.clients[x.id].allClients.forEach((z) => {
      if (shareModel.clients[x.id][z].firstName) {
        clientList.push(shareModel.clients[x.id][z].firstName);
        shareModel.clients[x.id][z].firstName = '';
      }

      if (shareModel.clients[x.id][z].lastName) {
        clientList.push(shareModel.clients[x.id][z].lastName);
        shareModel.clients[x.id][z].lastName = '';
      }

      if (shareModel.clients[x.id][z].fullName) {
        clientList.push(shareModel.clients[x.id][z].fullName);
        shareModel.clients[x.id][z].fullName = '';
      }
    });

    // To set Special Quote Options to none
    // Get all Coverage types
    // Iterate through all coverages in each Scenario and set specialQuoteOption to None
    shareModel.coverageTabNavs[x.id].tabNavs.forEach((a) => {
      shareModel.coverageTabNavs[x.id][a].specialQuoteOption = 'None';
    });

    // Remove Payer Information
    removePayerInformartion(shareModel, x, clientList);

    // Remove children names for each scenario
    removeChildrenNames(shareModel, x, clientList);
  });

  // Check if scenario name contains any client names
  shareModel.scenarioTabNavs.tabNavs.forEach((m) => {
    // Skip iteration if scenario name is empty
    if (shareModel.scenarioTabNavs.topBars[m.id].scenarioName) {
      const sanitizedScenarioName = shareModel.scenarioTabNavs.topBars[m.id].scenarioName
        .toLowerCase()
        .replace(/[^A-Za-z]+/g, '');
      clientList.forEach((n) => {
        const sanitizedClientName = n.toLowerCase().replace(/[^A-Za-z]+/g, '');
        if (sanitizedScenarioName.includes(sanitizedClientName)) {
          shareModel.scenarioTabNavs.topBars[m.id].scenarioName = '';
        }
      });
    }
  });

  // Check if client name contains any cleaned scenario name
  clientList.forEach((n) => {
    // Skip iteration if client name is empty
    if (!n) {
      return;
    }
    const sanitizedClientName = n.toLowerCase().replace(/[^A-Za-z]+/g, '');
    shareModel.scenarioTabNavs.tabNavs.forEach((m) => {
      const sanitizedScenarioName = shareModel.scenarioTabNavs.topBars[m.id].scenarioName
        .toLowerCase()
        .replace(/[^A-Za-z]+/g, '');
      if (sanitizedClientName.includes(sanitizedScenarioName)) {
        shareModel.scenarioTabNavs.topBars[m.id].scenarioName = '';
      }
    });
  });

  // Remove the business cards from the share case by setting advisorProfile to an empty object.
  shareModel.advisorProfile = {};

  return shareModel;
};

const removeUdmPi = (udm, advisorProfile) => {
  let foundBusinessCard = -1;

  // Remove first and last name from udm
  // Also mark the index of the business card if found
  udm.illustration.party.forEach((item, index) => {
    udm.illustration.party[index].firstName = '';
    udm.illustration.party[index].lastName = '';
    if (advisorProfile[item.partyId]) {
      foundBusinessCard = index;
    }
  });

  // Remove the business card from the udm, leaving other parties intact.
  if (foundBusinessCard > -1) {
    udm.illustration.party.splice(foundBusinessCard, 1);
  }

  // Set special quote to none always
  udm.illustration.policy.coverage.forEach((item, index) => {
    if (item.specialQuote) {
      udm.illustration.policy.coverage[index].specialQuote = 'None';
    }
  });

  return udm;
};

export const getSharedCaseModel = (props) => {
  const { model } = props;
  // To ensure object is not sharing reference/pointing to the model object
  const shareModelParsed = JSON.parse(JSON.stringify(model));
  const udmGenerator = new UDMGenerator();
  const udm = udmGenerator.setProps(props).getUDM();
  const product = shareModelParsed?.scenarioTabNavs?.topBars['Scenario.1']?.product;
  const englishSuffix = ' - Shared';
  const frenchSuffix = ' - Partagé';
  const advisorProfile = model.advisorProfile;

  let name = props?.app?.openedCaseName;

  if (!name || name === '' || name == null) {
    name = `New illustration - ${product}`;
  } else if (name.endsWith(englishSuffix)) {
    name = name.replace(englishSuffix, '');
  } else if (name.endsWith(frenchSuffix)) {
    name = name.replace(frenchSuffix, '');
  } else {
    //do nothing
  }

  const caseModel = {
    model: removeModelPi(shareModelParsed),
    udm: removeUdmPi(udm, advisorProfile),
    name,
    product,
  };

  return caseModel;
};
