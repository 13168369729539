import * as actionTypes from '../../core/actionTypes';
import { call, put, select } from 'redux-saga/effects';
import { configs, eProduct, eField, eInsuranceType } from '../configs';
import UDMGenerator from './udmGenerator';
import { requestValidation } from './sideEffect';

const manualRefreshState = (state) => {
  const { coverageTabNavs, deposits, scenarioTabNavs } = state;
  const product = coverageTabNavs[eField.product];
  let manualRefresh = scenarioTabNavs.manualRefresh;

  // Settings for MUL
  if (eProduct.MUL === product) {
    const depositType = deposits[deposits.activeTabId].depositType;
    const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
    const scenarioTabId = state.scenarioTabNavs.activeTabId;
    const amountOfInsuranceType = coverageState[coverageState.activeTabId].amountOfInsuranceType;
    const withdrawal = state.withdrawals[scenarioTabId];
    const WithdrawalCustomAmount = withdrawal && withdrawal.customAmount && withdrawal.customAmount.length > 0;

    // Setting 1:  Summary call if deposit type is not equal [Specified]
    manualRefresh = manualRefresh || depositType !== null;

    // Setting 2: Summary call if Amount of insurance type is not equal [Specified]
    manualRefresh = manualRefresh || amountOfInsuranceType !== eInsuranceType.specified;

    // Setting 3: Summary call if error custom withdrawal should send data back to back-end
    manualRefresh = manualRefresh || WithdrawalCustomAmount;
  }
  return manualRefresh;
};

export const getState = (state) => {
  const scenarioTabId = state.scenarioTabNavs.activeTabId;
  const coverages = state.coverageTabNavs[scenarioTabId];
  const activewithdrawals = state.withdrawals[scenarioTabId];
  const concepts = state.conceptTabNavs[scenarioTabId];

  return {
    app: state.app,
    isOutOfDate: state.scenarioTabNavs.isOutOfDate,
    manualRefresh: manualRefreshState(state),
    isForceUpdate: state.scenarioTabNavs.isForceUpdate,
    isSpreadsheetView: state.scenarioTabNavs.isSpreadsheetView,
    policyData: state.scenarioTabNavs.topBars[scenarioTabId],
    clients: state.clients[scenarioTabId],
    deposits: state.deposits[scenarioTabId],
    riders: state.riders[scenarioTabId],
    termRiders: state.termRiders[scenarioTabId],
    termOtherRiders: state.termOtherRiders[scenarioTabId],
    spousalRiders: state.spousalRiders[scenarioTabId],
    vitalityStatus: state.vitalityStatus[scenarioTabId],
    report: state.report[state.scenarioTabNavs.activeTabId],
    advisorProfile: state.advisorProfile,
    withdrawal: activewithdrawals,
    coverages,
    scenarioTabId,
    concepts,
  };
};

export function* requestUDMResponse() {
  let udmRequest = new UDMGenerator();
  const request = yield select(getState);
  const refreshUrl = request.manualRefresh ? configs.endPoints.summary : configs.endPoints.validation;
  const requestUrl = request.isSpreadsheetView ? configs.endPoints.spreadsheet : refreshUrl;
  yield put({ type: actionTypes.LOADING_UDM_RESPONSE });
  udmRequest = yield call(udmRequest.setProps, request);
  const udm = yield call(udmRequest.getUDM);
  try {
    const response = yield call(requestValidation, udm, requestUrl);
    if (response.status === 'cancel') {
      yield put({
        type: actionTypes.CANCEL_REQUEST,
      });
    } else {
      yield put({
        type: actionTypes.LOADED_UDM_RESPONSE,
        payload: { response, scenarioTabId: request.scenarioTabId },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOADED_UDM_RESPONSE,
      payload: { response: error, scenarioTabId: request.scenarioTabId },
    });
  }
}
