import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import TaxInformation from '../../core/components/tax-information';
import AlternateInvestment from '../../core/components/alternate-investment';
import LifeExpectancyAssumption from '../../core/components/life-expectancy-assumption';
import CorporateIRPStructure from '../../core/components/corporate-irp-structure';
import TaxInformationRates from '../../core/components/tax-information-rates';
import ConceptDividendScales from '../../core/components/dividend-scales-concepts';
import LoanInformation from '../../core/components/loan-information';
import DocumentTitle from 'react-document-title';
import { conceptValidator, isFeatureFlagSet, trackUserActions } from '../../core/utils';
import MWIDropdown from '../../core/components/mwi-dropdown';
import { dropDownOptions } from '../../core/dropdown-config';
import {
  eField,
  eActionEvent,
  eConcept,
  eReqStatus,
  eReportType,
  eProduct,
  ePremiumDuration,
} from '../../core/configs';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';
import { ConceptDescription } from './concept-description';
import ViewReportButton from '../../core/components/view-report';
import _ from 'lodash';
import HelpQuestionMark from '../../core/components/help-question-mark';

import * as AppActions from '../../core/actions';
import * as ConceptActions from './actions';
import * as ScenarioActions from '../../custom-mode/scenario-tab-navs/actions';
import './concepts.css';

const showViewConceptButton = function (concept, isValidationError, alternateInvestment, udmResponse) {
  return (
    concept !== eConcept.NONE &&
    concept !== '' && (
      <div id="select-concept-button" className="p-col-6 p-xl-7 p-lg-8 p-col-align-end">
        <ViewReportButton
          label={this.props.intl.formatMessage({ id: 'concept.viewConcept' })}
          styleClass="button-width"
          disabled={isValidationError || alternateInvestment.isAllocationError || udmResponse.isLoading}
          reportType={eReportType.concept}
        />
      </div>
    )
  );
};
const getConseptMessage = (product, isVitalityPlusSelected, premiumDuration) => {
  if (product === eProduct.MUL){
    return <FormattedMessage id="concept.mul.details" />;
  }

  return !isVitalityPlusSelected && premiumDuration === ePremiumDuration.Pay100 ? (
          <FormattedHTMLMessage id="concept.parpay100.details" />
        ) : (
          <FormattedHTMLMessage id="concept.parvp.details" />
        );
};
const showConceptDetailsPar = (product, isVitalityPlusSelected, premiumDuration) => {
  return (product === eProduct.PAR && isVitalityPlusSelected) || premiumDuration === ePremiumDuration.Pay100;
};
const getConceptDetails = (product, isVitalityPlusSelected, premiumDuration, intl) =>
  (product === eProduct.MUL || showConceptDetailsPar(product, isVitalityPlusSelected, premiumDuration)) && (
    <div className="p-col-10" id="ConceptDetails">
      <div style={{ marginTop: '10px' }}>
        {getConseptMessage(product, isVitalityPlusSelected, premiumDuration)}
        <a href="mailto:ConceptQuote@manulife.ca" className="email-link">
          <React.Fragment>{intl.formatMessage({ id: 'concept.all.email' })}</React.Fragment>
        </a>
      </div>
      <div style={{ marginTop: '10px' }}>
        <FormattedMessage id="concept.all.details2" />
      </div>
      <div style={{ marginTop: '10px' }}>
        <FormattedMessage id="concept.all.details3" />
      </div>
      <div>
        <ul className="details-list">
          <li>
            <FormattedMessage id="concept.all.details3A" />
          </li>
          <li>
            <FormattedMessage id="concept.all.details3B" />
          </li>
          <li>
            <FormattedMessage id="concept.all.details3C" />
          </li>
          <li>
            <FormattedMessage id="concept.all.details3D" />
          </li>
        </ul>
      </div>
    </div>
  );

const getTaxInformation = function (concept, taxInformation) {
  return (
    conceptValidator(concept, 'TaxInformation') && (
      <div className="p-col-12 accordionDiv">
        <MWIAccordion
          className="mwi-reverse-margin15 mwi-lightGreen "
          title={<FormattedMessage id="concept.taxInformation.label" />}
          folded={taxInformation.isFolded}
          onClickChange={() => this.toggleFolded(!taxInformation.isFolded, eField.taxInformation)}
        >
          <div className="concept-component">
            <TaxInformation
              concept={concept}
              taxInformation={taxInformation}
              setConceptFieldValue={this.setConceptFieldValue}
            />
          </div>
        </MWIAccordion>
      </div>
    )
  );
};

const getTaxRateInformation = function (concept, taxInformationRates) {
  return (
    conceptValidator(concept, 'TaxInformationRates') && (
      <div className="p-col-12 accordionDiv">
        <MWIAccordion
          className="mwi-reverse-margin15 mwi-lightGreen"
          title={<FormattedMessage id="concept.taxInformationRates.label" />}
          folded={taxInformationRates.isFolded}
          onClickChange={() => this.toggleFolded(!taxInformationRates.isFolded, eField.taxInformationRates)}
        >
          <div className="concept-component">
            <TaxInformationRates
              taxInformationRates={taxInformationRates}
              setConceptFieldValue={this.setConceptFieldValue}
            />
          </div>
        </MWIAccordion>
      </div>
    )
  );
};

const getConceptDividendScalesInformation = function (concept, dividendScale, conceptDividendScales, bankLoanRate) {
  return (
    conceptValidator(concept, 'ConceptDividendScales') && (
      <div className="p-col-12 accordionDiv">
        <MWIAccordion
          className="mwi-reverse-margin15 mwi-lightGreen"
          title={<FormattedMessage id="dividendscale.label" />}
          folded={dividendScale.isFolded}
          onClickChange={() => this.toggleFolded(!dividendScale.isFolded, eField.dividendScale)}
        >
          <div className="concept-component" id="dividend-scale-accordion">
            <ConceptDividendScales
              dividendScale={dividendScale}
              setConceptFieldValue={this.setConceptFieldValue}
              conceptDividendScales={conceptDividendScales}
              bankLoanRate={bankLoanRate}
            />
          </div>
        </MWIAccordion>
      </div>
    )
  );
};

const getConceptValidator = function (concept, corporateIRPStructure, companyTypeOptions) {
  return (
    conceptValidator(concept, 'CorporateIRPStructure') && (
      <div className="p-col-12 accordionDiv">
        <MWIAccordion
          className="mwi-reverse-margin15 mwi-lightGreen "
          title={<FormattedMessage id="concept.corporateIRPStructure" />}
          folded={corporateIRPStructure.isFolded}
          onClickChange={() => this.toggleFolded(!corporateIRPStructure.isFolded, eField.corporateIRPStructure)}
        >
          <div className="concept-component">
            <CorporateIRPStructure
              companyTypeOptions={companyTypeOptions}
              corporateIRPStructure={corporateIRPStructure}
              setConceptFieldValue={this.setConceptFieldValue}
            />
          </div>
        </MWIAccordion>
      </div>
    )
  );
};

const getLifeExpectancyAssumption = function (
  concept,
  lifeExpectancyAssumption,
  coverage,
  activeScenarioTabId,
  clients
) {
  return (
    conceptValidator(concept, 'LifeExpectancyAssumption') && (
      <div className="p-col-12 accordionDiv">
        <MWIAccordion
          className="mwi-reverse-margin15 mwi-lightGreen "
          title={<FormattedMessage id="concept.lifeExpectancyAssumption.label" />}
          folded={lifeExpectancyAssumption.isFolded}
          onClickChange={() => this.toggleFolded(!lifeExpectancyAssumption.isFolded, eField.lifeExpectancyAssumption)}
        >
          <div className="concept-component">
            <LifeExpectancyAssumption
              lifeExpectancyAssumption={lifeExpectancyAssumption}
              setConceptFieldValue={this.setConceptFieldValue}
              ratings={coverage.ratings}
              coverageType={coverage.coverageType}
              activeScenarioTabId={activeScenarioTabId}
              client1={clients.client1}
              client2={clients.client2}
              equivalentAge={coverage.equivalentAge}
              minAge={coverage.minClientAge}
            />
          </div>
        </MWIAccordion>
      </div>
    )
  );
};

const getLoanInformationFunc = function (concept, loanAmountOptions, clients) {
  const { loanInformation, activeScenarioTabId, lifeExpectancyAssumption, coverage, primaryDividendScale } = this.props;
  return (
    conceptValidator(concept, 'LoanInformation') && (
      <div className="p-col-12 accordionDiv">
        <MWIAccordion
          className="mwi-reverse-margin15 mwi-lightGreen"
          title={<FormattedMessage id="concept.loaninformation.label" />}
          folded={loanInformation.isFolded}
          onClickChange={() => this.toggleFolded(!loanInformation.isFolded, eField.loanInformation)}
        >
          <div className="concept-component">
            {this.getLoanInformation(
              loanInformation,
              primaryDividendScale,
              activeScenarioTabId,
              coverage,
              lifeExpectancyAssumption,
              loanAmountOptions,
              clients
            )}
          </div>
        </MWIAccordion>
      </div>
    )
  );
};

const getMwiDropdownConceptOptions = function (concept, conceptOptions) {
  return (
    <MWIDropdown
      name="conceptDropdown"
      id="conceptDropdown"
      label={<FormattedMessage id="concept.selectConcept" />}
      value={concept}
      options={conceptOptions}
      onChange={(e) => this.dispatchAction(eField.concept, e.target.value, false, 'scenario')}
      isRequired={false}
      grid={12}
    />
  );
};

export class Concept extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    taxInformation: PropTypes.object,
    alternateInvestment: PropTypes.object,
    lifeExpectancyAssumption: PropTypes.object,
    conceptActions: PropTypes.object,
    sceActions: PropTypes.object,
    activeScenarioTabId: PropTypes.string.isRequired,
    appActions: PropTypes.object,
    concept: PropTypes.string,
    corporateIRPStructure: PropTypes.object,
    coverage: PropTypes.object,
    client1: PropTypes.object,
    client2: PropTypes.object,
    taxInformationRates: PropTypes.object,
    loanInformation: PropTypes.object,
    dividendScale: PropTypes.object,
    isOutOfDate: PropTypes.bool,
    isForceUpdate: PropTypes.bool,
    primaryDividendScale: PropTypes.string,
    alternateDividendScale: PropTypes.string,
    bankLoanRate: PropTypes.string,
    udmResponse: PropTypes.object,
    product: PropTypes.string,
  };
  constructor(props) {
    super(props);

    this.dispatchAction('isOpen', true);
    this.state = { ariaBusy: false };
  }

  componentDidMount() {
    if (
      this.props.product === eProduct.PAR &&
      (this.props.coverage.isVitalityPlusSelected || this.props.coverage.premiumDuration === ePremiumDuration.Pay100)
    ) {
      this.dispatchAction(eField.concept, eConcept.NONE, false, 'scenario');
    }
    if (this.props.isOutOfDate) {
      this.props.sceActions.toggleForceUpdate(true);
    }
  }

  componentWillUnmount() {
    this.dispatchAction('isOpen', false);
  }

  dispatchAction(field, value, isError = false, target = 'concepts') {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
    });
    if (target === 'scenario') {
      this.props.sceActions.toggleOutOfDate(true);
    }

    if (field === eField.concept) {
      trackUserActions(eActionEvent.selection, 'Concept', value, '');
      this.props.sceActions.performManualRefresh(true);
      this.props.sceActions.requestUDMResponse();
    }
  }

  // chahe to fieldName, fieldValue, fieldParent, fieldParent2
  setConceptFieldValue = (fieldName, fieldValue, fieldParent, fieldParent2, refresh = true) => {
    /* update store with fieldName: value fieldParent2 is to
       allow for alternateInvestment state values where allocation and rateOfReturn are sub objects
       !!! TO BE REFRACTORED to not use evt and val, but rather field and field is
    */

    this.props.conceptActions.setConceptFieldValue({
      fieldName,
      fieldValue,
      fieldParent,
      fieldParent2,
      scenarioTabId: this.props.activeScenarioTabId,
    });
    if (refresh) {
      this.props.sceActions.toggleOutOfDate(true);
    }
  };

  toggleFolded(folded, parent) {
    this.setConceptFieldValue(eField.isFolded, folded, parent, undefined, false);
  }

  setDurationDefault = (durationtype, from, to) => {
    this.props.conceptActions.setDurationDefault({
      [eField.duration]: durationtype,
      [eField.durationFrom]: from,
      [eField.durationTo]: to,
      scenarioTabId: this.props.activeScenarioTabId,
    });
    this.props.sceActions.toggleOutOfDate(true);
  };

  setAllocationValues = (fieldName, value, type) => {
    let calculatedValues;
    if (type === eField.percentage) {
      calculatedValues = this.calculateAllocationValues(
        this.props.alternateInvestment.investmentAllocation,
        fieldName,
        value
      );
      this.props.conceptActions.setAllocationValues({
        ...calculatedValues,
        scenarioTabId: this.props.activeScenarioTabId,
        type,
      });
    } else {
      this.props.conceptActions.setAllocationValues({
        fieldName,
        type,
        fieldValue: value,
        scenarioTabId: this.props.activeScenarioTabId,
      });
    }
    // if isAllocationError no udm request
    if (
      !(calculatedValues && calculatedValues.isAllocationError) &&
      !this.props.alternateInvestment.isAllocationError
    ) {
      this.props.sceActions.toggleOutOfDate(true);
    }
  };
  // compute Allocation interest
  calculateAllocationValues = (allocation, fieldName, fieldValue) => {
    let sum = 0;
    let interest;
    let isAllocationError;
    const maxAllocationAllowed = 100;
    fieldValue = Math.round(fieldValue);
    Object.keys(allocation).forEach((key) => {
      // find the sum of the values, skip if interest
      const intrest = key !== 'interest' ? allocation[key]['percentage'] : 0;
      sum += key === fieldName ? fieldValue : intrest;
    });
    const diff = maxAllocationAllowed - sum;
    if (diff < 0) {
      interest = 0;
      isAllocationError = true;
    } else {
      interest = diff;
      isAllocationError = false;
    }
    return {
      interest,
      fieldName,
      fieldValue,
      isAllocationError,
    };
  };

  setGrowth = () => {
    this.setState({ ariaBusy: true });
    this.props.conceptActions.setGrowth({ scenarioTabId: this.props.activeScenarioTabId });
    this.props.sceActions.toggleOutOfDate(true);
    this.setState({ ariaBusy: false });
  };

  setIncome = () => {
    this.setState({ ariaBusy: true });
    this.props.conceptActions.setIncome({ scenarioTabId: this.props.activeScenarioTabId });
    this.props.sceActions.toggleOutOfDate(true);
    this.setState({ ariaBusy: false });
  };

  setBalanced = () => {
    this.setState({ ariaBusy: true });
    this.props.conceptActions.setBalanced({ scenarioTabId: this.props.activeScenarioTabId });
    this.props.sceActions.toggleOutOfDate(true);
    this.setState({ ariaBusy: false });
  };

  getLoanInformation(
    loanInformation,
    primaryDividendScale,
    activeScenarioTabId,
    coverage,
    lifeExpectancyAssumption,
    loanAmountOptions,
    clients
  ) {
    return (
      <LoanInformation
        loanInformation={loanInformation}
        setConceptFieldValue={this.setConceptFieldValue}
        setDurationDefault={this.setDurationDefault}
        primaryDividendScale={primaryDividendScale}
        activeScenarioTabId={activeScenarioTabId}
        coverageType={coverage.coverageType}
        lifeExpectancyAssumption={lifeExpectancyAssumption}
        loanAmountOptions={loanAmountOptions}
        client1={clients.client1}
        client2={clients.client2}
        equivalentAge={coverage.equivalentAge}
        minAge={coverage.minClientAge}
      />
    );
  }

  render() {
    const {
      intl,
      taxInformation,
      alternateInvestment,
      lifeExpectancyAssumption,
      concept,
      corporateIRPStructure,
      coverage,
      activeScenarioTabId,
      client1,
      client2,
      taxInformationRates,
      dividendScale,
      bankLoanRate,
      udmResponse,
      product,
    } = this.props;
    const clients = { client1, client2 };
    const isValidationError = udmResponse.validationStatus.toUpperCase() === eReqStatus.error;
    const { conceptOptions, companyTypeOptions, conceptDividendScales, loanAmountOptions } = dropDownOptions(intl);
    const showProductConceptDropDown =
      (product === eProduct.MUL && !isFeatureFlagSet()) || // not allow MUL/MULVP in prod
      (product === eProduct.PAR &&
        !coverage.isVitalityPlusSelected &&
        coverage.premiumDuration !== ePremiumDuration.Pay100); // only allow PAR in prod
    return (
      <div>
        <DocumentTitle
          title={`${intl.formatMessage({ id: 'common.illustrations' })} - ${intl.formatMessage({
            id: 'concept.title',
          })}`}
        >
          <React.Fragment>
            <div className="form-main-concepts">
              <div className="p-grid p-grid-no-margin-all p-col-12 p-col-align-end">
                {showProductConceptDropDown && (
                  <React.Fragment>
                    <div className="p-col-6 p-lg-6 p-xl-5">
                      {getMwiDropdownConceptOptions.call(this, concept, conceptOptions)}
                    </div>
                    <div className="p-col-6 p-col-align-end">
                      <div className="p-grid p-grid-no-margin p-col-12">
                        <div className="p-col-2 p-xl-2 p-lg-3" id="concept-icon-container">
                          <HelpQuestionMark
                            containerClass={`select-concept-info ${
                              concept === eConcept.NONE ? 'help-icon' : 'help-icon-wide'
                            }`}
                            overlayId="concept-description"
                            overlayContent={() => <ConceptDescription concept={concept} />}
                          />
                        </div>
                        {showViewConceptButton.call(this, concept, isValidationError, alternateInvestment, udmResponse)}
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {getConceptDetails(product, coverage.isVitalityPlusSelected, coverage.premiumDuration, intl)}
              </div>
              {getTaxInformation.call(this, concept, taxInformation)}
              {getConceptValidator.call(this, concept, corporateIRPStructure, companyTypeOptions)}
              {getTaxRateInformation.call(this, concept, taxInformationRates)}
              {getConceptDividendScalesInformation.call(
                this,
                concept,
                dividendScale,
                conceptDividendScales,
                bankLoanRate
              )}
              {getLoanInformationFunc.call(this, concept, loanAmountOptions, clients)}
              {getLifeExpectancyAssumption.call(
                this,
                concept,
                lifeExpectancyAssumption,
                coverage,
                activeScenarioTabId,
                clients
              )}
              {conceptValidator(concept, 'AlternateInvestment') && (
                <div className="p-col-12 accordionDiv">
                  <MWIAccordion
                    className="mwi-reverse-margin15 mwi-lightGreen "
                    title={<FormattedMessage id="concept.alternateInvestment.label" />}
                    folded={alternateInvestment.isFolded}
                    onClickChange={() => this.toggleFolded(!alternateInvestment.isFolded, eField.alternateInvestment)}
                  >
                    <div className="concept-component">
                      <AlternateInvestment
                        investmentAllocation={alternateInvestment.investmentAllocation}
                        setGrowth={this.setGrowth}
                        setBalanced={this.setBalanced}
                        setIncome={this.setIncome}
                        setConceptFieldValue={this.setConceptFieldValue}
                        isAllocationError={alternateInvestment.isAllocationError}
                        setAllocationValues={this.setAllocationValues}
                        isIllustrateChecked={alternateInvestment.isIllustrateChecked}
                        concept={concept}
                        ariaBusy={this.state.ariaBusy}
                      />
                    </div>
                  </MWIAccordion>
                </div>
              )}
            </div>
          </React.Fragment>
        </DocumentTitle>
      </div>
    );
  }
}
export const mapStateToProps = ({ app, scenarioTabNavs, conceptTabNavs, coverageTabNavs, clients, udmResponse }) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const topbar = scenarioTabNavs.topBars[scenarioTabId];
  const coverages = coverageTabNavs[scenarioTabId];
  const coverage = coverages[coverages.activeTabId];
  const clientIdsInCov = _.keys(coverage.ratings);
  return {
    locale: app.locale,
    taxInformation: conceptTabNavs[scenarioTabId].taxInformation,
    alternateInvestment: conceptTabNavs[scenarioTabId].alternateInvestment,
    lifeExpectancyAssumption: conceptTabNavs[scenarioTabId].lifeExpectancyAssumption,
    corporateIRPStructure: conceptTabNavs[scenarioTabId].corporateIRPStructure,
    taxInformationRates: conceptTabNavs[scenarioTabId].taxInformationRates,
    loanInformation: conceptTabNavs[scenarioTabId].loanInformation,
    activeScenarioTabId: scenarioTabId,
    concept: topbar.concept,
    dividendScale: conceptTabNavs[scenarioTabId].dividendScale,
    primaryDividendScale: topbar.primaryDividendScale,
    alternateDividendScale: topbar.alternateDividendScale,
    client1: clients[scenarioTabId][clientIdsInCov[0]],
    client2: clients[scenarioTabId][clientIdsInCov[1]],
    isOutOfDate: scenarioTabNavs.isOutOfDate,
    isForceUpdate: scenarioTabNavs.isForceUpdate,
    coverage,
    udmResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  conceptActions: bindActionCreators(ConceptActions, dispatch),
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Concept));
