import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import * as AppActions from '../../core/actions';
import { dropDownOptions } from '../../core/dropdown-config';
import * as ScenarioActions from '../../custom-mode/scenario-tab-navs/actions';
import { eTermRenewalClient } from '../../core/configs';
import PremiumFrequency from '../../core/components/premium-frequency';
import Sex from '../../core/components/sex';
import Dob from '../../core/components/dob';
import CoverageEffectiveDate from '../../core/components/coverage-effective-date';
import CoverageAmount from '../../core/components/coverage-amount';
import HealthStyle from '../../core/components/health-style-trc';

import './calculator-input.css';

export class CalculatorInput extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    match: PropTypes.object,
    history: PropTypes.object,
    intl: PropTypes.object,
    index: PropTypes.number,
    uniqueId: PropTypes.number,
    isSpreadsheetView: PropTypes.bool,
    activeScenarioTabId: PropTypes.string,
    activeCoverageTabId: PropTypes.string,
    clients: PropTypes.object,
    clientIndex: PropTypes.number,
    coverage: PropTypes.object,
    clientActions: PropTypes.object,
    client1: PropTypes.object,
    udmEnabled: PropTypes.bool,
    selectedProduct: PropTypes.string,
    product: PropTypes.string,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    this.props.sceActions.toggleOutOfDate(true);
  }

  dispatchAction(field, value, isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target: 'client',
      scenarioTabId: this.props.activeScenarioTabId,
      coverageTabId: this.props.activeCoverageTabId,
      coverageOption: this.props.coverage.coverageOption,
      clientId: eTermRenewalClient.defaultId,
      product: this.props.product,
      field,
      value,
    });
  }

  dispatchScenarioAction(field, value, isError = false, refresh = true) {
    const { appActions, sceActions } = this.props;

    appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target: 'scenario',
      scenarioTabId: this.props.activeScenarioTabId,
      coverageTabId: this.props.activeCoverageTabId,
      coverageOption: this.props.coverage.coverageOption,
      clientId: eTermRenewalClient.defaultId,
      product: this.props.product,
      field,
      value,
    });
    if (refresh) {
      sceActions.toggleOutOfDate(true);
    }
  }

  onChange(type, value, isErrorAction) {
    if (type === 'effectiveDate') {
      this.dispatchScenarioAction(type, value, isErrorAction);
    } else {
      this.dispatchAction(type, value, isErrorAction);
    }

    if (type === 'healthstyle' || type === 'smokingstatus' || type === 'sex' || type === 'dob') {
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  onBlur(type, error, isErrorAction) {
    if (type === 'effectiveDate') {
      this.dispatchScenarioAction(type, error, isErrorAction);
    } else {
      this.dispatchAction(type, error, isErrorAction);
    }

    if (!error) {
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  render() {
    const { locale, intl, client1, index, uniqueId } = this.props;
    const { healthstyle } = dropDownOptions(intl);

    return (
      <React.Fragment>
        <div className="p-grid">
          <div className="p-col-3 p-lg-3 p-sm-4 trc-product-desc">
            <FormattedMessage id="ftrc.calculatorInput.product" />
          </div>
          <div className="p-col-3 p-lg-3 p-sm-4 trc-product-desc">
            <FormattedMessage id="ftrc.calculatorInput.coverageType" />
          </div>
          <div className="p-col-4 p-lg-4 p-sm-4 trc-product-desc">
            <FormattedMessage id="ftrc.calculatorInput.coverageOption" />
          </div>
        </div>
        <div className="p-grid mwi-margin-top18">
          <div className="p-col-10 p-sm-12 p-lg-10 trc-sub-section">
            <CoverageEffectiveDate person={client1} onChange={this.onChange} onBlur={this.onBlur} />
          </div>
        </div>
        <div className="p-grid mwi-margin-top18">
          <div className="p-col-3 p-lg-3 p-sm-7 trc-coverage-amount space-right">
            <CoverageAmount />
          </div>
          <div className="p-col-3 p-lg-3 p-sm-5 trc-premium-frequency">
            <PremiumFrequency />
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-12">
            <hr className="splitter client-splitter" />
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-12 calculator-section-title">
            <FormattedMessage id="ftrc.calculatorInput.insuredInformation" />
          </div>
          <div className="p-col-3 p-sm-6 p-lg-3 trc-sex space-right mwi-control-wrapper">
            <Sex person={client1} onChange={this.onChange} />
          </div>
          <div className="p-col-3 p-sm-6 p-lg-3 trc-dob">
            <Dob person={client1} onChange={this.onChange} onBlur={this.onBlur} />
          </div>
          {client1 && (
            <HealthStyle
              locale={locale}
              selectedHealthStyle={client1.healthstyle}
              healthStyles={healthstyle}
              index={index}
              uniqueId={uniqueId}
              onChange={(e) => this.onChange('healthstyle', e.target.value, false)}
              containerStyle="p-xl-2 p-lg-2 p-sm-5 trc-hs space-left"
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ landingPage, scenarioTabNavs, app, udmResponse, coverageTabNavs, clients }) => {
  const selectedProduct = landingPage.selectedProduct;
  const { udmEnabled, summaryError } = scenarioTabNavs;

  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const clientIdsInCov = _.keys(coverage.ratings);
  const illustration = udmResponse.illustration;
  const udmPolicy = (illustration && illustration.policy) || {};
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const product = activeScenario.product;
  const scenarioTabId = scenarioTabNavs.activeTabId;

  const isMultiCov = coverageState.tabNavs.length > 1;

  return {
    locale: app.locale,
    activeScenarioTabId: scenarioTabId,
    activeCoverageTabId: coverageState.activeTabId,
    clients: clients[scenarioTabId],
    clientIndex: clients.clientIndex,
    client1: clients[scenarioTabId][clientIdsInCov[0]],
    depositOptionType: activeScenario.depositOptionType,
    includeMaxLevelDepositOption: activeScenario.includeMaxLevelDepositOption,
    udmCoverages: udmPolicy.coverage,
    duration: udmPolicy.duration,
    coverage,
    isMultiCov,
    product,
    udmPolicy,
    selectedProduct,
    udmEnabled,
    summaryError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CalculatorInput));
