// application level
export const COMMON_INITIALIZE = 'APP: put in the condition appropriate to the start of an operation';
export const OPEN_SAVEDCASE = 'APP: open a saved case';
export const DELETE_SAVEDCASES = 'APP: delete saved cases';
export const SAVE_OPEN_CASE_NAME = 'APP: saved open case name';
export const SHOW_GLOBAL_ERROR = 'APP: show global error message';
export const MWI_ON_CHANGE = 'APP: onChange';
export const SHOW_NOTIFICATION = 'APP: showNotification';
export const HIDE_NOTIFICATION = 'APP: hideNotification';
export const SHOW_RATES_NOTIFICATION = 'APP: showRatesNotification';
export const HIDE_RATES_NOTIFICATION = 'APP: hideRatesNotification';
export const SHOW_STATUS_DIALOG = 'APP: show status dialog';
export const HIDE_STATUS_DIALOG = 'APP: hide status dialog';
export const MWI_ON_BLUR = 'APP: onBlur';
export const MWI_ON_FOCUS = 'APP: onFocus';
export const MWI_UPDATE_ERROR = 'APP: update errors';
export const GET_CALCULATIONS = 'APP: get calculations';
export const RECEIVE_CALCULATIONS = 'APP: receive calculations';
export const TOGGLE_LOCALE = 'APP: toggle locale';
export const TOGGLE_ACCESSIBLE = 'APP: toggle accessible';
export const LOADED_RESPONSE = 'APP: load response';
export const SHOW_WARNING = 'APP: show warning';
export const LOADING_UDM_RESPONSE = 'APP: loading udm response';
export const REQUEST_UDM_RESPONSE = 'APP: request udm response';
export const LOADED_UDM_RESPONSE = 'APP: loaded udm response';
export const ERROR_UDM_RESPONSE = 'APP: error udm response';
export const AUTH_USER = 'APP: authenticate Token and get user information';
export const AUTH_USER_DONE = 'APP: authenticate Token done successfully';
export const AUTH_CIAM_INFO = 'APP: Retrieved ciam info successfully';
export const AUTO_SAVE_CASE = 'APP: auto saving case';
export const SAVE_CASE_STARTED = 'APP: saving case started';
export const SAVE_CASE_ERROR = 'APP: error saving case';
export const SAVE_CASE_FINISHED = 'APP: saving case ended';
export const LANGUAGE_CHANGE = 'APP: language changed';
export const DELETE_CASE_ERROR = 'APP: error deleting case';
export const LOADING_INFORCE_REQUEST_FORM = 'APP: getting inforce request form link';
export const LOADED_INFORCE_REQUEST_FORM = 'APP: got inforce request form link';
export const ERROR_INFORCE_REQUEST_FORM = 'APP: error getting inforce request form link';
export const CANCEL_REQUEST = 'APP: cancel request';

// landing page
export const SELECT_PRODUCT = 'LP: select product';
export const SELECT_BUSINESS_TYPE = 'LP: select business type';
export const CLICK_PRODUCT_BTN = 'LP: click product btn';
export const CLICK_QUICK_MODE_BTN = 'LP: click quick mode btn';
export const CLICK_SIGN_IN = 'LP: click sign in'; // TODO not confirmed to have it on landing page
// Inforce actions
export const SEARCHING_INFORCE_POLICY = 'LP: search inforce policy';
export const SEARCHED_INFORCE_POLICY = 'LP: searched inforce policy';
export const GET_INFORCE_POLICY = 'LP: get inforce policy';
export const LOADING_INFORCE_POLICY = 'INF: authenticate user and load policy';
export const LOADED_INFORCE_POLICY = 'INF: loaded inforce policy';
export const ERROR_INFORCE_POLICY = 'INF: error loading inforce policy';
export const DEP_DEFAULT_INFORCE_DEPOSIT_AMOUNT = 'INF: default deposit amount for inforce policy';
export const RESET_INFORCE_SCREEN = 'INF: Reset inforce landing page';

// custom mode
export const SELECT_PRODUCT_IN_CUSTOM = 'CM: select product';
export const CLICK_TOPBAR_NAV = 'CM: click topbar';

// Scenario Tab Navs
export const LOAD_SCENARIO_TABNAVS = 'SCE: load tabnavs';
export const ADD_SCENARIO_TABNAVS = 'SCE: add tabnavs';
export const REMOVE_SCENARIO_TABNAVS = 'SCE: remove tabnavs';
export const CLICK_SCENARIO_TABNAVS = 'SCE: click tabnavs';
export const TOGGLE_OUT_OF_DATE = 'SCE: toggle out of date';
export const TOGGLE_FORCE_UPDATE = 'SCE: toggle force update';
export const TOGGLE_MANUAL_REFRESH = 'SCE: toggle manual refresh';
export const TOGGLE_RIDERS_PANEL = 'SCE: toggle riders panel';
export const TOGGLE_UDM = 'SCE: toggle UDM flag';
export const TOGGLE_SUMMARY = 'SCE: toggle summary flag';
export const PERFORM_MANUAL_REFRESH = 'SCE: perform manual refresh';

// Concept
export const SET_GROWTH = 'CEP: set growth alternate investment';
export const SET_INCOME = 'CEP: set income alternate investment';
export const SET_BALANCED = 'CEP: set balanced alternate investment';
export const SET_CONCEPT_FIELD_VALUE = 'CEP: set concept field value';
export const SET_ALLOCATION_VALUES = 'CEP: SET alternate investment allocation';
export const SET_LIFE_EXPECTANCY_VALUE = 'CEP: set life expectancy value';
export const SET_DURATION_DEFAULT_VALUES = 'CEP: set duration default values';

// Coverage Tab Navs
export const LOAD_COVERAGE_TABNAVS = 'COV: load tabnavs';
export const ADD_COVERAGE_TABNAVS = 'COV: add tabnavs';
export const REMOVE_COVERAGE_TABNAVS = 'COV: remove tabnavs';
export const CLICK_COVERAGE_TABNAVS = 'COV: click tabnavs';
export const SAVE_RATINGS = 'COV: save ratings';
export const RESET_RATINGS = 'COV: reset ratings';
export const COVERAGE_INITIALIZE = 'COV: reset values';
export const COVERAGE_TYPE = 'COV: coverage type changed';

// Clients
export const LOAD_CLIENTS = 'CLI: load clients';
export const ADD_CLIENT = 'CLI: add client';
export const REMOVE_CLIENT = 'CLI: remove client';
export const SELECT_CLIENT = 'CLI: select client';
export const UPDATE_CLIENT = 'CLI: update client';

// Term Riders
export const ADD_TERM_RIDER = 'TRM: add Term rider';
export const REMOVE_TERM_RIDER = 'TRM: remove Term rider';
export const SELECT_TERM_RIDER_CLIENT = 'TRM: select Term rider client';
export const UPDATE_TERM_RIDER_RATING = 'TRM: save raiting for Term rider ';

// Riders
export const TOGGLE_RIDER = 'RID: toggle rider';
export const TOGGLE_RIDER_CLIENT = 'RID: toggle rider client';
export const ADD_RIDER_CLIENT = 'RID: add rider client';
export const REMOVE_RIDER_CLIENT = 'RID: remove rider client';
export const ADD_CPR_CHILD = 'RID: add child';
export const REMOVE_CPR_CHILD = 'RID: remove child';
export const UPDATE_CHILD_RATINGS = 'RID: update child ratings';
export const UNSELECT_ALL_RIDERS = 'RID: unselect all riders';
export const UPDATE_PAYOR_RATINGS = 'RID: update payor ratings';

// vitality status
export const LOAD_VITALITY_STATUS = 'VIT: load vitality status';
export const SAVE_VITALITY_STATUS = 'VIT: save vitality status';

export const LOAD_RATINGS = 'RAT: load ratings';
export const REMOVE_RATINGS = 'RAT: remove ratings';
// export const RESET_RATINGS = 'RAT: reset ratings';
// quick mode

// Reports
export const CHANGE_LANG = 'REP: change language';
export const TOGGLE_PAGE_OPTION = 'REP: toggle page option';
export const GET_REPORT = 'REP: get report';
export const DOWNLOADED_REPORT = 'REP: download report';
export const DOWNLOAD_ERROR = 'REP: download error';

// Spreadsheet
export const LOADING_SPREADSHEET = 'SPS: loading spreadsheet';
export const LOADED_SPREADSHEET = 'SPS: loaded spreadsheet';
export const CLOSE_SPREADSHEET = 'SPS: close spreadsheet';

// advisor profile
export const OPEN_PROFILE = 'PRF: open profile';
export const UPSERT_PROFILE = 'PRF: upsert profile';
export const DELETE_PROFILE = 'PRF: delete profile';
export const SAVE_PROFILE = 'PRF: save profile';
export const CANCEL_PROFILE = 'PRF: cancel profile';
export const TOGGLE_PROFILE = 'PRF: toggle profile';

export const NO_PAYLOAD = false;

// withdrawal WTH
export const WTH_DURATION_CHANGE = 'WTH: duration changed';
export const WTH_CLIENT_CHANGE = 'WTH: based on client changed';
export const WTH_DEFAULT_DURATION = 'WTH: set default value for duration';

// deposit DEP
export const DEP_DURATION_CHANGE = 'DEP: duration changed';
export const DEP_DURATION_SET = 'DEP: duration set';
export const DEP_CLIENT_CHANGE = 'DEP: based on client changed';
export const DEP_DEFAULT_DURATION = 'DEP: set default value for duration';

// tests
export const INITIALIZE_TESTS = 'TST: initialize';
