import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { eProduct, eRider, eRatingTemplate } from '../../../core/configs';
import { dropDownOptions } from '../../../core/dropdown-config';
import Person from '../../../core/components/person';
import RatingsButton from '../../../core/components/ratings-button';
import HealthStyle from '../../../core/components/health-style';
import SmokingStatus from '../../../core/components/smoking-status';
import * as AppActions from '../../../core/actions';
import * as RiderActions from '../actions';
import * as ScenarioActions from '../../scenario-tab-navs/actions';
import { isJuvenile } from '../../../core/utils';
import './payor.css';

export class PayorRider extends PureComponent {
  static propTypes = {
    riderId: PropTypes.string,
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    intl: PropTypes.object,
    udmEnabled: PropTypes.bool,
    tdw: PropTypes.object,
    product: PropTypes.string,
    appActions: PropTypes.object,
    riderActions: PropTypes.object,
    sceActions: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveRatings = this.saveRatings.bind(this);
  }

  dispatchAction(field, value, isError = false) {
    const currentRider = this.props.tdw;
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target: 'rider',
      riderName: this.props.riderId,
      payorId: currentRider.payor.id,
      field,
      value,
    });
    if (this.props.product === eProduct.PAR) {
      this.props.sceActions.performManualRefresh(true);
    }
  }

  onChange(type, value, isErrorAction) {
    this.dispatchAction(type, value, isErrorAction);
    if (type === 'healthstyle' || type === 'smokingstatus' || type === 'sex') {
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  onBlur(type, error, isErrorAction) {
    this.dispatchAction(type, error, isErrorAction);

    if (!error) {
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  saveRatings(payorId, ratings) {
    this.props.riderActions.updatePayorRatings({ payorId, ratings });
    this.props.sceActions.toggleOutOfDate(true);
  }

  render() {
    const { locale, product, tdw, intl, inforce } = this.props;
    const currentRider = tdw;
    const { healthstyle, smokingStatus } = dropDownOptions(intl);
    const payorId = currentRider.payor.id;
    return (
      <div
        className="p-grid p-grid-no-margin wrapper"
        style={{
          overflow: product === eProduct.PG ? 'inherit' : 'hidden',
        }}
      >
        <div className="p-col-12 childDivider">
          <div className="rider-label" role="heading" aria-level="3">
            <span className="label-group">
              <i className="material-icons" aria-hidden="true">
                account_circle
              </i>
              <FormattedMessage id="rider.payor" /> {1}
            </span>
          </div>
          {this.props.udmEnabled && <div className="udm-id">{`${payorId} - ${currentRider[payorId].riderId}`}</div>}
          <div className="p-col-12 flexdisplay">
            <Person person={currentRider[payorId]} onChange={this.onChange} onBlur={this.onBlur} personId={payorId} />
            {product !== eProduct.PAR && product !== eProduct.LC && (
              <HealthStyle
                locale={locale}
                selectedHealthStyle={currentRider[payorId].healthstyle}
                healthStyles={healthstyle}
                onChange={(e) => this.onChange('healthstyle', e.target.value, false)}
                containerStyle={`p-col-2 p-xl-2 p-lg-3 ${this.props.product}`}
              />
            )}
            {product === eProduct.PAR && (
              <SmokingStatus
                locale={locale}
                selectedStatus={currentRider[payorId].smokingstatus}
                smokingStatuses={smokingStatus}
                disabled={isJuvenile(currentRider[payorId].age) || (this.props.inforce && product === eProduct.PAR)}
                onChange={(e) => this.onChange('smokingstatus', e.target.value, false)}
                containerStyle="p-col-2 p-lg-3 p-xl-3 space-right"
              />
            )}
            <RatingsButton
              onSave={(ratings) => this.saveRatings(payorId, ratings)}
              ratings={currentRider.ratings}
              ratingTemplate={eRatingTemplate.payor}
              partyId={payorId}
              containerStyle="payor-ratings"
              buttonStyle="button-width"
              locale={locale}
              inforce={inforce}
              product={product}
            />
          </div>
        </div>
      </div>
    );
  }
}

// TODO inject scenarioTabNavs to get scenario id
const mapStateToProps = ({ app, scenarioTabNavs, riders }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  return {
    locale: app.locale,
    inforce: app.inforcePolicy,
    tdw: riders[scenarioTabNavs.activeTabId][eRider.tdwpayor],
    product: activeScenario.product,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  riderActions: bindActionCreators(RiderActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PayorRider));
