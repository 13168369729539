import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AppActions from '../../actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { eField, eLocale, ePolicyInterestRate, maxPrformanceCreditRateSubstractor } from '../../configs';
import MWINumberField from '../mwi-calculated-number-field';

export class AltPerformanceCreditRate extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    scenarioTabNavs: PropTypes.object,
  };

  dispatchAction(field, value, target = 'scenario', isError = false) {
    const { appActions, sceActions } = this.props;

    appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
    });
    appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field: 'alternateUpdated',
      value: true,
    });
    this.props.sceActions.performManualRefresh();
    sceActions.toggleOutOfDate(true);
  }

  render() {
    const { locale, scenarioTabNavs } = this.props;
    const { topBars } = scenarioTabNavs;
    const { performanceCreditRate='', altPerformanceCreditRate='' } = topBars[scenarioTabNavs.activeTabId] || {};

    return (
      <div className="p-col-12 policy-interest-rate mwi-padding-right12 mwi-margin-bottom24">
        <MWINumberField
          decimalScale={2}
          fixedDecimalScale
          onBlur={(id, val) => this.dispatchAction(id, val)}
          decimalSeparator={locale === eLocale.en ? '.' : ','}
          suffix=" %"
          allowNegative={false}
          autoComplete="off"
          id={eField.altPerformanceCreditRate}
          name={eField.altPerformanceCreditRate}
          label={<FormattedMessage id="coverage.altPerformanceCreditRate" />}
          minValue={ePolicyInterestRate.min}
          local={locale}
          value={+altPerformanceCreditRate}
          maxValue={performanceCreditRate - maxPrformanceCreditRateSubstractor}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export const mapStateToProps = ({ app, scenarioTabNavs }) => {
  return {
    locale: app.locale,
    scenarioTabNavs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AltPerformanceCreditRate));
