import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { eDefault, eDefaultRatings } from '../../core/configs';
import MWIButton from '../../core/components/mwi-button';
import NumberFormat from 'react-number-format';
import { handleFocus } from '../../core/utils';
import './ratings.css';

const defaultRatings = { ...eDefaultRatings };

export class PayorRatings extends PureComponent {
  static propTypes = {
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    permRatingAmount: PropTypes.number,
    permRatingPercent: PropTypes.number,
    tempRatingAmount: PropTypes.number,
    tempRatingYear: PropTypes.number,
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);
    // because ratings can be cancelled, it should work with local state
    // once save is called it will be saved into the Store
    // initialize them with values in the store so that it doesn't lose the previous values
    // while routers changed
    this.state = {
      permRatingAmount: this.props.permRatingAmount,
      permRatingPercent: this.props.permRatingPercent,
      tempRatingAmount: this.props.tempRatingAmount,
      tempRatingYear: this.props.tempRatingYear,
    };
    this.savedRatings = { ...this.state };

    this.permRatingRef = React.createRef();

    this.reset = this.reset.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    this.permRatingRef.focus();
  }

  reset() {
    this.setState({ ...defaultRatings });
  }

  save() {
    this.savedRatings = { ...this.state };
    this.props.save(this.state);
  }

  cancel() {
    if (!this.props.inforce) {
      this.setState({ ...this.savedRatings });
    }
    this.props.cancel();
  }

  onChange(type, value) {
    this.setState({ [type]: +value });
  }

  onBlur(type, value) {
    const ONE_HUNDRED = 100;
    if (
      value === '' ||
      value < eDefault[type] ||
      (type === 'permRatingPercent' && +value.replace(/[%\s]/g, '') < ONE_HUNDRED)
    ) {
      this.setState({ [type]: eDefault[type] });
    }
  }

  render() {
    const { intl, inforce } = this.props;
    const SEVEN_HUNDRED_FIFTY = 750;
    return (
      <div className="p-grid p-grid-no-margin-side ratings-container">
        <h2 className="p-col-12 title">
          <FormattedMessage id="rating.title" />
        </h2>
        <div className="p-col-12 rating-row">
          <div className="p-col-8">
            <div className="mwi-label" id="tempRating">
              <FormattedMessage id="rating.perm.rate" />
            </div>
            <div className="p-col-5 flexfive">
              <NumberFormat
                name="perm-percent"
                className={`mwi-widget mwi-input number-data mwi-w80 `}
                value={this.state.permRatingPercent}
                suffix=" %"
                allowNegative={false}
                disabled={inforce}
                isAllowed={(values) => +values.value >= 0 && +values.value <= SEVEN_HUNDRED_FIFTY}
                onValueChange={(values) => this.onChange('permRatingPercent', values.value)}
                onBlur={(e) => this.onBlur('permRatingPercent', e.target.value)}
                aria-labelledby="permRating"
                autoComplete="off"
                onFocus={handleFocus}
                getInputRef={(input) => (this.permRatingRef = input)}
              />
            </div>
          </div>
        </div>

        {!inforce && (
          <div className="p-col-12 rating-footer flexdisplay">
            <MWIButton
              name="reset"
              label={intl.formatMessage({ id: 'common.reset' })}
              onClick={this.reset}
              styleClass="mwi-w80 reset-cancel-button reset-btn oneflex p-col-3"
            />
            <MWIButton
              name="cancel"
              label={intl.formatMessage({ id: 'common.cancel' })}
              onClick={this.cancel}
              styleClass="mwi-w80 reset-cancel-button cancel-btn oneflex p-col-3"
            />
            <MWIButton
              name="save"
              label={intl.formatMessage({ id: 'common.save' })}
              onClick={this.save}
              styleClass="mwi-w80 save-button oneflex p-col-3"
            />
          </div>
        )}
        {inforce && (
          <div className="p-col-12 rating-footer centerflexdisplay">
            <MWIButton
              name="close"
              label={intl.formatMessage({ id: 'common.close' })}
              onClick={this.cancel}
              styleClass="mwi-w25 reset-cancel-button cancel-btn p-col-3"
            />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(PayorRatings);
