import React, { PureComponent } from 'react';
import './summary.css';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import * as AppActions from '../../core/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

export class SummaryPremiumTabRateChange extends PureComponent {
  static propTypes = {
    app: PropTypes.object,
    intl: PropTypes.object,
    appActions: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.timer = null;
  }

  componentDidMount() {
    this.setRateNotification();
  }

  componentDidUpdate() {
    this.setRateNotification();
  }

  componentWillUnmount() {
    this.clearRatesNotificationTimeout();
  }

  clearRatesNotificationTimeout() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.dispatchNotification('hideRatesNotification');
      this.timer = null;
    }
  }

  dispatchNotification(type, field, value) {
    this.props?.appActions[type]({
      field,
      value,
    });
  }

  setRateNotification() {
    const hideRatesNotificationTime = 10000;
    if (this.props.app.showRatesNotification && !this.timer) {
      this.timer = setTimeout(() => {
        this.timer = null;
        this.dispatchNotification('hideRatesNotification');
      }, hideRatesNotificationTime);
    }
  }

  showRateChangeDiv = () => {
    return (
      <div className="summary-premium-rate-change">
        <i className="material-icons">info</i>
        <div id="ratesNotificationDiv" role="ratesNotificationDiv">{this.props.intl.formatMessage({ id: 'common.rateChange' })}</div>
      </div>
    );
  };

  render = () => (this.props.app.showRatesNotification ? this.showRateChangeDiv() : null);
}

export const mapStateToProps = ({ app, scenarioTabNavs }) => {
  const topbar = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  return {
    product: topbar.product,
    app
  };
};

const mapDispatchToProps = (dispatch) => ({ appActions: bindActionCreators(AppActions, dispatch) });
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SummaryPremiumTabRateChange));
