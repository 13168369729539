/* eslint-disable import/prefer-default-export */
export const brandingColors = {
  /** 2.01.01 Manulife Green*/
  light_4_green: '#CAEED9',
  light_3_green: '#ACE5C4',
  light_2_green: '#5CD790',
  light_1_green: '#00C46E',
  m_green: '#00A758',
  link_green: '#008048',
  dark_1_green: '#06874E',
  dark_2_green: '#046138',
  dark_3_green: '#004427',

  /** 2.01.02 Manulife Blue*/

  light_4_blue: '#C1D8F7',
  light_3_blue: '#76B0FF',
  light_2_blue: '#2D69FF',
  light_1_blue: '#1E1EE5',
  blue: '#0000C1',
  dark_1_blue: '#00009A',
  dark_2_blue: '#000082',
  dark_3_blue: '#000060',

  /** 2.01.03 Coral*/

  light_4_coral: '#F6DCD8',
  light_3_coral: '#F6CCC7',
  light_2_coral: '#FCACA1',
  light_1_coral: '#F69082',
  coral: '#FF7769',
  dark_1_coral: '#EC6453',
  dark_2_coral: '#DC5A44',
  dark_3_coral: '#C14A36',

  /** 2.01.05 Light Grey
   */
  light_2_grey: '#FAFAFA',
  light_1_grey: '#F5F5F5',
  light_grey: '#EDEDED',
  dark_1_light_grey: '#E5E5E8',
  dark_2_light_grey: '#DFE0E2',
  dark_3_light_grey: '#C2C3C9',
  accent_grey: '#858893',

  /** 2.01.04 Dark Navy
   */
  light_4_dark_navy: '#8E90A2',
  light_3_dark_navy: '#5E6073',
  light_2_dark_navy: '#424559',
  light_1_dark_navy: '#34384B',
  dark_navy: '#282B3E',
  super_dark_navy: '#1E212F',

  /** 2.01.07 Utility Colors
   */
  white: '#FFFFFF',
  red_error: '#DB1F00',

  /** 2.01.06 Accent Colors - Violet
   */
  light_4_violet: '#D9D2E8',
  light_3_violet: '#BEB4D3',
  light_2_violet: '#8877AB',
  light_1_violet: '#533573',
  violet: '#361558',
  dark_1_violet: '#260B42',
  dark_2_violet: '#1D0833',
  dark_3_violet: '#0F011D',

  /** 2.01.06 Accent Colors - Gold
   */
  light_4_gold: '#FBE9C6',
  light_3_gold: '#F8D38A',
  light_2_gold: '#FCC457',
  light_1_gold: '#F9AB2E',
  gold: '#F49600',
  dark_1_gold: '#E38400',
  dark_2_gold: '#CE7612',
  dark_3_gold: '#A75900',

  /** 2.01.06 Accent Colors - Turquoise
   */
  light_4_turquoise: '#C5F4F1',
  light_3_turquoise: '#9DF3ED',
  light_2_turquoise: '#6AE7DF',
  light_1_turquoise: '#28D7CB',
  turquoise: '#06C7BA',
  dark_1_turquoise: '#05B2A7',
  dark_2_turquoise: '#08A298',
  dark_3_turquoise: '#0B9189'
};
