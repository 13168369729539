import React from 'react';
import PropTypes from 'prop-types';

const MWISROnly = ({ label, styleClass, disabled, tabIndex }) => (
  <span tabIndex={tabIndex} className={`sr-only ${styleClass}`} id={label} disabled={disabled} role="span-mwisronly">
    {label}
  </span>
);

export default MWISROnly;

MWISROnly.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  styleClass: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

MWISROnly.defaultProps = {
  disabled: false,
  styleClass: '',
  tabIndex: '',
};
