import {
  eLang,
  eHS,
  eSmokingStatus,
  eSex,
  eProduct,
  eCoverageType,
  eCoverageOption,
  eDepositOptionType,
  eDepositOptionDurationType,
  eDepositOwnerResidence,
  eDepositType,
  eDividendOption,
  ePerformaxDividendOption,
  eDividendScale,
  eConceptDividendScale,
  ePremiumDuration,
  ePremiumFrequency,
  ePremiumOffset,
  eVitalityStatus,
  eReportLayoutType,
  eRider,
  eConcept,
  eSpecialQuote,
  eWithdrawalType,
  eCompanyType,
  eLoanAmountSelection,
  eDeathType,
  eCOIType,
  eInsuranceType,
  eAppStatus,
  eLifecheque,
  eCostDuration,
  ePerformanceCreditOption,
  eAdditionOptionType,
  eAdditionOptionDurationType,
  PerformaxPlanTypeKeys,
} from './configs';
import { NUMBERS } from './constants';

const jltdLdAbbr = 'covType.jltd.lastdeath.abbr';
const premiumOffsetEarliest = 'premiumoffset.earliest';
const depositOptionSpecified = 'depositoption.type.specified';

export const dropDownOptions = (intl) => {
  const covTypeSingleLabel = intl.formatMessage({ id: 'covType.single' });
  const covTypeCombinedLabel = intl.formatMessage({ id: 'covType.combined' });
  const covTypeSingleMulLabel = intl.formatMessage({ id: 'covType.singleMUL' });
  const covTypeSinglePGLabel = intl.formatMessage({ id: 'covType.singlePG' });
  const covTypeSinglePMaxLabel = intl.formatMessage({ id: 'covType.singlePMax' });
  const specialQuoteNoneLabel = intl.formatMessage({ id: 'specialQuote.opt.none' });
  const specialQuoteS1Label = intl.formatMessage({ id: 'specialQuote.opt.S1' });

  return {
    languages: languageIntl(intl),
    tabNavs: [{ index: 2, label: intl.formatMessage({ id: 'topbar.spreadsheet' }), url: 'spreadsheet' }],
    sexes: [
      { label: intl.formatMessage({ id: 'common.male' }), value: eSex.male },
      { label: intl.formatMessage({ id: 'common.female' }), value: eSex.female },
    ],
    healthstyle: healthstyleIntl(intl),
    smokingStatus: [
      { label: intl.formatMessage({ id: 'smokingstatus.nonsmoker' }), value: eSmokingStatus.nonsmoker },
      { label: intl.formatMessage({ id: 'smokingstatus.smoker' }), value: eSmokingStatus.smoker },
    ],
    products: productsIntl(intl),
    appStatus: appStatusIntl(intl),
    productOptions: productOptionsIntl(intl),
    coverageTypeOptions: coverageTypeOptionscovTypeIntl(covTypeSingleLabel, covTypeCombinedLabel, intl),
    coverageTypeOptionsMUL: coverageTypeOptionsMULcovTypeIntl(covTypeSingleMulLabel, covTypeSingleLabel, intl),
    coverageTypeOptionsPG: coverageTypeOptionsPGcovTypeIntl(covTypeSinglePGLabel, covTypeCombinedLabel, intl),
    coverageTypeOptionsPMax: coverageTypeOptionsPMaxcovTypeIntl(covTypeSinglePMaxLabel, intl),
    coverageTypeOptionsRiderMUL: coverageTypeOptionsRiderMULcovType(
      covTypeSingleMulLabel,
      covTypeSingleLabel,
      covTypeCombinedLabel
    ),
    performaxDropDowns: Object.values(PerformaxPlanTypeKeys).map((planType) => ({
      label: intl.formatMessage({ id: 'coverage.planType.' + planType }),
      value: planType,
    })),
    coverageOptions: coverageOptionsIntl(intl),
    termRiderOptions: termRiderOptionsIntl(intl),
    performaxTermRiderOptions: performaxTermRiderOptions(intl),
    performaxTermOtherRiderOptions: performaxTermOtherRiderOptions(intl),
    performaxTermSpousalRiderOptions: performaxTermSpousalRiderOptions(intl),
    // costReductions: [{ label: intl.formatMessage({id:'cost.none'}), value: 'X' }],
    specialQuoteOptionTerm: [
      { label: specialQuoteNoneLabel, value: eSpecialQuote.none },
      { label: specialQuoteS1Label, value: eSpecialQuote.S1 },
      { label: intl.formatMessage({ id: 'specialQuote.opt.H1' }), value: eSpecialQuote.H1 },
    ],
    specialQuoteOptionPAR: [
      { label: specialQuoteNoneLabel, value: eSpecialQuote.none },
      { label: specialQuoteS1Label, value: eSpecialQuote.S1 },
      { label: intl.formatMessage({ id: 'specialQuote.opt.S2' }), value: eSpecialQuote.S2 },
    ],
    withdrawalTypeOption: withdrawalTypeOptionIntl(intl),
    dividendOptions: [
      { label: intl.formatMessage({ id: 'divOption.pui' }), value: eDividendOption.pui },
      { label: intl.formatMessage({ id: 'divOption.cash' }), value: eDividendOption.cash },
    ],
    PerformaxdividendOptions: [
      { label: intl.formatMessage({ id: 'divOption.PaidUpInsurance' }), value: ePerformaxDividendOption.pui },
      { label: intl.formatMessage({ id: 'divOption.Performaxcash' }), value: ePerformaxDividendOption.cash },
      { label: intl.formatMessage({ id: 'divOption.termOption' }), value: ePerformaxDividendOption.termOption },
      { label: intl.formatMessage({ id: 'divOption.Accumulation' }), value: ePerformaxDividendOption.Accumulation },
      {
        label: intl.formatMessage({ id: 'divOption.AccumulationMaintainExistingPUI' }),
        value: ePerformaxDividendOption.AccumulationMaintainExistingPUI,
      },
      {
        label: intl.formatMessage({ id: 'divOption.AccumulationTransferPUIToAccumulation' }),
        value: ePerformaxDividendOption.AccumulationTransferPUIToAccumulation,
      },
      {
        label: intl.formatMessage({ id: 'divOption.PremiumReduction' }),
        value: ePerformaxDividendOption.PremiumReduction,
      },
    ],
    dividendScales: dividendScalesIntl(intl),
    performaxDividendScales: dividendScalesIntlPerformax(intl),
    performanceCreditOptions: [
      { label: intl.formatMessage({ id: 'pcOption.pui' }), value: ePerformanceCreditOption.pui },
      {
        label: intl.formatMessage({ id: 'pcOption.accumulatedInterest' }),
        value: ePerformanceCreditOption.accumulatedInterest,
      },
    ],

    conceptDividendScales: [
      { label: intl.formatMessage({ id: 'conceptdivscale.primary' }), value: eConceptDividendScale.primary },
      { label: intl.formatMessage({ id: 'conceptdivscale.alternate' }), value: eConceptDividendScale.alternate },
    ],

    premiumDurations: premiumDurationsPay100Intl(intl),
    costDurations: costDurationsIntl(intl),
    premiumFrequency: [
      { label: intl.formatMessage({ id: 'common.monthly' }), value: ePremiumFrequency.monthly },
      { label: intl.formatMessage({ id: 'common.annually' }), value: ePremiumFrequency.annually },
    ],
    premiumFrequencyAll: [
      { label: intl.formatMessage({ id: 'common.monthly' }), value: ePremiumFrequency.monthly },
      { label: intl.formatMessage({ id: 'common.annually' }), value: ePremiumFrequency.annually },
      { label: intl.formatMessage({ id: 'common.quarterly' }), value: ePremiumFrequency.quarterly },
      { label: intl.formatMessage({ id: 'common.semiannually' }), value: ePremiumFrequency.semiannually },
    ],
    premiumOffsets: [
      { label: intl.formatMessage({ id: 'premiumoffset.no' }), value: ePremiumOffset.no },
      { label: intl.formatMessage({ id: premiumOffsetEarliest }), value: ePremiumOffset.earliest },
      { label: intl.formatMessage({ id: 'premiumoffset.startingatyear' }), value: ePremiumOffset.startingatyear },
    ],
    premiumOffsetsInforce: [
      { label: intl.formatMessage({ id: 'premiumoffset.inforce.yes' }), value: ePremiumOffset.inforce },
      { label: intl.formatMessage({ id: 'premiumoffset.inforce.no' }), value: ePremiumOffset.no },
      { label: intl.formatMessage({ id: premiumOffsetEarliest }), value: ePremiumOffset.earliest },
      { label: intl.formatMessage({ id: 'premiumoffset.startingatyear' }), value: ePremiumOffset.startingatyear },
    ],
    premiumOffsetsPG: [
      {
        label: intl.formatMessage({ id: 'premiumoffset.inforce' }),
        value: ePremiumOffset.inforce,
      },
      { label: intl.formatMessage({ id: 'premiumoffset.sameaspolicy' }), value: ePremiumOffset.sameaspolicy },
      { label: intl.formatMessage({ id: premiumOffsetEarliest }), value: ePremiumOffset.earliest },
      { label: intl.formatMessage({ id: 'premiumoffset.paymentstartingat' }), value: ePremiumOffset.startingatyear },
    ],
    premiumOffsetsPMax: [
      {
        label: intl.formatMessage({ id: 'premiumoffset.onpremiumoffsetindicator' }),
        value: ePremiumOffset.inforce,
      },
      {
        label: intl.formatMessage({ id: 'premiumoffset.paymentcoverpolicycosts' }),
        value: ePremiumOffset.paymentcoverpolicycosts,
      },
      { label: intl.formatMessage({ id: premiumOffsetEarliest }), value: ePremiumOffset.earliest },
      { label: intl.formatMessage({ id: 'premiumoffset.premiumstartingat' }), value: ePremiumOffset.startingatyear },
      { label: intl.formatMessage({ id: 'premiumoffset.premiumholiday' }), value: ePremiumOffset.premiumholiday },
    ],
    depositOptionTypes: [
      { label: intl.formatMessage({ id: 'depositoption.type.none' }), value: eDepositOptionType.none },
      { label: intl.formatMessage({ id: depositOptionSpecified }), value: eDepositOptionType.specified },
      { label: intl.formatMessage({ id: 'depositoption.type.levelmax' }), value: eDepositOptionType.levelmax },
    ],
    depositOptionTypesParInforce: [
      { label: intl.formatMessage({ id: 'depositoption.type.none' }), value: eDepositOptionType.none },
      { label: intl.formatMessage({ id: depositOptionSpecified }), value: eDepositOptionType.specified },
    ],
    additionOptionTypes: [
      { label: intl.formatMessage({ id: depositOptionSpecified }), value: eAdditionOptionType.specified },
      { label: intl.formatMessage({ id: 'depositoption.type.levelmax' }), value: eAdditionOptionType.levelmax },
    ],
    depositOptionDurationTypes: depositOptionDurationTypesIntl(intl),
    additionOptionDurationTypes: additionOptionDurationTypesIntl(intl),

    depositOwnerResidences: depositOwnerResidencesIntl(intl),
    depositTypes: depositTypesIntl(intl),
    vitalityStatus: vitalityStatusIntl(intl),
    riders: ridersIntl(intl),
    conceptOptions: conceptOptionsIntl(intl),
    companyTypeOptions: companyTypeOptionsIntl(intl),
    loanAmountOptions: loanAmountOptionsIntl(intl),
    lcCoverageOptions: lcCoverageOptionsIntl(intl),
    lcPremiumDurations: lcPremiumDurationIntl(intl),
    lcRiderChildrensOptions: lcRiderChildrensIntl(),
    reportLayoutType: [
      { label: intl.formatMessage({ id: 'report.layout.allyears' }), value: eReportLayoutType.allyears },
      { label: intl.formatMessage({ id: 'report.layout.condensed' }), value: eReportLayoutType.condensed },
    ],
    // new death benefit types
    deathTypes: deathTypesIntl(intl),
    deathTypesForSummary: deathTypesForSummaryIntl(intl),
    // new COI types
    coiTypes: [
      { label: intl.formatMessage({ id: 'coverage.mul.coiTypes.levelToAge100' }), value: eCOIType.levelToAge100 },
      { label: intl.formatMessage({ id: 'coverage.mul.coiTypes.yrtToAge8520Year' }), value: eCOIType.yrtToAge85 },
    ],
    ivCoiTypes: [{ label: intl.formatMessage({ id: 'coverage.mul.coiTypes.yrtToAge100' }), value: eCOIType.yrt100 }],
    inforceCoiTypes: [
      { label: intl.formatMessage({ id: 'coverage.mul.coiTypes.levelToAge100' }), value: eCOIType.levelToAge100 },
      { label: intl.formatMessage({ id: 'coverage.mul.coiTypes.levelFor10Years' }), value: eCOIType.levelFor10Years },
      { label: intl.formatMessage({ id: 'coverage.mul.coiTypes.levelFor20Years' }), value: eCOIType.levelFor20Years },
      { label: intl.formatMessage({ id: 'coverage.mul.coiTypes.yrtToAge8520Year' }), value: eCOIType.yrtToAge85 },
      { label: intl.formatMessage({ id: 'coverage.mul.coiTypes.yrtToAge85' }), value: eCOIType.yrt85 },
      { label: intl.formatMessage({ id: 'coverage.mul.coiTypes.yrtToAge100' }), value: eCOIType.yrt100 },
    ],
    // Amount of insurance types
    amountOfInsuranceTypes: amountOfInsuranceTypesIntl(intl),
    // Amount of InnoVision insurance types
    amountOfInsuranceIVTypes: amountOfInsuranceTypesIVIntl(intl),
    // Special quote
    specialQuoteOptionMUL: specialQuoteOptionMULIntl(intl),
    viewCustomizeIllustrationsOptions: [
      {
        label: intl.formatMessage({ id: 'common.illustration' }),
        value: intl.formatMessage({ id: 'common.illustration' }),
      },
      { label: intl.formatMessage({ id: 'common.customize' }), value: intl.formatMessage({ id: 'common.customize' }) },
    ],
  };
};
const languageIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'common.lang.english' }), value: eLang.en },
    { label: intl.formatMessage({ id: 'common.lang.french' }), value: eLang.fr },
  ];
};
const coverageTypeOptionsBase = (intl) => {
  return [
    {
      label: intl.formatMessage({ id: 'covType.jftd' }),
      abbr: intl.formatMessage({ id: 'covType.jftd.abbr' }),
      value: eCoverageType.jointFirst,
    },
    {
      label: intl.formatMessage({ id: 'covType.jltd' }),
      abbr: intl.formatMessage({ id: 'covType.jltd.abbr' }),
      value: eCoverageType.jointLast,
    },
    {
      label: intl.formatMessage({ id: 'covType.jltd.lastdeath' }),
      abbr: intl.formatMessage({ id: jltdLdAbbr }),
      value: eCoverageType.jointLastPayLastDeath,
    },
    {
      label: intl.formatMessage({ id: 'covType.jltd.costtofirstdeath' }),
      abbr: intl.formatMessage({ id: 'covType.jltd.firstdeath.abbr' }),
      value: eCoverageType.jointLastPayFirstDeath,
    },
    {
      label: intl.formatMessage({ id: 'covType.jltd.costtolastdeath' }),
      abbr: intl.formatMessage({ id: jltdLdAbbr }),
      value: eCoverageType.jointLastPayLastDeath,
    },
  ];
};
const coverageTypeOptionscovTypeIntl = (covTypeSingleLabel, covTypeCombinedLabel, intl) => {
  return [
    {
      label: covTypeSingleLabel,
      abbr: covTypeSingleLabel,
      value: eCoverageType.single,
    },
    {
      label: covTypeCombinedLabel,
      abbr: covTypeCombinedLabel,
      value: eCoverageType.combined,
    },
    coverageTypeOptionsBase(intl)[NUMBERS.ZERO],
    coverageTypeOptionsBase(intl)[NUMBERS.ONE],
    coverageTypeOptionsBase(intl)[NUMBERS.TWO],
  ];
};

const productOptionsIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'dashboard.table.select.all' }), value: eProduct.ALL },
    { label: intl.formatMessage({ id: 'product.ft' }), value: eProduct.FT },
    { label: intl.formatMessage({ id: 'product.lc' }), value: eProduct.LC },
    { label: intl.formatMessage({ id: 'product.par' }), value: eProduct.PAR },
    { label: intl.formatMessage({ id: 'product.mul' }), value: eProduct.MUL },
    { label: intl.formatMessage({ id: 'product.pg' }), value: eProduct.PG },
    { label: intl.formatMessage({ id: 'product.gi' }), value: eProduct.GI },
  ];
};

const appStatusIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'status.started' }), value: eAppStatus.STARTED },
    { label: intl.formatMessage({ id: 'status.cleared' }), value: eAppStatus.CLEARED },
    { label: intl.formatMessage({ id: 'status.submitted' }), value: eAppStatus.SUBMITTED },
    { label: intl.formatMessage({ id: 'status.locked' }), value: eAppStatus.LOCKED },
    { label: intl.formatMessage({ id: 'status.await-client-e-sign' }), value: eAppStatus.AWAIT_CLIENT_ESIGN },
    { label: intl.formatMessage({ id: 'status.await-advisor-e-sign' }), value: eAppStatus.AWAIT_ADVISOR_ESIGN },
    { label: intl.formatMessage({ id: 'status.await-submit' }), value: eAppStatus.AWAIT_SUBMIT },
    { label: intl.formatMessage({ id: 'status.declined-by-client' }), value: eAppStatus.DECLINED_BY_CLIENT },
    { label: intl.formatMessage({ id: 'status.expired' }), value: eAppStatus.EXPIRED },
    { label: intl.formatMessage({ id: 'status.esign-link-expired' }), value: eAppStatus.ESIGN_LINK_EXPIRED },
  ];
};

const productsIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'product.ft' }), value: eProduct.FT },
    { label: intl.formatMessage({ id: 'product.ftrc' }), value: eProduct.FTRC },
    { label: intl.formatMessage({ id: 'product.lc' }), value: eProduct.LC },
    { label: intl.formatMessage({ id: 'product.par' }), value: eProduct.PAR },
    { label: intl.formatMessage({ id: 'product.mul' }), value: eProduct.MUL },
    { label: intl.formatMessage({ id: 'product.iv' }), value: eProduct.IV },
    { label: intl.formatMessage({ id: 'product.pg' }), value: eProduct.PG },
    { label: intl.formatMessage({ id: 'product.gi' }), value: eProduct.GI },
  ];
};

const healthstyleIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'healthstyle.1' }), value: eHS.hs1 },
    { label: intl.formatMessage({ id: 'healthstyle.2' }), value: eHS.hs2 },
    { label: intl.formatMessage({ id: 'healthstyle.3' }), value: eHS.hs3 },
    { label: intl.formatMessage({ id: 'healthstyle.4' }), value: eHS.hs4 },
    { label: intl.formatMessage({ id: 'healthstyle.5' }), value: eHS.hs5 },
  ];
};
const specialQuoteOptionMULIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'specialQuote.opt.none' }), value: eSpecialQuote.none },
    { label: intl.formatMessage({ id: 'specialQuote.opt.S1' }), value: eSpecialQuote.S1 },
    { label: intl.formatMessage({ id: 'specialQuote.opt.S2' }), value: eSpecialQuote.S2 },
    { label: intl.formatMessage({ id: 'specialQuote.opt.H1' }), value: eSpecialQuote.H1 },
  ];
};

const amountOfInsuranceTypesIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'coverage.mul.insurTypes.specified' }), value: eInsuranceType.specified },
    { label: intl.formatMessage({ id: 'coverage.mul.insurTypes.minLevel' }), value: eInsuranceType.minLevel },
    { label: intl.formatMessage({ id: 'coverage.mul.insurTypes.maxLevel' }), value: eInsuranceType.maxLevel },
  ];
};

const amountOfInsuranceTypesIVIntl = (intl) => {
  return [{ label: intl.formatMessage({ id: 'coverage.mul.insurTypes.specified' }), value: eInsuranceType.specified }];
};

const deathTypesForSummaryIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'coverage.mul.deathTypes.facePlusSummary' }), value: eDeathType.facePlus },
    { label: intl.formatMessage({ id: 'coverage.mul.deathTypes.levelSummary' }), value: eDeathType.level },
    {
      label: intl.formatMessage({ id: 'coverage.mul.deathTypes.accValEachDeath' }),
      value: eDeathType.accValEachDeath,
    },
  ];
};

const deathTypesIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'coverage.mul.deathTypes.facePlus' }), value: eDeathType.facePlus },
    { label: intl.formatMessage({ id: 'coverage.mul.deathTypes.level' }), value: eDeathType.level },
    {
      label: intl.formatMessage({ id: 'coverage.mul.deathTypes.accValEachDeath' }),
      value: eDeathType.accValEachDeath,
    },
  ];
};

const loanAmountOptionsIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'concept.loanAmount.specified' }), value: eLoanAmountSelection.specified },
    {
      label: intl.formatMessage({ id: 'concept.loanAmount.solveMaximum' }),
      value: eLoanAmountSelection.solveMaximum,
    },
  ];
};

const companyTypeOptionsIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'corporateIRPStructure.holdingCompany' }), value: eCompanyType.holdingCompany },
    {
      label: intl.formatMessage({ id: 'corporateIRPStructure.operatingCompany' }),
      value: eCompanyType.operatingCompany,
    },
  ];
};

const conceptOptionsIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'corporate.NONE' }), value: eConcept.NONE },
    { label: intl.formatMessage({ id: 'concept.personal.IRP' }), value: eConcept.PIRP },
    { label: intl.formatMessage({ id: 'corporate.IRP' }), value: eConcept.CIRP },
    { label: intl.formatMessage({ id: 'concept.personal.EB' }), value: eConcept.PEB },
    { label: intl.formatMessage({ id: 'corporate.EB' }), value: eConcept.CEB },
  ];
};

const ridersIntl = (intl) => {
  return [
    {
      label: intl.formatMessage({ id: 'rider.tdw' }),
      abbr: intl.formatMessage({ id: 'rider.tdw.abbr' }),
      id: eRider.tdw,
      // isAmount: false,
      // isRating: true
    },
    {
      label: intl.formatMessage({ id: 'rider.bvp' }),
      abbr: intl.formatMessage({ id: 'rider.bvp.abbr' }),
      id: eRider.bvp,
      // isAmount: true,
      // isRating: false
    },
    {
      label: intl.formatMessage({ id: 'rider.gio' }),
      abbr: intl.formatMessage({ id: 'rider.gio.abbr' }),
      id: eRider.gio,
      // isAmount: true,
      // isRating: false
    },
    {
      label: intl.formatMessage({ id: 'rider.adnd' }),
      abbr: intl.formatMessage({ id: 'rider.adnd.abbr' }),
      id: eRider.adnd,
      // isAmount: true,
      // isRating: true
    },
    {
      label: intl.formatMessage({ id: 'rider.adb' }),
      abbr: intl.formatMessage({ id: 'rider.adb.abbr' }),
      id: eRider.adb,
      // isAmount: true,
      // isRating: true
    },
    {
      label: intl.formatMessage({ id: 'rider.cpr' }),
      abbr: intl.formatMessage({ id: 'rider.cpr.abbr' }),
      id: eRider.cpr,
      // isAmount: false,
      // isRating: false
    },
    {
      label: intl.formatMessage({ id: 'rider.tdw.payor' }),
      abbr: intl.formatMessage({ id: 'rider.tdw.payor.abbr' }),
      id: eRider.tdwpayor,
    },
    {
      label: intl.formatMessage({ id: 'lc.rider.clc' }),
      abbr: intl.formatMessage({ id: 'lc.rider.clc.abbr' }),
      id: eRider.clc,
    },
    {
      label: intl.formatMessage({ id: 'lc.rider.wpd' }),
      abbr: intl.formatMessage({ id: 'lc.rider.wpd.abbr' }),
      id: eRider.wpd,
    },
    {
      label: intl.formatMessage({ id: 'lc.rider.prw' }),
      abbr: intl.formatMessage({ id: 'lc.rider.prw.abbr' }),
      id: eRider.prw,
    },
    {
      label: intl.formatMessage({ id: 'rider.pwdd' }),
      abbr: intl.formatMessage({ id: 'rider.pwdd.abbr' }),
      id: eRider.pwdd,
    },
    {
      label: intl.formatMessage({ id: 'rider.rop' }),
      abbr: intl.formatMessage({ id: 'rider.rop.abbr' }),
      id: eRider.rop,
    },
  ];
};

const vitalityStatusIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'vitality.platinum' }), value: eVitalityStatus.platinum },
    { label: intl.formatMessage({ id: 'vitality.gold' }), value: eVitalityStatus.gold },
    { label: intl.formatMessage({ id: 'vitality.silver' }), value: eVitalityStatus.silver },
    { label: intl.formatMessage({ id: 'vitality.bronze' }), value: eVitalityStatus.bronze },
  ];
};

const depositTypesIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'deposit.type.specified' }), value: eDepositType.specified },
    { label: intl.formatMessage({ id: 'deposit.type.levelmax' }), value: eDepositType.levelmax },
    { label: intl.formatMessage({ id: 'deposit.type.levelmin' }), value: eDepositType.levelmin },
    { label: intl.formatMessage({ id: 'deposit.type.yearlymin' }), value: eDepositType.yearlymin },
  ];
};

const depositOwnerResidencesIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'deposit.ownerresidence.select' }), value: eDepositOwnerResidence.select },
    { label: intl.formatMessage({ id: 'deposit.ownerresidence.alberta' }), value: eDepositOwnerResidence.alberta },
    {
      label: intl.formatMessage({ id: 'deposit.ownerresidence.britishcolumbia' }),
      value: eDepositOwnerResidence.britishColumbia,
    },
    { label: intl.formatMessage({ id: 'deposit.ownerresidence.manitoba' }), value: eDepositOwnerResidence.manitoba },
    {
      label: intl.formatMessage({ id: 'deposit.ownerresidence.newbrunswick' }),
      value: eDepositOwnerResidence.newBrunswick,
    },
    {
      label: intl.formatMessage({ id: 'deposit.ownerresidence.newfoundlandandlabrador' }),
      value: eDepositOwnerResidence.newfoundlandAndLabrador,
    },
    {
      label: intl.formatMessage({ id: 'deposit.ownerresidence.northwestterritories' }),
      value: eDepositOwnerResidence.northwestTerritories,
    },
    {
      label: intl.formatMessage({ id: 'deposit.ownerresidence.novascotia' }),
      value: eDepositOwnerResidence.novaScotia,
    },
    { label: intl.formatMessage({ id: 'deposit.ownerresidence.nunavut' }), value: eDepositOwnerResidence.nunavut },
    { label: intl.formatMessage({ id: 'deposit.ownerresidence.ontario' }), value: eDepositOwnerResidence.ontario },
    {
      label: intl.formatMessage({ id: 'deposit.ownerresidence.princeedwardisland' }),
      value: eDepositOwnerResidence.princeEdwardIsland,
    },
    { label: intl.formatMessage({ id: 'deposit.ownerresidence.quebec' }), value: eDepositOwnerResidence.quebec },
    {
      label: intl.formatMessage({ id: 'deposit.ownerresidence.saskatchewan' }),
      value: eDepositOwnerResidence.saskatchewan,
    },
    { label: intl.formatMessage({ id: 'deposit.ownerresidence.yukon' }), value: eDepositOwnerResidence.yukon },
    {
      label: intl.formatMessage({ id: 'deposit.ownerresidence.notapplicable' }),
      value: eDepositOwnerResidence.notApplicable,
    },
  ];
};

const depositOptionDurationTypesIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'depositoption.duration.years' }), value: eDepositOptionDurationType.year },
    {
      label: intl.formatMessage({ id: 'depositoption.duration.sameasbase' }),
      value: eDepositOptionDurationType.sameasbase,
    },
  ];
};
const additionOptionDurationTypesIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'depositoption.duration.years' }), value: eAdditionOptionDurationType.year },
    {
      label: intl.formatMessage({ id: 'depositoption.duration.sameasbase' }),
      value: eAdditionOptionDurationType.sameasbase,
    },
  ];
};

const dividendScalesIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'divscale.current' }), value: eDividendScale.current },
    { label: intl.formatMessage({ id: 'divscale.lesshalf' }), value: eDividendScale.lessHalf },
    { label: intl.formatMessage({ id: 'divscale.lessone' }), value: eDividendScale.lessOne },
    { label: intl.formatMessage({ id: 'divscale.lessonehalf' }), value: eDividendScale.lessOneHalf },
    { label: intl.formatMessage({ id: 'divscale.lesstwo' }), value: eDividendScale.lessTwo },
  ];
};

const dividendScalesIntlPerformax = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'divscale.current' }), value: eDividendScale.current },
    { label: intl.formatMessage({ id: 'divscale.Performax.lesshalf' }), value: eDividendScale.lessHalf },
    { label: intl.formatMessage({ id: 'divscale.Performax.lessone' }), value: eDividendScale.lessOne },
    { label: intl.formatMessage({ id: 'divscale.Performax.lessonehalf' }), value: eDividendScale.lessOneHalf },
    { label: intl.formatMessage({ id: 'divscale.Performax.lesstwo' }), value: eDividendScale.lessTwo },
    { label: intl.formatMessage({ id: 'divscale.Performax.lesstwohalf' }), value: eDividendScale.lessTwohalf },
  ];
};

const termRiderOptionsIntl = (intl) => {
  return [
    {
      label: intl.formatMessage({ id: 'rider.t10' }),
      abbr: intl.formatMessage({ id: 'rider.t10.abbr' }),
      value: eCoverageOption.t10,
    },
    {
      label: intl.formatMessage({ id: 'rider.t20' }),
      abbr: intl.formatMessage({ id: 'rider.t20.abbr' }),
      value: eCoverageOption.t20,
    },
  ];
};

const performaxTermRiderOptions = (intl) => {
  return [
    {
      label: intl.formatMessage({ id: 'rider.t05' }),
      abbr: intl.formatMessage({ id: 'pmax.rider.t05.abbr' }),
      value: eCoverageOption.t05,
    },
    {
      label: intl.formatMessage({ id: 'rider.t10' }),
      abbr: intl.formatMessage({ id: 'pmax.rider.t10.abbr' }),
      value: eCoverageOption.t10,
    },
    {
      label: intl.formatMessage({ id: 'rider.t65' }),
      abbr: intl.formatMessage({ id: 'pmax.rider.t65.abbr' }),
      value: eCoverageOption.t65,
    },
  ];
};

const performaxTermOtherRiderOptions = (intl) => {
  return [
    {
      label: intl.formatMessage({ id: 'rider.t05' }),
      abbr: intl.formatMessage({ id: 'pmax.rider.tor.t05.abbr' }),
      value: eCoverageOption.t05,
    },
    {
      label: intl.formatMessage({ id: 'rider.t10' }),
      abbr: intl.formatMessage({ id: 'pmax.rider.tor.t10.abbr' }),
      value: eCoverageOption.t10,
    },
    {
      label: intl.formatMessage({ id: 'rider.t65' }),
      abbr: intl.formatMessage({ id: 'pmax.rider.tor.t65.abbr' }),
      value: eCoverageOption.t65,
    },
  ];
};

const performaxTermSpousalRiderOptions = (intl) => {
  return [
    {
      label: intl.formatMessage({ id: 'rider.t05' }),
      abbr: intl.formatMessage({ id: 'pmax.rider.spr.t05.abbr' }),
      value: eCoverageOption.t05,
    },
    {
      label: intl.formatMessage({ id: 'rider.t10' }),
      abbr: intl.formatMessage({ id: 'pmax.rider.spr.t10.abbr' }),
      value: eCoverageOption.t10,
    },
    {
      label: intl.formatMessage({ id: 'rider.t65' }),
      abbr: intl.formatMessage({ id: 'pmax.rider.spr.t65.abbr' }),
      value: eCoverageOption.t65,
    },
  ];
};

const coverageOptionsIntl = (intl) => {
  return [
    {
      label: intl.formatMessage({ id: 'covOption.t10' }),
      abbr: intl.formatMessage({ id: 'covOption.t10.abbr' }),
      value: eCoverageOption.t10,
      coverageTypes: [eCoverageType.single, eCoverageType.combined],
    },
    {
      label: intl.formatMessage({ id: 'covOption.t20' }),
      abbr: intl.formatMessage({ id: 'covOption.t20.abbr' }),
      value: eCoverageOption.t20,
      coverageTypes: [eCoverageType.single, eCoverageType.combined],
    },
    {
      label: intl.formatMessage({ id: 'covOption.t65' }),
      abbr: intl.formatMessage({ id: 'covOption.t65.abbr' }),
      value: eCoverageOption.t65,
      coverageTypes: [eCoverageType.single, eCoverageType.combined],
    },
    {
      label: intl.formatMessage({ id: 'covOption.tlife' }),
      abbr: intl.formatMessage({ id: 'covOption.tlife.abbr' }),
      value: eCoverageOption.tlife,
      coverageTypes: [
        eCoverageType.single,
        eCoverageType.jointFirst,
        eCoverageType.jointLast,
        eCoverageType.jointLastPayFirstDeath,
        eCoverageType.jointLastPayLastDeath,
      ],
    },
  ];
};

const coverageTypeOptionsRiderMULcovType = (covTypeSingleMulLabel, covTypeSingleLabel, covTypeCombinedLabel) => {
  return [
    {
      label: covTypeSingleMulLabel,
      abbr: covTypeSingleLabel,
      value: eCoverageType.single,
    },
    {
      label: covTypeCombinedLabel,
      abbr: covTypeCombinedLabel,
      value: eCoverageType.combined,
    },
  ];
};

const coverageTypeOptionsMULcovTypeIntl = (covTypeSingleMulLabel, covTypeSingleLabel, intl) => {
  return [
    {
      label: covTypeSingleMulLabel,
      abbr: covTypeSingleLabel,
      value: eCoverageType.single,
    },
    {
      label: intl.formatMessage({ id: 'covType.jltd.costtolastdeath' }),
      abbr: intl.formatMessage({ id: jltdLdAbbr }),
      value: eCoverageType.jointLastPayLastDeath,
    },
  ];
};

const coverageTypeOptionsPGcovTypeIntl = (covTypeSinglePGLabel, covTypeCombinedLabel, intl) => {
  return [
    {
      label: covTypeSinglePGLabel,
      abbr: covTypeSinglePGLabel,
      value: eCoverageType.single,
    },
    {
      label: covTypeCombinedLabel,
      abbr: covTypeCombinedLabel,
      value: eCoverageType.combined,
    },
    coverageTypeOptionsBase(intl)[NUMBERS.ZERO],
    coverageTypeOptionsBase(intl)[NUMBERS.THREE],
    coverageTypeOptionsBase(intl)[NUMBERS.FOUR],
  ];
};

const coverageTypeOptionsPMaxcovTypeIntl = (covTypeSinglePMaxLabel, intl) => {
  return [
    {
      label: covTypeSinglePMaxLabel,
      abbr: covTypeSinglePMaxLabel,
      value: eCoverageType.single,
    },
    coverageTypeOptionsBase(intl)[NUMBERS.ZERO],
    coverageTypeOptionsBase(intl)[NUMBERS.THREE],
    coverageTypeOptionsBase(intl)[NUMBERS.FOUR],
  ];
};

const premiumDurationsPay100Intl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'premiumDuration.pay10' }), value: ePremiumDuration.pay10, amount: 10 },
    { label: intl.formatMessage({ id: 'premiumDuration.pay20' }), value: ePremiumDuration.pay20, amount: 20 },
    { label: intl.formatMessage({ id: 'premiumDuration.Pay90' }), value: ePremiumDuration.Pay90, amount: 90 },
    { label: intl.formatMessage({ id: 'premiumDuration.Pay100' }), value: ePremiumDuration.Pay100, amount: 100 },
  ];
};

const costDurationsIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'costDuration.pay100' }), value: eCostDuration.pay100 },
    { label: intl.formatMessage({ id: 'costDuration.pay15' }), value: eCostDuration.pay15 },
  ];
};

const withdrawalTypeOptionIntl = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'common.none' }), value: eWithdrawalType.none },
    { label: intl.formatMessage({ id: 'withdrawal.beforeTax' }), value: eWithdrawalType.before },
    { label: intl.formatMessage({ id: 'withdrawal.afterTax' }), value: eWithdrawalType.after },
  ];
};

const lcCoverageOptionsIntl = (intl) => {
  return [
    {
      label: intl.formatMessage({ id: 'lc.coverage.option.LifechequeRenewable10' }),
      abbr: intl.formatMessage({ id: 'lc.coverage.option.lc10.abbr' }),
      value: eLifecheque.lcCoverageOptions.lc10,
    },
    {
      label: intl.formatMessage({ id: 'lc.coverage.option.LifechequeRenewable20' }),
      abbr: intl.formatMessage({ id: 'lc.coverage.option.lc20.abbr' }),
      value: eLifecheque.lcCoverageOptions.lc20,
    },
    {
      label: intl.formatMessage({ id: 'lc.coverage.option.lc65' }),
      abbr: intl.formatMessage({ id: 'lc.coverage.option.lc65.abbr' }),
      value: eLifecheque.lcCoverageOptions.lc65,
    },
    {
      label: intl.formatMessage({ id: 'lc.coverage.option.lc75' }),
      abbr: intl.formatMessage({ id: 'lc.coverage.option.lc75.abbr' }),
      value: eLifecheque.lcCoverageOptions.lc75,
    },
    {
      label: intl.formatMessage({ id: 'lc.coverage.option.lcPermanent' }),
      abbr: intl.formatMessage({ id: 'lc.coverage.option.lcPermanent.abbr' }),
      value: eLifecheque.lcCoverageOptions.lcPermanent,
    },
  ];
};

const lcPremiumDurationIntl = (intl) => {
  return [
    {
      label: intl.formatMessage({ id: 'lc.premium.duration.lcPayToAge100' }),
      value: eLifecheque.lcPremiumDurations.lcPayToAge100,
    },
    {
      label: intl.formatMessage({ id: 'lc.premium.duration.lcPayFor15' }),
      value: eLifecheque.lcPremiumDurations.lcPayFor15,
    },
  ];
};

const lcRiderChildrensIntl = () => {
  return [
    { label: '5000', value: 5000 },
    { label: '10000', value: 10000 },
    { label: '15000', value: 15000 },
    { label: '20000', value: 20000 },
    { label: '25000', value: 25000 },
    { label: '30000', value: 30000 },
    { label: '35000', value: 35000 },
    { label: '40000', value: 40000 },
    { label: '45000', value: 45000 },
    { label: '50000', value: 50000 },
    { label: '55000', value: 55000 },
    { label: '60000', value: 60000 },
    { label: '65000', value: 65000 },
    { label: '70000', value: 70000 },
    { label: '75000', value: 75000 },
    { label: '80000', value: 80000 },
    { label: '85000', value: 85000 },
    { label: '90000', value: 90000 },
    { label: '95000', value: 95000 },
    { label: '100000', value: 100000 },
  ];
};
