import React from 'react';
import { components } from 'react-select';
import * as Cds from '@manulife/mux-cds-icons';

const SearchIconIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Cds.Search />
    </components.DropdownIndicator>
  );
};

export default SearchIconIndicator;
