import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MWIButton from '../../core/components/mwi-button';
import MWIDropdown from '../../core/components/mwi-dropdown';
import { eLocale } from '../../core/configs';
import { dropDownOptions } from '../../core/dropdown-config';
import _ from 'lodash';

export class CustomVitality extends PureComponent {
  static propTypes = {
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    locale: PropTypes.string,
    customStatuses: PropTypes.array, // saved custom statuses
    age: PropTypes.number,
    duration: PropTypes.number,

    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const statusesWithCustom = this.getStatusesWithCustom();

    this.focusedYear = 0;

    // because statuses can be cancelled, it should work with local state
    // once save is called it will be saved into the Store
    // initialize them with values in the store so that it doesn't lose the previous values
    // while routers changed
    this.state = { statuses: statusesWithCustom };

    this.filldown = this.filldown.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);

    this.customVatalityRef = React.createRef();
  }

  componentDidMount() {
    // Set focus to the content container
    //const customVatalityRef = this.customVatalityRef;
    const timeoutDuration = 100;
    setTimeout(this.custumFocus, timeoutDuration, this.customVatalityRef);
  }

  custumFocus(refParam) {
    refParam.current.focus();
  }

  // breaks down the prop statuses into one yearly array
  // make a list of statuses with saved custom statuses
  getStatusesWithCustom() {
    let newStatuses = [];
    const { age, duration, customStatuses } = this.props;

    let nextToYear = duration + 1;

    // read from the last custom status
    _.forEachRight(customStatuses, (status) => {
      const tempStatuses = [];

      for (let i = status.fromYear; i < nextToYear; i++) {
        tempStatuses.push({ year: i, age: +age + i, status: status.vitalityStatus });
      }

      newStatuses = tempStatuses.concat(newStatuses);
      if (status.fromYear < nextToYear) {
        nextToYear = status.fromYear;
      }
    });

    return newStatuses;
  }

  // retrieves the prop statuses from the yearly array
  getCustomStatuses() {
    // used to generate UDM request
    const customStatuses = [];
    let prevStatus;

    this.state.statuses.forEach((status) => {
      // empty prevStatus means it is the first status
      if (!prevStatus || status.status !== prevStatus.status) {
        customStatuses.push({ vitalityStatus: status.status, fromYear: status.year });
      }
      prevStatus = status;
    });

    return customStatuses;
  }

  // set all statuses greater than the focusedYear to the same status as the focusedYear's
  filldown() {
    if (this.focusedYear === 0) {
      return;
    }

    const focusedStatus = this.state.statuses.find((s) => s.year === this.focusedYear).status;
    const newStatuses = [...this.state.statuses];

    newStatuses.forEach((status) => {
      if (status.year > this.focusedYear) {
        status.status = focusedStatus;
      }
    });

    this.setState({ statuses: newStatuses });
  }

  save() {
    this.props.save(this.getCustomStatuses());
  }

  cancel() {
    this.props.cancel();
  }

  setFocusedYear(year) {
    this.focusedYear = year;
  }

  updateStatuses(changedStatus, changedYear) {
    const newStatus = [...this.state.statuses];
    const selectedStatus = _.find(newStatus, (status) => status.year === changedYear);

    selectedStatus.status = changedStatus;

    this.setState({ statuses: newStatus });
    this.setFocusedYear(changedYear);
  }

  render() {
    const { locale, intl } = this.props;
    const { vitalityStatus } = dropDownOptions(intl);
    return (
      <div
        className="custom-vitality-wrapper"
        role="dialog"
        aria-modal="true"
        ref={this.customVatalityRef}
        tabIndex="0"
      >
        <h2 className="wrapper-title">{intl.formatMessage({ id: 'vitality.status' })}</h2>
        <div className="custom-vitality-help" tabIndex="1">
          {intl.formatMessage({ id: 'help.customizeVitality4' })}
          <b>{intl.formatMessage({ id: 'help.customizeVitality5' })}</b>
          {intl.formatMessage({ id: 'help.customizeVitality6' })}
        </div>
        <div
          className="duration-list-wrapper"
          role="table"
          aria-rowcount={this.state.statuses.length + 1}
          aria-colcount="3"
          tabIndex="0"
        >
          <div role="rowgroup" aria-hidden="true">
            <div className="header-wrapper wrapper mwi-table" role="row">
              <div className="mwi-cell" role="columnheader">
                <span id="year">{intl.formatMessage({ id: 'vitality.year' })}</span>
              </div>
              <div className="mwi-cell" role="columnheader">
                <span id="age">{intl.formatMessage({ id: 'vitality.age' })}</span>
              </div>
              <div className="mwi-cell" role="columnheader">
                <span id="status">
                  {intl.formatMessage({ id: 'vitality.status' })}
                  <span className="mwi-tm">{locale === eLocale.en ? 'TM' : 'MC'}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="duration-list wrapper mwi-table" role="rowgroup">
            {this.state.statuses.map((row, index) => {
              return (
                <div className="mwi-row duration" key={index} onClick={() => this.setFocusedYear(row.year)} role="row">
                  <div className="mwi-cell" role="cell">
                    <input
                      type="text"
                      value={row.year}
                      className="mwi-widget mwi-input mwi-w100"
                      aria-labelledby="year"
                      readOnly
                    />
                  </div>
                  <div className="mwi-cell" role="cell">
                    <input
                      type="text"
                      value={row.age}
                      className="mwi-widget mwi-input mwi-w100"
                      aria-labelledby="age"
                      readOnly
                    />
                  </div>
                  <div className="mwi-cell" role="cell">
                    <MWIDropdown
                      label=""
                      name="vitalityStatus"
                      value={row.status}
                      options={vitalityStatus}
                      locale={this.props.locale}
                      onChange={(e) => this.updateStatuses(e.target.value, row.year)}
                      containerStyle="status-dropdown"
                      isTM
                      aria-labelledby="status"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="footer-wrapper wrapper mwi-table">
          <div className="mwi-cell">
            <MWIButton
              label={intl.formatMessage({ id: 'common.filldown' })}
              onClick={this.filldown}
              styleClass={`${locale} mwi-white-button filldown-btn`}
            />
          </div>
          <div className="mwi-cell">
            <MWIButton
              label={intl.formatMessage({ id: 'common.cancel' })}
              onClick={this.cancel}
              styleClass="mwi-w30 mwi-white-button  cancel-btn"
            />
            <MWIButton
              label={intl.formatMessage({ id: 'common.save' })}
              onClick={this.save}
              styleClass="mwi-w30 save-button"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(CustomVitality);
