import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import MWIDropdown from '../../../core/components/mwi-dropdown';
import MWIAccordion from '../../../core/components/mwi-accordion-stateless';
import { dropDownOptions } from '../../dropdown-config';
import MWIButton from '../../../core/components/mwi-button';
import NumberFormat from 'react-number-format';
import { handleFocus, formatNumberValue, isRated, getPolicyYear, isDeceased } from '../../utils';
import MWINumberField from '../mwi-calculated-number-field';
import {
  eField,
  eLocale,
  eDepositOptionType,
  eDepositOptionDurationType,
  ePremiumFrequency,
  configs,
  ePremiumOffset,
  eSpreadsheetFields,
  eAdditionOptionType,
  eProduct,
  eDefault,
  eCostDuration,
  eDividendOption,
  ePerformaxDividendOption,
  PerformaxPlanTypeKeys,
  eGrandfatheredStatusType,
} from '../../../core/configs';
import './deposit-option.css';
import HelpQuestionMark from '../help-question-mark';
import moment from 'moment';

export class DepositOption extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    product: PropTypes.string,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    depositOptionType: PropTypes.string,
    additionOptionType: PropTypes.string,
    depositOptionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    depositOptionDurationType: PropTypes.string,
    additionOptionDurationType: PropTypes.string,
    depositOptionDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isDepositOptionFolded: PropTypes.bool,
    includeMaxLevelDepositOption: PropTypes.bool,
    lastDepositYear: PropTypes.number,
    loanBalance: PropTypes.number,
    grandfatheredStatus: PropTypes.number,
    maximumAdditionalDepositAmt: PropTypes.number,
    premiumFrequency: PropTypes.string,
    premiumOffset: PropTypes.string,
    duration: PropTypes.number,
    coverage: PropTypes.object,
    isDepositOptionCustom: PropTypes.bool,
    history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    effectiveDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    additionalDeposit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isAdditionalDepositSelected: PropTypes.bool,
    deposit: PropTypes.object,
    activeScenarioTabId: PropTypes.string,
    ratings: PropTypes.object,
    clients: PropTypes.object,
    client1: PropTypes.object,
    solveDuration: PropTypes.number,
    inforceDataCoverage: PropTypes.object,
    inforce: PropTypes.bool,
    dividendOption: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
    this.customClick = this.customClick.bind(this);
  }

  dispatchAction(field, value, target = 'scenario', isError = false, refresh = true) {
    const { appActions, sceActions } = this.props;

    appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      scenarioTabId: this.props.activeScenarioTabId,
      field,
      value,
    });
    if (refresh) {
      sceActions.toggleOutOfDate(true);
    }
    if (
      this.props.product === eProduct.PAR ||
      ([eProduct.PG, eProduct.Performax, eProduct.SB].includes(this.props.product) && refresh)
    ) {
      this.props.sceActions.performManualRefresh(true);
    }
  }

  onDepositOptionChange(field, value) {
    this.dispatchAction(eField.isDepositOptionCustom, false, 'scenario', false, true);
    this.dispatchAction(field, value);
  }

  toggleFolded(folded) {
    this.dispatchAction(eField.isDepositOptionFolded, folded, 'scenario', false, false);
  }

  isDoi() {
    const { inforceDataCoverage } = this.props;

    if (inforceDataCoverage) {
      const doiCoverage = inforceDataCoverage.find((cov) => cov.product === 'DOI');
      return doiCoverage ? true : false;
    }

    return false;
  }

  getMaxDepositDuration() {
    const { duration, product, effectiveDate, coverage, depositOptionDurationType } = this.props;

    if (product === eProduct.PG) {
      const policyYear = getPolicyYear(moment(effectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
      if (
        coverage.costDuration === eCostDuration.pay15 &&
        this.isDoi() &&
        depositOptionDurationType === eDepositOptionDurationType.year
      ) {
        const doiPay15Duration = 15;
        const retVal = doiPay15Duration - policyYear;
        return retVal < 0 ? 1 : retVal;
      } else {
        const maxValueInforce = 99;
        return maxValueInforce;
      }
    } else {
      return duration;
    }
  }

  onBlur(type, value) {
    const maxDepositDuration = this.getMaxDepositDuration();
    if (type === eField.depositOptionDuration && value > maxDepositDuration) {
      this.dispatchAction(type, maxDepositDuration);
    } else if (type === eField.depositAdditionalDeposit) {
      this.dispatchAction(type, value);
      this.dispatchAction(eField.depositAdditionalDeposit, value, 'deposit');
    } else {
      this.dispatchAction(type, value);
    }
  }

  customClick() {
    const spreadsheetIndex = 2;
    this.dispatchAction('activeTopBarIndex', spreadsheetIndex);
    this.props.history.push(configs.routes.spreadsheet, {
      isCustom: true,
      customField: eSpreadsheetFields.illustratedDepositPayment,
    });
  }

  isPMaxOrPG(product) {
    return product === eProduct.PG || product === eProduct.Performax;
  }
  isPMaxOrSBOrPG(product) {
    return [eProduct.PG, eProduct.Performax, eProduct.SB].includes(product);
  }
  isPMaxOrSB(product) {
    return [eProduct.Performax, eProduct.SB].includes(product);
  }

  renderDepositOptionType(additionOptionTypes) {
    const { additionOptionType, product } = this.props;

    this.dispatchAction(eField.isDepositOptionCustom, false, 'scenario', false, false);
    if (product === eProduct.PG) {
      this.dispatchAction(eField.additionOptionType, eAdditionOptionType.specified, 'scenario', false, false);
    }

    return (
      <div className="mwi-margin-top24 flexdisplayCenter" role="dropdown-test">
        {product === eProduct.PG && (
          <MWIDropdown
            name="additionOptionType"
            label={<FormattedMessage id="common.type" />}
            value={additionOptionType}
            options={additionOptionTypes}
            onChange={(e) => {
              this.dispatchAction(eField.isDepositOptionCustom, false, 'scenario', false, false);
              this.dispatchAction(eField.additionOptionType, e.target.value);
            }}
            disabled={product === eProduct.PG}
            containerStyle="p-col-4 p-lg-5 p-xl-4 p-sm-6 space-right "
            dropdownStyle="mwi-w100"
          />
        )}
      </div>
    );
  }

  shouldParInforceDepositOptionDisable() {
    const { inforce, product, premiumOffset, dividendOption } = this.props;

    const isInforceOnOffset = premiumOffset === ePremiumOffset.inforce;
    const isCashDividend = dividendOption === eDividendOption.cash;
    const isParInforce = product === eProduct.PAR && inforce;

    return isParInforce && (isInforceOnOffset || isCashDividend);
  }

  renderDepositOptionTypeDO(depositOptionTypes) {
    const {
      intl,
      product,
      depositOptionType,
      depositOptionDurationType,
      includeMaxLevelDepositOption,
      coverage: { isCoverageSolve },
      premiumOffset,
    } = this.props;

    let depositOptionValue = '';
    if ([eProduct.Performax, eProduct.SB].includes(product)) {
      depositOptionValue = eDepositOptionType.specified;
      this.dispatchAction(eField.additionOptionType, eAdditionOptionType.specified, 'scenario', false, false);
    } else {
      const isInforceOnOffset = premiumOffset === ePremiumOffset.inforce;
      if (isInforceOnOffset) {
        depositOptionValue = eDepositOptionType.none;
        if (depositOptionType !== eDepositOptionType.none) {
          this.dispatchAction(eField.isDepositOptionCustom, false, 'scenario', false, false);
          this.dispatchAction(eField.depositOptionType, depositOptionValue, 'scenario', false, false);
        }
      } else if (isCoverageSolve && includeMaxLevelDepositOption) {
        depositOptionValue = eDepositOptionType.specified;
      } else {
        depositOptionValue = depositOptionType;
      }
    }

    return (
      <div className="mwi-margin-top24 flexdisplayCenter" role="dropdown-test">
        {product !== eProduct.PG && (
          <MWIDropdown
            name="depositOptionType"
            label={<FormattedMessage id="common.type" />}
            value={depositOptionValue}
            options={depositOptionTypes}
            onChange={(e) => {
              this.dispatchAction(eField.isDepositOptionCustom, false, 'scenario', false, false);
              this.dispatchAction(eField.depositOptionType, e.target.value);
            }}
            disabled={isCoverageSolve || this.isPMaxOrSB(product) || this.shouldParInforceDepositOptionDisable()}
            containerStyle="p-col-4 p-lg-5 p-xl-4 p-sm-6 space-right "
            dropdownStyle="mwi-w100"
          />
        )}
        {!this.isPMaxOrPG(product) && depositOptionType === eDepositOptionType.levelmax && (
          <HelpQuestionMark
            containerClass="help deposit-option-help"
            overlayId="summaryTip"
            overlayContent={() => <FormattedMessage id={'help.levelmax'} />}
          />
        )}
        {!this.isPMaxOrSBOrPG(product) &&
          depositOptionDurationType === eDepositOptionDurationType.year &&
          depositOptionType === eDepositOptionType.specified && (
            <MWIButton
              label={intl.formatMessage({ id: 'common.customize' })}
              onClick={this.customClick}
              disabled={isCoverageSolve && includeMaxLevelDepositOption}
              styleClass="align p-col-2 p-lg-2 p-md-2 p-sm-3"
            />
          )}
      </div>
    );
  }

  renderPremiumFreqLabel(premiumFrequency) {
    switch (premiumFrequency) {
      case ePremiumFrequency.annually:
        return <FormattedMessage id="depositoption.amount.annually" />;
      case ePremiumFrequency.quarterly:
        return <FormattedMessage id="depositoption.amount.quarterly" />;
      case ePremiumFrequency.semiannually:
        return <FormattedMessage id="depositoption.amount.semi-annually" />;
      default:
        return <FormattedMessage id="depositoption.amount.monthly" />;
    }
  }

  isPMaxRated = (product, rating1) =>
    this.isPMaxOrSB(product) && isRated(rating1.permRatingAmount, rating1.permRatingPercent, rating1.tempRatingAmount);

  performaxAdditionalPaymentsDisabled = (
    product,
    client1Ratings,
    dividendOption,
    costDuration,
    loanBalance,
    lastDepositYear,
    clients,
    grandfatheredStatus
  ) => {
    const disabled = true;

    const currentYear = new Date().getFullYear();
    const maximumYearGap = 5;

    if (
      (dividendOption !== ePerformaxDividendOption.termOption || (this.props.coverage.toFaceAmountRaw ?? 0) <= 0) &&
      currentYear - lastDepositYear > maximumYearGap
    ) {
      return disabled;
    }

    const isGrandfathered = grandfatheredStatus && grandfatheredStatus !== eGrandfatheredStatusType.none;
    if (
      isGrandfathered &&
      ![PerformaxPlanTypeKeys.Performax88, PerformaxPlanTypeKeys.Performax90].includes(costDuration)
    ) {
      return disabled;
    }

    if (loanBalance > 0) {
      return disabled;
    }

    if (isDeceased(clients)) {
      return disabled;
    }

    if (this.isPMaxRated(product, client1Ratings)) {
      return disabled;
    }

    // If they have not met any of the above conditions, then enable the additional payments.
    return false;
  };

  renderDepositAmount() {
    const {
      intl,
      locale,
      depositOptionAmount,
      isDepositOptionCustom,
      includeMaxLevelDepositOption,
      loanBalance,
      lastDepositYear,
      grandfatheredStatus,
      premiumFrequency,
      coverage: { dividendOption, costDuration, ratings, isCoverageSolve },
      premiumOffset,
      product,
      clients,
      client1,
    } = this.props;
    const maxDepositAmount = 99999999;

    let additionalPaymentsDisabled =
      (isCoverageSolve && includeMaxLevelDepositOption) || premiumOffset === ePremiumOffset.inforce;

    // If it's a Performax product, and the additional payments are not disabled, then check if they should be disabled.
    if (this.isPMaxOrSB(product) && !additionalPaymentsDisabled) {
      const client1Ratings = ratings[client1.id];
      additionalPaymentsDisabled = this.performaxAdditionalPaymentsDisabled(
        product,
        client1Ratings,
        dividendOption,
        costDuration,
        loanBalance,
        lastDepositYear,
        clients,
        grandfatheredStatus
      );
    }

    return (
      <div className={`p-col-3 p-lg-4 p-xl-3 p-sm-6 mwi-control-wrapper space-right `}>
        <div className="mwi-label" id="depositOptionAmount" data-testid="container-specifieddepositamount">
          {<span>{this.renderPremiumFreqLabel(premiumFrequency)}</span>}
        </div>
        <div className="ui-input" role="ui-input-test">
          <NumberFormat
            className="mwi-widget mwi-input number-data"
            value={!isDepositOptionCustom ? +depositOptionAmount : ''}
            decimalScale={2}
            fixedDecimalScale
            placeholder={isDepositOptionCustom ? intl.formatMessage({ id: 'depositoption.custom' }) : undefined}
            prefix={locale === eLocale.en ? '$ ' : ''}
            suffix={locale === eLocale.fr ? ' $' : ''}
            thousandSeparator={locale === eLocale.en ? ',' : ' '}
            decimalSeparator={locale === eLocale.en ? '.' : ','}
            allowNegative={false}
            isAllowed={(values) => +values.value >= 0 && +values.value <= maxDepositAmount}
            onBlur={(e) =>
              this.onDepositOptionChange(eField.depositOptionAmount, formatNumberValue(e.target.value, locale))
            }
            aria-labelledby="depositOptionAmount"
            aria-required="true"
            autoComplete="off"
            disabled={additionalPaymentsDisabled}
            onFocus={handleFocus}
          />
        </div>
      </div>
    );
  }

  updateDepositOptionDropdown = (product, premiumOffset, depositOptionDurationTypes) => {
    if (this.isPMaxOrSB(product)) {
      if (premiumOffset === ePremiumOffset.earliest) {
        const indexToremoveSameasbase = depositOptionDurationTypes.findIndex(
          (x) => x.value === eDepositOptionDurationType.sameasbase
        );
        depositOptionDurationTypes.splice(indexToremoveSameasbase, 1);

        this.dispatchAction(
          eField.depositOptionDurationType,
          eDepositOptionDurationType.year,
          eField.scenario,
          false,
          false
        );
      }

      if (premiumOffset === ePremiumOffset.premiumholiday) {
        const indexToremoveYears = depositOptionDurationTypes.findIndex(
          (x) => x.value === eDepositOptionDurationType.year
        );
        depositOptionDurationTypes.splice(indexToremoveYears, 1);

        this.dispatchAction(
          eField.depositOptionDurationType,
          eDepositOptionDurationType.sameasbase,
          eField.scenario,
          false,
          false
        );
      }
    }
  };

  renderDepositDuration(depositOptionDurationTypes) {
    const {
      locale,
      depositOptionDuration,
      depositOptionDurationType,
      includeMaxLevelDepositOption,
      premiumOffset,
      coverage: { isCoverageSolve },
      product,
    } = this.props;

    const maxDepositDurationInforce = 99;
    let maxDepositDuration = 100;
    const minDepositDuration = eDefault.depositOptionDurationInforce;
    const pmaxEarliestOffset = this.isPMaxOrSB(product) && premiumOffset === ePremiumOffset.earliest;
    if (this.isPMaxOrSBOrPG(product)) {
      maxDepositDuration = maxDepositDurationInforce;
    }

    this.updateDepositOptionDropdown(product, premiumOffset, depositOptionDurationTypes);

    const handleDurationChange = (value) => {
      if (+value < minDepositDuration) {
        value = minDepositDuration;
      }
      if (+value > maxDepositDuration) {
        value = maxDepositDuration;
      }
      this.onBlur(eField.depositOptionDuration, value);
      this.forceUpdate();
    };

    if (premiumOffset === ePremiumOffset.inforce) {
      this.dispatchAction(eField.depositOptionAmount, 0, 'scenario', false, false);
    }

    return (
      <React.Fragment>
        <MWIDropdown
          name="depositOptionDurationType"
          label={<FormattedMessage id="depositoption.duration" />}
          value={depositOptionDurationType}
          options={depositOptionDurationTypes}
          disabled={(isCoverageSolve && includeMaxLevelDepositOption) || premiumOffset === ePremiumOffset.earliest}
          topBarFieldStyle={'ui-input'}
          onChange={(e) => this.dispatchAction(eField.depositOptionDurationType, e.target.value)}
          containerStyle={`space-right ${
            locale === eLocale.fr ? 'p-col-4 p-lg-5 p-xl-4 p-sm-4' : 'p-col-3 p-lg-4 p-xl-3 p-sm-4'
          }`}
        />
        {!pmaxEarliestOffset && depositOptionDurationType === eDepositOptionDurationType.year && (
          <div className={`p-col-4 p-lg-3 p-xl-2 deposit-option-duration-wrapper`}>
            <div className="mwi-label">
              <span>
                <FormattedMessage id="depositoption.duration.years" />
              </span>
            </div>
            <div className="p-col-6 p-lg-7 p-xl-6 ui-input" data-testid="container-depositdurationyears">
              <NumberFormat
                className="mwi-widget mwi-input number-data mwi-w100"
                value={+depositOptionDuration}
                decimalScale={0}
                allowNegative={false}
                isAllowed={(values) => +values.value <= maxDepositDuration}
                onBlur={(e) => handleDurationChange(e.target.value)}
                aria-labelledby="depositOptionDuration"
                aria-required="true"
                autoComplete="off"
                disabled={isCoverageSolve && includeMaxLevelDepositOption}
                onFocus={handleFocus}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderAdditionalAmountCheckbox() {
    const { deposit, locale, premiumOffset, product, inforce } = this.props;
    const isParInforce = inforce && product === eProduct.PAR;
    const isInforceOnOffset = premiumOffset === ePremiumOffset.inforce;
    const isAdditionalDepositsApplied =
      deposit.isAdditionalDepositSelected || deposit[eField.depositAdditionalDeposit] > 0;

    if (isInforceOnOffset && isAdditionalDepositsApplied) {
      this.dispatchAction(eField.isAdditionalDepositSelected, false, 'deposit', false, false);
      this.dispatchAction(eField.depositAdditionalDeposit, 0, 'deposit');
    }

    return (
      <div id="additionalDeposit" className={`p-col-12 ${!isParInforce && 'mwi-margin-bottom24'} flexdisplay`}>
        <div>
          <input
            className="rider-checkbox focusOutline"
            type="checkbox"
            id={eField.depositAdditionalDeposit}
            value={deposit.isAdditionalDepositSelected}
            onChange={() => {
              this.dispatchAction(
                eField.isAdditionalDepositSelected,
                !deposit.isAdditionalDepositSelected,
                'deposit',
                false,
                false
              );
              this.dispatchAction(eField.depositAdditionalDeposit, 0, 'deposit');
            }}
            checked={deposit.isAdditionalDepositSelected}
            name={'isAdditionalDepositSelected'}
            aria-label={'additionalDepositCheckbox'}
            disabled={isParInforce ? this.shouldParInforceDepositOptionDisable() : isInforceOnOffset}
            aria-describedby="ridersHeader"
          />

          <span className="mwi-padding-left6">
            <FormattedMessage
              id={isParInforce ? 'deposit.additional.deposit.inforce.par' : 'deposit.additional.deposit.inforce.PG'}
            />
          </span>
        </div>
        {this.renderAdditionalAmount(locale)}
      </div>
    );
  }

  renderAdditionalAmount(locale) {
    const { deposit } = this.props;
    const containerClass =
      'mwi-input number-data p-col-4 p-lg-5 p-xl-4 p-sm-6 space-right mwi-margin-left24 mwi-margin-top-6';
    return (
      deposit[eField.isAdditionalDepositSelected] && (
        <div className="p-col-2 p-lg-4 p-xl-3 p-sm-6 space-right">
          <div className="p-col-12 flexdisplay" id="additionalDepositAmount" role="additionalDepositAmount">
            <MWINumberField
              id={eField.depositAdditionalDeposit}
              styleClass="p-col-12"
              containerClass={containerClass}
              onBlur={this.onBlur}
              value={deposit[eField.depositAdditionalDeposit]}
              decimalScale={2}
              prefix={locale === eLocale.en ? '$ ' : ''}
              suffix={locale === eLocale.fr ? ' $' : ''}
              thousandSeparator={locale === eLocale.en ? ',' : ' '}
              minValue={0.0}
              maxValue={99999999.0}
              decimalSeparator={locale === eLocale.en ? '.' : ','}
              fixedDecimalScale
              locale={locale}
            />
          </div>
        </div>
      )
    );
  }

  render() {
    const {
      intl,
      additionOptionType,
      product,
      depositOptionType,
      isDepositOptionFolded,
      isDepositOptionCustom,
      includeMaxLevelDepositOption,
      coverage: { isCoverageSolve },
      inforce,
    } = this.props;
    const {
      additionOptionTypes,
      additionOptionDurationTypes,
      depositOptionTypes,
      depositOptionDurationTypes,
      depositOptionTypesParInforce,
    } = dropDownOptions(intl);
    const isParInforce = inforce && product === eProduct.PAR;
    return (
      <div className="p-col-12 deposit-option-content" data-testid="deposit-option-wrapper-test">
        <MWIAccordion
          className={`mwi-reverse-margin15 first-child mwi-lightGreen`}
          title={
            this.isPMaxOrSBOrPG(product) ? (
              <FormattedMessage id="additionalpayments.label" />
            ) : (
              <FormattedMessage id="depositoption.label" />
            )
          }
          folded={isDepositOptionFolded}
          onClickChange={() => this.toggleFolded(!isDepositOptionFolded)}
        >
          {product === eProduct.PG && (
            <>
              <div className={`p-col-12`}>{this.renderDepositOptionType(additionOptionTypes)}</div>
              <div className="p-col-12 deposit-option-wrapper flexdisplay">
                {additionOptionType === eAdditionOptionType.specified && this.renderDepositAmount()}
                {this.renderDepositDuration(additionOptionDurationTypes)}
              </div>
              {this.renderAdditionalAmountCheckbox()}
            </>
          )}
          <div className={`p-col-12 ${depositOptionType === eDepositOptionType.none ? 'mwi-margin-bottom24' : ''}`}>
            {this.renderDepositOptionTypeDO(isParInforce ? depositOptionTypesParInforce : depositOptionTypes)}
          </div>
          {depositOptionType !== eDepositOptionType.none && product !== eProduct.PG && (
            <div className="p-col-12 deposit-option-wrapper flexdisplay">
              {depositOptionType === eDepositOptionType.specified &&
                this.renderDepositAmount(isCoverageSolve && includeMaxLevelDepositOption)}
              {!(isDepositOptionCustom && depositOptionType === eDepositOptionType.specified) &&
                this.renderDepositDuration(depositOptionDurationTypes)}
            </div>
          )}
          {isParInforce && this.renderAdditionalAmountCheckbox()}
        </MWIAccordion>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, udmResponse, scenarioTabNavs, coverageTabNavs, deposits, clients }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const activeScenarioTabId = scenarioTabNavs.activeTabId;
  const illustration = udmResponse.illustration;
  const udmPolicy = (illustration && illustration.policy) || {};
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const deposit = deposits[activeScenarioTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const clientIdsInCov = Object.keys(coverage.ratings);
  const scenarioTabId = scenarioTabNavs.activeTabId;

  return {
    locale: app.locale,
    depositOptionType: activeScenario.depositOptionType,
    additionOptionType: activeScenario.additionOptionType,
    depositOptionAmount: activeScenario.depositOptionAmount,
    isDepositOptionCustom: activeScenario.isDepositOptionCustom,
    includeMaxLevelDepositOption: activeScenario.includeMaxLevelDepositOption,
    depositOptionDurationType: activeScenario.depositOptionDurationType,
    additionOptionDurationType: activeScenario.additionOptionDurationType,
    isDepositOptionFolded: activeScenario.isDepositOptionFolded,
    depositOptionDuration: activeScenario.depositOptionDuration,
    additionOptionDuration: activeScenario.additionOptionDuration,
    premiumFrequency: activeScenario.premiumFrequency,
    premiumOffset: activeScenario.premiumOffset,
    effectiveDate: activeScenario.effectiveDate,
    product: activeScenario.product,
    coverage: coverageState[coverageState.activeTabId],
    duration: udmPolicy.duration,
    solveDuration: udmPolicy.depositOption?.solveDuration,
    additionalDeposit: activeScenario.additionalDeposit,
    maximumAdditionalDepositAmt: activeScenario.maximumAdditionalDepositAmt,
    loanBalance: activeScenario.loanBalance,
    lastDepositYear: activeScenario.lastDepositYear,
    grandfatheredStatus: activeScenario.grandfatheredStatus,
    isAdditionalDepositSelected: activeScenario.isAdditionalDepositSelected,
    deposit,
    activeScenarioTabId,
    clients: clients[scenarioTabId],
    client1: clients[scenarioTabId][clientIdsInCov[0]],
    inforceDataCoverage: app.inforceData?.policy?.coverage,
    inforce: app.inforcePolicy,
    dividendOption: coverage.dividendOption,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(DepositOption)));
