import * as actionTypes from '../../core/actionTypes';
import {
  eConcept,
  eDefault,
  eDepositOptionDurationType,
  eDepositOptionType,
  eField,
  eMaxVal,
  ePremiumDuration,
  ePremiumFrequency,
  ePremiumOffset,
  eProduct,
  newIllustrationAutoSaved,
  eUserType,
  ePremiumOffsetMinYear,
} from '../../core/configs';
import { assignDef, getPolicyYear } from '../../core/utils';
import _ from 'lodash';
import moment from 'moment';
import { scenarioSeq } from '../../core/sequenceUtils';
import { createCase, deleteSavedCases, updateCase } from '../../core/udm-mapper/sideEffect';

const firstScenarioId = scenarioSeq();

const defaultTopBar = {
  [eField.scenarioName]: '',
  illustrationId: '',
  activeTopBarIndex: 1,
  product: eProduct.PAR,
  isRiderOpened: false,
  isTermRiderFolded: false,
  isTermOtherRiderFolded: false,
  isSpousalRiderFolded: false,
  isCoverageSummaryFolded: false,
  isRiderSummaryFolded: false,
  isIllustrationRatesFolded: false,
  isAdditionalCostsFolded: false,
  productValidationError: false,
  premiumFrequency: eDefault.premiumFrequency,
  effectiveDate: moment().format('DD/MM/YYYY'),
  primaryDividendScale: eDefault.primaryDividendScale,
  alternateDividendScale: eDefault.alternateDividendScale,
  premiumOffset: eDefault.premiumOffset,
  premiumOffsetYear: eDefault.premiumOffsetYear,
  premiumOffsetYearFrom: eDefault.premiumOffsetYearFrom,
  premiumOffsetYearTo: eDefault.premiumOffsetYearTo,
  premiumOffsetAltYear: eDefault.premiumOffsetAltYear,
  maximumAdditionalDepositAmt: eDefault.maximumAdditionalDepositAmt,
  loanBalance: eDefault.loanBalance,
  lastDepositYear: eDefault.lastDepositYear,
  grandfatheredStatus: eDefault.grandfatheredStatus,
  depositOptionType: eDefault.depositOptionType,
  additionOptionType: eDefault.additionOptionType,
  isDepositOptionFolded: false,
  isPremiumOffsetFolded: false,
  includeMaxLevelDepositOption: false,
  depositOptionAmount: eDefault.depositOptionAmount,
  isDepositOptionCustom: false,
  // we store a cache of changed values for DO, to show
  // user entered values
  depositOptionPaymentCache: {},
  depositOptionDurationType: eDefault.depositOptionDurationType,
  depositOptionDuration: eDefault.depositOptionDuration,
  marginalTaxRate: eDefault.marginalTaxRate,
  policyInterestRate: eDefault.policyInterestRate,
  performanceCreditRate: eDefault.performanceCreditRate,
  altPerformanceCreditRate: eDefault.altPerformanceCreditRate,
  sideAccountInterestRate: eDefault.sideAccountInterestRate,
  errors: {},
  activeTabViewTab: 0, // active TabViewTab
  concept: eConcept.NONE,
  // view mode for an active scenario
  outOfPocketDuration: 0,
};

const initialState = {
  activeTabId: firstScenarioId,
  isOutOfDate: false,
  udmEnabled: false,
  manualRefresh: true,
  isForceUpdate: false,
  isSpreadsheetView: false,
  tabNavs: [{ id: firstScenarioId }],
  topBars: { [firstScenarioId]: { ...defaultTopBar } },
};

const performManualRefresh = (topBar, manualRefresh) => {
  if (
    topBar.premiumOffset !== ePremiumOffset.no ||
    (topBar.depositOptionAmount > 0 && topBar.depositOptionType === eDepositOptionType.specified) ||
    topBar.depositOptionType === eDepositOptionType.levelmax
  ) {
    manualRefresh = true;
  }
  return manualRefresh;
};

const getStartYear = (premiumOffset, topBar, userType) => {
  let premiumOffSetYear = (premiumOffset && premiumOffset.startYear) || topBar.premiumOffsetYear;
  let minYear = 1;

  if (topBar.product === eProduct.PAR) {
    minYear =
      userType === eUserType.advisor
        ? ePremiumOffsetMinYear.advisorStartingAtMinYear
        : ePremiumOffsetMinYear.headOfficeStartingAtMinYear;
    if (
      (premiumOffSetYear < minYear && premiumOffset && premiumOffset.type !== ePremiumOffset.earliest) ||
      (premiumOffset && premiumOffset.type === ePremiumOffset.startingatyear)
    ) {
      premiumOffSetYear = minYear;
    }
  }

  return premiumOffset && premiumOffset.type === ePremiumOffset.startingatyear
    ? topBar.premiumOffsetYear
    : premiumOffSetYear;
};

const checkForPremiumDuration = (value, newTopBars, field, isCoverageSolve, state) => {
  const maxPremiumDuration = eMaxVal()[value].duration;
  const includeMaxLevelDepositOption = state.topBars[state.activeTabId].includeMaxLevelDepositOption;

  if (newTopBars.premiumOffsetYear > maxPremiumDuration) {
    newTopBars.premiumOffsetYear = maxPremiumDuration;
  }
  if (field === eField.premiumDuration && isCoverageSolve === true) {
    return {
      ...state,
      topBars: {
        ...state.topBars,
        [state.activeTabId]: {
          ...newTopBars,
          depositOptionType:
            value !== ePremiumDuration.pay10 && includeMaxLevelDepositOption
              ? eDepositOptionType.specified
              : eDepositOptionType.none,
          isDepositOptionCustom: false,
          depositOptionDurationType: eDepositOptionDurationType.sameasbase,
        },
      },
    };
  }
  return {
    ...state,
    topBars: {
      ...state.topBars,
      [state.activeTabId]: newTopBars,
    },
  };
};

const getDepositOptionAmount = (illustration) => {
  return illustration?.policy?.depositOption?.modalAmount > 0 ? illustration.policy.depositOption.modalAmount : 0;
};

const getPolicyDuration = (illustration, depositOptionDuration) => {
  const policy = illustration.policy;
  if (policy && ![eProduct.PG, eProduct.Performax, eProduct.SB].includes(policy.product)) {
    const policyDuration = policy.duration;
    if (policyDuration && depositOptionDuration > policyDuration) {
      depositOptionDuration = policyDuration;
    }
  }

  return depositOptionDuration;
};

const getAltStartYear = (premiumOffset, topBar) => {
  if (premiumOffset && premiumOffset.type === ePremiumOffset.startingatyear) {
    return premiumOffset?.alternateStartYear || topBar.premiumOffsetYear;
  } else {
    return (premiumOffset && premiumOffset.alternateStartYear) || topBar.premiumOffsetAltYear;
  }
};

const getOutOfPocketDuration = (illustration, startYear) => {
  return [eProduct.PG, eProduct.Performax, eProduct.SB].includes(illustration.policy?.product)
    ? illustration.policy.outOfPocketDuration ?? 0
    : startYear;
};

const setLoadedUdmResponseVariables = (action, state) => {
  const { isLoading, response } = action.payload;
  const topBar = state.topBars[state.activeTabId];
  const illustration = (response && response.illustration) || {};
  const illustrationId = illustration.illustrationId || '';
  const responseError = !isLoading && response.status === 'Error';
  const userType = illustration.user && illustration.user.type;
  const premiumOffset = illustration.policy && illustration.policy.premiumOffset;
  // if premiumOffsetType is starting at year we don't get response, assign user input
  const startYear = getStartYear(premiumOffset, topBar, userType);
  // keep default value for input
  const altStartYear = getAltStartYear(premiumOffset, topBar);
  let depositOptionDuration = topBar.depositOptionDuration;
  depositOptionDuration = getPolicyDuration(illustration, depositOptionDuration);
  const depositOptionAmount = getDepositOptionAmount(illustration);
  const outOfPocketDuration = getOutOfPocketDuration(illustration, startYear);
  return {
    topBar,
    illustrationId,
    responseError,
    startYear,
    altStartYear,
    depositOptionDuration,
    depositOptionAmount,
    outOfPocketDuration,
  };
};

const dealWithConversion = (convertToFT, productList, payload) => {
  if (convertToFT) {
    // if old FTV/BT saved cases was auto-saved, delete it
    if (payload.loadedCase.autoSave) {
      deleteSavedCases([payload.guid], true);
      payload.loadedCase.name = `${newIllustrationAutoSaved} - ${eProduct.FT}`;
    }
    payload.loadedCase.product = eProduct.FT;
    // resave the case
    payload.loadedCase.showNotification = true;
    payload.loadedCase.productList = productList;
    payload.loadedCase.autoSave
      ? createCase(payload.loadedCase)
      : updateCase(payload.loadedCase, payload.loadedCase.guid);
  }
};

const scenarioTabNavsReducer = (state, action) => {
  state = state === undefined ? initialState : state;
  switch (action.type) {
    case actionTypes.OPEN_SAVEDCASE: {
      const { scenarioTabNavs, coverageTabNavs } = action.payload.model;
      const productList = [];
      let convertToFT = false;
      // This to assign all the missing properties from the initial/default to the saved old object with default value.
      const targetState = scenarioTabNavs;
      assignDef(targetState, initialState);
      scenarioTabNavs.tabNavs.forEach((Scenario) => {
        assignDef(targetState.topBars[Scenario.id], defaultTopBar);

        const current = targetState.topBars[Scenario.id];
        // this is a hack to make it backwards compatible with the old schema, do not remove
        if (current.depositOptionDurationType === 'Years') {
          current.depositOptionDurationType = eDepositOptionDurationType.year;
        }
        // there's no such thing as a FTV product anymore so when loading old FTV saved cases,
        // make sure Vitality option is set to true and...
        coverageTabNavs[Scenario.id].tabNavs.forEach((coverage) => {
          if (current.product === eProduct.FTV) {
            coverageTabNavs[Scenario.id][coverage].isVitalityPlusSelected = true;
          }
        });
        if (!productList.includes(current.product)) {
          productList.push(current.product);
        }
        // ...set product to FT (also for BT)
        // Ha, only Sonarqube would think an 8 line switch statement is
        // less complex that a 5 line if statement
        switch (current.product) {
          case eProduct.FTV:
          case eProduct.BT:
            current.product = eProduct.FT;
            // since these have been converted to a "new" product,
            // clear illustrationId so that caching doesn't come into play
            current.illustrationId = '';
            convertToFT = true;
            break;
          default:
        }
      });

      dealWithConversion(convertToFT, productList, action.payload);

      return {
        ...targetState,
      };
    }

    case actionTypes.ADD_SCENARIO_TABNAVS: {
      //Copying the Active State information except for Scenario Name
      //and Illustration as is required for the caching mechanism
      return {
        ...state,

        tabNavs: [...state.tabNavs, { id: action.payload.newTabId }],
        topBars: {
          ...state.topBars,
          [action.payload.newTabId]: {
            ...state.topBars[action.payload.currentTabId],
            [eField.scenarioName]: '',
            illustrationId: '',
          },
        },
        activeTabId: action.payload.newTabId,
      };
    }

    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      // update the new tab nav array to not include the removed scenario id
      const newTabNavs = _.reject(state.tabNavs, { id: action.payload.id });
      // omit/remove the id from the scenario top bars
      const newTopBars = _.omit(state.topBars, [action.payload.id]);
      // return updated state
      return {
        ...state,
        tabNavs: newTabNavs,
        topBars: newTopBars,
        activeTabId: action.payload.newActiveTabId,
      };
    }

    default:
      return scenarioTabNavsReducer2(state, action);
  }
};

const getNewScenario = (newState, value, premiumDuration) => {
  const newScenario = newState.topBars[newState.activeTabId];
  newScenario.depositOptionType =
    value && premiumDuration !== ePremiumDuration.pay10 ? eDepositOptionType.specified : eDepositOptionType.none;
  newScenario.depositOptionDurationType = value
    ? eDepositOptionDurationType.sameasbase
    : eDepositOptionDurationType.year;
};

const getScenarioState = (state, field, value, premiumDuration) => {
  const newTopBars = {
    ...state.topBars,
    [state.activeTabId]: {
      ...state.topBars[state.activeTabId],
      productValidationError: field === eField.prodError,
      product: field === eField.product ? value : state.topBars[state.activeTabId].product,
    },
  };

  if (field !== eField.product && field !== eField.prodError) {
    newTopBars[state.activeTabId][field] = value;
  }

  const newState = {
    ...state,
    isOutOfDate: field === eField.product || field === eField.effectiveDate ? true : state.isOutOfDate,
    topBars: newTopBars,
  };

  if (field === eField.includeMaxLevelDepositOption && state.topBars[state.activeTabId].product === eProduct.PAR) {
    getNewScenario(newState, value, premiumDuration);
  }
  return newState;
};

const checkCoverageSolve = (field, value, state) =>
  field === eField.isCoverageSolve && value && state.topBars[state.activeTabId].product === eProduct.PAR;

const newCoverageState = function (state, field, value, isCoverageSolve, premiumDuration) {
  const newTopBars = _.cloneDeep(state.topBars[state.activeTabId]);
  if (field === eField.premiumDuration) {
    return checkForPremiumDuration(value, newTopBars, field, isCoverageSolve, state);
  } else {
    if (checkCoverageSolve(field, value, state)) {
      // if coverageSolve is true change deposit option type to level max
      // and depositOptionDurationType to same as base for default
      // when it is false, set depositoptiontype to none
      const includeMaxLevelDepositOption = state.topBars[state.activeTabId].includeMaxLevelDepositOption;
      return {
        ...state,
        topBars: {
          ...state.topBars,
          [state.activeTabId]: {
            ...newTopBars,
            depositOptionType:
              includeMaxLevelDepositOption && premiumDuration !== ePremiumDuration.pay10
                ? eDepositOptionType.specified
                : eDepositOptionType.none,
            isDepositOptionCustom: false,
            depositOptionDurationType: eDepositOptionDurationType.sameasbase,
          },
        },
      };
    }
  }
  return state;
};

const scenarioTabNavsReducer2 = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_OUT_OF_DATE: {
      const isOutOfDate = action.payload;
      const topBar = state.topBars[state.activeTabId];
      let manualRefresh = state.manualRefresh;

      // for PAR, when certain fields are set, want to force the Refresh button to appear
      if (isOutOfDate && topBar.product === eProduct.PAR) {
        manualRefresh = performManualRefresh(topBar, manualRefresh);
      }

      return {
        ...state,
        isOutOfDate,
        manualRefresh,
        topBars: {
          ...state.topBars,
          [state.activeTabId]: {
            ...state.topBars[state.activeTabId],
            productValidationError: false,
          },
        },
      };
    }

    case actionTypes.LOADED_UDM_RESPONSE: {
      const {
        topBar,
        illustrationId,
        responseError,
        startYear,
        altStartYear,
        depositOptionDuration,
        depositOptionAmount,
        outOfPocketDuration,
      } = setLoadedUdmResponseVariables(action, state);
      const activeScenario = state.topBars[state.activeTabId];
      return {
        ...state,
        manualRefresh: false,
        isOutOfDate: false,
        topBars: {
          ...state.topBars,
          [state.activeTabId]: {
            ...topBar,
            illustrationId,
            depositOptionDuration,
            depositOptionAmount:
              activeScenario?.product === eProduct.PG ? activeScenario.depositOptionAmount : depositOptionAmount,
            premiumOffsetYear: startYear,
            premiumOffsetAltYear: altStartYear,
            outOfPocketDuration,
          },
        },
        summaryError: responseError,
      };
    }

    case actionTypes.MWI_ON_CHANGE: {
      const { target, field, value, premiumDuration, isCoverageSolve } = action.payload;
      if (target === eField.scenario) {
        return getScenarioState(state, field, value, premiumDuration);
      }
      if (target === eField.coverage) {
        return newCoverageState(state, field, value, isCoverageSolve, premiumDuration);
      }
      return state;
    }

    case actionTypes.LOADING_SPREADSHEET: {
      return { ...state, isSpreadsheetView: true };
    }

    case actionTypes.CLOSE_SPREADSHEET: {
      return { ...state, isSpreadsheetView: false };
    }
    default:
      return scenarioTabNavsReducer3(state, action);
  }
};

const scenarioTabNavsReducer3 = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_UDM: {
      return {
        ...state,
        udmEnabled: action.payload,
      };
    }
    case actionTypes.TOGGLE_SUMMARY: {
      return {
        ...state,
        sidebarVisible: action.payload,
      };
    }
    case actionTypes.LOADING_UDM_RESPONSE: {
      return { ...state, manualRefresh: false };
    }
    case actionTypes.TOGGLE_FORCE_UPDATE: {
      const isForceUpdate = action.payload;
      return {
        ...state,
        isForceUpdate,
      };
    }

    case actionTypes.TOGGLE_MANUAL_REFRESH: {
      const manualRefresh = action.payload;
      return {
        ...state,
        manualRefresh,
      };
    }

    case actionTypes.PERFORM_MANUAL_REFRESH: {
      const topBar = state.topBars[state.activeTabId];
      // when set, action.payload is a boolean to check whether a manual refresh is needed or not
      let manualRefresh = action.payload !== undefined ? action.payload : state.manualRefresh;

      // for PAR, when certain fields are set, want to call the summary endpoint
      if (!manualRefresh && topBar.product === eProduct.PAR) {
        manualRefresh = performManualRefresh(topBar, manualRefresh);
      }

      return {
        ...state,
        isOutOfDate: true,
        manualRefresh,
        isForceUpdate: true,
        topBars: {
          ...state.topBars,
          [state.activeTabId]: {
            ...state.topBars[state.activeTabId],
            productValidationError: false,
          },
        },
      };
    }

    default:
      return scenarioTabNavsReducer4(state, action);
  }
};

const scenarioTabNavsReducer4 = (state, action) => {
  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      return initialState;
    }
    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return {
        ...state,
        activeTabId: action.payload,
      };
    }
    case actionTypes.SELECT_PRODUCT: {
      return {
        manualRefresh: true,
        ...initialState,
        topBars: {
          ...initialState.topBars,
          [firstScenarioId]: {
            ...defaultTopBar,
            product: action.payload,
            premiumFrequency: action.payload === eProduct.PAR ? ePremiumFrequency.annually : ePremiumFrequency.monthly,
          },
        },
      };
    }

    default:
      return scenarioTabNavsReducer5(state, action);
  }
};

const scenarioTabNavsReducer5 = (state, action) => {
  switch (action.type) {
    case actionTypes.LOADED_INFORCE_POLICY: {
      const illustration = action.payload.policyData.illustration;
      const effectiveDate = moment(illustration.policy.effectiveDate).format('DD/MM/YYYY');
      const policyYear = getPolicyYear(illustration.policy.effectiveDate);
      const premiumOffsetIndicator = illustration.policy.premiumOffset;
      const depositoptiontypePerformax = eDepositOptionType.specified;
      const paymentOffsetPG = premiumOffsetIndicator ? ePremiumOffset.inforce : ePremiumOffset.sameaspolicy;
      const paymentOffsetPMax = premiumOffsetIndicator
        ? ePremiumOffset.inforce
        : ePremiumOffset.paymentcoverpolicycosts;

      let premiumOffset = defaultTopBar.premiumOffset;
      let depositOptionDuration = defaultTopBar.depositOptionDuration;
      let premiumOffsetYear = defaultTopBar.premiumOffsetYear;
      let premiumOffsetYearFrom = defaultTopBar.premiumOffsetYearFrom;
      let premiumOffsetYearTo = defaultTopBar.premiumOffsetYearTo;
      let maximumAdditionalDepositAmt = defaultTopBar.maximumAdditionalDepositAmt;
      let loanBalance = defaultTopBar.loanBalance;
      let lastDepositYear = defaultTopBar.lastDepositYear;
      let grandfatheredStatus = defaultTopBar.grandfatheredStatus;
      const depositOptionDurationType = defaultTopBar.depositOptionDurationType;
      let depositOptionAmount = 0;
      let depositOptionType = defaultTopBar.depositOptionType;
      if ([eProduct.PG, eProduct.PAR].includes(illustration.policy.product)) {
        premiumOffset = paymentOffsetPG;
        depositOptionDuration = eDefault.depositOptionDurationInforce;
        premiumOffsetYear = policyYear + 1;
        depositOptionAmount = illustration.policy.depositOption?.modalAmount ?? 0;

        if (depositOptionAmount > 0) {
          depositOptionType = eDepositOptionType.specified;
        }
      }
      if ([eProduct.Performax, eProduct.SB].includes(illustration.policy.product)) {
        depositOptionType = depositoptiontypePerformax;
        depositOptionDuration = eDefault.depositOptionDurationInforce;
        premiumOffsetYear = policyYear + 1;
        premiumOffsetYearFrom = policyYear + 1;
        premiumOffsetYearTo = policyYear + 1;
        premiumOffset = paymentOffsetPMax;
        depositOptionAmount = illustration.policy.depositOption?.modalAmount ?? 0;
        maximumAdditionalDepositAmt = illustration.policy.maximumAdditionalDepositAmt;
        loanBalance = illustration.policy.loanBalance;
        lastDepositYear = illustration.policy.depositOption?.depositYear;
        grandfatheredStatus = illustration.policy.tax?.grandfatheredStatus;
      }

      return {
        manualRefresh: true,
        ...initialState,
        topBars: {
          ...initialState.topBars,
          [firstScenarioId]: {
            ...defaultTopBar,
            product: illustration.policy.product,
            premiumOffset: premiumOffset,
            premiumFrequency: illustration.policy.depositMode,
            effectiveDate,
            depositOptionDuration: depositOptionDuration,
            premiumOffsetYear: premiumOffsetYear,
            premiumOffsetYearFrom,
            premiumOffsetYearTo,
            maximumAdditionalDepositAmt,
            loanBalance,
            lastDepositYear,
            grandfatheredStatus,
            depositOptionDurationType: depositOptionDurationType,
            depositOptionAmount: depositOptionAmount,
            depositOptionType: depositOptionType,
            premiumOffsetIndicator: premiumOffsetIndicator,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default scenarioTabNavsReducer;

export const getTabNavs = (state) => state.tabNavs;
export const getTopBars = (state) => state.topBars;
export const getActiveTab = (state) => state.activeTab;
