import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import MWIDropdown from '../../../core/components/mwi-dropdown';
import { eField, eConceptDividendScale } from '../../../core/configs';
import '../dividend-scales/dividend-scales.css';
import { calculateBankLoanRateDefault } from '../loan-information/bank-loan-default';

export class ConceptDividendScales extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    conceptDividendScales: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }).isRequired
    ),
    dividendScale: PropTypes.object,
    setConceptFieldValue: PropTypes.func,
    alternateDividendScale: PropTypes.string,
    primaryDividendScale: PropTypes.string,
    bankLoanRate: PropTypes.number,
  };

  onValueChange = (fieldName, fieldValue) => {
    let conceptDividendScale;
    let bankLoanRate;
    let recalculateBankLoanDefault;
    this.props.setConceptFieldValue(fieldName, fieldValue, eField.dividendScale);
    if (fieldValue === eConceptDividendScale.alternate && fieldName === eField.dividendScaleType) {
      conceptDividendScale = this.props.alternateDividendScale;
      bankLoanRate = this.props.bankLoanRate;
      recalculateBankLoanDefault = calculateBankLoanRateDefault(bankLoanRate, conceptDividendScale);
      this.props.setConceptFieldValue(eField.bankLoanRate, recalculateBankLoanDefault, eField.loanInformation);
    } else {
      if (fieldValue === eConceptDividendScale.primary && fieldName === eField.dividendScaleType) {
        bankLoanRate = this.props.bankLoanRate;
        conceptDividendScale = this.props.primaryDividendScale;
        recalculateBankLoanDefault = calculateBankLoanRateDefault(bankLoanRate, conceptDividendScale);
        this.props.setConceptFieldValue(eField.bankLoanRate, recalculateBankLoanDefault, eField.loanInformation);
      }
    }
  };

  render() {
    const { dividendScale, conceptDividendScales } = this.props;
    return (
      <div className="p-grid p-grid-no-margin p-col-12 mwi-margin-top18" id="conceptDividendScalesContainer">
        <div className="p-col-6 p-lg-6 p-xl-4" id="concept-dividend-scale-dropdown">
          <MWIDropdown
            name={eField.dividendScale}
            label={<FormattedMessage id="dividendscale.concept" />}
            value={dividendScale.type}
            options={conceptDividendScales}
            onChange={(e) => {
              this.onValueChange(eField.dividendScaleType, e.target.value);
            }}
            containerStyle="space-right"
            grid="12"
            role="dividendscale-concept-dropdown"
          />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, conceptTabNavs }) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const topbar = scenarioTabNavs.topBars[scenarioTabId];
  return {
    locale: app.locale,
    alternateDividendScale: topbar.alternateDividendScale,
    primaryDividendScale: topbar.primaryDividendScale,
    bankLoanRate: conceptTabNavs[scenarioTabId].loanInformation.bankLoanRate,
  };
};

export default connect(mapStateToProps, null)(injectIntl(ConceptDividendScales));
