import React from 'react';
import PropTypes from 'prop-types';
import Riders from '../riders';
import TermRiders from '../term-riders';
import { ProductConfig } from '../product-config';
import TermOtherRiders from '../termOther-riders';
import SpousalRiders from '../spousal-riders';

export const RiderTab = function (product) {
  return (
    <React.Fragment>
      {ProductConfig[product.product].riderSections.termRiders && (
        <div className="p-grid p-grid-no-margin riders-container tir">
          <TermRiders />
        </div>
      )}

      {ProductConfig[product.product].riderSections.termOtherRiders && (
        <div className="p-grid p-grid-no-margin riders-container tir">
          <TermOtherRiders />
        </div>
      )}

      {ProductConfig[product.product].riderSections.spousalRiders && (
        <div className="p-grid p-grid-no-margin riders-container tir">
          <SpousalRiders />
        </div>
      )}

      {ProductConfig[product.product].riderSections.riders && (
        <div className="p-grid p-grid-no-margin riders-container">
          <Riders />
        </div>
      )}
    </React.Fragment>
  );
};

export default RiderTab;

RiderTab.propTypes = {
  product: PropTypes.string,
};
