import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InputMask } from 'primereact/components/inputmask/InputMask';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { isIOS } from '../../utils';
import { eLocale } from '../../configs';
import HelpText from '../help-text';
import enMyClientsSample from '../../../../src/assets/images/my-clients-image-en.png';
import frMyClientsSample from '../../../../src/assets/images/my-clients-image-fr.png';

export class MWICoverageEffective extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locale: PropTypes.string,
    errors: PropTypes.object,
    grid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isRequired: PropTypes.bool,
    index: PropTypes.number,
    styleClass: PropTypes.string,
    labelStyle: PropTypes.string,
    topBarFieldStyle: PropTypes.string,
    containerClass: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    labelId: PropTypes.string,
    ariaLabel: PropTypes.string,
  };

  static defaultProps = {
    grid: '2',
    locale: eLocale.en,
    isRequired: false,
    labelId: '',
  };

  constructor(props) {
    super(props);
    this.mwiDobRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { locale } = this.props;

    /*This logic to fix placeholder issue which is not updated when the language gets changed*/
    if (prevProps.locale !== locale) {
      this.mwiDobRef.current.forceUpdate();
    }
  }

  render() {
    const {
      name,
      label,
      value,
      locale,
      placeholder,
      onChange,
      onBlur,
      grid,
      intl,
      index,
      errors,
      isRequired,
      styleClass,
      labelId,
      ariaLabel,
      containerClass,
      labelStyle,
      topBarFieldStyle,
    } = this.props;

    return (
      <div className={`p-col-${grid} ${containerClass} mwi-control-wrapper ${locale}`}>
        <div className="coverage-div">
          <div className={`mwi-label ${labelStyle}`}>
            <label htmlFor={`txt-${name}${labelId}`}>{label}</label>
            {!!isRequired && <span className="mwi-mandatory-ind">*</span>}
          </div>

          <div className="label-link" style={{ marginLeft: '12px' }}>
            <HelpText
              containerClass={`trc-help  ${locale} ${index === 1 ? 'second' : ''}`}
              overlayId="coverageEffectiveDateTip"
              overlayContent={() => {
                return (
                  <div>
                    <div className="trc-help-text">
                      <span className="mwi-margin-right15">
                        <FormattedMessage id="ftrc.calculatorInput.help.coverage.effective.date.1" /> <br /> <br />
                        <FormattedMessage id="ftrc.calculatorInput.help.coverage.effective.date.2" /> <br /> <br />
                      </span>
                      <i className="material-icons">clear</i>
                    </div>
                    <img
                      className="example-my-clients"
                      src={locale === eLocale.en ? enMyClientsSample : frMyClientsSample}
                      alt={intl.formatMessage({ id: 'common.logo' })}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div className={`${styleClass}`}>
          <InputMask
            id={`txt-${name}${labelId}`}
            mask="99/99/9999"
            slotChar={placeholder || label}
            value={value}
            className={`mwi-widget mwi-input ${topBarFieldStyle} ${styleClass} ${
              !errors[name] ? '' : 'mwi-error-field'
            }`}
            placeholder={placeholder || label}
            onComplete={(e) => {
              // Because of mask copy paste issue we are using the reference value
              if (e.originalEvent.target) {
                onBlur(name, e.originalEvent.target.value);
              } else {
                onBlur(name, this.mwiDobRef.current.input.value);
              }
            }}
            onChange={(e) => onChange(name, e.value)}
            aria-label={ariaLabel}
            ref={this.mwiDobRef}
            aria-describedby="dobErrorMessage"
          />
        </div>

        <div
          id="dobErrorMessage"
          className="mwi-error-msg"
          role={errors[name] || isIOS ? 'alert' : ''}
          aria-atomic="true"
        >
          {errors[name]}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps)(injectIntl(MWICoverageEffective));
