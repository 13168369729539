import React from 'react';
import MWIDropdown from '../mwi-dropdown';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { eField } from '../../configs';

const SpecialQuote = ({
  specialQuoteValue,
  specialQuoteOptions,
  dropdownStyle,
  containerStyle,
  onChange,
  isDisabled,
  role,
  inforce,
}) => {
  return (
    <React.Fragment>
      {!inforce && (
      <MWIDropdown
        name={eField.quoteOption}
        label={<FormattedMessage id="coverage.specialQuote" />}
        value={specialQuoteValue}
        options={specialQuoteOptions}
        onChange={(e) => onChange(eField.quoteOption, e.target.value, specialQuoteValue)}
        dropdownStyle={dropdownStyle}
        containerStyle={containerStyle}
        disabled={isDisabled}
        role={role}
      />
  )}
    </React.Fragment>
  );
};
export default SpecialQuote;

SpecialQuote.propTypes = {
  specialQuoteValue: PropTypes.string,
  specialQuoteOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired
  ),
  onChange: PropTypes.func,
  dropdownStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  isDisabled: PropTypes.bool,
  role: PropTypes.string,
  inforce: PropTypes.bool,
};
SpecialQuote.defaultProps = {
  containerStyle: 'mwi-margin-bottom10 p-col-4 p-md-5 p-lg-4',
  dropdownStyle: 'mwi-w90',
};
