import React from 'react';
import { eDepositOwnerResidence, eDepositType, eReqStatus, eLocale } from '../../core/configs';
import { getFormattedValue } from '../../core/utils';

// ----------- MUL Product ------------
const getDepositOptionLines = (depositType, depositFrequency, isCustom) => {
  // Deposit option + years
  let line1, line2;
  let line4 = 'common.years';
  let line3 = line4;
  switch (isCustom ? 'custom' : depositType) {
    case eDepositType.specified:
      line1 = `summary.depositoption.${depositFrequency.toLowerCase()}`;
      line2 = 'summary.depositoption.for';
      line4 = null;
      break;
    case eDepositType.levelmin:
      line1 = 'deposit.mul.lvlmin';
      line2 = `summary.depositoption.MUL.${depositFrequency.toLowerCase()}.small`;
      line3 = 'common.for.term';
      break;
    case eDepositType.levelmax:
      line1 = 'deposit.mul.lvlmax';
      line2 = `summary.depositoption.MUL.${depositFrequency.toLowerCase()}.small`;
      line3 = 'common.for.term';
      break;
    case eDepositType.yearlymin:
      line1 = 'summary.depositoption.MUL.yearly';
      line2 = line3 = line4 = null;
      break;
    default:
      line1 = `summary.depositoption.${depositFrequency.toLowerCase()}`;
      line2 = line3 = line4 = null;
  }
  return { line1, line2, line3, line4 };
};

export const printOwnerResidence = (ownerResidence, locale) => {
  if (locale === 'fr') {
    switch (ownerResidence) {
      case 'None':
        return 'S. O.';
      case 'Select':
        return 'Sélectionnez';
      default:
        return ownerResidence;
    }
  } else {
    return ownerResidence;
  }
};

// Prepare values for Top Summary
export const formatValidationsMUL = ({
  maximumFirstYearAmount,
  minimumFirstYearAmount,
  validationStatus,
  validationWarnings,
  selectResidenceClicked,
  locale,
  ownerResidence,
  validationErrors,
  bronzeDepositAmount,
}) => {
  return {
    maximumFirstYearAmount: getFormattedValue(locale, maximumFirstYearAmount),
    minimumFirstYearAmount: getFormattedValue(locale, minimumFirstYearAmount),
    ownerResidence: `${locale === 'fr' ? ` ` : ``}: ${printOwnerResidence(ownerResidence, locale)}`,
    ownerResidenceError: ownerResidence === eDepositOwnerResidence.select && selectResidenceClicked,
    isValidationError: validationStatus.toUpperCase() === eReqStatus.error,
    isValidationWarning:
      validationWarnings &&
      validationWarnings.length > 0 &&
      validationWarnings.filter((e) => e.id !== 'MaxWithdrawal').length > 0,
    validationWarnings:
      validationWarnings && validationWarnings.length > 0 && validationWarnings.filter((e) => e.id !== 'MaxWithdrawal'),
    validationErrors,
    bronzeDepositAmount: getFormattedValue(locale, bronzeDepositAmount),
  };
};

// Summary Coverage Fommatting
export const formattedDataMULPaymentDeposits = ({
  depositType,
  isCustom,
  depositFrequency,
  durationTo,
  durationStart,
  durationMUL,
  isAdditionalDepositSelected,
  additionalDeposit,
  locale,
  amount,
  withdrawals,
}) => {
  const { line1, line2, line3, line4 } = getDepositOptionLines(depositType, depositFrequency, isCustom);

  const spacingFrenchYearlyMin = () =>
    ((locale === eLocale.fr && depositType === eDepositType.levelmin) || depositType === eDepositType.yearlymin) && (
      <br />
    );
  const duration = () => durationTo && durationStart && Math.abs(durationTo - durationStart + 1);

  return {
    line1,
    line2,
    line3,
    line4,
    isCustom,
    depositType,
    withdrawalType: withdrawals.type,
    withdrawalAmount: getFormattedValue(locale, withdrawals.amount || 0, 0),
    withdrawalDuration: withdrawals.durationEnd - withdrawals.durationStart + 1 || '0',
    spacingFrenchYearlyMin: spacingFrenchYearlyMin(),
    isAdditionalDepositSelected: isAdditionalDepositSelected && getFormattedValue(locale, additionalDeposit || '0'),
    durationPayment: ` ${duration() || durationMUL || 0} `,
    depositFrequencyPricing:
      !isCustom && depositType !== eDepositType.yearlymin && getFormattedValue(locale, amount || '0'),
  };
};
