import { eProduct, eTabName, eRider } from '../core/configs';
export const ProductConfig = {
  [eProduct.FT]: {
    tabs: [eTabName[0], eTabName[2]],
    coverageSections: {
      coverageAccordion: true,
      coverageDetails: true,
      coverageSolve: true,
      vitalityStatus: false,
      clientSection: true,
      ratings: true,
      dividendOptions: false,
    },
    paymentSections: {},
    riderSections: {
      termRiders: false,
      termOtherRiders: false,
      spousalRiders: false,
      riders: true,
      riderList: [
        {
          id: eRider.tdw,
          isAmount: false,
          isRating: true,
        },
        {
          id: eRider.bvp,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.gio,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.adnd,
          isAmount: true,
          isRating: true,
        },
        {
          id: eRider.cpr,
          isAmount: false,
          isRating: false,
        },
      ],
    },
    ratesSections: {},
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: false,
      riderSummary: true,
      additionalCostSummary: true,
      vitalitySummary: false,
    },
    hasDepositOption: false,
    hasRates: false,
    hasPremiumOffsets: false,
  },
  [eProduct.LC]: {
    tabs: [eTabName[0], eTabName[2]],
    coverageSections: {
      coverageAccordion: true,
      coverageDetails: true,
      coverageSolve: false,
      vitalityStatus: false,
      clientSection: true,
      ratings: true,
      dividendOptions: false,
    },
    riderSections: {
      termRiders: false,
      termOtherRiders: false,
      spousalRiders: false,
      riders: true,
      riderList: [
        {
          id: eRider.wpd,
          isAmount: false,
          isRating: true,
        },
        {
          id: eRider.clc,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.tdwpayor,
          isAmount: false,
          isRating: true,
        },
      ],
    },
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: false,
      riderSummary: true,
      additionalCostSummary: true,
      vitalitySummary: false,
    },
    hasDepositOption: false,
    hasRates: false,
    hasPremiumOffsets: false,
  },
  [eProduct.BT]: {
    tabs: [eTabName[0], eTabName[2]],
    coverageSections: {
      coverageAccordion: true,
      coverageDetails: true,
      coverageSolve: true,
      vitalityStatus: false,
      clientSection: true,
      ratings: true,
      dividendOptions: false,
    },
    paymentSections: {},
    riderSections: {
      termRiders: false,
      termOtherRiders: false,
      spousalRiders: false,
      riders: true,
      riderList: [
        {
          id: eRider.tdw,
          isAmount: false,
          isRating: true,
        },
        {
          id: eRider.bvp,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.gio,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.adnd,
          isAmount: true,
          isRating: true,
        },
        {
          id: eRider.cpr,
          isAmount: false,
          isRating: false,
        },
      ],
    },
    ratesSections: {},
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: false,
      riderSummary: true,
      additionalCostSummary: true,
      vitalitySummary: false,
    },
    hasDepositOption: false,
    hasRates: false,
    hasPremiumOffsets: false,
  },
  [eProduct.FTV]: {
    tabs: [eTabName[0], eTabName[2]],
    coverageSections: {
      coverageAccordion: false,
      coverageDetails: true,
      coverageSolve: true,
      vitalityStatus: true,
      clientSection: true,
      ratings: true,
      dividendOptions: false,
    },
    paymentSections: {},
    riderSections: {
      termRiders: false,
      termOtherRiders: false,
      spousalRiders: false,
      riders: true,
      riderList: [
        {
          id: eRider.tdw,
          isAmount: false,
          isRating: true,
        },
        {
          id: eRider.bvp,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.gio,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.adnd,
          isAmount: true,
          isRating: true,
        },
        {
          id: eRider.cpr,
          isAmount: false,
          isRating: false,
        },
      ],
    },
    ratesSections: {},
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: false,
      riderSummary: true,
      additionalCostSummary: true,
      vitalitySummary: true,
    },
    hasDepositOption: false,
    hasRates: false,
    hasPremiumOffsets: false,
  },
  [eProduct.PAR]: {
    tabs: [eTabName[0], eTabName[1], eTabName[2], eTabName[3], eTabName[4]],
    coverageSections: {
      coverageAccordion: false,
      coverageDetails: true,
      coverageSolve: true,
      vitalityStatus: false,
      clientSection: true,
      ratings: true,
      dividendOptions: true,
    },
    paymentSections: {
      deposits: false,
      depositOption: true,
      premiumOffset: true,
      withdrawals: true,
    },
    riderSections: {
      termRiders: true,
      termOtherRiders: false,
      spousalRiders: false,
      riders: true,
      riderList: [
        {
          id: eRider.tdw,
          isAmount: false,
          isRating: true,
        },
        {
          id: eRider.gio,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.cpr,
          isAmount: false,
          isRating: false,
        },
        {
          id: eRider.tdwpayor,
          isAmount: false,
          isRating: false,
        },
      ],
    },
    ratesSections: {
      policyInterestRate: false,
      sideAccountInterestRate: false,
      marginalTaxRate: true,
    },
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: true,
      riderSummary: true,
      additionalCostSummary: true,
      vitalitySummary: false,
    },
    hasDepositOption: true,
    hasRates: true,
    hasPremiumOffsets: true,
  },
  [eProduct.PG]: {
    tabs: [eTabName[0], eTabName[1], eTabName[2], eTabName[3], eTabName[4]],
    coverageSections: {
      coverageAccordion: true,
      coverageDetails: true,
      coverageSolve: false,
      vitalityStatus: false,
      clientSection: true,
      ratings: true,
      dividendOptions: false,
    },
    paymentSections: {
      deposits: false,
      depositOption: false,
      premiumOffset: true,
      withdrawals: true,
      additionPayment: true,
    },
    riderSections: {
      termRiders: true,
      termOtherRiders: false,
      spousalRiders: false,
      riders: true,
      riderList: [
        {
          id: eRider.tdw,
          isAmount: false,
          isRating: true,
        },
        {
          id: eRider.gio,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.adb,
          isAmount: true,
          isRating: true,
        },
        {
          id: eRider.cpr,
          isAmount: false,
          isRating: false,
        },
        {
          id: eRider.tdwpayor,
          isAmount: false,
          isRating: false,
        },
      ],
    },
    ratesSections: {
      policyInterestRate: false,
      sideAccountInterestRate: true,
      marginalTaxRate: true,
    },
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: true,
      riderSummary: true,
      additionalCostSummary: true,
      vitalitySummary: false,
    },
    hasDepositOption: true,
    hasRates: true,
    hasPremiumOffsets: true,
  },
  [eProduct.MUL]: {
    tabs: [eTabName[0], eTabName[1], eTabName[2], eTabName[4]],
    coverageSections: {
      coverageAccordion: false,
      coverageDetails: true,
      coverageSolve: false,
      vitalityStatus: false,
      clientSection: true,
      ratings: true,
      dividendOptions: false,
    },
    paymentSections: {
      deposits: true,
      depositOption: false,
      premiumOffset: false,
      withdrawals: true,
    },
    riderSections: {
      termRiders: true,
      termOtherRiders: false,
      spousalRiders: false,
      riders: true,
      riderList: [
        {
          id: eRider.cpr,
          isAmount: false,
          isRating: false,
        },
      ],
    },
    ratesSections: {
      policyInterestRate: true,
      sideAccountInterestRate: true,
      marginalTaxRate: true,
    },
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: true,
      riderSummary: true,
      additionalCostSummary: false,
      vitalitySummary: false,
    },
    hasDepositOption: true,
    hasRates: true,
    hasPremiumOffsets: false,
  },
  [eProduct.IV]: {
    tabs: [eTabName[0], eTabName[1], eTabName[2], eTabName[4]],
    coverageSections: {
      coverageAccordion: false,
      coverageDetails: true,
      coverageSolve: false,
      vitalityStatus: false,
      clientSection: true,
      ratings: true,
      dividendOptions: false,
    },
    paymentSections: {
      deposits: true,
      depositOption: false,
      premiumOffset: false,
      withdrawals: true,
    },
    riderSections: {
      termRiders: true,
      termOtherRiders: false,
      spousalRiders: false,
      riders: true,
      riderList: [
        {
          id: eRider.cpr,
          isAmount: false,
          isRating: false,
        },
      ],
    },
    ratesSections: {
      policyInterestRate: true,
      sideAccountInterestRate: true,
      marginalTaxRate: true,
    },
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: true,
      riderSummary: true,
      additionalCostSummary: false,
      vitalitySummary: false,
    },
    hasDepositOption: true,
    hasRates: true,
    hasPremiumOffsets: false,
  },
  [eProduct.FTRC]: {
    tabs: [],
    coverageSections: {},
    paymentSections: {},
    riderSections: {},
    ratesSections: {},
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: false,
      riderSummary: false,
      additionalCostSummary: false,
      vitalitySummary: false,
    },
    hasDepositOption: false,
    hasRates: false,
    hasPremiumOffsets: false,
  },
  [eProduct.Performax]: {
    tabs: [eTabName[0], eTabName[1], eTabName[2], eTabName[3]],
    coverageSections: {
      coverageAccordion: true,
      coverageDetails: true,
      coverageSolve: false,
      vitalityStatus: false,
      clientSection: true,
      ratings: true,
      dividendOptions: true,
    },
    paymentSections: {
      deposits: false,
      depositOption: false,
      premiumOffset: true,
      withdrawals: true,
      additionPayment: true,
    },
    riderSections: {
      termRiders: true,
      termOtherRiders: true,
      spousalRiders: true,
      riders: true,
      riderList: [
        {
          id: eRider.tdw,
          isAmount: false,
          isRating: true,
        },
        {
          id: eRider.gio,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.cpr,
          isAmount: false,
          isRating: false,
        },
        {
          id: eRider.adb,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.pwdd,
          isAmount: false,
          isRating: false,
        },
        {
          id: eRider.rop,
          isAmount: true,
          isRating: false,
        },
      ],
    },
    ratesSections: {
      policyInterestRate: false,
      sideAccountInterestRate: false,
      marginalTaxRate: true,
    },
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: true,
      riderSummary: true,
      additionalCostSummary: true,
      vitalitySummary: false,
    },
    hasDepositOption: true,
    hasRates: true,
    hasPremiumOffsets: true,
  },
  [eProduct.SB]: {
    tabs: [eTabName[0], eTabName[1], eTabName[2], eTabName[3]],
    coverageSections: {
      coverageAccordion: true,
      coverageDetails: true,
      coverageSolve: false,
      vitalityStatus: false,
      clientSection: true,
      ratings: true,
      dividendOptions: true,
    },
    paymentSections: {
      deposits: false,
      depositOption: false,
      premiumOffset: true,
      withdrawals: true,
      additionPayment: true,
    },
    riderSections: {
      termRiders: true,
      termOtherRiders: true,
      spousalRiders: true,
      riders: true,
      riderList: [
        {
          id: eRider.tdw,
          isAmount: false,
          isRating: true,
        },
        {
          id: eRider.gio,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.cpr,
          isAmount: false,
          isRating: false,
        },
        {
          id: eRider.adb,
          isAmount: true,
          isRating: false,
        },
        {
          id: eRider.pwdd,
          isAmount: false,
          isRating: false,
        },
        {
          id: eRider.rop,
          isAmount: true,
          isRating: false,
        },
      ],
    },
    ratesSections: {
      policyInterestRate: false,
      sideAccountInterestRate: false,
      marginalTaxRate: true,
    },
    summary: {
      premiumSummary: true,
      coverageSummary: true,
      paymentSummary: true,
      riderSummary: true,
      additionalCostSummary: true,
      vitalitySummary: false,
    },
    hasDepositOption: true,
    hasRates: true,
    hasPremiumOffsets: true,
  },
};
