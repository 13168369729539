import React from 'react';
import PropTypes from 'prop-types';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';
import { FormattedMessage } from 'react-intl';
import { eField, eProduct } from '../../core/configs';
import MULPaymentSection from './summary_payment_section/MULPaymentSection';
import PaymentSection from './summary_payment_section/PaymentSection';
import PGoldPaymentSection from './summary_payment_section/PGoldPaymentSection';

export const SummaryPaymentSection = (props) => {
  const {
    isIllustrationRatesFolded,
    dispatchAction,
    depositOption,
    product,
    withdrawals,
    isInforce,
    premiumFrequency,
    premiumOffset,
    premiumOffsetYear,
    premiumDuration,
    premiumOffsetAltYear,
    additionOptionType,
    depositOptionDurationType,
    depositOptionDuration,
    depositOptionAmount,
    isSwitchToCashDividends,
    locale,
    userType,
    intl,
    clients,
    onChange,
    initialPremium,
    app,
    outOfPocketDuration,
    isCoverageSolve,
  } = props;

  const productSummary = () => {
    if (product === eProduct.MUL) {
      return <MULPaymentSection depositOption={depositOption} withdrawals={withdrawals} isInforce={isInforce} />;
    } else if (product === eProduct.PG || product === eProduct.Performax || product === eProduct.SB) {
      return (
        <PGoldPaymentSection
          premiumFrequency={premiumFrequency}
          premiumOffset={premiumOffset}
          premiumOffsetYear={premiumOffsetYear}
          product={product}
          additionOptionType={additionOptionType}
          depositOptionDurationType={depositOptionDurationType}
          depositOptionDuration={depositOptionDuration}
          depositOptionAmount={depositOptionAmount}
          withdrawals={withdrawals}
          initialModalPremium={initialPremium}
          locale={locale}
          depositOption={depositOption}
          app={app}
          outOfPocketDuration={outOfPocketDuration}
        />
      );
    } else {
      return (
        <PaymentSection
          premiumFrequency={premiumFrequency}
          premiumOffset={premiumOffset}
          premiumOffsetYear={premiumOffsetYear}
          premiumDuration={premiumDuration}
          premiumOffsetAltYear={premiumOffsetAltYear}
          isSwitchToCashDividends={isSwitchToCashDividends}
          additionOptionType={additionOptionType}
          depositOptionDurationType={depositOptionDurationType}
          depositOptionDuration={depositOptionDuration}
          depositOptionAmount={depositOptionAmount}
          depositOption={depositOption}
          withdrawals={withdrawals}
          locale={locale}
          userType={userType}
          product={product}
          clients={clients}
          intl={intl}
          onChange={onChange}
          isCoverageSolve={isCoverageSolve}
        />
      );
    }
  };

  return (
    <MWIAccordion
      folded={isIllustrationRatesFolded}
      title={
        <span className="summary-accordion-font">
          <FormattedMessage id="summary.payments" />
        </span>
      }
      onClickChange={() => dispatchAction(eField.isIllustrationRatesFolded, !isIllustrationRatesFolded, 'scenario')}
      className={'summary-accordion-no-border'}
    >
      <div className="illustration-rates mwi-padding-top12 mwi-padding-top12" tabIndex="0">
        {/* Display Payments sections based on product type */}
        {productSummary()}
      </div>
    </MWIAccordion>
  );
};

SummaryPaymentSection.propTypes = {
  isIllustrationRatesFolded: PropTypes.bool,
  dispatchAction: PropTypes.func,
  depositOption: PropTypes.object,
  locale: PropTypes.string,
  product: PropTypes.string,
  withdrawals: PropTypes.object,
  isInforce: PropTypes.bool,
  premiumFrequency: PropTypes.string,
  premiumDuration: PropTypes.string,
  premiumOffset: PropTypes.string,
  premiumOffsetYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  premiumOffsetAltYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionOptionType: PropTypes.string,
  depositOptionDurationType: PropTypes.string,
  depositOptionDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  depositOptionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSwitchToCashDividends: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userType: PropTypes.string,
  intl: PropTypes.object,
  clients: PropTypes.object,
  onChange: PropTypes.func,
  initialPremium: PropTypes.string,
  app: PropTypes.object,
  outOfPocketDuration: PropTypes.number,
  isCoverageSolve: PropTypes.bool,
};

export default SummaryPaymentSection;
