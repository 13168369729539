import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './riders.css';
import { eProduct } from '../../core/configs';

export const RiderListHeader = (props) => {
  const isParInforce = props.inforce && props.product === eProduct.PAR;
  const isFTBTInforce = props.inforce && (props.product === eProduct.FT || props.product === eProduct.BT);
  return (
    <div className="p-grid p-grid-no-margin p-col-12 header-labels">
      <div className="p-col-5" id="ridersHeader">
        <span aria-required="true">
          <FormattedMessage id="common.riders" />
        </span>
      </div>
      {/* <div className="p-col-6"> &nbsp; </div> */}
      {(!props.inforce ||
        [eProduct.PG, eProduct.Performax, eProduct.SB].includes(props.product) ||
        isParInforce ||
        isFTBTInforce) && (
        <div data-testid="riders" className="p-col-7 header-labels-wrapper">
          <div className="p-grid p-grid-no-margin-all p-col-12">
            <div className="p-col-5" id="ridersClients">
              <span aria-required="true">
                <FormattedMessage id="common.client" />
              </span>
              {/* <span className="mwi-mandatory-ind" aria-hidden="true">
            *
          </span> */}
            </div>
            <div className="p-col-4 ratings-label" id="riderAmount">
              <label aria-required="true">
                <FormattedMessage id="common.amount" />
              </label>
              {/* <span className="mwi-mandatory-ind" aria-hidden="true">
            *
          </span> */}
            </div>
            <div className="p-col-3 ratings-label" id="riderRating">
              <label aria-required="true">
                <FormattedMessage id="common.ratings" />
              </label>
              {/* <span className="mwi-mandatory-ind" aria-hidden="true">
            *
          </span> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

RiderListHeader.propTypes = {
  inforce: PropTypes.bool,
  product: PropTypes.string,
};

RiderListHeader.defaultProps = {
  inforce: false,
};

export default RiderListHeader;
