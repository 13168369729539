import { useEffect } from 'react';
import { useToast } from '@manulife/mux';
import PropTypes from 'prop-types';

const ToastNotification = ( { text, status, icon, action, send, toggle } ) => {
  const { push } = useToast();

  useEffect(() => {
    if (send) {
      push({ text, status, icon, action });
    }
  }, [toggle]);

  return null;
};

ToastNotification.propTypes = {
  text: PropTypes.string,
  status: PropTypes.string,
  icon: PropTypes.object,
  action: PropTypes.object,
  send: PropTypes.bool,
  toggle: PropTypes.bool
};

export default ToastNotification;