import React from 'react';
import PropTypes from 'prop-types';
import MWIDropdown from '../mwi-dropdown';
import { FormattedMessage } from 'react-intl';
import './client-selection.css';

// clients: all clients
// clientId: the selected client(insured) id
const ClientSelection = ({
  clientIdInCov,
  clientOptions,
  showClientLabel,
  showClientIcon,
  showClientSelect,
  udmEnabled,
  uniqueId,
  onChange,
}) => {
  return (
    <div className="p-col-10 client insured" role="container-clientselection">
      <div className="p-col-8 p-lg-8 p-sm-10 content">
        <span className="mwi-label label-group" role="heading" aria-level="3">
          {showClientIcon && (
            <i className="material-icons" aria-hidden="true" data-testid="material-icons">
              account_circle
            </i>
          )}
          {showClientLabel && (
            <label className="label" id="client-insured" data-testid="label-clientinsured">
              <FormattedMessage id="common.client" />
            </label>
          )}
        </span>

        {showClientSelect && (
          <div className="mwi-inline mwi-w100 client-selection">
            <MWIDropdown
              name={`clientSelection.${uniqueId}`}
              label={<FormattedMessage id="common.client" />}
              value={clientIdInCov}
              options={clientOptions}
              onChange={(e) => onChange(e.target.value, clientIdInCov)}
              grid="6"
            />
          </div>
        )}
        {udmEnabled && <div className="udm-id mwi-inline">{clientIdInCov}</div>}
      </div>
    </div>
  );
};

export default ClientSelection;

ClientSelection.propTypes = {
  clientIdInCov: PropTypes.string,
  clientOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  showClientIcon: PropTypes.bool,
  showClientLabel: PropTypes.bool,
  showClientSelect: PropTypes.bool,
  udmEnabled: PropTypes.bool,
  uniqueId: PropTypes.string,
  placeholder: PropTypes.string,
};

ClientSelection.defaultProps = {
  clientOptions: [],
  showClientIcon: true,
  showClientLabel: false,
  showClientSelect: false,
  showClientRatings: false,
  showJointRatings: false,
  placeholder: 'Client 1',
};
