import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import MWIButton from '../mwi-button';
import MWIInput from '../mwi-input';
import { createShareCase } from '../../udm-mapper/sideEffect';
import { connect } from 'react-redux';
import { saveCaseError, dispatchMWIOnChange } from '../../actions';
import { bindActionCreators } from 'redux';
import { Growl } from 'primereact/growl';
import './share-illustration-model.css';
import { getSharedCaseModel } from './share-illustration-functions';

export class ShareIllustrationModel extends PureComponent {
  static propTypes = {
    cancel: PropTypes.func,
    locale: PropTypes.string,
    title: PropTypes.string,
    intl: PropTypes.object,
    app: PropTypes.object,
    model: PropTypes.object,
    saveCaseErrorAppAction: PropTypes.func,
    dispatchMWIOnChangeAppAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      shareLink: '',
      copyBtnTxt: 'common.copy',
    };

    this.save = this.save.bind(this);
    this.copy = this.copy.bind(this);
    this.cancel = this.cancel.bind(this);
    this.txtShareLink = React.createRef();
    this.timer = React.createRef();
  }

  componentDidMount() {
    this.save();
  }

  dispatchSaveCaseError(errResponse) {
    this.props.saveCaseErrorAppAction({
      target: 'app',
      value: errResponse,
    });
  }

  dispatchAction(field = 'guid', value = '', target = 'app') {
    this.props.dispatchMWIOnChangeAppAction({
      target,
      field,
      value,
    });
  }

  save() {
    const caseModel = getSharedCaseModel(this.props);

    createShareCase(caseModel)
      .then((response) => {
        if (response !== undefined && response !== null && response.isError) {
          this.dispatchSaveCaseError(response.message);
        } else {
          this.setState({
            shareLink: `${window.location.origin}/illustrations/share/${response?.items[0].guid}`,
          });
        }
      })
      .catch((errResponse) => {
        this.dispatchAction('errorMessage', errResponse);
      });
  }

  copy() {
    // Highlight the input
    this.txtShareLink.current.input.current.element.select();

    navigator.clipboard.writeText(this.state.shareLink);
    // Set button to Copied
    this.setState({ copyBtnTxt: 'common.copied' });

    // Timer in 5 seconds
    const timeOut = 5000;
    this.timer = setTimeout(() => {
      this.setState({
        copyBtnTxt: 'common.copy',
      });
      this.txtShareLink.current.input.current.element.blur();
    }, timeOut);
  }

  cancel() {
    clearTimeout(this.timer);
    this.props.cancel();
  }

  render() {
    const { intl, title } = this.props;

    return (
      <div className="p-grid p-grid-no-margin-side">
        <Growl ref={(el) => (this.growl = el)} />
        {
          <div>
            <div className="row-model p-col-12">
              <h2 className="title-model p-col-11">{title}</h2>
              <MWIButton
                label="X"
                onClick={this.cancel}
                styleClass="cancelShareLinkButton p-col-1"
                title="cancel-btn"
              />
            </div>
            <div className="shareIllustrationContent row-model">
              <div>
                <FormattedMessage id="header.model.label.share" />
              </div>
              <div id="txt-shareLinkInput-noSelect">
                <MWIInput
                  name=""
                  labelId="shareLinkInput"
                  label=""
                  value={this.state.shareLink}
                  ref={this.txtShareLink}
                  styleClass="mwiShareInputField"
                />
              </div>
              <div>
                <MWIButton
                  name="copy"
                  label={intl.formatMessage({ id: this.state.copyBtnTxt })}
                  onClick={this.copy}
                  id="shareIllustrationCopyButton"
                  title="copy-btn"
                />
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export const mapStateToProps = ({
  app,
  landingPage,
  scenarioTabNavs,
  coverageTabNavs,
  conceptTabNavs,
  clients,
  riders,
  termRiders,
  vitalityStatus,
  report,
  spreadsheet,
  advisorProfile,
  myCases,
  withdrawals,
  deposits,
}) => {
  const curScenario = scenarioTabNavs.activeTabId;
  const model = {
    landingPage,
    scenarioTabNavs,
    coverageTabNavs,
    conceptTabNavs,
    clients,
    riders,
    termRiders,
    vitalityStatus,
    report,
    spreadsheet,
    advisorProfile,
    myCases,
    withdrawals,
    deposits,
  };
  return {
    app,
    model,
    coverageTabNavs,
    advisorProfile,
    coverages: coverageTabNavs[curScenario],
    concepts: conceptTabNavs[curScenario],
    report: report[scenarioTabNavs.activeTabId],
    policyData: scenarioTabNavs.topBars[curScenario],
    clients: clients[curScenario],
    riders: riders[curScenario],
    termRiders: termRiders[curScenario],
    vitalityStatus: vitalityStatus[curScenario],
    withdrawal: withdrawals[curScenario],
    activeScenarioTabId: curScenario,
    deposits: deposits[curScenario],
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveCaseErrorAppAction: bindActionCreators(saveCaseError, dispatch),
  dispatchMWIOnChangeAppAction: bindActionCreators(dispatchMWIOnChange, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShareIllustrationModel));
