import React, { PureComponent } from 'react';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import './landing-page.css';
import PropTypes from 'prop-types';
import VitalityLinks from './vitality-links';

export class ManulifeULDescription extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    locale: PropTypes.string,
  };

  render() {
    return (
      <React.Fragment>
        <div className="product-description margin-bottom10">
          <FormattedHTMLMessage id="landing.mul.productDescription" />
        </div>
        <div className="product-description margin-bottom20">
          <FormattedHTMLMessage id="landing.mul.productDescription2" />
        </div>
        <div className="product-description margin-bottom10">
          <FormattedHTMLMessage id="landing.mul.productDescription4" />
        </div>
        <VitalityLinks />
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(ManulifeULDescription));
