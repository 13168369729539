import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import './life-expectancy-assumption.css';
import { eField, eLocale } from '../../configs';
import {
  isRated,
  deathAtAgeMax,
  calculateMWINumberFieldValue,
  deathAtAgeMin,
  additionalYearsMax,
  additionalYearsMin,
} from '../../utils';
import MWINumberField from '../../components/mwi-calculated-number-field';
import NumberFormat from 'react-number-format';

export class LifeExpectancyAssumption extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    lifeExpectancyAssumption: PropTypes.object,
    setConceptFieldValue: PropTypes.func,
    ratings: PropTypes.object,
    coverageType: PropTypes.string,
    activeScenarioTabId: PropTypes.string,
    client1: PropTypes.object,
    // we need client2 for sprint8
    client2: PropTypes.object,
    equivalentAge: PropTypes.number,
    minAge: PropTypes.number,
    getMax: PropTypes.bool,
  };

  onValueChange = (id, val) => {
    this.props.setConceptFieldValue(id, val, eField.lifeExpectancyAssumption);

    if (id === eField.deathAtAge) {
      // Update additional year(s) here due to issue where the user may not enter the field, therefore
      // no update will trigger
      const maxDAA = deathAtAgeMax(this.props.coverageType, this.props.equivalentAge, this.props.minAge);
      const additionalYears = calculateMWINumberFieldValue(
        this.props.lifeExpectancyAssumption.additionalYears,
        additionalYearsMin(val, maxDAA),
        additionalYearsMax(val, maxDAA),
        this.props.getMax
      );

      this.props.setConceptFieldValue(eField.additionalYears, additionalYears, eField.lifeExpectancyAssumption);
    }
  };

  render() {
    const { lifeExpectancyAssumption, ratings, client1, locale, equivalentAge, coverageType, minAge } = this.props;
    const rating1 = ratings[client1.id];
    const rated = isRated(rating1.permRatingAmount, rating1.permRatingPercent, rating1.tempRatingAmount);
    const maxDAA = deathAtAgeMax(coverageType, equivalentAge, minAge);
    return (
      <React.Fragment>
        <div className="life-expectancy-assumption mwi-margin-bottom24" role="container-lifeexpectancyassumption">
          <div className="p-grid p-grid-no-margin p-col-12">
            <MWINumberField
              styleClass={`death-at-age-wrapper mwi-padding-right12 p-col-3 p-lg-3 p-xl-2 p-sm-5`}
              id={eField.deathAtAge}
              containerClass={'mwi-w100'}
              name={eField.deathAtAge}
              label={<FormattedMessage id="concept.deathAtAge" />}
              onBlur={this.onValueChange}
              value={+lifeExpectancyAssumption.deathAtAge}
              minValue={deathAtAgeMin(rated, lifeExpectancyAssumption.lifeExpectancy)}
              maxValue={maxDAA}
            />
            <div className={`p-col-4 p-xl-3 p-lg-5 p-sm-7 mwi-padding-right12`}>
              <MWINumberField
                styleClass={`illustrate-additional`}
                id={eField.additionalYears}
                containerClass={`${locale === eLocale.fr ? 'fr-label' : 'en-label'}`}
                name={eField.additionalYears}
                label={<FormattedMessage id="concept.illustrateAdditional" />}
                onBlur={this.onValueChange}
                value={+lifeExpectancyAssumption.additionalYears}
                minValue={additionalYearsMin(lifeExpectancyAssumption.deathAtAge, maxDAA)}
                maxValue={additionalYearsMax(lifeExpectancyAssumption.deathAtAge, maxDAA)}
              />
            </div>
            <div className="mwi-control-wrapper p-col-4 p-lg-4 p-xl-3 p-sm-6">
              <div className="mwi-label">
                <label htmlFor="life-expectancy-age">
                  <FormattedMessage id="concept.lifeExpectancyAge" />
                </label>
              </div>
              <div>
                <NumberFormat
                  className="mwi-widget mwi-input number-data number-disabled"
                  value={+lifeExpectancyAssumption.lifeExpectancy}
                  decimalScale={0}
                  allowNegative={false}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(LifeExpectancyAssumption));
