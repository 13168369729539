import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import './summary.css';
import MWISrOnly from '../../core/components/mwi-sr-only';
import { formatValidationsMUL } from './summary-support-functions';
import HelpQuestionMark from '../../core/components/help-question-mark';

const renderOwnerResidenceError = (intl) => {
  return (
    <div className="p-grid p-grid-no-margin">
      <div className="message-box" role="alert">
        <div className="message error" role="alert">
          <div style={{ width: '7%', display: 'inline-flex' }}>
            <i aria-hidden className="material-icons msg-icon">
              {' '}
              cancel{' '}
            </i>
            <MWISrOnly label={intl.formatMessage({ id: 'common.error' })} />
          </div>
          <div className="out-of-date" style={{ width: '93%', display: 'inline-flex', paddingLeft: '5px' }}>
            <FormattedMessage id="summary.residence.error.notselected" />
          </div>
        </div>
      </div>
    </div>
  );
};

const renderBronzeDepositAmount = (isVitalityPlusSelected, isLevelCOI, bronzeDepositAmount) => {
  return (
    isVitalityPlusSelected &&
    isLevelCOI && (
      <div className="mwi-padding-left12 mwi-padding-bottom12">
        <div className="p-grid p-grid-no-margin">
          <div className="p-col-8 bronze-minimum-label">
            <FormattedMessage id="summary.bronzeMinDeposit" />
            {
              <HelpQuestionMark
                containerClass="help"
                overlayId="bronzeDepositTip"
                overlayContent={() => (
                  <div>
                    <FormattedMessage id={'summary.bronzeMinimum.tooltip.text'} />
                  </div>
                )}
              />
            }
          </div>
          <div className="p-col-4 bronze-minimum-amount">
            <span>{bronzeDepositAmount}</span>
          </div>
        </div>
      </div>
    )
  );
};

const renderDepositAmounts = (minimumFirstYearAmount, maximumFirstYearAmount, inforce) => {
  return (
    <div>
      {/* First year min deposit: number */}
      <div className="mwi-padding-left12 mwi-padding-bottom12 mwi-padding-top12">
        <div className="p-grid p-grid-no-margin">
          <div className="p-col-8 label">
            <FormattedMessage id={inforce ? 'summary.currentYearMinDeposit' : 'summary.firstYearMinDeposit'} />
          </div>

          <div className="p-col-4 amount">
            <span>{minimumFirstYearAmount}</span>
          </div>
        </div>
      </div>

      {/* First year max deposit: number */}
      <div className="mwi-padding-left12 mwi-padding-bottom12">
        <div className="p-grid p-grid-no-margin">
          <div className="p-col-8 label">
            <FormattedMessage id={inforce ? 'summary.currentYearMaxDeposit' : 'summary.firstYearMaxDeposit'} />
          </div>
          <div className="p-col-4 amount">
            <span>{maximumFirstYearAmount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SummaryPremiumTabMUL = ({
  intl,
  udmResponse,
  productValidationError,
  depositOption,
  isLevelCOI,
  isVitalityPlusSelected,
  app,
}) => {
  const {
    ownerResidence,
    isValidationError,
    isValidationWarning,
    validationWarnings,
    validationErrors,
    ownerResidenceError,
    maximumFirstYearAmount,
    minimumFirstYearAmount,
    bronzeDepositAmount,
  } = formatValidationsMUL({ ...udmResponse, ...depositOption, ...intl });
  const inforce = app.inforcePolicy;

  return (
    <div className="fixed-panel mwi-padding-right12" tabIndex="0">
      {renderDepositAmounts(minimumFirstYearAmount, maximumFirstYearAmount, inforce)}

      {renderBronzeDepositAmount(isVitalityPlusSelected, isLevelCOI, bronzeDepositAmount)}

      {/* Residence: Province */}
      <div className={`mwi-padding-left12 ${!ownerResidenceError && 'mwi-padding-bottom12'}`}>
        <div className="p-grid p-grid-no-margin">
          <div className="p-col-12 label">
            <FormattedMessage id="summary.residence" />
            {ownerResidence}
          </div>
        </div>
      </div>

      {/* Owner residence error */}
      {ownerResidenceError && renderOwnerResidenceError(intl)}

      <div className="message-box" role="alert">
        {productValidationError && (
          <div className="out-of-date message error">
            <i className="material-icons msg-icon">cancel</i>
            <FormattedMessage
              id="summary.error.vitality"
              values={{
                vitality: (
                  <i>
                    <FormattedMessage id="common.vitality" />
                  </i>
                ),
              }}
            />
          </div>
        )}

        {isValidationError &&
          validationErrors.map((error, index) => {
            return !(error.id === 'DepositOwnerResidence' && ownerResidenceError) ? (
              <div className="message error" key={index} role="alert">
                <i aria-hidden className="material-icons msg-icon">
                  cancel
                </i>
                <MWISrOnly label={intl.formatMessage({ id: 'common.error' })} />
                <div className="out-of-date">{error.message[0].text}</div>
              </div>
            ) : (
              <React.Fragment />
            );
          })}

        {isValidationWarning &&
          validationWarnings.map((warning, index) => {
            return (
              <div className="message warning" key={index}>
                <i aria-hidden className="material-icons msg-icon">
                  warning
                </i>
                <MWISrOnly label={intl.formatMessage({ id: 'common.warning' })} />
                <div className="out-of-date">{warning.message[0].text}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

SummaryPremiumTabMUL.propTypes = {
  productValidationError: PropTypes.bool,
  app: PropTypes.object,
  udmResponse: PropTypes.object,
  intl: PropTypes.object,
  depositOption: PropTypes.object,
  clientSummary: PropTypes.array,
  isLevelCOI: PropTypes.bool,
  isVitalityPlusSelected: PropTypes.bool,
};

export default injectIntl(SummaryPremiumTabMUL);
