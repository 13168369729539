import React from 'react';
import { FormattedMessage } from 'react-intl';
import { eConcept } from '../../core/configs';
import PropTypes from 'prop-types';

export const ConceptDescription = (props) => {
  const { concept } = props;
  return (
    <span>
      {/* for Estate bond use this format */}
      {concept === eConcept.CEB && (
        <React.Fragment>
          <FormattedMessage id={'concept.description.EstateBond.1'} />
          <br />
          <ul className="ceb-list">
            <br />
            <li>
              <FormattedMessage id={'concept.description.EstateBond.2'} />
              <br />
            </li>
            <li>
              <FormattedMessage id={'concept.description.EstateBond.3'} />
              <br />
            </li>
          </ul>
          <br />
          <FormattedMessage id={'concept.description.EstateBond.4'} />
        </React.Fragment>)}
      {concept === eConcept.PEB && (
        <React.Fragment>
          <FormattedMessage id={'concept.description.PEB.1'} />
          <br />
          <FormattedMessage id={'concept.description.PEB.2'} />
        </React.Fragment>)}
      {concept !== eConcept.CEB && concept !== eConcept.PEB &&
        <FormattedMessage id={`concept.description.${concept ? concept : 'None'}`} />
      }
    </span>
  );
};
ConceptDescription.propTypes = {
  concept: PropTypes.string
};
