import React from 'react';
import PropTypes from "prop-types";

const PaginationHelper = (props) => {
  PaginationHelper.propTypes = {
    rangeStart: PropTypes.number,
    rangeEnd: PropTypes.number,
    maxRecords: PropTypes.number,
    intl: PropTypes.object
  };
  const { rangeStart, rangeEnd, maxRecords, intl } = props;
  const showingLabel = intl.formatMessage({
    id: 'dashboard.table.footer.showing'
  });
  const ofLabel = intl.formatMessage({
    id: 'dashboard.table.footer.of'
  });
  const recordLabel = intl.formatMessage({
    id: 'dashboard.table.footer.records'
  });
  return (
    <div style={{ fontWeight: '300', fontSize: '16px' }}>
      {showingLabel} {rangeStart}-{rangeEnd} {ofLabel} {maxRecords} {recordLabel}
    </div>
  );
};

export default PaginationHelper;
