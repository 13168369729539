import { MWI_ON_CHANGE, COMMON_INITIALIZE } from '../core/actionTypes';

const initState = {
  isOpen: false,
  isLoading: false,
  errors: {},
  startNewIllustration: false
};

export default function myCasesReducer(state, action) {
  state = state === undefined ? initState : state;
  switch (action.type) {
    case COMMON_INITIALIZE: {
      return initState;
    }

    case MWI_ON_CHANGE: {
      const { target, field, value } = action.payload;

      if (target === 'mycases') {
        return {
          ...state,
          [field]: value
        };
      }

      return state;
    }

    default:
      return state;
  }
}
