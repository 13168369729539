import {
  eRider,
  eReportType,
  eCoverageType,
  eCoverageOption,
  eDepositFrequency,
  eReport,
  eReportLayoutType,
  eLang,
  eSpreadsheetFields,
  eDefault,
  eCompanyType,
  eConceptDividendScale,
  eLoanAmountSelection,
  eField,
  eProduct,
  eSpecialQuote,
  ePremiumDuration,
  eBankLoanRate,
  eDeathType,
  eInsuranceType,
  eCOIType,
  eCostDuration,
} from '../configs';
import { getTermRiderDuration } from '../../core/utils';
import { getPerformaxTermRiderDuration } from '../../core/utils';

export const coverage = 'Coverage.1';
const illustraitonStartDate = '2021-11-20';
const illustrationEndDate = '0001-01-01';

export const scenario0 = 'Scenario.0';
export const scenario1 = 'Scenario.1';
export const scenario2 = 'Scenario.2';

export const client1Mock = {
  id: 'Client.1',
  sex: 'Male',
  age: 35,
  healthstyle: '2',
  coverageTabId: [coverage],
  errors: {},
};
export const client2Mock = {
  id: 'Client.2',
  sex: 'Female',
  age: 35,
  healthstyle: '2',
  coverageTabId: [coverage],
  errors: {},
};
export const child1Mock = { id: 'Child.1', sex: 'Male', age: 5, healthstyle: '3', coverageTabId: [coverage] };
export const child2Mock = { id: 'Child.2', sex: 'Male', age: 10, healthstyle: '3', coverageTabId: [coverage] };
export const testErrorObj = { id: 'MaxWithdrawal' };
export const testError = 'test error';

export const udmResponse = {
  status: 'Success',
  type: 'Validation',
  requestId: 'C4531BE25D',
  illustration: {
    illustrationId: 'EA70DACF72',
    language: 'English',
    party: [
      {
        partyId: 'Client.1',
        birthdate: null,
        age: 35,
        gender: 'Male',
        firstName: '',
        lastName: '',
      },
    ],
    policy: {
      premiumOffset: {
        id: 'CE4CD9D8BE',
        type: 'Earliest',
      },
      calculationVectors: [],
      coverage: [
        {
          coverageId: coverage,
          equivalentAge: 20,
          costOfInsurance: eCOIType.levelToAge100,
          lifeRating: [
            {
              ratingPercent: 100,
              ratingPermDollar: 0,
              ratingTempDollar: 0,
            },
          ],
        },
      ],
      depositAmount: 28.31,
      duration: 45,
      minimumModalPremium: 28.31,
      policyFee: 5.1,
      product: eProduct.PAR,
      life: [
        {
          partyId: 'Client.1',
          totalInsuredCoverage: 500000,
          coverageSummary: [
            {
              product: 'TDW',
              totalMinimumModalPremium: 3.01,
            },
          ],
        },
        {
          partyId: 'Child.1',
          coverageSummary: [
            {
              product: 'CPR',
              totalMinimumModalPremium: 2.5,
            },
          ],
        },
      ],
    },
    concept: {
      lifeExpectancy: {
        age: 83,
        plusDuration: 5,
        deathAge: 83,
        endDate: '2107-05-10',
      },
      loanInformation: {
        bankLoanRate: eBankLoanRate.min,
      },
    },
    user: {},
  },
  udmIllustration: {
    illustrationId: 'EA70DACF72',
    language: 'English',
    party: [
      {
        partyId: 'Client.1',
        birthdate: null,
        age: 35,
        gender: 'Male',
        firstName: '',
        lastName: '',
      },
    ],
    policy: {
      premiumOffset: {
        id: 'CE4CD9D8BE',
        type: 'Earliest',
      },
      coverage: [
        {
          coverageId: coverage,
          equivalentAge: 20,
          lifeRating: [
            {
              ratingPercent: 100,
              ratingPermDollar: 0,
              ratingTempDollar: 0,
            },
          ],
        },
      ],
      depositAmount: 28.31,
      duration: 45,
      minimumModalPremium: 28.31,
      policyFee: 5.1,
      product: eProduct.PAR,
      life: [
        {
          partyId: 'Client.1',
          totalInsuredCoverage: 500000,
          coverageSummary: [
            {
              product: 'TDW',
              totalMinimumModalPremium: 3.01,
            },
          ],
        },
        {
          partyId: 'Child.1',
          coverageSummary: [
            {
              product: 'CPR',
              totalMinimumModalPremium: 2.5,
            },
          ],
        },
      ],
    },
    concept: {
      lifeExpectancy: {
        age: 83,
        plusDuration: 5,
        deathAge: 83,
        endDate: '2107-05-10',
      },
      loanInformation: {
        bankLoanRate: eBankLoanRate.min,
      },
    },
    user: {},
  },
  validations: {},
};
export const udmResponseSpreadsheet = {
  status: 'success',
  illustration: {
    policy: {
      calculationVectors: [
        {
          type: 'PolicyYear',
          basisType: 'Base',
          title: 'Year',
          spreadsheetRank: 1,
          values: [1, 2, 3, 4, 5],
        },
        {
          type: 'ScheduledPremium',
          basisType: 'Base',
          title: 'TotalAnnualPremium',
          spreadsheetRank: 2,
          values: [100.32, 100.32, 430.56, 566.78, 200.12],
        },
        {
          type: 'IllustratedDepositOptionPayments',
          basisType: 'Base',
          title: 'Illustrated annual deposit option payment',
          spreadsheetRank: 3,
          values: [25, 25, 25, 0, 0],
        },
      ],
    },
  },
};

export const udmMULBasicResponse = {
  status: 'Success',
  illustration: {
    policy: {
      calculationVectors: [
        {
          type: 'PolicyYear',
          basisType: 'Base',
          title: 'Year',
          rank: 1,
          interestAssumption: 'Specified',
          values: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
            30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
          ],
        },
        {
          type: 'PrimaryAttainedAge',
          basisType: 'Base',
          title: 'Age',
          rank: 2,
          interestAssumption: 'Specified',
          values: [
            46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,
            73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
            100,
          ],
        },
        {
          type: 'RequestedAnnualDeposit',
          basisType: 'Base',
          title: 'Requested annual deposit',
          rank: 3,
          interestAssumption: 'Specified',
          values: [
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
          ],
        },
        {
          type: 'ScheduledAnnualPremium',
          basisType: 'Base',
          title: 'Annual deposit',
          rank: 4,
          interestAssumption: 'Specified',
          values: [
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
          ],
        },
        {
          type: 'GrossAccountValue',
          basisType: 'Base',
          title: 'Account value',
          rank: 5,
          interestAssumption: 'Specified',
          values: [
            0.16, 0.32, 0.48, 0.64, 0.8, 0.96, 1.12, 1.28, 1.44, 1.6, 1.76, 1.92, 2.08, 2.24, 2.4, 2.56, 2.72, 2.88,
            3.04, 3.2, 3.36, 3.52, 3.68, 3.84, 4, 4.16, 4.32, 4.48, 4.64, 4.8, 4.96, 5.12, 5.28, 5.44, 5.6, 5.76, 5.92,
            6.08, 6.24, 6.4, 6.56, 6.72, 6.88, 7.04, 7.2, 7.36, 7.52, 7.68, 7.84, 8, 8.16, 8.32, 8.48, 8.64, 8.8,
          ],
        },
        {
          type: 'TotalCashValue',
          basisType: 'Base',
          title: 'Cash value',
          rank: 6,
          interestAssumption: 'Specified',
          values: [
            0.15, 0.31, 0.47, 0.63, 0.79, 0.96, 1.12, 1.28, 1.44, 1.6, 1.76, 1.92, 2.08, 2.24, 2.4, 2.56, 2.72, 2.88,
            3.04, 3.2, 3.36, 3.52, 3.68, 3.84, 4, 4.16, 4.32, 4.48, 4.64, 4.8, 4.96, 5.12, 5.28, 5.44, 5.6, 5.76, 5.92,
            6.08, 6.24, 6.4, 6.56, 6.72, 6.88, 7.04, 7.2, 7.36, 7.52, 7.68, 7.84, 8, 8.16, 8.32, 8.48, 8.64, 8.8,
          ],
        },
        {
          type: 'TotalDeathBenefit',
          basisType: 'Base',
          title: 'Total death benefit',
          rank: 7,
          interestAssumption: 'Specified',
          values: [
            500000.16, 500000.32, 500000.48, 500000.64, 500000.8, 500000.96, 500001.12, 500001.28, 500001.44, 500001.6,
            500001.76, 500001.92, 500002.08, 500002.24, 500002.4, 500002.56, 500002.72, 500002.88, 500003.04, 500003.2,
            500003.36, 500003.52, 500003.68, 500003.84, 500004, 500004.16, 500004.32, 500004.48, 500004.64, 500004.8,
            500004.96, 500005.12, 500005.28, 500005.44, 500005.6, 500005.76, 500005.92, 500006.08, 500006.24, 500006.4,
            500006.56, 500006.72, 500006.88, 500007.04, 500007.2, 500007.36, 500007.52, 500007.68, 500007.84, 500008,
            500008.16, 500008.32, 500008.48, 500008.64, 500008.8,
          ],
        },
        {
          type: 'SideAccountBalance',
          basisType: 'Base',
          title: 'Side account balance',
          rank: 8,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'Withdrawal',
          basisType: 'Base',
          title: 'Requested before tax withdrawal',
          rank: 9,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'BeforeTaxWithdrawalAmount',
          basisType: 'Base',
          title: 'Withdrawal',
          rank: 10,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'FaceAmountAdjustedWithdrawal',
          basisType: 'Base',
          title: 'Face amount adjusted for withdrawal',
          rank: 11,
          interestAssumption: 'Specified',
          values: [
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000,
          ],
        },
        {
          type: 'FaceAmount',
          basisType: 'Base',
          title: 'Coverage face amount',
          rank: 12,
          interestAssumption: 'Specified',
          values: [
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000,
          ],
        },
        {
          type: 'PermanentInsuranceCost',
          basisType: 'Base',
          title: 'Permanent insurance costs',
          rank: 14,
          interestAssumption: 'Specified',
          values: [
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
          ],
        },
        {
          type: 'CoverageDeduction',
          basisType: 'Base',
          title: 'Total cost of insurance',
          rank: 15,
          interestAssumption: 'Specified',
          values: [
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
          ],
        },
        {
          type: 'DepositLoad',
          basisType: 'Base',
          title: 'Deposit tax charge (Deposit load)',
          rank: 16,
          interestAssumption: 'Specified',
          values: [
            130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52,
            130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52,
            130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52,
            130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52, 130.52,
            130.52, 130.52, 130.52,
          ],
        },
        {
          type: 'TotalAnnualPolicyCost',
          basisType: 'Base',
          title: 'Total policy cost',
          rank: 17,
          interestAssumption: 'Specified',
          values: [
            6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8,
            6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8,
            6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8,
            6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8, 6525.8,
            6525.8, 6525.8, 6525.8,
          ],
        },
        {
          type: 'MaximumAnnualDepositOptionLimit',
          basisType: 'Base',
          title: 'Maximum deposit',
          rank: 19,
          interestAssumption: 'Specified',
          values: [
            21970.13, 41808.68, 59449.73, 77090.81, 94731.88, 112373.49, 130014.61, 86190.48, 76979.07, 67601.7,
            79034.26, 92388.51, 106030.4, 119950.12, 127766.7, 124837.69, 121881.41, 118904.15, 115911.82, 112912.42,
            109916.31, 106931.61, 103971.03, 101044.1, 98165.65, 95349.62, 92610.03, 89964.47, 87432.17, 85031.97,
            82787.39, 80726.63, 78880.18, 77284.34, 75985.39, 75039.85, 74519.01, 74516.56, 75153.92, 76594.35,
            79060.53, 82857.58, 88418.6, 96358.35, 107571.67, 101771.07, 96182.61, 90769.46, 85477.37, 80217.45,
            74850.37, 69137.81, 62663.15, 54669.11, 43711.88,
          ],
        },
        {
          type: 'TaxPayableOnCancellationCashValue',
          basisType: 'Base',
          title: 'Tax on cash value',
          rank: 20,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 3.68, 3.76, 3.84, 3.92, 4, 4.08, 4.16, 4.24, 4.32, 4.4,
          ],
        },
        {
          type: 'TaxablePortionOfCancellationCashValue',
          basisType: 'Base',
          title: 'Taxable portion of cash value',
          rank: 21,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 7.36, 7.52, 7.68, 7.84, 8, 8.16, 8.32, 8.48, 8.64, 8.8,
          ],
        },
        {
          type: 'CancellationCashValueAfterTax',
          basisType: 'Base',
          title: 'Cash value after tax',
          rank: 22,
          interestAssumption: 'Specified',
          values: [
            0.15, 0.31, 0.47, 0.63, 0.79, 0.96, 1.12, 1.28, 1.44, 1.6, 1.76, 1.92, 2.08, 2.24, 2.4, 2.56, 2.72, 2.88,
            3.04, 3.2, 3.36, 3.52, 3.68, 3.84, 4, 4.16, 4.32, 4.48, 4.64, 4.8, 4.96, 5.12, 5.28, 5.44, 5.6, 5.76, 5.92,
            6.08, 6.24, 6.4, 6.56, 6.72, 6.88, 7.04, 7.2, 3.68, 3.76, 3.84, 3.92, 4, 4.08, 4.16, 4.24, 4.32, 4.4,
          ],
        },
        {
          type: 'ScheduledPremium',
          basisType: 'Base',
          title: 'Deposit to the policy',
          rank: 23,
          interestAssumption: 'Specified',
          values: [
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
            6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96, 6525.96,
          ],
        },
        {
          type: 'CashValueIncrease',
          basisType: 'Base',
          title: 'Increase in cash value',
          rank: 24,
          interestAssumption: 'Specified',
          values: [
            0.15, 0.16, 0.16, 0.16, 0.16, 0.17, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16,
            0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16,
            0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16, 0.16,
            0.16,
          ],
        },
        {
          type: 'PolicyInterestRate',
          basisType: 'Base',
          title: 'Policy interest rate (%)',
          rank: 25,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'TotalNetWithdrawal',
          basisType: 'Base',
          title: 'Net withdrawal',
          rank: 26,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'TaxablePortionOfWithdrawal',
          basisType: 'Base',
          title: 'Taxable withdrawal',
          rank: 27,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'TaxableGainOnWithdrawal',
          basisType: 'Base',
          title: 'Tax on withdrawal',
          rank: 28,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'MarginalTaxRate',
          basisType: 'Base',
          title: 'Marginal tax rate (%)',
          rank: 29,
          interestAssumption: 'Specified',
          values: [
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50,
          ],
        },
        {
          type: 'DepositToSideAccount',
          basisType: 'Base',
          title: 'Deposit to the side account',
          rank: 30,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'TransferToSideAccount',
          basisType: 'Base',
          title: 'Transfer to side account',
          rank: 31,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'TransferFromSideAccount',
          basisType: 'Base',
          title: 'Transfer from side account',
          rank: 32,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'SideAccountInterest',
          basisType: 'Base',
          title: 'Side account interest',
          rank: 33,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'TaxOnSideAccount',
          basisType: 'Base',
          title: 'Tax on side account',
          rank: 34,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'SideAccountInterestRate',
          basisType: 'Base',
          title: 'Side acount interest rate (%)',
          rank: 35,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'AdjustedCostBasis',
          basisType: 'Base',
          title: 'Adjusted cost basis',
          rank: 36,
          interestAssumption: 'Specified',
          values: [
            6230.96, 12353.66, 18386.38, 24322.91, 30162.19, 35894.24, 41505.3, 46987.02, 52327.46, 57509.7, 62517.11,
            67330.87, 71930.32, 76293.4, 80389.45, 84191.4, 87765.32, 91090.6, 94146.73, 96910.47, 99356.62, 101458.75,
            103189.98, 104526.49, 105439.59, 105902.3, 105892.49, 105383.16, 104353.31, 102783.36, 100655.78, 97953.49,
            94666.38, 90783.56, 86299.52, 81210.54, 75516.69, 69220.28, 62327.5, 54847.08, 46787.76, 38165.05, 28992.88,
            19289.68, 9077, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'CdaCredit',
          basisType: 'Base',
          title: 'Capital dividend account credit',
          rank: 37,
          interestAssumption: 'Specified',
          values: [
            493769.2, 487646.66, 481614.1, 475677.73, 469838.61, 464106.72, 458495.82, 453014.26, 447673.98, 442491.9,
            437484.65, 432671.05, 428071.76, 423708.84, 419612.95, 415811.16, 412237.4, 408912.28, 405856.31, 403092.73,
            400646.74, 398544.77, 396813.7, 395477.35, 394564.41, 394101.86, 394111.83, 394621.32, 395651.33, 397221.44,
            399349.18, 402051.63, 405338.9, 409221.88, 413706.08, 418795.22, 424489.23, 430785.8, 437678.74, 445159.32,
            453218.8, 461841.67, 471014, 480717.36, 490930.2, 500007.36, 500007.52, 500007.68, 500007.84, 500008,
            500008.16, 500008.32, 500008.48, 500008.64, 500008.8,
          ],
        },
        {
          type: 'NetCostOfPureInsurance',
          basisType: 'Base',
          title: 'Net cost of pure insurance',
          rank: 38,
          interestAssumption: 'Specified',
          values: [
            295, 403.26, 493.24, 589.43, 686.68, 793.91, 914.9, 1044.24, 1185.52, 1343.72, 1518.55, 1712.2, 1926.51,
            2162.88, 2429.91, 2724.01, 2952.04, 3200.68, 3469.83, 3762.22, 4079.81, 4423.83, 4794.73, 5189.45, 5612.86,
            6063.25, 6535.77, 7035.29, 7555.81, 8095.91, 8653.54, 9228.25, 9813.07, 10408.78, 11010, 11614.94, 12219.81,
            12822.37, 13418.74, 14006.38, 14585.28, 15148.67, 15698.13, 16229.16, 16738.64, 17224.64, 17680.96,
            18099.68, 18470.91, 18774.76, 18981.63, 19036.45, 18842.41, 18220.84, 16826.63,
          ],
        },
        {
          type: 'IrrOnTotalCashValue',
          basisType: 'Base',
          title: 'IRR on cash value %',
          rank: 39,
          interestAssumption: 'Specified',
          values: [
            -100, -100, -99.99, -99.99, -99.99, -99.99, -99.98, -99.98, -99.98, -99.98, -99.97, -99.97, -99.97, -99.97,
            -99.96, -99.96, -99.96, -99.96, -99.95, -99.95, -99.95, -99.95, -99.94, -99.94, -99.94, -99.94, -99.93,
            -99.93, -99.93, -99.93, -99.92, -99.92, -99.92, -99.92, -99.91, -99.91, -99.91, -99.91, -99.9, -99.9, -99.9,
            -99.9, -99.89, -99.89, -99.89, -99.89, -99.88, -99.88, -99.88, -99.88, -99.88, -99.87, -99.87, -99.87,
            -99.87,
          ],
        },
        {
          type: 'IrrOnTotalDeathBenefit',
          basisType: 'Base',
          title: 'IRR on death benefit %',
          rank: 40,
          interestAssumption: 'Specified',
          values: [
            7561.71, 726.74, 286.65, 164.03, 110.36, 81.13, 63.04, 50.86, 42.17, 35.69, 30.69, 26.73, 23.53, 20.89,
            18.69, 16.82, 15.22, 13.84, 12.64, 11.58, 10.65, 9.82, 9.08, 8.41, 7.81, 7.26, 6.76, 6.31, 5.9, 5.51, 5.16,
            4.84, 4.54, 4.26, 4, 3.76, 3.53, 3.32, 3.13, 2.94, 2.77, 2.61, 2.45, 2.31, 2.17, 2.04, 1.92, 1.8, 1.69,
            1.59, 1.49, 1.39, 1.3, 1.22, 1.13,
          ],
        },
      ],
    },
  },
  validations: {
    status: 'None',
    validation: [],
  },
};

export const udmMULSpreadsheetBasicResponse = {
  status: 'Success',
  illustration: {
    policy: {
      calculationVectors: [
        {
          type: 'PolicyYear',
          basisType: 'Base',
          title: 'Year',
          rank: 1,
          interestAssumption: 'Specified',
          values: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
            30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
          ],
        },
        {
          type: 'PrimaryAttainedAge',
          basisType: 'Base',
          title: 'Age',
          rank: 2,
          interestAssumption: 'Specified',
          values: [
            46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,
            73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
            100,
          ],
        },
        {
          type: 'RequestedAnnualDeposit',
          basisType: 'Base',
          title: 'Requested annual deposit',
          rank: 3,
          interestAssumption: 'Specified',
          values: [
            30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
            30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
            30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
            30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
          ],
        },
        {
          type: 'ScheduledAnnualPremium',
          basisType: 'Base',
          title: 'Annual deposit',
          rank: 4,
          interestAssumption: 'Specified',
          values: [
            31000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
            30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
            30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
            30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000,
          ],
        },
        {
          type: 'GrossAccountValue',
          basisType: 'Base',
          title: 'Account value',
          rank: 5,
          interestAssumption: 'Specified',
          values: [
            19811.81, 40346.85, 66932.11, 92023.68, 114758.6, 137493.52, 160228.44, 182963.36, 205698.28, 228433.2,
            250515.56, 272597.92, 291694, 302494.1, 309759.86, 315608.26, 321018.57, 326475.61, 332244.16, 338495.12,
            341648.46, 344664.58, 346713.64, 349357.61, 352551.82, 356310.27, 360686.29, 365766.7, 371671.43, 378554.07,
            378177.22, 375704.13, 371469.59, 365604.7, 358088.35, 351693.07, 350565.36, 355120.93, 365383.43, 381937.54,
            406018.14, 439727.48, 486455.62, 551653.37, 644290.09, 723693.67, 798576.72, 869111.04, 935415.27,
            997520.95, 1055323.05, 1108483, 1154896.54, 1177799.72, 1200702.45,
          ],
        },
        {
          type: 'TotalCashValue',
          basisType: 'Base',
          title: 'Cash value',
          rank: 6,
          interestAssumption: 'Specified',
          values: [
            18821.22, 38732.98, 64924.15, 90183.21, 113611.01, 137493.52, 160228.44, 182963.36, 205698.28, 228433.2,
            250515.56, 272597.92, 291694, 302494.1, 309759.86, 315608.26, 321018.57, 326475.61, 332244.16, 338495.12,
            341648.46, 344664.58, 346713.64, 349357.61, 352551.82, 356310.27, 360686.29, 365766.7, 371671.43, 378554.07,
            378177.22, 375704.13, 371469.59, 365604.7, 358088.35, 351693.07, 350565.36, 355120.93, 365383.43, 381937.54,
            406018.14, 439727.48, 486455.62, 551653.37, 644290.09, 723693.67, 798576.72, 869111.04, 935415.27,
            997520.95, 1055323.05, 1108483, 1154896.54, 1177799.72, 1200702.45,
          ],
        },
        {
          type: 'TotalDeathBenefit',
          basisType: 'Base',
          title: 'Total death benefit',
          rank: 7,
          interestAssumption: 'Specified',
          values: [
            616509.55, 633621.79, 655775.8, 676685.09, 695630.52, 714575.96, 733521.41, 752466.84, 771412.28, 790357.73,
            808759.37, 827161.03, 843074.15, 852074.08, 858128.77, 863002.35, 867510.87, 872058.33, 876865.37,
            882074.42, 884702.15, 887215.54, 888927.92, 891131.23, 893793.07, 896925.11, 900571.79, 904805.46,
            909726.07, 915461.6, 915147.56, 913086.65, 909557.87, 904670.46, 898406.84, 851693.07, 850565.36, 855120.93,
            865383.43, 881937.54, 906018.14, 939727.48, 986455.62, 1051653.37, 1144290.09, 1223693.67, 1298576.72,
            1369111.04, 1435415.27, 1497520.95, 1555323.05, 1608483, 1654896.54, 1677799.72, 1700702.45,
          ],
        },
        {
          type: 'SideAccountBalance',
          basisType: 'Base',
          title: 'Side account balance',
          rank: 8,
          interestAssumption: 'Specified',
          values: [
            4084.81, 6331.63, 2404.75, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3049.27, 14563.82, 29684.84, 46252.15, 63266.49,
            80233.14, 96881.93, 113038.46, 130860.36, 148822.28, 167800.99, 186172.65, 203982.84, 221217.28, 237821.55,
            253707.05, 268751.41, 282797.9, 300913.58, 321168.28, 343220.38, 366936.1, 392336.99, 422236.99, 446761.92,
            465487.58, 478389.85, 484872.11, 483674.27, 472651.19, 448343.62, 405189.51, 334036.45, 276386.59,
            223349.52, 174749.93, 130466.76, 90467.82, 54860.29, 23989.65, 0, 0, 0,
          ],
        },
        {
          type: 'NetWithdrawal',
          basisType: 'Base',
          title: 'Requested after tax withdrawal',
          rank: 9,
          interestAssumption: 'Specified',
          values: [
            0, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
            100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
            100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
          ],
        },
        {
          type: 'BeforeTaxWithdrawalAmount',
          basisType: 'Base',
          title: 'Withdrawal',
          rank: 10,
          interestAssumption: 'Specified',
          values: [
            0, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
            100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
            100, 100, 100, 100, 100, 100, 100, 100, 100, 101.04, 101.54, 101.99,
          ],
        },
        {
          type: 'FaceAmountAdjustedWithdrawal',
          basisType: 'Base',
          title: 'Face amount adjusted for withdrawal',
          rank: 11,
          interestAssumption: 'Specified',
          values: [
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000,
          ],
        },
        {
          type: 'FaceAmount',
          basisType: 'Base',
          title: 'Coverage face amount',
          rank: 12,
          interestAssumption: 'Specified',
          values: [
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000, 500000,
            500000, 500000, 500000,
          ],
        },
        {
          type: 'CoverageDeduction',
          basisType: 'Base',
          title: 'Term rider costs - 1 -',
          rank: 13,
          coverageId: 'Rider.2',
          coverageProduct: 'Term10',
          coverageIndicatorCode: 'TermRider',
          interestAssumption: 'Specified',
          values: [
            140.4, 140.4, 140.4, 140.4, 140.4, 140.4, 140.4, 140.4, 140.4, 140.4, 792.96, 792.96, 792.96, 792.96,
            792.96, 792.96, 792.96, 792.96, 792.96, 792.96, 2258.52, 2258.52, 2258.52, 2258.52, 2258.52, 2258.52,
            2258.52, 2258.52, 2258.52, 2258.52, 5530.2, 5530.2, 5530.2, 5530.2, 5530.2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'FaceAmount',
          basisType: 'Base',
          title: 'Term rider amount of insurance - 1 -',
          rank: 13,
          coverageId: 'Rider.2',
          coverageProduct: 'Term10',
          coverageIndicatorCode: 'TermRider',
          interestAssumption: 'Specified',
          values: [
            100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000,
            100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000,
            100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 100000, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'PermanentInsuranceCost',
          basisType: 'Base',
          title: 'Permanent insurance costs',
          rank: 14,
          interestAssumption: 'Specified',
          values: [
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
          ],
        },
        {
          type: 'CoverageDeduction',
          basisType: 'Base',
          title: 'Total cost of insurance',
          rank: 15,
          interestAssumption: 'Specified',
          values: [
            6535.68, 6535.68, 6535.68, 6535.68, 6535.68, 6535.68, 6535.68, 6535.68, 6535.68, 6535.68, 7188.24, 7188.24,
            7188.24, 7188.24, 7188.24, 7188.24, 7188.24, 7188.24, 7188.24, 7188.24, 8653.8, 8653.8, 8653.8, 8653.8,
            8653.8, 8653.8, 8653.8, 8653.8, 8653.8, 8653.8, 11925.48, 11925.48, 11925.48, 11925.48, 11925.48, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
            6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28, 6395.28,
          ],
        },
        {
          type: 'DepositLoad',
          basisType: 'Base',
          title: 'Deposit tax charge (Deposit load)',
          rank: 16,
          interestAssumption: 'Specified',
          values: [
            538.3, 553.06, 676.54, 648.1, 600, 600, 600, 600, 600, 600, 600, 600, 537.01, 367.71, 295.58, 266.65,
            257.71, 258.67, 265.02, 274.87, 241.56, 238.76, 218.43, 230.57, 241.8, 253.31, 265.91, 280.29, 297.11,
            317.07, 235.69, 192.91, 156.96, 123.69, 89.98, 0, 107.5, 223.49, 339.95, 468.35, 621.96, 818.46, 1084.15,
            1461.08, 2021.06, 1751, 1658.74, 1569.99, 1483.66, 1397.98, 1310.15, 1215.41, 1079.79, 600, 600,
          ],
        },
        {
          type: 'TotalAnnualPolicyCost',
          basisType: 'Base',
          title: 'Total policy cost',
          rank: 17,
          interestAssumption: 'Specified',
          values: [
            7103.38, 7118.14, 7241.62, 7213.18, 7165.08, 7165.08, 7165.08, 7165.08, 7165.08, 7165.08, 7817.64, 7817.64,
            7754.65, 7585.35, 7513.22, 7484.29, 7475.35, 7476.31, 7482.66, 7492.51, 8924.76, 8921.96, 8872.23, 8884.37,
            8895.6, 8907.11, 8919.71, 8934.09, 8950.91, 8970.87, 12161.17, 12118.39, 12082.44, 12049.17, 12015.46,
            6395.28, 6502.78, 6618.77, 6735.23, 6863.63, 7017.24, 7213.74, 7479.43, 7856.36, 8416.34, 8146.28, 8054.02,
            7965.27, 7878.94, 7793.26, 7705.43, 7610.69, 7475.07, 6995.28, 6995.28,
          ],
        },
        {
          type: 'CprDeduction',
          basisType: 'Base',
          title: 'Child protection rider costs',
          rank: 18,
          interestAssumption: 'Specified',
          values: [
            29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4, 29.4,
            29.4, 29.4, 29.4, 29.4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'MaximumAnnualDepositOptionLimit',
          basisType: 'Base',
          title: 'Maximum deposit',
          rank: 19,
          interestAssumption: 'Specified',
          values: [
            26915.19, 27653.18, 33826.88, 37211.88, 44492.72, 56050.2, 69966.18, 86241.76, 73481.11, 61383.44, 48960.9,
            37569.77, 26850.73, 18385.45, 14778.98, 13332.69, 12885.66, 12933.35, 13251.21, 13743.47, 12078.1, 11938.08,
            10921.29, 11528.34, 12089.81, 12665.56, 13295.73, 14014.5, 14855.64, 15853.51, 11784.32, 9645.3, 7847.9,
            6184.28, 4499.11, 0, 5375.07, 11174.34, 16997.73, 23417.74, 31097.84, 40923.08, 54207.57, 73054.11,
            101053.06, 87549.86, 82937.07, 78499.59, 74183.17, 69898.94, 65507.53, 60770.64, 55271.66, 47363.69,
            36491.95,
          ],
        },
        {
          type: 'TaxPayableOnCancellationCashValue',
          basisType: 'Base',
          title: 'Tax on cash value',
          rank: 20,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4368.72, 10006.82, 15719.7, 21402.32, 27013.55, 31927.23,
          ],
        },
        {
          type: 'TaxablePortionOfCancellationCashValue',
          basisType: 'Base',
          title: 'Taxable portion of cash value',
          rank: 21,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8737.43, 20013.63, 31439.39, 42804.63, 54027.1, 63854.46,
          ],
        },
        {
          type: 'CancellationCashValueAfterTax',
          basisType: 'Base',
          title: 'Cash value after tax',
          rank: 22,
          interestAssumption: 'Specified',
          values: [
            18821.22, 38732.98, 64924.15, 90183.21, 113611.01, 137493.52, 160228.44, 182963.36, 205698.28, 228433.2,
            250515.56, 272597.92, 291694, 302494.1, 309759.86, 315608.26, 321018.57, 326475.61, 332244.16, 338495.12,
            341648.46, 344664.58, 346713.64, 349357.61, 352551.82, 356310.27, 360686.29, 365766.7, 371671.43, 378554.07,
            378177.22, 375704.13, 371469.59, 365604.7, 358088.35, 351693.07, 350565.36, 355120.93, 365383.43, 381937.54,
            406018.14, 439727.48, 486455.62, 551653.37, 644290.09, 723693.67, 798576.72, 869111.04, 935415.27,
            993152.23, 1045316.23, 1092763.3, 1133494.22, 1150786.17, 1168775.22,
          ],
        },
        {
          type: 'ScheduledPremium',
          basisType: 'Base',
          title: 'Deposit to the policy',
          rank: 23,
          interestAssumption: 'Specified',
          values: [
            26915.19, 27653.18, 33826.88, 32404.75, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 26850.73,
            18385.45, 14778.98, 13332.69, 12885.66, 12933.35, 13251.21, 13743.47, 12078.1, 11938.08, 10921.29, 11528.34,
            12089.81, 12665.56, 13295.73, 14014.5, 14855.64, 15853.51, 11784.32, 9645.3, 7847.9, 6184.28, 4499.11, 0,
            5375.07, 11174.34, 16997.73, 23417.74, 31097.84, 40923.08, 54207.57, 73054.11, 101053.06, 87549.86,
            82937.07, 78499.59, 74183.17, 69898.94, 65507.53, 60770.64, 53989.65, 30000, 30000,
          ],
        },
        {
          type: 'CashValueIncrease',
          basisType: 'Base',
          title: 'Increase in cash value',
          rank: 24,
          interestAssumption: 'Specified',
          values: [
            18821.22, 19911.76, 26191.17, 25259.06, 23427.8, 23882.51, 22734.92, 22734.92, 22734.92, 22734.92, 22082.36,
            22082.36, 19096.08, 10800.1, 7265.76, 5848.4, 5410.31, 5457.04, 5768.55, 6250.96, 3153.34, 3016.12, 2049.06,
            2643.97, 3194.21, 3758.45, 4376.02, 5080.41, 5904.73, 6882.64, -376.85, -2473.09, -4234.54, -5864.89,
            -7516.35, -6395.28, -1127.71, 4555.57, 10262.5, 16554.11, 24080.6, 33709.34, 46728.14, 65197.75, 92636.72,
            79403.58, 74883.05, 70534.32, 66304.23, 62105.68, 57802.1, 53159.95, 46413.54, 22903.18, 22902.73,
          ],
        },
        {
          type: 'PolicyInterestRate',
          basisType: 'Base',
          title: 'Policy interest rate (%)',
          rank: 25,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'TotalNetWithdrawal',
          basisType: 'Base',
          title: 'Net withdrawal',
          rank: 26,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 100, 100, 100, 100, 100, 100, 100, 100, 100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 99.99, 100, 100,
          ],
        },
        {
          type: 'TaxablePortionOfWithdrawal',
          basisType: 'Base',
          title: 'Taxable withdrawal',
          rank: 27,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2.1, 3.09, 3.99,
          ],
        },
        {
          type: 'TaxableGainOnWithdrawal',
          basisType: 'Base',
          title: 'Tax on withdrawal',
          rank: 28,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1.05, 1.54, 1.99,
          ],
        },
        {
          type: 'MarginalTaxRate',
          basisType: 'Base',
          title: 'Marginal tax rate (%)',
          rank: 29,
          interestAssumption: 'Specified',
          values: [
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
            50,
          ],
        },
        {
          type: 'DepositToSideAccount',
          basisType: 'Base',
          title: 'Deposit to the side account',
          rank: 30,
          interestAssumption: 'Specified',
          values: [
            4084.81, 2346.82, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3149.27, 11614.55, 15221.02, 16667.31, 17114.34, 17066.65,
            16748.79, 16256.53, 17921.9, 18061.92, 19078.71, 18471.66, 17910.19, 17334.44, 16704.27, 15985.5, 15144.36,
            14146.49, 18215.68, 20354.7, 22152.1, 23815.72, 25500.89, 30000, 24624.93, 18825.66, 13002.27, 6582.26, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'TransferToSideAccount',
          basisType: 'Base',
          title: 'Transfer to side account',
          rank: 31,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'TransferFromSideAccount',
          basisType: 'Base',
          title: 'Transfer from side account',
          rank: 32,
          interestAssumption: 'Specified',
          values: [
            0, 0, 3826.88, 2404.75, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 1097.84, 10923.08, 24207.57, 43054.11, 71053.06, 57549.86, 52937.07, 48499.59,
            44183.17, 39898.94, 35507.53, 30770.64, 23989.65, 0, 0,
          ],
        },
        {
          type: 'SideAccountInterest',
          basisType: 'Base',
          title: 'Side account interest',
          rank: 33,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'TaxOnSideAccount',
          basisType: 'Base',
          title: 'Tax on side account',
          rank: 34,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'SideAccountInterestRate',
          basisType: 'Base',
          title: 'Side acount interest rate (%)',
          rank: 35,
          interestAssumption: 'Specified',
          values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
        {
          type: 'AdjustedCostBasis',
          basisType: 'Base',
          title: 'Adjusted cost basis',
          rank: 36,
          interestAssumption: 'Specified',
          values: [
            26552.57, 53712.44, 86936.44, 118520.36, 147579.57, 176505.8, 205281.34, 233894.92, 262330.39, 290564.36,
            318554.28, 346297.51, 370716.97, 386366.69, 398064.31, 407932.65, 417050.53, 425882.66, 434668.28,
            443546.01, 450274.12, 456410.74, 461053.65, 465781.73, 470508.99, 475210.68, 479907.48, 484646.63,
            489515.37, 494635.16, 494805.64, 492248.09, 487346.61, 480292.09, 471147.42, 459532.48, 452687.74,
            451039.71, 454618.7, 464030.06, 480542.62, 506317.03, 544826.47, 601651.42, 685965.84, 756291.06, 821547.17,
            881947.08, 937659.34, 988783.52, 1035309.42, 1077043.61, 1112091.91, 1123772.62, 1136847.99,
          ],
        },
        {
          type: 'CdaCredit',
          basisType: 'Base',
          title: 'Capital dividend account credit',
          rank: 37,
          interestAssumption: 'Specified',
          values: [
            589956.98, 579909.35, 568839.36, 558164.73, 548050.95, 538070.16, 528240.07, 518571.92, 509081.89,
            499793.37, 490205.09, 480863.52, 472357.18, 465707.39, 460064.46, 455069.7, 450460.34, 446175.67, 442197.09,
            438528.41, 434428.03, 430804.8, 427874.27, 425349.5, 423284.08, 421714.43, 420664.31, 420158.83, 420210.7,
            420826.44, 420341.92, 420838.56, 422211.26, 424378.37, 427259.42, 392160.59, 397877.62, 404081.22,
            410764.73, 417907.48, 425475.52, 433410.45, 441629.15, 450001.95, 458324.25, 467402.61, 477029.55,
            487163.96, 497755.93, 508737.43, 520013.63, 531439.39, 542804.63, 554027.1, 563854.46,
          ],
        },
        {
          type: 'NetCostOfPureInsurance',
          basisType: 'Base',
          title: 'Net cost of pure insurance',
          rank: 38,
          interestAssumption: 'Specified',
          values: [
            356.5, 487.19, 596.76, 714.71, 834.67, 967.65, 1118.34, 1280.3, 1458.41, 1659.91, 1884.4, 2131.09, 2405.59,
            2710.05, 3055.68, 3438.67, 3742.1, 4075.54, 4439.91, 4840.06, 5280.27, 5731.74, 6210.58, 6732.46, 7294.75,
            7896.07, 8531.13, 9207.55, 9919.1, 10665.92, 11447.88, 12036.89, 12583.42, 13072.84, 13477.82, 11614.94,
            12219.81, 12822.37, 13418.74, 14006.38, 14585.28, 15148.67, 15698.13, 16229.16, 16738.64, 17224.64,
            17680.96, 18099.68, 18470.91, 18774.76, 18981.63, 19036.45, 18842.41, 18220.84, 16826.63,
          ],
        },
        {
          type: 'IrrOnTotalCashValue',
          basisType: 'Base',
          title: 'IRR on cash value %',
          rank: 39,
          interestAssumption: 'Specified',
          values: [
            -39.29, -26.58, -15.88, -11.32, -9.27, -7.76, -6.85, -6.13, -5.55, -5.06, -4.7, -4.39, -4.27, -4.52, -4.85,
            -5.16, -5.41, -5.61, -5.76, -5.85, -6.01, -6.15, -6.29, -6.4, -6.47, -6.51, -6.52, -6.5, -6.47, -6.4, -6.49,
            -6.63, -6.79, -6.98, -7.21, -7.4, -7.47, -7.4, -7.21, -6.89, -6.47, -5.93, -5.27, -4.51, -3.64, -3.06, -2.6,
            -2.24, -1.94, -1.71, -1.52, -1.36, -1.25, -1.22, -1.19,
          ],
        },
        {
          type: 'IrrOnTotalDeathBenefit',
          basisType: 'Base',
          title: 'IRR on death benefit %',
          rank: 40,
          interestAssumption: 'Specified',
          values: [
            1888.74, 306.29, 137.5, 82.15, 55.87, 40.96, 31.51, 25.07, 20.45, 16.99, 14.32, 12.22, 10.48, 8.97, 7.67,
            6.57, 5.63, 4.82, 4.12, 3.52, 2.97, 2.48, 2.04, 1.65, 1.31, 1.01, 0.74, 0.5, 0.29, 0.1, -0.11, -0.31, -0.51,
            -0.7, -0.89, -1.33, -1.46, -1.54, -1.58, -1.57, -1.53, -1.43, -1.27, -1.05, -0.74, -0.52, -0.35, -0.21,
            -0.1, -0.01, 0.06, 0.11, 0.14, 0.12, 0.11,
          ],
        },
      ],
    },
  },
};

export const udmMULErrorResponse = {
  status: 'Success',
  requestId: 'F66AB185B1',
  type: 'Full',
  illustration: {
    illustrationId: 'CE95FDAB9D',
    version: '',
    language: 'English',
    creationDate: '2021-11-04T17:03:45.7718673+00:00',
    system: 'Web',
    user: {
      type: 'HeadOffice',
      userId: 'HeadOffice.1',
      name: '',
    },
    party: [
      {
        partyId: 'Client.1',
        age: 45,
        gender: 'Male',
        fullName: 'Client 1',
        firstName: '',
        lastName: '',
        addressProvince: 'ON',
      },
      {
        partyId: 'Child.1',
        age: 21,
        gender: 'Male',
        firstName: '',
        lastName: '',
      },
    ],
    reportOptions: {
      type: 'Base',
      layout: 'AllYears',
      outputType: 'Spreadsheet',
      language: 'English',
      preparedForId: 'Child.1',
      section: {},
    },
    policy: {
      product: 'Mul',
      productFamily: 'Mul',
      businessType: 'NewBusiness',
      depositStartDate: illustraitonStartDate,
      depositEndDate: illustrationEndDate,
      depositDuration: 0,
      depositMode: 'Annually',
      depositOption: {
        type: 'Specified',
        additionalDeposit: 0,
        durationPartyId: 'Client.1',
        modalAmount: 6525.96,
        depositAge: 100,
        durationType: 'Age',
        startDate: illustraitonStartDate,
        endDate: illustrationEndDate,
        duration: 0,
        allocation: [],
        additionalDepositSolveResult: 'None',
      },
      duration: 0,
      effectiveDate: illustraitonStartDate,
      rates: {
        marginalTaxRate: 50,
        primaryInterestRate: ['1:0'],
        sideAccountInterestRate: ['1:0'],
      },
      specialQuote: 'None',
      terminationDate: illustrationEndDate,
      coverage: [
        {
          coverageId: coverage,
          product: 'MUL',
          coverageType: 'Single',
          effectiveDate: illustraitonStartDate,
          coverageAmount: 500000,
          amountType: 'Specified',
          costOfInsurance: {
            type: 'Level100',
          },
          deathBenefit: {
            type: 'FacePlus',
          },
          depositDuration: 0,
          duration: 0,
          durationEndDate: illustrationEndDate,
          indicatorCode: 'Base',
          specialQuote: 'None',
          terminationDate: illustrationEndDate,
          life: [
            {
              partyId: 'Client.1',
              age: 45,
              healthstyle: 'NonSmoker',
            },
          ],
          lifeRating: [
            {
              partyId: 'Client.1',
              permanentPercent: 100,
              permanentAmount: 0,
              temporaryAmount: 0,
              temporaryDuration: 1,
            },
          ],
          exemptTestPolicy: [
            {
              effectiveDate: illustraitonStartDate,
              deathBenefitAmount: 500000,
            },
          ],
        },
        {
          coverageId: 'Rider.1',
          product: 'CPR',
          coverageType: 'Single',
          effectiveDate: illustraitonStartDate,
          coverageAmount: 10000,
          depositDuration: 0,
          duration: 0,
          durationEndDate: illustrationEndDate,
          indicatorCode: 'Rider',
          terminationDate: illustrationEndDate,
          life: [
            {
              partyId: 'Child.1',
              age: 21,
              healthstyle: 'Smoker',
            },
          ],
          lifeRating: [
            {
              partyId: 'Child.1',
              permanentPercent: 100,
              permanentAmount: 0,
              temporaryAmount: 0,
              temporaryDuration: 1,
            },
          ],
          exemptTestPolicy: [
            {
              effectiveDate: illustraitonStartDate,
              deathBenefitAmount: 0,
            },
          ],
        },
      ],
      life: [
        {
          partyId: 'Client.1',
        },
        {
          partyId: 'Child.1',
        },
      ],
    },
  },
  validations: {
    status: 'Error',
    validation: [
      {
        id: 'MinMaxIssueAge',
        type: 'MinMaxIssueAge',
        status: 'Error',
        message: [
          {
            id: 'MinMaxIssueAge',
            type: 'Error',
            text: 'Age for CPR must be between 0 and 18 - (Client 2).',
          },
        ],
      },
    ],
  },
};

export const noTermRider = {
  activeTabId: scenario1,
  [scenario1]: {
    allRiders: [],
  },
};

export const noTermOtherRider = {
  activeTabId: scenario1,
  [scenario1]: {
    allRiders: [],
  },
};

export const noSpousalRider = {
  activeTabId: scenario1,
  [scenario1]: {
    allRiders: [],
  },
};

export const InforceData = {
  pmaxWithTermOptionPlus: {
    policyNumber: '5582590',
    product: 'Performax',
    productFamily: 'Performax',
    businessType: 'Inforce',
    depositMode: 'Monthly',
    depositOption: {
      type: 'Specified',
      depositYear: 0,
      durationType: 'Year',
      duration: 1,
      modalAmount: 0,
    },
    effectiveDate: '2003-02-26',
    initialModalPremium: 102.75,
    loanBalance: 0,
    maximumAdditionalDepositAmt: 16586.94,
    nextAnniversary: '2025-02-26',
    status: 'Inforce',
    coverage: [
      {
        coverageId: 'Base.1',
        product: 'Performax94',
        coverageType: 'Single',
        effectiveDate: '2003-02-26',
        coverageAmount: 25000,
        dividendOption: {
          type: 'TermDividendOption',
        },
        equivalentAge: 52,
        indicatorCode: 'Base',
        minimumModalPremium: 102.75,
        premiumDuration: {
          type: 'Age99',
        },
        status: 'Inforce',
        subType: 734,
        life: [
          {
            partyId: 'Life.1',
            age: 52,
            healthstyle: 'NonSmoker',
          },
        ],
        lifeRating: [
          {
            partyId: 'Life.1',
            permanentAmount: 0,
            temporaryAmount: 0,
            temporaryDuration: 0,
            temporaryAmount2: 0,
            temporaryDuration2: 0,
          },
        ],
      },
      {
        coverageId: 'Pui.1',
        parentCoverageId: 'Base.1',
        product: 'PUI',
        coverageType: 'Single',
        effectiveDate: '2003-02-26',
        coverageAmount: 2043.8,
        equivalentAge: 52,
        indicatorCode: 'Enhancer',
        minimumModalPremium: 0,
        status: 'Inforce',
        subType: 734,
        life: [
          {
            partyId: 'Life.1',
            age: 52,
            healthstyle: 'NonSmoker',
          },
        ],
      },
      {
        coverageId: 'Yti.1',
        parentCoverageId: 'Base.1',
        product: 'YTI',
        coverageType: 'Single',
        effectiveDate: '2003-02-26',
        coverageAmount: 42706.21,
        equivalentAge: 52,
        indicatorCode: 'Enhancer',
        minimumModalPremium: 2759.2,
        status: 'Inforce',
        subType: 734,
        life: [
          {
            partyId: 'Life.1',
            age: 52,
            healthstyle: 'NonSmoker',
          },
        ],
      },
      {
        coverageId: 'YtiPlus.1',
        parentCoverageId: 'Base.1',
        product: 'YTIPlus',
        coverageType: 'Single',
        effectiveDate: '2003-02-26',
        coverageAmount: 30250,
        equivalentAge: 52,
        indicatorCode: 'Enhancer',
        minimumModalPremium: 30.25,
        status: 'Inforce',
        subType: 734,
        life: [
          {
            partyId: 'Life.1',
            age: 52,
            healthstyle: 'NonSmoker',
          },
        ],
      },
    ],
  },

  thirdPartyRiderWithPGold: {
    status: 'Success',

    policyData: {
      status: 'Inforce',
      illustration: {
        user: {},
        party: [
          {
            partyId: '10123788',
            gender: 'Male',
            firstName: 'AMCFASSD',
            lastName: 'XMLQHK',
            addressProvince: 'ON',
          },
          {
            partyId: '10123789',
            gender: 'Female',
            firstName: 'JFLWNHGF',
            lastName: 'XMLQHK',
          },
        ],
        policy: {
          policyNumber: '3115173',
          product: 'PerformaxGold',
          productFamily: 'PerformaxGold',
          businessType: 'Inforce',
          ownerId: '10123788',
          depositMode: 'Annually',
          depositOption: {
            type: 'Specified',
            modalAmount: 6000.0,
            minimumDepositAmount: 5842.2,
            maximumDepositAmount: 0.0,
          },
          effectiveDate: '2014-09-21',
          minimumModalPremium: 5842.2,
          status: 'Inforce',
          coverage: [
            {
              coverageId: '4',
              product: 'Pay15',
              coverageType: 'Single',
              effectiveDate: '2014-09-21',
              coverageAmount: 125000.0,
              equivalentAge: 39,
              healthstyle: 'HS3',
              indicatorCode: 'Base',
              minimumModalPremium: 3603.6,
              status: 'Inforce',
              life: [
                {
                  partyId: '10123788',
                  age: 39,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '5',
              product: 'PUI',
              coverageType: 'Single',
              effectiveDate: '2014-09-21',
              coverageAmount: 3570.49,
              equivalentAge: 39,
              healthstyle: 'HS3',
              indicatorCode: 'Enhancer',
              minimumModalPremium: 0.0,
              status: 'Inforce',
              life: [
                {
                  partyId: '10123788',
                  age: 39,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '6',
              product: 'DOI',
              coverageType: 'Single',
              effectiveDate: '2014-09-21',
              coverageAmount: 3588.57,
              equivalentAge: 39,
              healthstyle: 'HS3',
              indicatorCode: 'Enhancer',
              minimumModalPremium: 0.0,
              status: 'Inforce',
              life: [
                {
                  partyId: '10123788',
                  age: 39,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '7',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2014-09-21',
              coverageAmount: 1250000.0,
              duration: 41,
              equivalentAge: 39,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 704.88,
              status: 'Inforce',
              life: [
                {
                  partyId: '10123788',
                  age: 39,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '8',
              product: 'Term20',
              coverageType: 'Single',
              effectiveDate: '2014-09-21',
              coverageAmount: 1250000.0,
              duration: 41,
              equivalentAge: 39,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 1289.28,
              status: 'Inforce',
              life: [
                {
                  partyId: '10123788',
                  age: 39,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '9',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2014-09-21',
              coverageAmount: 500000.0,
              duration: 42,
              equivalentAge: 38,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 219.48,
              status: 'Inforce',
              life: [
                {
                  partyId: '10123789',
                  age: 38,
                  healthstyle: 'HS3',
                },
              ],
            },
          ],
        },
      },
    },
  },
  successfulTir10: {
    status: 'Success',
    type: 'PolicyData',
    number: '200tir10',
    agents: [],
    policyData: {
      status: 'Inforce',
      illustration: {
        user: { type: 'None' },
        party: [
          {
            partyId: 'Life.1',
            age: 45,
            gender: 'Male',
            firstName: 'Stu',
            lastName: 'Fowler',
            addressProvince: 'ON',
          },
        ],
        policy: {
          product: 'Mul',
          productFamily: 'Mul',
          businessType: 'Inforce',
          depositMode: 'Monthly',
          depositOption: {
            type: 'Specified',
            modalAmount: 5000.0,
            durationType: 'Age',
            duration: 0,
            allocation: [],
          },
          coverage: [
            {
              coverageId: 'Mul.1',
              product: 'MUL',
              coverageType: 'Single',
              effectiveDate: '2020-03-15',
              coverageAmount: 7500000.0,
              amountType: 'Specified',
              costOfInsurance: { type: 'Level100' },
              deathBenefit: { type: 'FacePlus' },
              indicatorCode: 'Base',
              life: [{ partyId: 'Life.1', age: 45, healthstyle: 'NonSmoker' }],
              lifeRating: [
                {
                  partyId: 'Life.1',
                  permanentPercent: 110.0,
                  permanentAmount: 3.0,
                  temporaryAmount: 15.0,
                  temporaryDuration: 7,
                },
              ],
            },
            {
              coverageId: 'Term.2',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2020-03-15',
              coverageAmount: 10000000.0,
              indicatorCode: 'TermRider',
              life: [{ partyId: 'Life.1', healthstyle: 'NonSmoker' }],
              lifeRating: [
                {
                  partyId: 'Life.1',
                  permanentPercent: 150.0,
                  permanentAmount: 5.0,
                  temporaryAmount: 25.0,
                  temporaryDuration: 2,
                },
              ],
            },
          ],
        },
      },
    },
    message: '',
  },
  successfulRiders: {
    status: 'Success',
    type: 'PolicyData',
    number: '200riders',
    agents: [],
    policyData: {
      status: 'Inforce',
      illustration: {
        user: { type: 'None' },
        party: [
          {
            partyId: 'Life.1',
            age: 45,
            gender: 'Male',
            firstName: 'Stu',
            lastName: 'Fowler',
            addressProvince: 'ON',
          },
          { partyId: 'Child.1', age: 3, gender: 'Female', firstName: 'Kelly', lastName: 'Fowler' },
        ],
        policy: {
          product: 'Mul',
          productFamily: 'Mul',
          businessType: 'Inforce',
          depositMode: 'Annually',
          depositOption: {
            type: 'Specified',
            modalAmount: 25000.0,
            durationType: 'Age',
            duration: 0,
            allocation: [],
          },
          coverage: [
            {
              coverageId: 'Mul.1',
              product: 'MUL',
              coverageType: 'Single',
              effectiveDate: '2020-03-15',
              coverageAmount: 7500000.0,
              amountType: 'Specified',
              costOfInsurance: { type: 'Level100' },
              deathBenefit: { type: 'FacePlus' },
              indicatorCode: 'Base',
              life: [{ partyId: 'Life.1', age: 45, healthstyle: 'NonSmoker' }],
            },
            {
              coverageId: 'Term.3',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2020-03-15',
              coverageAmount: 10000000.0,
              indicatorCode: 'TermRider',
              life: [{ partyId: 'Life.1', age: 45, healthstyle: 'NonSmoker' }],
            },
            {
              coverageId: 'Cpr.2',
              product: 'CPR',
              coverageType: 'Single',
              indicatorCode: 'Rider',
              life: [{ partyId: 'Child.1', age: 3 }],
            },
          ],
        },
      },
    },
    message: '',
  },
  successfulInforceTir10: {
    status: 'Success',
    policyData: {
      status: 'Inforce',
      illustration: {
        user: { type: 'Advisor', userId: 'Agent.1' },
        party: [
          {
            partyId: 'Life.0005572073',
            gender: 'Male',
            firstName: 'Timmy',
            lastName: 'Starbucks',
            addressProvince: 'ON',
          },
          {
            partyId: 'Life.0005572074',
            gender: 'Male',
            firstName: 'Timme',
            lastName: 'Starbuckz',
            addressProvince: 'ON',
          },
          { partyId: 'Agent.1' },
        ],
        relation: [
          { relationId: 'R.Life.0005572073', partyId: 'Life.0005572073', role: 'Insured' },
          { relationId: 'R.Life.0005572074', partyId: 'Life.0005572074', role: 'Insured' },
          { relationId: 'R.Owner.1', partyId: 'Life.0005572073', role: 'Owner' },
          { relationId: 'R.Agent.1', partyId: 'Agent.1', role: 'ServicingAgent' },
        ],
        policy: {
          policyNumber: '3334444',
          product: 'Mul',
          productFamily: 'Mul',
          businessType: 'Inforce',
          chargeTotalAmount: 87.45,
          costBasis: 34213.29,
          depositMode: 'Monthly',
          depositOption: { type: 'Specified', modalAmount: 400.0, duration: 0, mode: 'Monthly' },
          effectiveDate: '2014-08-28',
          lastEffectiveDate: '2016-05-05',
          maximumTaxExemptPremAmt: 30899.88,
          minimumModalPremium: 89.26,
          netCostofPureInsurance: 6529.59,
          netSurrenderValue: 38536.27,
          status: 'Inforce',
          tax: {
            status: 'Exempt',
            avAtLastAnniv1: 36039.45,
            avAtLastAnniv2: 28132.06,
            avAtLastAnniv3: 22933.91,
            reserveAmount: 0.0,
          },
          terminationDate: '2216-05-04',
          coverage: [
            {
              coverageId: 'Mul.1',
              product: 'MUL',
              coverageType: 'Single',
              costOfInsurance: {
                type: 'Yrt85',
              },
              effectiveDate: '2014-08-28',
              coverageAmount: 250000.0,
              accumulationValue: 'N',
              deathBenefit: { type: 'FacePlus', amount: 1135869.15 },
              deferredNetPremium: 0.0,
              equivalentAge: 42,
              indicatorCode: 'Base',
              initialAmount: 250000.0,
              lastAge: 42,
              minimumModalPremium: 70.34,
              originalAge: 42,
              status: 'Inforce',
              taxAge: 42,
              terminationDate: '2072-08-28',
              life: [{ partyId: 'Life.0005572073', deceased: false, healthstyle: 'NonSmoker' }],
              lifeRating: [
                { permanentPercent: 100.0, permanentAmount: 0.0, temporaryAmount2: 0.0, temporaryDuration2: 0 },
              ],
              exemptTestPolicy: [{ effectiveDate: '2014-08-28', deathBenefitAmount: 286039.45 }],
            },
            {
              coverageId: 'Term10.1',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2014-08-28',
              coverageAmount: 250000.0,
              accumulationValue: 'X',
              deferredNetPremium: 0.0,
              equivalentAge: 42,
              indicatorCode: 'TermRider',
              initialAmount: 250000.0,
              lastAge: 42,
              minimumModalPremium: 18.92,
              originalAge: 42,
              status: 'Inforce',
              taxAge: 42,
              terminationDate: '2058-08-28',
              life: [{ partyId: 'Life.0005572074', deceased: false, healthstyle: 'NonSmoker' }],
              lifeRating: [
                { permanentPercent: 100.0, permanentAmount: 0.0, temporaryAmount2: 0.0, temporaryDuration2: 0 },
              ],
              exemptTestPolicy: [{ effectiveDate: '2014-08-28', deathBenefitAmount: 250000.0 }],
            },
            {
              coverageId: 'Term10.2',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2014-08-28',
              coverageAmount: 500000.0,
              accumulationValue: 'X',
              deferredNetPremium: 0.0,
              equivalentAge: 42,
              indicatorCode: 'TermRider',
              initialAmount: 500000.0,
              lastAge: 42,
              minimumModalPremium: 37.84,
              originalAge: 42,
              status: 'Inforce',
              taxAge: 42,
              terminationDate: '2058-08-28',
              life: [{ partyId: 'Life.0005572073', deceased: false, healthstyle: 'NonSmoker' }],
              lifeRating: [
                { permanentPercent: 100.0, permanentAmount: 0.0, temporaryAmount2: 0.0, temporaryDuration2: 0 },
              ],
              exemptTestPolicy: [{ effectiveDate: '2014-08-28', deathBenefitAmount: 500000.0 }],
            },
          ],
        },
      },
    },
  },
  ReplacedTir10: {
    status: 'Success',
    policyData: {
      status: 'Inforce',
      illustration: {
        user: { type: 'Advisor', userId: 'Agent.1' },
        party: [
          {
            partyId: 'Life.0005572073',
            gender: 'Male',
            firstName: 'Timmy',
            lastName: 'Starbucks',
            addressProvince: 'ON',
          },
          {
            partyId: 'Life.0005572074',
            gender: 'Male',
            firstName: 'Timme',
            lastName: 'Starbuckz',
            addressProvince: 'ON',
          },
          { partyId: 'Agent.1' },
        ],
        relation: [
          { relationId: 'R.Life.0005572073', partyId: 'Life.0005572073', role: 'Insured' },
          { relationId: 'R.Life.0005572074', partyId: 'Life.0005572074', role: 'Insured' },
          { relationId: 'R.Owner.1', partyId: 'Life.0005572073', role: 'Owner' },
          { relationId: 'R.Agent.1', partyId: 'Agent.1', role: 'ServicingAgent' },
        ],
        policy: {
          policyNumber: '3334444',
          product: 'PerformaxGold',
          productFamily: 'PerformaxGold',
          businessType: 'Inforce',
          chargeTotalAmount: 87.45,
          costBasis: 34213.29,
          depositMode: 'Monthly',
          depositOption: { type: 'Specified', modalAmount: 400.0, duration: 0, mode: 'Monthly' },
          effectiveDate: '2014-08-28',
          lastEffectiveDate: '2016-05-05',
          maximumTaxExemptPremAmt: 30899.88,
          minimumModalPremium: 89.26,
          netCostofPureInsurance: 6529.59,
          netSurrenderValue: 38536.27,
          status: 'Inforce',
          tax: {
            status: 'Exempt',
            avAtLastAnniv1: 36039.45,
            avAtLastAnniv2: 28132.06,
            avAtLastAnniv3: 22933.91,
            reserveAmount: 0.0,
          },
          terminationDate: '2216-05-04',
          coverage: [
            {
              coverageId: 'Coverage.1',
              product: 'Pay100',
              coverageType: 'JointLtdCtl',
              costOfInsurance: {
                type: 'Yrt85',
              },
              effectiveDate: '2014-08-28',
              coverageAmount: 250000.0,
              accumulationValue: 'N',
              deathBenefit: { type: 'FacePlus', amount: 1135869.15 },
              deferredNetPremium: 0.0,
              equivalentAge: 42,
              indicatorCode: 'Base',
              initialAmount: 250000.0,
              lastAge: 42,
              minimumModalPremium: 70.34,
              originalAge: 42,
              status: 'Inforce',
              taxAge: 42,
              terminationDate: '2072-08-28',
              life: [{ partyId: 'Life.0005572073', deceased: false, healthstyle: 'NonSmoker' }],
              lifeRating: [
                { permanentPercent: 100.0, permanentAmount: 0.0, temporaryAmount2: 0.0, temporaryDuration2: 0 },
              ],
              exemptTestPolicy: [{ effectiveDate: '2014-08-28', deathBenefitAmount: 286039.45 }],
            },
            {
              coverageId: 'Term10.2',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2014-08-28',
              coverageAmount: 500000.0,
              accumulationValue: 'X',
              deferredNetPremium: 0.0,
              equivalentAge: 42,
              indicatorCode: 'TermRider',
              initialAmount: 500000.0,
              lastAge: 42,
              minimumModalPremium: 37.84,
              originalAge: 42,
              status: 'Replaced',
              taxAge: 42,
              terminationDate: '2058-08-28',
              life: [{ partyId: 'Life.0005572073', deceased: false, healthstyle: 'NonSmoker' }],
              lifeRating: [
                { permanentPercent: 100.0, permanentAmount: 0.0, temporaryAmount2: 0.0, temporaryDuration2: 0 },
              ],
              exemptTestPolicy: [{ effectiveDate: '2014-08-28', deathBenefitAmount: 500000.0 }],
            },
          ],
        },
      },
    },
  },
  successfulInforcePolicyWithPayorTDW: {
    status: 'Success',
    entitlements: {
      status: 'Entitled',
    },
    policyData: {
      status: 'Inforce',
      illustration: {
        user: {},
        party: [
          {
            partyId: 'Client.1',
            gender: 'Male',
            firstName: 'LF PY',
            lastName: 'CHHA',
            addressProvince: 'ON',
          },
          {
            partyId: 'Client.2',
            gender: 'Female',
            firstName: 'LFVNLJD XMW HHZ',
            lastName: 'CHHA',
          },
        ],
        policy: {
          policyNumber: '0000000',
          product: 'PerformaxGold',
          productFamily: 'PerformaxGold',
          businessType: 'Inforce',
          ownerId: 'Client.1',
          depositMode: 'Annually',
          depositOption: {
            type: 'Specified',
            modalAmount: 1500.0,
            minimumDepositAmount: 1079.28,
            maximumDepositAmount: 0.0,
          },
          effectiveDate: '2010-08-03',
          minimumModalPremium: 1079.28,
          status: 'Inforce',
          coverage: [
            {
              coverageId: '4',
              product: 'Pay100',
              coverageType: 'Single',
              effectiveDate: '2010-08-03',
              coverageAmount: 70000.0,
              equivalentAge: 1,
              healthstyle: 'HS5',
              indicatorCode: 'Base',
              minimumModalPremium: 930.24,
              status: 'Inforce',
              life: [
                {
                  partyId: 'Client.2',
                  age: 1,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '5',
              product: 'PUI',
              coverageType: 'Single',
              effectiveDate: '2010-08-03',
              coverageAmount: 48341.65,
              equivalentAge: 1,
              healthstyle: 'HS5',
              indicatorCode: 'Enhancer',
              minimumModalPremium: 0.0,
              status: 'Inforce',
              life: [
                {
                  partyId: 'Client.2',
                  age: 1,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '6',
              product: 'DOI',
              coverageType: 'Single',
              effectiveDate: '2010-08-03',
              coverageAmount: 95113.49,
              equivalentAge: 1,
              healthstyle: 'HS5',
              indicatorCode: 'Enhancer',
              minimumModalPremium: 0.0,
              status: 'Inforce',
              life: [
                {
                  partyId: 'Client.2',
                  age: 1,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '7',
              product: 'PTDW',
              coverageType: 'Single',
              effectiveDate: '2010-08-03',
              coverageAmount: 955.2,
              equivalentAge: 35,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 124.08,
              status: 'Inforce',
              life: [
                {
                  partyId: 'Client.3',
                  age: 35,
                  healthstyle: 'HS5',
                },
              ],
            },
          ],
        },
      },
    },
  },

  successfulParInforcePay90: {
    status: 'Success',
    entitlements: {
      status: 'Entitled',
      product: 'Par',
    },
    policyData: {
      status: 'Inforce',
      illustration: {
        user: {},
        party: [
          {
            partyId: '10148425',
            gender: 'Female',
            firstName: 'YRLZQCQ',
            lastName: 'VFVQYX',
          },
          {
            partyId: '10148426',
            gender: 'Female',
            firstName: 'ZQCLNJ',
            lastName: 'VFVQYX',
            addressProvince: 'ON',
          },
        ],
        policy: {
          policyNumber: '1234567',
          product: 'Par',
          productFamily: 'Par',
          businessType: 'Inforce',
          annualizedInitialModalPremium: 4145.4,
          ownerId: '10148426',
          depositMode: 'Annually',
          depositOption: {
            type: 'Specified',
            modalAmount: 4000.0,
          },
          effectiveDate: '2019-10-16',
          initialModalPremium: 4145.4,
          maximumAdditionalDepositAmt: 221496.94,
          minimumModalPremium: 8145.4,
          status: 'Inforce',
          withdrawal: {
            amount: 0.0,
            durationStart: 0,
            durationEnd: 0,
          },
          coverage: [
            {
              coverageId: '2',
              product: 'Pay90',
              coverageType: 'Single',
              effectiveDate: '2019-10-16',
              coverageAmount: 250000.0,
              dividendOption: {
                type: 'Pui',
              },
              equivalentAge: 28,
              healthstyle: 'HS3',
              indicatorCode: 'Base',
              minimumModalPremium: 3295.32,
              status: 'Inforce',
              life: [
                {
                  partyId: '10148425',
                  age: 28,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '3',
              parentCoverageId: '2',
              product: 'PUI',
              coverageType: 'Single',
              effectiveDate: '2019-10-16',
              coverageAmount: 15032.86,
              equivalentAge: 28,
              healthstyle: 'HS3',
              indicatorCode: 'Enhancer',
              minimumModalPremium: 0.0,
              status: 'Inforce',
              life: [
                {
                  partyId: '10148425',
                  age: 28,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '4',
              parentCoverageId: '2',
              product: 'DOI',
              coverageType: 'Single',
              effectiveDate: '2019-10-16',
              coverageAmount: 38071.64,
              equivalentAge: 28,
              healthstyle: 'HS3',
              indicatorCode: 'Enhancer',
              minimumModalPremium: 0.0,
              status: 'Inforce',
              life: [
                {
                  partyId: '10148425',
                  age: 28,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '5',
              product: 'TDW',
              coverageType: 'Single',
              effectiveDate: '2019-10-16',
              coverageAmount: 3995.04,
              equivalentAge: 28,
              healthstyle: 'HS3',
              indicatorCode: 'Rider',
              minimumModalPremium: 150.36,
              status: 'Inforce',
              life: [
                {
                  partyId: '10148425',
                  age: 28,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '6',
              product: 'Term20',
              coverageType: 'Single',
              effectiveDate: '2019-10-16',
              coverageAmount: 1550000.0,
              duration: 52,
              equivalentAge: 28,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 699.72,
              status: 'Inforce',
              life: [
                {
                  partyId: '10148425',
                  age: 28,
                  healthstyle: 'HS3',
                },
              ],
            },
          ],
        },
      },
    },
  },

  successfulInforcePolicy: {
    status: 'Success',
    entitlements: {
      status: 'Entitled',
    },
    policyData: {
      status: 'Inforce',
      illustration: {
        illustrationDate: '2023-05-02',
        user: {},
        party: [
          {
            partyId: '10121159',
            age: 35,
            gender: 'Female',
            firstName: 'YUBNOHSG',
            lastName: 'UFCFL',
            addressProvince: 'BC',
          },
          {
            partyId: '10121160',
            age: 12,
            gender: 'Male',
            firstName: 'OUIFA',
            lastName: 'UFCFL',
          },
          {
            partyId: '10121161',
            age: 12,
            gender: 'Female',
            firstName: 'LEUF',
            lastName: 'UFCFL',
          },
        ],
        policy: {
          policyNumber: '3088831',
          product: 'Mul',
          productFamily: 'Mul',
          businessType: 'Inforce',
          ownerId: '10121159',
          depositMode: 'Monthly',
          depositOption: {
            type: 'Specified',
            modalAmount: 250.0,
            minimumDepositAmount: 110.31,
            maximumDepositAmount: 71415.46,
          },
          effectiveDate: '2014-09-22',
          minimumModalPremium: 110.31,
          status: 'Inforce',
          coverage: [
            {
              coverageId: '3',
              product: 'MUL',
              coverageType: 'Single',
              effectiveDate: '2014-09-22',
              coverageAmount: 250000.0,
              costOfInsurance: {
                type: 'Yrt85',
              },
              deathBenefit: {
                type: 'FacePlus',
              },
              equivalentAge: 35,
              healthstyle: 'HS3',
              indicatorCode: 'Base',
              minimumModalPremium: 54.01,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121159',
                  age: 35,
                  healthstyle: 'HS3',
                },
              ],
              lifeRating: [
                {
                  partyId: '10121159',
                  permanentPercent: 150.0,
                },
              ],
            },
            {
              coverageId: '4',
              product: 'Term20',
              coverageType: 'Single',
              effectiveDate: '2014-09-22',
              coverageAmount: 750000.0,
              duration: 45,
              equivalentAge: 35,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 51.3,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121159',
                  age: 35,
                  healthstyle: 'HS3',
                },
              ],
              lifeRating: [
                {
                  partyId: '10121159',
                  permanentPercent: 150.0,
                },
              ],
            },
            {
              coverageId: '5',
              product: 'CPR',
              coverageType: 'Single',
              effectiveDate: '2014-09-22',
              coverageAmount: 10000.0,
              equivalentAge: 12,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 2.5,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121160',
                  age: 12,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '6',
              product: 'CPR',
              coverageType: 'Single',
              effectiveDate: '2014-09-22',
              coverageAmount: 10000.0,
              equivalentAge: 12,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 2.5,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121161',
                  age: 12,
                  healthstyle: 'HS5',
                },
              ],
            },
          ],
        },
      },
    },
  },
  successfulInforceT10T20WithRatings: {
    status: 'Success',
    entitlements: {
      status: 'Entitled',
    },
    policyData: {
      status: 'Inforce',
      illustration: {
        illustrationDate: '2023-05-02',
        user: {},
        party: [
          {
            partyId: '10116670',
            age: 46,
            gender: 'Male',
            firstName: 'JJKUHHD',
            lastName: 'HUTZPKD',
            addressProvince: 'ON',
          },
        ],
        policy: {
          policyNumber: '3082908',
          product: 'Mul',
          productFamily: 'Mul',
          businessType: 'Inforce',
          ownerId: '10116670',
          depositMode: 'Monthly',
          depositOption: {
            type: 'Specified',
            modalAmount: 4000.0,
            minimumDepositAmount: 1194.16,
            maximumDepositAmount: 129235.32,
          },
          effectiveDate: '2014-07-05',
          minimumModalPremium: 1194.16,
          status: 'Inforce',
          coverage: [
            {
              coverageId: '3',
              product: 'MUL',
              coverageType: 'Single',
              effectiveDate: '2014-07-05',
              coverageAmount: 400000.0,
              costOfInsurance: {
                type: 'Level100',
              },
              deathBenefit: {
                type: 'FacePlus',
              },
              equivalentAge: 46,
              healthstyle: 'HS3',
              indicatorCode: 'Base',
              minimumModalPremium: 953.62,
              status: 'Inforce',
              life: [
                {
                  partyId: '10116670',
                  age: 46,
                  healthstyle: 'HS3',
                },
              ],
              lifeRating: [
                {
                  partyId: '10116670',
                  permanentPercent: 250.0,
                },
              ],
            },
            {
              coverageId: '4',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2014-07-05',
              coverageAmount: 300000.0,
              duration: 34,
              equivalentAge: 46,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 80.72,
              status: 'Inforce',
              life: [
                {
                  partyId: '10116670',
                  age: 46,
                  healthstyle: 'HS3',
                },
              ],
              lifeRating: [
                {
                  partyId: '10116670',
                  permanentPercent: 250.0,
                },
              ],
            },
            {
              coverageId: '5',
              product: 'Term20',
              coverageType: 'Single',
              effectiveDate: '2014-07-05',
              coverageAmount: 300000.0,
              duration: 34,
              equivalentAge: 46,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 159.82,
              status: 'Inforce',
              life: [
                {
                  partyId: '10116670',
                  age: 46,
                  healthstyle: 'HS3',
                },
              ],
              lifeRating: [
                {
                  partyId: '10116670',
                  permanentPercent: 250.0,
                },
              ],
            },
          ],
        },
      },
    },
  },
  successfulInforceMulSingleWithCPRs: {
    status: 'Success',
    entitlements: {
      status: 'Entitled',
    },
    policyData: {
      status: 'Inforce',
      illustration: {
        user: {},
        party: [
          {
            partyId: '10121153',
            age: 43,
            gender: 'Female',
            firstName: 'JHHAAID',
            lastName: 'VROLSCUQ-TCFDRKI',
          },
          {
            partyId: '10121154',
            age: 13,
            gender: 'Female',
            firstName: 'RFOLF',
            lastName: 'LHHDCCD',
          },
          {
            partyId: '10121155',
            age: 7,
            gender: 'Female',
            firstName: 'TNLF',
            lastName: 'LHHDCCD',
          },
          {
            partyId: '10121156',
            age: 5,
            gender: 'Female',
            firstName: 'SFUDFZ',
            lastName: 'LHHDCCD',
          },
          {
            partyId: '10121157',
            age: 1,
            gender: 'Male',
            firstName: 'TFR HIPQJRLN',
            lastName: 'LHHDCCD',
          },
          {
            partyId: '10121158',
            lastName: '2299154 ZLVF UGC',
            addressProvince: 'AB',
          },
        ],
        policy: {
          policyNumber: '3065615',
          product: 'Mul',
          productFamily: 'Mul',
          businessType: 'Inforce',
          ownerId: '10121158',
          depositMode: 'Annually',
          depositOption: {
            modalAmount: 0.0,
          },
          effectiveDate: '2014-10-08',
          minimumModalPremium: 5160.18,
          status: 'Inforce',
          coverage: [
            {
              coverageId: '3',
              product: 'MUL',
              coverageType: 'Single',
              effectiveDate: '2014-10-08',
              coverageAmount: 1000000.0,
              costOfInsurance: {
                type: 'Yrt85',
              },
              deathBenefit: {
                type: 'Level',
              },
              equivalentAge: 43,
              healthstyle: 'HS3',
              indicatorCode: 'Base',
              minimumModalPremium: 5038.9,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121153',
                  age: 43,
                  healthstyle: 'HS3',
                },
              ],
              lifeRating: [
                {
                  partyId: '10121153',
                  permanentPercent: 175.0,
                },
              ],
            },
            {
              coverageId: '4',
              product: 'CPR',
              coverageType: 'Single',
              effectiveDate: '2014-11-08',
              coverageAmount: 10000.0,
              equivalentAge: 13,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 30.32,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121154',
                  age: 13,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '5',
              product: 'CPR',
              coverageType: 'Single',
              effectiveDate: '2014-11-08',
              coverageAmount: 10000.0,
              equivalentAge: 7,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 30.32,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121155',
                  age: 7,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '6',
              product: 'CPR',
              coverageType: 'Single',
              effectiveDate: '2014-11-08',
              coverageAmount: 10000.0,
              equivalentAge: 5,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 30.32,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121156',
                  age: 5,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '7',
              product: 'CPR',
              coverageType: 'Single',
              effectiveDate: '2014-11-08',
              coverageAmount: 10000.0,
              equivalentAge: 1,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 30.32,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121157',
                  age: 1,
                  healthstyle: 'HS5',
                },
              ],
            },
          ],
        },
      },
    },
  },
  successfulInforceCombinedTir10: {
    status: 'Success',
    entitlements: {
      status: 'Entitled',
    },
    policyData: {
      status: 'Inforce',
      illustration: {
        illustrationDate: '2023-05-03',
        user: {},
        party: [
          {
            partyId: '10121163',
            gender: 'Female',
            firstName: 'CIPQBCA',
            lastName: 'TUFHMD',
          },
          {
            partyId: '10121164',
            gender: 'Male',
            firstName: 'HMLBNL',
            lastName: 'TUFHMD',
          },
          {
            partyId: '10121165',
            lastName: 'KLQN SZFAULZ RKDAQ LBS',
            addressProvince: 'MB',
          },
        ],
        policy: {
          policyNumber: '3130788',
          product: 'Mul',
          productFamily: 'Mul',
          businessType: 'Inforce',
          ownerId: '10121165',
          depositMode: 'Annually',
          depositOption: {
            type: 'Specified',
            modalAmount: 10689.24,
            minimumDepositAmount: 8310.26,
            maximumDepositAmount: 535838.09,
          },
          effectiveDate: '2015-01-14',
          minimumModalPremium: 8310.26,
          status: 'Inforce',
          coverage: [
            {
              coverageId: '3',
              product: 'MUL',
              coverageType: 'JointLtdCtl',
              effectiveDate: '2015-01-14',
              coverageAmount: 1000000.0,
              costOfInsurance: {
                type: 'Yrt85',
              },
              deathBenefit: {
                type: 'FacePlus',
              },
              equivalentAge: 39,
              healthstyle: 'HS3',
              indicatorCode: 'Base',
              minimumModalPremium: 3394.12,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121164',
                  age: 50,
                  healthstyle: 'HS3',
                },
                {
                  partyId: '10121163',
                  age: 49,
                  healthstyle: 'HS1',
                },
              ],
            },
            {
              coverageId: '4',
              associatedCoverageId: '5',
              product: 'Term10',
              coverageType: 'CombinedTir',
              effectiveDate: '2015-01-14',
              coverageAmount: 2000000.0,
              duration: 30,
              equivalentAge: 50,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 3380.9,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121164',
                  age: 50,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '5',
              associatedCoverageId: '4',
              product: 'Term10',
              coverageType: 'CombinedTir',
              effectiveDate: '2015-01-14',
              coverageAmount: 2000000.0,
              duration: 30,
              equivalentAge: 49,
              healthstyle: 'HS1',
              indicatorCode: 'TermRider',
              minimumModalPremium: 1535.24,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121163',
                  age: 49,
                  healthstyle: 'HS1',
                },
              ],
            },
          ],
        },
      },
    },
  },
  successfulInforceCombinedTir20WithRatings: {
    status: 'Success',
    entitlements: { status: 'Entitled' },
    policyData: {
      status: 'Inforce',
      illustration: {
        illustrationDate: '2023-05-03',
        user: {},
        party: [
          {
            partyId: '10121151',
            gender: 'Male',
            firstName: 'HRHA WKDYU',
            lastName: 'KFEBYXS',
            addressProvince: 'QC',
          },
          { partyId: '10121152', gender: 'Female', firstName: 'AFUSLHDF', lastName: 'NRFHSLX' },
        ],
        policy: {
          policyNumber: '3082801',
          product: 'Mul',
          productFamily: 'Mul',
          businessType: 'Inforce',
          ownerId: '10121151',
          depositMode: 'Monthly',
          depositOption: {
            type: 'Specified',
            modalAmount: 400.0,
            minimumDepositAmount: 181.66,
            maximumDepositAmount: 59244.13,
          },
          effectiveDate: '2014-06-17',
          minimumModalPremium: 181.66,
          status: 'Inforce',
          coverage: [
            {
              coverageId: '3',
              product: 'MUL',
              coverageType: 'JointLtdCtl',
              effectiveDate: '2014-06-17',
              coverageAmount: 100000.0,
              costOfInsurance: { type: 'Yrt85' },
              deathBenefit: { type: 'FacePlus' },
              equivalentAge: 30,
              healthstyle: 'HS3',
              indicatorCode: 'Base',
              minimumModalPremium: 23.83,
              status: 'Inforce',
              life: [
                { partyId: '10121151', age: 46, healthstyle: 'HS3' },
                { partyId: '10121152', age: 38, healthstyle: 'HS3' },
              ],
            },
            {
              coverageId: '4',
              associatedCoverageId: '5',
              product: 'Term20',
              coverageType: 'CombinedTir',
              effectiveDate: '2014-06-17',
              coverageAmount: 360000.0,
              duration: 34,
              equivalentAge: 46,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 131.97,
              status: 'Inforce',
              life: [{ partyId: '10121151', age: 46, healthstyle: 'HS3' }],
              lifeRating: [{ partyId: '10121151', permanentPercent: 175.0 }],
            },
            {
              coverageId: '5',
              associatedCoverageId: '4',
              product: 'Term20',
              coverageType: 'CombinedTir',
              effectiveDate: '2014-06-17',
              coverageAmount: 360000.0,
              duration: 34,
              equivalentAge: 38,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 25.86,
              status: 'Inforce',
              life: [{ partyId: '10121152', age: 38, healthstyle: 'HS3' }],
            },
          ],
        },
      },
    },
  },
  successfulInforceJoint2xTir10WithRatings: {
    status: 'Success',
    entitlements: {
      status: 'Entitled',
    },
    policyData: {
      status: 'Inforce',
      illustration: {
        illustrationDate: '2023-05-03',
        user: {},
        party: [
          {
            partyId: '10116706',
            gender: 'Male',
            firstName: 'HUUZYX',
            lastName: 'YNSLFXN',
            addressProvince: 'NS',
          },
          {
            partyId: '10116707',
            gender: 'Female',
            firstName: 'JHKLFFF',
            lastName: 'YNSLFXN',
          },
        ],
        policy: {
          policyNumber: '3125069',
          product: 'Mul',
          productFamily: 'Mul',
          businessType: 'Inforce',
          ownerId: '10116706',
          depositMode: 'Annually',
          depositOption: {
            type: 'Specified',
            modalAmount: 7215.81,
            minimumDepositAmount: 7215.81,
            maximumDepositAmount: 50318.11,
          },
          effectiveDate: '2014-12-02',
          minimumModalPremium: 7215.81,
          status: 'Inforce',
          coverage: [
            {
              coverageId: '3',
              product: 'MUL',
              coverageType: 'JointLtdCtl',
              effectiveDate: '2014-12-02',
              coverageAmount: 200000.0,
              costOfInsurance: {
                type: 'Level10',
              },
              deathBenefit: {
                type: 'FacePlus',
              },
              equivalentAge: 47,
              healthstyle: 'HS3',
              indicatorCode: 'Base',
              minimumModalPremium: 6076.48,
              status: 'Inforce',
              life: [
                {
                  partyId: '10116706',
                  age: 59,
                  healthstyle: 'HS3',
                },
                {
                  partyId: '10116707',
                  age: 57,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '4',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2014-12-02',
              coverageAmount: 150000.0,
              duration: 21,
              equivalentAge: 59,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 818.2,
              status: 'Inforce',
              life: [
                {
                  partyId: '10116706',
                  age: 59,
                  healthstyle: 'HS3',
                },
              ],
              lifeRating: [
                {
                  partyId: '10116706',
                  permanentPercent: 250.0,
                },
              ],
            },
            {
              coverageId: '5',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2014-12-02',
              coverageAmount: 100000.0,
              duration: 23,
              equivalentAge: 57,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 321.13,
              status: 'Inforce',
              life: [
                {
                  partyId: '10116707',
                  age: 57,
                  healthstyle: 'HS3',
                },
              ],
            },
          ],
        },
      },
    },
  },
  successfulInforce3082801: {
    status: 'Success',
    entitlements: {
      status: 'Entitled',
    },
    policyData: {
      status: 'Inforce',
      illustration: {
        illustrationDate: '2023-05-04',
        user: {},
        party: [
          {
            partyId: '10121151',
            gender: 'Male',
            firstName: 'HRHA WKDYU',
            lastName: 'KFEBYXS',
            addressProvince: 'QC',
          },
          {
            partyId: '10121152',
            gender: 'Female',
            firstName: 'AFUSLHDF',
            lastName: 'NRFHSLX',
          },
        ],
        policy: {
          policyNumber: '3082801',
          product: 'Mul',
          productFamily: 'Mul',
          businessType: 'Inforce',
          ownerId: '10121151',
          depositMode: 'Monthly',
          depositOption: {
            type: 'Specified',
            modalAmount: 400.0,
            minimumDepositAmount: 2179.92,
            maximumDepositAmount: 59253.57,
          },
          effectiveDate: '2014-06-17',
          minimumModalPremium: 181.66,
          status: 'Inforce',
          coverage: [
            {
              coverageId: '3',
              product: 'MUL',
              coverageType: 'JointLtdCtl',
              effectiveDate: '2014-06-17',
              coverageAmount: 100000.0,
              costOfInsurance: {
                type: 'Yrt85',
              },
              deathBenefit: {
                type: 'FacePlus',
              },
              equivalentAge: 30,
              healthstyle: 'HS3',
              indicatorCode: 'Base',
              minimumModalPremium: 23.83,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121151',
                  age: 46,
                  healthstyle: 'HS3',
                },
                {
                  partyId: '10121152',
                  age: 38,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '4',
              associatedCoverageId: '5',
              product: 'Term20',
              coverageType: 'CombinedTir',
              effectiveDate: '2014-06-17',
              coverageAmount: 360000.0,
              duration: 34,
              equivalentAge: 46,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 131.97,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121151',
                  age: 46,
                  healthstyle: 'HS3',
                },
              ],
              lifeRating: [
                {
                  partyId: '10121151',
                  permanentPercent: 175.0,
                },
              ],
            },
            {
              coverageId: '5',
              associatedCoverageId: '4',
              product: 'Term20',
              coverageType: 'CombinedTir',
              effectiveDate: '2014-06-17',
              coverageAmount: 360000.0,
              duration: 34,
              equivalentAge: 38,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 25.86,
              status: 'Inforce',
              life: [
                {
                  partyId: '10121152',
                  age: 38,
                  healthstyle: 'HS3',
                },
              ],
            },
          ],
        },
      },
    },
  },
  successfulInforceMpar3906177: {
    status: 'Success',
    entitlements: {
      status: 'Entitled',
      product: 'Par',
    },
    policyData: {
      status: 'Inforce',
      illustration: {
        user: {},
        party: [
          {
            partyId: '10152368',
            gender: 'Male',
            firstName: 'HRHA WKDYU',
            lastName: 'KFEBYXS',
            addressProvince: 'QC',
          },
          {
            partyId: '10152374',
            age: 32,
            gender: 'Female',
            firstName: 'That',
            lastName: 'Innocent',
          },
          {
            partyId: '10152370',
            age: 5,
            gender: 'Male',
            firstName: 'BB',
            lastName: 'One',
          },
          {
            partyId: '10152371',
            age: 6,
            gender: 'Male',
            firstName: 'BBT',
            lastName: 'Wo',
          },
          {
            partyId: '10152372',
            age: 7,
            gender: 'Female',
            firstName: 'Th',
            lastName: 'Ree',
          },
          {
            partyId: '10152373',
            age: 8,
            gender: 'Female',
            firstName: 'Fo',
            lastName: 'Ur',
          },
          {
            partyId: '10152369',
            age: 32,
            gender: 'Female',
            firstName: 'That',
            lastName: 'Innocent',
          },
        ],
        policy: {
          policyNumber: '3906177',
          product: 'Par',
          productFamily: 'Par',
          businessType: 'Inforce',
          annualizedInitialModalPremium: 6716.04,
          automaticPremiumLoanAmount: 0,
          ownerId: '10152368',
          depositMode: 'Annually',
          depositOption: {
            type: 'Specified',
            modalAmount: 4575.68,
          },
          effectiveDate: '2023-11-28',
          initialModalPremium: 6716.04,
          loanAmount: 0,
          maximumAdditionalDepositAmt: 124890.02,
          minimumModalPremium: 11291.72,
          status: 'Inforce',
          vitality: {
            type: 'Plus',
            life: [
              {
                lifeId: '10152368',
                status: ['B:1'],
              },
            ],
            coverage: [
              {
                coverageId: '2',
                lifeId: '10152368',
              },
            ],
          },
          coverage: [
            {
              coverageId: '2',
              product: 'Pay90',
              coverageType: 'JointLtd',
              effectiveDate: '2023-11-28',
              coverageAmount: 250000,
              dividendOption: {
                type: 'Pui',
              },
              equivalentAge: 30,
              healthstyle: 'HS3',
              indicatorCode: 'Base',
              minimumModalPremium: 3544.92,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152368',
                  age: 30,
                  healthstyle: 'HS5',
                },
                {
                  partyId: '10152374',
                  age: 32,
                  healthstyle: 'HS5',
                },
              ],
              lifeRating: [
                {
                  permanentPercent: 100,
                  permanentAmount: 0,
                  temporaryAmount: 0,
                  temporaryDuration: 1,
                },
              ],
            },
            {
              coverageId: '3',
              parentCoverageId: '2',
              product: 'PUI',
              coverageType: 'Single',
              effectiveDate: '2023-11-28',
              coverageAmount: 0,
              equivalentAge: 30,
              healthstyle: 'HS3',
              indicatorCode: 'Enhancer',
              minimumModalPremium: 0,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152368',
                  age: 30,
                  healthstyle: 'HS5',
                },
              ],
              lifeRating: [
                {
                  partyId: '10152368',
                  permanentPercent: 101,
                },
              ],
            },
            {
              coverageId: '4',
              parentCoverageId: '2',
              product: 'DOI',
              coverageType: 'Single',
              effectiveDate: '2023-11-28',
              coverageAmount: 24953.94,
              equivalentAge: 30,
              healthstyle: 'HS3',
              indicatorCode: 'Enhancer',
              minimumModalPremium: 0,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152368',
                  age: 30,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '5',
              product: 'GIO',
              coverageType: 'Single',
              effectiveDate: '2023-11-28',
              coverageAmount: 250000,
              equivalentAge: 30,
              healthstyle: 'HS3',
              indicatorCode: 'Rider',
              minimumModalPremium: 343.08,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152368',
                  age: 30,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '6',
              product: 'Term20',
              coverageType: 'Single',
              effectiveDate: '2023-11-28',
              coverageAmount: 4000000,
              duration: 50,
              equivalentAge: 30,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 2756.04,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152368',
                  age: 30,
                  healthstyle: 'HS5',
                },
              ],
              lifeRating: [
                {
                  partyId: '10152368',
                  permanentPercent: 101,
                },
              ],
            },
            {
              coverageId: '7',
              product: 'Term10',
              coverageType: 'Single',
              effectiveDate: '2023-11-28',
              coverageAmount: 123456,
              duration: 30,
              equivalentAge: 30,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 978.08,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152368',
                  age: 30,
                  healthstyle: 'HS5',
                },
              ],
              lifeRating: [
                {
                  partyId: '10152368',
                  permanentPercent: 101,
                },
              ],
            },
            {
              coverageId: '8',
              associatedCoverageId: '9',
              product: 'Term20',
              coverageType: 'CombinedTir',
              effectiveDate: '2019-01-27',
              coverageAmount: 500000,
              duration: 40,
              equivalentAge: 30,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 418.08,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152368',
                  age: 30,
                  healthstyle: 'HS3',
                },
              ],
            },
            {
              coverageId: '9',
              associatedCoverageId: '8',
              product: 'Term20',
              coverageType: 'CombinedTir',
              effectiveDate: '2019-01-27',
              coverageAmount: 500000,
              duration: 40,
              equivalentAge: 31,
              healthstyle: 'HS3',
              indicatorCode: 'TermRider',
              minimumModalPremium: 1240.68,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152374',
                  age: 32,
                  healthstyle: 'HS3',
                },
              ],
              lifeRating: [
                {
                  partyId: '10152374',
                  permanentPercent: 150,
                },
              ],
            },
            {
              coverageId: '10',
              product: 'PTDW',
              coverageType: 'Single',
              effectiveDate: '2023-11-28',
              depositDuration: 0,
              duration: 33,
              durationEndDate: '2057-10-22',
              healthstyle: 'HS3',
              indicatorCode: 'Rider',
              minimumModalPremium: 70.44,
              terminationDate: '2057-10-22',
              status: 'Inforce',
              life: [
                {
                  partyId: '10152369',
                  age: 32,
                  healthstyle: 'HS5',
                },
              ],
              lifeRating: [
                {
                  partyId: '10152369',
                  permanentPercent: 101,
                },
              ],
            },
            {
              coverageId: '11',
              product: 'CPR',
              coverageType: 'Single',
              effectiveDate: '2022-01-16',
              coverageAmount: 10000,
              equivalentAge: 5,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 28.44,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152370',
                  age: 5,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '12',
              product: 'CPR',
              coverageType: 'Single',
              effectiveDate: '2022-01-16',
              coverageAmount: 10000,
              equivalentAge: 6,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 28.44,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152371',
                  age: 6,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '13',
              product: 'CPR',
              coverageType: 'Single',
              effectiveDate: '2022-01-16',
              coverageAmount: 10000,
              equivalentAge: 7,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 28.44,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152372',
                  age: 7,
                  healthstyle: 'HS5',
                },
              ],
            },
            {
              coverageId: '14',
              product: 'CPR',
              coverageType: 'Single',
              effectiveDate: '2022-01-16',
              coverageAmount: 10000,
              equivalentAge: 8,
              healthstyle: 'HS5',
              indicatorCode: 'Rider',
              minimumModalPremium: 28.44,
              status: 'Inforce',
              life: [
                {
                  partyId: '10152373',
                  age: 8,
                  healthstyle: 'HS5',
                },
              ],
            },
          ],
        },
      },
    },
  },
};

export const mulCoverage = {
  scenarioId: scenario1,
  activeTabId: coverage,
  isMultiCov: false,
  tabNavs: [coverage],
  'Coverage.1': {
    amountOfInsuranceType: 'Specified',
    costOfInsurance: 'Level100',
    costOfInsuranceSwitchYear: false,
    coverageAmount: 500000,
    coverageOption: 'Term10',
    coverageType: 'Single',
    deathBenefit: 100,
    deathBenefitType: 'FacePlus',
    dividendOption: 'Pui',
    equivalentAge: undefined,
    errors: {},
    id: coverage,
    isExpandedInSummary: false,
    minClientAge: 45,
    premiumAmount: 6525.96,
    premiumDuration: 'Pay10',
    ratings: {
      'Client.1': {
        permRatingAmount: 0,
        permRatingPercent: 100,
        tempRatingAmount: 0,
        tempRatingYear: 1,
      },
    },
    specialQuoteOption: 'None',
    switchToCashDividends: false,
    switchToCashDividendsYear: 2,
    switchYear: 5,
    isVitalityPlusSelected: false,
  },
};

export const mulDeposit = {
  depositAmount: 6525.96,
  depositFrequency: 'Annually',
  depositOwnerResidence: 'ON',
  depositType: 'Specified',
  durationEnd: 100,
  durationPartyId: 'Client.1',
  durationStart: 46,
  durationType: 'Age',
  isAdditionalDepositSelected: false,
  isFolded: false,
  selectOwnersResidenceClicked: false,
};

export const mulWithdrawal = {
  amount: 100,
  customAmount: [],
  durationEnd: 100,
  durationPartyId: 'Client.1',
  durationStart: 47,
  durationType: 'Age',
  isFolded: false,
  type: 'None',
};

export const MOCK_STATE = {
  app: {
    locale: 'en',
    statusDialog: {
      showStatusDialog: false,
      statusMessage: '',
    },
    inforceData: {
      policy: {
        effectiveDate: '2014-09-21',
      },
    },
  },

  location: {
    pathname: '/custom-mode/scenarios/clientcoverage',
  },

  coverages: {
    activeTabId: coverage,
    'Coverage.1': {
      isCoverageSolve: false,
      coverageType: eCoverageType.single,
      coverageOption: eCoverageOption.t10,
      errors: {},
    },
  },

  coverage: {
    isCoverageSolve: false,
    coverageType: eCoverageType.single,
    coverageOption: eCoverageOption.t10,
    includeMaxLevelDepositOption: true,
    coverageAmount: 100000,
    minClientAge: 45,
    errors: {},
    lcReturnPremiums: {
      ropd: true,
      rops: false,
      ropx: false,
    },
  },

  coverageMUL: {
    coverageAmount: 500000,
    isCoverageSolve: false,
    coverageType: eCoverageType.single,
    specialQuoteOption: eSpecialQuote.none,
    deathBenefitType: eDeathType.facePlus,
    costOfInsurance: eCOIType.yrtToAge85,
    amountOfInsuranceType: eInsuranceType.specified,
    deathBenefit: 100,
    costOfInsuranceSwitchYear: false,
    switchYear: 5,
  },

  coverageMULV: {
    coverageAmount: 500000,
    isCoverageSolve: false,
    coverageType: eCoverageType.single,
    specialQuoteOption: eSpecialQuote.none,
    deathBenefitType: eDeathType.facePlus,
    costOfInsurance: eCOIType.yrtToAge85,
    amountOfInsuranceType: eInsuranceType.specified,
    deathBenefit: 100,
    costOfInsuranceSwitchYear: false,
    switchYear: 5,
    isVitalityPlusSelected: false,
    isDefaultChangeToBronzeAge: true,
    isChangeToBronze: true,
    changeToBronzeAge: 65,
  },

  coveragePARV: {
    isCoverageSolve: false,
    coverageType: eCoverageType.single,
    coverageOption: eCoverageOption.t10,
    includeMaxLevelDepositOption: true,
    coverageAmount: 100000,
    minClientAge: 45,
    errors: {},
    isVitalityPlusSelected: false,
    specialQuoteOption: 'None',
  },

  coveragePG: {
    coverageAmount: 500000,
    coverageType: eCoverageType.single,
    costDuration: eCostDuration.pay100,
    errors: {},
  },

  depositOption: {
    type: eDefault.depositOptionType,
    amount: eDefault.depositOptionAmount,
    durationType: eDefault.depositOptionDurationType,
    duration: eDefault.depositOptionDuration,
  },

  vitalityStatus: {
    activeTabId: 'Scenario.1',
    'Scenario.1': [
      {
        vitalityStatus: 'Gold',
        fromYear: 2,
      },
    ],
  },

  report: {
    activeTabId: 'Scenario.0',
    'Scenario.0': {
      isLoading: false,
      isOpen: false,
      lang: eLang.en,
      layout: eReportLayoutType.allyears,
      preparedFor: '',
      reportType: eReportType.base,
      selectAll: false,
      [eReport.cd]: false,
      [eReport.irr]: false,
      [eReport.pod]: false,
      [eReport.pos]: false,
      [eReport.tcop]: false,
      [eReport.tpop]: false,
      [eReport.tpcd]: false,
      [eReport.td]: false,
      [eReport.ur]: false,
      errors: {},
    },
  },

  spreadsheet: {
    activeTabId: scenario1,
    [scenario1]: {
      errors: {},
      isLoading: false,
      isOpen: false,
    },
  },
  spreadsheetResponse: [
    {
      coverageId: '',
      type: eSpreadsheetFields.year,
      basisType: 'Base',
      coverageProduct: 'Par',
      title: 'Year',
      values: [1, 2, 3, 4, 5],
    },
  ],

  riders: {
    [eRider.tdw]: { isSelected: false },
    [eRider.wpd]: { isSelected: false },
    [eRider.bvp]: { isSelected: false },
    [eRider.clc]: { isSelected: false },
    [eRider.gio]: { isSelected: false },
    [eRider.adnd]: { isSelected: false },
    [eRider.adb]: { isSelected: false },
    [eRider.cpr]: { isSelected: false, allChildren: [] },
    [eRider.tdwpayor]: { isSelected: false, payor: {} },
    isFolded: false,
  },

  clients: {
    activeTabId: scenario1,
    [scenario1]: {
      allClients: ['Client.1'],
      'Client.1': client1Mock,
    },
  },

  singleClient: {
    allClients: ['Client.1'],
    'Client.1': client1Mock,
  },

  multiClients: {
    activeTabId: scenario1,
    [scenario1]: {
      allClients: ['Client.1', 'Client.2'],
      'Client.1': { ...client1Mock, errors: {} },
      'Client.2': { ...client2Mock, errors: {} },
    },
  },

  landingPage: {
    product: '',
    entitledProduct: '',
    errorStatus: '',
    policyNumber: '',
    businessType: '',
    errors: '',
    isLoading: false,
    isError: false,
  },

  cpr: {
    'Child.1': {
      id: 'Child.1',
      age: 0,
      permRatingAmount: 0,
      permRatingPercent: 100,
      riderId: 'Rider.1',
      tempRatingAmount: 0,
      tempRatingYear: 1,
      errors: {},
    },
    allChildren: ['Child.1'],
  },

  termRiders: {
    activeTabId: scenario1,
    [scenario1]: {
      'Rider.1': {
        coverageType: eCoverageType.single,
        coverageOption: eCoverageOption.t10,
        coverageAmount: 100000,
        duration: getTermRiderDuration(client1Mock.age),
        clientIds: ['Client.1'],
        clientId: 'Client.1',
        isFolded: false,
        id: 'Rider.1',
        ratings: {
          'Client.1': { permRatingAmount: 0, permRatingPercent: 100, tempRatingAmount: 0, tempRatingYear: 1 },
        },
      },
      allRiders: ['Rider.1'],
    },
  },

  termOtherRiders: {
    activeTabId: scenario1,
    [scenario1]: {
      'Rider.1': {
        coverageType: eCoverageType.single,
        coverageOption: eCoverageOption.t10,
        coverageAmount: 100000,
        duration: getPerformaxTermRiderDuration(client1Mock.age, eCoverageOption.t05),
        clientIds: ['Client.1'],
        clientId: 'Client.1',
        isFolded: false,
        id: 'Rider.1',
      },
      allRiders: ['Rider.1'],
    },
  },

  spousalRiders: {
    activeTabId: scenario1,
    [scenario1]: {
      'Rider.1': {
        coverageType: eCoverageType.single,
        coverageOption: eCoverageOption.t10,
        coverageAmount: 100000,
        duration: getPerformaxTermRiderDuration(client1Mock.age, eCoverageOption.t05),
        clientIds: ['Client.1'],
        clientId: 'Client.1',
        isFolded: false,
        id: 'Rider.1',
      },
      allRiders: ['Rider.1'],
    },
  },

  tdwPayor: {
    'Payor.1': {
      id: 'Payor.1',
      age: 45,
      permRatingAmount: 0,
      permRatingPercent: 100,
      riderId: 'Rider.1',
      tempRatingAmount: 0,
      tempRatingYear: 1,
      errors: {},
    },
    payor: { id: 'Payor.1' },
  },
  prwPayor: {
    'Payor.1': {
      id: 'Payor.1',
      age: 45,
      permRatingAmount: 0,
      permRatingPercent: 100,
      riderId: 'Rider.1',
      tempRatingAmount: 0,
      tempRatingYear: 1,
    },
    payor: { id: 'Payor.1' },
  },

  udmResponse: {
    status: 'Success',
    validationStatus: '',
    isValidationError: false,
    validationErrors: [{ message: [{ text: testError }] }],
    validationWarnings: [],
    illustration: {
      party: [
        {
          partyId: 'Client.1',
          birthdate: null,
          age: 35,
          gender: 'Male',
          firstName: '',
          lastName: '',
        },
      ],
      policy: {
        coverage: [
          {
            coverageId: coverage,
            equivalentAge: 20,
          },
        ],
        depositAmount: 28.31,
        duration: 45,
        minimumModalPremium: 28.31,
        policyFee: 5.1,
        product: eProduct.PAR,
        policyLife: [
          {
            partyId: 'Client.1',
            totalInsuredCoverage: 500000,
            coverageSummary: [
              {
                product: 'TDW',
                totalMinimumModalPremium: 3.01,
              },
            ],
          },
          {
            partyId: 'Child.1',
            coverageSummary: [
              {
                product: 'CPR',
                totalMinimumModalPremium: 2.5,
              },
            ],
          },
        ],
      },
    },
  },

  configs: {
    vitalityStatus: [
      {
        label: 'Platinum',
        value: 'Platinum',
      },
      {
        label: 'Gold',
        value: 'Gold',
      },
      {
        label: 'Silver',
        value: 'Silver',
      },
      {
        label: 'Bronze',
        value: 'Bronze',
      },
    ],
    languages: [
      { label: eLang.en, value: eLang.en },
      { label: eLang.fr, value: eLang.fr },
    ],
    concept: [
      { label: 'corporate.NONE', value: '' },
      { label: 'corporate.IRP', value: 'InsuredRetirementProgram' },
      { label: 'corporate.EB', value: 'EstateBond' },
    ],
    companyTypeOptions: [
      { label: 'corporateIRPStructure.holdingCompany', value: eCompanyType.holdingCompany },
      { label: 'corporateIRPStructure.operatingCompany', value: eCompanyType.operatingCompany },
    ],
    conceptDividendScales: [
      { label: 'conceptdivscale.primary', value: eConceptDividendScale.primary },
      { label: 'conceptdivscale.alternate', value: eConceptDividendScale.alternate },
    ],
    premiumDurations: [
      { label: 'premiumDuration.pay10', value: ePremiumDuration.pay10, amount: 10 },
      { label: 'premiumDuration.pay20', value: ePremiumDuration.pay20, amount: 20 },
      { label: 'premiumDuration.Pay90', value: ePremiumDuration.Pay90, amount: 90 },
      { label: 'premiumDuration.Pay100', value: ePremiumDuration.Pay100, amount: 100 },
    ],
    specialQuoteOptionPAR: [
      { label: 'specialQuote.opt.none', value: eSpecialQuote.none },
      { label: 'specialQuote.opt.S1', value: eSpecialQuote.S1 },
      { label: 'specialQuote.opt.S2', value: eSpecialQuote.S2 },
    ],
  },
  scenarioTabNavs: {
    activeTabId: scenario1,
    isOutOfDate: false,
    udmEnabled: false,
    tabNavs: [
      {
        id: scenario1,
      },
    ],
    topBars: {
      [scenario1]: {
        activeTopBarIndex: 1,
        illustrationId: 'E4GHT3S',
        product: 'Par',
        isRiderOpened: false,
        productValidationError: false,
        effectiveDate: '2018-10-15',
        concept: 'None',
        primaryDividendScale: 'Current',
        alternateDividendScale: 'CurrentLess100bp',
        marginalTaxRate: 50,
        policyInterestRate: eDefault.policyInterestRate,
        sideAccountInterestRate: eDefault.sideAccountInterestRate,
        premiumOffset: eDefault.premiumOffset,
        ratings: {
          'Client.1': { permRatingAmount: 0, permRatingPercent: 100, tempRatingAmount: 0, tempRatingYear: 1 },
        },
        coverageType: eCoverageType.single,
        client1: client1Mock,
        [eField.scenarioName]: '',
      },
    },
  },
  coverageTabNavs: {
    activeTabId: scenario1,
    [scenario1]: {
      scenarioId: scenario1,
      activeTabId: coverage,
      ratings: {
        'Client.1': { permRatingAmount: 0, permRatingPercent: 100, tempRatingAmount: 0, tempRatingYear: 1 },
        'Client.2': { permRatingAmount: 0, permRatingPercent: 100, tempRatingAmount: 0, tempRatingYear: 1 },
      },
      coverageType: eCoverageType.single,
      isMultiCov: false,
      tabNavs: [coverage, 'Coverage.2'],
      'Coverage.1': {
        id: coverage,
        coverageType: eCoverageType.single,
        coverageOption: 'Term10',
        coverageAmount: 500000,
        toFaceAmount: 10000.0,
        topFaceAmount: 15000.0,
        coverageTabId: coverage,
        premiumDuration: ePremiumDuration.pay10,
        premiumAmount: 0,
        isCoverageSolve: false,
        premiumFrequency: 'Monthly',
        costDuration: eCostDuration.pay100,
        ratings: {
          'Client.1': { permRatingAmount: 0, permRatingPercent: 100, tempRatingAmount: 0, tempRatingYear: 1 },
          'Client.2': { permRatingAmount: 0, permRatingPercent: 100, tempRatingAmount: 0, tempRatingYear: 1 },
        },
        errors: {},
      },
      'Coverage.2': {
        id: 'Coverage.2',
        coverageType: eCoverageType.single,
        coverageOption: 'Term10',
        coverageAmount: 500000,
        toFaceAmount: 10000.0,
        topFaceAmount: 15000.0,
        coverageTabId: 'Coverage.2',
        premiumAmount: 0,
        isCoverageSolve: false,
        premiumFrequency: 'Monthly',
        ratings: {
          'Client.1': { permRatingAmount: 0, permRatingPercent: 100, tempRatingAmount: 0, tempRatingYear: 1 },
          'Client.2': { permRatingAmount: 0, permRatingPercent: 100, tempRatingAmount: 0, tempRatingYear: 1 },
        },
        errors: {},
      },
      inforce: false,
    },
  },

  advisorProfile: {
    isOpen: false,
    currentProfileId: eDefault.advisorProfile,
    allProfiles: [],
    // [eDefault.advisorProfile]: {
    //   guid: eDefault.advisorProfile,
    //   advisorName: eDefault.advisorProfile,
    //   companyName: '',
    //   phoneNumber: '',
    //   extension: '',
    //   email: '',
    //   errors: {}
    // },
    workingProfile: {
      guid: '',
      advisorName: '',
      companyName: '',
      residence: '',
      phoneNumber: null,
      extension: '',
      email: null,
      errors: {},
    },
  },

  conceptTabNavs: {
    [scenario1]: {
      scenarioId: scenario1,
      isOpen: false,
      taxInformation: { ...eDefault.taxInformation, isFolded: false },
      personalDividendTaxRate: eDefault.personalDividendTaxRate,
      alternateInvestment: {
        investmentAllocation: { ...eDefault.investmentAllocation },
        isAllocationError: false,
        isIllustrateChecked: false,
        isFolded: false,
      },
      lifeExpectancyAssumption: { ...eDefault.lifeExpectancyAssumption, deathAtAge: 83, isFolded: false },
      corporateIRPStructure: {
        investmentIncomeTax: eDefault.corporateIRPStructure.investmentIncomeTax,
        operatingIncomeTax: eDefault.corporateIRPStructure.operatingIncomeTax,
        borrowingType: eDefault.corporateIRPStructure.borrowingType,
        companyType: eCompanyType.holdingCompany,
        isFolded: false,
      },
      dividendScale: {
        type: eConceptDividendScale.primary,
        isFolded: false,
      },
      taxInformationRates: {
        personalDividendTaxRate: 35,
        presentValueDiscountRate: 3,
        isFolded: false,
      },
      loanInformation: {
        [eField.assumeInterestDeductibility]: false,
        [eField.collateralInsuranceDeduction]: false,
        [eField.loanAmountSelection]: eLoanAmountSelection.solveMaximum,
        bankLoanRate: 5.75,
        csvLoanPercentage: 90,
        durationType: 'Age',
        durationEnd: 65,
        durationStart: 65,
        isFolded: false,
      },
      errors: {},
    },
    isOpen: true,
  },

  myCases: {
    isOpen: false,
    isLoading: false,
    errors: {},
    startNewIllustration: false,
  },

  withdrawals: {
    activeTabId: scenario1,
    [scenario1]: {
      scenarioId: scenario1,
      [eField.duration]: 'Age',
      [eField.durationFrom]: '',
      [eField.durationTo]: '',
      [eField.withdrawalTypeOption]: 'beforeTax',
      [eField.withdrawalAmountOption]: 1,
      [eField.basedonClient]: 'Client.1',
    },
  },

  deposits: {
    activeTabId: scenario1,
    [scenario1]: {
      scenarioId: scenario1,
      [eField.duration]: 'Age',
      [eField.durationFrom]: '',
      [eField.durationTo]: '',
      [eField.depositOwnerResidence]: 'Ontario',
      [eField.depositType]: 'Specified',
      [eField.depositFrequency]: eDepositFrequency.annual,
      [eField.depositAmount]: 0.0,
      [eField.depositAdditionalDeposit]: 0.0,
      [eField.basedonClient]: 'Client.1',
      inforce: false,
    },
  },
  shareIllustrationModel: {
    landingPage: {
      selectedProduct: 'Par',
    },
    scenarioTabNavs: {
      manualRefresh: true,
      activeTabId: scenario2,
      isOutOfDate: true,
      udmEnabled: false,
      isForceUpdate: false,
      isSpreadsheetView: false,
      tabNavs: [
        {
          id: scenario1,
        },
        {
          id: scenario1,
        },
      ],
      topBars: {
        [scenario1]: {
          scenarioName: 'ChildFN',
          illustrationId: '',
          activeTopBarIndex: 1,
          product: 'Par',
          isRiderOpened: false,
          isTermRiderFolded: false,
          isCoverageSummaryFolded: false,
          isRiderSummaryFolded: false,
          isIllustrationRatesFolded: false,
          isAdditionalCostsFolded: false,
          productValidationError: false,
          premiumFrequency: 'Annually',
          effectiveDate: '26/08/2021',
          primaryDividendScale: 'Current',
          alternateDividendScale: 'CurrentLess100bp',
          premiumOffset: 'None',
          premiumOffsetYear: '11',
          depositOptionType: 'None',
          isDepositOptionFolded: false,
          isPremiumOffsetFolded: false,
          includeMaxLevelDepositOption: true,
          depositOptionAmount: 0,
          isDepositOptionCustom: false,
          depositOptionPaymentCache: {},
          depositOptionDurationType: 'Year',
          depositOptionDuration: 10,
          marginalTaxRate: 50,
          policyInterestRate: 0,
          sideAccountInterestRate: 0,
          errors: {},
          activeTabViewTab: 2,
          concept: 'None',
        },
        [scenario2]: {
          scenarioName: 'TestScenario2',
          illustrationId: '',
          activeTopBarIndex: 1,
          product: 'Par',
          isRiderOpened: false,
          isTermRiderFolded: false,
          isCoverageSummaryFolded: false,
          isRiderSummaryFolded: false,
          isIllustrationRatesFolded: false,
          isAdditionalCostsFolded: false,
          productValidationError: false,
          premiumFrequency: 'Annually',
          effectiveDate: '26/08/2021',
          primaryDividendScale: 'Current',
          alternateDividendScale: 'CurrentLess100bp',
          premiumOffset: 'None',
          premiumOffsetYear: '11',
          depositOptionType: 'None',
          isDepositOptionFolded: false,
          isPremiumOffsetFolded: false,
          includeMaxLevelDepositOption: true,
          depositOptionAmount: 0,
          isDepositOptionCustom: false,
          depositOptionPaymentCache: {},
          depositOptionDurationType: 'Year',
          depositOptionDuration: 10,
          marginalTaxRate: 50,
          policyInterestRate: 0,
          sideAccountInterestRate: 0,
          errors: {},
          activeTabViewTab: 2,
          concept: 'None',
        },
      },
    },
    coverageTabNavs: {
      activeTabId: scenario1,
      product: 'Par',
      [scenario1]: {
        scenarioId: scenario1,
        activeTabId: coverage,
        coverageIndex: 2,
        isMultiCov: false,
        tabNavs: [coverage],
        'Coverage.1': {
          id: coverage,
          switchYear: 5,
          costOfInsuranceSwitchYear: false,
          deathBenefit: 100,
          coverageType: 'Single',
          coverageOption: 'Term10',
          amountOfInsuranceType: 'Specified',
          specialQuoteOption: 'None',
          premiumAmount: 0,
          premiumDuration: 'Pay10',
          isCoverageSolve: false,
          dividendOption: 'Pui',
          switchToCashDividends: false,
          switchToCashDividendsYear: 2,
          ratings: {
            'Client.1': {
              permRatingAmount: 0,
              permRatingPercent: 100,
              tempRatingAmount: 0,
              tempRatingYear: 1,
            },
          },
          deathBenefitType: 'FacePlus',
          costOfInsurance: 'Level100',
          errors: {},
          isExpandedInSummary: false,
          minClientAge: 45,
          coverageAmount: 100000,
          isVitalityPlusSelected: false,
        },
      },
      [scenario2]: {
        scenarioId: scenario1,
        activeTabId: coverage,
        coverageIndex: 2,
        isMultiCov: false,
        tabNavs: [coverage],
        'Coverage.1': {
          id: coverage,
          switchYear: 5,
          costOfInsuranceSwitchYear: false,
          deathBenefit: 100,
          coverageType: 'Single',
          coverageOption: 'Term10',
          amountOfInsuranceType: 'Specified',
          specialQuoteOption: 'None',
          premiumAmount: 0,
          premiumDuration: 'Pay10',
          isCoverageSolve: false,
          dividendOption: 'Pui',
          switchToCashDividends: false,
          switchToCashDividendsYear: 2,
          ratings: {
            'Client.1': {
              permRatingAmount: 0,
              permRatingPercent: 100,
              tempRatingAmount: 0,
              tempRatingYear: 1,
            },
          },
          deathBenefitType: 'FacePlus',
          costOfInsurance: 'Level100',
          errors: {},
          isExpandedInSummary: false,
          minClientAge: 45,
          coverageAmount: 100000,
          isVitalityPlusSelected: false,
        },
      },
    },
    conceptTabNavs: {
      activeTabId: scenario1,
      [scenario1]: {
        scenarioId: scenario1,
        isOpen: false,
        taxInformation: {
          corporateTaxRate: 50,
          isFolded: false,
        },
        personalDividendTaxRate: 35,
        alternateInvestment: {
          investmentAllocation: {
            interest: {
              percentage: 100,
              rate: 6,
            },
            dividends: {
              percentage: 0,
              rate: 6,
            },
            deferredCapitalGains: {
              percentage: 0,
              rate: 6,
            },
            realizedCapitalGains: {
              percentage: 0,
              rate: 6,
            },
          },
          isAllocationError: false,
          isIllustrateChecked: false,
          isFolded: false,
        },
        lifeExpectancyAssumption: {
          additionalYears: 5,
          isFolded: false,
        },
        corporateIRPStructure: {
          borrowingType: 'Corporate',
          investmentIncomeTax: 50,
          operatingIncomeTax: 40,
          companyType: 'Holding',
          isFolded: false,
        },
        dividendScale: {
          type: 'Primary',
          isFolded: false,
        },
        taxInformationRates: {
          presentValueDiscountRate: 3,
          isFolded: false,
        },
        loanInformation: {
          assumeInterestDeductibility: false,
          collateralInsuranceDeduction: true,
          loanAmount: 'MaximumSolve',
          amount: 1,
          durationType: 'Age',
          isFolded: false,
        },
        errors: {},
      },
      [scenario2]: {
        scenarioId: scenario1,
        isOpen: false,
        taxInformation: {
          corporateTaxRate: 50,
          isFolded: false,
        },
        personalDividendTaxRate: 35,
        alternateInvestment: {
          investmentAllocation: {
            interest: {
              percentage: 100,
              rate: 6,
            },
            dividends: {
              percentage: 0,
              rate: 6,
            },
            deferredCapitalGains: {
              percentage: 0,
              rate: 6,
            },
            realizedCapitalGains: {
              percentage: 0,
              rate: 6,
            },
          },
          isAllocationError: false,
          isIllustrateChecked: false,
          isFolded: false,
        },
        lifeExpectancyAssumption: {
          additionalYears: 5,
          isFolded: false,
        },
        corporateIRPStructure: {
          borrowingType: 'Corporate',
          investmentIncomeTax: 50,
          operatingIncomeTax: 40,
          companyType: 'Holding',
          isFolded: false,
        },
        dividendScale: {
          type: 'Primary',
          isFolded: false,
        },
        taxInformationRates: {
          presentValueDiscountRate: 3,
          isFolded: false,
        },
        loanInformation: {
          assumeInterestDeductibility: false,
          collateralInsuranceDeduction: true,
          loanAmount: 'MaximumSolve',
          amount: 1,
          durationType: 'Age',
          isFolded: false,
        },
        errors: {},
      },
    },
    clients: {
      activeTabId: scenario1,
      clientIndex: 2,
      [scenario1]: {
        allClients: ['Client.1'],
        'Client.1': {
          id: 'Client.1',
          firstName: 'TestFN',
          lastName: 'TestLN',
          placeholder: 'Client 1',
          sex: 'Male',
          dob: '',
          errors: {
            firstName: '',
            lastName: '',
          },
          fullName: 'TestFN TestLN',
          age: 45,
          healthstyle: '3',
          smokingstatus: 'NonSmoker',
          coverageTabId: [coverage],
        },
      },
      [scenario2]: {
        allClients: ['Client.1'],
        'Client.1': {
          id: 'Client.1',
          firstName: '',
          lastName: '',
          placeholder: 'Client 1',
          sex: 'Male',
          dob: '',
          errors: {
            firstName: '',
            lastName: '',
          },
          fullName: '',
          age: 45,
          healthstyle: '3',
          smokingstatus: 'NonSmoker',
          coverageTabId: [coverage],
        },
      },
    },
    riders: {
      activeTabId: scenario1,
      [scenario1]: {
        TDW: {
          isSelected: false,
        },
        BVP: {
          isSelected: false,
        },
        GIO: {
          isSelected: false,
        },
        ADD: {
          isSelected: false,
        },
        CPR: {
          isSelected: true,
          allChildren: ['Child.1'],
          'Child.1': {
            id: 'Child.1',
            firstName: 'ChildFN',
            lastName: 'ChildLN',
            placeholder: '',
            sex: 'Male',
            dob: '',
            errors: {
              firstName: '',
              lastName: '',
            },
            age: 0,
            permRatingAmount: 0,
            permRatingPercent: 100,
            tempRatingAmount: 0,
            tempRatingYear: 1,
            riderId: 'Rider.1',
            fullName: 'ChildFN ChildLN',
          },
        },
        PTDW: {
          isSelected: true,
          payor: {
            id: 'Payor.1',
          },
          'Payor.1': {
            id: 'Payor.1',
            firstName: 'PayerFN',
            lastName: 'PayerLN',
            placeholder: 'Payor 1',
            sex: 'Male',
            dob: '',
            errors: {
              firstName: '',
              lastName: '',
            },
            fullName: 'PayerFN PayerLN',
            age: 45,
            healthstyle: '3',
            smokingstatus: 'NonSmoker',
            riderId: 'Rider.2',
          },
          ratings: {
            'Payor.1': {
              permRatingPercent: 100,
            },
          },
        },
        isFolded: false,
      },
      [scenario2]: {
        TDW: {
          isSelected: false,
        },
        BVP: {
          isSelected: false,
        },
        GIO: {
          isSelected: false,
        },
        ADD: {
          isSelected: false,
        },
        CPR: {
          isSelected: true,
          allChildren: ['Child.1'],
          'Child.1': {
            id: 'Child.1',
            firstName: '',
            lastName: '',
            placeholder: '',
            sex: 'Male',
            dob: '',
            errors: {
              firstName: '',
              lastName: '',
            },
            age: 0,
            permRatingAmount: 0,
            permRatingPercent: 100,
            tempRatingAmount: 0,
            tempRatingYear: 1,
            riderId: 'Rider.1',
            fullName: '',
          },
        },
        PTDW: {
          isSelected: true,
          payor: {
            id: 'Payor.1',
          },
          'Payor.1': {
            id: 'Payor.1',
            firstName: '',
            lastName: '',
            placeholder: 'Payor 1',
            sex: 'Male',
            dob: '',
            errors: {
              firstName: '',
              lastName: '',
            },
            fullName: '',
            age: 45,
            healthstyle: '3',
            smokingstatus: 'NonSmoker',
            riderId: 'Rider.2',
          },
          ratings: {
            'Payor.1': {
              permRatingPercent: 100,
            },
          },
        },
        isFolded: false,
      },
    },
    termRiders: {
      activeTabId: scenario1,
      [scenario1]: {
        allRiders: [],
      },
      [scenario2]: {
        allRiders: [],
      },
    },
    vitalityStatus: {
      activeTabId: scenario1,
      [scenario1]: [
        {
          vitalityStatus: 'Gold',
          fromYear: 2,
        },
      ],
      [scenario2]: [
        {
          vitalityStatus: 'Gold',
          fromYear: 2,
        },
      ],
    },
    report: {
      activeTabId: scenario1,
      [scenario1]: {
        isOpen: false,
        isLoading: false,
        lang: 'English',
        layout: 'AllYears',
        preparedFor: '',
        reportType: 'Base',
        errors: {},
        selectAll: false,
        coverageDetails: false,
        irrValues: false,
        offsetDetailed: false,
        offsetSummary: false,
        corporateTaxValues: false,
        personalTaxValues: false,
        cashTaxValues: false,
        taxDetails: false,
        underwritingRequirements: false,
      },
      [scenario2]: {
        isOpen: false,
        isLoading: false,
        lang: 'English',
        layout: 'AllYears',
        preparedFor: '',
        reportType: 'Base',
        errors: {},
        selectAll: false,
        coverageDetails: false,
        irrValues: false,
        offsetDetailed: false,
        offsetSummary: false,
        corporateTaxValues: false,
        personalTaxValues: false,
        cashTaxValues: false,
        taxDetails: false,
        underwritingRequirements: false,
      },
    },
    spreadsheet: {
      activeTabId: scenario1,
      [scenario1]: {
        isLoading: false,
        isOpen: false,
        errors: {},
      },
      [scenario2]: {
        isLoading: false,
        isOpen: false,
        errors: {},
      },
    },
    advisorProfile: {
      isOpen: false,
      currentProfileId: 'Default',
      allProfiles: ['Default', '59903a84-4f13-467c-8811-efa9354ea2bf', '5c11ce0b-5a47-41fc-81ce-883068b7fc40'],
      Default: {
        guid: 'Default',
        advisorName: 'Default',
        companyName: '',
        phoneNumber: null,
        extension: '',
        email: null,
        errors: {},
      },
      workingProfile: {
        guid: 'Default',
        advisorName: 'Default',
        companyName: '',
        phoneNumber: null,
        extension: '',
        email: null,
        errors: {},
      },
      '59903a84-4f13-467c-8811-efa9354ea2bf': {
        guid: '59903a84-4f13-467c-8811-efa9354ea2bf',
        advisorName: 'Advisor sample',
        companyName: '',
        phoneNumber: '124-567-8900',
        extension: '555',
        email: 'advisorsample@abcfinancial.ca',
        errors: {},
      },
      '5c11ce0b-5a47-41fc-81ce-883068b7fc40': {
        guid: '5c11ce0b-5a47-41fc-81ce-883068b7fc40',
        advisorName: 'AAA',
        companyName: '',
        phoneNumber: '',
        extension: '',
        email: 'aaa@aaa.com',
        errors: {},
      },
    },
    myCases: {
      isOpen: false,
      isLoading: false,
      errors: {},
    },
    withdrawals: {
      activeTabId: scenario1,
      [scenario1]: {
        durationType: 'Age',
        durationStart: '',
        durationEnd: '',
        type: 'None',
        amount: 100,
        durationPartyId: 'Client.1',
        isFolded: false,
      },
      [scenario2]: {
        durationType: 'Age',
        durationStart: '',
        durationEnd: '',
        type: 'None',
        amount: 100,
        durationPartyId: 'Client.1',
        isFolded: false,
      },
    },
  },
  shareIllustrationUdm: {
    type: 'Validation',
    illustration: {
      illustrationId: '',
      version: '',
      mode: 'Custom',
      language: 'English',
      user: {
        userId: '',
        name: '',
        type: 'HeadOffice',
      },
      party: [
        {
          partyId: 'Client.1',
          age: 45,
          gender: 'Male',
          firstName: '',
          lastName: '',
        },
        {
          partyId: 'Child.1',
          age: 0,
          gender: 'Male',
          firstName: '',
          lastName: '',
        },
        {
          partyId: 'Payor.1',
          age: 45,
          gender: 'Male',
          firstName: '',
          lastName: '',
        },
      ],
      policy: {
        ownerId: null,
        advisorId: null,
        businessType: 'NewBusiness',
        product: 'Par',
        depositAmount: null,
        depositMode: 'Annually',
        effectiveDate: '2021-08-26',
        premiumOffset: {
          type: 'None',
          alternateStartYear: null,
        },
        rates: {
          marginalTaxRate: 50,
          alternateDividendScale: 'CurrentLess100bp',
          primaryDividendScale: 'Current',
        },
        specialQuote: 'None',
        solveType: 'Premium',
        coverage: [
          {
            coverageId: coverage,
            index: 1,
            coverageAmount: 100000,
            coverageType: 'Single',
            dividendOption: {
              type: 'Pui',
            },
            product: 'Pay10',
            specialQuote: 'None',
            life: [
              {
                partyId: 'Client.1',
                age: 45,
                healthstyle: 'NonSmoker',
              },
            ],
            lifeRating: [
              {
                partyId: 'Client.1',
                permanentPercent: 100,
                permanentAmount: 0,
                temporaryAmount: 0,
                temporaryDuration: 1,
              },
            ],
            vitality: null,
          },
          {
            coverageId: 'Rider.1',
            product: 'CPR',
            life: [
              {
                partyId: 'Child.1',
                age: 0,
              },
            ],
          },
          {
            coverageId: 'Rider.2',
            product: 'PTDW',
            life: [
              {
                partyId: 'Payor.1',
                age: 45,
                healthstyle: 'NonSmoker',
              },
            ],
            lifeRating: [
              {
                partyId: 'Payor.1',
                permanentPercent: 100,
              },
            ],
          },
        ],
      },
    },
  },
  shareIllustrationGuid: '4286124a-d0f7-4584-96fa-53718fcbcf48',
};

export const udmResponseVitalityHealthyLivingPDF = {
  status: 'Success',
  document: '',
  message: '',
};

export default MOCK_STATE;
