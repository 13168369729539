import React, { Profiler } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import sessionStorage from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primeflex/primeflex.css';

import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import reducers from './core/reducers';
import { isFeatureFlagSet } from './core/utils';
import App from './App';
import UnsupportedBrowserPage from './unsupported-browser/UnsupportedBrowserPage';
import sagas from './core/sagas';

import './index.css';
import 'babel-polyfill';
import thunk from 'redux-thunk';

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: 'root',
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
// hide redux dev tools in production and uat
const composeEnhancers = !isFeatureFlagSet() ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware, thunk)));

const persistor = persistStore(store);

sagaMiddleware.run(sagas);

const isIE11 = /MSIE|Trident/.test(window.navigator.userAgent);

if (isIE11) {
  ReactDOM.render(<UnsupportedBrowserPage />, document.getElementById('mwi-root'));
} else {
  ReactDOM.render(
    <Profiler>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Profiler>,
    document.getElementById('mwi-root')
  );
}

unregisterServiceWorker();
