import React from 'react';
import { H1, Button, BUTTON_VARIANT, MessageCard, MESSAGE_VARIANT } from '@manulife/mux';
import { intlShape, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Manulife } from '@manulife/mux-logos';
import { eLocale } from '../../../core/configs';
import './dashboard-title.css';
import {
  DASH_LOGO_HEADER_ID,
  DASH_TITLE_CONTAINER_ID,
  DASH_TITLE_ID,
  START_NEW_ILLUSTRATION_ID,
  DASH_APPLICATION_TAB_ID,
  START_NEW_APPLICATION_ID,
  START_TCA_APPLICATION_ID,
} from '../../../core/constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const TitleContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const getTabStyles = (intl) => {
  return {
    applicationTabWidth: intl.locale === eLocale.en ? '350px' : '400px',
    illustrationTabWidth: intl.locale === eLocale.en ? '263px' : '340px',
    applicationTcaTabWidth: intl.locale === eLocale.en ? '400px' : '450px',
  };
};

export const DashboardTitle = (props) => {
  const { intl, selectedTab, selectedSubTab, handleStartNewIllustrationClick, notificationMessage, showNotification } =
    props;

  const title = intl.formatMessage({ id: 'dashboard.title' });
  const { applicationTabWidth, illustrationTabWidth, applicationTcaTabWidth } = getTabStyles(intl);

  let buttonTitle = intl.formatMessage({ id: 'dashboard.startIllustration' });
  let buttonStyleWidth = illustrationTabWidth;
  let buttonId = START_NEW_ILLUSTRATION_ID;
  if (selectedTab === DASH_APPLICATION_TAB_ID) {
    if (selectedSubTab === DASH_APPLICATION_TAB_ID) {
      buttonTitle = intl.formatMessage({ id: 'dashboard.startApplication' });
      buttonStyleWidth = applicationTabWidth;
      buttonId = START_NEW_APPLICATION_ID;
    } else {
      buttonTitle = intl.formatMessage({ id: 'dashboard.startTcaApplication' });
      buttonStyleWidth = applicationTcaTabWidth;
      buttonId = START_TCA_APPLICATION_ID;
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div id={DASH_LOGO_HEADER_ID} className="logoHeader-Height">
          <Manulife.WORDMARK_BLACK //eslint-disable-line
            alt="Manulife Logo"
            role="Header-logo-Dashboard"
            variant={Manulife.BLACK_WORDMARK}
            style={{ height: '4rem', padding: '1rem' }}
            lang={intl.locale === eLocale.en ? 'en-CA' : 'fr-CA'}
          />
        </div>
        <div>
          {showNotification && (
            <MessageCard variant={MESSAGE_VARIANT.PRIMARY_COMPLETE} titleText="">
              {notificationMessage}
            </MessageCard>
          )}
        </div>
      </div>
      <TitleContainer id={DASH_TITLE_CONTAINER_ID}>
        <H1 id={DASH_TITLE_ID}>{title}</H1>
        <div>
          <Button
            type="submit"
            id={buttonId}
            ariaLabel={buttonTitle}
            variant={BUTTON_VARIANT.PRIMARY}
            customStyle={{
              buttonStyle: {
                width: buttonStyleWidth,
                height: selectedTab === DASH_APPLICATION_TAB_ID ? '50px' : '48px',
                padding: '12px',
                fontWeight: '300',
                fontSize: '19px',
                border: 'none',
              },
            }}
            onClick={handleStartNewIllustrationClick}
          >
            {buttonTitle}
          </Button>
        </div>
      </TitleContainer>
    </div>
  );
};

DashboardTitle.propTypes = {
  intl: intlShape.isRequired,
  selectedTab: PropTypes.string,
  selectedSubTab: PropTypes.string,
  handleStartNewIllustrationClick: PropTypes.func,
  showNotification: PropTypes.bool,
  notificationMessage: PropTypes.string,
};

export const mapStateToProps = ({ app }) => {
  return {
    notificationMessage: app.notificationMessage,
    showNotification: app.showNotification,
  };
};

export default connect(mapStateToProps)(injectIntl(DashboardTitle));
