import { getConvertStatus } from '../core/configs';

export const checkToShowCaseConversionNotification = (loadedCase, props) => {
  // Determine if dialog for letting user know that a saved case has been updated should be displayed or not
  // initialize using field in case it was set from scenarioTabNavReducer open save case action
  if (loadedCase.showNotification) {
    dispatchNotification(
      'showStatusDialog',
      null,
      [props.intl.formatMessage({ id: 'common.information' }), getConvertStatus(loadedCase.productList, props.intl)],
      props
    );
    delete loadedCase.productList;
  }
  // remove it since it's only needed this one time
  delete loadedCase.showNotification;
};

const dispatchNotification = (type, field, value, props) => {
  type = type.concat('AppAction');
  if (props) {
    props[type]({ field, value });
  }
};
