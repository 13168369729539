import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AppActions from '../../core/actions';
import * as DepositActions from './actions';
import * as LearnMore from '../../core/learn-more-config';
import * as ScenarioActions from '../../custom-mode/scenario-tab-navs/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import MWIDropdown from '../../core/components/mwi-dropdown';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';
import MWISelect from '../../core/components/mwi-select';
import NumberFormat from 'react-number-format';
import { ageToYear, handleFocus, getBaseCoverageClientOptions, getClientOptions } from '../../core/utils';
import MWINumberField from '../../core/components/mwi-calculated-number-field';
import MWIButton from '../../core/components/mwi-button';
import {
  getAgeFromValue,
  getAgeToValue,
  getAgeToValueBasedOnCoi,
  getDefaultAgeToValue,
  getDefaultYearToValue,
  getMaxAgeByCOIType,
  getYearFromValue,
  getYearToValue,
} from './duration-logic';

import {
  eDepositFrequency,
  eDurationType,
  eDepositType,
  eField,
  eLocale,
  eCoverageType,
  eDefault,
  eDepositOwnerResidence,
  configs,
  eSpreadsheetFields,
  eProduct,
  eInsuranceType,
  eBusinessType,
} from '../../core/configs';
import { dropDownOptions } from '../../core/dropdown-config';
import './deposit.css';
import _ from 'lodash';
import { ProductConfig } from '../product-config';
import SideAccountInterestRate from '../../core/components/side-account-interest-rate';
import PolicyInterestRate from '../../core/components/policy-interest-rate';
import MarginalTaxRate from '../../core/components/marginal-tax-rate';
import LearnMoreTable from '../../core/components/learn-more-table';

export class Deposit extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    depositActions: PropTypes.object,
    onBlur: PropTypes.func,
    activeScenarioTabId: PropTypes.string,
    deposit: PropTypes.object,
    clients: PropTypes.object,
    coverageType: PropTypes.string,
    equivalentAge: PropTypes.number,
    clientAge: PropTypes.number,
    isOutOfDate: PropTypes.bool,
    isForceUpdate: PropTypes.bool,
    duration: PropTypes.number,
    isDepositFolded: PropTypes.bool,
    costOfInsurance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isAdditionalDepositSelected: PropTypes.bool,
    depositOwnerResidence: PropTypes.string,
    depositFrequency: PropTypes.string,
    depositType: PropTypes.string,
    depositAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    depositDurationType: PropTypes.string,
    depositDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    depositAdditionalDeposit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    coverage: PropTypes.object,
    history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isDepositOptionCustom: PropTypes.bool,
    depositOptionPaymentCache: PropTypes.object,
    product: PropTypes.string,
    policy: PropTypes.object,
    amountOfInsuranceType: PropTypes.string,
    inforce: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.customClick = this.customClick.bind(this);
  }

  componentDidMount() {
    const { deposit, product } = this.props;
    const { depositOwnerResidence } = deposit;
    if (this.props.isOutOfDate) {
      if (product === eProduct.MUL && depositOwnerResidence === eDepositOwnerResidence.select) {
        this.props.appActions.dispatchMWIOnChange({
          target: 'deposit',
          scenarioTabId: this.props.activeScenarioTabId,
          field: eField.selectClicked,
          value: true,
        });
      } else {
        this.props.sceActions.performManualRefresh();
        this.props.sceActions.requestUDMResponse();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { deposit, isDepositOptionCustom, equivalentAge } = this.props;
    const holder = JSON.stringify(this.props) === JSON.stringify(prevProps);
    if (!holder) {
      this.setDefaultValuesDepCheck(prevProps, deposit, isDepositOptionCustom, equivalentAge);
    }
  }

  setDefaultValuesDepCheck(prevProps, deposit, isDepositOptionCustom, equivalentAge) {
    if (prevProps.deposit[eField.basedonClient] !== deposit[eField.basedonClient] && !deposit.isDurationSet) {
      this.setDefaultValuesDEP();
    }
    if (
      prevProps.deposit[eField.depositType] !== deposit[eField.depositType] &&
      prevProps.deposit[eField.depositType] === eDepositType.yearlymin
    ) {
      this.setDefaultValuesDEP();
    }
    this.setDefaultValuesDepElsaPresent(isDepositOptionCustom, deposit, equivalentAge, prevProps);
  }

  setDefaultValuesDepElsaPresent(isDepositOptionCustom, deposit, equivalentAge, prevProps) {
    // elsa has to be present
    if (!isDepositOptionCustom) {
      if (!deposit.durationEnd && equivalentAge > 0) {
        this.setDefaultValuesDEP();
      } else {
        if (equivalentAge && prevProps.equivalentAge !== equivalentAge && !deposit.isDurationSet) {
          this.setDefaultValuesDEP();
        }
      }
    }
  }

  setDefaultValuesDEP() {
    const { deposit, clientAge, activeScenarioTabId, coverageType, equivalentAge, costOfInsurance, duration } =
      this.props;

    if (deposit[eField.duration] === eDurationType.age) {
      const ValueFrom = getAgeFromValue(coverageType, equivalentAge, clientAge, '');

      this.props.depositActions.setDefaultDuration({
        defaultValueFrom: ValueFrom,
        defaultValueTo: getDefaultAgeToValue(coverageType, equivalentAge, clientAge, costOfInsurance),
        defaultActiveScenarioTabId: activeScenarioTabId,
      });
    } else {
      if (deposit[eField.duration] === eDurationType.year) {
        const fromValue = getYearFromValue(duration, '');
        this.props.depositActions.setDefaultDuration({
          defaultvalueFrom: fromValue,
          defaultvalueTo: getDefaultYearToValue(coverageType, equivalentAge, clientAge, costOfInsurance),
          defaultactiveScenarioTabId: activeScenarioTabId,
        });
      }
    }
  }

  dispatchAction(field, value, target = 'deposit', isError = false, refresh = true) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      scenarioTabId: this.props.activeScenarioTabId,
      field,
      target,
      value,
    });

    if (field === eField.depositType && value === eDepositType.levelmax) {
      this.dispatchAction(eField.isAdditionalDepositSelected, false, 'deposit', false, false);
      this.dispatchAction(eField.depositAdditionalDeposit, 0);
    }
    if (refresh) {
      this.props.sceActions.performManualRefresh();
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  onBlur(field, value) {
    //Validation check for Duration From/To Value Field
    const { activeScenarioTabId, deposit, clientAge, coverageType, equivalentAge, duration, inforce, costOfInsurance } =
      this.props;

    if (field === eField.durationTo) {
      if (deposit[eField.duration] === eDurationType.age) {
        if (inforce) {
          value = getAgeToValueBasedOnCoi(
            coverageType,
            costOfInsurance,
            equivalentAge,
            clientAge,
            value,
            clientAge + 1
          );
        } else {
          value = getAgeToValue(coverageType, equivalentAge, clientAge, value, clientAge + 1);
        }
      } else {
        if (deposit[eField.duration] === eDurationType.year) {
          value = getYearToValue(duration, value, 1);
        }
      }
      this.props.depositActions.setDuration({
        scenarioTabId: activeScenarioTabId,
      });
    }
    this.dispatchAction(eField.isDepositOptionCustom, false, 'scenario', false, false);
    this.dispatchAction(field, value);
  }

  customClick() {
    const indexOfTopBar = 2;
    this.dispatchAction('activeTopBarIndex', indexOfTopBar);
    this.props.history.push(configs.routes.spreadsheet, {
      isCustom: true,
      customField: eSpreadsheetFields.requestedAnnualDeposit,
    });
  }

  onDurationTypeChange(value) {
    const { deposit, clientAge, activeScenarioTabId } = this.props;

    //this mean no changes
    if (deposit[eField.duration] === value) {
      return;
    }

    //get the equivalent calculated value from year to age and vice versa
    if (value === eDurationType.age) {
      this.props.depositActions.selectDuration({
        valueFrom: +deposit[eField.durationFrom] + +clientAge,
        valueTo: +deposit[eField.durationTo] + +clientAge,
        type: eDurationType.age,
        activeScenarioTabId,
      });
    } else {
      if (value === eDurationType.year) {
        this.props.depositActions.selectDuration({
          valueFrom: ageToYear(clientAge + 1, clientAge),
          valueTo: ageToYear(deposit[eField.durationTo], clientAge),
          type: eDurationType.year,
          activeScenarioTabId,
        });
      }
    }

    this.props.sceActions.performManualRefresh();
    this.props.sceActions.toggleOutOfDate(true);
  }

  depositOptionDropdown(deposit, depositTypes, policy, locale, intl) {
    return (
      <MWIDropdown
        name="depositType"
        label={<FormattedMessage id="deposit.type" />}
        value={deposit[eField.depositType]}
        options={depositTypes}
        disabled={this.props.inforce}
        onChange={(e) => {
          this.dispatchAction(eField.isDepositOptionCustom, false, 'scenario', false, false);
          this.dispatchAction(eField.depositType, e.target.value);
          if (policy && policy.depositOption) {
            this.dispatchAction(
              eField.depositAmount,
              policy.depositOption.modalAmount ? policy.depositOption.modalAmount : policy.initialModalPremium
            );
          }
        }}
        helpText
        helpTextBanner="deposit.type.learnmore"
        helpTextContent={
          <LearnMoreTable
            locale={locale}
            columnHeaders={LearnMore.depositAmountLearnMoreHeader}
            tableMatrix={LearnMore.depositAmountLearnMoreMatrix}
          />
        }
        helpTextContainer="helptext-size"
        containerStyle="p-col-4 p-lg-5 p-xl-4 p-sm-6 mwi-margin-right24"
        dropdownStyle="mwi-w100"
        intl={intl}
        locale={locale}
      />
    );
  }

  setDepositFrequency(value) {
    const monthsInYear = 12;
    const divisor = 100;
    // click same button, don't make the calc service
    if (this.props.depositFrequency === value) {
      return;
    }
    if (value === eDepositFrequency.annual) {
      const newAmt = Math.round(this.props.deposit[eField.depositAmount] * monthsInYear * divisor) / divisor;
      this.dispatchAction(eField.depositAmount, newAmt);
    } else {
      const newAmt = Math.round((this.props.deposit[eField.depositAmount] / monthsInYear) * divisor) / divisor;
      this.dispatchAction(eField.depositAmount, newAmt);
    }
    this.dispatchAction(eField.depositFrequency, value);

    // if the user changes frequency, set the depositOptionCustomAmount to be built from the values in the
    // depositOptionCache (see spreadsheet's buildCustomStatuses), we use the user entered
    // values to send to the calc service
    // if frequency is monthly, means user is switching from annual to monthly and depositOption
    // is custom divide the values in the cache by 12
    // rounded down to 2 decimal places. This is to account for the calc servic which multiplies the value by 12.
    const { isDepositOptionCustom, depositOptionPaymentCache } = this.props;
    const customAmount = [];
    if (isDepositOptionCustom && !_.isEmpty(depositOptionPaymentCache)) {
      for (const year in depositOptionPaymentCache) {
        if (Object.prototype.hasOwnProperty.call(depositOptionPaymentCache, year)) {
          const amt =
            value === eDepositFrequency.monthly
              ? Math.floor((depositOptionPaymentCache[year] / monthsInYear) * divisor) / divisor
              : depositOptionPaymentCache[year];
          customAmount.push(`${year}:${amt}`);
        }
      }
      this.dispatchAction(eField.depositOptionCustomAmount, customAmount, 'scenario', false, false);
    }
  }

  depositOptionAdditionalDeposits(deposit, amountOfInsuranceType, locale) {
    return (
      deposit[eField.depositType] !== eDepositType.levelmax &&
      amountOfInsuranceType === eInsuranceType.specified && (
        <div
          id="additionalDeposit"
          className={`p-col-12 ${deposit[eField.depositType] === eDepositType.yearlymin ? 'mwi-margin-top24' : ''} 
                      mwi-margin-bottom24 flexdisplay`}
        >
          <div>
            <input
              className="rider-checkbox focusOutline"
              type="checkbox"
              id={eField.depositAdditionalDeposit}
              value={deposit.isAdditionalDepositSelected}
              onChange={() => {
                this.dispatchAction(
                  eField.isAdditionalDepositSelected,
                  !deposit.isAdditionalDepositSelected,
                  'deposit',
                  false,
                  false
                );
                this.dispatchAction(eField.depositAdditionalDeposit, 0);
              }}
              checked={deposit.isAdditionalDepositSelected}
              name={'additionalDeposit'}
              aria-label={'additionalDepositCheckbox'}
              aria-describedby="ridersHeader"
            />
            {this.props.inforce === true ? (
              <span className="mwi-padding-left6">
                <FormattedMessage id="deposit.additional.deposit.inforce" />
              </span>
            ) : (
              <span className="mwi-padding-left6">
                <FormattedMessage id="deposit.additional.deposit" />
              </span>
            )}
          </div>
          {deposit[eField.isAdditionalDepositSelected] && (
            <div className="p-col-2 p-lg-4 p-xl-3 p-sm-6 space-right">
              <div className="p-col-12 flexdisplay" id="additionalDepositAmount" role="additionalDepositAmount">
                <MWINumberField
                  id={eField.depositAdditionalDeposit}
                  styleClass="p-col-12"
                  containerClass="mwi-input number-data p-col-4 p-lg-5 p-xl-4 p-sm-6 space-right mwi-margin-left24 mwi-margin-top-6"
                  onBlur={this.onBlur}
                  value={deposit[eField.depositAdditionalDeposit]}
                  decimalScale={2}
                  prefix={locale === eLocale.en ? '$ ' : ''}
                  suffix={locale === eLocale.fr ? ' $' : ''}
                  thousandSeparator={locale === eLocale.en ? ',' : ' '}
                  minValue={0.0}
                  maxValue={99999999.0}
                  decimalSeparator={locale === eLocale.en ? '.' : ','}
                  fixedDecimalScale
                  locale={locale}
                />
              </div>
            </div>
          )}
        </div>
      )
    );
  }

  depositOptionsDurationFields(deposit, intl, isDepositOptionCustom, coverageType, clientOptions) {
    return (
      deposit[eField.depositType] !== eDepositType.yearlymin && (
        <>
          <div id="durationContainer" className="p-col-12 flexdisplay mwi-margin-top18 mwi-margin-bottom24">
            <div className="p-col-3 p-lg-3 p-sm-4">
              <div className="mwi-label">
                <span>
                  <FormattedMessage id="common.duration" />
                </span>
              </div>

              <MWISelect
                containerStyle="mwi-padding-right12"
                ariaDescribedBy="common.duration"
                labelL={intl.formatMessage({ id: 'vitality.age' })}
                labelR={intl.formatMessage({ id: 'deposit.mul.years' })}
                onClickL={() => this.onDurationTypeChange(eDurationType.age)}
                onClickR={() => this.onDurationTypeChange(eDurationType.year)}
                selectedL={deposit[eField.duration] === eDurationType.age}
                selectedR={deposit[eField.duration] === eDurationType.year}
              />
            </div>
            <div className={`mwi-control-wrapper p-col-4 p-lg-3 p-xl-2 p-sm-4 mwi-padding-right12`}>
              <div className="mwi-label">
                <label htmlFor={eField.durationTo} aria-required="true">
                  {intl.formatMessage({
                    id: `${deposit[eField.duration] === eDurationType.age ? eField.ageto : 'deposit.mul.years'}`,
                  })}
                </label>
              </div>
              <div>
                <NumberFormat
                  className={`mwi-widget mwi-input number-data mwi-w100`}
                  value={deposit[eField.durationTo]}
                  disabled={isDepositOptionCustom}
                  decimalSeparator="false"
                  // onValueChange={values => (onChange ? onChange(id, values.value) : '')}
                  onBlur={(e) => this.onBlur(eField.durationTo, e.target.value)}
                  decimalScale={0}
                  fixedDecimalScale={false}
                  id={eField.durationTo}
                  autoComplete="off"
                  aria-required="true"
                  onFocus={handleFocus}
                />
                {/* <div className="mwi-error-msg">{errors[eField.coverageAmount]}</div> */}
              </div>
            </div>
          </div>
          {coverageType === eCoverageType.jointLastPayLastDeath && deposit[eField.duration] === eDurationType.age && (
            <div id="basedOnClientContainer" className="p-col-8 flexdisplay mwi-margin-top20 mwi-margin-bottom24">
              <MWIDropdown
                name={eField.basedonClient}
                label={<FormattedMessage id="withdrawal.client" />}
                value={deposit[eField.basedonClient]}
                options={clientOptions}
                onChange={(e) => this.dispatchAction(eField.basedonClient, e.target.value)}
                dropdownStyle="mwi-w100"
                containerStyle="p-col-6 p-lg-8 p-xl-6"
              />
            </div>
          )}
        </>
      )
    );
  }

  getDepositOptionsMonthlyOrAnnual(intl, deposit) {
    return (
      <MWISelect
        containerStyle="p-col-3 p-lg-10 p-sm-4"
        ariaDescribedBy="depositFrequency"
        labelL={intl.formatMessage({ id: 'common.monthly' })}
        labelR={intl.formatMessage({ id: 'common.annually' })}
        disabled={this.props.inforce}
        onClickL={() => {
          if (deposit[eField.depositFrequency] === eDepositFrequency.annual) {
            this.setDepositFrequency(eDepositFrequency.monthly);
          }
        }}
        onClickR={() => {
          if (deposit[eField.depositFrequency] === eDepositFrequency.monthly) {
            this.setDepositFrequency(eDepositFrequency.annual);
          }
        }}
        selectedL={deposit[eField.depositFrequency] === eDepositFrequency.monthly}
        selectedR={deposit[eField.depositFrequency] === eDepositFrequency.annual}
      />
    );
  }

  displayDepositOptionFields(deposit, isDepositOptionCustom, locale, amountOfInsuranceType) {
    return (
      deposit[eField.depositType] === eDepositType.specified && (
        <>
          {this.getDepositAmountInputBox(deposit, isDepositOptionCustom, locale)}
          {amountOfInsuranceType === eInsuranceType.specified && (
            <MWIButton
              label={this.props.intl.formatMessage({ id: 'common.customize' })}
              onClick={this.customClick}
              disabled={false}
              styleClass="mwi-margin-top24 mwi-margin-left24 p-col-2"
            />
          )}
        </>
      )
    );
  }

  getDepositAmountInputBox(deposit, isDepositOptionCustom, locale) {
    return (
      <MWINumberField
        id={eField.depositAmount}
        styleClass="p-col-3"
        containerClass="p-sm-4"
        label={
          <FormattedMessage
            id={
              deposit[eField.depositFrequency] === eDepositFrequency.annual
                ? 'deposit.amount.annual'
                : 'deposit.amount.monthly'
            }
          />
        }
        onBlur={this.onBlur}
        value={!isDepositOptionCustom ? deposit[eField.depositAmount] : ''}
        decimalScale={2}
        isCustom={isDepositOptionCustom}
        placeholder={isDepositOptionCustom ? this.props.intl.formatMessage({ id: 'depositoption.custom' }) : undefined}
        prefix={locale === eLocale.en ? '$ ' : ''}
        suffix={locale === eLocale.fr ? ' $' : ''}
        thousandSeparator={locale === eLocale.en ? ',' : ' '}
        minValue={eDefault.depositAmount}
        maxValue={99999999.99}
        decimalSeparator={locale === eLocale.en ? '.' : ','}
        fixedDecimalScale
        locale={locale}
      />
    );
  }

  render() {
    const {
      locale,
      intl,
      deposit,
      clients,
      coverageType,
      isDepositOptionCustom,
      product,
      policy,
      amountOfInsuranceType,
      inforce,
    } = this.props;
    const { depositTypes } = dropDownOptions(intl);
    const clientOptions = inforce ? getBaseCoverageClientOptions(clients, true) : getClientOptions(clients, true);

    return (
      <div className="p-col-12 deposit-content">
        <MWIAccordion
          className={`mwi-reverse-margin15 first-child mwi-lightGreen`}
          title={<FormattedMessage id="deposit.label" />}
          folded={deposit.isFolded}
          onClickChange={() => this.dispatchAction(eField.isFolded, !deposit.isFolded, 'deposit', false, false)}
        >
          <div id="depositContainer" className={`p-col-12 mwi-margin-top18 flexdisplayCenter`}>
            <div className="p-col-3 p-sm-4 space-right">
              <div className="mwi-label" id="depositFrequency">
                <span>
                  <FormattedMessage id="deposit.frequency" />
                </span>
              </div>
              <div className="mwi-dropdown-container">{this.getDepositOptionsMonthlyOrAnnual(intl, deposit)}</div>
            </div>
          </div>
          <div id="amountContainer" className="p-col-12 mwi-margin-top18 flexdisplay">
            {this.depositOptionDropdown(deposit, depositTypes, policy, locale, intl)}
            {this.displayDepositOptionFields(deposit, isDepositOptionCustom, locale, amountOfInsuranceType)}
          </div>
          {this.depositOptionsDurationFields(deposit, intl, isDepositOptionCustom, coverageType, clientOptions)}
          {this.depositOptionAdditionalDeposits(deposit, amountOfInsuranceType, locale)}
          <>
            <div id="durationContainer" className="p-col-12 flexdisplay">
              <div className="p-col-3 p-lg-4">
                {ProductConfig[product]?.ratesSections?.policyInterestRate && <PolicyInterestRate />}
              </div>
              <div className="p-col-3 p-lg-4">
                {ProductConfig[product]?.ratesSections?.sideAccountInterestRate && <SideAccountInterestRate />}
              </div>
              <div className="p-col-3 p-lg-4">
                {ProductConfig[product]?.ratesSections?.marginalTaxRate && <MarginalTaxRate />}
              </div>
            </div>
          </>
        </MWIAccordion>
      </div>
    );
  }
}

const getAgeFromProps = (deposit, udmIllustration, coverageState, inforce) => {
  let clientAge = 0;
  let equivalentAge = 0;
  if (!inforce) {
    clientAge =
      udmIllustration &&
      udmIllustration.party &&
      udmIllustration.party.find((party) => party.partyId === deposit[eField.basedonClient]).age;
    equivalentAge = coverageState[coverageState.activeTabId].equivalentAge;
  } else {
    const coverageTabId = coverageState.activeTabId;
    if (udmIllustration && udmIllustration.policy) {
      const activeCoverage = udmIllustration.policy.coverage.find((coverage) => coverage.coverageId === coverageTabId);
      if (activeCoverage) {
        clientAge =
          activeCoverage.life && activeCoverage.life.find((life) => life.partyId === deposit[eField.basedonClient]).age;
      }
    }
    equivalentAge = coverageState[coverageState.activeTabId].equivalentAge
      ? coverageState[coverageState.activeTabId].equivalentAge
      : clientAge;
  }
  return { clientAge, equivalentAge };
};

export const mapStateToProps = ({ app, deposits, clients, udmResponse, scenarioTabNavs, coverageTabNavs }) => {
  const activeScenarioTabId = scenarioTabNavs.activeTabId;
  const activeScenario = scenarioTabNavs.topBars[activeScenarioTabId];
  const deposit = deposits[activeScenarioTabId];
  const { illustration, udmIllustration } = udmResponse;
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const product = coverageTabNavs[eField.product];

  const policy = illustration && illustration.policy;
  let duration = policy && +policy.duration;

  let { clientAge, equivalentAge } = getAgeFromProps(deposit, udmIllustration, coverageState, app.inforcePolicy);

  if (app.inforcePolicy) {
    clientAge = clients[activeScenarioTabId][deposit.durationPartyId].age;
    if (illustration && illustration.policy && illustration.policy.coverage) {
      const coverages = illustration.policy.coverage;
      const baseCoverage = coverages.find(
        (cvg) => cvg.indicatorCode === eField.indicatorCode.base && cvg.status === eBusinessType.IF
      );
      duration = getMaxAgeByCOIType(baseCoverage.costOfInsurance.type) - equivalentAge;
    }
  }
  return {
    locale: app.locale,
    clients: clients[activeScenarioTabId],
    coverageType: coverageState[coverageState.activeTabId].coverageType,
    costOfInsurance: coverageState[coverageState.activeTabId].costOfInsurance,
    clientAge: +clientAge,
    depositAmount: activeScenario.depositAmount,
    depositDurationType: activeScenario.depositDurationType,
    isAdditionalDepositSelected: activeScenario.isAdditionalDepositSelected,
    isDepositFolded: activeScenario.isDepositFolded,
    depositDuration: activeScenario.depositDuration,
    depositFrequency: activeScenario.depositFrequency,
    depositAdditionalDeposit: activeScenario.depositAdditionalDeposit,
    coverage: coverageState[coverageState.activeTabId],
    amountOfInsuranceType: coverageState[coverageState.activeTabId].amountOfInsuranceType,
    isOutOfDate: scenarioTabNavs.isOutOfDate,
    isForceUpdate: scenarioTabNavs.isForceUpdate,
    isDepositOptionCustom: activeScenario.isDepositOptionCustom,
    depositOptionPaymentCache: activeScenario.depositOptionPaymentCache,
    inforce: app.inforcePolicy,
    product,
    duration,
    activeScenarioTabId,
    deposit,
    equivalentAge,
    policy,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  depositActions: bindActionCreators(DepositActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Deposit)));
