import React from 'react';
import {
  eSpreadsheetFields,
  eLocale,
  eUDMReqType,
  eVitalityStatusNum,
  eSpreadsheetDecimal,
  eSpreadsheetPercent,
  eReqStatus,
  configs,
  eField,
  ePremiumFrequency,
  eProduct,
  eDepositFrequency,
  ePremiumDuration,
  eColumnToHide,
} from '../core/configs';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { requestSpreadsheetValidation } from '../core/udm-mapper/sideEffect';
import NumberFormat from 'react-number-format';

import { getFormattedValue, exportExcel, handleFocus } from '../core/utils';
import UDMGenerator from '../core/udm-mapper/udmGenerator';
import _ from 'lodash';
import './spreadsheet.css';
import ErrorDialog from '../core/components/error-dialog';
import { NUM_MONTHS, PERCENT_HUNDRED } from '../core/constants';

const policyYearModulusFive = 5;
const policyYearModulusTwo = 2;
const customAmountLengthMinus = 2;
const formattedValueDecimal = 2;

export function openCustomModeDesign(props) {
  props.history.push(configs.routes.clientCoverage);
  dispatch(props.appActions, 'closeSpreadsheet', undefined);
}

export function updateDuration(customAmount, props) {
  // Calculation of years for duration
  const conversionCalc = customAmount[customAmount.length - 1];
  const conversionCalcArr = conversionCalc.split(':');
  const durationYears = parseInt(conversionCalcArr[0], 10);

  const { deposits, scenarioTabId } = props;
  if (deposits) {
    const { durationStart } = deposits;
    dispatch(props.appActions, eField.durationTo, durationStart - 1 + durationYears - 1, 'deposit', scenarioTabId);
  }
}

export async function recalculateSpreadSheet(props, state, setState) {
  setState({ ...state, calculating: true, recalculate: true });
  const { customAmount, changes } = buildCustomStatuses(
    state.formattedData,
    state.yearColumn,
    state.customColumn,
    props
  );
  //only update duration when last customAmount year has value 0
  if (props.product === eProduct.MUL && parseInt(customAmount[customAmount.length - 1].split(':')[1]) === 0) {
    updateDuration(customAmount, props);
  }

  dispatch(props.appActions, eField.depositOptionCustomAmount, customAmount, 'scenario');
  dispatch(props.appActions, eField.isDepositOptionCustom, true, 'scenario');
  dispatch(props.appActions, eField.depositOptionPaymentCache, changes, 'scenario');
}

export function buildCustomStatuses(formattedData, yearColumn, targetColumn, props) {
  const customAmount = [];
  const changes = {};
  let previousAmount;

  formattedData.forEach((data, index) => {
    if ((!previousAmount && data[targetColumn]) || data[targetColumn] !== previousAmount) {
      // divide by 12 if premium frequency amount is monthly
      // this would cause a rounding error in come cases
      // calc service needs to fix (eg amount 250 would be 250/12 = 20.83 which when returned
      // form the service would be 249.96)
      changes[data[yearColumn]] = data[targetColumn]; // store cache of user entered values
      // change the calculation to round to match the behaviour on the UI
      getCustomAmount(data, targetColumn, props, customAmount, index, yearColumn);
    }
    previousAmount = data[targetColumn];
  });

  // check if MUL
  mulCustomAmount(customAmount, props, formattedData);

  if (
    customAmount.length > 1 &&
    parseInt(customAmount[customAmount.length - 1].split(':')[1], 10) === 0 &&
    parseInt(customAmount[customAmount.length - customAmountLengthMinus].split(':')[1], 10) === 0 &&
    props.spreadsheet.fillDownClicked
  ) {
    customAmount.pop();
  }

  return {
    customAmount,
    changes,
  };
}

function getCustomAmount(data, targetColumn, props, customAmount, index, yearColumn) {
  const depositAmount = getDepositAmount(data, targetColumn, props);
  if (index === 0) {
    customAmount.push(`${data[yearColumn]}:${depositAmount}`);
  } else {
    if (
      (depositAmount === 0 &&
        customAmount.length > 0 &&
        customAmount[customAmount.length - 1].split(':')[1] !== '0.00') ||
      depositAmount !== 0
    ) {
      customAmount.push(`${data[yearColumn]}:${depositAmount}`);
    }
  }
}

function mulCustomAmount(customAmount, props, formattedData) {
  if (
    customAmount.length > 0 &&
    parseInt(customAmount[customAmount.length - 1], 10) !== 0 &&
    props.spreadsheet.fillDownClicked
  ) {
    customAmount.push(`${formattedData[formattedData.length - 1].PolicyYear0 + 1}:${0}`);
  }
}

function getDepositAmount(data, targetColumn, props) {
  let depositAmount;
  if (props.product === eProduct.MUL) {
    depositAmount =
      props.deposits.depositFrequency === eDepositFrequency.monthly
        ? // round to 2 decimal places
          Math.round((data[targetColumn] / NUM_MONTHS) * PERCENT_HUNDRED) / PERCENT_HUNDRED
        : data[targetColumn];
  } else {
    depositAmount =
      props.policyData.premiumFrequency === ePremiumFrequency.monthly
        ? // round to 2 decimal places
          Math.round((data[targetColumn] / NUM_MONTHS) * PERCENT_HUNDRED) / PERCENT_HUNDRED
        : data[targetColumn];
  }
  return parseFloat(depositAmount);
}

function getSpreadsheetResponse(sceActions, props, spreadsheetActions, spreadsheetData, ref, state, setState) {
  sceActions.loadedUDMResponse({ response:spreadsheetData, scenarioTabId: props.scenarioTabId });
  spreadsheetActions.loadedSpreadsheet({
    scenarioId: props.scenarioTabId,
  });
  ref && (DataTable.prototype.exportExcel = exportExcel.bind(ref.current));
  const illustration = spreadsheetData.illustration;
  const spreadsheetResponse = illustration.policy && illustration.policy.calculationVectors;
  transformData(spreadsheetResponse, props, state, setState);
}

export function RequestSpreadSheet(props, ref, state, setState) {
  state = {
    ...state,
    isChanged: false,
    lastValue: '',
    rowIndex: 0,
    recalculate: false,
  };
  setState(state);
  const { sceActions, spreadsheetActions } = props;

  const udmGenerator = new UDMGenerator();
  const udm = udmGenerator.setProps({ ...props, reqType: eUDMReqType.spreadsheet }).getUDM();

  dispatch(props.appActions, 'isRequesting', true);
  sceActions.loadingUDMResponse();

  requestSpreadsheetValidation(udm, configs.endPoints.spreadsheet)
    .then((response) => {
      dispatch(props.appActions, 'isRequesting', false);
      if (
        response.status.toUpperCase() !== eReqStatus.error &&
        response.status.toUpperCase() !== eReqStatus.reqError &&
        !_.isEmpty(response.illustration.policy.calculationVectors)
      ) {
        getSpreadsheetResponse(sceActions, props, spreadsheetActions, response, ref, state, setState);
      } else {
        sceActions.loadedUDMResponse({ response, scenarioTabId: props.scenarioTabId });
      }
    })
    .catch((errResponse) => {
      sceActions.loadedUDMResponse({ response: errResponse, scenarioTabId: props.scenarioTabId });
      spreadsheetActions.loadedSpreadsheet({
        scenarioId: props.scenarioTabId,
      });
      dispatch(props.appActions, 'isRequesting', false);
    });
}


// dispatch an app action to update isRequesting state in the appReducer
export function dispatch(appActions, field, value, target = 'app', scenarioTabId = '') {
  if (scenarioTabId !== '') {
    appActions.dispatchMWIOnChange({
      target,
      field,
      value,
      scenarioTabId,
    });
  } else {
    appActions.dispatchMWIOnChange({ target, field, value });
  }
}

export function isSpreadsheetValues(text, props) {
  const year = props.intl.formatMessage({ id: 'spreadsheet.PolicyYear' });
  const age = props.intl.formatMessage({ id: 'spreadsheet.PrimaryAttainedAge' });

  return text.includes(year) && text.includes(age);
}

// the onCopy event is overridden so that we don't allow copying of spreadsheet values to the clipboard
export function onCopy(event, props) {
  // clear out the clipboard if spreadsheet values were copied;
  // do this by checking if the target came from the cell data or
  // if the selected data contains Policy and Year column headers
  if (event.target.className === 'ui-cell-data' || isSpreadsheetValues(window.getSelection().toString(), props)) {
    const textarea = document.createElement('textarea');
    textarea.textContent = '';
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('cut');
    } catch (ex) {
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
  return false;
}

export function getCacheValue(yr, depositOptionCache) {
  // if value is directly in cache return
  if (depositOptionCache[yr]) {
    return depositOptionCache[yr];
  }
  // the value would be the cache value less than the value we are searching for
  let result = 0;
  for (const key of Object.keys(depositOptionCache)) {
    if (key <= yr) {
      result = depositOptionCache[key];
    }
  }
  return result;
}

function colTypeIsCustomAndIsCustomField(col, customField, isCustom) {
  if (col.type === customField && isCustom) {
    return true;
  }
  return false;
}
function transformInner(col, customField, isCustom, value, row, state, props) {
  const { intl, locale, policyData } = props;
  let formattedValue;
  if (col.type === eSpreadsheetFields.vitalityStatus && value >= 0) {
    const vitalityName = eVitalityStatusNum[value].toLowerCase();
    formattedValue = intl.formatMessage({ id: `vitality.${vitalityName}` });
  } else if (colTypeIsCustomAndIsCustomField(col, customField, isCustom)) {
    // if monthly frequency and we have a cache of user enterd values we use the cache values
    // this is because for monthly premium freq we convert values inputed by the user from annual to monthly
    //  by dividing by 12 and rounding down(See buildCustomStatuses) which the calc service
    //  multiplies by 12 this leads to the value returned from the service
    // being different from the users entered value by a couple of decimal places. So we need
    // the user to see the value they entered, hence the cache
    if (
      policyData.premiumFrequency === ePremiumFrequency.monthly &&
      policyData.isDepositOptionCustom &&
      !_.isEmpty(policyData.depositOptionPaymentCache)
    ) {
      formattedValue = getCacheValue(row[state.yearColumn], policyData.depositOptionPaymentCache);
    } else {
      formattedValue = value;
    }
  } else if (
    col.type !== eSpreadsheetFields.year &&
    col.type !== eSpreadsheetFields.age &&
    col.type !== eSpreadsheetFields.ageInforce
  ) {
    const decimal = eSpreadsheetDecimal.indexOf(col.type) >= 0 ? formattedValueDecimal : 0;
    const percentage = eSpreadsheetPercent.indexOf(col.type) >= 0;
    formattedValue = getFormattedValue(locale, value, decimal, percentage);
  } else {
    formattedValue = value;
  }
  return formattedValue;
}

export function transformData(mappedResponse, props, state, setState) {
  const isCustom =
    (props.history.location && props.history.location.state && props.history.location.state.isCustom) || false;
  const customField =
    props.history.location && props.history.location.state && props.history.location.state.customField;
  const tableData = [];
  let keepMapping = true;
  const yearColumn = _.find(mappedResponse, ['type', eSpreadsheetFields.year]);
  const timeout = 100;

  _.times(timeout, (i) => {
    if (!keepMapping) {
      return false;
    }

    const row = {};
    let formattedValue;

    _.forEach(mappedResponse, (col, index) => {
      const value = col.values[i];

      formattedValue = transformInner(col, customField, isCustom, value, row, state, props);
      // We append the index to the spreadsheet field to
      // prevent the case of duplicates col.type is not unique
      // and this causes the value shown in the spreadhseet to be wrong
      // expecially for primary and alternate values
      const uniqueSpreadsheetFieldKey = col.type + index;
      if (col.type === eSpreadsheetFields.year && i === 0) {
        state = { ...state, yearColumn: uniqueSpreadsheetFieldKey };
        setState(state);
      } else if (col.type === customField && i === 0) {
        state = { ...state, customColumn: uniqueSpreadsheetFieldKey };
        setState(state);
      } else {
        /* skip */
      }

      row[uniqueSpreadsheetFieldKey] = formattedValue !== 'NaN' ? formattedValue : '';
    });

    const year = yearColumn && yearColumn.values[i];
    if (year && row[mappedResponse[0].title] !== 'NaN') {
      tableData.push(row);
    } else {
      keepMapping = false;
    }
    return true;
  });
  state = {
    ...state,
    formattedData: tableData,
  };
  setState(state);
}

export function rowClassName(rowData) {
  const PolicyYear = rowData[Object.keys(rowData)[0]];
  return {
    'p-highlight-spreadsheet': PolicyYear % policyYearModulusFive === 0,
    'p-even': PolicyYear % policyYearModulusTwo === 0 && PolicyYear % policyYearModulusFive !== 0,
  };
}

function getFormatSpecifiers(locale) {
  const prefix = locale === eLocale.en ? '"$"' : '';
  const suffix = locale === eLocale.fr ? ' "$"' : '';
  const percent = locale === eLocale.en ? '%' : ' %';

  return { prefix, suffix, percent };
}

function hidePay100(props, spreadsheetResponse) {
  const isHidden =
    props.product === eProduct.PAR &&
    props.isVitalityPlusSelected === true &&
    props.premiumDuration === ePremiumDuration.Pay100;
  if (isHidden) {
    const columnObj = spreadsheetResponse.find((x) => x.type === eColumnToHide.VitalityStatus);
    const columnIndex = spreadsheetResponse.indexOf(columnObj);
    if (columnIndex > -1) {
      spreadsheetResponse.splice(columnIndex, 1);
    }
  }
  return spreadsheetResponse;
}

export function exportSpreadsheet(ref, props) {
  const { intl, locale } = props;
  let { spreadsheetResponse } = props;

  spreadsheetResponse = hidePay100(props, spreadsheetResponse);

  //get the format prefix/suffix i.e. $/%
  const specifiers = getFormatSpecifiers(locale);

  //get the headers from spreadsheetResponse
  const headers = spreadsheetResponse.map(function (column) {
    return column.title;
  });

  //get the values from spreadsheetResponse
  const values = spreadsheetResponse.map(function (column) {
    return column.values;
  });

  //transpose the values matrix so that it is in table format
  const records = values[0].map((i, colIndex) => values.map((row) => row[colIndex]));

  const colMetaDatas = [];

  //for each column, create a metadata of the column (i.e. is column percentage, formatString for the column)
  _.forEach(spreadsheetResponse, (col, index) => {
    const colMetaData = { percentage: false, formatString: '###0' };

    //if column is vitalityStatus then do not format it, convert record enum values to text values
    if (col.type === eSpreadsheetFields.vitalityStatus) {
      colMetaData.formatString = null;
      records.forEach(function (row) {
        const vitalityName = eVitalityStatusNum[row[index]].toLowerCase();
        row[index] = intl.formatMessage({ id: `vitality.${vitalityName}` });
      });
    } else if (
      col.type !== eSpreadsheetFields.year &&
      col.type !== eSpreadsheetFields.age &&
      col.type !== eSpreadsheetFields.ageInforce
    ) {
      //if not age/year column then it is currency/percentage column, set appropriate format
      colMetaData.percentage = eSpreadsheetPercent.indexOf(col.type) >= 0;
      const decimal = eSpreadsheetDecimal.indexOf(col.type) >= 0 ? '.00' : '';
      colMetaData.formatString = colMetaData.percentage
        ? `#,##0.00${specifiers.percent}`
        : `${specifiers.prefix}#,##0${decimal}${specifiers.suffix}`;
    } else {
      //else do not set format
      colMetaData.formatString = null;
    }

    colMetaDatas.push(colMetaData);
  });

  if (ref && ref.current) {
    //create object that contains everything and pass it to exportExcel
    const spreadSheetData = {
      headers,
      records,
      colMetaDatas,
      language: locale,
      fileName: ref.current.props.exportFilename,
    };
    ref.current.exportExcel(spreadSheetData);
  }
}

export function onEditorDepositChange(value, rowData, column, props, state, setState) {
  const { spreadsheetResponse } = props;
  const customField =
    props.history.location && props.history.location.state && props.history.location.state.customField;
  const customCol = _.find(spreadsheetResponse, ['type', customField]);
  if (customCol) {
    customCol.values[column.rowIndex] = value;
  }
  rowData[column.field] = value;

  if (!state.isChanged) {
    state = { ...state, isChanged: true, lastValue: value, rowIndex: column.rowIndex };
    setState(state);
  } else {
    state = { ...state, lastValue: value, rowIndex: column.rowIndex };
    setState(state);
  }
}

export function EditorhandleFocus(event, value, column, state, setState) {
  handleFocus(event);
  if (state.isChanged) {
    state = { ...state, lastValue: value, rowIndex: column.rowIndex };
    setState(state);
  }
}

export function fillDown(props, state, setState) {
  const { rowIndex, lastValue, formattedData, customColumn } = state;
  const modifiedFormattedData = _.cloneDeep(formattedData);
  for (let index = rowIndex; index < modifiedFormattedData.length; index++) {
    modifiedFormattedData[index][customColumn] = lastValue;
  }

  dispatch(props.appActions, 'fillDownClicked', true, 'spreadsheet', props.scenarioTabId);
  state = { ...state, formattedData: modifiedFormattedData };
  setState(state);
}

export function EditorKeyPress(event, props, state, setState) {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault();
    recalculateSpreadSheet(props, state, setState);
  }
  if (event.key === 'Enter' && event.shiftKey) {
    event.preventDefault();
    fillDown(props, state, setState);
  }
}

export function depositEditor(rowData, column, props, state, setState) {
  const { locale } = props;
  const maxValue = 99999999;

  return (
    <div className="depositEditorContainer">
      <NumberFormat
        className=" mwi-widget mwi-input number-data mwi-w99 depositEditor"
        value={+rowData[column.field]}
        decimalScale={2}
        fixedDecimalScale
        prefix={locale === eLocale.en ? '$ ' : ''}
        suffix={locale === eLocale.fr ? ' $' : ''}
        thousandSeparator={locale === eLocale.en ? ',' : ' '}
        decimalSeparator={locale === eLocale.en ? '.' : ','}
        allowNegative={false}
        isAllowed={(values) => +values.value >= 0 && +values.value <= maxValue}
        onValueChange={(values) => onEditorDepositChange(values.value, rowData, column, props, state, setState)}
        onBlur={(e) => e.target.blur()}
        aria-labelledby="depositOptionAmount"
        aria-required="true"
        autoComplete="off"
        onFocus={(event) => EditorhandleFocus(event, rowData[column.field], column, state, setState)}
        onKeyPress={(e) => EditorKeyPress(e, props, state, setState)}
        aria-live="polite"
        aria-atomic
      />
      {column.rowIndex === 0 && (
        <ErrorDialog
          from="spreadsheet"
          changleFirstLine={(value, rowDataValue, columnDataValue) => {
            onEditorDepositChange(value, rowDataValue, columnDataValue, props, state, setState);
          }}
          rowData={rowData}
          column={column}
        />
      )}
    </div>
  );
}
