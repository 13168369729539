import * as actionTypes from '../../core/actionTypes';
import {
  eBusinessType,
  eCoverageType,
  eDefaultRatings,
  eField,
  eProduct,
  eRider,
  eSmokingStatus,
} from '../../core/configs';
import { getPerformaxTermRiderDuration, getTermRiderCoverageOption } from '../../core/utils';
import { scenarioSeq } from '../../core/sequenceUtils';
import * as reducerHelper from '../../helper/reducer-helper';

const defaultState = {
  allRiders: [],
};

const initialState = {
  activeTabId: scenarioSeq(true),
  [scenarioSeq(true)]: { ...defaultState },
};

const termOtherRiderReducer = (state, action) => {
  state = state === undefined ? initialState : state;
  switch (action.type) {
    case actionTypes.MWI_ON_CHANGE: {
      const payload = action.payload;
      if (payload.target === 'termOtherRider') {
        return getTermOtherRiderState(payload, state);
      }
      return state;
    }

    case actionTypes.COMMON_INITIALIZE: {
      return initialState;
    }
    case actionTypes.ADD_SCENARIO_TABNAVS: {
      return reducerHelper.getAddScenarioTabNavsState(state, action);
    }
    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return reducerHelper.getClickScenarioTabNavsState(state, action);
    }
    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      return reducerHelper.getRemoveScenarioTabNavsState(state, action);
    }

    default:
      return termOtherRiderReducer2(state, action);
  }
};

const termOtherRiderReducer2 = (state, action) => {
  if (action.type === actionTypes.LOADED_INFORCE_POLICY) {
    const inforceIllustration = action.payload.policyData.illustration;
    const coverages = inforceIllustration.policy.coverage;
    const parties = inforceIllustration.party;
    const product = inforceIllustration.policy.product;

    const newState = loadInforceTermOtherRiders(coverages, parties, product);

    return {
      ...newState,
    };
  } else {
    return state;
  }
};

const getInforceCoverageInfo = (newRider, coverage) => {
  newRider.coverageId = coverage.coverageId;
  newRider.coverageOption = getTermRiderCoverageOption(coverage);
  newRider.coverageAmount = coverage.coverageAmount;
  newRider.costOfInsurance = coverage.costOfInsurance?.type;
  newRider.coverageType = coverage.coverageType;
  newRider.healthstyle = coverage.healthstyle ? coverage.healthstyle : eSmokingStatus.nonsmoker;
  newRider.coverageDuration = getPerformaxTermRiderDuration(newRider.age ? newRider.age : 0, newRider.coverageOption);
};

const loadInforceTermOtherRiders = (coverages, parties, product) => {
  const newState = { ...initialState };
  newState[newState['activeTabId']] = { allRiders: [] };
  coverages.forEach((coverage) => {
    if (
      coverage.indicatorCode === eField.indicatorCode.termRider &&
      coverage.product === eRider.tor &&
      coverage.status === 'Inforce'
    ) {
      const newRider = {};
      const id = coverage.coverageId;
      const clientId = coverage.life[0].partyId;
      if (coverage.associatedCoverageId) {
        const associatedCoverage = newState[newState.activeTabId][coverage.associatedCoverageId];
        if (associatedCoverage) {
          newState[newState.activeTabId][coverage.associatedCoverageId] = combinedInforceTermOtherRider(
            coverage,
            associatedCoverage,
            clientId,
            product
          );
          return; // Skip to the next coverage
        }
      }
      newRider.associatedCoverageId = coverage.associatedCoverageId;
      const partyId = coverage.life[0].partyId;
      const partyIndex = parties.findIndex((pty) => pty.partyId === partyId);
      let ratings = { ...eDefaultRatings };
      newRider.age = coverage.equivalentAge;
      if (partyIndex !== -1) {
        newRider.id = partyId;
        newRider.firstName = parties[partyIndex].firstName;
        newRider.lastName = parties[partyIndex].lastName;
        newRider.sex = parties[partyIndex].gender;
      }
      if (coverage.lifeRating) {
        ratings = reducerHelper.getInforceRatingsFromCoverage(ratings, coverage, product);
      }

      getInforceCoverageInfo(newRider, coverage);

      newRider.isFolded = false;
      newRider.id = id;
      newRider.clientId = clientId;
      newRider['clientIds'] = [];
      newRider['clientIds'].push(clientId);
      newRider.ratings = {};
      newRider.ratings[clientId] = { ...ratings };

      if (isProductAllowThirdPartyTIR(product) || isRiderEligibleForInforce(coverages, coverage, partyId)) {
        newState[newState.activeTabId][id] = newRider;
        newState[newState.activeTabId]['allRiders'].push(id);
      }
    }
  });
  return newState;
};

const isProductAllowThirdPartyTIR = (product) => [eProduct.Performax, eProduct.SB].includes(product);

const isRiderEligibleForInforce = (coverages, coverage, partyId) => {
  let isEligible = false;

  const baseCoverages = coverages.filter(
    (cvg) => cvg.indicatorCode === eField.indicatorCode.base && cvg.status === eBusinessType.IF
  );
  baseCoverages.forEach((cvg) => {
    if (cvg.life.find((life) => life.partyId === partyId)) {
      const associatedCoverage = coverages.find((x) => x.coverageId === coverage.associatedCoverageId);
      if (
        !associatedCoverage ||
        (associatedCoverage && cvg.life.find((life) => life.partyId === associatedCoverage.life[0].partyId))
      ) {
        isEligible = true;
      }
    }
  });
  return isEligible;
};

const combinedInforceTermOtherRider = (coverage, jointCoverage, clientId, product) => {
  const coverageDuration = getPerformaxTermRiderDuration(coverage.equivalentAge ? coverage.equivalentAge : 0);
  jointCoverage.coverageType = eCoverageType.combined;
  jointCoverage.coverageDuration =
    jointCoverage.coverageDuration < coverageDuration ? jointCoverage.coverageDuration : coverageDuration;
  let ratings = { ...eDefaultRatings };
  if (coverage.lifeRating) {
    ratings = reducerHelper.getInforceRatingsFromCoverage(ratings, coverage, product);
  }
  jointCoverage.ratings[clientId] = { ...ratings };
  jointCoverage['clientIds'].push(clientId);
  return jointCoverage;
};

const getTermOtherRiderState = (payload, state) => {
  const { riderId, data } = payload;
  const changedData = data ? data : { [payload.field]: payload.value };

  const checkCombinedCoverageType = payload.field === eField.covType && payload.value === eCoverageType.combined;
  if (payload.field === eField.coverageDuration || checkCombinedCoverageType) {
    changedData.coverageDuration =
      payload.field === eField.coverageDuration ? payload.value : state[state.activeTabId][riderId].coverageDuration;
  }
  return {
    ...state,
    [state.activeTabId]: {
      ...state[state.activeTabId],
      [riderId]: {
        ...state[state.activeTabId][riderId],
        ...changedData,
      },
    },
  };
};

export default termOtherRiderReducer;
