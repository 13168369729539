import _ from 'lodash';
import { eCoverageType, eProduct } from '../core/configs';

export const getAddScenarioTabNavsState = (state, action) => {
  return {
    ...state,
    activeTabId: action.payload.newTabId,
    [action.payload.newTabId]: { ...state[action.payload.currentTabId] },
  };
};

export const getClickScenarioTabNavsState = (state, action) => {
  return {
    ...state,
    activeTabId: action.payload,
  };
};

export const getRemoveScenarioTabNavsState = (state, action) => {
  const newState = _.omit(state, [action.payload.id]);

  return {
    ...newState,
    activeTabId: action.payload.newActiveTabId,
  };
};

export const getInforceRatingsFromCoverage = (ratings, coverage, product, partyId = 0) => {
  const defaultPercent = 100;
  const permanentDuration = 999;
  const defaultTempRatingAmount = 0;
  let lifeRatingToUse = coverage.lifeRating[0];
  if (product === eProduct.Performax || product === eProduct.SB) {
    setPerformaxRatings(ratings, coverage, permanentDuration);
  } else {
    if([eProduct.BT, eProduct.FT].includes(product) && coverage.coverageType === eCoverageType.combined){
      lifeRatingToUse = ratings;
      coverage?.lifeRating.forEach((covLifeRating) => {
        if(covLifeRating?.partyId === partyId){
          lifeRatingToUse = covLifeRating;
        }
      });
    }
    ratings.tempRatingYear = lifeRatingToUse.temporaryDuration ? lifeRatingToUse.temporaryDuration : 1;
    ratings.tempRatingAmount = getTempRatingAmount(lifeRatingToUse);
    ratings.permRatingAmount = getPermRatingAmount(lifeRatingToUse);
    ratings.permRatingPercent = lifeRatingToUse.permanentPercent
      ? lifeRatingToUse.permanentPercent
      : defaultPercent;
    if (ratings.tempRatingYear === permanentDuration) {
      ratings.permRatingAmount = ratings.tempRatingAmount;
      ratings.tempRatingAmount = defaultTempRatingAmount;
      ratings.tempRatingYear = 1;
    }
  }
  return ratings;
};

const setPerformaxRatings = (ratings, coverage, permanentDuration) => {
  const coverageRating = coverage.lifeRating[0];
  if (!coverageRating) {
    return;
  }

  ratings.permRatingAmount = coverageRating.permanentAmount;
  if (ratings.permRatingAmount) {
    ratings.permanentDuration = permanentDuration;
    ratings.permRatingPercent = coverageRating.permanentPercent;
  }

  ratings.tempRatingAmount = coverageRating.temporaryAmount;
  ratings.tempRatingYear = coverageRating.temporaryDuration;
  ratings.tempRatingPercent = coverageRating.temporaryPercent;

  ratings.tempRatingAmount2 = coverageRating.temporaryAmount2;
  ratings.tempRatingYear2 = coverageRating.temporaryDuration2;
  ratings.tempRatingPercent2 = coverageRating.temporaryPercent2;
};

const getTempRatingAmount = (lifeRatingToUse) => {
  if (lifeRatingToUse.temporaryAmount) {
    return lifeRatingToUse.temporaryAmount;
  } else if (lifeRatingToUse.temporaryModalAmount) {
    return lifeRatingToUse.temporaryModalAmount;
  } else {
    return 0;
  }
};

const getPermRatingAmount = (lifeRatingToUse) => {
  if (lifeRatingToUse.permanentAmount) {
    return lifeRatingToUse.permanentAmount;
  } else if (lifeRatingToUse.permanentModalAmount) {
    return lifeRatingToUse.permanentModalAmount;
  } else {
    return 0;
  }
};
