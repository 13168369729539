import React from 'react';
import './unsupported-browser.css';
import IENotSupportedEn from './ieNotSupportedEn';
import IENotSupportedFr from './ieNotSupportedFr';

const UnsupportedBrowserPage = () => (
  <div className="center" role="container-unsupportedbrowser">
    <IENotSupportedEn />
    <hr className="splitter-width" />
    <IENotSupportedFr />
  </div>
);

export default UnsupportedBrowserPage;
