import * as actionTypes from '../core/actionTypes';
import { eDefault, eField } from '../core/configs';
import _ from 'lodash';
import { advisorSeq } from '../core/sequenceUtils';
const initProfile = {
  guid: '',
  advisorName: '',
  companyName: '',
  residence: '',
  phoneNumber: null,
  extension: '',
  email: null,
  errors: {},
};
const getSavedProfile = () => {
  if (global.localStorage !== undefined) {
    const profile = localStorage.getItem('currentProfile');
    if (profile !== 'undefined' && profile) {
      const savedProfile = JSON.parse(profile);
      if (savedProfile !== null) {
        return savedProfile;
      }
    }
  }
  return null;
};
const savedOrDefaultProfile = getSavedProfile();
const initState = {
  isOpen: false,
  currentProfileId: eDefault.advisorProfile,
  allProfiles: [],
  workingProfile: {},
};

const getProfileNewState = (state, payload, newState) =>{
  const { field, value } = payload;
  switch (field) {
      case 'savedProfiles':
        if (value === undefined) {
          return newState;
        }
        newState = _.cloneDeep(state);
        _.forEach(value, (profile) => {
          const profileModel = profile.model;
          newState[profile.guid] = {
            guid: profile.guid,
            ...profileModel,
            errors: {},
          };
          if(!newState.allProfiles.includes(profile.guid)){
            newState.allProfiles.push(profile.guid);
          }
        });
        if (savedOrDefaultProfile) {
          newState.currentProfileId = savedOrDefaultProfile.guid;
          newState.workingProfile = savedOrDefaultProfile;
        } else {
          // profile was deleted elsewhere delete it from storage
          global.localStorage.setItem('currentProfile', null);
          newState.currentProfileId = newState.allProfiles[0] ? newState.allProfiles[0] : null;
          newState.workingProfile = newState[newState.allProfiles[0]] ? newState[newState.allProfiles[0]] : null;
        }
        return newState;
      case eField.advisorProfile:
        newState = _.cloneDeep(state);
        newState.currentProfileId = value;
        newState.workingProfile = newState[value];
        global.localStorage.setItem('currentProfile', JSON.stringify(newState[value]));
        return newState;
      default:
        return newState;
    }
};

const reduceChange = (state, payload) => {
  const { target, field, value } = payload;
  let newState = state;
  if (target === 'advisorProfile') {
    newState = _.cloneDeep(state);
    newState.workingProfile[field] = value;
  }
  if (target !== 'header' && target !== 'report') {
    return newState;
  }

  return getProfileNewState(state, payload, newState);
};

const openProfile = (state, menu) => {
  const newState = _.cloneDeep(state);
  if (menu === 'add') {
    const newAdvisorId = advisorSeq();
    newState.workingProfile = initProfile;
    newState.workingProfile.guid = newAdvisorId;
  } else {
    newState.workingProfile = _.cloneDeep(state[state.currentProfileId]);
  }
  newState.isOpen = true;
  return newState;
};
const saveProfile = (state, profileId) => {
  const newState = _.cloneDeep(state);
  if (!_.includes(state.allProfiles, profileId)) {
    newState.allProfiles = [profileId, ...state.allProfiles];
    newState.currentProfileId = profileId;
    newState.workingProfile.guid = profileId;
  }
  newState[newState.currentProfileId] = newState.workingProfile;
  global.localStorage.setItem('currentProfile', JSON.stringify(newState.workingProfile));
  return newState;
};
const deleteProfile = (state, profileId) => {
  const newState = _.omit(state, profileId);
  newState.allProfiles = _.filter(state.allProfiles, (advisorId) => advisorId !== profileId);
  if (newState.allProfiles.length < state.allProfiles.length) {
    // Note that there will always be a Default profile
    newState.currentProfileId = newState.allProfiles[0] ? newState.allProfiles[0] : null;
    newState.workingProfile = newState[newState.currentProfileId] ? newState[newState.currentProfileId] : null;
    newState.isOpen = false;
  }
  return newState;
};
export default function profileReducer(state, action) {
  state = state === undefined ? initState : state;
  switch (action.type) {
    case actionTypes.MWI_ON_CHANGE: {
      return reduceChange(state, action.payload);
    }
    case actionTypes.MWI_UPDATE_ERROR: {
      const { target, field, value } = action.payload;
      let newState = state;
      if (target === 'advisorProfile') {
        newState = _.cloneDeep(state);
        newState.workingProfile.errors[field] = value;
      }
      return newState;
    }
    case actionTypes.OPEN_PROFILE: {
      return openProfile(state, action.payload.menu);
    }
    case actionTypes.SAVE_PROFILE: {
      return saveProfile(state, action.payload.guid);
    }
    case actionTypes.DELETE_PROFILE: {
      return deleteProfile(state, action.payload.guid);
    }
    case actionTypes.CANCEL_PROFILE: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case actionTypes.TOGGLE_PROFILE: {
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    }
    default:
      return state;
  }
}
