import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { eLocale } from '../core/configs';

import './landing-page.css';

export class FamilyTermTrcDescription extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
  };

  render() {
    const { locale } = this.props;

    const numTextMessages = 5;
    const notCombinedMessageIndex = 2;

    return (
      <React.Fragment>
        <div id="family-term-trc-description">
          <FormattedMessage id="landing.ftrc.productDescription" />
        </div>
        <div className="p-col-12">
          <ul className="details-list">
            {_.map(_.range(1, numTextMessages, 1), (index) => {
              if (locale === eLocale.fr && index === 1) {
                return (
                  <li key={index}>
                    <FormattedMessage
                      id="multipart"
                      defaultMessage={`{part1}{part2}{part3}`}
                      values={{
                        part1: <FormattedMessage id="landing.ftrc.with.newtext1A" />,
                        part2: (
                          <span className="mwi-tm">
                            <FormattedMessage id="landing.ftrc.with.newtext1B" />
                          </span>
                        ),
                        part3: <FormattedMessage id="landing.ftrc.with.newtext1C" />,
                      }}
                    />
                  </li>
                );
              } else {
                return (
                  <li key={index}>
                    <FormattedMessage id={`landing.ftrc.with.newtext${index}`} />
                    {index === notCombinedMessageIndex && (
                      <span style={{ fontFamily: 'OpenSans-Bold' }}>
                        <FormattedMessage id={'landing.ftrc.with.newtext.notcombined'} />
                      </span>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <div style={{ marginTop: '12px' }}>
          <FormattedMessage id="landing.ftrc.productDescription2" />
        </div>
        <div style={{ marginTop: '12px' }}>
          <FormattedMessage id="landing.ftrc.productDescription3" />
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(FamilyTermTrcDescription));
