import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  eLang,
  eDividendOption,
  ePremiumOffset,
  eReport,
  eReportType,
  eProduct,
  ePageId,
  eDefault,
  eField,
  configs,
} from '../core/configs';
import ViewReportButton from '../core/components/view-report';
import MWIButton from '../core/components/mwi-button';
import MWIDropdown from '../core/components/mwi-dropdown';
import MWIDropdownSearch from '../core/components/mwi-dropdown-search';
import MWIInput from '../core/components/mwi-input';
import PageOptionCheckbox from './page-option-checkbox';
import DocumentTitle from 'react-document-title';
import { dispatchMWIUpdateError, dispatchMWIOnChange } from '../core/actions';
import _ from 'lodash';
import TitleBar from '../core/components/title-bar';
import ScenarioTabNavs from '../custom-mode/scenario-tab-navs';
import './report.css';
import { dropDownOptions } from '../core/dropdown-config';
import { HelpQuestionMark } from '../core/components/help-question-mark';
import AdvisorProfileModal from '../core/components/change-modals/advisor-profile-modal';
import ReactModal from 'react-modal';
import { openAdvisorProfile, deleteProfile } from '../profile/actions';
import { deleteProfile as deleteProfileSideEffect } from '../core/udm-mapper/sideEffect';
import { DeleteDialog } from '../core/components/delete-dialog';

const labelSelectAll = 'report.selectall';

export class Report extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    udmResponse: PropTypes.object,
    report: PropTypes.object,
    policyData: PropTypes.object,
    riders: PropTypes.object,
    coverages: PropTypes.object,
    product: PropTypes.string,
    intl: PropTypes.object,
    isMultiCov: PropTypes.bool,
    advisorProfile: PropTypes.object,
    openAdvisorProfileAction: PropTypes.func,
    deleteProfileAction: PropTypes.func,
    dispatchMWIUpdateErrorAppAction: PropTypes.func,
    dispatchMWIOnChangeAppAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClickUnderwrite = this.onClickUnderwrite.bind(this);

    this.dispatchAction('isOpen', true);

    this.setOpenProfileModal = this.setOpenProfileModal.bind(this);
    this.openAdvisorProfile = this.openAdvisorProfile.bind(this);
    this.setShowBusinessCardInfo = this.setShowBusinessCardInfo.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.hideDelete = this.hideDelete.bind(this);
    this.delete = this.delete.bind(this);
    this.setShowAdd = this.setShowAdd.bind(this);
    this.setShowEdit = this.setShowEdit.bind(this);
    this.setShowDelete = this.setShowDelete.bind(this);
    this.setprofileModalButtonLabel = this.setprofileModalButtonLabel.bind(this);
    this.setprofileModalTitle = this.setprofileModalTitle.bind(this);
    this.showParMulOptions = this.showParMulOptions.bind(this);
    this.showBusinessCardInfo = this.showBusinessCardInfo.bind(this);
    this.renderLanguageDropdown = this.renderLanguageDropdown.bind(this);
    this.renderReportLayoutDropdown = this.renderReportLayoutDropdown.bind(this);
    this.renderReportPageOptions = this.renderReportPageOptions.bind(this);
    this.renderLifechequePageOptions = this.renderLifechequePageOptions.bind(this);
    this.renderMULInforcePageOptions = this.renderMULInforcePageOptions.bind(this);
    this.renderStandaloneAdvisorReportOptions = this.renderStandaloneAdvisorReportOptions.bind(this);
    this.renderBusinessDisplayCheckbox = this.renderBusinessDisplayCheckbox.bind(this);
    this.renderBusinessCardTooltip = this.renderBusinessCardTooltip.bind(this);
    this.renderPreparedForInput = this.renderPreparedForInput.bind(this);
    this.renderPgoldPageOptions = this.renderPgoldPageOptions.bind(this);

    this.state = {
      openProfileModal: false,
      showBusinessCardInfo: true,
      showDeleteDialog: false,
      showAdd: true,
      showEdit: false,
      showDelete: false,
      profileModalButtonLabel: props.intl.formatMessage({ id: 'common.create' }),
      profileModalTitle: props.intl.formatMessage({ id: 'report.dialog.businessCard.title.add' }),
    };
  }

  componentDidMount() {
    if (this.isAnyRiderSelected()) {
      this.onChange(eReport.rp, true);
    }
  }

  componentDidUpdate() {
    if (this.props.advisorProfile.currentProfileId === eDefault.advisorProfile) {
      this.setShowDelete(false);
      this.setShowEdit(false);
    } else {
      this.setShowDelete(true);
      this.setShowEdit(true);
    }
    if (this.props.advisorProfile.allProfiles.length < configs.businessCardMaxCount) {
      this.setShowAdd(true);
    } else {
      this.setShowAdd(false);
    }
  }

  componentWillUnmount() {
    this.dispatchAction('isOpen', false);
  }

  dispatchAction(field, value, isError = false) {
    if (isError) {
      this.props.dispatchMWIUpdateErrorAppAction({
        field,
        value,
        target: 'report',
        product: this.props.product,
        isAnyRiderSelected: this.isAnyRiderSelected(),
        showOptions: {
          coverageDetails: this.props.isMultiCov,
          premiumOffset: this.showPremiumOffsetOptions(),
          cashDividends: this.showCashDividendsOptions(),
        },
      });
    } else {
      this.props.dispatchMWIOnChangeAppAction({
        field,
        value,
        target: 'report',
        product: this.props.product,
        isAnyRiderSelected: this.isAnyRiderSelected(),
        showOptions: {
          coverageDetails: this.props.isMultiCov,
          premiumOffset: this.showPremiumOffsetOptions(),
          cashDividends: this.showCashDividendsOptions(),
        },
      });
    }
  }

  showPremiumOffsetOptions() {
    const { coverages, policyData } = this.props;
    return (
      coverages[coverages.activeTabId].dividendOption === eDividendOption.pui &&
      policyData.premiumOffset !== ePremiumOffset.no
    );
  }

  showCashDividendsOptions() {
    const { coverages } = this.props;
    return coverages[coverages.activeTabId].dividendOption === eDividendOption.cash;
  }

  onChange(type, value) {
    const maxLength = 45;
    if ((type === 'preparedFor' || type === 'advisor') && value.length > maxLength) {
      return false;
    }

    this.dispatchAction(type, value);
    return true;
  }

  onBlur(type, value) {
    this.dispatchAction(type, value);
  }

  isAnyRiderSelected() {
    const anySelectedRiderClient = _.find(
      _.filter(this.props.riders, (rider) => rider.isSelected),
      (client) => typeof client === 'object' && client.isSelected
    );

    return !!anySelectedRiderClient;
  }

  onClick() {
    const { report, intl, product, coverages } = this.props;
    const productName = coverages[coverages.activeTabId].isVitalityPlusSelected ? product + 'VP' : product;
    const url = intl
      .formatMessage({ id: `report.link${productName}` })
      .replace('$language$', report.lang === eLang.en ? '_en' : '_fr');

    window.open(url);
  }

  onClickUnderwrite() {
    const { report, intl, product } = this.props;
    const url = intl
      .formatMessage({ id: `report.underwrite.link${product}` })
      .replace('$language$', report.lang === eLang.en ? '_en' : '_fr');

    window.open(url);
  }
  openAdvisorProfile(menu) {
    this.props.openAdvisorProfileAction({
      menu,
    });
  }

  addNew() {
    const { intl } = this.props;
    this.setprofileModalButtonLabel(intl.formatMessage({ id: 'common.create' }));
    this.openAdvisorProfile('add');
    this.setprofileModalTitle(intl.formatMessage({ id: 'report.dialog.businessCard.title.add' }));
    this.setOpenProfileModal(true);
  }

  edit() {
    const { intl } = this.props;
    this.setprofileModalButtonLabel(intl.formatMessage({ id: 'common.save' }));
    this.openAdvisorProfile('edit');
    this.setprofileModalTitle(intl.formatMessage({ id: 'report.dialog.businessCard.title.edit' }));
    this.setOpenProfileModal(true);
  }

  setOpenProfileModal(val) {
    this.setState({ openProfileModal: val });
  }

  getLanguageText(text) {
    let value = text;
    if (value === eDefault.advisorProfile) {
      value = _.find(configs.defaultProfile, ['id', this.props.locale]).value;
    }
    return value;
  }

  shortenName(name, length) {
    return name && name.length > length + 1 ? name.substring(0, length) + '...' : name;
  }

  setShowBusinessCardInfo() {
    if (this.state.showBusinessCardInfo) {
      this.dispatchAction(eField.advisorProfile, null);
    }
    this.setState({ showBusinessCardInfo: !this.state.showBusinessCardInfo });
  }

  openDeleteDialog() {
    this.setState({ showDeleteDialog: true });
  }

  hideDelete() {
    this.setState({ showDeleteDialog: false });
  }

  setShowDelete(val) {
    this.setState({ showDelete: val });
  }

  setShowEdit(val) {
    this.setState({ showEdit: val });
  }

  setShowAdd(val) {
    this.setState({ showAdd: val });
  }

  setprofileModalButtonLabel(val) {
    this.setState({ profileModalButtonLabel: val });
  }

  setprofileModalTitle(val) {
    this.setState({ profileModalTitle: val });
  }

  compareAdvisorNames(valA, valB) {
    if (valA.label.toUpperCase() < valB.label.toUpperCase()) {
      return -1;
    }
    if (valA.label.toUpperCase() > valB.label.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  getProfileNames(advisorProfile) {
    let advisorNames;
    if (advisorProfile) {
      advisorNames = _.map(advisorProfile.allProfiles, (guid) => {
        const nameLength = 15;
        return {
          label: advisorProfile[guid].advisorName
            ? this.shortenName(this.getLanguageText(advisorProfile[guid].advisorName), nameLength)
            : '-',
          value: guid,
        };
      });
    }
    return advisorNames.sort(this.compareAdvisorNames);
  }

  async delete() {
    const guid = this.props.advisorProfile.workingProfile.guid;

    try {
      await deleteProfileSideEffect([guid]);
      this.props.deleteProfileAction({ guid });
    } catch (err) {
      this.dispatchAction('errorMessage', err, undefined, true);
    }

    this.hideDelete();
  }

  showParMulOptions() {
    const { report, intl, product } = this.props;

    return (
      <>
        {!this.props.inforce && (product === eProduct.PAR || product === eProduct.MUL) && (
          <div className="p-col-12 mwi-padding-bottom24 flexdisplay pages">
            <PageOptionCheckbox
              value={'selectAll'}
              label={intl.formatMessage({ id: labelSelectAll })}
              isSelected={report.selectAll}
              onChange={(e) => this.onChange(e.target.value, e.target.checked)}
            />
            <PageOptionCheckbox
              value={eReport.irr}
              label={intl.formatMessage({ id: 'report.option.irr' })}
              isSelected={report[eReport.irr]}
              onChange={(e) => this.onChange(e.target.value, e.target.checked)}
            />
            <PageOptionCheckbox
              value={eReport.tcop}
              label={intl.formatMessage({ id: 'report.option.tcop' })}
              isSelected={report[eReport.tcop]}
              onChange={(e) => this.onChange(e.target.value, e.target.checked)}
            />
            {this.showPremiumOffsetOptions() && (
              <PageOptionCheckbox
                value={eReport.pos}
                label={intl.formatMessage({ id: 'report.option.pos' })}
                isSelected={report[eReport.pos]}
                onChange={(e) => this.onChange(e.target.value, e.target.checked)}
              />
            )}
            <PageOptionCheckbox
              value={eReport.tpop}
              label={intl.formatMessage({ id: 'report.option.tpop' })}
              isSelected={report[eReport.tpop]}
              onChange={(e) => this.onChange(e.target.value, e.target.checked)}
            />
            {this.showPremiumOffsetOptions() && (
              <PageOptionCheckbox
                value={eReport.pod}
                label={intl.formatMessage({ id: 'report.option.pod' })}
                isSelected={report[eReport.pod]}
                onChange={(e) => this.onChange(e.target.value, e.target.checked)}
              />
            )}
            {this.showCashDividendsOptions() && (
              <PageOptionCheckbox
                value={eReport.tpcd}
                label={intl.formatMessage({ id: 'report.option.tpcd' })}
                isSelected={report[eReport.tpcd]}
                onChange={(e) => this.onChange(e.target.value, e.target.checked)}
              />
            )}
            <PageOptionCheckbox
              value={eReport.ur}
              label={intl.formatMessage({ id: 'report.option.ur' })}
              isSelected={report[eReport.ur]}
              onChange={(e) => this.onChange(e.target.value, e.target.checked)}
            />
          </div>
        )}
      </>
    );
  }

  showBusinessCardInfo() {
    const { intl, advisorProfile } = this.props;
    const advisorProfileNames = this.getProfileNames(advisorProfile);

    return (
      <>
        {this.state.showBusinessCardInfo && (
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <MWIDropdownSearch
              label=""
              name="businessCard"
              advisorProfile={advisorProfile.currentProfileId}
              placeholder={intl.formatMessage({ id: 'profile.businessCard.select' })}
              value={
                advisorProfile === undefined || advisorProfile.currentProfileId === null
                  ? intl.formatMessage({ id: 'profile.businessCard.select' })
                  : advisorProfile.currentProfileId
              }
              options={advisorProfileNames}
              onChange={(e) => this.dispatchAction(eField.advisorProfile, e.value)}
              dropdownStyle="mwi-w95"
              isRequired={false}
              grid="5"
            />
            <div className="link-container">
              {this.state.showEdit && (
                <a
                  className="add-edit-delete-link"
                  onClick={() => this.edit()}
                  tabIndex={0}
                  aria-label={intl.formatMessage({ id: 'common.edit' })}
                  role="button"
                >
                  {intl.formatMessage({ id: 'common.edit' })}
                </a>
              )}
              {this.state.showDelete && (
                <a
                  className="add-edit-delete-link"
                  onClick={this.openDeleteDialog}
                  tabIndex={0}
                  aria-label={intl.formatMessage({ id: 'common.delete' })}
                  role="button"
                >
                  {intl.formatMessage({ id: 'common.delete' })}
                </a>
              )}
              {this.state.showAdd && (
                <a
                  className="add-edit-delete-link"
                  onClick={() => this.addNew()}
                  tabIndex={0}
                  aria-label={intl.formatMessage({ id: 'report.addNew' })}
                  role="button"
                >
                  {intl.formatMessage({ id: 'report.addNew' })}
                </a>
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  renderLanguageDropdown = () => {
    const { report, intl } = this.props;
    const { languages } = dropDownOptions(intl);

    return (
      <MWIDropdown
        label={intl.formatMessage({ id: 'report.language' })}
        name="lang"
        value={report.lang}
        options={languages}
        onChange={(e) => this.onChange('lang', e.target.value)}
        dropdownStyle="mwi-w85"
        containerStyle={'select-container'}
        isRequired={false}
        grid="4"
      />
    );
  };

  renderReportLayoutDropdown = () => {
    const { report, intl } = this.props;
    const { reportLayoutType } = dropDownOptions(intl);

    return (
      <MWIDropdown
        label={intl.formatMessage({ id: 'report.layout' })}
        name="layout"
        value={report.layout}
        options={reportLayoutType}
        onChange={(e) => this.onChange('layout', e.target.value)}
        dropdownStyle="mwi-w85"
        containerStyle={'select-container'}
        isRequired={false}
        grid="4"
      />
    );
  };

  isReportPageOptionsEligible = (product) =>
    product !== eProduct.PAR && product !== eProduct.MUL && product !== eProduct.LC && !this.props.inforce;

  renderReportPageOptions = () => {
    const { locale, report, intl, isMultiCov } = this.props;

    return (
      <React.Fragment>
        <div className={`select-all ${locale}`}>
          <PageOptionCheckbox
            value={'selectAll'}
            label={intl.formatMessage({ id: labelSelectAll })}
            isSelected={report.selectAll}
            onChange={(e) => this.onChange(e.target.value, e.target.checked)}
          />
        </div>
        <div className={`option-ur ${locale}`}>
          <PageOptionCheckbox
            value={eReport.ur}
            label={intl.formatMessage({ id: 'report.option.ur' })}
            isSelected={report[eReport.ur]}
            onChange={(e) => this.onChange(e.target.value, e.target.checked)}
          />
        </div>
        <div className="option-td">
          <PageOptionCheckbox
            value={eReport.td}
            label={intl.formatMessage({ id: 'report.option.td' })}
            isSelected={report[eReport.td]}
            onChange={(e) => this.onChange(e.target.value, e.target.checked)}
          />
        </div>
        {isMultiCov && (
          <div className={`option-cd ${locale}`}>
            <PageOptionCheckbox
              value={eReport.cd}
              label={intl.formatMessage({ id: 'report.option.cd' })}
              isSelected={report[eReport.cd]}
              onChange={(e) => this.onChange(e.target.value, e.target.checked)}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  renderLifechequePageOptions = () => {
    const { locale, report, intl } = this.props;

    return (
      <div className={`option-cd ${locale}`}>
        <PageOptionCheckbox
          value={eReport.cd}
          label={intl.formatMessage({ id: 'report.option.cd' })}
          isSelected={report[eReport.cd]}
          onChange={(e) => this.onChange(e.target.value, e.target.checked)}
        />
      </div>
    );
  };

  renderMULInforcePageOptions = () => {
    const { report, intl } = this.props;

    return (
      <div className="p-col-12 mwi-padding-bottom24 flexcolumn pages">
        <PageOptionCheckbox
          value={'selectAll'}
          label={intl.formatMessage({ id: labelSelectAll })}
          isSelected={report.selectAll}
          onChange={(e) => this.onChange(e.target.value, e.target.checked)}
        />
        <PageOptionCheckbox
          value={eReport.tcop}
          label={intl.formatMessage({ id: 'report.option.tcop' })}
          isSelected={report[eReport.tcop]}
          onChange={(e) => this.onChange(e.target.value, e.target.checked)}
        />
        <PageOptionCheckbox
          value={eReport.tpop}
          label={intl.formatMessage({ id: 'report.option.tpop' })}
          isSelected={report[eReport.tpop]}
          onChange={(e) => this.onChange(e.target.value, e.target.checked)}
        />
      </div>
    );
  };

  renderPgoldPageOptions = () => {
    const { report, intl } = this.props;

    return (
      <div className="p-col-12 mwi-padding-bottom24 flexcolumn pages">
        <PageOptionCheckbox
          value={eReport.td}
          label={intl.formatMessage({ id: 'report.option.td.PG' })}
          isSelected={report[eReport.td]}
          onChange={(e) => this.onChange(e.target.value, e.target.checked)}
        />
      </div>
    );
  };

  renderStandaloneAdvisorReportOptions = () => {
    const { intl, product } = this.props;

    return (
      <div className="standalone-reports">
        {![eProduct.Performax, eProduct.SB].includes(product) && (
          <div className="p-grid p-grid-no-margin label sub-label" role="heading" aria-level="2">
            {<FormattedMessage id="report.standalone" />}
          </div>
        )}
        <div className="p-grid p-grid-no-margin">
          {product === eProduct.LC && (
            <MWIButton
              label=" "
              styleClass="mwi-white-button oneflex"
              srOnlyLabel={intl.formatMessage({ id: 'sronly.newtab' })}
              onClick={this.onClickUnderwrite}
            >
              <span>
                {intl.formatMessage({ id: 'report.underwriting' })}
                <i className="material-icons opens-in-new-tab" aria-hidden="false">
                  open_in_new
                </i>
              </span>
            </MWIButton>
          )}
          {product !== eProduct.LC && !this.props.inforce && (
            <ViewReportButton
              styleClass="mwi-white-button oneflex"
              label={intl.formatMessage({ id: 'report.underwriting' })}
              reportType={eReportType.uw}
              srOnlyLabel={intl.formatMessage({ id: 'sronly.pdf' })}
              displayReportDropdown={false}
            />
          )}
        </div>
        <div className="p-grid p-grid-no-margin">
          {!this.props.inforce && (
            <ViewReportButton
              label={intl.formatMessage({ id: 'report.commission' })}
              reportType={eReportType.comm}
              srOnlyLabel={intl.formatMessage({ id: 'sronly.pdf' })}
              styleClass="mwi-white-button oneflex"
              displayReportDropdown={false}
            />
          )}
        </div>
        {![eProduct.Performax, eProduct.SB].includes(product) && (
          <div className="p-grid p-grid-no-margin">
            <MWIButton
              label=" "
              styleClass="mwi-white-button oneflex"
              srOnlyLabel={intl.formatMessage({ id: 'sronly.newtab' })}
              onClick={this.onClick}
            >
              <span>
                {intl.formatMessage({ id: 'report.productpage' })}
                <i className="material-icons opens-in-new-tab" aria-hidden="false">
                  open_in_new
                </i>
              </span>
            </MWIButton>
          </div>
        )}
      </div>
    );
  };

  renderBusinessDisplayCheckbox = () => {
    const { intl } = this.props;

    return (
      <div className="right-margin-4px">
        <PageOptionCheckbox
          value={'showBusinessCardInfo'}
          label={intl.formatMessage({ id: 'report.businessCard' })}
          isSelected={this.state.showBusinessCardInfo}
          onChange={this.setShowBusinessCardInfo}
        />
      </div>
    );
  };

  renderBusinessCardTooltip = () => {
    return (
      <div>
        <HelpQuestionMark
          containerClass="help-icon"
          overlayId="concept-description"
          overlayContent={() => (
            <div>
              <FormattedMessage id="report.businessCard.tooltip1" />
              <br />
              <FormattedMessage id="report.businessCard.tooltip2" />
              <br />
              <FormattedMessage id="report.businessCard.tooltip3" />
              <br />
              <FormattedMessage id="report.businessCard.tooltip4" />
              <br />
            </div>
          )}
          {...this.props}
        />
      </div>
    );
  };

  renderPreparedForInput = () => {
    const { report, intl } = this.props;

    return (
      <MWIInput
        name="preparedFor"
        label={intl.formatMessage({ id: 'report.preparedfor' })}
        placeholder={intl.formatMessage({ id: 'report.preparedfor' })}
        value={report.preparedFor}
        errors={report.errors}
        onChange={this.onChange}
        grid="4"
        styleClass="mwi-w85"
      />
    );
  };

  render() {
    const { locale, intl, product, inforce } = this.props;
    const isFtBtInforce = inforce && [eProduct.FT, eProduct.BT].includes(product);
    const isMulInforce = inforce && product === eProduct.MUL;
    return (
      <>
        <ReactModal
          className="add-profile-wrapper"
          isOpen={this.state.openProfileModal}
          shouldCloseOnOverlayClick
          shouldFocusAfterRender
          shouldReturnFocusAfterClose
          onRequestClose={() => this.setOpenProfileModal(false)}
          role="dialog"
          aria={{
            modal: 'true',
          }}
          ariaHideApp
        >
          <AdvisorProfileModal
            title={this.state.profileModalTitle}
            intl={intl}
            setOpenProfileModal={this.setOpenProfileModal}
            profileModalButtonLabel={this.state.profileModalButtonLabel}
            {...this.props}
          />
        </ReactModal>

        <ReactModal
          className={`delete-dialog-wrapper ${locale}`}
          contentLabel={intl.formatMessage({ id: 'advisor.deleteProfile' })}
          isOpen={this.state.showDeleteDialog}
          shouldCloseOnOverlayClick={false}
          onRequestClose={this.hideDelete}
          role="dialog"
        >
          <DeleteDialog
            title={intl.formatMessage({ id: 'report.businessCard.delete.title' })}
            message={intl.formatMessage({ id: 'report.businessCard.delete.message' })}
            delete={this.delete}
            cancel={this.hideDelete}
            locale={locale}
            intl={intl}
          />
        </ReactModal>
        <DocumentTitle
          title={`${intl.formatMessage({ id: 'common.illustrations' })} - ${intl.formatMessage({
            id: 'common.customizereport',
          })}`}
        >
          <div className="report-wrapper" role="container-report">
            <ScenarioTabNavs />
            <TitleBar messageId="common.customizereport" page={ePageId.report} top={164} />
            <div className="p-grid report-options-container sub-wrapper" role="container-reportoptions">
              <div className={`p-col-8 section${!isFtBtInforce ? ' report-option-section' : ''}`}>
                <div className="p-grid p-grid-no-margin label sub-label report-option" role="heading" aria-level="2">
                  {<FormattedMessage id="report.options" />}
                </div>
                <div className="p-grid p-grid-no-margin">
                  {this.renderLanguageDropdown()}
                  {product === eProduct.PAR && this.renderReportLayoutDropdown()}
                </div>

                <div className="p-grid p-grid-no-margin prepared-for">{this.renderPreparedForInput()}</div>
                {!isFtBtInforce && (
                  <div className="p-grid p-grid-no-margin sub-label" id="reportPages" role="heading" aria-level="3">
                    {<FormattedMessage id="report.label.pages" />}
                  </div>
                )}
                <div className="p-grid p-grid-no-margin report-page-option">
                  {this.isReportPageOptionsEligible(product) && !isFtBtInforce && this.renderReportPageOptions()}
                  {this.showParMulOptions()}
                  {product === eProduct.LC && this.renderLifechequePageOptions()}
                  {isMulInforce && this.renderMULInforcePageOptions()}
                  {[eProduct.PG, eProduct.Performax, eProduct.SB].includes(product) && this.renderPgoldPageOptions()}
                </div>
                <div
                  className="p-grid p-grid-no-margin sub-label"
                  id="reportFooter"
                  role="heading"
                  aria-level="4"
                  style={{ marginTop: '15px' }}
                >
                  {<FormattedMessage id="report.label.footer" />}
                </div>
                <div style={{ display: 'flex' }} className="report-page-option">
                  {this.renderBusinessDisplayCheckbox()}
                  {this.renderBusinessCardTooltip()}
                </div>
                {this.showBusinessCardInfo()}
              </div>

              <div className="p-col-3 p-lg-4 p-md-4 section">
                {!isFtBtInforce && this.renderStandaloneAdvisorReportOptions()}
              </div>
            </div>
          </div>
        </DocumentTitle>
      </>
    );
  }
}

// Don't remove udmResponse even though it isn't used explicityly.
// It is required to get updated UDM so that it passes illustrationId to document service
export const mapStateToProps = ({
  app,
  udmResponse,
  scenarioTabNavs,
  coverageTabNavs,
  riders,
  report,
  advisorProfile,
}) => {
  const curScenario = scenarioTabNavs.activeTabId;
  const policyData = scenarioTabNavs.topBars[curScenario];
  const coverages = coverageTabNavs[curScenario];

  return {
    locale: app.locale,
    inforce: app.inforcePolicy,
    report: report[curScenario],
    riders: riders[curScenario],
    product: policyData.product,
    isMultiCov: coverages.isMultiCov,
    udmResponse,
    coverages,
    advisorProfile,
    policyData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openAdvisorProfileAction: bindActionCreators(openAdvisorProfile, dispatch),
  deleteProfileAction: bindActionCreators(deleteProfile, dispatch),
  dispatchMWIUpdateErrorAppAction: bindActionCreators(dispatchMWIUpdateError, dispatch),
  dispatchMWIOnChangeAppAction: bindActionCreators(dispatchMWIOnChange, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Report));
