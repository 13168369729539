import './loan-information.css';

import { FormattedMessage, injectIntl } from 'react-intl';
import React, { PureComponent } from 'react';
import { calculateMWINumberFieldValue, deathAtAgeMax, getAge, getLoanToValueMax } from '../../utils';
import {
  eBankLoanRate,
  eCSVLoanPercentage,
  eCoverageType,
  eDividendOption,
  eDurationType,
  eField,
  eLoanAmount,
  eLoanAmountSelection,
  eLocale,
  ePremiumDuration,
} from '../../configs';

import { InputText } from 'primereact/components/inputtext/InputText';
import MWIDropDown from '../../components/mwi-dropdown';
import MWINumberField from '../../components/mwi-calculated-number-field';
import MWISelect from '../../components/mwi-select';
import PropTypes from 'prop-types';
import { calculateBankLoanRateDefault } from './bank-loan-default';
import { connect } from 'react-redux';

const maxAge = 65;

const getCvsLoanPercentage = function (locale, csvLoanPercentage, decSeparator) {
  return (
    <MWINumberField
      id={eField.csvLoanPercentage}
      name={eField.csvLoanPercentage}
      locale={locale}
      label={<FormattedMessage id="concept.CSVLoanPercentage" />}
      styleClass={locale === eLocale.en ? 'p-col-2 p-lg-3 p-xl-2 p-sm-6' : 'p-col-3 p-lg-5 p-xl-3 p-sm-6'}
      minValue={0}
      maxValue={eCSVLoanPercentage.default}
      value={csvLoanPercentage}
      onBlur={this.onValueChange}
      decimalScale={0}
      decimalSeparator={decSeparator}
      suffix=" %"
    />
  );
};

const getBankLoanRate = function (locale, bankLoanRate, decSeparator) {
  return (
    <MWINumberField
      id={eField.bankLoanRate}
      name={eField.bankLoanRate}
      label={<FormattedMessage id="concept.bankLoanRate" />}
      styleClass={
        'bank-loan-rate ' + (locale === eLocale.en ? 'p-col-2 p-lg-3 p-xl-2 p-sm-6' : 'p-col-3 p-lg-4 p-xl-3 p-sm-6')
      }
      minValue={eBankLoanRate.min}
      local={locale}
      value={bankLoanRate}
      maxValue={eBankLoanRate.max}
      decimalScale={2}
      fixedDecimalScale
      onBlur={this.onValueChange}
      decimalSeparator={decSeparator}
      suffix=" %"
    />
  );
};

const getDurationFrom = function (locale, durationType, from) {
  return (
    <MWINumberField
      containerClass="mwi-w100"
      id={eField.durationFrom}
      name={eField.durationFrom}
      locale={locale}
      label={<FormattedMessage id={durationType === eDurationType.age ? eField.agefrom : eField.yearfrom} />}
      styleClass="p-col-2 p-lg-3 p-xl-2 p-sm-4 mwi-padding-right12"
      minValue={this.getFromValueMin(durationType)}
      maxValue={this.getFromValueMax(durationType)}
      value={from}
      onBlur={this.onValueChange}
      decimalScale={0}
    />
  );
};

const getDurationTo = function (locale, durationType, switchToCashDividends, from, to, dividendOption) {
  return (
    <MWINumberField
      containerClass="mwi-w100"
      id={eField.durationTo}
      name={eField.durationTo}
      locale={locale}
      label={<FormattedMessage id={durationType === eDurationType.age ? eField.ageto : eField.yearto} />}
      styleClass="p-col-2 p-lg-3 p-xl-2 p-sm-4 mwi-padding-right12"
      minValue={switchToCashDividends ? this.getToValueMax(durationType) : from}
      maxValue={this.getToValueMax(durationType)}
      value={to}
      onBlur={this.onValueChange}
      decimalScale={0}
      disabled={switchToCashDividends || dividendOption === eDividendOption.cash}
    />
  );
};

export class LoanInformation extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    loanInformation: PropTypes.object,
    setConceptFieldValue: PropTypes.func,
    setDurationDefault: PropTypes.func,
    product: PropTypes.string,
    conceptDividendScale: PropTypes.string,
    activeScenarioTabId: PropTypes.string,
    coverageType: PropTypes.string,
    lifeExpectancyAssumption: PropTypes.object,
    client1: PropTypes.object,
    client2: PropTypes.object,
    loanAmountOptions: PropTypes.array,
    premiumDuration: PropTypes.string,
    primaryDividendScale: PropTypes.string,
    equivalentAge: PropTypes.number,
    minAge: PropTypes.number,
    switchToCashDividends: PropTypes.bool,
    dividendOption: PropTypes.string,
  };

  componentDidMount() {
    const { primaryDividendScale, loanInformation } = this.props;
    const bankLoanRate = loanInformation[eField.bankLoanRate];
    const from = loanInformation[eField.durationFrom];
    const to = loanInformation[eField.durationTo];
    const durationType = loanInformation[eField.duration];
    if (!bankLoanRate) {
      // if theres not bank loan rate, get the default value and update state
      this.onValueChange(eField.bankLoanRate, calculateBankLoanRateDefault(bankLoanRate, primaryDividendScale));
    }
    // set default values on first load only
    if (!from && !to) {
      this.setDefaultValues();
    } else {
      // recalculate values
      // fix issues with update and recalulation from deathAtAge
      this.recalculateDurationValues(from, to, durationType);
    }
  }
  componentDidUpdate(prevProps) {
    const { loanInformation } = this.props;
    const from = loanInformation[eField.durationFrom];
    const to = loanInformation[eField.durationTo];
    const durationType = loanInformation[eField.duration];
    if (
      this.props.lifeExpectancyAssumption[eField.deathAtAge] &&
      prevProps.lifeExpectancyAssumption[eField.deathAtAge] !== this.props.lifeExpectancyAssumption[eField.deathAtAge]
    ) {
      // death at age has been updated, we need to revalidate
      this.recalculateDurationValues(from, to, durationType);
    }
  }
  // recalculate values and update state
  recalculateDurationValues = (from, to, durationType) => {
    const newFrom = calculateMWINumberFieldValue(
      from,
      this.getFromValueMin(durationType),
      this.getFromValueMax(durationType)
    );
    const newTo = calculateMWINumberFieldValue(to, from, this.getToValueMax(durationType));
    this.setDefaultValues(durationType, newFrom, newTo);
  };
  onValueChange = (id, val) => {
    this.props.setConceptFieldValue(id, val, eField.loanInformation);
  };
  getAge = () => {
    const { client1, client2, coverageType } = this.props;
    return getAge(client1.age, client2 && client2.age, coverageType);
  };

  setDefaultValues = (
    durationType = eDurationType.age,
    from = Math.max(this.getAge(), maxAge),
    to = this.props.lifeExpectancyAssumption[eField.deathAtAge]
      ? this.props.lifeExpectancyAssumption[eField.deathAtAge] - 1
      : deathAtAgeMax(this.props.coverageType, this.props.equivalentAge, this.props.minAge)
  ) => {
    // set default duration values
    this.props.setDurationDefault(durationType, from, to);
  };
  getFromValueMin = (durationType) => {
    if (durationType === eDurationType.year) {
      return 1;
    }
    return this.getAge();
  };
  getFromValueMax = (durationType) => {
    const age2 = 2;
    const deathAtAge = this.props.lifeExpectancyAssumption[eField.deathAtAge];
    if (durationType === eDurationType.year) {
      return deathAtAge - this.getAge() - 1;
    }
    return deathAtAge - age2;
  };

  getJointLastPayLastDeath() {
    const { locale, intl, coverageType } = this.props;

    return (
      <>
        {coverageType === eCoverageType.jointLastPayLastDeath && (
          <div className="p-grid p-grid-no-margin p-col-12 mwi-margin-bottom24">
            <div className={locale === eLocale.en ? 'p-col-4 p-md-5 p-xl-4 p-sm-6' : 'p-col-5 p-xl-5 p-md-7 p-sm-6'}>
              <div className="mwi-label" id="baseLoanAmountDuration">
                {<FormattedMessage id="concept.baseLoanAmountDuration" />}
              </div>
              <div className="p-col-12">
                <InputText
                  name={eField.youngestLife}
                  id={eField.youngestLife}
                  value={intl.formatMessage({ id: 'concept.youngestLife' })}
                  disabled
                  aria-describedby="baseLoanAmountDuration"
                  className="mwi-widget mwi-w100 mwi-input"
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  getLoanAmountSpecified() {
    const { locale, loanInformation } = this.props;
    const loanAmount = loanInformation[eField.loanAmountSelection];
    const amount = loanInformation[eField.loanAmount];

    return (
      <>
        {loanAmount === eLoanAmountSelection.specified && (
          <div className="p-col-4 p-lg-4 p-sm-6">
            <MWINumberField
              id={eField.loanAmount}
              name={eField.loanAmount}
              locale={locale}
              styleClass={'loan-amount-input'}
              containerClass="mwi-margin-top24"
              minValue={eLoanAmount.min}
              maxValue={eLoanAmount.max}
              value={amount}
              onBlur={this.onValueChange}
              decimalScale={0}
              thousandSeparator={locale === eLocale.en ? ',' : ' '}
              prefix={locale === eLocale.en ? '$ ' : ''}
              suffix={locale === eLocale.fr ? ' $' : ''}
            />
          </div>
        )}
      </>
    );
  }

  getPay90() {
    const { intl, loanInformation, premiumDuration } = this.props;
    const assumeInterestDeductibility = loanInformation[eField.assumeInterestDeductibility];
    const collateralInsuranceDeduction = loanInformation[eField.collateralInsuranceDeduction];

    if (!(premiumDuration === ePremiumDuration.Pay90 && assumeInterestDeductibility)) {
      return null;
    }

    return (
      <div className={'collateral-container p-col-4 p-lg-3 p-sm-6'}>
        <div className="mwi-label" id="collateralInsuranceDeduction">
          <span>
            <FormattedMessage id="concept.collateralInsuranceDeduction" />
          </span>
        </div>
        <MWISelect
          labelL={intl.formatMessage({ id: 'common.yes' })}
          labelR={intl.formatMessage({ id: 'common.no' })}
          selectedL={collateralInsuranceDeduction}
          selectedR={!collateralInsuranceDeduction}
          ariaDescribedBy="collateralInsuranceDeduction"
          onClickL={() => this.onValueChange(eField.collateralInsuranceDeduction, true)}
          onClickR={() => this.onValueChange(eField.collateralInsuranceDeduction, false)}
        />
      </div>
    );
  }

  getToValueMax = (durationType) =>
    getLoanToValueMax(durationType, this.props.lifeExpectancyAssumption[eField.deathAtAge], getAge());

  onDurationTypeChange = (value) => {
    //if switching from age to year, convert values and vice versa
    let newFrom, newTo;
    const { loanInformation } = this.props;
    const from = loanInformation[eField.durationFrom];
    const to = loanInformation[eField.durationTo];
    const durationType = loanInformation[eField.duration];
    if (value === eDurationType.year && durationType === eDurationType.age) {
      newFrom = from - this.getAge() + 1;
      newTo = to - this.getAge() + 1;
    } else {
      if (value === eDurationType.age && durationType === eDurationType.year) {
        newFrom = from + this.getAge() - 1;
        newTo = to + this.getAge() - 1;
      }
    }
    if (newFrom && newTo) {
      this.setDefaultValues(value, newFrom, newTo);
    }
  };

  render() {
    const { locale, intl, loanInformation, loanAmountOptions, switchToCashDividends, dividendOption } = this.props;
    // unpack props using eField Values
    const assumeInterestDeductibility = loanInformation[eField.assumeInterestDeductibility];
    const bankLoanRate = loanInformation[eField.bankLoanRate];
    const csvLoanPercentage = loanInformation[eField.csvLoanPercentage];
    const loanAmount = loanInformation[eField.loanAmountSelection];
    const durationType = loanInformation[eField.duration];
    const from = loanInformation[eField.durationFrom];
    const to = loanInformation[eField.durationTo];
    const decSeparator = locale === eLocale.en ? '.' : ',';

    return (
      <div className="mwi-margin-bottom24" id="loan-information-container">
        {this.getJointLastPayLastDeath()}
        <div className="p-grid p-grid-no-margin p-col-12 mwi-margin-bottom24">
          <div className="p-col-4 p-lg-4 p-sm-6">
            <div className="p-col-12">
              <MWIDropDown
                label={<FormattedMessage id="concept.loanAmount" />}
                name={eField.loanAmountSelection}
                id={eField.loanAmountSelection}
                options={loanAmountOptions}
                onChange={(e) => this.onValueChange(eField.loanAmountSelection, e.target.value)}
                value={loanAmount}
                grid={'12'}
              />
            </div>
          </div>
          {this.getLoanAmountSpecified()}
        </div>
        <div className="p-grid p-grid-no-margin p-col-12 mwi-margin-bottom24">
          <div className="p-col-3 p-lg-3 p-sm-4">
            <div className="mwi-label">
              <span>
                <FormattedMessage id="common.duration" />
              </span>
            </div>
            <MWISelect
              ariaDescribedBy="common.duration"
              labelL={intl.formatMessage({ id: 'vitality.age' })}
              labelR={intl.formatMessage({ id: 'vitality.year' })}
              onClickL={() => this.onDurationTypeChange(eDurationType.age)}
              onClickR={() => this.onDurationTypeChange(eDurationType.year)}
              selectedL={durationType === eDurationType.age}
              selectedR={durationType === eDurationType.year}
            />
          </div>
          {getDurationFrom.call(this, locale, durationType, from)}
          {getDurationTo.call(this, locale, durationType, switchToCashDividends, from, to, dividendOption)}
        </div>
        <div className="p-grid p-grid-no-margin p-col-12 mwi-margin-bottom24">
          {getCvsLoanPercentage.call(this, locale, csvLoanPercentage, decSeparator)}
        </div>
        <div className="p-grid p-grid-no-margin p-col-12">
          {getBankLoanRate.call(this, locale, bankLoanRate, decSeparator)}
          <div
            className={`${
              locale === eLocale.en ? 'p-col-3 p-lg-5 p-xl-4' : 'p-col-4 p-lg-6 p-xl-5'
            } mwi-padding-right10`}
          >
            <div className="mwi-label" id="interestDeductibilty">
              <span>
                <FormattedMessage id="concept.assumeInterestDeductibility" />
              </span>
            </div>
            <MWISelect
              labelL={intl.formatMessage({ id: 'common.yes' })}
              labelR={intl.formatMessage({ id: 'common.no' })}
              selectedL={assumeInterestDeductibility}
              selectedR={!assumeInterestDeductibility}
              ariaDescribedBy="interestDeductibilty"
              onClickL={() => this.onValueChange(eField.assumeInterestDeductibility, true)}
              onClickR={() => this.onValueChange(eField.assumeInterestDeductibility, false)}
            />
          </div>
          {this.getPay90()}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, coverageTabNavs }) => {
  const activeCoverageTab = coverageTabNavs[coverageTabNavs.activeTabId];
  const activeCoverage = activeCoverageTab[activeCoverageTab.activeTabId];
  const { switchToCashDividends, dividendOption, premiumDuration } = activeCoverage;
  return {
    locale: app.locale,
    premiumDuration,
    switchToCashDividends,
    dividendOption,
  };
};

export default connect(mapStateToProps, null)(injectIntl(LoanInformation));
