import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import MWIButton from '../mwi-button';
import MWIInput from '../mwi-input';
import MWIDropdown from '../mwi-dropdown';
import { createCase, updateCase } from '../../udm-mapper/sideEffect';
import UDMGenerator from '../../udm-mapper/udmGenerator';
import { connect } from 'react-redux';
import { saveCaseStarted, saveCaseFinished, saveCaseError, saveOpenCaseName, dispatchMWIOnChange } from '../../actions';
import { bindActionCreators } from 'redux';
import { eReqStatus, eActionEvent, newIllustrationAutoSaved, persistenceErrors } from '../../configs';
import { trackUserActions } from '../../utils';
import { Growl } from 'primereact/growl';
import './savecase.css';
import HelpQuestionMark from '../../../core/components/help-question-mark';

const checkOpenCaseName = function (openedCaseName) {
  return openedCaseName !== '' || openedCaseName !== newIllustrationAutoSaved;
};

const checkGuid = function (guid, fileName) {
  return guid !== undefined || fileName !== '';
};

export class CaseSaveModel extends PureComponent {
  static propTypes = {
    app: PropTypes.object,
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    locale: PropTypes.string,
    intl: PropTypes.object,
    title: PropTypes.string,
    model: PropTypes.object,
    isSaveAs: PropTypes.bool,
    isDuplicate: PropTypes.bool,
    setDuplicate: PropTypes.func,
    fileName: PropTypes.string,
    saveCaseStartedAppAction: PropTypes.func,
    saveCaseFinishedAppAction: PropTypes.func,
    saveCaseErrorAppAction: PropTypes.func,
    saveOpenCaseNameAppAction: PropTypes.func,
    dispatchMWIOnChangeAppAction: PropTypes.func,
  };

  static defaultProps = {
    fileName: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      name: this.props.fileName,
      showAssignCase: false,
      showSaveCase: true,
      isAssignConfirmed: false,
      saveCaseForId: '-1',
      createdForPartyId: '-1',
      createdForCode: '',
      createdForName: '',
      isNameInvalid: false,
      isDDInvalid: false,
      ddStyleClass: 'ddDefault',
    };

    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveButton = React.createRef();
    this.focusSaveModal = this.focusSaveModal.bind(this);
    this.getCaseModelName = this.getCaseModelName.bind(this);
    this.getCaseModel = this.getCaseModel.bind(this);
    this.setguidNull = this.setguidNull.bind(this);
    this.setDuplicateProp = this.setDuplicateProp.bind(this);
    this.manipulateResponse = this.manipulateResponse.bind(this);
  }

  componentDidMount() {
    this.onCloseConfirmClick = this.onCloseConfirmClick.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.onChangeSaveCaseFor = this.onChangeSaveCaseFor.bind(this);
    this.getCreatedForName = this.getCreatedForName.bind(this);

    // Do the save in background if already saved
    const {
      app: { guid, openedCaseName },
      isSaveAs,
      fileName,
    } = this.props;

    const checkGuidAndOpenCaseName = checkGuid(guid, fileName) && !isSaveAs && checkOpenCaseName(openedCaseName);
    if (guid !== '' && checkGuidAndOpenCaseName) {
      this.save();
      this.cancel();
    }

    this.focusSaveModal();
  }

  onChange(type, value) {
    const maxLength = 60;
    if (value.length > maxLength) {
      return false;
    } else {
      this.setState({ name: value });
      this.props.setDuplicate(false);
      return true;
    }
  }

  dispatchSaveCaseStarted() {
    this.props.saveCaseStartedAppAction({
      target: 'app',
    });
  }

  dispatchSaveCaseFinished() {
    this.props.saveCaseFinishedAppAction({
      target: 'app',
    });
  }

  dispatchSaveCaseError(errResponse) {
    this.props.saveCaseErrorAppAction({
      target: 'app',
      value: errResponse,
    });
  }

  dispatchOpenedCaseName(name) {
    this.props.saveOpenCaseNameAppAction({
      target: 'app',
      name,
    });
  }

  dispatchAction(field = 'guid', value = '', target = 'app') {
    this.props.dispatchMWIOnChangeAppAction({
      target,
      field,
      value,
    });
  }

  focusSaveModal() {
    if (this.saveButton.current) {
      this.saveButton.current.input.current.element.focus();
    }
  }

  onCloseConfirmClick() {
    this.setState({ showAssignCase: false });
    this.setState({ showSaveCase: true });
    this.setState({ isAssignConfirmed: false });
  }

  onConfirmClick() {
    this.setState({ showSaveCase: false });
    this.setState({ showAssignCase: false });
    this.setState({ isAssignConfirmed: true }, () => {
      this.save();
    });
  }

  onChangeSaveCaseFor(target) {
    if (target.value === '-1') {
      this.setState({ ddStyleClass: 'ddDefault' });
    } else {
      this.setState({ ddStyleClass: 'ddSelected' });
    }

    const partyId = target.value === '' ? undefined : target.value;
    this.setState({ saveCaseForId: partyId });
    this.setState({ createdForName: target.options[target.selectedIndex].text });
    this.setState({ createdForPartyId: partyId });
    const selectedAdvisor = this.props.app.advisorList.find((adv) => adv.partyId === partyId);

    this.setState({ createdForCode: selectedAdvisor == null ? null : selectedAdvisor.advisorCode });
  }

  get AdvisorList() {
    this.setState({ createdByPartyId: this.props.app.partyId });

    const partyId = this.props.app.partyId ? this.props.app.partyId : '';
    const ddList = [
      { label: this.props.intl.formatMessage({ id: 'common.select' }), value: '-1' },
      { label: this.props.intl.formatMessage({ id: 'assign.model.myself' }), value: partyId },
    ];
    this.props.app.advisorList.forEach(function (advisor) {
      ddList.push({ label: advisor.fullName, value: advisor.partyId });
    });
    return ddList;
  }

  isDuplicate(errorMessage) {
    return errorMessage.toUpperCase().indexOf(persistenceErrors.duplicate.toUpperCase()) >= 0;
  }

  getCreatedForName() {
    if (this.props.app.canAssignCase) {
      return this.state.createdForPartyId === this.state.createdByPartyId
        ? this.props.app.userName
        : this.state.createdForName;
    } else {
      return this.props.app.userName;
    }
  }

  isCaseguidDefined(caseModel) {
    const isguidDefined = caseModel.guid === undefined || caseModel.guid === '' ? false : true;
    return isguidDefined;
  }

  isCaseNameDefined(caseModel) {
    const isnameDefined = caseModel.name === undefined || caseModel.name === '' ? false : true;
    return isnameDefined;
  }

  getCaseModelName() {
    const {
      app: { openedCaseName, autoSaveCaseLoaded },
      isSaveAs,
    } = this.props;

    if (!isSaveAs && openedCaseName !== '' && !autoSaveCaseLoaded) {
      return openedCaseName;
    } else {
      return this.state.name.trim();
    }
  }

  getCaseModel() {
    const {
      model,
      isSaveAs,
      app: { userID, guid, openedCaseName },
    } = this.props;

    const udmGenerator = new UDMGenerator();
    const udm = udmGenerator.setProps(this.props).getUDM();

    return {
      model,
      udm,
      guid: isSaveAs || openedCaseName === '' ? '' : guid,
      name: this.getCaseModelName(),
      userId: userID,
      product: model.scenarioTabNavs.topBars['Scenario.1'].product,
      createdForPartyId: this.props.app.canAssignCase ? this.state.createdForPartyId : this.props.app.partyId,
      createdForCode: this.state.createdForCode,
      createdForName: this.getCreatedForName(),
    };
  }

  isNameValid(caseModel) {
    const isValid = caseModel.name !== undefined || caseModel.name !== '';
    return isValid;
  }

  isDDIValid(caseModel) {
    const isValid = !this.props.app.canAssignCase && caseModel.createdForPartyId !== '-1';
    return isValid;
  }

  setguidNull(caseModel) {
    const {
      app: { autoSaveCaseLoaded },
    } = this.props;

    if (autoSaveCaseLoaded) {
      caseModel.guid = '';
    }

    return caseModel;
  }

  isValidResponse(response) {
    const isValid = response !== undefined && response !== null && response.isError ? true : false;
    return isValid;
  }

  setDuplicateProp() {
    if (this.props.setDuplicate) {
      this.props.setDuplicate(true);
    }
  }

  manipulateResponse(response, caseModel) {
    if (this.isValidResponse(response)) {
      this.setState({ showSaveCase: true });
      if (this.isDuplicate(response.error)) {
        this.props.setDuplicate(true);
      }
      this.dispatchSaveCaseError(response.message);
      this.focusSaveModal();
    } else {
      this.dispatchOpenedCaseName(caseModel.name);
      this.dispatchAction('guid', response.items[0].guid);
      this.cancel();
      this.dispatchSaveCaseFinished();
    }
  }

  save() {
    const { model } = this.props;

    let caseModel = this.getCaseModel();

    this.setState({ isNameInvalid: !this.isNameValid(caseModel) });
    this.setState({ isDDInvalid: !this.isDDIValid(caseModel) });

    if (
      (!this.isCaseguidDefined(caseModel) && !this.isCaseNameDefined(caseModel)) ||
      (this.props.app.canAssignCase && caseModel.createdForPartyId === '-1')
    ) {
      return;
    }

    if (
      !this.state.isAssignConfirmed &&
      this.state.saveCaseForId !== undefined &&
      this.state.saveCaseForId !== this.state.createdByPartyId &&
      this.props.app.canAssignCase
    ) {
      this.setState({ showAssignCase: true });
      this.setState({ showSaveCase: false });
      return;
    } else {
      this.setState({ showAssignCase: false });
      this.setState({ showSaveCase: false });
    }

    trackUserActions(eActionEvent.illustration, 'complete', model.clients);

    this.dispatchSaveCaseStarted();

    caseModel = this.setguidNull(caseModel);

    if (!this.isCaseguidDefined(caseModel)) {
      createCase(caseModel)
        .then((response) => {
          this.manipulateResponse(response, caseModel);
        })
        .catch((errResponse) => {
          this.dispatchAction('errorMessage', errResponse);
          this.setDuplicateProp();
          this.setState({ showSaveCase: true });
        });
    } else {
      updateCase(caseModel, caseModel.guid)
        .then((response) => {
          this.dispatchSaveCaseFinished();
          this.dispatchOpenedCaseName(caseModel.name);
          if (response.status.toUpperCase().indexOf(eReqStatus.success) < 0) {
            this.setState({ showSaveCase: true });
            this.props.setDuplicate(true);
            this.focusSaveModal();
          } else {
            this.dispatchAction('guid', response.items[0].guid);
            this.cancel();
          }
        })
        .catch((errResponse) => {
          this.dispatchAction('errorMessage', errResponse);
          this.setState({ showSaveCase: true });
          this.setDuplicateProp();
        });
    }
  }

  cancel() {
    this.props.cancel();
  }

  renderSaveCaseInput = () => {
    const {
      title,
      intl,
      app: { errorMessage },
    } = this.props;

    return (
      <div id="saveCaseInput" className="p-col-12" role="container-savecase">
        <div className="p-col-12 title-model ">{title}</div>
        <div className="p-col-12 row-model">
          <MWIInput
            containerClass="p-col-12"
            id="saveCaseName"
            name=""
            labelId="saveCase"
            sizeStyle={this.state.isNameInvalid ? 'mwi-w100 mwi-error-field' : 'mwi-w100'}
            label={<FormattedMessage id="header.model.label" />}
            placeholder={`${intl.formatMessage({ id: 'header.model.placeholder' })}`}
            onChange={this.onChange}
            value={this.state.name}
            ref={this.saveButton}
            inputFieldStyle=""
            styleClass="savecase-input"
          />
          {this.state.isNameInvalid && (
            <span className="caseSave-error">{intl.formatMessage({ id: 'assign.model.error.required' })}</span>
          )}
          {this.props.isDuplicate && (
            <div className="error">
              <FormattedMessage id="header.model.error" />
            </div>
          )}

          {this.props.app.canAssignCase && (
            <MWIDropdown
              name="saveCaseForDropdown"
              placeholder=""
              value={this.state.saveCaseForId}
              isRequired
              options={this.AdvisorList}
              onChange={(e) => this.onChangeSaveCaseFor(e.target)}
              errorState={this.state.isDDInvalid}
              errorText={intl.formatMessage({ id: 'assign.model.error.required' })}
              containerStyle="p-col-12 savecase"
              dropdownStyle={this.state.ddStyleClass}
              labelStyle="ddSavecaseLabel"
              label={
                <>
                  <FormattedMessage id="save-case.save-as.label" />
                  <HelpQuestionMark
                    containerClass="help"
                    overlayId="assignTip"
                    overlayContent={() => <FormattedMessage id={'assign.model.tooltip'} />}
                  />
                </>
              }
            />
          )}
        </div>
        <div className="p-col-12 rating-footer flexdisplay">
          <div className="p-col-3" />
          <MWIButton
            id="btnSaveCancel"
            name="cancel"
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={this.cancel}
            styleClass="mwi-w80 saveas-cancel-button cancel-btn oneflex p-col-4"
          />
          <MWIButton
            id="btnSave"
            name="save"
            label={intl.formatMessage({ id: 'common.save' })}
            onClick={this.save}
            styleClass="mwi-w80 save-button oneflex p-col-4"
            disabled={(errorMessage && errorMessage.length > 0) || this.props.isDuplicate}
          />
        </div>
      </div>
    );
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="p-grid p-grid-no-margin-side">
        <Growl ref={(el) => (this.growl = el)} />
        {this.state.showSaveCase && this.renderSaveCaseInput()}

        {this.state.showAssignCase && (
          <div id="confirmDiv">
            <h2 className="p-col-12 title-model">{<FormattedMessage id="assign.model.header.label" />}</h2>
            <div className="p-col-12 row-model mwi-label">
              <span>
                {intl.formatMessage({ id: 'assign.model.text1' })} <b>{this.state.createdForName}</b>.
              </span>
            </div>
            <div className="p-col-12 row-model mwi-label">{<FormattedMessage id="assign.model.text2" />}</div>

            <div className="p-col-12 rating-footer flexdisplay">
              <div className="p-col-3" />
              <MWIButton
                id="btnAssignCancel"
                name="cancel"
                label={intl.formatMessage({ id: 'common.cancel' })}
                onClick={this.onCloseConfirmClick}
                styleClass="mwi-w80 reset-cancel-button cancel-btn oneflex p-col-4"
              />

              <MWIButton
                id="btnAssign"
                name="assign"
                label={intl.formatMessage({ id: 'assign.model.button.assign' })}
                onClick={this.onConfirmClick}
                styleClass="mwi-w80 save-button oneflex p-col-4"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const mapStateToProps = ({
  app,
  landingPage,
  scenarioTabNavs,
  coverageTabNavs,
  conceptTabNavs,
  clients,
  riders,
  termRiders,
  vitalityStatus,
  report,
  spreadsheet,
  advisorProfile,
  myCases,
  withdrawals,
  deposits,
}) => {
  const curScenario = scenarioTabNavs.activeTabId;
  const model = {
    landingPage,
    scenarioTabNavs,
    coverageTabNavs,
    conceptTabNavs,
    clients,
    riders,
    termRiders,
    vitalityStatus,
    report,
    spreadsheet,
    advisorProfile,
    myCases,
    withdrawals,
    deposits,
  };
  return {
    app,
    model,
    coverageTabNavs,
    advisorProfile,
    deposits,
    coverages: coverageTabNavs[curScenario],
    concepts: conceptTabNavs[curScenario],
    report: report[scenarioTabNavs.activeTabId],
    policyData: scenarioTabNavs.topBars[curScenario],
    clients: clients[curScenario],
    riders: riders[curScenario],
    termRiders: termRiders[curScenario],
    vitalityStatus: vitalityStatus[curScenario],
    withdrawal: withdrawals[curScenario],
    activeScenarioTabId: curScenario,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveCaseStartedAppAction: bindActionCreators(saveCaseStarted, dispatch),
  saveCaseFinishedAppAction: bindActionCreators(saveCaseFinished, dispatch),
  saveCaseErrorAppAction: bindActionCreators(saveCaseError, dispatch),
  saveOpenCaseNameAppAction: bindActionCreators(saveOpenCaseName, dispatch),
  dispatchMWIOnChangeAppAction: bindActionCreators(dispatchMWIOnChange, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CaseSaveModel));
