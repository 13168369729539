import {
  MWI_ON_CHANGE,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  MWI_UPDATE_ERROR,
  TOGGLE_LOCALE,
  TOGGLE_ACCESSIBLE,
  COMMON_INITIALIZE,
  OPEN_SAVEDCASE,
  DELETE_SAVEDCASES,
  SAVE_OPEN_CASE_NAME,
  AUTH_USER,
  SAVE_CASE_STARTED,
  SAVE_CASE_FINISHED,
  SAVE_CASE_ERROR,
  DELETE_CASE_ERROR,
  SHOW_RATES_NOTIFICATION,
  HIDE_RATES_NOTIFICATION,
  SHOW_STATUS_DIALOG,
  HIDE_STATUS_DIALOG,
  LOADING_INFORCE_REQUEST_FORM,
  LOADED_INFORCE_REQUEST_FORM,
  ERROR_INFORCE_REQUEST_FORM, AUTH_CIAM_INFO,
} from './actionTypes';
import { makeActionCreator } from './utils';

export const dispatchMWIOnChange = makeActionCreator(MWI_ON_CHANGE);
export const showNotification = makeActionCreator(SHOW_NOTIFICATION);
export const hideNotification = makeActionCreator(HIDE_NOTIFICATION);
export const dispatchMWIUpdateError = makeActionCreator(MWI_UPDATE_ERROR);
export const toggleLanguage = makeActionCreator(TOGGLE_LOCALE);
export const toggleAccessible = makeActionCreator(TOGGLE_ACCESSIBLE);
export const appInitialize = makeActionCreator(COMMON_INITIALIZE);
export const openSaveCase = makeActionCreator(OPEN_SAVEDCASE);
export const deleteSavedCases = makeActionCreator(DELETE_SAVEDCASES);
export const deleteCaseError = makeActionCreator(DELETE_CASE_ERROR);
export const saveOpenCaseName = makeActionCreator(SAVE_OPEN_CASE_NAME);
export const saveCaseError = makeActionCreator(SAVE_CASE_ERROR);
export const authenticateUser = makeActionCreator(AUTH_USER);
export const saveCaseStarted = makeActionCreator(SAVE_CASE_STARTED);
export const saveCaseFinished = makeActionCreator(SAVE_CASE_FINISHED);
export const showRatesNotification = makeActionCreator(SHOW_RATES_NOTIFICATION);
export const hideRatesNotification = makeActionCreator(HIDE_RATES_NOTIFICATION);
export const showStatusDialog = makeActionCreator(SHOW_STATUS_DIALOG);
export const hideStatusDialog = makeActionCreator(HIDE_STATUS_DIALOG);
export const loadingInforceRequestForm = makeActionCreator(LOADING_INFORCE_REQUEST_FORM);
export const loadedInforceRequestForm = makeActionCreator(LOADED_INFORCE_REQUEST_FORM);
export const errorInforceRequestForm = makeActionCreator(ERROR_INFORCE_REQUEST_FORM);
export const getCiamInfo = makeActionCreator(AUTH_CIAM_INFO);
