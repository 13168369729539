import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import MWIButton from '../mwi-button';
import CustomVitality from '../../../custom-mode/custom-vitality';
import './vitality-button.css';

export class VitalityButton extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    disabled: PropTypes.bool,
    containerStyle: PropTypes.string,
    buttonStyle: PropTypes.string,
    onSave: PropTypes.func,
    customStatuses: PropTypes.array.isRequired,
    age: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showStatuses: false,
    };

    this.saveStatuses = this.saveStatuses.bind(this);
    this.hideStatuses = this.hideStatuses.bind(this);
    this.openStatuses = this.openStatuses.bind(this);
    this.CustVBtnRef = React.createRef();
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      ReactModal.setAppElement('#mwi-root');
    }
  }

  saveStatuses(statuses) {
    this.props.onSave(statuses);
    this.hideStatuses();
  }

  hideStatuses() {
    const timeOutDuration = 100;
    this.setState({ showStatuses: false });
    //Fix iPad focus lost
    setTimeout(this.setCustVBtnFocus, timeOutDuration, this.CustVBtnRef);
  }

  openStatuses() {
    this.setState({ showStatuses: true });
  }

  setCustVBtnFocus(CustVBtnRef) {
    CustVBtnRef.current.firstChild.focus();
  }

  render = () => {
    const currentVitalityData = {
      customStatuses: this.props.customStatuses,
      age: this.props.age,
      duration: this.props.duration,
    };

    return (
      <div className={`${this.props.containerStyle}`} ref={this.CustVBtnRef}>
        <MWIButton
          label={this.props.intl.formatMessage({ id: 'common.customize' })}
          styleClass={`edit-status mwi-w90 ${this.props.buttonStyle}`}
          disabled={this.props.disabled}
          onClick={this.openStatuses}
        />

        <ReactModal
          className="button custom-vitality"
          contentLabel={this.props.intl.formatMessage({ id: 'vitality.status' })}
          isOpen={this.state.showStatuses}
          shouldCloseOnOverlayClick={false}
          onRequestClose={this.hideStatuses}
          role="dialog"
        >
          <CustomVitality
            save={this.saveStatuses}
            cancel={this.hideStatuses}
            locale={this.props.locale}
            {...currentVitalityData}
          />
        </ReactModal>
      </div>
    );
  };
}

export default injectIntl(VitalityButton);
