import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { eDefault, eDefaultRatings, eLocale, eProduct } from '../../core/configs';
import MWIButton from '../../core/components/mwi-button';
import NumberFormat from 'react-number-format';
import './ratings.css';

const defaultRatings = { ...eDefaultRatings };

export class Ratings extends PureComponent {
  static propTypes = {
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    product: PropTypes.string,
    permRatingAmount: PropTypes.number,
    permRatingPercent: PropTypes.number,
    tempRatingAmount: PropTypes.number,
    tempRatingYear: PropTypes.number,
    tempRatingAmount2: PropTypes.number,
    tempRatingYear2: PropTypes.number,
    twoTempRatings: PropTypes.bool,
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);
    // because ratings can be cancelled, it should work with local state
    // once save is called it will be saved into the Store
    // initialize them with values in the store so that it doesn't lose the previous values
    // while routers changed
    this.state = {
      permRatingAmount: this.props.permRatingAmount,
      permRatingPercent: this.props.permRatingPercent,
      tempRatingAmount: this.props.tempRatingAmount,
      tempRatingYear: this.props.tempRatingYear,
      tempRatingAmount2: this.props.tempRatingAmount2,
      tempRatingYear2: this.props.tempRatingYear2,
    };
    this.state.twoTempRatings = false;

    if (
      (this.props.product === eProduct['Performax'] || this.props.product === eProduct['SB']) &&
      this.props.tempRatingAmount2 > 0 &&
      this.props.permRatingAmount === 0
    ) {
      this.state.twoTempRatings = true;
    }

    this.savedRatings = { ...this.state };

    this.permRatingRef = React.createRef();

    this.reset = this.reset.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    this.state.twoTempRatings?.false && this.permRatingRef?.focus();
  }

  reset() {
    this.setState({ ...defaultRatings });
  }

  save() {
    this.savedRatings = { ...this.state };
    this.props.save(this.state);
  }

  cancel() {
    if (!this.props.inforce) {
      this.setState({ ...this.savedRatings });
    }
    this.props.cancel();
  }

  onChange(type, value) {
    this.setState({ [type]: +value });
  }

  onBlur(type, value) {
    const maximumPercentage = 100;
    if (
      value === '' ||
      value < eDefault[type] ||
      (type === 'permRatingPercent' && +value.replace(/[%\s]/g, '') < maximumPercentage)
    ) {
      // due to a NumberFormat bug, ratingAmount needs to be updated with non-default and then with default
      this.setState({ [type]: eDefault[type] });
    }
  }

  //Select the number format value once get focused
  handleFocus = (event) => event.target.select();

  renderInforceRatingsButtons() {
    const { intl, inforce } = this.props;
    if (!inforce) {
      return (
        <div className="p-col-12 rating-footer flexdisplay">
          <MWIButton
            name="reset"
            label={intl.formatMessage({ id: 'common.reset' })}
            onClick={this.reset}
            styleClass="mwi-w80 reset-cancel-button reset-btn oneflex p-col-3"
          />
          <MWIButton
            name="cancel"
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={this.cancel}
            styleClass="mwi-w80 reset-cancel-button cancel-btn oneflex p-col-3"
          />
          <MWIButton
            name="save"
            label={intl.formatMessage({ id: 'common.save' })}
            onClick={this.save}
            styleClass="mwi-w80 save-button oneflex p-col-3"
          />
        </div>
      );
    } else {
      return (
        <div className="p-col-12 rating-footer centerflexdisplay">
          <MWIButton
            name="close"
            label={intl.formatMessage({ id: 'common.close' })}
            onClick={this.cancel}
            styleClass="mwi-w25 reset-cancel-button cancel-btn p-col-3"
          />
        </div>
      );
    }
  }

  renderPermanentLabel(inforce, product) {
    const maximumAllowedPermPercentage = 750;
    if ([eProduct.Performax, eProduct.SB].includes(product)) {
      return (
        <>
          <div className="p-col-1 mwi-label flexone betweendiv">
            <FormattedMessage id="common.for" />
          </div>
          <div className="p-col-3 mwi-label flexone betweendiv">
            <FormattedMessage id="common.life" />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="p-col-1 mwi-label flexone betweendiv">
            <FormattedMessage id="common.and" />
          </div>
          <div className="p-col-5 flexfive">
            <NumberFormat
              name="perm-percent"
              className={`mwi-widget mwi-input number-data mwi-w80 `}
              value={this.state.permRatingPercent}
              suffix=" %"
              allowNegative={false}
              disabled={inforce}
              isAllowed={(values) => +values.value >= 0 && +values.value <= maximumAllowedPermPercentage}
              onValueChange={(values) => this.onChange('permRatingPercent', values.value)}
              onBlur={(e) => this.onBlur('permRatingPercent', e.target.value)}
              aria-labelledby="permRating"
              autoComplete="off"
              onFocus={this.handleFocus}
            />
          </div>
        </>
      );
    }
  }

  renderPermanentRatings(inforce, product) {
    const maximumAllowedPermAmount = 999;
    return (
      <div className="p-col-12 rating-row">
        <div className="flexfive" style={{ width: '42.6667%' }}>
          <div className="mwi-label" id="permRating">
            <FormattedMessage id="rating.perm.rate" />
          </div>
          <NumberFormat
            name="perm-amount"
            className={`mwi-widget mwi-input number-data mwi-w80 flexfive`}
            value={this.state.permRatingAmount}
            allowEmptyFormatting={false}
            decimalScale={2}
            fixedDecimalScale
            prefix={this.props.locale === eLocale.en ? '$ ' : ''}
            suffix={this.props.locale === eLocale.fr ? ' $' : ''}
            thousandSeparator={this.props.locale === eLocale.en ? ',' : ' '}
            decimalSeparator={this.props.locale === eLocale.en ? '.' : ','}
            allowNegative={false}
            disabled={inforce}
            isAllowed={(values) => +values.value >= 0 && +values.value <= maximumAllowedPermAmount}
            onValueChange={(values) => this.onChange('permRatingAmount', values.value)}
            onBlur={(e) => this.onBlur('permRatingAmount', e.target.value)}
            aria-labelledby="permRating"
            getInputRef={(input) => (this.permRatingRef = input)}
            autoComplete="off"
            onFocus={this.handleFocus}
          />
        </div>
        {this.renderPermanentLabel(inforce, product)}
      </div>
    );
  }

  renderSecondTemporaryRating(inforce, product) {
    if ([eProduct.Performax, eProduct.SB].includes(product)) {
      const maximumAllowedPermAmount = 999;
      const maximumAllowedTempRating = 10;
      return (
        <div className="p-col-12 rating-row">
          <div className="p-col-5 flexfive">
            <div className="mwi-label" id="tempRating">
              <FormattedMessage id="rating.temp.rate" />
            </div>
            <NumberFormat
              name="temp-amount"
              className={`mwi-widget mwi-input number-data mwi-w80`}
              value={this.state.tempRatingAmount2}
              decimalScale={2}
              fixedDecimalScale
              prefix={this.props.locale === eLocale.en ? '$ ' : ''}
              suffix={this.props.locale === eLocale.fr ? ' $' : ''}
              thousandSeparator={this.props.locale === eLocale.en ? ',' : ' '}
              decimalSeparator={this.props.locale === eLocale.en ? '.' : ','}
              allowNegative={false}
              disabled={inforce}
              isAllowed={(values) => +values.value >= 0 && +values.value <= maximumAllowedPermAmount}
              onValueChange={(values) => this.onChange('tempRatingAmount2', values.value)}
              onBlur={(e) => this.onBlur('tempRatingAmount2', e.target.value)}
              aria-labelledby="tempRating2"
              autoComplete="off"
              onFocus={this.handleFocus}
            />
          </div>
          <div className="p-col-1 mwi-label flexone betweendiv">
            <FormattedMessage id="common.for" />
          </div>
          <div className="p-col-5 flexfive">
            <NumberFormat
              name="temp-year"
              className={`mwi-widget mwi-input number-data mwi-w40`}
              value={this.state.tempRatingYear2}
              allowNegative={false}
              disabled={inforce}
              isAllowed={(values) => +values.value >= 0 && +values.value <= maximumAllowedTempRating}
              onValueChange={(values) => this.onChange('tempRatingYear2', values.value)}
              onBlur={(e) => this.onBlur('tempRatingYear2', e.target.value)}
              aria-labelledby="tempRating2 tempYears2"
              autoComplete="off"
              onFocus={this.handleFocus}
            />
            <span id="tempYears2" className=" label unit">
              {' '}
              <FormattedMessage id="common.years" />
            </span>
          </div>
        </div>
      );
    }
    return <></>;
  }

  render() {
    const { inforce, product } = this.props;
    const maximumAllowedPermAmount = 999;
    const maximumAllowedTempRating = 10;
    return (
      <div className="p-grid p-grid-no-margin-side ratings-container">
        <h2 className="p-col-12 title">
          <FormattedMessage id="rating.title" />
        </h2>
        {!this.state.twoTempRatings && this.renderPermanentRatings(inforce, product)}

        <div className="p-col-12 rating-row">
          <div className="p-col-5 flexfive">
            <div className="mwi-label" id="tempRating">
              <FormattedMessage id="rating.temp.rate" />
            </div>
            <NumberFormat
              name="temp-amount"
              className={`mwi-widget mwi-input number-data mwi-w80`}
              value={this.state.tempRatingAmount}
              decimalScale={2}
              fixedDecimalScale
              prefix={this.props.locale === eLocale.en ? '$ ' : ''}
              suffix={this.props.locale === eLocale.fr ? ' $' : ''}
              thousandSeparator={this.props.locale === eLocale.en ? ',' : ' '}
              decimalSeparator={this.props.locale === eLocale.en ? '.' : ','}
              allowNegative={false}
              disabled={inforce}
              isAllowed={(values) => +values.value >= 0 && +values.value <= maximumAllowedPermAmount}
              onValueChange={(values) => this.onChange('tempRatingAmount', values.value)}
              onBlur={(e) => this.onBlur('tempRatingAmount', e.target.value)}
              aria-labelledby="tempRating"
              autoComplete="off"
              onFocus={this.handleFocus}
            />
          </div>
          <div className="p-col-1 mwi-label flexone betweendiv">
            <FormattedMessage id="common.for" />
          </div>
          <div className="p-col-5 flexfive">
            <NumberFormat
              name="temp-year"
              className={`mwi-widget mwi-input number-data mwi-w40`}
              value={this.state.tempRatingYear}
              allowNegative={false}
              disabled={inforce}
              isAllowed={(values) => +values.value >= 0 && +values.value <= maximumAllowedTempRating}
              onValueChange={(values) => this.onChange('tempRatingYear', values.value)}
              onBlur={(e) => this.onBlur('tempRatingYear', e.target.value)}
              aria-labelledby="tempRating tempYears"
              autoComplete="off"
              onFocus={this.handleFocus}
            />
            <span id="tempYears" className=" label unit">
              {' '}
              <FormattedMessage id="common.years" />
            </span>
          </div>
        </div>

        {this.state.twoTempRatings && this.renderSecondTemporaryRating(inforce, product)}

        {this.renderInforceRatingsButtons()}
      </div>
    );
  }
}

export default injectIntl(Ratings);
