import * as actionTypes from '../../core/actionTypes';
import {
  eDefault,
  eAlternateInvestment,
  eField,
  eDurationType,
  eProduct,
  eCSVLoanPercentage,
  eLoanAmount,
  eLoanAmountSelection,
  eInvestmentAllocation,
} from '../../core/configs';
import { calculateBankLoanRateDefault } from '../../core/components/loan-information/bank-loan-default';
import {
  assignDef,
  calculateMWINumberFieldValue,
  deathAtAgeMax,
  deathAtAgeMin,
  additionalYearsMax,
  additionalYearsMin,
  isRated,
  getMinClientAge,
} from '../../core/utils';
import _ from 'lodash';
import { scenarioSeq } from '../../core/sequenceUtils';

const defaultConcept = {
  isOpen: false,
  taxInformation: { ...eDefault.taxInformation, isFolded: false },
  personalDividendTaxRate: eDefault.personalDividendTaxRate,
  alternateInvestment: {
    investmentAllocation: { ...eDefault.investmentAllocation },
    isAllocationError: false,
    isIllustrateChecked: false,
    isFolded: false,
  },
  lifeExpectancyAssumption: { ...eDefault.lifeExpectancyAssumption, isFolded: false },
  corporateIRPStructure: {
    ...eDefault.corporateIRPStructure,
    isFolded: false,
  },
  dividendScale: {
    ...eDefault.dividendScale,
    isFolded: false,
  },
  taxInformationRates: { ...eDefault.taxInformationRates, isFolded: false },
  [eField.loanInformation]: {
    [eField.assumeInterestDeductibility]: false,
    [eField.collateralInsuranceDeduction]: true,
    [eField.loanAmountSelection]: eLoanAmountSelection.solveMaximum,
    [eField.loanAmount]: eLoanAmount.def,
    [eField.duration]: eDurationType.age,
    isFolded: false,
  },
  errors: {},
};

const initialState = {
  activeTabId: scenarioSeq(true),
  [scenarioSeq(true)]: {
    scenarioId: scenarioSeq(true),
    ...defaultConcept,
  },
};

const setParUdmResponse = (concept, state, scenarioTabId, policy, udmParty) => {
  // dummy number, switch to the UDM RESPONSE for concepts when value is ready
  const lifeExpectancy = concept.lifeExpectancy.age;
  // set deathAtAge to lifeExpectancy if lifeexpectancy changes from service
  const deathAtAge =
    lifeExpectancy && lifeExpectancy !== state[scenarioTabId].lifeExpectancyAssumption.lifeExpectancy
      ? lifeExpectancy
      : state[scenarioTabId].lifeExpectancyAssumption.deathAtAge;
  const additionalYears = state[scenarioTabId].lifeExpectancyAssumption.additionalYears;
  const csvLoanPercentage = state[scenarioTabId].loanInformation.csvLoanPercentage;

  const coverage = policy?.coverage[0];
  const equivalentAge = coverage?.equivalentAge;
  const coverageType = coverage?.coverageType;
  const minClientAge = getMinClientAge(udmParty);

  const ratings = policy?.coverage[0]?.lifeRating[0];
  // if ratings is undefined set rated value to false
  const rated = ratings ? isRated(ratings.permanentAmount, ratings.permanentPercent, ratings.temporaryAmount) : false;

  const calculatedDeathAtAge = calculateMWINumberFieldValue(
    deathAtAge,
    deathAtAgeMin(rated, lifeExpectancy),
    deathAtAgeMax(coverageType, equivalentAge, minClientAge)
  );
  const maxDAA = deathAtAgeMax(coverageType, equivalentAge, minClientAge);
  const calculatedAdditionalYears = calculateMWINumberFieldValue(
    additionalYears,
    additionalYearsMin(calculatedDeathAtAge, maxDAA),
    additionalYearsMax(calculatedDeathAtAge, maxDAA)
  );
  return { lifeExpectancy, csvLoanPercentage, calculatedDeathAtAge, calculatedAdditionalYears };
};

const conceptReducer = (state, action) => {
  state = state === undefined ? initialState : state;
  switch (action.type) {
    case actionTypes.SET_BALANCED: {
      const { scenarioTabId } = action.payload;
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          alternateInvestment: {
            ...state[scenarioTabId].alternateInvestment,
            investmentAllocation: {
              [eInvestmentAllocation.interest]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.interest,
                percentage: eAlternateInvestment.setBalanced[eInvestmentAllocation.interest],
              },
              [eInvestmentAllocation.dividends]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.dividends,
                percentage: eAlternateInvestment.setBalanced[eInvestmentAllocation.dividends],
              },
              [eInvestmentAllocation.deferredCapitalGains]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.deferredCapitalGains,
                percentage: eAlternateInvestment.setBalanced[eInvestmentAllocation.deferredCapitalGains],
              },
              [eInvestmentAllocation.realizedCapitalGains]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.realizedCapitalGains,
                percentage: eAlternateInvestment.setBalanced[eInvestmentAllocation.realizedCapitalGains],
              },
            },
            //allocation: eAlternateInvestment.setBalanced,
            isAllocationError: false,
          },
        },
      };
    }
    case actionTypes.SET_ALLOCATION_VALUES: {
      const { interest, fieldName, fieldValue, type, isAllocationError, scenarioTabId } = action.payload;
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          alternateInvestment: {
            ...state[scenarioTabId].alternateInvestment,
            // allocation: {
            //   ...state[scenarioTabId].alternateInvestment.allocation,
            //   interest: interest,
            //   [fieldName]: fieldValue
            // },
            investmentAllocation: {
              ...state[scenarioTabId].alternateInvestment.investmentAllocation,
              [eInvestmentAllocation.interest]:
                typeof interest !== 'undefined'
                  ? {
                      ...state[scenarioTabId].alternateInvestment.investmentAllocation.interest,
                      [type]: interest,
                    }
                  : {
                      ...state[scenarioTabId].alternateInvestment.investmentAllocation.interest,
                    },
              [fieldName]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation[fieldName],
                [type]: fieldValue,
              },
            },
            isAllocationError:
              typeof isAllocationError !== 'undefined'
                ? isAllocationError
                : state[scenarioTabId].alternateInvestment.isAllocationError,
          },
        },
      };
    }
    case actionTypes.SET_LIFE_EXPECTANCY_VALUE: {
      const { deathAtAge, additionalYears, scenarioTabId } = action.payload;
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          lifeExpectancyAssumption: {
            deathAtAge,
            additionalYears,
          },
        },
      };
    }
    case actionTypes.SET_DURATION_DEFAULT_VALUES: {
      const { scenarioTabId } = action.payload;
      const payload = action.payload;
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          [eField.loanInformation]: {
            ...state[scenarioTabId][eField.loanInformation],
            [eField.duration]: payload[eField.duration],
            [eField.durationFrom]: payload[eField.durationFrom],
            [eField.durationTo]: payload[eField.durationTo],
          },
        },
      };
    }
    default:
      return conceptReducer2(state, action);
  }
};

const conceptReducer2 = (state, action) => {
  switch (action.type) {
    case actionTypes.LOADED_UDM_RESPONSE: {
      // on the udm load, set the deathAtAge to the life Expectancy Value
      // for CDTPARC-90 we would use the dummy number 85, when the service is ready
      // switch to loading the data from the return value
      // We also get the default value for csvLoanPercentage to depend on the udmResponse
      const { scenarioTabId, response } = action.payload;
      // if is Rated, calculate the deathAtAge
      const udmParty = response?.illustration?.party;
      const policy = response?.illustration?.policy;
      const concept = response?.illustration?.concept;
      if (policy && policy.product === eProduct.PAR && concept) {
        const { lifeExpectancy, csvLoanPercentage, calculatedDeathAtAge, calculatedAdditionalYears } =
          setParUdmResponse(concept, state, scenarioTabId, policy, udmParty);
        return {
          ...state,
          [scenarioTabId]: {
            ...state[scenarioTabId],
            lifeExpectancyAssumption: {
              ...state[scenarioTabId].lifeExpectancyAssumption,
              lifeExpectancy,
              // if deathAtAge is undefined set to default value for now!
              // if value exists use calculated value
              deathAtAge: calculatedDeathAtAge,
              additionalYears: calculatedAdditionalYears,
            },
            loanInformation: {
              ...state[scenarioTabId].loanInformation,
              // set bank loan rate to return value from calculations
              bankLoanRate: concept?.loanInformation?.bankLoanRate,
              // if theres no csvLoanPercentage ie undefined, get the calculated default
              csvLoanPercentage:
                !csvLoanPercentage && csvLoanPercentage !== 0 ? eCSVLoanPercentage.max : csvLoanPercentage,
            },
          },
        };
      }
      return state;
    }
    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return {
        ...state,
        activeTabId: action.payload,
      };
    }
    case actionTypes.ADD_SCENARIO_TABNAVS: {
      return {
        ...state,
        activeTabId: action.payload.newTabId,
        [action.payload.newTabId]: { scenarioId: action.payload.newTabId, ...state[action.payload.currentTabId] },
      };
    }
    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      const newState = _.omit(state, [action.payload.id]);

      return {
        ...newState,
        activeTabId: action.payload.newActiveTabId,
      };
    }
    default:
      return conceptReducer3(state, action);
  }
};

const conceptReducer3 = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_GROWTH: {
      const { scenarioTabId } = action.payload;
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          alternateInvestment: {
            ...state[scenarioTabId].alternateInvestment,
            investmentAllocation: {
              [eInvestmentAllocation.interest]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.interest,
                percentage: eAlternateInvestment.setGrowth[eInvestmentAllocation.interest],
              },
              [eInvestmentAllocation.dividends]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.dividends,
                percentage: eAlternateInvestment.setGrowth[eInvestmentAllocation.dividends],
              },
              [eInvestmentAllocation.deferredCapitalGains]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.deferredCapitalGains,
                percentage: eAlternateInvestment.setGrowth[eInvestmentAllocation.deferredCapitalGains],
              },
              [eInvestmentAllocation.realizedCapitalGains]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.realizedCapitalGains,
                percentage: eAlternateInvestment.setGrowth[eInvestmentAllocation.realizedCapitalGains],
              },
            },
            //allocation: eAlternateInvestment.setGrowth,
            isAllocationError: false,
          },
        },
      };
    }

    case actionTypes.SET_INCOME: {
      const { scenarioTabId } = action.payload;
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          alternateInvestment: {
            ...state[scenarioTabId].alternateInvestment,
            investmentAllocation: {
              [eInvestmentAllocation.interest]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.interest,
                percentage: eAlternateInvestment.setIncome[eInvestmentAllocation.interest],
              },
              [eInvestmentAllocation.dividends]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.dividends,
                percentage: eAlternateInvestment.setIncome[eInvestmentAllocation.dividends],
              },
              [eInvestmentAllocation.deferredCapitalGains]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.deferredCapitalGains,
                percentage: eAlternateInvestment.setIncome[eInvestmentAllocation.deferredCapitalGains],
              },
              [eInvestmentAllocation.realizedCapitalGains]: {
                ...state[scenarioTabId].alternateInvestment.investmentAllocation.realizedCapitalGains,
                percentage: eAlternateInvestment.setIncome[eInvestmentAllocation.realizedCapitalGains],
              },
            },
            //allocation: eAlternateInvestment.setIncome,
            isAllocationError: false,
          },
        },
      };
    }
    case actionTypes.MWI_ON_CHANGE: {
      const { target, field, value, scenarioTabId } = action.payload;

      if (target === 'concepts') {
        return {
          ...state,
          [field]: value,
        };
      } else if (target === 'scenario') {
        // for the loanInformation boject we depend on changes from target scenario
        if (field === eField.divScale.primary) {
          const bankLoanRate = state[scenarioTabId].loanInformation.bankLoanRate;
          return {
            ...state,
            [scenarioTabId]: {
              ...state[scenarioTabId],
              loanInformation: {
                ...state[scenarioTabId].loanInformation,
                // calculate the default value for the bankloanrate based on the value of the Primary dividend scale
                bankLoanRate:
                  field === eField.divScale.primary ? calculateBankLoanRateDefault(bankLoanRate, value) : bankLoanRate,
              },
            },
          };
        }
      } else {
        /* skip */
      }

      return state;
    }
    default:
      return conceptReducer4(state, action);
  }
};
const conceptReducer4 = (state, action) => {
  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      return initialState;
    }
    case actionTypes.OPEN_SAVEDCASE: {
      const { scenarioTabNavs, conceptTabNavs } = action.payload.model;
      //This logic to assign all the missing propertied from the
      //initial/default to the saved old object with default value.
      const targetState = conceptTabNavs;
      assignDef(targetState, initialState);
      scenarioTabNavs.tabNavs.forEach((Scenario) => {
        assignDef(targetState[Scenario.id], defaultConcept);
        assignDef(targetState[Scenario.id].taxInformation, eDefault.taxInformation);
        assignDef(targetState[Scenario.id].alternateInvestment, defaultConcept.alternateInvestment);
        assignDef(targetState[Scenario.id].lifeExpectancyAssumption, defaultConcept.lifeExpectancyAssumption);
        assignDef(targetState[Scenario.id].taxInformationRates, defaultConcept.taxInformationRates);
        assignDef(targetState[Scenario.id].corporateIRPStructure, defaultConcept.corporateIRPStructure);
      });
      return {
        ...targetState,
      };
    }
    case actionTypes.SET_CONCEPT_FIELD_VALUE: {
      const { fieldName, fieldValue, scenarioTabId, fieldParent, fieldParent2 } = action.payload;
      if (!fieldParent) {
        // if setting just a field: value pair e.g personalDividendTaxrate
        return {
          ...state,
          [scenarioTabId]: {
            ...state[scenarioTabId],
            [fieldName]: fieldValue,
          },
        };
      }
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          [fieldParent]: (fieldParent2 && {
            ...state[scenarioTabId][fieldParent],
            [fieldParent2]: {
              ...state[scenarioTabId][fieldParent][fieldParent2],
              [fieldName]: fieldValue,
            },
          }) || {
            ...state[scenarioTabId][fieldParent],
            [fieldName]: fieldValue,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default conceptReducer;
