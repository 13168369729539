import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { dispatchMWIUpdateError, dispatchMWIOnChange } from '../../../core/actions';
import { toggleOutOfDate } from '../../../custom-mode/scenario-tab-navs/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { eField, eLocale, eSideAccountInterestRate } from '../../../core/configs';
import MWINumberField from '../../components/mwi-calculated-number-field';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';

export class SideAccountInterestRate extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    dispatchMWIUpdateErrorAppAction: PropTypes.func,
    dispatchMWIOnChangeAppAction: PropTypes.func,
    toggleOutOfDateSceAction: PropTypes.func,
    sideAccountInterestRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    scenarioTabNavs: PropTypes.object,
  };

  dispatchAction(field, value, target = 'scenario', isError = false) {
    const { dispatchMWIUpdateErrorAppAction, dispatchMWIOnChangeAppAction, toggleOutOfDateSceAction } = this.props;

    if (isError) {
      dispatchMWIUpdateErrorAppAction({ target, field, value });
    } else {
      dispatchMWIOnChangeAppAction({ target, field, value });
    }
    this.props.sceActions.performManualRefresh();
    toggleOutOfDateSceAction(true);
  }

  render() {
    const { locale, scenarioTabNavs } = this.props;
    const sideAccountInterestRate = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId].sideAccountInterestRate;
    return (
      <div className="p-col-12 side-account-interest-rate mwi-padding-right12 mwi-margin-bottom24">
        <MWINumberField
          id={eField.sideAccountInterestRate}
          name={eField.sideAccountInterestRate}
          label={<FormattedMessage id="coverage.sideAccountInterestRate" />}
          minValue={eSideAccountInterestRate.min}
          local={locale}
          value={+sideAccountInterestRate}
          maxValue={eSideAccountInterestRate.max}
          decimalScale={2}
          fixedDecimalScale
          onBlur={(id, val) => this.dispatchAction(id, val)}
          decimalSeparator={locale === eLocale.en ? '.' : ','}
          suffix=" %"
          allowNegative={false}
          autoComplete="off"
        />
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs }) => {
  return {
    locale: app.locale,
    scenarioTabNavs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchMWIUpdateErrorAppAction: bindActionCreators(dispatchMWIUpdateError, dispatch),
  dispatchMWIOnChangeAppAction: bindActionCreators(dispatchMWIOnChange, dispatch),
  toggleOutOfDateSceAction: bindActionCreators(toggleOutOfDate, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SideAccountInterestRate));
