import { eDividendScale } from '../../configs';

export const calculateBankLoanRateDefault = (loanRate, conceptDividendScale) => {
  const min = 5.0;
  let val;
  const rateHalf = 0.5;
  const rateOne = 1;
  const rateOneHalf = 1.5;
  const rateTwo = 2;
  switch (conceptDividendScale) {
    case eDividendScale.current:
      val = loanRate;
      break;
    case eDividendScale.lessHalf:
      val = loanRate - rateHalf;
      break;
    case eDividendScale.lessOne:
      val = loanRate - rateOne;
      break;
    case eDividendScale.lessOneHalf:
      val = loanRate - rateOneHalf;
      break;
    case eDividendScale.lessTwo:
      val = loanRate - rateTwo;
      break;
    default:
      val = loanRate;
  }
  return min > val ? min : val;
};
