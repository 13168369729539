import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './custom-mode.css';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';

import ScenarioTabNavs from './scenario-tab-navs';
import { isSpreadsheetView } from '../core/configs';
import ScenarioPanel from './scenario-panel';
import SpreadsheetFullScreen from '../core/components/spreadsheet-fullmode';

export class CustomMode extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    intl: PropTypes.object,
  };

  render() {
    const { intl } = this.props;

    return (
      <DocumentTitle title={intl.formatMessage({ id: 'common.illustrations' })}>
        <div className="mwi-main mwi-content-margin" role="container-custommode">
          {isSpreadsheetView(this.props.history.location.pathname) && <ScenarioTabNavs />}
          {isSpreadsheetView(this.props.history.location.pathname) && <SpreadsheetFullScreen />}
          <ScenarioPanel match={this.props.match} history={this.props.history} />
        </div>
      </DocumentTitle>
    );
  }
}

export default withRouter(injectIntl(connect()(CustomMode)));
