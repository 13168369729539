import {rateChangeProducts} from "../core/configs";

const dispatchNotification = (type, props) => {
    type = type.concat('AppAction');
    if (props) {
      props[type]({});
    }
  };

    const formatDate = (date) => {
    const yearIndex = 2,
      monthIndex = 1,
      dayIndex = 0;
    const splitDate = date.split('/');
    return new Date(splitDate[yearIndex], splitDate[monthIndex] - 1, splitDate[dayIndex]).setHours(0, 0, 0, 0);
  };

export const checkToShowRatesNotification = (loadedCase, props) => {
    // Determine if rate change content should be displayed or not
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const lastOpenedCaseAt = new Date(loadedCase.updatedAt).setHours(0, 0, 0, 0);
    let boolShowRatesNotification = false;

    loadedCase.model.scenarioTabNavs.tabNavs.forEach((s) => {
        const scenario = loadedCase.model.scenarioTabNavs.topBars[s.id];
        if (!boolShowRatesNotification && rateChangeProducts[scenario.product]) {
            const lastRateChange = formatDate(rateChangeProducts[scenario.product]);
            const effectiveDate = formatDate(scenario.effectiveDate);
            if (
                lastRateChange <= currentDate &&
                (lastOpenedCaseAt < lastRateChange ||
                    (props.userType === 'HeadOffice' && effectiveDate < lastRateChange))
            ) {
                boolShowRatesNotification = true;
            }
        }
    });

    if (boolShowRatesNotification) {
        dispatchNotification('showRatesNotification', props);
    }
};