import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Growl } from 'primereact/growl';
import { eField } from '../../configs';
import MWIButton from '../mwi-button';
import MWIInput from '../mwi-input';
import * as AppActions from '../../actions';

export class ScenarioNameModal extends PureComponent {
  static propTypes = {
    configs: PropTypes.object,
    locale: PropTypes.string,
    product: PropTypes.string,
    effectiveDate: PropTypes.string,
    scenarioTabId: PropTypes.string,
    topBarErrors: PropTypes.object,
    intl: PropTypes.object,
    appActions: PropTypes.object,
    userType: PropTypes.string,
    closeScenarioNameModal: PropTypes.func,
    title: PropTypes.string,
    tabIndex: PropTypes.number,
    currentScenario: PropTypes.string,
    scenarioName: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      scenarioName: this.props.scenarioName,
    };

    this.onChangeScenarioName = this.onChangeScenarioName.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  onChangeScenarioName(name, value) {
    this.setState({ scenarioName: value });
  }

  save(type, value) {
    this.dispatchAction(type, value);
    this.props.closeScenarioNameModal();
  }

  cancel() {
    this.props.closeScenarioNameModal();
  }

  dispatchAction(field, value, target = 'scenario') {
    this.props.appActions.dispatchMWIOnChange({
      scenarioTabId: this.props.scenarioTabId,
      field,
      target,
      value,
    });
  }

  render() {
    const { intl, title, currentScenario, tabIndex } = this.props;

    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} />
        <h2 className="p-col-12 title-model" role="title-model-test">
          {title}
        </h2>
        <div className="p-col-12 row-model space-right7">
          <MWIInput
            containerClass="p-col-12 dropdown-container"
            name={eField.scenarioName}
            labelId={currentScenario}
            label=""
            placeholder={`${intl.formatMessage({ id: 'common.scenario' })} ${tabIndex}`}
            onChange={this.onChangeScenarioName}
            value={this.state.scenarioName}
            labelStyle="remove-label-margin"
            topBarFieldStyle="dropdown-text"
            styleClass="no-margin"
            maxLength={45}
            inputFieldStyle="space-right0"
          />
        </div>
        <div className="p-col-12 mwi-w89 modal-footer flexdisplayCenter">
          <MWIButton
            name="cancel"
            title="cancel"
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={this.cancel}
            styleClass="mwi-w50 cancel-modal cancel-btn oneflex p-col-4"
          />
          <MWIButton
            name="save"
            title="save"
            label={intl.formatMessage({ id: 'common.save' })}
            onClick={() => this.save(eField.scenarioName, this.state.scenarioName)}
            styleClass="mwi-w50 save-modal save-button oneflex p-col-4"
          />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs }) => {
  const topbar = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const currentScenario = scenarioTabNavs.activeTabId;
  const tabIndex = topbar[eField.scenarioName]
    ? 0
    : scenarioTabNavs.tabNavs.findIndex(function (o) {
        return o.id === currentScenario;
      });

  return {
    locale: app.locale,
    scenarioTabId: scenarioTabNavs.activeTabId,
    product: topbar.product,
    effectiveDate: topbar.effectiveDate,
    topBarErrors: topbar.errors,
    userType: app.userType,
    scenarioName: topbar[eField.scenarioName],
    tabIndex: tabIndex + 1,
    currentScenario,
  };
};

const mapDispatchToProps = (dispatch) => ({ appActions: bindActionCreators(AppActions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ScenarioNameModal));
