import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import _ from 'lodash';
import { clientIndexHelper, trackPrinting } from '../core/utils';
import { eTermRenewalClient, eField, configs } from '../core/configs';
import { Dialog } from 'primereact/components/dialog/Dialog';
import UDMMapper from '../core/udm-mapper';
import * as AppActions from '../core/actions';
import * as ScenarioActions from '../custom-mode/scenario-tab-navs/actions';
import * as ClientActions from '../services/reducers/components/client/actions';
import * as CoverageActions from '../custom-mode/coverage-tab-navs/actions';
import Calculator from './calculator';
import Summary from '../custom-mode/summary';

import './term-renewal-calc.css';

export class TermRenewalCalc extends PureComponent {
  static propTypes = {
    activeScenarioTabId: PropTypes.string,
    activeCoverageTabId: PropTypes.string,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    clients: PropTypes.object,
    clientIndex: PropTypes.number,
    coverage: PropTypes.object,
    clientActions: PropTypes.object,
    client1: PropTypes.object,
    match: PropTypes.object,
    udmEnabled: PropTypes.bool,
    selectedProduct: PropTypes.string,
    product: PropTypes.string,
    history: PropTypes.object,
    intl: PropTypes.object,
    isSpreadsheetView: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.hideUDM = this.hideUDM.bind(this);
  }

  componentDidMount() {
    const { client1, clientActions, activeScenarioTabId, coverage, clientIndex } = this.props;
    this.dispatchAction('activeTopBarIndex', 1, 'scenario');
    // if client.1 does not exist make a request to create
    if (!client1) {
      clientActions.addClient({
        scenarioTabId: activeScenarioTabId,
        coverageTabId: coverage.id,
        clientId: clientIndexHelper(clientIndex),
      });

      // Set client age default for FTRC
      this.dispatchAction(eField.age, '', 'client');
    }

    // Default effective date for FTRC
    this.dispatchAction(eField.effectiveDate, '', 'scenario');
  }

  dispatchAction(field, value, target = 'coverage', isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
      scenarioTabId: this.props.activeScenarioTabId,
      coverageTabId: this.props.activeCoverageTabId,
      product: this.props.product,
      coverageOption: this.props.coverage.coverageOption,
      clientId: eTermRenewalClient.defaultId,
      premiumDuration: this.props.coverage.premiumDuration,
      isCoverageSolve: this.props.coverage.isCoverageSolve,
      clients: this.props.clients,
    });
  }

  hideUDM() {
    this.props.sceActions.toggleUDM(false);
  }

  print() {
    window.print();

    trackPrinting();
  }

  render() {
    const { intl } = this.props;

    return (
      <DocumentTitle title={intl.formatMessage({ id: 'common.calculator' })}>
        <div className="mwi-main mwi-content-margin text-size14">
          <div className="flex-baseline">
            <div className="ftrc-header">
              <FormattedMessage id="product.ftrc" />
            </div>
            <div className="right-align">
              <a onClick={() => this.props.history.push(configs.routes.home)} className="printer-link">
                <FormattedMessage id="common.selectProduct" />
              </a>
            </div>
            <div className="link-space">
              <a onClick={this.print} className="printer-link">
                <FormattedMessage id="topbar.print" />
              </a>
            </div>
          </div>

          <div>
            <div className="p-grid p-grid-no-margin form-wrapper">
              <div className="p-col-8 coverage-wrapper">
                <Calculator />
              </div>
              <div className="p-col-3 p-lg-5 p-xl-4 summary-wrapper" style={{ marginTop: '22px' }}>
                <Summary />
              </div>
              <Dialog
                header="UDM Viewer"
                visible={this.props.udmEnabled}
                modal={false}
                onHide={this.hideUDM}
                className="udm-viewer"
                autoAlign
                positionTop={134}
                positionLeft={650}
              >
                <UDMMapper />
              </Dialog>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = ({ landingPage, scenarioTabNavs, app, udmResponse, coverageTabNavs, clients }) => {
  const selectedProduct = landingPage.selectedProduct;
  const tabNavs = scenarioTabNavs.udmEnabled;
  const udmEnabled = tabNavs;
  const summaryError = scenarioTabNavs.summaryError;

  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const clientIdsInCov = _.keys(coverage.ratings);
  const illustration = udmResponse.illustration;
  const udmPolicy = (illustration && illustration.policy) || {};
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const product = activeScenario.product;
  const scenarioTabId = scenarioTabNavs.activeTabId;

  const isMultiCov = coverageState.tabNavs.length > 1;

  return {
    locale: app.locale,
    activeScenarioTabId: scenarioTabId,
    activeCoverageTabId: coverageState.activeTabId,
    clients: clients[scenarioTabId],
    clientIndex: clients.clientIndex,
    client1: clients[scenarioTabId][clientIdsInCov[0]],
    depositOptionType: activeScenario.depositOptionType,
    includeMaxLevelDepositOption: activeScenario.includeMaxLevelDepositOption,
    udmCoverages: udmPolicy.coverage,
    duration: udmPolicy.duration,
    coverage,
    isMultiCov,
    product,
    udmPolicy,
    selectedProduct,
    udmEnabled,
    summaryError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  clientActions: bindActionCreators(ClientActions, dispatch),
  coverageActions: bindActionCreators(CoverageActions, dispatch),
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(TermRenewalCalc)));
