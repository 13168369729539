import * as actionTypes from '../core/actionTypes';
import { assignDef } from '../core/utils';
import { scenarioSeq } from '../core/sequenceUtils';
import * as reducerHelper from '../helper/reducer-helper';

const defaultState = {
  isLoading: false,
  isOpen: false,
  errors: {},
  fillDownClicked: false,
};

const initialState = {
  activeTabId: scenarioSeq(true),
  [scenarioSeq(true)]: defaultState,
};

const spreadsheetReducer = (state, action) => {
  state = stateValue(state);
  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      return initialState;
    }
    case actionTypes.OPEN_SAVEDCASE: {
      const { scenarioTabNavs, spreadsheet } = action.payload.model;
      //This logic to assign all the missing propertied from
      // the initial/default to the saved old object with default value.
      const targetState = spreadsheet;
      assignDef(targetState, initialState);
      scenarioTabNavs.tabNavs.forEach((Scenario) => {
        assignDef(targetState[Scenario.id], defaultState);
      });
      return {
        ...targetState,
      };
    }
    case actionTypes.ADD_SCENARIO_TABNAVS: {
      return reducerHelper.getAddScenarioTabNavsState(state, action);
    }
    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return reducerHelper.getClickScenarioTabNavsState(state, action);
    }
    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      return reducerHelper.getRemoveScenarioTabNavsState(state, action);
    }
    case actionTypes.LOADING_SPREADSHEET:
    case actionTypes.CLOSE_SPREADSHEET: {
      const { scenarioId } = action.payload;

      return {
        ...state,
        [scenarioId]: {
          ...state[scenarioId],
          isLoading: action.type === actionTypes.LOADING_SPREADSHEET,
          isOpen: action.type === actionTypes.LOADING_SPREADSHEET,
        },
      };
    }

    case actionTypes.LOADED_SPREADSHEET: {
      const { scenarioId } = action.payload;

      return {
        ...state,
        [scenarioId]: {
          ...state[scenarioId],
          isLoading: false,
        },
      };
    }
    //refactor & give title for action
    case actionTypes.MWI_ON_CHANGE: {
      return handleFillDownClicked(state, action);
    }
    default:
      return state;
  }
};
const handleFillDownClicked = (state, action) => {
  const { scenarioTabId, field, value } = action.payload;
  if (field === 'fillDownClicked') {
    return {
      ...state,
      [scenarioTabId]: {
        ...state[scenarioTabId],
        [field]: value,
      },
    };
  }
  return state;
};

export default spreadsheetReducer;

const stateValue = (state) => (state === undefined ? initialState : state);
