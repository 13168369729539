import React, { PureComponent } from 'react';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import '../landing-page.css';
import PropTypes from 'prop-types';
import { eProduct } from '../../core/configs';

export class PerformaxGoldInforceDescription extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    goToIRForm: PropTypes.func,
    isLoading: PropTypes.bool,
    product: PropTypes.string,
  };

  render() {
    const productBullets = Array.from({ length: 6 }, (v, i) => i).map((index) => (
      <li key={index}>
        {[eProduct.Performax, eProduct.SB].includes(this.props.product) && (
          <FormattedMessage id={`landing.performax.productBullet${index}`} />
        )}
        {[eProduct.PG].includes(this.props.product) && (
          <FormattedMessage id={`landing.performaxgold.productBullet${index}`} />
        )}
      </li>
    ));
    const specialRequestBullets = Array.from({ length: 5 }, (v, i) => i).map((index) => (
      <li key={index}>
        {[eProduct.Performax, eProduct.SB].includes(this.props.product) && (
          <FormattedMessage id={`landing.performax.specialRequestBullet${index}`} />
        )}
        {[eProduct.PG].includes(this.props.product) && (
          <FormattedMessage id={`landing.performaxgold.specialRequestBullet${index}`} />
        )}
      </li>
    ));
    const limitationsBullets = [eProduct.PG].includes(this.props.product)
      ? Array.from({ length: 4 }, (v, i) => i).map((index) => (
          <li key={index}>
            <FormattedMessage id={`landing.performaxgold.limitationsBullet${index}`} />
          </li>
        ))
      : Array.from({ length: 1 }, (v, i) => i).map((index) => (
          <li key={index}>
            <FormattedMessage id={`landing.performax.limitationsBullet${index}`} />
          </li>
        ));
    return (
      <React.Fragment>
        <div className="p-col-12 product-description margin-bottom10">
          {this.props.product === eProduct.Performax && (
            <FormattedHTMLMessage id="landing.performax.openingParagraph" />
          )}
          {this.props.product === eProduct.SB && <FormattedHTMLMessage id="landing.sb.openingParagraph" />}
          {[eProduct.PG].includes(this.props.product) && (
            <FormattedHTMLMessage id="landing.performaxgold.openingParagraph" />
          )}
          <ul className="details-list">{productBullets}</ul>
        </div>
        <div className="p-col-12 product-description margin-bottom10">
          <FormattedMessage
            id="landing.performaxgold.specialRequestParagraph"
            defaultMessage={`{part1}{link}{part2}`}
            values={{
              part1: <FormattedHTMLMessage id="landing.performaxgold.specialRequestParagraph1" />,
              link: (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.goToIRForm();
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="url-link"
                >
                  <FormattedMessage
                    id={
                      this.props.isLoading
                        ? this.props.intl.formatMessage({ id: 'common.loading' })
                        : this.props.intl.formatMessage({ id: 'landing.performaxgold.specialRequestForm' })
                    }
                  />
                </a>
              ),
              part2: <FormattedHTMLMessage id="landing.performaxgold.specialRequestParagraph2" />,
            }}
          />
          <ul className="details-list">{specialRequestBullets}</ul>
        </div>
        <div className="p-col-12 product-description margin-bottom10">
          <FormattedHTMLMessage id="landing.performaxgold.limitationsParagraph" />
          <ul className="details-list">{limitationsBullets}</ul>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(PerformaxGoldInforceDescription);
