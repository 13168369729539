/** Dashboard Title Page IDs */
export const DASH_LOGO_HEADER_ID = 'dashboard-logo-header';
export const DASH_TITLE_CONTAINER_ID = 'dashboard-title-container';
export const DASH_TITLE_ID = 'dashboard-title';

/** Dashboard Page IDs */
export const START_NEW_ILLUSTRATION_ID = 'start-new-illustration-btn';
export const START_NEW_APPLICATION_ID = 'start-new-application-btn';
export const START_TCA_APPLICATION_ID = 'start-tca-application-btn';
export const DASH_ILLUSTRATION_TAB_ID = 'dashboard-illustration-tab';
export const DASH_APPLICATION_TAB_ID = 'dashboard-application-tab';
export const DASH_APPLICATION_NEW_TAB_ID = 'dashboard-application-new-tab';
export const DASH_APPLICATION_TCA_TAB_ID = 'dashboard-application-tca-tab';
export const DASHBOARD_FORM_TITLE_ID = 'dashboard-form-title';
export const DASHBOARD_TABLE_ID = 'dashboard-table';
export const DASHBOARD_TABLE_APPS_ID = 'dashboard-table-apps';
export const DASH_RECORDS_PER_PAGE_ID = 'dashboard-records-per-page';
export const DASH_RESET_FILTERS_ID = 'dashboard-reset-filters-btn';
export const DASH_RESET_FILTERS_APPS_ID = 'dashboard-reset-filters-btn';
export const DASH_DDA2_TOGGLE_APPS_ID = 'dashboard-dda2-toggle-btn';
export const DASH_TCA2_TOGGLE_APPS_ID = 'dashboard-tca2-toggle-btn';
export const DASH_DELETE_ID = 'dashboard-delete-btn';
export const DASH_DELETE_APPS_ID = 'dashboard-delete-apps-btn';
export const DASH_ILLUSTRATION_SEARCH_ID = 'dashboard-illustration-search';
export const DASH_APPLICATION_SEARCH_ID = 'dashboard-application-search';
export const DASH_APPLICATION_INS_NAME_SEARCH_ID = 'dashboard-application-ins-name-search';
export const DASH_PRODUCT_FILTER_ID = 'dashboard-product-filter';
export const DASH_PRODUCT_FILTER_APPS_ID = 'dashboard-product-filter-apps';
export const DASH_CREATEDBY_FILTER_ID = 'dashboard-createdBy-filter';
export const DASH_CREATEDBY_FILTER_APPS_ID = 'dashboard-createdBy-filter-apps';
export const DASH_ADVISOR_FILTER_ID = 'dashboard-advisor-filter';
export const DASH_ADVISOR_FILTER_APPS_ID = 'dashboard-advisor-filter-apps';
export const DASH_LASTUPDATEDBY_FILTER_ID = 'dashboard-lastUpdatedBy-filter';
export const DASH_STATUS_FILTER_APPS_ID = 'dashboard-status-filter-apps';
export const DASH_ILLUSTRATION_ID = 'illustrationName';
export const DASH_APPLICATION_ID = 'applicationName';
export const DASH_ORIGIN_APPLICATION_ID = 'originApplicationName';
export const DASH_APPLICATION_INSURED_ID = 'insuredName';
export const DASH_PRODUCT_ID = 'product';
export const DASH_PRODUCT_APPS_ID = 'productApps';
export const DASH_CREATED_BY_ID = 'createdBy';
export const DASH_CREATED_BY_APPS_ID = 'createdByApps';
export const DASH_ADVISOR_ID = 'advisor';
export const DASH_ADVISOR_APPS_ID = 'advisorApps';
export const DASH_LAST_UPDATED_ON_ID = 'lastUpdatedOn';
export const DASH_LAST_UPDATED_ON_APPS_ID = 'lastUpdatedOnApps';
export const DASH_LAST_UPDATED_BY_ID = 'lastUpdatedBy';
export const DASH_PAGINATION_ID = 'pagination-illustrations';
export const DASH_PAGINATION_APPS_ID = 'pagination-apps';
export const DASH_STATUS_APPS_ID = 'statusApps';
export const DASH_STATUS_ID = 'status';
export const DASH_ACTION_ID = 'action';
export const PAGE_SIZE = 50;
export const NUM_MONTHS = 12;
export const PERCENT_HUNDRED = 100;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const MINIMUM_TO_FACE_AMOUNT = 1000;

export const BREAKPOINTS = {
  PHONE_PORTRAIT_SM: 320,
  PHONE_PORTRAIT_MED: 375,
  PHONE_PORTRAIT_LG: 576,
  TABLET_PORTRAIT: 768,
  /** For scenarios where the tablet portrait view needs to be maintainted for smaller widths upto 700px
   * then from 700 to 576 kicking in the mobile view */
  TABLET_PORTRAIT_VIEW_RETAIN: 700,
  DESKTOP: 1024 /** tablet landscape */,
  DESKTOP_HD: 1200,
  MAX_768: 767.98,
  MAX_1280: 1279.98,
  MAX_1600: 1599.98,
};

export const NUMBERS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  ONE_HUNDRED: 100,
};

export const SPREADSHEET = {
  COL_WIDTH: {
    SIX: 6,
    THIRTEEN: 13,
  },
  FONT_SIZE: {
    HEADER: 11,
  },
  COLOR: {
    HEADER_COLOR: 'ff000000',
    HEADER_FG_COLOR: 'ffe2e2e2',
    FIFTH_ROW_HIGHLIGHT: 'ffe3f4f1',
  },
};
