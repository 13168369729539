import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { dropDownOptions } from '../../dropdown-config';
import { eField, eGoodRatings, eProduct, eSmokingStatus, healthStyleEffectiveDate } from '../../configs';
import { isJuvenile } from '../../../core/utils';
import Person from '../../../core/components/person';
import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import HealthStyle from '../health-style';
import SmokingStatus from '../smoking-status';
import './client.css';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants';

export class Client extends PureComponent {
  static propTypes = {
    app: PropTypes.object,
    inforce: PropTypes.bool,
    scenarioTabId: PropTypes.string,
    coverageTabId: PropTypes.string,
    coverageOption: PropTypes.string,
    coverageType: PropTypes.string,
    client: PropTypes.object.isRequired,
    onHealthstyleChange: PropTypes.func,
    showHealthstyle: PropTypes.bool,
    useHealthstyle: PropTypes.bool,
    useSmokingstatus: PropTypes.bool,
    effectiveDate: PropTypes.string,
    intl: PropTypes.object,
    index: PropTypes.number,
    uniqueId: PropTypes.string,
    product: PropTypes.string,
    coverageEffectiveDate: PropTypes.string,

    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    children: PropTypes.object,
  };

  static defaultProps = {
    // clientIndex: 1,
    showHealthstyle: true,
  };

  constructor(props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  dispatchAction(field, value, target, isError = false) {
    if (!target) {
      target = field === eField.durationFrom || field === eField.durationTo ? 'deposit' : 'client';
    }
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      // TODO for multiple scenario, it should be passed
      scenarioTabId: this.props.scenarioTabId,
      coverageTabId: this.props.coverageTabId,
      coverageOption: this.props.coverageOption,
      coverageType: this.props.coverageType,
      clientId: this.props.client.id,
      product: this.props.product,
      target,
      field,
      value,
    });

    if (this.props.product === eProduct.PAR) {
      this.props.sceActions.performManualRefresh(true);
    }
  }

  onChange(type, value, target, isErrorAction) {
    this.dispatchAction(type, value, target, isErrorAction);

    if (type === 'healthstyle' && eGoodRatings.indexOf(value) >= 0) {
      this.props.onHealthstyleChange(this.props.client, value);
    }

    if (type === 'healthstyle' || type === 'smokingstatus' || type === 'sex') {
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  onBlur(type, error, isErrorAction) {
    // set to non-smoker for juveniles
    if (
      type === eField.age &&
      this.props.client.smokingstatus === eSmokingStatus.smoker &&
      isJuvenile(this.props.client.age)
    ) {
      this.dispatchAction(eField.smokingStatus, eSmokingStatus.nonsmoker);
    }

    this.dispatchAction(type, error, isErrorAction);

    // TODO no need for calculation but may need for report
    if (!error) {
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  render = () => {
    const { app, intl, client, index, uniqueId, inforce, effectiveDate, product, coverageEffectiveDate } = this.props;
    const { healthstyle, smokingStatus } = dropDownOptions(intl);
    const { useHealthstyle, useSmokingstatus } = useHealthMetric(product, effectiveDate, inforce, coverageEffectiveDate);

    return (
      <div className="p-col-12 flexdisplay client-container" role="container-client">
        <Person
          person={client}
          onChange={this.onChange}
          onBlur={this.onBlur}
          personId={client.id}
          uniqueId={uniqueId}
        />

        {useHealthstyle && (
          <HealthStyle
            locale={app.locale}
            selectedHealthStyle={client.healthstyle}
            healthStyles={healthstyle}
            index={index}
            uniqueId={uniqueId}
            onChange={(e) => this.onChange('healthstyle', e.target.value, false)}
            containerStyle={`p-col-2 p-lg-3 p-xl-3 p-sm-6 ${this.props.product}`}
          />
        )}
        {useSmokingstatus && (
          <SmokingStatus
            locale={app.locale}
            selectedStatus={client.smokingstatus}
            smokingStatuses={smokingStatus}
            index={index}
            uniqueId={uniqueId}
            disabled={isJuvenile(client.age) || this.props.inforce}
            onChange={(e) => this.onChange('smokingstatus', e.target.value, false)}
            containerStyle="p-col-2 p-lg-3 p-xl-3 p-sm-6 space-right"
          />
        )}
        {this.props.children}
      </div>
    );
  };
}

const useHealthMetric = (product, effectiveDate, inforce, coverageEffectiveDate) => {
  if (!inforce) {
    const useHealthstyle = product !== eProduct.PAR && product !== eProduct.LC && product !== eProduct.MUL;
    const useSmokingstatus = product === eProduct.PAR || product === eProduct.MUL || product === eProduct.LC;
    return { useHealthstyle, useSmokingstatus };
  } else {
    return useInforceHealthMetric(effectiveDate, product, coverageEffectiveDate);
  }
};

const useInforceHealthMetric = (effectiveDate, product, coverageEffectiveDate) => {
  let useHealthstyle = false;
  let useSmokingstatus = false;
  const healthStyleApplicableDate = healthStyleEffectiveDate[product];
  const effectiveDateMoment = product===eProduct.MUL ? moment(effectiveDate, DATE_FORMAT) : moment(coverageEffectiveDate, 'YYYY-MM-DD');
  switch (product) {
    case eProduct.BT:
    case eProduct.FT:
      if (moment(effectiveDateMoment, DATE_FORMAT).isBefore(moment(healthStyleApplicableDate , DATE_FORMAT))) {
        useSmokingstatus = true;
      } else {
        useHealthstyle = true;
      }
      break;
    case eProduct.MUL:
      if (moment(effectiveDateMoment, DATE_FORMAT).isSameOrBefore(moment(healthStyleApplicableDate , DATE_FORMAT))) {
        useHealthstyle = true;
      } else {
        useSmokingstatus = true;
      }
      break;
    case eProduct.PG:
      useHealthstyle = true;
      break;
    case eProduct.PAR:
      useSmokingstatus = true;
      break;
    case eProduct.Performax:
      useSmokingstatus = true;
      break;
    case eProduct.SB:
      useSmokingstatus = true;
      break;
    default:
      break;
  }
  return { useHealthstyle, useSmokingstatus };
};

const mapStateToProps = ({ app, scenarioTabNavs }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const { effectiveDate, product } = activeScenario;

  return {
    app,
    effectiveDate,
    product,
    inforce: app.inforcePolicy,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Client));
