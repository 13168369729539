import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { eLocale, eField, eConcept, eInvestmentAllocation } from '../../../core/configs';
import { handleFocus } from '../../utils';
import MWIButton from '../../../core/components/mwi-button';
import _ from 'lodash';
import './alternate-investment.css';

const milliseconds = 300;
export class AlternateInvestment extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    setGrowth: PropTypes.func,
    setBalanced: PropTypes.func,
    setIncome: PropTypes.func,
    setConceptFieldValue: PropTypes.func,
    isAllocationError: PropTypes.bool,
    setAllocationValues: PropTypes.func,
    isIllustrateChecked: PropTypes.bool,
    concept: PropTypes.string,
    investmentAllocation: PropTypes.object,
    ariaBusy: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.help = React.createRef();
  }
  // debounce the setRoundValue function by 300 milliseconds so
  // the user can see the decimal values inputed before updating state
  onValueChange = _.debounce(this.props.setAllocationValues, milliseconds);

  renderName = (firstChild, idInvestment, altIntOptInvestment) => {
    return (
      <div
        className={`${this.props.locale === eLocale.en ? 'p-col-4' : 'p-col-5'}${
          firstChild ? ' alt-invest-wrapper' : ''
        }`}
      >
        <div className={`mwi-label alt-invst-sec-label${firstChild ? ' first-child' : ''}`} id={idInvestment}>
          <span>
            <FormattedMessage id={altIntOptInvestment} />
          </span>
        </div>
      </div>
    );
  };

  renderInvestment = (
    {
      allocationNumberFormatClass,
      allocationErrorClass,
      investmentAllocation,
      minPercentageAllowed,
      maxPercentageAllowed,
      rateOfReturnNumberFormatClass,
    },
    { firstChild, idReg, idInvestment, altIntOptInvestment, investment, fieldInvestment, fieldInvestmentRate }
  ) => {
    const { ariaBusy, locale, isAllocationError } = this.props;
    const allocationErrorClassName = isAllocationError
      ? `${allocationNumberFormatClass} ${allocationErrorClass}`
      : `${allocationNumberFormatClass}`;

    return (
      <div
        className="p-grid p-grid-no-margin"
        id={idReg}
        role="region"
        aria-live="polite"
        aria-atomic="true"
        aria-busy={ariaBusy}
      >
        {this.renderName(firstChild, idInvestment, altIntOptInvestment)}
        <div className={`alt-invest-container ${locale === eLocale.en ? 'p-col-4' : 'p-col-3'}`}>
          {firstChild && (
            <div className="mwi-label" id="allocation-column">
              <span>
                <FormattedMessage id="concept.altIntColOptionAllocation" />
              </span>
            </div>
          )}
          <div className="p-col-12 ui-input">
            <NumberFormat
              className={firstChild ? `${allocationNumberFormatClass} number-disabled` : allocationErrorClassName}
              value={+investmentAllocation[investment].percentage}
              name={fieldInvestment}
              onValueChange={(values) => this.onValueChange(fieldInvestment, values.value, eField.percentage)}
              decimalScale={0}
              suffix=" %"
              decimalSeparator={locale === eLocale.en ? '.' : ','}
              allowNegative={false}
              isAllowed={(values) => values.value >= minPercentageAllowed && values.value <= maxPercentageAllowed}
              aria-labelledby={`${idInvestment} allocation-column`}
              aria-required="true"
              aria-readonly={firstChild}
              autoComplete="off"
              disabled={firstChild}
              onFocus={handleFocus}
            />
          </div>
        </div>
        <div className={`alt-invest-container p-col-4`}>
          {firstChild && (
            <div className="mwi-label" id="rate-return-column">
              <span>
                <FormattedMessage id="concept.altIntColOptionRateOfReturns" />
              </span>
            </div>
          )}
          <div className="p-col-12 ui-input">
            <NumberFormat
              className={
                locale === eLocale.en ? `${rateOfReturnNumberFormatClass} en-w85` : `${rateOfReturnNumberFormatClass}`
              }
              value={+investmentAllocation[investment].rate}
              decimalScale={3}
              name={fieldInvestmentRate}
              fixedDecimalScale
              onValueChange={(values) => this.onValueChange(fieldInvestment, values.value, eField.rate)}
              suffix=" %"
              decimalSeparator={locale === eLocale.en ? '.' : ','}
              allowNegative={false}
              isAllowed={(values) => values.value >= minPercentageAllowed && values.value <= maxPercentageAllowed}
              aria-labelledby={`${idInvestment} rate-return-column`}
              aria-required="true"
              autoComplete="off"
              onFocus={handleFocus}
            />
          </div>
        </div>
      </div>
    );
  };

  renderAllocationStrategy = (altIntColOptionAllocationStrategy) => {
    const { intl, setBalanced, setGrowth, setIncome } = this.props;
    const strategyText = intl.formatMessage({ id: `${altIntColOptionAllocationStrategy}` });
    return (
      <div className="p-col-3 alt-invest-wrapper allocation-strategy">
        <div className="mwi-label" id="allocation-strategy">
          <span>
            <FormattedMessage id={`${altIntColOptionAllocationStrategy}`} />
          </span>
        </div>
        <MWIButton
          label={intl.formatMessage({ id: 'concept.altIntBtnGrowth' })}
          styleClass="edit-rating mwi-white-button allocation-buttons"
          onClick={setGrowth}
          aria-controls="interest-reg dividends-reg def-cap-reg real-cap-reg"
          srOnlyLabel={strategyText}
        />
        <MWIButton
          label={intl.formatMessage({ id: 'concept.altIntBtnBalanced' })}
          styleClass="edit-rating mwi-white-button allocation-buttons"
          onClick={setBalanced}
          aria-controls="interest-reg dividends-reg def-cap-reg real-cap-reg"
          srOnlyLabel={strategyText}
        />
        <MWIButton
          label={intl.formatMessage({ id: 'concept.altIntBtnIncome' })}
          styleClass="edit-rating mwi-white-button allocation-buttons"
          onClick={setIncome}
          aria-controls="interest-reg dividends-reg def-cap-reg real-cap-reg"
          srOnlyLabel={strategyText}
        />
      </div>
    );
  };

  renderIllustrateAltInvestment = () => {
    const { isIllustrateChecked, setConceptFieldValue } = this.props;
    return (
      <React.Fragment>
        <input
          id="lumpsum-amt"
          className="lumpsum-amount-checkbox"
          type="checkbox"
          name={eField.illustrateChecked}
          onChange={(e) => setConceptFieldValue(eField.illustrateChecked, e.target.checked, eField.alternateInvestment)}
          aria-labelledby="lumpsum-amount-label"
          checked={isIllustrateChecked}
        />
        <label htmlFor="lumpsum-amt" className="p-chkbox-label">
          <FormattedMessage id="concept.lumpsumAmountLabel" />
        </label>
      </React.Fragment>
    );
  };

  render() {
    const { locale, isAllocationError, concept, investmentAllocation } = this.props;
    const allocationErrorClass = 'allocation-input-error';
    const allocationNumberFormatClass = 'mwi-widget mwi-input number-data mwi-w75';
    const altIntColOptionAllocationStrategy = 'concept.altIntColOptionAllocationStrategy';
    const rateOfReturnNumberFormatClass = 'mwi-widget mwi-input number-data m-w75';
    const minPercentageAllowed = 0,
      maxPercentageAllowed = 100;
    const renderData = {
      allocationNumberFormatClass,
      allocationErrorClass,
      investmentAllocation,
      minPercentageAllowed,
      maxPercentageAllowed,
      rateOfReturnNumberFormatClass,
    };

    return (
      <React.Fragment>
        <div className="alternate-container p-grid">
          <div className="p-col-7 p-md-9 p-lg-9 p-xl-7 p-sm-9">
            {this.renderInvestment(renderData, {
              firstChild: true,
              idReg: 'interest-reg',
              idInvestment: 'interest',
              altIntOptInvestment: 'concept.altIntOptInterest',
              investment: eInvestmentAllocation.interest,
              fieldInvestment: eField.interest,
              fieldInvestmentRate: eField.interestRate,
            })}
            {this.renderInvestment(renderData, {
              firstChild: false,
              idReg: 'dividends-reg',
              idInvestment: 'dividends',
              altIntOptInvestment: 'concept.altIntOptDividends',
              investment: eInvestmentAllocation.dividends,
              fieldInvestment: eField.dividends,
              fieldInvestmentRate: eField.dividendRate,
            })}
            {concept !== eConcept.CIRP &&
              this.renderInvestment(renderData, {
                firstChild: false,
                idReg: 'def-cap-reg',
                idInvestment: 'def-capital-gains',
                altIntOptInvestment: 'concept.altIntOptDefCapGains',
                investment: eInvestmentAllocation.deferredCapitalGains,
                fieldInvestment: eField.deferredCapitalGains,
                fieldInvestmentRate: eField.deferredCapitalGainsRate,
              })}
            {this.renderInvestment(renderData, {
              firstChild: false,
              idReg: 'real-cap-reg',
              idInvestment: 'real-capital-gains',
              altIntOptInvestment: 'concept.altIntOptRelCapGains',
              investment: eInvestmentAllocation.realizedCapitalGains,
              fieldInvestment: eField.realizedCapitalGains,
              fieldInvestmentRate: eField.realizedCapitalGainsRate,
            })}
            <div className="p-grid p-grid-no-margin">
              {isAllocationError && (
                <div
                  className={`p-col-8 alt-invest-error-label ${locale === eLocale.en ? 'p-offset-4' : 'p-offset-5'}`}
                >
                  <span>
                    <FormattedMessage id="concept.altIntColOptionAllocationError" />
                  </span>
                </div>
              )}
            </div>
          </div>
          {concept !== eConcept.CIRP && this.renderAllocationStrategy(altIntColOptionAllocationStrategy)}
          <div className={`p-grid p-grid-no-margin lumpsum-container ${isAllocationError ? 'allocation-error' : ''}`}>
            {(concept === eConcept.CEB || concept === eConcept.PEB) && this.renderIllustrateAltInvestment()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(AlternateInvestment));
