import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MWIDropdown from '../../core/components/mwi-dropdown';
import {
  eProduct,
  eField,
  eReqStatus,
  eCoverageType,
  eActionEvent,
  eUserType,
  ePageId,
  configs,
  isSpreadsheetView, //HEADOFFICE TEMP SOLUTION
} from '../../core/configs';
import {
  trackUserActions,
  screenValidator,
  isDevFeatureFlagSet,
  setCoverageSolveToYes,
  setIncludeDepositOptionToYes,
} from '../../core/utils';
import { dropDownOptions } from '../../core/dropdown-config';
import MWIDob from '../../core/components/mwi-dob';
import MWIInput from '../../core/components/mwi-input';
import _ from 'lodash';
import * as AppActions from '../../core/actions';
import './scenario-topbar.css';
//HEADOFFICE TEMP SOLUTION
import moment from 'moment';

const getLinkElement = function (tab, activeTopBarIndex, isValidationError) {
  const indexPosition = 2;
  return (
    <Link
      key={tab.index}
      to={`${this.props.match.url}/${tab.url}`}
      role="tab"
      aria-selected={activeTopBarIndex === tab.index ? 'true' : 'false'}
      onClick={() => {
        this.clickTopBarNav(tab.index);
      }}
      className={tab.index !== 1 && isValidationError ? 'disabled-link' : ''}
    >
      {this.getTabEl(tab.index, tab.label, false, indexPosition)}
    </Link>
  );
};

export class ScenarioTopBar extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    configs: PropTypes.object,
    locale: PropTypes.string,
    product: PropTypes.string,
    concept: PropTypes.string,
    isMultiCov: PropTypes.bool,
    effectiveDate: PropTypes.string,
    scenarioTabId: PropTypes.string,
    topBarErrors: PropTypes.object,
    coverageType: PropTypes.string,
    coverageOption: PropTypes.string,
    premiumDuration: PropTypes.string,
    udmResponse: PropTypes.object,
    intl: PropTypes.object,
    activeTopBarIndex: PropTypes.number,
    appActions: PropTypes.object,
    isConceptOpen: PropTypes.bool,
    userType: PropTypes.string,
    currentScenario: PropTypes.string,
    scenarioName: PropTypes.string,
    tabIndex: PropTypes.number,
    location: PropTypes.object,
    advisorProfile: PropTypes.object,
    coverages: PropTypes.object,
    activeScenario: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange(type, value) {
    if (type === eField.product) {
      trackUserActions(eActionEvent.selection, 'product', value, '', {
        product: value,
        coverageType: this.props.coverageType,
        coverageOption: value === eProduct.PAR ? this.props.premiumDuration : this.props.coverageOption,
        coverageSolve: setCoverageSolveToYes(this.props.coverages),
        includeDepositOption: setIncludeDepositOptionToYes(this.props.activeScenario, this.props.coverages),
      });
      if (value === eProduct.FTV && this.props.coverageType !== eCoverageType.single) {
        this.dispatchAction(eField.prodError, true);
        return true;
      }

      this.dispatchAction(type, value);
    }
    if (type === eField.concept) {
      this.dispatchAction(type, value);
    }
    if (type === eField.scenarioName) {
      const productLength = 9;
      if (value.length > productLength) {
        return false;
      } else {
        this.dispatchAction(type, value);
      }
    }
    return false;
  }

  onBlur(type, value) {
    const date = 'DD/MM/YYYY';
    if (type === eField.effectiveDate) {
      this.dispatchAction(type, value);
      //HEADOFFICE TEMP SOLUTION
      if (isDevFeatureFlagSet() && moment(value, date).format(date) === moment('01/01/2019', date).format(date)) {
        this.dispatchAction('userType', eUserType.advisor, 'app');
      }
    }
  }

  clickTopBarNav = (index) => {
    this.dispatchAction('activeTopBarIndex', index);
  };

  dispatchAction(field, value, target = 'scenario') {
    this.props.appActions.dispatchMWIOnChange({
      scenarioTabId: this.props.scenarioTabId,
      field,
      target,
      value,
    });
  }

  getTabEl = (index, label, disabled, indexPosition) => (
    <div
      key={index}
      disabled={disabled}
      className={`tab-header 
            ${this.props.activeTopBarIndex === index && index !== indexPosition ? 'active' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <span>{label}</span>
    </div>
  );

  getTabLink = (tab) => {
    const { udmResponse, activeTopBarIndex } = this.props;
    const isValidationError = udmResponse.validationStatus.toUpperCase() === eReqStatus.error;

    if (isSpreadsheetView(this.props.location.pathname)) {
      return false;
    }
    return getLinkElement.call(this, tab, activeTopBarIndex, isValidationError);
  };

  get productOptions() {
    const { isMultiCov, product, intl } = this.props;
    let { products } = dropDownOptions(intl);

    products = _.filter(products, (p) => {
      //if product is PAR: dropdown should be just PAR and if product is FT or BT for FTV, no par selection
      // the filanl else is for future products, return the product that matches the value
      if (product === eProduct.FT || product === eProduct.FTV || product === eProduct.BT) {
        return p.value !== eProduct.PAR;
      } else {
        return p.value === product;
      }
    });

    if (isMultiCov) {
      products = _.filter(products, (productItem) => productItem.value !== eProduct.FTV);
    }

    return _.clone(products);
  }

  render() {
    const {
      product,
      effectiveDate,
      scenarioTabId,
      locale,
      topBarErrors,
      intl,
      userType,
      currentScenario,
      scenarioName,
      tabIndex,
      location,
    } = this.props;

    const currentPage = _.findKey(configs.routes, (routes) => routes === location.pathname) || '';
    const { tabNavs } = dropDownOptions(intl);

    return (
      <div className="p-grid p-grid-no-margin subtab-navs-wrapper" role="container-scenariotopbar">
        <div className="p-col-8 product-wrapper">
          {currentPage.toUpperCase() === ePageId.clientcoverage.toUpperCase() && (
            <div className="p-grid p-grid-no-margin">
              <React.Fragment>
                <MWIDropdown
                  name="productDropdown"
                  label={<FormattedMessage id="product.label" />}
                  value={product}
                  options={this.productOptions}
                  onChange={(e) => this.onChange(eField.product, e.target.value)}
                  isRequired={false}
                  containerStyle="p-col-4 p-lg-5 p-xl-4"
                  labelStyle="top-bar-font-size"
                  topBarFieldStyle="top-bar-field"
                />

                <MWIInput
                  containerClass="mwi-padding-left24 p-col-4 p-xl-3 p-lg-4 no-top-bottom-padding"
                  name={eField.scenarioName}
                  labelId={currentScenario}
                  sizeStyle="mwi-w100"
                  label={<FormattedMessage id="scenario.scenarioname" />}
                  placeholder={`${intl.formatMessage({ id: 'common.scenario' })} ${tabIndex}`}
                  onChange={this.onChange}
                  value={scenarioName}
                  labelStyle="top-bar-font-size"
                  topBarFieldStyle="top-bar-field"
                />

                {screenValidator(product).effDateAvailable && userType === eUserType.headOffice && (
                  <div className=" effective-date p-col-3">
                    {/* TODO it should be renamed once the new UI is confirmed */}
                    {/* eslint-disable */}
                    {
                      <MWIDob
                        containerClass="p-col-12 no-top-bottom-padding"
                        name="effectiveDate"
                        labelId={scenarioTabId}
                        label={intl.formatMessage({ id: 'topbar.effectivedate' })}
                        value={effectiveDate}
                        locale={locale}
                        placeholder={intl.formatMessage({ id: 'common.date.placeholder' })}
                        errors={topBarErrors}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        ariaLabel={`${intl.formatMessage({ id: 'topbar.effectivedate' })} 
                      ${intl.formatMessage({ id: 'common.date.placeholder' })}`}
                        labelStyle="top-bar-font-size"
                        topBarFieldStyle="top-bar-field"
                      />
                    }
                    {/* eslint-enable */}
                  </div>
                )}
              </React.Fragment>
            </div>
          )}
        </div>
        <div className="p-col-2" />
        <div className="p-col-2 tabnavs-wrapper">
          <div className="sce-subtab-navs" role="tablist">
            {tabNavs && tabNavs.map((tab) => this.getTabLink(tab))}
          </div>
        </div>
      </div>
    );
  }
}

// TODO add mapStateToProps to refer to scenario tab navs reducer
export const mapStateToProps = ({
  app,
  scenarioTabNavs,
  coverageTabNavs,
  udmResponse,
  conceptTabNavs,
  advisorProfile,
}) => {
  const topbar = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const coverages = coverageTabNavs[scenarioTabNavs.activeTabId];
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const activeScenario = scenarioTabNavs.topBars[scenarioTabId];
  const isMultiCov = coverages.tabNavs.length > 1;
  const { coverageType, coverageOption, premiumDuration } = coverages[coverages.activeTabId];
  const currentScenario = scenarioTabNavs.activeTabId;
  const tabIndex = topbar[eField.scenarioName]
    ? 0
    : _.findIndex(scenarioTabNavs.tabNavs, function (o) {
        return o.id === currentScenario;
      });

  return {
    locale: app.locale,
    scenarioTabId: scenarioTabNavs.activeTabId,
    activeTopBarIndex: topbar.activeTopBarIndex,
    product: topbar.product,
    concept: topbar.concept,
    effectiveDate: topbar.effectiveDate,
    topBarErrors: topbar.errors,
    isConceptOpen: conceptTabNavs.isOpen,
    userType: app.userType,
    scenarioName: topbar[eField.scenarioName],
    tabIndex: tabIndex + 1,
    isMultiCov,
    currentScenario,
    coverageType,
    coverageOption,
    premiumDuration,
    udmResponse,
    advisorProfile,
    coverages,
    activeScenario,
  };
};

const mapDispatchToProps = (dispatch) => ({ appActions: bindActionCreators(AppActions, dispatch) });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ScenarioTopBar)));
