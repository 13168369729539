import React from 'react';
import { eProduct } from '../../core/configs';
import TermCoverageContainer from '../../core/components/term-coverage-container';
import WholeLifeCoverageContainer from '../../core/components/whole-life-coverage-container';
import UniversalLifeCoverageContainer from '../../core/components/universal-life-coverage-container';
import PropTypes from 'prop-types';
import LifechequeCoverageContainer from '../../core/components/lifecheque-coverage-container';

const ProductCoverageContainer = function (props) {
  if ([eProduct.PAR, eProduct.PG, eProduct.Performax, eProduct.SB].includes(props.product)) {
    return <WholeLifeCoverageContainer {...props} />;
  } else if ([eProduct.LC].includes(props.product)) {
    return <LifechequeCoverageContainer {...props} />;
  } else if ([eProduct.FT, eProduct.BT, eProduct.FTV].includes(props.product)) {
    return <TermCoverageContainer {...props} />;
  } else if ([eProduct.MUL, eProduct.IV].includes(props.product)) {
    return <UniversalLifeCoverageContainer {...props} />;
  } else {
    return <React.Fragment />;
  }
};

export default ProductCoverageContainer;

ProductCoverageContainer.propTypes = {
  product: PropTypes.string,
};
