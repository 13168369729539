import * as actionTypes from '../core/actionTypes';
import { takeLatest } from 'redux-saga/effects';
import { authenticateWithBackend } from '../appLoginSaga';
import { requestUDMResponse } from './udm-mapper/udmRequestSaga';

const sagas = function* () {
  yield takeLatest(actionTypes.AUTH_USER, authenticateWithBackend);
  yield takeLatest(actionTypes.REQUEST_UDM_RESPONSE, requestUDMResponse);
};

export default sagas;
