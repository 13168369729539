import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import HelpQuestionMark from '../../../core/components/help-question-mark';
import MWINumberField from '../../../core/components/mwi-calculated-number-field';
import { coverageConfig, eLocale, eField } from '../../../core/configs';

import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import * as CoverageActions from '../../../custom-mode/coverage-tab-navs/actions';

import './coverage-amount.css';

export class CoverageAmount extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    index: PropTypes.number,
    coverage: PropTypes.object,
    onBlur: PropTypes.func,
    validate: PropTypes.func,
    locale: PropTypes.string,
    match: PropTypes.object,
    history: PropTypes.object,
    isSpreadsheetView: PropTypes.bool,
    activeScenarioTabId: PropTypes.string,
    activeCoverageTabId: PropTypes.string,
    clients: PropTypes.object,
    clientIndex: PropTypes.number,
    clientActions: PropTypes.object,
    client1: PropTypes.object,
    udmEnabled: PropTypes.bool,
    selectedProduct: PropTypes.string,
    product: PropTypes.string,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
  }

  dispatchAction(field, value, target = 'coverage', isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
      scenarioTabId: this.props.activeScenarioTabId,
      coverageTabId: this.props.activeCoverageTabId,
      product: this.props.product,
      premiumDuration: this.props.coverage.premiumDuration,
      isCoverageSolve: this.props.coverage.isCoverageSolve,
      clients: this.props.clients,
    });
  }

  onBlur(type, value) {
    if (type === eField.coverageAmount || type === eField.premiumAmount) {
      this.dispatchAction(type, value);
    }

    if (type === eField.switchToCashDividendsYear) {
      this.dispatchAction(type, value);
    }

    this.props.sceActions.toggleOutOfDate(true);
  }

  render = () => {
    const {
      locale,
      index,
      coverage,
      coverage: { coverageAmount, isCoverageSolve, errors },
    } = this.props;

    return (
      <React.Fragment>
        <div className="trc-sub-section" role="coverage-amount">
          <span id="coverage-amount-label">
            <FormattedMessage id="ftrc.calculatorInput.coverageAmount.label" />
          </span>
          <HelpQuestionMark
            containerClass={`trc-help ${locale} ${index === 1 ? 'second' : ''}`}
            overlayId="coverageAmountTip"
            overlayContent={() => {
              return (
                <span>
                  <FormattedMessage id="ftrc.calculatorInput.help.coverage.amount" />
                </span>
              );
            }}
          />
        </div>
        {coverage && (
          <div className="trc-space-right mwi-control-wrapper" role="coverage-control">
            <MWINumberField
              id={eField.coverageAmount}
              name={eField.coverageAmount}
              containerClass={`mwi-widget mwi-input number-data ${!errors.coverageAmount ? '' : 'mwi-error-field'}`}
              onBlur={this.onBlur}
              value={coverageAmount}
              prefix={locale === eLocale.en ? '$ ' : ''}
              suffix={locale === eLocale.fr ? ' $' : ''}
              thousandSeparator={locale === eLocale.en ? ',' : ' '}
              disabled={isCoverageSolve}
              locale={locale}
              minValue={coverageConfig.minAmount}
              maxValue={coverageConfig.maxAmount}
              role="amount-input"
            />
          </div>
        )}
      </React.Fragment>
    );
  };
}

export const mapStateToProps = ({ landingPage, scenarioTabNavs, app, udmResponse, coverageTabNavs, clients }) => {
  const selectedProduct = landingPage.selectedProduct;
  const { udmEnabled, summaryError } = scenarioTabNavs;

  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const clientIdsInCov = Object.keys(coverage.ratings);
  const illustration = udmResponse.illustration;
  const udmPolicy = (illustration && illustration.policy) || {};
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const product = activeScenario.product;
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const isMultiCov = coverageState.tabNavs.length > 1;

  return {
    locale: app.locale,
    activeScenarioTabId: scenarioTabId,
    activeCoverageTabId: coverageState.activeTabId,
    clients: clients[scenarioTabId],
    clientIndex: clients.clientIndex,
    client1: clients[scenarioTabId][clientIdsInCov[0]],
    depositOptionType: activeScenario.depositOptionType,
    includeMaxLevelDepositOption: activeScenario.includeMaxLevelDepositOption,
    udmCoverages: udmPolicy.coverage,
    duration: udmPolicy.duration,
    coverage,
    isMultiCov,
    product,
    selectedProduct,
    summaryError,
    udmEnabled,
    udmPolicy,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  coverageActions: bindActionCreators(CoverageActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CoverageAmount));
