import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MWIButton from '../../core/components/mwi-button';
import _ from 'lodash';

class UnSelectAllRiders extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    riders: PropTypes.object,
    riderActions: PropTypes.object,
    sceActions: PropTypes.object,
  };

  onUnselectAll = () => {
    this.props.riderActions.unselectAllRiders();
    this.props.sceActions.toggleOutOfDate(true);
  };

  get selectedRidersCount() {
    return _.filter(this.props.riders, (rider) => rider.isSelected).length;
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="p-grid rider-remove-all mwi-margin-bottom24">
        <div className="p-col-2">
          <MWIButton
            label={intl.formatMessage({ id: 'rider.unselect.all' })}
            onClick={this.onUnselectAll}
            styleClass="rider-remove-all-btn"
            disabled={this.selectedRidersCount === 0}
          />
        </div>
      </div>
    );
  }
}

export default UnSelectAllRiders;
