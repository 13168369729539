/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { isJoint, getFormattedValue } from '../../core/utils';
import * as Utils from './utils';
import { FormattedMessage } from 'react-intl';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';
import Persona from '../../core/components/persona';
import {
  eField,
  eCoverageType,
  eLifecheque,
  eProduct,
  eBusinessType,
  eEnhancerType,
  eDefault,
} from '../../core/configs';
import { isSummaryAmountsHidden } from './utils';

const getLcCoverageDiv = (configs, coverage, intl) => {
  let lcSummaryCoverage;
  const lcCoverageOptionLabel = configs.lcCoverageOptions.find((obj) => obj.value === coverage.lcCoverageOption).label;
  if (lcCoverageOptionLabel === intl.formatMessage({ id: 'lc.coverage.option.lcPermanent' })) {
    lcSummaryCoverage = `${lcCoverageOptionLabel}, 
      ${configs.lcPremiumDurations.find((obj) => obj.value === coverage.lcPremiumDuration).label},`;
  } else {
    lcSummaryCoverage = `${lcCoverageOptionLabel},`;
  }
  return (
    <span>
      {lcSummaryCoverage}
      <br />
    </span>
  );
};

const renderLcRop = (info, intl) => {
  const { coverage } = info,
    ropContainer = [];
  if (coverage.lcReturnPremiums.ropd) {
    ropContainer.push(intl.formatMessage({ id: 'lc.rop.death.coverage' }));
  }
  if (coverage.lcReturnPremiums.ropx) {
    ropContainer.push(intl.formatMessage({ id: 'lc.rop.expiry.coverage' }));
  }
  if (coverage.lcReturnPremiums.rops) {
    ropContainer.push(intl.formatMessage({ id: 'lc.rop.surrender.coverage' }));
  }
  return (
    <>
      <span>
        {ropContainer.map((rop, index) => (
          <div key={index}>
            {rop}
            <br />
          </div>
        ))}
      </span>
    </>
  );
};

const getCostTypeFromCoverage = (coverage, configs) => {
  return !configs.inforceCoiTypes.find((obj) => obj.value === coverage.costOfInsurance)
    ? ' '
    : configs.inforceCoiTypes.find((obj) => obj.value === coverage.costOfInsurance).label + `, `;
};

const getDeathBenefitFromCoverage = (coverage, configs) => {
  return !configs.deathTypesForSummary.find((obj) => obj.value === coverage.deathBenefitType)
    ? ' '
    : configs.deathTypesForSummary.find((obj) => obj.value === coverage.deathBenefitType).label;
};

const getECVPG = (app, product) => {
  if (product === eProduct.PG) {
    const ecvCoverage = app.inforceData.policy.coverage.find(
      (cvg) => cvg.product === eEnhancerType.ECV && cvg.status === eBusinessType.IF
    );
    if (ecvCoverage) {
      return (
        <div className="mwi-padding-top7">
          {' '}
          <FormattedMessage id="summary.earlycashvalueenhancer" />{' '}
        </div>
      );
    }
  }
  return <></>;
};

const getCoverageTypeOptions = (configs, product) =>
  Utils.isPGold(product) ? configs.coverageTypeOptionsPG : configs.coverageTypeOptions;

const getCoverageInfo = (info, props, esla) => {
  const { app, intl, configs, product, isUDMLoading } = props;
  const { coverage, covOption, targetUDMCoverage } = info;
  const coverageType = coverage.coverageType;
  const coverageTypeOptions = getCoverageTypeOptions(configs, product);

  let coverageInfo;
  if (Utils.isPar(product)) {
    coverageInfo = (
      <span>
        {coverageType !== eCoverageType.single && (
          <span>{coverageTypeOptions.find((covTypeOption) => covTypeOption.value === coverageType).abbr}, </span>
        )}
        {info.covOption}
      </span>
    );
  } else if (Utils.isMUL(product)) {
    coverageInfo = (
      <span>
        {/* // * Coverage amount*/}
        {Utils.coverageAmount(targetUDMCoverage, app.locale)} <br />
        {coverageType !== eCoverageType.single && (
          <span>{coverageTypeOptions.find((obj) => obj.value === coverageType).abbr}, </span>
        )}
        {/* // * Esla years  */}
        {esla && !isUDMLoading && (
          <>
            <span>
              <FormattedMessage id="summary.esla.mul" /> {esla}
            </span>
            <br />
          </>
        )}
        {/* // * Cost Type */}
        {getCostTypeFromCoverage(coverage, configs)}
        {/* // * Death Benefit */}
        {getDeathBenefitFromCoverage(coverage, configs)}
        <br />
        {/* // * COI switch */}
        {coverage.costOfInsuranceSwitchYear &&
          `${intl.formatMessage({ id: 'coverage.mul.coiSwitchSummary' })} ${coverage.switchYear}`}
      </span>
    );
  } else if (Utils.isPGold(product)) {
    coverageInfo = (
      <span>
        {Utils.coverageAmount(targetUDMCoverage, app.locale)} <br />
        {coverageType !== eCoverageType.single && (
          <span>{configs.coverageTypeOptionsPG.find((obj) => obj.value === coverageType).abbr}, </span>
        )}
        {esla && !isUDMLoading && (
          <span>
            <FormattedMessage id="summary.esla.mul" /> {esla}
            <br />
          </span>
        )}
        <span>
          <FormattedMessage id="summary.pgold.costs" />
        </span>
        {covOption}
        <br />
      </span>
    );
  } else if (Utils.isLC(product)) {
    coverageInfo = getLcCoverageDiv(configs, coverage, intl);
  } else {
    coverageInfo = (
      <span>
        {covOption}
        {coverageType !== eCoverageType.single && (
          <span>, {coverageTypeOptions.find((covTypeOption) => covTypeOption.value === coverageType).abbr}</span>
        )}
      </span>
    );
  }
  return coverageInfo;
};

const Ratings = ({ info, product, client1, client2, isJointType, isTermInforceCombined }) => {
  const { client1Ratings, client2Ratings, coverage } = info;

  return (
    <>
      {isJointType && (
        <FormattedMessage id="common.rated">
          {(msg) => (Utils.isRated(client1Ratings) ? `, ${msg}` : '')}
        </FormattedMessage>
      )}
      {isTermInforceCombined && (
        <FormattedMessage id="common.rated">
          {(msg) =>
            Utils.isRated(client1Ratings) || (client2Ratings && Utils.isRated(client2Ratings)) ? `, ${msg}` : ''
          }
        </FormattedMessage>
      )}
      {!isTermInforceCombined && client1 && (
        <FormattedMessage id="common.rated">
          {(msg) => (Utils.showRated(client1, product, coverage) ? `, ${msg}` : '')}
        </FormattedMessage>
      )}
      {!isTermInforceCombined && client2 && (
        <FormattedMessage id="common.rated">
          {(msg) => (Utils.showRated(client2, product, coverage) ? `, ${msg}` : '')}
        </FormattedMessage>
      )}
    </>
  );
};

const renderFamilyTermRenewalCalc = (props, client1, targetUDMCoverage) => {
  const { app, intl, effectiveDate, isUDMLoading } = props;
  const locale = app.locale;
  return (
    <div>
      <div className="ftrc-option mwi-padding-bottom12 mwi-padding-top12" tabIndex="0">
        <div className="p-grid p-grid-no-margin">
          <div className="p-col-8">
            <FormattedMessage id="summary.coveragecost" />
          </div>
          <div className="p-col-4 amount">
            <span>{!isUDMLoading && Utils.coveragePremium(targetUDMCoverage, locale)}</span>
          </div>
        </div>
      </div>

      <div className="ftrc-option mwi-padding-bottom12" tabIndex="0">
        <div className="p-grid p-grid-no-margin">
          <div className="p-col-8">
            <FormattedMessage id="summary.coverageeffective" />
          </div>
          <div className="p-col-4 amount">
            <span>
              {Utils.showCoverageEffectiveDate(
                effectiveDate,
                locale,
                intl.formatMessage({ id: 'summary.defaultdate' })
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="ftrc-option mwi-padding-bottom12" tabIndex="0">
        <div className="p-grid p-grid-no-margin">
          <div className="p-col-10">
            <FormattedMessage id="summary.ageoncoverageeffective" />
          </div>
          <div className="p-col-2 amount">
            <span>{!isUDMLoading && Utils.coverageAge(client1)}</span>
          </div>
        </div>
      </div>

      <div className="p-grid">
        <div className="p-col-12">
          <hr className="splitter client-splitter" />
        </div>
      </div>

      <div className="ftrc-option mwi-padding-bottom12" tabIndex="0">
        <div className="p-grid p-grid-no-margin">
          <div className="p-col-12">
            <FormattedMessage id="summary.coveragedisclaimer" />
          </div>
        </div>
      </div>
    </div>
  );
};

const getReturnOfPremiumAmount = (targetUDMCoverage, locale, lcReturnPremiumType) => {
  return Utils.getReturnOfPremium(
    locale,
    targetUDMCoverage.returnOfPremium &&
      targetUDMCoverage.returnOfPremium.find((premium) => premium.type === lcReturnPremiumType)
  );
};

const isCoveragePremiumVisible = (product, isFamilyTermRenewalCalc) => {
  const isMul = Utils.isMUL(product);
  const isLC = Utils.isLC(product);
  const isPGold = Utils.isPGold(product);
  const isPerformax = Utils.isPerformax(product);
  const isSecurityBuilder = Utils.isSB(product);
  return !isFamilyTermRenewalCalc && !isMul && !isLC && !isPGold && !isPerformax && !isSecurityBuilder;
};

const CoveragePremium = ({ isFamilyTermRenewalCalc, isUDMLoading, targetUDMCoverage, locale, info, product, app }) => {
  const { coverage } = info;
  const isLC = Utils.isLC(product);

  return (
    <>
      {isCoveragePremiumVisible(product, isFamilyTermRenewalCalc) &&
        !isSummaryAmountsHidden(product, app.inforcePolicy) && (
          <div className="p-col-4 amount" role="container-summarycoverage-coverageinfo-amount">
            <span>{!isUDMLoading && Utils.coveragePremium(targetUDMCoverage, locale)}</span>
          </div>
        )}
      {isLC && (
        <div className="p-col-4 amount" role="container-summarycoverage-coverageinfo-amount">
          <span>{!isUDMLoading && Utils.coveragePremium(targetUDMCoverage, locale)}</span>
          <br />
          <br />
          {coverage.lcReturnPremiums.ropd && (
            <>
              <span>
                {!isUDMLoading &&
                  getReturnOfPremiumAmount(targetUDMCoverage, locale, eLifecheque.lcReturnPremiums.ropd)}
              </span>
              <br />
            </>
          )}
          {coverage.lcReturnPremiums.ropx && (
            <>
              <span>
                {!isUDMLoading &&
                  getReturnOfPremiumAmount(targetUDMCoverage, locale, eLifecheque.lcReturnPremiums.ropx)}
              </span>
              <br />
            </>
          )}
          {coverage.lcReturnPremiums.rops && (
            <span>
              {!isUDMLoading && getReturnOfPremiumAmount(targetUDMCoverage, locale, eLifecheque.lcReturnPremiums.rops)}
            </span>
          )}
        </div>
      )}
    </>
  );
};

const PerformaxCoverage = ({ props, info }) => {
  const { configs, app, intl, product } = props;
  const { locale, formatMessage } = intl;

  const {
    coverage: { coverageType, equivalentAge },
    targetUDMCoverage,
    client1Ratings: ratings,
  } = info;

  const joint =
    coverageType !== eCoverageType.single &&
    Utils.safeFind(configs.coverageTypeOptionsPMax, coverageType, 'value', 'abbr');

  const isRated = () => {
    if ([eProduct.Performax, eProduct.SB].includes(product)) {
      return ratings && Utils.isRatedPMax(ratings);
    } else {
      return ratings && Utils.isRated(ratings);
    }
  };
  const { permRatingAmount, tempRatingAmount, tempRatingYear } = ratings;

  const permRatingAmountPresent = permRatingAmount > eDefault.permRatingAmount;
  const tempRatingYearPresent = tempRatingAmount > eDefault.tempRatingAmount;

  const showTempSeparator = () => tempRatingYearPresent && `${locale === 'en' ? ', ' : '; '}`;
  const showTempEndBreak = () => tempRatingYearPresent && <br />;

  return (
    <span>
      {Utils.coverageAmount(targetUDMCoverage, app.locale)}
      <br />
      {joint && (
        <>
          {joint}, <FormattedMessage id="summary.esla" /> {equivalentAge}
          <br />
        </>
      )}
      <FormattedMessage id="coverage.pMax.costDuration" />
      <br />
      {isRated() && (
        <>
          {Utils.ratingsMessage(joint, intl) + ': '}
          {permRatingAmountPresent && (
            <>
              {`${getFormattedValue(locale, permRatingAmount)} ${formatMessage({ id: 'common.forLife' })}`}
              {showTempSeparator()}
              {showTempEndBreak()}
            </>
          )}
          {tempRatingYearPresent && (
            <>
              {`${getFormattedValue(locale, tempRatingAmount)} ${formatMessage({
                id: 'common.for',
              })} ${tempRatingYear} ${formatMessage({ id: 'common.yearss' })}`}
            </>
          )}
        </>
      )}
    </span>
  );
};

const CoverageInfo = ({ info, index, props, client1, client2 }) => {
  const { app, intl, product, isUDMLoading } = props;
  const locale = app.locale;
  const { coverage, targetUDMCoverage } = info;
  const coverageType = coverage.coverageType;
  const isJointType = isJoint(product, coverageType);
  const esla = isJointType && Utils.ESLA(targetUDMCoverage);
  const isCombined = coverageType === eCoverageType.combined;
  const ratings = info.client1Ratings;
  const isFamilyTermRenewalCalc = Utils.isFamilyTermRenewalCalc(product);
  const isMul = Utils.isMUL(product);
  const isLC = Utils.isLC(product);
  const isPGold = Utils.isPGold(product);
  const isPerformax = Utils.isPerformax(product);
  const isSecurityBuilder = Utils.isSB(product);
  const isTermInforce = app.inforcePolicy && [eProduct.FT, eProduct.BT].includes(product);

  const isMulRated = () => isMul && ratings && Utils.isRated(ratings);
  const isPGoldRated = () => isPGold && ratings && Utils.isRated(ratings);
  const renderEsla = () => {
    return (
      <div role="container-summarycoverage-esla">
        {esla && !isMul && !isPGold && !isUDMLoading && (
          <span>
            <FormattedMessage id="summary.esla" /> {esla}
            {', '}
          </span>
        )}
        {!isMul && !isPGold && Utils.coverageAmount(targetUDMCoverage, locale)}
      </div>
    );
  };

  return (
    <div key={index}>
      <div className="p-grid p-grid-no-margin no-margin">
        <div
          className={`p-col-8 label mwi-padding-left12 ${isFamilyTermRenewalCalc ? 'ftrc-flex' : ''}`}
          role="container-summarycoverage-coverageinfo"
        >
          {isPerformax || isSecurityBuilder ? (
            <PerformaxCoverage {...{ props, info, isJointType, client1, client2 }} />
          ) : (
            <>
              {/** 🤨 */}
              {getCoverageInfo(info, props, esla)}
              {!isLC && !isMul && !isPGold && <>, &nbsp;</>}
              {renderEsla()}
              {isLC && renderLcRop(info, intl)}
              {(isMulRated() || isPGoldRated()) && Utils.generateRatings(ratings, intl, isJointType)}
              {!isMul && !isPGold && (
                <Ratings
                  {...{
                    info,
                    product,
                    client1,
                    client2,
                    isJointType,
                    isTermInforceCombined: isCombined && isTermInforce,
                  }}
                />
              )}
              {getECVPG(app, product)}
            </>
          )}
        </div>
        <CoveragePremium
          {...{ isFamilyTermRenewalCalc, isUDMLoading, targetUDMCoverage, locale, info, product, app }}
        />
      </div>

      {isFamilyTermRenewalCalc && renderFamilyTermRenewalCalc(props, client1, targetUDMCoverage)}
    </div>
  );
};

export const SummaryCoverageSection = (props) => {
  // Summary Coverage
  const {
    isCoverageSummaryFolded,
    clientSummary,
    app,
    configs,
    product,
    dividendScale,
    dispatchAction,
    vitalityStatus,
    isVitalityPlusSelected,
    intl,
    isCustomVitalityStatus,
    performanceCreditRate,
    altPerformanceCreditRate,
  } = props;

  return (
    <MWIAccordion
      folded={isCoverageSummaryFolded}
      title={
        <span className="summary-accordion-font">
          <FormattedMessage id="summary.coverage" />
        </span>
      }
      onClickChange={() => dispatchAction(eField.isCoverageSummaryFolded, !isCoverageSummaryFolded, 'scenario')}
      className={'summary-accordion-no-border'}
    >
      {clientSummary.map((client, clientIndex, covInfo) => {
        const { client1, client2 } = client;
        const covOption = covInfo[0].covInfo[0].covOption;
        return (
          <div
            key={clientIndex}
            className={`coverage ${clientIndex === 0 ? 'mwi-padding-top12 ' : ''}mwi-padding-bottom12`}
            tabIndex="0"
          >
            <div className="p-grid p-grid-no-margin mwi-padding-left12">
              {client1 && (
                <Persona
                  intl={intl}
                  app={app}
                  configs={configs}
                  client={client1}
                  product={product}
                  vitalityStatus={vitalityStatus}
                  premiumDuration={covOption}
                  costDuration={covOption}
                  isVitalityPlusSelected={isVitalityPlusSelected}
                  isCustomVitalityStatus={isCustomVitalityStatus}
                />
              )}
              {client2 && (
                <Persona
                  intl={intl}
                  app={app}
                  configs={configs}
                  client={client2}
                  product={product}
                  vitalityStatus={vitalityStatus}
                  isVitalityPlusSelected={isVitalityPlusSelected}
                  isCustomVitalityStatus={isCustomVitalityStatus}
                />
              )}
            </div>
            {client.covInfo.map((info, index) => (
              <CoverageInfo key={index} {...{ info, index, props, client1, client2 }} />
            ))}
          </div>
        );
      })}

      {(Utils.isPerformax(product) || Utils.isSB(product)) && (
        <div className="deposit-option mwi-padding-bottom12" tabIndex="0">
          <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
            <FormattedMessage id="summary.dividendscale" />
          </div>
          <div className="p-grid p-grid-no-margin">
            <div className="p-col-7 label">
              <FormattedMessage id="summary.primaryrate" />
            </div>
            <div className="p-col-5 amount">
              <span>{Utils.safeFind(configs.performaxDividendScales, dividendScale.primary, 'value', 'label')}</span>
            </div>
          </div>
          <div className="p-grid p-grid-no-margin">
            <div className="p-col-7 label">
              <FormattedMessage id="summary.alternate" />
            </div>
            <div className="p-col-5 amount">
              <span>{Utils.safeFind(configs.performaxDividendScales, dividendScale.alternate, 'value', 'label')}</span>
            </div>
          </div>
        </div>
      )}

      {Utils.isPar(product) && (
        <div className="deposit-option mwi-padding-bottom12" tabIndex="0">
          <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
            <FormattedMessage id="summary.dividendscale" />
          </div>
          <div className="p-grid p-grid-no-margin">
            <div className="p-col-7 label" role="container-summarycoverage-alternaterate">
              <FormattedMessage id="summary.alternaterate" />
              {configs.dividendScales.find((divScale) => divScale.value === dividendScale.alternate).label}
            </div>
          </div>
        </div>
      )}

      {Utils.isPGold(product) && (
        <div className="deposit-option mwi-padding-bottom12" tabIndex="0">
          <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
            <FormattedMessage id="coverage.performanceCreditRate" />
          </div>
          <div className="p-grid p-grid-no-margin">
            <div className="p-col-7 label">
              <FormattedMessage id="summary.performanceCreditRate.primary" />
            </div>
            <div className="p-col-5 amount">
              <span>{getFormattedValue(app.locale, performanceCreditRate || '0', undefined, true)}</span>
            </div>
          </div>
          <div className="p-grid p-grid-no-margin">
            <div className="p-col-7 label">
              <FormattedMessage id="summary.performanceCreditRate.alternate" />
            </div>
            <div className="p-col-5 amount">
              <span>{getFormattedValue(app.locale, altPerformanceCreditRate || '0', undefined, true)}</span>
            </div>
          </div>
        </div>
      )}
    </MWIAccordion>
  );
};

SummaryCoverageSection.propTypes = {
  product: PropTypes.string,
  isCoverageSummaryFolded: PropTypes.bool,
  clientSummary: PropTypes.array,
  app: PropTypes.object,
  intl: PropTypes.object,
  configs: PropTypes.object,
  isUDMLoading: PropTypes.bool,
  effectiveDate: PropTypes.string,
  dividendScale: PropTypes.object,
  dispatchAction: PropTypes.func,
  vitalityStatus: PropTypes.string,
  isVitalityPlusSelected: PropTypes.bool,
  isCustomVitalityStatus: PropTypes.bool,
  performanceCreditRate: PropTypes.number,
  altPerformanceCreditRate: PropTypes.number,
};

export default SummaryCoverageSection;
