import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { dropDownOptions } from '../../dropdown-config';
import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import MWIDropdown from '../../../core/components/mwi-dropdown';
import { eField, eProduct } from '../../../core/configs';
import './dividend-scales.css';
import { filterDividendScales } from '../../utils';

export class DividendScales extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    appActions: PropTypes.object,
    intl: PropTypes.object,
    sceActions: PropTypes.object,
    primaryDividendScale: PropTypes.string,
    alternateDividendScale: PropTypes.string,
    scenarioTabId: PropTypes.string,
    product: PropTypes.string,
    coverage: PropTypes.object,
  };

  dispatchAction(field, value, target = 'scenario', isError = false) {
    const { appActions, sceActions, scenarioTabId } = this.props;
    appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
      scenarioTabId,
    });

    sceActions.toggleOutOfDate(true);
    sceActions.performManualRefresh(true);
  }

  render() {
    const { intl, alternateDividendScale, primaryDividendScale, product } = this.props;
    const { dividendScales: dividendScaleOptions, performaxDividendScales } = dropDownOptions(intl);

    const dividendScales =
      [eProduct.Performax, eProduct.SB].includes(product)
        ? {
            primary: performaxDividendScales,
            alternate: performaxDividendScales,
          }
        : {
            primary: filterDividendScales(dividendScaleOptions, alternateDividendScale),
            alternate: filterDividendScales(dividendScaleOptions, primaryDividendScale),
          };
    return (
      <div className="p-grid p-grid-no-margin p-col-12 mwi-margin-top18">
        <div className="p-col-6 p-lg-6 p-xl-4" role="primaryDividendScale-test">
          <MWIDropdown
            data-testid="primary"
            name="primaryDividendScale"
            label={<FormattedMessage id="dividendscale.primary" />}
            value={primaryDividendScale}
            disabled={![eProduct.Performax, eProduct.SB].includes(product)}
            options={dividendScales.primary}
            onChange={(e) => this.dispatchAction(eField.divScale.primary, e.target.value)}
            containerStyle="space-right"
            grid="12"
            tabIndex={'0'}
          />
        </div>
        <div className="p-col-6 p-lg-6 p-xl-4" role="alternateDividendScale-test">
          <MWIDropdown
            data-testid="alternate"
            name="alternateDividendScale"
            label={<FormattedMessage id="dividendscale.alternate" />}
            value={alternateDividendScale}
            options={dividendScales.alternate}
            onChange={(e) => this.dispatchAction(eField.divScale.alternate, e.target.value)}
            containerStyle="space-right"
            grid="12"
          />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const { primaryDividendScale, alternateDividendScale, product } = activeScenario;
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const coverageState = coverageTabNavs[scenarioTabId];
  const coverage = coverageState[coverageState.activeTabId];

  return {
    locale: app.locale,
    scenarioTabId: scenarioTabNavs.activeTabId,
    primaryDividendScale,
    alternateDividendScale,
    product,
    coverage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DividendScales));
