import {
  SET_GROWTH,
  SET_INCOME,
  SET_BALANCED,
  SET_CONCEPT_FIELD_VALUE,
  SET_ALLOCATION_VALUES,
  SET_LIFE_EXPECTANCY_VALUE,
  SET_DURATION_DEFAULT_VALUES
} from '../../core/actionTypes';
import { makeActionCreator } from '../../core/utils';

export const setGrowth = makeActionCreator(SET_GROWTH);
export const setIncome = makeActionCreator(SET_INCOME);
export const setBalanced = makeActionCreator(SET_BALANCED);
export const setConceptFieldValue = makeActionCreator(SET_CONCEPT_FIELD_VALUE);
export const setAllocationValues = makeActionCreator(SET_ALLOCATION_VALUES);
export const setLifeExpectancyValue = makeActionCreator(SET_LIFE_EXPECTANCY_VALUE);
export const setDurationDefault = makeActionCreator(SET_DURATION_DEFAULT_VALUES);
