import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { eLocale, eField, eCompanyType } from '../../../core/configs';
import MWIDropDown from '../../../core/components/mwi-dropdown';
import { InputText } from 'primereact/components/inputtext/InputText';
import { handleFocus } from '../../utils';
import './corporate-irp-structure.css';

export class CorporateIRPStructure extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    companyTypeOptions: PropTypes.array,
    corporateIRPStructure: PropTypes.object,
    setConceptFieldValue: PropTypes.func,
  };

  onValueChange = (fieldName, fieldValue) => {
    this.props.setConceptFieldValue(fieldName, fieldValue, eField.corporateIRPStructure);
  };

  render() {
    const { intl, locale, corporateIRPStructure, companyTypeOptions } = this.props;
    const maxValue = 100;
    return (
      <React.Fragment>
        <div className="mwi-margin-bottom24" id="corporateIRPSContainer" role="container-corporateIRPS">
          <div className="p-grid p-grid-no-margin p-col-12">
            <div className="p-col-4 p-sm-3 p-lg-4 p-xl-3" id="borrowing-type-container" role="container-borrowingtype">
              <div className="mwi-label" id="borrowingTypeLabel">
                {<FormattedMessage id="concept.corporateIRPStructure.borrowingType" />}
              </div>
              <InputText
                name={eField.borrowingType}
                id={eField.borrowingType}
                label={<FormattedMessage id="concept.corporateIRPStructure.borrowingType" />}
                value={intl.formatMessage({ id: 'concept.corporateIRPStructure.corporateBorrowing' })}
                disabled
                className="mwi-widget borrowing-type-input mwi-w100 mwi-input"
              />
            </div>
            <div className="p-col-8 p-lg-7 p-xl-6">
              <div className="mwi-label" id="investmentIncomeTaxLabel">
                <span>
                  <FormattedMessage id="concept.corporateIRPStructure.investmentTax" />
                </span>
              </div>
              <div className={`p-col-9 ui-input`}>
                <NumberFormat
                  className={'mwi-widget mwi-input number-data'}
                  value={+corporateIRPStructure.investmentIncomeTax}
                  decimalScale={2}
                  name={eField.investmentIncomeTax}
                  id={eField.investmentIncomeTax}
                  fixedDecimalScale
                  suffix=" %"
                  decimalSeparator={locale === eLocale.en ? '.' : ','}
                  onValueChange={(values) => this.onValueChange(eField.investmentIncomeTax, values.value)}
                  allowNegative={false}
                  isAllowed={(values) => +values.value >= 0 && +values.value <= maxValue}
                  aria-labelledby="investmentIncomeTaxLabel"
                  aria-required="true"
                  autoComplete="off"
                  onFocus={handleFocus}
                />
              </div>
            </div>
          </div>
          <div className="p-grid p-grid-no-margin p-col-12">
            <div className="p-col-4 p-lg-4 p-xl-3" id="company-type-container" role="container-companytype">
              <MWIDropDown
                label={<FormattedMessage id="concept.corporateIRPStructure.companyType" />}
                name={eField.companyType}
                id="companyTypeDropdown"
                options={companyTypeOptions}
                onChange={(e) => {
                  this.onValueChange(eField.companyType, e.target.value);
                }}
                value={corporateIRPStructure.companyType}
                grid={'12'}
                disabled
              />
            </div>
            {corporateIRPStructure.companyType === eCompanyType.operatingCompany && (
              <React.Fragment>
                <div className="p-col-7 p-lg-4">
                  <div className="mwi-label">
                    <span>
                      <FormattedMessage id="concept.corporateIRPStructure.operatingIncomeTax" />
                    </span>
                  </div>
                  <div className="p-col-10 ui-input">
                    <NumberFormat
                      className={'mwi-widget mwi-input number-data'}
                      value={+corporateIRPStructure.operatingIncomeTax}
                      decimalScale={2}
                      name={eField.operatingIncomeTax}
                      id={eField.operatingIncomeTax}
                      fixedDecimalScale
                      suffix=" %"
                      decimalSeparator={locale === eLocale.en ? '.' : ','}
                      onValueChange={(values) => this.onValueChange(eField.operatingIncomeTax, values.value)}
                      allowNegative={false}
                      isAllowed={(values) => +values.value >= 0 && +values.value <= maxValue}
                      aria-labelledby="operatingIncomeTaxLabel"
                      aria-required="true"
                      autoComplete="off"
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(CorporateIRPStructure));
