import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { eRider } from '../../core/configs';
import Person from '../../core/components/person';
import * as AppActions from '../../core/actions';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import validate from './validate';

export class Child extends PureComponent {
  static propTypes = {
    app: PropTypes.object,
    child: PropTypes.object.isRequired,
    clientIndex: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
  };

  static defaultProps = {
    clientIndex: 1,
  };

  constructor(props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  dispatchAction(field, value, isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target: 'rider',
      riderName: eRider.cpr,
      childId: this.props.child.id,
      field,
      value,
    });
    this.props.sceActions.performManualRefresh(true);
  }

  onChange(type, value, isErrorAction) {
    this.dispatchAction(type, value, isErrorAction);

    if (type === 'sex') {
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  onBlur(type, error, isErrorAction) {
    this.dispatchAction(type, error, isErrorAction);

    if (!error) {
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  render = () => (
    <div className="p-col-12 mwi-margin-top10 child flexdisplay" role="container-child">
      <Person
        person={this.props.child}
        onChange={this.onChange}
        onBlur={this.onBlur}
        validate={validate}
        isChild
        personId={this.props.child.id}
      />

      {this.props.children}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Child);
