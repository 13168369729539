import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import * as ProfileActions from '../../../profile/actions';
import {
  SideNav,
  SideNavSection,
  MainNavItem,
  THEMES,
  SignIn,
  SignInMenuItem,
  Lang,
  Search,
  ContactUs,
} from '@manulife/mux';
import { Briefcase, Calculator, Compare, Home } from '@manulife/mux-cds-icons';
import { ThemeProvider } from 'styled-components';
import { configs, eLocale, getContactUsEndpoint, getAdvisorPortalEndpoint, eProduct } from '../../configs';
import { Manulife, ManulifeFr } from '@manulife/mux-logos';

/*Navigation Bar Parent Component*/
export class SideNavBar extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    app: PropTypes.object,
    isDisable: PropTypes.bool,

    locale: PropTypes.string,
    myCases: PropTypes.object,
    advisorProfile: PropTypes.object,
    productInfo: PropTypes.object,
    userID: PropTypes.string,
    userType: PropTypes.string,

    history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    profileActions: PropTypes.object,
    location: PropTypes.object,
    selectedProduct: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      // activeKey: _.findKey(configs.routes, (routes) => routes === this.props.location.pathname)
    };

    this.openDashboard = this.openDashboard.bind(this);
    this.openCustomMode = this.openIllustration.bind(this);
    this.toggleLang = this.toggleLang.bind(this);
  }

  dispatchAction(field, value, target = 'header', isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
      userId: this.props.userID,
    });
  }
  dispatchNotification(type, field, value) {
    this.props?.appActions[type]({
      field,
      value,
    });
  }

  openDashboard() {
    this.props.history.push(configs.routes.myCases);
  }

  openIllustration() {
    if (this.props.selectedProduct === '') {
      this.props.history.push(configs.routes.home);
    } else if (this.props.selectedProduct === eProduct.FTRC) {
      this.props.history.push(configs.routes.termRenewalCalc);
    } else {
      this.props.history.push(configs.routes.clientCoverage);
    }
  }

  productToIllustrate() {
    this.dispatchNotification('hideRatesNotification');
    this.props.history.push(configs.routes.home);
  }

  toggleLang() {
    const lang = this.props.locale === eLocale.en ? eLocale.fr : eLocale.en;
    this.props.appActions.toggleLanguage(lang);
    this.dispatchAction('languageChange', true, 'app');

    if (this.props.selectedProduct !== eProduct.FTRC) {
      this.props.sceActions.performManualRefresh();
      if (
        this.props.location.pathname !== configs.routes.home &&
        this.props.location.pathname !== configs.routes.myCases
      ) {
        // Do not make a network call on the landing page and dashboard
        this.props.sceActions.requestUDMResponse();
      }
    }
  }

  render() {
    const { intl, app, myCases } = this.props;
    const language = `${intl.locale}-CA`;
    const contactUsLink = getContactUsEndpoint(intl);
    const advisorPortalLink = getAdvisorPortalEndpoint(intl);

    return (
      <div>
        <ThemeProvider theme={THEMES.canadianTheme}>
          <SideNav
            id="sidenav"
            activeKey={myCases.startNewIllustration || app.model ? 'home' : 'myCases'}
            closeNavOnBlur={false}
            lang={language}
            mobileLogo={
              intl.locale === eLocale.en ? (
                // eslint-disable-next-line react/jsx-pascal-case
                <Manulife.WORDMARK_WHITE />
              ) : (
                // eslint-disable-next-line
                <ManulifeFr.WORDMARK_WHITE />
              )
            }
            mobileFooterItems={[
              <Search
                key="utilnav.advisorportal"
                id="utilnav.advisorportal"
                label={<FormattedMessage id="utilnav.advisorportal" />}
                icon={<Compare />}
                onClick={() => window.open(advisorPortalLink)}
              />,
              <SignIn key="utilnav.signin" id="utilnav.signin" label={<FormattedMessage id="utilnav.profile" />}>
                <SignInMenuItem
                  id="utilnav.profile.signout"
                  label={<FormattedMessage id="utilnav.profile.signout" />}
                />
              </SignIn>,
              <ContactUs
                key="utilnav.contactus"
                id="utilnav.contactus"
                label={<FormattedMessage id="utilnav.contactus" />}
                onClick={() => window.open(contactUsLink)}
              />,
              <Lang
                key="utilnav.togglelang"
                id="utilnav.togglelang"
                abbreviation={intl.locale === eLocale.en ? eLocale.fr : eLocale.en}
                onClick={() => {
                  this.toggleLang();
                }}
              />,
            ]}
          >
            <SideNavSection id="individual">
              <MainNavItem
                id="sidenav-dashboard"
                as="button"
                itemKey="myCases"
                label={intl.formatMessage({
                  id: 'sidenav.dashboard',
                })}
                icon={<Briefcase />}
                onClick={() => {
                  this.openDashboard();
                }}
                disabled={this.state.isDisabled}
              />
              <MainNavItem
                id="sidenav-prod-to-illustrate"
                as="button"
                itemKey="prod-to-illustrate"
                label={intl.formatMessage({
                  id: 'sidenav.productstoillustrate',
                })}
                icon={<Home />}
                onClick={() => this.productToIllustrate()}
                disabled={this.state.isDisabled}
              />
              {(myCases.startNewIllustration || app.model) && (
                <MainNavItem
                  id="sidenav-illustrations"
                  as="button"
                  itemKey="home"
                  label={intl.formatMessage({
                    id: 'sidenav.illustration',
                  })}
                  icon={<Calculator />}
                  onClick={() => {
                    this.openIllustration();
                  }}
                  disabled={this.state.isDisabled}
                />
              )}
            </SideNavSection>
          </SideNav>
        </ThemeProvider>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, advisorProfile, myCases, scenarioTabNavs, coverageTabNavs }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const product = activeScenario.product;
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const productInfo = {
    product,
    coverageType: coverage.coverageType,
    coverageOption: product === eProduct.PAR ? coverage.premiumDuration : coverage.coverageOption,
  };
  return {
    app,
    myCases,
    productInfo,
    advisorProfile,
    locale: app.locale,
    userID: app.userID,
    selectedProduct: app.model ? app.model.scenarioTabNavs.topBars['Scenario.1'].product : '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  profileActions: bindActionCreators(ProfileActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SideNavBar)));
