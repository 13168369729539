import {
  SELECT_PRODUCT,
  SELECT_BUSINESS_TYPE,
  GET_INFORCE_POLICY,
  LOADING_INFORCE_POLICY,
  LOADED_INFORCE_POLICY,
  ERROR_INFORCE_POLICY,
  SEARCHING_INFORCE_POLICY,
  SEARCHED_INFORCE_POLICY,
  RESET_INFORCE_SCREEN,
} from '../core/actionTypes';
import { makeActionCreator } from '../core/utils';

export const searchInforcePolicy = makeActionCreator(SEARCHING_INFORCE_POLICY);
export const searchedInforcePolicy = makeActionCreator(SEARCHED_INFORCE_POLICY);
export const selectProduct = makeActionCreator(SELECT_PRODUCT);
export const selectBusinessType = makeActionCreator(SELECT_BUSINESS_TYPE);
export const getInforcePolicy = makeActionCreator(GET_INFORCE_POLICY);
export const loadingInforcePolicy = makeActionCreator(LOADING_INFORCE_POLICY);
export const loadedInforcePolicy = makeActionCreator(LOADED_INFORCE_POLICY);
export const errorInforcePolicy = makeActionCreator(ERROR_INFORCE_POLICY);
export const resetInforceScreen = makeActionCreator(RESET_INFORCE_SCREEN);
