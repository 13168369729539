import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MWIButton from '../../components/mwi-button';
import { injectIntl } from 'react-intl';

export class ViewReportModal extends PureComponent {
  static propTypes = {
    app: PropTypes.object,
    intl: PropTypes.object,
    closeModal: PropTypes.func,
    openReport: PropTypes.func,
    title: PropTypes.string,
    msg: PropTypes.string,
  };
  render() {
    const { intl, closeModal, openReport, title, msg } = this.props;
    return (
      <React.Fragment>
        <div className="p-grid p-grid-no-margin-side scenarioTab-wrapper">
          <h2 className="p-col-12 title-model ">{intl.formatMessage({ id: `${title}` })}</h2>
          <div id="view-report-model-row" className="p-col-12 row-model">
            {intl.formatMessage({ id: `${msg}` })}
          </div>
          <div className="p-col-12 dialog-footer flexdisplay" id="button-list">
            <div className="p-col-3" />
            <MWIButton
              id="report-cancel-button"
              name="cancel"
              label={intl.formatMessage({ id: 'common.cancel' })}
              onClick={closeModal}
              styleClass="mwi-w100 reset-cancel-button cancel-btn oneflex"
              title="cancel-btn"
            />
            <MWIButton
              id="report-open-button"
              tabIndex="0"
              name="save"
              label={intl.formatMessage({ id: 'common.open' })}
              onClick={openReport}
              styleClass="mwi-w100 save-button oneflex p-col-4"
              title="open-btn"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export const mapStateToProps = ({ app }) => {
  return {
    app,
  };
};
export default connect(mapStateToProps)(injectIntl(ViewReportModal));
