import React from 'react';
import PropTypes from 'prop-types';

import { eLocale, eProduct } from '../../configs';
import { getFullName } from '../../../core/utils';
import _ from 'lodash';
import './persona.css';
import moment from 'moment';

const isSelectedProducts = (product, productList) => productList?.includes(product);

const getHealthStatus = (product, client, app, configs) => {
  if (app.inforcePolicy && product === eProduct.MUL) {
    const effectiveDate = app.inforceData.policy.effectiveDate;
    const effectiveDateMoment = moment(effectiveDate, 'yyyy-mm-dd');
    const mulHealthStyleChangeDate = moment('19/11/2021', 'dd/mm/yyyy');
    if (moment(effectiveDateMoment).isSameOrBefore(mulHealthStyleChangeDate)) {
      return `${app.locale === eLocale.en ? 'HS' : 'IS'}${client.healthstyle}`;
    } else {
      return _.find(configs.smokingStatus, {
        value: client.smokingstatus,
      }).label;
    }
  } else if (app.inforcePolicy && [eProduct.BT, eProduct.FT].includes(product)) {
    const effectiveDate = app.inforceData.policy.effectiveDate;
    const effectiveDateMoment = moment(effectiveDate, 'yyyy-mm-dd');
    const termHealthStyleChangeDate = moment('01/05/1998', 'dd/mm/yyyy');
    if (moment(effectiveDateMoment).isSameOrBefore(termHealthStyleChangeDate)) {
            return _.find(configs.smokingStatus, {
        value: client.smokingstatus,
      }).label;
    } else {
      return `${app.locale === eLocale.en ? 'HS' : 'IS'}${client.healthstyle}`;
    }
  } else {
    if (isSelectedProducts(product, [eProduct.PAR, eProduct.MUL, eProduct.LC, eProduct.Performax, eProduct.SB])) {
      return _.find(configs.smokingStatus, {
        value: client.smokingstatus,
      }).label;
    } else {
      return `${app.locale === eLocale.en ? 'HS' : 'IS'}${client.healthstyle}`;
    }
  }
};

const getVitalityStatus = (isVitalityPlusSelected, premiumDuration, selectedVitalityStatus, intl) => {
  let status = '';
  if (isVitalityPlusSelected) {
    status =
      premiumDuration === intl.formatMessage({ id: 'premiumDuration.Pay100' }) ? '' : ', ' + selectedVitalityStatus;
  }
  return status;
};

const Persona = ({
  intl,
  app,
  configs,
  client,
  product,
  vitalityStatus,
  isVitalityPlusSelected,
  isCustomVitalityStatus,
  premiumDuration,
}) => {
  const name = getFullName(client);
  const custom = intl.formatMessage({ id: 'common.custom' });
  const showName = ![eProduct.PG, eProduct.Performax, eProduct.SB].includes(product);
  const isTermInforce = app.inforcePolicy && [eProduct.BT, eProduct.FT].includes(product);

  let selectedVitalityStatus;
  const status = getHealthStatus(product, client, app, configs);

  if (isSelectedProducts(product, [eProduct.MUL, eProduct.FT, eProduct.PAR])) {
    if (isCustomVitalityStatus) {
      selectedVitalityStatus = custom;
    } else {
      selectedVitalityStatus = _.find(configs.vitalityStatus, {
        value: vitalityStatus,
      }).label;
    }
  }

  return (
    <div className="p-col-12 mwi-persona" role="container-persona">
     {!isTermInforce &&
        <span className="info" role="info">
          {showName && name && `${name} - `}
          {_.find(configs.sexes, { value: client.sex }).label.charAt(0)}, {client.age}, {status}
          {isSelectedProducts(product, [eProduct.MUL, eProduct.FT, eProduct.PAR]) &&
            getVitalityStatus(isVitalityPlusSelected, premiumDuration, selectedVitalityStatus, intl)}
        </span>
      }
      {isTermInforce &&
        <span className="info" role="info">
          {showName && name && `${name} - `}
          {_.find(configs.sexes, { value: client.sex }).label.charAt(0 )}
        </span>
      }
    </div>
  );
};

export default Persona;

Persona.propTypes = {
  app: PropTypes.object,
  configs: PropTypes.object,
  client: PropTypes.object,
  index: PropTypes.number,
  product: PropTypes.string,
  vitalityStatus: PropTypes.string,
  premiumDuration: PropTypes.string,
  isVitalityPlusSelected: PropTypes.bool,
  intl: PropTypes.object,
  isCustomVitalityStatus: PropTypes.bool,
};
