import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import MWIDropdown from '../mwi-dropdown';

export class SmokingStatus extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    selectedStatus: PropTypes.string,
    smokingStatuses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      }).isRequired
    ),
    disabled: PropTypes.bool,
    index: PropTypes.number,
    uniqueId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    containerStyle: PropTypes.string,
    intl: PropTypes.object
  };

  render = () => {
    const { locale, selectedStatus, smokingStatuses, disabled, uniqueId, onChange, containerStyle } = this.props;

    return (
      <React.Fragment>
        <MWIDropdown
          name={`smokingstatus.${uniqueId}`}
          label={
            <span id="dd-healthstyle-label">
              <FormattedMessage id="party.smokingstatus" />
            </span>
          }
          value={selectedStatus}
          options={smokingStatuses}
          disabled={disabled}
          onChange={onChange}
          containerStyle={`${containerStyle} mwi-control-wrapper healthstyle ${locale} `}
          grid="2"
        />
        {/* <HelpQuestionMark
          containerClass={`help hs-help ${locale} ${index === 1 ? 'second' : ''}`}
          overlayId="smokingStatusTip"
          overlayContent={() => <FormattedMessage id="help.smokingstatus" />}
        /> */}
      </React.Fragment>
    );
  };
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale
  };
};

export default connect(mapStateToProps)(injectIntl(SmokingStatus));
