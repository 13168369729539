import {LifechequeCheckbox} from "./lifecheque-checkbox";
import {eLifecheque} from "../../configs";
import React from "react";
import PropTypes from 'prop-types';

export const LifechequeCheckboxContainer = (props) => {
  LifechequeCheckboxContainer.propTypes = {
    checked: PropTypes.bool,
    checked1: PropTypes.bool,
    checked2: PropTypes.bool,
    disabled: PropTypes.bool,
    disabled1: PropTypes.bool,
    intl: PropTypes.object,
    onChange: PropTypes.func,
    onChange1: PropTypes.func,
    onChange2: PropTypes.func,
  };

    return <div className="p-grid p-col-11 mwi-margin-top18">
        <LifechequeCheckbox
            onChange={props.onChange}
            checked={props.checked}
            disabled={false}
            intl={props.intl}
            field={eLifecheque.lcReturnPremiums.ropd}
            message="lc.rop.death"
        />
        <LifechequeCheckbox
            onChange={props.onChange1}
            checked={props.checked1}
            disabled={props.disabled}
            intl={props.intl}
            field={eLifecheque.lcReturnPremiums.ropx}
            message="lc.rop.expiry"
        />
        <LifechequeCheckbox
            onChange={props.onChange2}
            checked={props.checked2}
            disabled={props.disabled1}
            intl={props.intl}
            field={eLifecheque.lcReturnPremiums.rops}
            message="lc.rop.surrender"
        />
    </div>;
};