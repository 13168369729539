import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { TabView } from '../../core/components/tabview/tab-view';
import { TabPanel } from '../../core/components/tabview/tab-panel';
import { eProduct, eTabName } from '../../core/configs';
import * as AppActions from '../../core/actions';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import * as ClientActions from '../../services/reducers/components/client/actions';
import * as RiderActions from '../../custom-mode/riders/actions';
import { PaymentTab } from './payment-tab';
import { RiderTab } from './riders-tab';
import Concepts from '../concepts';
import CoverageTab from './coverage-tab';
import CoverageTabNavs from '../coverage-tab-navs';
import RatesTab from '../rates';
import { trackActiveTab, getDataLayerProductInfoFromState } from '../../core/utils';
import { ProductConfig } from '../product-config';
import _ from 'lodash';
import './coverage.css';

const getTabPanels = function (intl, headerStyle, locale, product, location, inforce) {
  const eTabNameCoverage = 0;
  const eTabNamePayments = 1;
  const eTabNameRiders = 2;
  const eTabNameRates = 3;
  const eTabNameConcepts = 4;
  const { coveragePanelTabNavs, mulProductDiv, coveragePanel } = tabPanelSetup(product, location);
  const tabView = [];
  tabView.push(
    this.hasSection(eTabName[eTabNameCoverage]) ? (
      <TabPanel
        header={intl.formatMessage({ id: 'coverage.label' })}
        headerStyle={headerStyle}
        headerClassName={locale}
        contentClassName={coveragePanel}
        key={eTabName[eTabNameCoverage]}
      >
        {coveragePanelTabNavs}
      </TabPanel>
    ) : (
      <TabPanel headerStyle={{ display: 'none' }} key={eTabName[eTabNameCoverage]} />
    )
  );

  tabView.push(
    this.hasSection(eTabName[eTabNamePayments]) ? (
      <TabPanel
        header={intl.formatMessage({ id: 'payments.label' })}
        headerClassName={locale}
        headerStyle={headerStyle}
        key={eTabName[eTabNamePayments]}
      >
        <PaymentTab product={product} inforce={inforce} />
      </TabPanel>
    ) : (
      <TabPanel headerStyle={{ display: 'none' }} key={eTabName[eTabNamePayments]} />
    )
  );

  tabView.push(
    this.hasSection(eTabName[eTabNameRiders]) ? (
      <TabPanel
        header={intl.formatMessage({ id: product === eProduct.LC ? 'lc.riders.label' : 'riders.label' })}
        headerClassName={`riderFix ${locale}`}
        headerStyle={headerStyle}
        key={eTabName[eTabNameRiders]}
      >
        <RiderTab product={product} />
      </TabPanel>
    ) : (
      <TabPanel headerStyle={{ display: 'none' }} key={eTabName[eTabNameRiders]} />
    )
  );

  tabView.push(
    this.hasSection(eTabName[eTabNameRates]) ? (
      <TabPanel
        headerStyle={headerStyle}
        header={intl.formatMessage({ id: 'common.rates' })}
        headerClassName={locale}
        key={eTabName[eTabNameRates]}
      >
        <RatesTab product={product} />
      </TabPanel>
    ) : (
      <TabPanel headerStyle={{ display: 'none' }} key={eTabName[eTabNameRates]} />
    )
  );

  if (!this.props.inforce) {
    tabView.push(
      this.hasSection(eTabName[eTabNameConcepts]) ? (
        <TabPanel
          header={intl.formatMessage({ id: 'concept.tabTitle' })}
          headerClassName={locale}
          headerStyle={headerStyle}
          contentClassName="concepts-panel"
          key={eTabName[eTabNameConcepts]}
        >
          {mulProductDiv}
        </TabPanel>
      ) : (
        <TabPanel headerStyle={{ display: 'none' }} key={eTabName[eTabNameConcepts]} />
      )
    );
  }

  return tabView;
};

const tabPanelSetup = (product, location) => {
  const coveragePanelTabNavs = ProductConfig[product].coverageSections.coverageAccordion ? (
    <CoverageTabNavs />
  ) : (
    <CoverageTab />
  );
  const mulProductDiv =
    product === eProduct.MUL ? <Concepts location={location} product={product} /> : <Concepts product={product} />;
  const coveragePanel = `${
    ProductConfig[product].coverageSections.coverageAccordion ? 'coverage-panel-accordion' : ''
  }`;

  return { coveragePanelTabNavs, mulProductDiv, coveragePanel };
};

export class Coverage extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    activeCoverageTabId: PropTypes.string,
    activeScenarioTabId: PropTypes.string,
    isMultiCov: PropTypes.bool,
    product: PropTypes.string,
    intl: PropTypes.object,
    tabNavs: PropTypes.arrayOf(PropTypes.string).isRequired,
    clients: PropTypes.object,
    activeTabViewTab: PropTypes.number,
    coverage: PropTypes.object,
    location: PropTypes.object,
    sceActions: PropTypes.object,
    riderActions: PropTypes.object,
    appActions: PropTypes.object,
    vitalityStatus: PropTypes.object,
    activeScenario: PropTypes.object,
  };

  componentDidMount() {
    // get premium with default values
    this.props.sceActions.toggleOutOfDate(true);
    this.trackTabInDataLayer();
  }

  componentDidUpdate(prevProps) {
    //Track first load or change
    if (this.props.activeScenarioTabId !== prevProps.activeScenarioTabId) {
      this.trackTabInDataLayer();
    }

    const prevClientIds = prevProps.clients.allClients;
    const curClientIds = this.props.clients.allClients;
    const action = this.props.riderActions;

    // if a client is added or removed, re-render client(s) for eachs rider
    if (curClientIds.length - prevClientIds.length > 0) {
      // add client
      const newClientId = _.difference(curClientIds, prevClientIds);
      action.addRiderClient({ clientId: newClientId[0] });
    } else {
      if (curClientIds.length - prevClientIds.length < 0) {
        // remove client
        const removedClientId = _.difference(prevClientIds, curClientIds);
        removedClientId.forEach((clientId) => {
          action.removeRiderClient({
            clientId,
            curClientIds,
          });
        });
      }
    }
    const curIsCoverageSolve = this.props.coverage.isCoverageSolve;
    const prevIsCoverageSolve = prevProps.coverage.isCoverageSolve;

    if (curIsCoverageSolve !== prevIsCoverageSolve && !curIsCoverageSolve) {
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  getProductInfo = () => {
    getDataLayerProductInfoFromState(
      this.props.coverage,
      this.props.vitalityStatus,
      this.props.product,
      this.props.inforce,
      this.props.activeScenario
    );
  };

  trackTabInDataLayer = function () {
    trackActiveTab(this.props.activeScenarioTabId, eTabName[this.props.activeTabViewTab], this.getProductInfo());
  };

  dispatchAction(field, value, isError = false, target = 'scenario') {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
    });
  }

  onTabChange(e) {
    trackActiveTab(this.props.activeScenarioTabId, eTabName[e.index], this.getProductInfo());

    this.dispatchAction('activeTabViewTab', e.index);
  }

  hasSection(section) {
    return ProductConfig[this.props.product].tabs.indexOf(section) >= 0;
  }

  render() {
    const { product, locale, activeTabViewTab, intl, location, inforce } = this.props;
    const headerStyle = {
      height: '100%',
      display: 'flex',
    };

    return (
      <React.Fragment>
        <TabView
          activeIndex={activeTabViewTab}
          onTabChange={(e) => this.onTabChange(e)}
          navStyle={{
            height: '48px',
          }}
        >
          {getTabPanels.call(this, intl, headerStyle, locale, product, location, inforce).map((panel) => panel)}
        </TabView>
      </React.Fragment>
    );
  }
}

// TODO if the landing page has a product selection, it should be referred here
//      and coverage should use the product from the landing page to update a product in scenarioTabNavs

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs, clients, vitalityStatus }) => {
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const product = activeScenario.product;

  const isMultiCov = coverageState.tabNavs.length > 1;

  return {
    locale: app.locale,
    inforce: app.inforcePolicy,
    activeScenarioTabId: scenarioTabNavs.activeTabId,
    activeTabViewTab: activeScenario.activeTabViewTab,
    activeCoverageTabId: coverageState.activeTabId,
    tabNavs: coverageState.tabNavs,
    coverage: coverageState[coverageState.activeTabId],
    clients: clients[scenarioTabNavs.activeTabId],
    isMultiCov,
    product,
    vitalityStatus,
    activeScenario,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  clientActions: bindActionCreators(ClientActions, dispatch),
  riderActions: bindActionCreators(RiderActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Coverage));
