import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AppActions from '../../actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { eField, eLocale, ePolicyInterestRate, eProduct, maxPrformanceCreditRateSubstractor } from '../../configs';
import MWINumberField from '../mwi-calculated-number-field';

export class PerformanceCreditRate extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    scenarioTabNavs: PropTypes.object,
  };

  dispatchAction(field, value, target = 'scenario', isError = false) {
    const { appActions, sceActions, scenarioTabNavs } = this.props;
    const product = scenarioTabNavs?.topBars[scenarioTabNavs.activeTabId]?.product;
    const alternateUpdated = scenarioTabNavs?.topBars[scenarioTabNavs.activeTabId]?.alternateUpdated;
    const maxAltPCRate = value - maxPrformanceCreditRateSubstractor;

    appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
    });
    if (scenarioTabNavs?.topBars[scenarioTabNavs.activeTabId]?.altPerformanceCreditRate > maxAltPCRate) {
      const maxPrformanceCreditRate = maxAltPCRate < 0 ? 0 : maxAltPCRate;
      appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
        target,
        field: eField.altPerformanceCreditRate,
        value: maxPrformanceCreditRate,
      });
    }
    if (product === eProduct.PG && !alternateUpdated) {
      const altField = eField.altPerformanceCreditRate;
      const altValue = value - 1 < 0 ? 0 : value - 1;
      appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
        target,
        field: altField,
        value: altValue,
      });
    }
    this.props.sceActions.performManualRefresh();
    sceActions.toggleOutOfDate(true);
  }

  render() {
    const { locale, scenarioTabNavs } = this.props;
    const { topBars } = scenarioTabNavs;
    const { performanceCreditRate = '' } = topBars[scenarioTabNavs.activeTabId] || {};

    return (
      <div className="p-col-12 policy-interest-rate mwi-padding-right12 mwi-margin-bottom24">
        <MWINumberField
          allowNegative={false}
          suffix=" %"
          decimalSeparator={locale === eLocale.en ? '.' : ','}
          autoComplete="off"
          id={eField.performanceCreditRate}
          name={eField.performanceCreditRate}
          label={<FormattedMessage id="coverage.performanceCreditRate" />}
          minValue={ePolicyInterestRate.min}
          local={locale}
          value={+performanceCreditRate}
          maxValue={ePolicyInterestRate.max}
          decimalScale={2}
          fixedDecimalScale
          onBlur={(id, val) => this.dispatchAction(id, val)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  appActions: bindActionCreators(AppActions, dispatch),
});

export const mapStateToProps = ({ app, scenarioTabNavs }) => {
  return {
    scenarioTabNavs,
    locale: app.locale,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PerformanceCreditRate));
