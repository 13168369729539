import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import MarginalTaxRate from '../../components/marginal-tax-rate';
import { eConcept, eField, eLocale } from '../../../core/configs';
import './tax-information.css';
import PersonalDividendTaxRate from '../../components/personal-dividend-tax-rate';
import { handleFocus } from '../../utils';

export class TaxInformation extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    concept: PropTypes.string,
    taxInformation: PropTypes.object,
    setConceptFieldValue: PropTypes.func,
  };
  constructor(props) {
    super(props);

    this.help = React.createRef();
  }
  onValueChange = (id, value) => this.props.setConceptFieldValue(id, value, eField.taxInformation);

  render() {
    const { concept, locale, taxInformation } = this.props;
    const maxTaxRate = 100;
    return (
      <React.Fragment>
        <div className="p-grid p-grid-no-margin p-col-12 tax-information-container" role="container-taxinformation">
          {concept === eConcept.PEB && (
            <div
              className={locale === eLocale.en ? 'p-col-3 p-lg-3 p-xl-2 p-sm-6' : 'p-col-5 p-lg-5 p-xl-3 p-sm-6'}
              id="marginal-tax-wrapper"
            >
              <MarginalTaxRate />
            </div>
          )}
          {concept === eConcept.CEB && (
            <div
              className={`alt-invest-container ${
                locale === eLocale.en ? 'p-col-3 p-lg-3 p-xl-2' : 'p-col-5 p-lg-5 p-xl-3'
              }`}
            >
              <div className="mwi-label" id="corporateTaxRate">
                <span>
                  <FormattedMessage id="concept.corporateTaxRate" />
                </span>
              </div>
              <div className="p-col-12 ui-input space-right">
                <NumberFormat
                  className={`mwi-widget mwi-input number-data mwi-w100`}
                  value={+taxInformation.corporateTaxRate}
                  decimalScale={2}
                  fixedDecimalScale
                  suffix=" %"
                  decimalSeparator={locale === eLocale.en ? '.' : ','}
                  allowNegative={false}
                  isAllowed={(values) => +values.value >= 0 && +values.value <= maxTaxRate}
                  name={eField.corporateTaxRate}
                  onValueChange={(values) => this.onValueChange(eField.corporateTaxRate, values.value)}
                  aria-labelledby="corporateTaxRate"
                  aria-required="true"
                  autoComplete="off"
                  onFocus={handleFocus}
                />
              </div>
            </div>
          )}
          <PersonalDividendTaxRate />
        </div>
      </React.Fragment>
    );
  }
}
export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(TaxInformation));
