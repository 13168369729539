import React, { useEffect, useState } from 'react';
import { Table, Pagination, Checkbox, Button, BUTTON_VARIANT, LANG, TextInput, Dropdown, ActionButton } from '@manulife/mux';
import { intlShape, injectIntl } from 'react-intl';
import { eLocale, eDefault, eProduct } from '../../configs';
import {
  PAGE_SIZE,
  DASHBOARD_TABLE_ID,
  DASH_ILLUSTRATION_TAB_ID,
  DASH_RESET_FILTERS_ID,
  DASH_DELETE_ID,
  DASH_ILLUSTRATION_SEARCH_ID,
  DASH_PRODUCT_FILTER_ID,
  DASH_CREATEDBY_FILTER_ID,
  DASH_ADVISOR_FILTER_ID,
  DASH_LASTUPDATEDBY_FILTER_ID,
  DASH_ILLUSTRATION_ID,
  DASH_PRODUCT_ID,
  DASH_CREATED_BY_ID,
  DASH_ADVISOR_ID,
  DASH_LAST_UPDATED_ON_ID,
  DASH_LAST_UPDATED_BY_ID,
  DASH_PAGINATION_ID,
} from '../../constants';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Cds from '@manulife/mux-cds-icons';
import { brandingColors } from '../../../assets/colors/index';
import moment from 'moment';
import PaginationHelper from '../pagination-helper/pagination-helper';
import { useMediaQuery } from 'react-responsive';
import { dropDownOptions } from '../../dropdown-config';
import './index.css';
import _ from 'lodash';
import { getProduct, commonSort } from '../../utils';

export const FormContent = styled.div`
  margin: 0 5px;
  padding: 0 10px;
  @media screen and (min-width: 576px) {
    padding: 0 10px;
  }
`;

export const showingRecordsLabel = (fromRec, toRec, totalRec, intl) => {
  return `${fromRec} ${intl.formatMessage({
    id: 'dashboard.table.footer.to',
  })} ${toRec} ${intl.formatMessage({
    id: 'dashboard.table.footer.of',
  })} ${totalRec} ${intl.formatMessage({
    id: 'dashboard.table.footer.records',
  })}`;
};

function conditionalFunc(condition, caseOne, caseTwo) {
  return condition ? caseOne : caseTwo;
}
function marginCondition(isTabletOrMobile, locale) {
  const enMargin = '25px 5px 0 0';
  const frMargin = '5px 5px 5px 0';
  return isTabletOrMobile || locale === eLocale.fr ? enMargin : frMargin;
}
function andCondition(optionOne, optionTwo) {
  return optionOne && optionTwo;
}
const checkCreatedForByBool = (query, checkCreatedForCreatedBy) => {
  return (
    query.name === '' &&
    query.product === eProduct.ALL &&
    checkCreatedForCreatedBy &&
    query.lastUpdatedBy === eProduct.ALL
  );
};

const createLabels = (intl) => {
  return {
    resetFiltersLabel: intl.formatMessage({ id: 'dashboard.table.resetFilters' }),
    deleteLabel: intl.formatMessage({ id: 'common.delete' }),
    illustrationNameLabel: intl.formatMessage({ id: 'dashboard.table.illustrationName' }),
    productLabel: intl.formatMessage({ id: 'dashboard.table.product' }),
    createdByLabel: intl.formatMessage({ id: 'dashboard.table.createdBy' }),
    advisorLabel: intl.formatMessage({ id: 'common.advisor' }),
    lastUpdatedOnLabel: intl.formatMessage({ id: 'dashboard.table.lastUpdatedOn' }),
    lastUpdatedByLabel: intl.formatMessage({ id: 'dashboard.table.lastUpdatedBy' }),
    selectAllLabel: intl.formatMessage({ id: 'dashboard.table.select.all' }),
  };
};

const createdByForVars = (query, props) => {
  const { intl, canAssignCase } = props;
  const { createdByLabel, advisorLabel } = createLabels(intl);
  return {
    createdByForId: conditionalFunc(canAssignCase, DASH_ADVISOR_ID, DASH_CREATED_BY_ID),
    createdByForLabel: conditionalFunc(canAssignCase, advisorLabel, createdByLabel),
    createdByForFilterId: conditionalFunc(canAssignCase, DASH_ADVISOR_FILTER_ID, DASH_CREATEDBY_FILTER_ID),
    createdByForValue: conditionalFunc(canAssignCase, query.createdFor, query.createdBy),
  };
};

const handleCheckboxChange = (item, checkedItems, setCheckedItems) => {
  if (!checkedItems.some((i) => i.guid === item.guid)) {
    setCheckedItems([...checkedItems, item]);
  } else {
    setCheckedItems(checkedItems.filter((i) => i.guid !== item.guid));
  }
};

const getChunks = (tableRows) => {
  const chunks = [];
  for (let i = 0; i < tableRows.length; i += PAGE_SIZE) {
    chunks.push(tableRows.slice(i, i + PAGE_SIZE));
  }
  return chunks;
};

const headerTextInput = (query, intl, isTabletOrMobile, setQuery) => {
  return (
    <TextInput
      id={DASH_ILLUSTRATION_SEARCH_ID}
      label=""
      value={query.name}
      icon={<Cds.Search />}
      lang={LANG.EN_CA}
      placeholder={intl.formatMessage({ id: 'common.typeKeywords' })}
      customStyle={{ rootStyle: { margin: marginCondition(isTabletOrMobile, intl.locale), width: '90%' } }}
      onChange={(val) => {
        setQuery({ ...query, name: val });
      }}
    />
  );
};
const getHeader = (props, query, setQuery, productOptions, createDropdownList) => {
  const { intl, canAssignCase, data } = props;
  const { illustrationNameLabel, productLabel, lastUpdatedByLabel, lastUpdatedOnLabel } = createLabels(intl);
  const { createdByForId, createdByForLabel, createdByForFilterId, createdByForValue } = createdByForVars(query, props);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 770px)' });
  productOptions = productOptions.filter((x) => x.value !== eProduct.PG && x.value !== eProduct.GI);

  return [
    { id: 'checkbox', label: '', width: conditionalFunc(isTabletOrMobile, '9%', '5%') },
    {
      id: DASH_ILLUSTRATION_ID,
      label: (
        <span>
          <div>{illustrationNameLabel}</div>
          <div>{headerTextInput(query, intl, isTabletOrMobile, setQuery)}</div>
        </span>
      ),
      width: '25%',
    },
    {
      id: DASH_PRODUCT_ID,
      label: (
        <span>
          <div>{productLabel}</div>
          <div>
            <Dropdown
              customStyle={{ rootStyle: { margin: marginCondition(isTabletOrMobile, intl.locale), width: '90%' } }}
              dropdownItems={productOptions}
              id={DASH_PRODUCT_FILTER_ID}
              label=""
              value={query.product}
              onChange={(val) => setQuery({ ...query, product: val })}
            />
          </div>
        </span>
      ),
      width: '17%',
    },
    {
      id: createdByForId,
      label: (
        <span>
          <div>{createdByForLabel}</div>
          <div>
            <Dropdown
              customStyle={{
                rootStyle: { margin: marginCondition(isTabletOrMobile, intl.locale), width: '90%' },
                dropdownInputStyle: { fontWeight: '300' },
              }}
              dropdownItems={createDropdownList(data, conditionalFunc(canAssignCase, 'createdFor', 'createdBy'))}
              id={createdByForFilterId}
              value={createdByForValue}
              onChange={(val) =>
                conditionalFunc(
                  canAssignCase,
                  setQuery({ ...query, createdFor: val }),
                  setQuery({ ...query, createdBy: val })
                )
              }
            />
          </div>
        </span>
      ),
      width: '17%',
      tdStyler: () => ({ color: 'green' }),
    },
    {
      id: DASH_LAST_UPDATED_BY_ID,
      label: (
        <span>
          <div>{lastUpdatedByLabel}</div>
          <div>
            <Dropdown
              customStyle={{ rootStyle: { margin: '5px 5px 5px 0', width: '90%' } }}
              dropdownItems={createDropdownList(data, 'updatedBy')}
              id={DASH_LASTUPDATEDBY_FILTER_ID}
              className="dash-lastupdatedby-filter"
              label=""
              value={query.lastUpdatedBy}
              onChange={(val) => setQuery({ ...query, lastUpdatedBy: val })}
            />
          </div>
        </span>
      ),
      width: '17%',
    },
    {
      id: DASH_LAST_UPDATED_ON_ID,
      label: <div>{lastUpdatedOnLabel}</div>,
      title: lastUpdatedOnLabel,
      isSortable: true,
      width: '20%',
    },
  ];
};
const getTableRows = (newData, query, productOptions, props) => {
  const { intl, canAssignCase, checkedItems, setCheckedItems, open } = props;
  return search(newData, query, productOptions, intl, canAssignCase).map((item) => ({
    checkbox: {
      label: (
        <span>
          <Checkbox
            id={`recselect-${item.guid}`}
            className="row-checkbox"
            disabled={false}
            checked={checkedItems.some((i) => i.guid === item.guid)}
            name={item.name}
            value={item.name}
            onChange={() => handleCheckboxChange(item, checkedItems, setCheckedItems)}
            customStyle={{
              margin: '2px 2px 2px 0',
              width: '90%',
            }}
          />
        </span>
      ),
    },
    illustrationName: {
      label: (
        <span>
          <ActionButton onClick={() => open(item.guid)}>
            <span className="click">{item.name}</span>
          </ActionButton>
        </span>
      ),
      sortValue: item.name,
    },
    product: { label: andCondition(item.product !== 'None', intl.formatMessage({ id: getProduct(item.product) })) },
    createdBy: { label: item.createdBy.name },
    advisor: { label: item.createdFor.name },
    lastUpdatedBy: { label: item.updatedBy.name },
    lastUpdatedOn: {
      label: andCondition(item.updatedAt, moment(item.updatedAt, 'DD/MM/YYYY h:mm a').format(eDefault.casesDate)),
      sortValue: Date.parse(moment(item.updatedAt, 'DD/MM/YYYY h:mm a')),
    },
  }));
};

const dashboardTable = (header, chunkedRows, currentPage, query, intl) => {
  const emptyTableMessage = conditionalFunc(
    query.name,
    intl.formatMessage({ id: 'dashboard.table.nomatch.illustration' }),
    intl.formatMessage({ id: 'dashboard.table.no.illustration' })
  );
  return (
    <Table
      id={DASHBOARD_TABLE_ID}
      header={header}
      rows={chunkedRows[currentPage - 1]}
      defaultSortColumn={DASH_LAST_UPDATED_ON_ID}
      defaultSortOrder="desc"
      emptyTableMessage={emptyTableMessage}
    />
  );
};
const paginationDisplay = (tableRows, currentPage, intl, handlePageChange) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginTop: '15px' }}>
      {tableRows.length > 0 && (
        <>
          <PaginationHelper
            rangeStart={PAGE_SIZE * (currentPage - 1) + 1}
            rangeEnd={Math.min(PAGE_SIZE * currentPage, tableRows.length)}
            maxRecords={tableRows.length}
            intl={intl}
          />
          <Pagination
            id={DASH_PAGINATION_ID}
            className="dash-pagination"
            lang={conditionalFunc(intl.locale === eLocale.en, LANG.EN_CA, LANG.FR_CA)}
            totalItems={Math.ceil(tableRows.length / PAGE_SIZE)}
            navigationAriaLabel="Page control"
            currentItemAriaLabel="Current Item"
            currentItem={currentPage}
            onChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

const dashboardButton = (isResetDisabled, handleOnResetClick, resetFiltersLabel) => {
  return (
    <span>
      <Button
        className="list-button"
        id={DASH_RESET_FILTERS_ID}
        variant={BUTTON_VARIANT.TERTIARY}
        icon={
          <Cds.Reload
            cdsName="icon-reload"
            color={conditionalFunc(isResetDisabled, brandingColors.light_grey, brandingColors.m_green)}
          />
        }
        disabled={isResetDisabled}
        onClick={handleOnResetClick}
        customStyle={{
          buttonStyle: {
            fontColor: brandingColors.accent_grey,
          },
        }}
      >
        {resetFiltersLabel}
      </Button>
    </span>
  );
};

function search(rows, query, productOptions, intl, canAssignCase) {
  if (!_.isEmpty(query.name)) {
    rows = rows.filter((row) => row.name.toLowerCase().includes(query.name.trim().toLowerCase()));
  }
  if (query.product !== eProduct.ALL) {
    const prod = productOptions.find((p) => p.value === query.product).label;
    rows = rows.filter((row) => row.product !== 'None' && intl.formatMessage({ id: getProduct(row.product) }) === prod);
  }
  if (andCondition(canAssignCase, query.createdFor !== eProduct.ALL)) {
    rows = rows.filter((row) => row.createdFor.name === query.createdFor);
  }
  if (andCondition(!canAssignCase, query.createdBy !== eProduct.ALL)) {
    rows = rows.filter((row) => row.createdBy.name === query.createdBy);
  }
  if (query.lastUpdatedBy !== eProduct.ALL) {
    rows = rows.filter((row) => row.updatedBy.name === query.lastUpdatedBy);
  }
  return rows;
}
export const DashboardIllustrationTab = (props) => {
  const { intl, data, deletefunc, checkedItems } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [newData, setNewData] = useState([]);
  const [query, setQuery] = useState({
    name: '',
    product: eProduct.ALL,
    createdBy: eProduct.ALL,
    lastUpdatedBy: eProduct.ALL,
    createdFor: eProduct.ALL,
  });
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const checkCreatedForCreatedBy = andCondition(query.createdBy === eProduct.ALL, query.createdFor === eProduct.ALL);
    if (checkCreatedForByBool(query, checkCreatedForCreatedBy)) {
      setIsResetDisabled(true);
      setNewData(newData);
    } else {
      setIsResetDisabled(false);
    }
  }, [query, data]);
  useEffect(() => {
    if (data?.length > 0) {
      const x = data.map((item) => ({ ...item }));
      setNewData(x);
    } else {
      setNewData([]);
    }
  }, [data]);
  useEffect(() => {
    setIsDisabled(checkedItems?.length === 0);
  }, [checkedItems]);
  const createDropdownList = (dataList, field) => {
    const dropdownList = [{ label: selectAllLabel, value: 'All' }];
    const tempDdList = [];
    Array.from(dataList).forEach((item, index) => {
      if (andCondition(item[field].name !== '', !tempDdList.some((d) => d.label === item[field].name))) {
        tempDdList.push({ id: index, label: item[field].name, value: item[field].name });
      }
    });
    const sortedList = conditionalFunc(tempDdList, [...tempDdList].sort(commonSort), [...tempDdList]);
    dropdownList.push(...sortedList);
    return dropdownList;
  };
  const handleOnResetClick = () => {
    setQuery({
      name: '',
      product: eProduct.ALL,
      createdBy: eProduct.ALL,
      createdFor: eProduct.ALL,
      lastUpdatedBy: eProduct.ALL,
    });
  };
  const { resetFiltersLabel, deleteLabel, selectAllLabel } = createLabels(intl);

  const { productOptions } = dropDownOptions(intl);

  const tableRows = getTableRows(newData, query, productOptions, props);

  const chunkedRows = getChunks(tableRows);
  if (chunkedRows.length > 0 && chunkedRows.length < currentPage) {
    setCurrentPage(chunkedRows.length);
  }
  const header = getHeader(props, query, setQuery, productOptions, createDropdownList);
  const handlePageChange = (selectedPageNum) => {
    setCurrentPage(selectedPageNum);
  };
  return (
    <React.Fragment>
      <FormContent>
        <div id={DASH_ILLUSTRATION_TAB_ID}>
          <div style={{ float: 'right', marginTop: '5px' }}>
            {dashboardButton(isResetDisabled, handleOnResetClick, resetFiltersLabel)}
            <span>
              <Button
                id={DASH_DELETE_ID}
                className="dash-delete-id"
                title="delete_btn"
                variant={BUTTON_VARIANT.TERTIARY}
                icon={
                  <Cds.Delete
                    cdsName="icon-delete"
                    color={conditionalFunc(isDisabled, brandingColors.light_grey, brandingColors.m_green)}
                  />
                }
                disabled={isDisabled}
                onClick={() => {
                  deletefunc(checkedItems.map((c) => c.guid));
                }}
              >
                {deleteLabel}
              </Button>
            </span>
          </div>
          {dashboardTable(header, chunkedRows, currentPage, query, intl)}
        </div>
        {paginationDisplay(tableRows, currentPage, intl, handlePageChange)}
      </FormContent>
    </React.Fragment>
  );
};

DashboardIllustrationTab.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.array.isRequired,
  deletefunc: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  checkedItems: PropTypes.array.isRequired,
  setCheckedItems: PropTypes.func.isRequired,
  canAssignCase: PropTypes.bool.isRequired,
};

export default injectIntl(DashboardIllustrationTab);
