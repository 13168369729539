import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { eProduct } from '../../../core/configs';
import MWIDropdown from '../../../core/components/mwi-dropdown';
import HelpQuestionMark from '../help-question-mark';

export class HealthStyle extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    selectedHealthStyle: PropTypes.string,
    product: PropTypes.string,
    healthStyles: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }).isRequired
    ),
    index: PropTypes.number,
    uniqueId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    intl: PropTypes.object,
    grid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    containerStyle: PropTypes.string,
  };

  static defaultProps = {
    grid: '1',
  };

  constructor(props) {
    super(props);

    this.help = React.createRef();
  }

  render = () => {
    const { locale, selectedHealthStyle, healthStyles, index, uniqueId, onChange, containerStyle, product, inforce } =
      this.props;

    return (
      <React.Fragment>
        <MWIDropdown
          name={`healthstyle.${uniqueId}`}
          label={
            <span id="dd-healthstyle-label">
              <FormattedMessage id="party.healthstyle" />
            </span>
          }
          disabled={this.props.inforce}
          value={selectedHealthStyle}
          options={healthStyles}
          onChange={onChange}
          containerStyle={`${containerStyle} mwi-control-wrapper healthstyle ${locale} `}
        />
        <HelpQuestionMark
          containerClass={` help hs-help ${locale} ${index === 1 ? 'second' : ''}`}
          overlayId="healthstyleTip"
          overlayContent={() => {
            return (
              <span>
                {product !== eProduct.PG && !([eProduct.BT, eProduct.FT].includes(product) && inforce) && (
                  <div>
                    <FormattedMessage id="help.healthstyle" /> <br /> <br />
                    <FormattedMessage id="help.healthstyle1" /> <br /> <br />
                  </div>
                )}
                <FormattedMessage id="help.healthstyle2" /> <br />
                <FormattedMessage id="help.healthstyle3" /> <br />
                <FormattedMessage id="help.healthstyle4" /> <br />
                <div className={`help-indent-${locale}`}>
                  <FormattedMessage id="help.healthstyle5" />
                </div>
                <br />
                <div className={`help-indent-${locale}`}>
                  <FormattedMessage id="help.healthstyle6" /> <br />
                </div>
              </span>
            );
          }}
        />
      </React.Fragment>
    );
  };
}

export const mapStateToProps = ({ app, scenarioTabNavs }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  return {
    locale: app.locale,
    inforce: app.inforcePolicy,
    product: activeScenario.product,
  };
};

export default connect(mapStateToProps)(injectIntl(HealthStyle));
