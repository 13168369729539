import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import 'moment/locale/fr';
import MWISrOnly from '../../core/components/mwi-sr-only';
import { eReqStatus, eLocale, eCalcField } from '../../core/configs';
import { getFormattedValue } from '../../core/utils';
import './summary.css';

export class SummaryFtrcPremiumTab extends PureComponent {
  static propTypes = {
    productValidationError: PropTypes.bool,
    app: PropTypes.object,
    udmResponse: PropTypes.object,
    intl: PropTypes.object,
    premiumFrequency: PropTypes.string,
    clientSummary: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.errorRef = React.createRef();
  }

  coveragePremium(targetUDMCoverage) {
    return getFormattedValue(
      this.props.app.locale,
      (targetUDMCoverage && targetUDMCoverage[eCalcField.covNextPremium]) || '0'
    );
  }

  coverageRenewalDate(targetUDMCoverage) {
    if (targetUDMCoverage && targetUDMCoverage[eCalcField.covNextDate]) {
      moment.locale(this.props.app.locale);

      return moment(targetUDMCoverage[eCalcField.covNextDate]).format(
        this.props.app.locale === eLocale.en ? 'MMM D, YYYY' : 'D MMM YYYY'
      );
    } else {
      return this.props.intl.formatMessage({ id: 'summary.defaultdate' });
    }
  }

  coverageRenewalAge(targetUDMCoverage) {
    return (targetUDMCoverage && targetUDMCoverage[eCalcField.covNextAge]) || '';
  }

  render = () => {
    const { intl, udmResponse, productValidationError, premiumFrequency, clientSummary, app } = this.props;
    const isUDMLoading = udmResponse.isLoading;
    const isValidationError = udmResponse.validationStatus.toUpperCase() === eReqStatus.error;
    const isValidationWarning = udmResponse.validationWarnings && udmResponse.validationWarnings.length > 0;

    return (
      <div className="fixed-panel" tabIndex="0" role="container-summaryftrcpremiumtab">
        {clientSummary &&
          clientSummary.map((client, clientIndex) => {
            return (
              <div key={clientIndex} tabIndex="0">
                {client.covInfo.map((info, index) => {
                  return (
                    <div key={index}>
                      <div className="mwi-padding-bottom12">
                        <div className="p-grid p-grid-no-margin sce-premium">
                          <div className="p-col-8 total-premium" role="heading" aria-level="2">
                            <FormattedMessage id="summary.coveragecostnext" /> (
                            <FormattedMessage id={`common.summary.${premiumFrequency.toLowerCase()}`} />)
                            {app.locale === eLocale.fr && <FormattedMessage id="summary.coverageend" />}
                          </div>
                          {!isUDMLoading && (
                            <div className="p-col-4">
                              <div className="total-amount total-premium">
                                {!isUDMLoading && this.coveragePremium(info.targetUDMCoverage)}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="deposit-option mwi-padding-bottom12" tabIndex="0">
                        <div className="p-grid p-grid-no-margin">
                          <div className="p-col-8">
                            <FormattedMessage id="summary.nextrenewaldate" />
                          </div>
                          <div className="p-col-4 amount">
                            <span>{!isUDMLoading && this.coverageRenewalDate(info.targetUDMCoverage)}</span>
                          </div>
                        </div>
                      </div>

                      <div className="deposit-option mwi-padding-bottom12" tabIndex="0">
                        <div className="p-grid p-grid-no-margin">
                          <div className="p-col-9">
                            <FormattedMessage id="summary.nextrenewalage" />
                          </div>
                          <div className="p-col-3 amount">
                            <span>{!isUDMLoading && this.coverageRenewalAge(info.targetUDMCoverage)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}

        <div className="message-box" role="alert">
          {productValidationError && (
            <div className="out-of-date message error">
              <i className="material-icons msg-icon">cancel</i>
              <FormattedMessage
                id="summary.error.vitality"
                values={{
                  vitality: (
                    <i>
                      <FormattedMessage id="common.vitality" />
                    </i>
                  ),
                }}
              />
            </div>
          )}

          {isValidationError &&
            udmResponse.validationErrors.map((error, index) => {
              return (
                <div className="message error" key={index} role="container-errormessage">
                  <i aria-hidden className="material-icons msg-icon">
                    cancel
                  </i>
                  <MWISrOnly label={intl.formatMessage({ id: 'common.error' })} />
                  <div className="out-of-date">{error.message[0].text}</div>
                </div>
              );
            })}
          {isValidationWarning &&
            udmResponse.validationWarnings.map((warning, index) => {
              return (
                <div className="message warning" key={index} role="container-warningmessage">
                  <i aria-hidden className="material-icons msg-icon">
                    warning
                  </i>
                  <MWISrOnly label={intl.formatMessage({ id: 'common.warning' })} />
                  <div className="out-of-date">{warning.message[0].text}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };
}

export default SummaryFtrcPremiumTab;
