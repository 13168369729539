import React from 'react';
import { eWithdrawalType } from '../../../core/configs';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formattedDataMULPaymentDeposits } from '../summary-support-functions';
import PropTypes from 'prop-types';

export const MULSummary = (props) => {
  const { depositOption, intl, withdrawals, isInforce } = props;
  const {
    line1,
    line2,
    line3,
    line4,
    isCustom,
    withdrawalType,
    withdrawalAmount,
    withdrawalDuration,
    spacingFrenchYearlyMin,
    depositFrequencyPricing,
    durationPayment,
    isAdditionalDepositSelected,
  } = formattedDataMULPaymentDeposits({ ...depositOption, locale: intl.locale, withdrawals });

  const showDurationPayment = () => !line4 && line2 && line3;

  return (
    <>
      <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
        <FormattedMessage id="summary.mul.depositoption" />
      </div>

      {/* Deposits */}
      <div className="p-grid p-grid-no-margin label no-margin refresh mwi-padding-bottom12">
        <div className="p-col-8 label">
          {/* Deposit Frequency: label // ? test 4 times */}
          <div id="depositFrequency">
            <FormattedMessage id={line1} />
            {/* Dynamic rendering line pricing for depositOption */}
            {line2 && <FormattedMessage id={line2} />}
            {showDurationPayment() && durationPayment}
            {line3 && <FormattedMessage id={line3} />}
            {line4 && durationPayment}
            {line4 && <FormattedMessage id={line4} />}
          </div>

          {/* Additional 1st year deposit: label  // ? test 1 times, default => false */}
          {isAdditionalDepositSelected &&
            (isInforce ? (
              <div id="additionalDeposit">
                <FormattedMessage id="deposit.additional.deposit.inforce" />
              </div>
            ) : (
              <div id="additionalDeposit">
                <FormattedMessage id="summary.additionalDepositFirstYear" />
              </div>
            ))}
        </div>

        <div className="p-col-4 amount">
          <div id="depositFrequencyAmount">
            {/* Display custom // ? test 2 time */}
            {isCustom && <FormattedMessage id={'depositoption.custom'} />}

            {/* Deposit Frequency: pricing // ? test 2 time */}
            {depositFrequencyPricing}
          </div>

          {/* Handle French or yearly min spacing */}
          {spacingFrenchYearlyMin}

          {/* Additional 1st year deposit: pricing*/}
          {isAdditionalDepositSelected && <div id="depositFrequencyAmount">{isAdditionalDepositSelected}</div>}
        </div>
      </div>

      {/* Withdrawals */}
      {withdrawalType !== eWithdrawalType.none && (
        <>
          <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
            <FormattedMessage id="summary.withdrawal" />
          </div>
          <div className="p-grid p-grid-no-margin label no-margin refresh mwi-padding-bottom12">
            <div className="p-col-12 label">
              {withdrawalAmount} <FormattedMessage id="summary.withdrawal.for" />
              {withdrawalDuration} <FormattedMessage id="common.yearss" />
            </div>
          </div>
        </>
      )}
    </>
  );
};

MULSummary.propTypes = {
  depositOption: PropTypes.object,
  withdrawals: PropTypes.object,
  intl: PropTypes.object,
  isInforce: PropTypes.bool,
};

export default injectIntl(MULSummary);
