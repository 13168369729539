import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { dispatchMWIUpdateError, dispatchMWIOnChange } from '../../actions';
import { toggleOutOfDate, performManualRefresh } from '../../../custom-mode/scenario-tab-navs/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { dropDownOptions } from '../../dropdown-config';
import MWIDropdown from '../../../core/components/mwi-dropdown';
import NumberFormat from 'react-number-format';
import MWIAccordion from '../../../core/components/mwi-accordion-stateless';
import {
  eField,
  eMaxVal,
  ePremiumOffset,
  eDepositOptionDurationType,
  eProduct,
  eUserType,
  ePremiumDuration,
  eDividendOption,
  ePerformaxDividendOption,
} from '../../configs';
import { handleFocus, getPolicyYear } from '../../utils';
import './premium-offset.css';
import moment from 'moment';

export class PremiumOffset extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    product: PropTypes.string,
    dispatchMWIUpdateErrorAppAction: PropTypes.func,
    dispatchMWIOnChangeAppAction: PropTypes.func,
    toggleOutOfDateSceAction: PropTypes.func,
    performManualRefresh: PropTypes.func,
    premiumFreq: PropTypes.string,
    premiumOffset: PropTypes.string,
    premiumOffsetYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    premiumOffsetYearFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    premiumOffsetYearTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    premiumDuration: PropTypes.string,
    isPremiumOffsetFolded: PropTypes.bool,
    isCoverageSolve: PropTypes.bool,
    effectiveDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    userType: PropTypes.string,
    app: PropTypes.object,
    inforce: PropTypes.bool,
    dividendOption: PropTypes.string,
  };

  dispatchAction(field, value, target = eField.scenario, isError = false, refresh = true) {
    const { dispatchMWIUpdateErrorAppAction, dispatchMWIOnChangeAppAction, toggleOutOfDateSceAction } = this.props;

    if (isError) {
      dispatchMWIUpdateErrorAppAction({
        target,
        field,
        value,
      });
    } else {
      dispatchMWIOnChangeAppAction({
        target,
        field,
        value,
      });
    }

    if (refresh) {
      toggleOutOfDateSceAction(true);
    }

    if ([eProduct.PG, eProduct.Performax, eProduct.SB].includes(this.props.product)) {
      this.props.performManualRefresh();
    }
  }

  onBlur(type, value) {
    this.checkPremiumOffset(type, value);
    this.props.toggleOutOfDateSceAction(true);
  }

  checkPremiumOffset(type, value) {
    const { product, app } = this.props;
    const isParInforce = product === eProduct.PAR && app.inforcePolicy;
    if ([eProduct.PG, eProduct.Performax, eProduct.SB].includes(this.props.product) || isParInforce) {
      this.dispatchAction(type, value);
    } else if (type === eField.premiumOffsetYear) {
      const maxPremiumDuration = this.getMaxPremiumDuration();
      this.dispatchAction(type, value < maxPremiumDuration ? value : maxPremiumDuration);
    } else {
      // do nothing
    }
  }

  getMaxPremiumDuration() {
    return eMaxVal()[this.props.premiumDuration].duration;
  }
  shouldParInforcePremiumOffsetDisable() {
    const { dividendOption } = this.props;

    const isCashDividend = dividendOption === eDividendOption.cash;
    const isParInforceOnOffset = this.getPremiumOffsetInforce();

    return !isParInforceOnOffset && isCashDividend;
  }

  shouldPmaxSbPremiumOffsetDisable() {
    const { dividendOption } = this.props;

    return dividendOption === ePerformaxDividendOption.PremiumReduction;
  }

  onDropdownChange(value) {
    // we moved the requirements to change the depositOptionDurationType to sameasbase when
    // premiumOffset is earliet and isCoveraegSolve is false into this function and away formthe reducer
    if (!this.props.isCoverageSolve) {
      if (value === ePremiumOffset.earliest) {
        this.dispatchAction(
          eField.depositOptionDurationType,
          eDepositOptionDurationType.sameasbase,
          eField.scenario,
          false,
          false
        );
        this.dispatchAction(eField.isDepositOptionCustom, false, eField.scenario, false, false);
      } else {
        this.dispatchAction(
          eField.depositOptionDurationType,
          eDepositOptionDurationType.year,
          eField.scenario,
          false,
          false
        );
      }
    }
    this.dispatchAction(eField.premiumOffset, value);
  }

  getPremiumOffsetInforce() {
    const { app } = this.props;
    const isInforceOnOffset = app.inforceData.policy.premiumOffset?.type === ePremiumOffset.inforce;
    return isInforceOnOffset;
  }

  handleYearChangeProps = (value, minYear) => {
    if (+value < minYear || +value === 0) {
      value = minYear;
    }
    return value;
  };

  handleYearChange = (value, minYear, maxYearCheck) => {
    if (+value < minYear || +value === 0) {
      value = minYear;
    } else if (+value > maxYearCheck) {
      value = maxYearCheck;
    } else {
      // do nothing
    }
    this.onBlur(eField.premiumOffsetYear, value);
    this.forceUpdate();
  };

  renderStartingAtYear(maxYearCheck, minYear) {
    return (
      <div className={`p-col-4 p-lg-3 p-xl-2 premium-offset-year-wrapper`} role="premium-offset-year-wrapper">
        <div className="mwi-label">
          <span>
            <FormattedMessage id="payments.premiumoffsetyear" />
          </span>
        </div>
        <div className={`p-col-6`} role="number-format">
          <NumberFormat
            className={`mwi-widget mwi-input number-data `}
            value={
              this.props.premiumOffset === ePremiumOffset.earliest
                ? +this.props.premiumOffsetYear
                : this.handleYearChangeProps(+this.props.premiumOffsetYear, minYear)
            }
            decimalScale={0}
            allowNegative={false}
            isAllowed={(values) => +values.value <= maxYearCheck}
            onBlur={(e) => this.handleYearChange(e.target.value, minYear, maxYearCheck)}
            aria-labelledby="premiumOffsetYear"
            aria-required="true"
            autoComplete="off"
            onFocus={handleFocus}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      userType,
      premiumOffset,
      premiumFreq,
      isPremiumOffsetFolded,
      intl,
      product,
      effectiveDate,
      isCoverageSolve,
      app,
    } = this.props;
    let {
      premiumOffsets,
      premiumFrequency,
      premiumOffsetsPG,
      premiumFrequencyAll,
      premiumOffsetsPMax,
      premiumOffsetsInforce,
    } = dropDownOptions(intl);

    const isParInforce = product === eProduct.PAR && app.inforcePolicy;
    const indexToremoveItem = premiumOffsets.findIndex((x) => x.value === ePremiumOffset.earliest);
    if (isCoverageSolve) {
      premiumOffsets.splice(indexToremoveItem, 1);
    }
    let titleLabel = 'payments.premiumoffset';
    let premiumOffsetLabel = 'payments.premiumoffset';
    let minYear = 1;
    const maxYearPay100 = 100;
    const maxYear99 = 99;
    const maxYear = isParInforce ? maxYearPay100 : maxYear99;

    const maxYearCheck =
      (this.props.product === eProduct.PAR && !app.inforcePolicy) ||
      this.props.premiumDuration === ePremiumDuration.Pay100
        ? maxYearPay100
        : maxYear;

    if (product === eProduct.PG) {
      premiumOffsets = premiumOffsetsPG;
    }

    if ([eProduct.PG, eProduct.Performax, eProduct.SB].includes(this.props.product) || isParInforce) {
      if (!isParInforce) {
        titleLabel = 'payments.title';
        premiumOffsetLabel = 'common.duration';
      }

      if (!app.inforceData.policy.premiumOffset) {
        premiumOffsets.shift();
      }
      premiumFrequency = premiumFrequencyAll;
      minYear = getPolicyYear(moment(effectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD')) + 1;
    }

    if ([eProduct.Performax, eProduct.SB].includes(product)) {
      premiumOffsets = premiumOffsetsPMax;

      if (app.inforceData.policy.premiumOffset) {
        premiumOffsets = premiumOffsets.filter((option) => option.value !== ePremiumOffset.premiumholiday);
      } else {
        premiumOffsets = premiumOffsets.filter((option) => option.value !== ePremiumOffset.inforce);
      }
    }

    if (product === eProduct.PAR && !isParInforce) {
      const advisorStartingAtVersion = 6;
      const headOfficeStartingAtVersion = 1;
      minYear = userType === eUserType.advisor ? advisorStartingAtVersion : headOfficeStartingAtVersion;
    }

    const handleYearChangeFrom = (value) => {
      if (+value < minYear || +value === 0) {
        value = minYear;
      } else if (+value > this.props.premiumOffsetYearTo) {
        this.onBlur(eField.premiumOffsetYearTo, value);
      } else if (+value > maxYearCheck) {
        value = maxYearCheck;
      } else {
        // do nothing
      }
      this.onBlur(eField.premiumOffsetYearFrom, value);
      this.forceUpdate();
    };

    const handleYearChangeTo = (value) => {
      if (+value < minYear || +value === 0) {
        value = minYear;
      } else if (+value > maxYearCheck) {
        value = maxYearCheck;
      } else {
        // do nothing
      }

      if (+value < +this.props.premiumOffsetYearFrom) {
        value = +this.props.premiumOffsetYearFrom;
      }

      this.onBlur(eField.premiumOffsetYearTo, value);
      this.forceUpdate();
    };

    if (isParInforce && !this.getPremiumOffsetInforce()) {
      premiumOffsetsInforce.shift();
    }

    return (
      <div className="p-col-12 mwi-margin-top24">
        <MWIAccordion
          className={`mwi-reverse-margin15 mwi-lightGreen`}
          title={<FormattedMessage id={titleLabel} />}
          folded={isPremiumOffsetFolded}
          onClickChange={() =>
            this.dispatchAction(eField.isPremiumOffsetFolded, !isPremiumOffsetFolded, 'scenario', false, false)
          }
        >
          {premiumFrequency && [eProduct.PG, eProduct.Performax, eProduct.SB].includes(this.props.product) && (
            <div className="p-grid p-grid-no-margin-all p-col-12 mwi-margin-top24 mwi-margin-bottom24 premium-offset">
              <MWIDropdown
                name="premiumFrequency"
                label={<FormattedMessage id="payments.frequency" />}
                value={premiumFreq}
                options={premiumFrequency}
                disabled={[eProduct.PG, eProduct.Performax, eProduct.SB].includes(this.props.product)}
                onChange={(e) => this.onDropdownChange(e.target.value)}
                containerStyle={`space-right p-col-6 p-sm-6`}
                role="premium-offset-dropdown"
              />
            </div>
          )}

          {isParInforce && (
            <div className="p-grid p-grid-no-margin-all p-col-12 mwi-margin-top24 mwi-margin-bottom24 premium-offset">
              <MWIDropdown
                name="premiumOffset"
                label={<FormattedMessage id={premiumOffsetLabel} />}
                value={premiumOffset}
                options={premiumOffsetsInforce}
                onChange={(e) => this.onDropdownChange(e.target.value)}
                containerStyle={`space-right p-col-6 p-sm-6 p-xl-5 p-lg-5`}
                disabled={this.shouldParInforcePremiumOffsetDisable()}
                role="premium-offset-dropdown"
              />
              {premiumOffset === ePremiumOffset.startingatyear && this.renderStartingAtYear(maxYearCheck, minYear)}
            </div>
          )}

          {!isParInforce && (
            <div className="p-grid p-grid-no-margin-all p-col-12 mwi-margin-top24 mwi-margin-bottom24 premium-offset">
              {premiumFrequency && [eProduct.PG, eProduct.Performax, eProduct.SB].includes(this.props.product) && (
                <div className={'p-col-3 planned-base-label'}>
                  <FormattedMessage id="payments.plannedbaselabel" />
                </div>
              )}
              <MWIDropdown
                name="premiumOffset"
                label={<FormattedMessage id={premiumOffsetLabel} />}
                value={premiumOffset}
                options={premiumOffsets}
                onChange={(e) => this.onDropdownChange(e.target.value)}
                containerStyle={`space-right p-col-6 p-sm-6 p-xl-5 p-lg-5`}
                role="premium-offset-dropdown"
                disabled={this.shouldPmaxSbPremiumOffsetDisable()}
              />

              {premiumOffset === ePremiumOffset.startingatyear && this.renderStartingAtYear(maxYearCheck, minYear)}

              {[eProduct.Performax, eProduct.SB].includes(product) &&
                premiumOffset === ePremiumOffset.premiumholiday && (
                  <React.Fragment>
                    <div
                      className={`p-col-4 p-lg-3 p-xl-2 pmax-payments-from-year-wrapper`}
                      role="pmax-payments-from-year-wrapper"
                    >
                      <div className="mwi-label">
                        <span>
                          <FormattedMessage id="payments.fromyear" />
                        </span>
                      </div>
                      <div className={`p-col-6`} role="number-format">
                        <NumberFormat
                          className={`mwi-widget mwi-input number-data `}
                          value={
                            this.props.premiumOffsetYearFrom === minYear
                              ? minYear
                              : this.handleYearChangeProps(+this.props.premiumOffsetYearFrom, minYear)
                          }
                          decimalScale={0}
                          allowNegative={false}
                          isAllowed={(values) => +values.value <= maxYearCheck}
                          onBlur={(e) => handleYearChangeFrom(e.target.value)}
                          aria-labelledby="paymentsFromYear"
                          aria-required="true"
                          autoComplete="off"
                          onFocus={handleFocus}
                        />
                      </div>
                    </div>
                    <div
                      className={`p-col-4 p-lg-3 p-xl-2 pmax-payments-to-year-wrapper`}
                      role="pmax-payments-to-year-wrapper"
                    >
                      <div className="mwi-label">
                        <span>
                          <FormattedMessage id="payments.toyear" />
                        </span>
                      </div>
                      <div className={`p-col-6`} role="number-format">
                        <NumberFormat
                          className={`mwi-widget mwi-input number-data `}
                          value={
                            this.props.premiumOffsetYearTo === minYear
                              ? minYear
                              : this.handleYearChangeProps(+this.props.premiumOffsetYearTo, minYear)
                          }
                          decimalScale={0}
                          allowNegative={false}
                          isAllowed={(values) =>
                            +values.value <= maxYearCheck || +values.value <= this.props.premiumOffsetYear
                          }
                          onBlur={(e) => handleYearChangeTo(e.target.value)}
                          aria-labelledby="paymentsToYear"
                          aria-required="true"
                          autoComplete="off"
                          onFocus={handleFocus}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
            </div>
          )}
        </MWIAccordion>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];

  return {
    locale: app.locale,
    premiumFreq: activeScenario.premiumFrequency,
    premiumOffset: activeScenario.premiumOffset,
    premiumOffsetYear: activeScenario.premiumOffsetYear,
    premiumOffsetYearFrom: activeScenario.premiumOffsetYearFrom,
    premiumOffsetYearTo: activeScenario.premiumOffsetYearTo,
    isPremiumOffsetFolded: activeScenario.isPremiumOffsetFolded,
    premiumDuration: coverageState[coverageState.activeTabId].premiumDuration,
    isCoverageSolve: coverageState[coverageState.activeTabId].isCoverageSolve,
    dividendOption: coverageState[coverageState.activeTabId].dividendOption,
    effectiveDate: activeScenario.effectiveDate,
    userType: app.userType,
    app: app,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchMWIUpdateErrorAppAction: bindActionCreators(dispatchMWIUpdateError, dispatch),
  dispatchMWIOnChangeAppAction: bindActionCreators(dispatchMWIOnChange, dispatch),
  toggleOutOfDateSceAction: bindActionCreators(toggleOutOfDate, dispatch),
  performManualRefresh: bindActionCreators(performManualRefresh, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PremiumOffset));
