import React from 'react';
import CopyLinkToClipboard from './CopyLinkToClipboard';
import messageEN from '../core/translations/en';
import messageFR from '../core/translations/fr';
import './unsupported-browser.css';

const messages = { en: messageEN, fr: messageFR };
const getLocalData = (id) => messages.en[id];

const IENotSupportedEn = () => (
  <div>
    <div className="browser-warning">
      <p>
        <i className="material-icons msg-icon cancel-icon">cancel</i>
        <span className="cancel-margin ">Your browser is not supported.</span>
      </p>
    </div>
    <div>
      <div>
        <p className="to-use-manulife">
          To use Manulife Illustrations, copy and paste the URL below into{' '}
          <span className="browser-text">Google Chrome</span> or <span className="browser-text">Microsoft Edge</span>.
        </p>
      </div>
    </div>
    <div>
      <CopyLinkToClipboard
        copyText={getLocalData('common.copytext')}
        linkCopied={getLocalData('common.linkcopied')}
        illustrationsLink={getLocalData('common.illustrations.link')}
      />
    </div>
    <div className="download-chrome-edge">
      <p>
        Download{' '}
        <a href={getLocalData('landing.googlechrome.link')} target="_blank" rel="noopener noreferrer">
          Google Chrome
        </a>{' '}
        or{' '}
        <a href={getLocalData('landing.msedge.link')} target="_blank" rel="noopener noreferrer">
          Microsoft Edge
        </a>
        .
      </p>
    </div>
    <div className="learn-more">
      <p>
        <a href={getLocalData('landing.ie.learnmore.link')} target="_blank" rel="noopener noreferrer">
          Learn more
        </a>{' '}
        about changing your default browser.
      </p>
    </div>
  </div>
);

export default IENotSupportedEn;
