import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { eDefaultRatings, eRatingTemplate } from '../../../core/configs';

import MWIButton from '../mwi-button';
import Ratings from '../../../custom-mode/ratings';
import PayorRatings from '../../../custom-mode/ratings/payorRating';
import './ratings-button.css';

export class RatingsButton extends Component {
  static propTypes = {
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    ratings: PropTypes.object,
    partyId: PropTypes.string,
    intl: PropTypes.object,
    ratingTemplate: PropTypes.string,
    product: PropTypes.string,

    disabled: PropTypes.bool,
    containerStyle: PropTypes.string,
    buttonStyle: PropTypes.string,
    onSave: PropTypes.func,
  };

  static defaultProps = {
    ratingTemplate: eRatingTemplate.default,
  };

  constructor(props) {
    super(props);

    this.state = {
      showRating: false,
    };

    this.saveRatings = this.saveRatings.bind(this);
    this.hideRatings = this.hideRatings.bind(this);
    this.openRatings = this.openRatings.bind(this);
    this.RaitingBtnRef = React.createRef();
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test') {
      ReactModal.setAppElement('#mwi-root');
    }
  }

  saveRatings(ratings) {
    this.props.onSave(ratings);
    this.hideRatings();
  }

  hideRatings() {
    const ratingBtnFocusDuration = 100;
    this.setState({ showRating: false });
    //Fix iPad focus lost
    setTimeout(this.setRaitingBtnFocus, ratingBtnFocusDuration, this.RaitingBtnRef);
  }

  openRatings() {
    this.setState({ showRating: true });
  }

  getRatings() {
    const ratings = this.props.ratings[this.props.partyId];
    return ratings ? ratings : eDefaultRatings;
  }

  setRaitingBtnFocus(RaitingBtnRef) {
    RaitingBtnRef.current.firstChild.focus();
  }

  render = () => {
    const currentRatingData = this.getRatings();
    const { ratingTemplate } = this.props;

    return (
      <div className={`p-col-2 p-col-align-end ${this.props.containerStyle}`} ref={this.RaitingBtnRef}>
        <MWIButton
          label={this.props.intl.formatMessage({ id: 'common.ratings' })}
          styleClass={`edit-rating ${this.props.buttonStyle}`}
          disabled={this.props.disabled}
          onClick={this.openRatings}
        />

        {/* Show The Raiting Model acoording to Template Variable, Default empty */}

        <ReactModal
          className={`${ratingTemplate} ratings-modal-content`}
          contentLabel={this.props.intl.formatMessage({ id: 'rating.title' })}
          isOpen={this.state.showRating}
          shouldCloseOnOverlayClick={false}
          onRequestClose={this.hideRatings}
          role="dialog"
        >
          {ratingTemplate === eRatingTemplate.payor && (
            <PayorRatings
              save={this.saveRatings}
              cancel={this.hideRatings}
              locale={this.props.locale}
              inforce={this.props.inforce}
              {...currentRatingData}
            />
          )}
          {ratingTemplate === eRatingTemplate.default && (
            <Ratings
              save={this.saveRatings}
              cancel={this.hideRatings}
              locale={this.props.locale}
              inforce={this.props.inforce}
              product={this.props.product}
              {...currentRatingData}
            />
          )}
        </ReactModal>
      </div>
    );
  };
}

export default injectIntl(RatingsButton);
