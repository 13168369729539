import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ePremiumFrequency, eField } from '../../../core/configs';
import MWISelect from '../../../core/components/mwi-select';
import MWIDropdown from '../mwi-dropdown';
import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import _ from 'lodash';
import './premium-frequency.css';
import { dropDownOptions } from '../../dropdown-config';

export class PremiumFrequency extends PureComponent {
  static propTypes = {
    premiumFrequency: PropTypes.string,
    intl: PropTypes.object,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    isDepositOptionCustom: PropTypes.bool,
    depositOptionPaymentCache: PropTypes.object,
    inforce: PropTypes.bool,
  };

  dispatchAction(field, value, target = 'scenario', isError = false, refresh = true) {
    const { appActions, sceActions } = this.props;

    appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
    });
    if (refresh) {
      sceActions.toggleOutOfDate(true);
    }
    sceActions.performManualRefresh(true);
  }

  setDepositOptionValues(frequency) {
    // if the user changes frequency, set the depositOptionCustomAmount to be built from the values in the
    // depositOptionCache (see spreadsheet's buildCustomStatuses), we use the user entered values to send to the calc service
    // if frequency is monthly, means user is switching from annual to monthly and depositOption is custom divide the values in the cache by 12
    // rounded down to 2 decimal places. This is to account for the calc servic which multiplies the value by 12.
    if (this.props.premiumFrequency === frequency) {
      return;
    }
    const { isDepositOptionCustom, depositOptionPaymentCache } = this.props;
    const customAmount = [];
    const monthInYear = 12;
    const divisorHundred = 100;
    if (isDepositOptionCustom && !_.isEmpty(depositOptionPaymentCache)) {
      for (const year in depositOptionPaymentCache) {
        if (Object.prototype.hasOwnProperty.call(depositOptionPaymentCache, year)) {
          const value =
            frequency === ePremiumFrequency.monthly
              ? Math.floor((depositOptionPaymentCache[year] / monthInYear) * divisorHundred) / divisorHundred
              : depositOptionPaymentCache[year];
          customAmount.push(`${year}:${value}`);
        }
      }
      this.dispatchAction(eField.depositOptionCustomAmount, customAmount, 'scenario', false, false);
    }
  }
  onChange(value) {
    // click the same button, don't make the calc service
    if (this.props.premiumFrequency === value) {
      return;
    }
    this.dispatchAction(eField.premiumFrequency, value);
    this.setDepositOptionValues(value);
  }

  render() {
    const { intl, premiumFrequency } = this.props;
    return this.props.inforce ? (
      <MWIDropdown
        name="paymentFrequency"
        label={<FormattedMessage id="payments.premium" />}
        value={premiumFrequency}
        options={dropDownOptions(intl).premiumFrequencyAll}
        disabled
        containerStyle={`p-col-12`}
        role="premium-offset-dropdown"
      />
    ) : (
      <div className="p-col-12">
        <div className="mwi-label" id="paymentFrequency">
          <span>
            <FormattedMessage id="payments.premium" />
          </span>
          {/* <span className="mwi-mandatory-ind" aria-hidden="true">
              *
            </span> */}
        </div>
        <div className="p-col-12">
          <MWISelect
            ariaDescribedBy="paymentFrequency"
            labelL={intl.formatMessage({ id: 'common.monthly' })}
            labelR={intl.formatMessage({ id: 'common.annually' })}
            onClickL={() => this.onChange(ePremiumFrequency.monthly)}
            onClickR={() => this.onChange(ePremiumFrequency.annually)}
            selectedL={ePremiumFrequency.monthly === premiumFrequency}
            selectedR={ePremiumFrequency.annually === premiumFrequency}
            disabled={this.props.inforce}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app, scenarioTabNavs }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  return {
    locale: app.locale,
    premiumFrequency: activeScenario.premiumFrequency,
    isDepositOptionCustom: activeScenario.isDepositOptionCustom,
    depositOptionPaymentCache: activeScenario.depositOptionPaymentCache,
    inforce: app.inforcePolicy,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PremiumFrequency));
