import React from 'react';
import PropTypes from 'prop-types';
import MWISROnly from '../mwi-sr-only';
import './mwi-link.css';
import { injectIntl } from 'react-intl';

export const MWILink = ({
  label,
  children,
  link,
  styleClass,
  disabled,
  tabIndex,
  openNewTab,
  intl,
  showIcon,
  srOnlyLabel,
  onClick,
}) => {
  return (
    <React.Fragment>
      <div tabIndex={tabIndex} className={styleClass} role="container-mwilink">
        <a
          href={link}
          onClick={onClick}
          target={openNewTab ? '_blank' : '_self'}
          rel="noopener noreferrer"
          disabled={disabled}
          className="link-underline"
        >
          {label}
          {srOnlyLabel && <MWISROnly label={srOnlyLabel} />}
          {children}
          {openNewTab && (
            <React.Fragment>
              {showIcon && (
                <i className="material-icons opens-in-new-tab" aria-hidden="true">
                  open_in_new
                </i>
              )}
              <MWISROnly label={intl.formatMessage({ id: 'sronly.newtab' })} />
            </React.Fragment>
          )}
        </a>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(MWILink);

MWILink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.string,
  disabled: PropTypes.bool,
  styleClass: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  openNewTab: PropTypes.bool,
  showIcon: PropTypes.bool,
  intl: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.object,
  srOnlyLabel: PropTypes.string,
};

MWILink.defaultProps = {
  disabled: false,
  styleClass: '',
  tabIndex: '',
  openNewTab: true,
  showIcon: false,
  srOnlyLabel: undefined,
};
