import { Footer } from '@manulife/mux';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { configs, JUNCTION, eLocale } from '../../configs';
import './footer.css';
import { Manulife, ManulifeFr } from '@manulife/mux-logos';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createShareCase } from '../../udm-mapper/sideEffect';
import { getSharedCaseModel } from '../share-illustration-model/share-illustration-functions';

export class AppFooter extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    locale: PropTypes.string,
    app: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const element = document.getElementById('app-footer-bar-a-feedback');
    if (element && element !== null) {
      element.onclick = () => {
        this.onFeedbackClick();
      };
    }
  }

  async onFeedbackClick() {
    const { intl, app } = this.props;
    const link = intl.formatMessage({ id: 'feedback.link' });

    if (!app?.model) {
      this.openFeedbackLink(link);
    } else {
      const caseModel = getSharedCaseModel(this.props);
      await this.saveSnapShot(caseModel, link);
    }
  }

  openFeedbackLink(link) {
    window.open(link);
  }

  async saveSnapShot(caseModel, link) {
    const response = await createShareCase(caseModel);
    if (response !== undefined && response !== null && response.isError) {
      this.openFeedbackLink(link);
    } else {
      link = `${link}&aid=${response?.items[0].guid}`;
      this.openFeedbackLink(link);
    }
  }

  render() {
    const { intl, locale } = this.props;
    const dateYear = new Date().getFullYear();

    const copyrightStringValue = intl.formatMessage({ id: 'footer.copyright' });

    const manulifeStringValue = intl.formatMessage({ id: 'footer.manulife' });
    const manulifeCaLink = intl.formatMessage({ id: 'footer.manulife.caLink' });

    const legalStringValue = intl.formatMessage({ id: 'footer.legal' });
    const legalLink = intl.formatMessage({ id: 'footer.legalLink' });

    const privacyStringValue = intl.formatMessage({ id: 'footer.privacy' });
    const privacyLink = intl.formatMessage({ id: 'footer.privacyLink' });

    const accessibilityStringValue = intl.formatMessage({ id: 'footer.accessibility' });
    const accessibilityLink = intl.formatMessage({ id: 'footer.accessibilityLink' });

    return (
      <div
        className={` ${
          location.pathname === JUNCTION + configs.routes.clientCoverage ? 'app-footer-small' : 'app-footer'
        }`}
        role="container-footer"
      >
        <Footer
          id="app-footer-bar"
          logo={
            locale === eLocale.en ? (
              // eslint-disable-next-line react/jsx-pascal-case
              <Manulife.REVERSE title="Manulife Logo" style={{ height: '20px' }} />
            ) : (
              // eslint-disable-next-line react/jsx-pascal-case
              <ManulifeFr.REVERSE title="Manuvie Logo" style={{ height: '20px' }} />
            )
          }
          variant="small"
          copyrightText={`© 1999-${dateYear} ${copyrightStringValue}`}
          copyrightYear={``}
          copyrightYearAria={`© 1999 - ${dateYear}`}
          items={[
            {
              ariaLabel: manulifeStringValue,
              href: manulifeCaLink,
              isExternal: true,
              id: 'manulifeCanada',
              label: manulifeStringValue,
              title: manulifeStringValue,
            },
            {
              ariaLabel: legalStringValue,
              href: legalLink,
              id: 'legal',
              isExternal: true,
              label: legalStringValue,
              title: legalStringValue,
            },
            {
              ariaLabel: privacyStringValue,
              href: privacyLink,
              id: 'privacyPolicy',
              isExternal: true,
              label: privacyStringValue,
              title: privacyStringValue,
            },
            {
              ariaLabel: accessibilityStringValue,
              href: accessibilityLink,
              id: 'accessibility',
              isExternal: true,
              label: accessibilityStringValue,
              title: accessibilityStringValue,
            }
          ]}
        />
      </div>
    );
  }
}

export const mapStateToProps = ({
  app,
  landingPage,
  scenarioTabNavs,
  coverageTabNavs,
  conceptTabNavs,
  clients,
  riders,
  termRiders,
  vitalityStatus,
  report,
  spreadsheet,
  advisorProfile,
  myCases,
  withdrawals,
  deposits,
  intl,
}) => {
  const curScenario = scenarioTabNavs.activeTabId;
  const model = {
    landingPage,
    scenarioTabNavs,
    coverageTabNavs,
    conceptTabNavs,
    clients,
    riders,
    termRiders,
    vitalityStatus,
    report,
    spreadsheet,
    advisorProfile,
    myCases,
    withdrawals,
    deposits,
  };
  return {
    app,
    model,
    intl,
    coverageTabNavs,
    advisorProfile,
    coverages: coverageTabNavs[curScenario],
    concepts: conceptTabNavs[curScenario],
    report: report[scenarioTabNavs.activeTabId],
    policyData: scenarioTabNavs.topBars[curScenario],
    clients: clients[curScenario],
    riders: riders[curScenario],
    termRiders: termRiders[curScenario],
    vitalityStatus: vitalityStatus[curScenario],
    withdrawal: withdrawals[curScenario],
    activeScenarioTabId: curScenario,
    deposits: deposits[curScenario],
  };
};

export default connect(mapStateToProps)(injectIntl(AppFooter));
