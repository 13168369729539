import {
  LOAD_SCENARIO_TABNAVS,
  ADD_SCENARIO_TABNAVS,
  REMOVE_SCENARIO_TABNAVS,
  CLICK_SCENARIO_TABNAVS,
  TOGGLE_OUT_OF_DATE,
  TOGGLE_FORCE_UPDATE,
  TOGGLE_MANUAL_REFRESH,
  TOGGLE_UDM,
  TOGGLE_SUMMARY,
  PERFORM_MANUAL_REFRESH,
  SHOW_WARNING,
  LOADING_UDM_RESPONSE,
  REQUEST_UDM_RESPONSE,
  LOADED_UDM_RESPONSE,
  ERROR_UDM_RESPONSE,
  TOGGLE_RIDERS_PANEL,
} from '../../core/actionTypes';
import { makeActionCreator } from '../../core/utils';
import { autoSaveCase } from '../../autoSaveCase';

export const showWarningMessage = makeActionCreator(SHOW_WARNING);
export const loadScenarioTabNavs = makeActionCreator(LOAD_SCENARIO_TABNAVS);
export const clickScenarioTabNavs = makeActionCreator(CLICK_SCENARIO_TABNAVS);
export const addScenarioTabNavs = makeActionCreator(ADD_SCENARIO_TABNAVS);
export const removeSenarioTabNavs = makeActionCreator(REMOVE_SCENARIO_TABNAVS);
export const toggleOutOfDate = autoSaveCase;
export const toggleOutOfDateAfterSave = makeActionCreator(TOGGLE_OUT_OF_DATE);
export const toggleForceUpdate = makeActionCreator(TOGGLE_FORCE_UPDATE);
export const toggleManualRefresh = makeActionCreator(TOGGLE_MANUAL_REFRESH);
export const toggleUDM = makeActionCreator(TOGGLE_UDM);
export const loadingUDMResponse = makeActionCreator(LOADING_UDM_RESPONSE);
export const requestUDMResponse = makeActionCreator(REQUEST_UDM_RESPONSE);
export const loadedUDMResponse = makeActionCreator(LOADED_UDM_RESPONSE);
export const errorUDMResponse = makeActionCreator(ERROR_UDM_RESPONSE);
export const toggleRidersPanel = makeActionCreator(TOGGLE_RIDERS_PANEL);
export const toggleSummary = makeActionCreator(TOGGLE_SUMMARY);
export const performManualRefresh = makeActionCreator(PERFORM_MANUAL_REFRESH);
