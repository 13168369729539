import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { eField, eSex } from '../../configs';
import MWISelect from '../mwi-select';

export class Sex extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    person: PropTypes.object,
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(type, value) {
    // selected button is clicked
    if (type === eField.sex && !value) {
      return false;
    }
    this.props.onChange(type, value);
    return true;
  }

  render = () => {
    const { intl, person } = this.props;

    return (
      <React.Fragment>
        <div className="p-col-12">
          <div className="mwi-label" id="sex">
            <span>
              <FormattedMessage id="party.sex" />
            </span>
          </div>
          {person && (
            <MWISelect
              containerStyle="p-col-12"
              ariaDescribedBy="personSex"
              labelL={intl.formatMessage({ id: 'common.male' })}
              labelR={intl.formatMessage({ id: 'common.female' })}
              onClickL={() => this.onChange('sex', eSex.male)}
              onClickR={() => this.onChange('sex', eSex.female)}
              selectedL={eSex.male === person.sex}
              selectedR={eSex.female === person.sex}
            />
          )}
        </div>
      </React.Fragment>
    );
  };
}

const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale
  };
};

export default connect(mapStateToProps)(injectIntl(Sex));
