import React from 'react';
import CopyLinkToClipboard from './CopyLinkToClipboard';
import messageEN from '../core/translations/en';
import messageFR from '../core/translations/fr';
import './unsupported-browser.css';

const messages = { en: messageEN, fr: messageFR };
const getLocalData = (id) => messages.fr[id];

const IENotSupportedFr = () => (
  <div>
    <div className="browser-warning">
      <p>
        <i className="material-icons msg-icon cancel-icon">cancel</i>
        Votre navigateur n’est pas pris en charge.
      </p>
    </div>
    <div>
      <div className="to-use-manulife">
        <p>
          Pour utiliser les Projets informatisés de Manuvie, copiez et collez l’adresse URL ci-après dans{' '}
          <span className="browser-text">Google Chrome</span> ou <span className="browser-text">Microsoft Edge</span>.{' '}
        </p>
      </div>
    </div>
    <div>
      <CopyLinkToClipboard
        copyText={getLocalData('common.copytext')}
        linkCopied={getLocalData('common.linkcopied')}
        illustrationsLink={getLocalData('common.illustrations.link')}
      />
    </div>
    <div className="download-chrome-edge">
      <p>
        Téléchargez{' '}
        <a href={getLocalData('landing.googlechrome.link')} target="_blank" rel="noopener noreferrer">
          Google Chrome
        </a>{' '}
        ou{' '}
        <a href={getLocalData('landing.msedge.link')} target="_blank" rel="noopener noreferrer">
          Microsoft Edge
        </a>
        .
      </p>
    </div>
    <div className="learn-more">
      <p className="end-of-page-margin">
        <a href={getLocalData('landing.ie.learnmore.link')} target="_blank" rel="noopener noreferrer">
          Apprenez-en
        </a>{' '}
        davantage sur le changement de votre navigateur par défaut.
      </p>
    </div>
  </div>
);

export default IENotSupportedFr;
