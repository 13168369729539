import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import MWIDropdown from '../../components/mwi-dropdown';
import MWISelect from '../../components/mwi-select';
import MWIButton from '../../components/mwi-button';
import MWINumberField from '../../components/mwi-calculated-number-field';
import SpecialQuote from '../special-quote';
import {
  eCoverageType,
  eField,
  eLocale,
  coverageConfig,
  switchYearConfig,
  eUserType,
  eDefault,
  eJuvenileAgeLimit,
  eDeathType,
  eCOIType,
  eInsuranceType,
  AccValuePerDeath,
  eSpecialQuote,
  eDepositType,
  eProduct,
  eActionEvent,
} from '../../../core/configs';
import { dropDownOptions } from '../../dropdown-config';
import { CoverageSettings } from '../../../custom-mode/coverage/coverage-settings';
import {
  isFeatureFlagSet,
  trackUserActions,
  setVitalityYes,
  setActiveVitalityStatus,
  setChangeToBronzeYes,
  setIncludeDepositOptionToYes,
  setCoverageSolveToYes,
} from '../../utils';
import * as LearnMore from '../../../core/learn-more-config';
import './universal-life-coverage-container.css';

import enMULLearnMore from '../../../../src/assets/images/mul-learn-more-en.png';
import frMULLearnMore from '../../../../src/assets/images/mul-learn-more-fr.png';
import LearnMoreTable from '../learn-more-table';
import { bindActionCreators } from 'redux';
import * as AppActions from '../../../core/actions';
import StatusDialog from '../../../core/components/status-dialog';
export class UniversalLifeCoverageContainer extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    product: PropTypes.string,
    intl: PropTypes.object,
    coverageTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }).isRequired
    ),
    toggleOutOfDate: PropTypes.func,
    coverage: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    userType: PropTypes.string,
    deposit: PropTypes.object,
    effectiveDate: PropTypes.string,
    illustrationDate: PropTypes.string,
    coverageType: PropTypes.string,
    deathBenefitType: PropTypes.string,
    costOfInsurance: PropTypes.string,
    isVitalityPlusSelected: PropTypes.bool,
    appActions: PropTypes.object,
    showStatusDialog: PropTypes.bool,
    statusMessage: PropTypes.string,
    contentLabel: PropTypes.string,
    activeVitalityStatus: PropTypes.string,
    inforce: PropTypes.bool,
    activeScenario: PropTypes.object,
  };

  constructor() {
    super();
    this.age100 = 100;
    this.age85 = 85;
  }

  componentDidUpdate(prevProps) {
    // make a call to the calc service if the min client age is updated from juvenile (< 16) to non juvenile (>= 16)
    // to force the update of the coverage amount. because of the rule for calculating
    // coverage amount minimum value: see coverageAmountMin
    if (
      prevProps.coverage.coverageAmount < eDefault.coverageAmount &&
      prevProps.coverage.minClientAge < eJuvenileAgeLimit &&
      this.props.coverage.minClientAge >= eJuvenileAgeLimit
    ) {
      this.props.toggleOutOfDate(true);
    }
  }

  renderCoverageType = () => {
    const {
      coverageTypes,
      deposit,
      coverage: { coverageType, deathBenefitType, costOfInsurance, isVitalityPlusSelected },
      product,
      coverage,
      activeVitalityStatus,
      activeScenario,
    } = this.props;

    return (
      <MWIDropdown
        name="coverageType"
        label={<FormattedMessage id="coverage.type" />}
        value={coverageType}
        options={coverageTypes}
        onChange={(e) => {
          if (e.target.value === eCoverageType.single) {
            if (deathBenefitType === eDeathType.accValEachDeath) {
              this.props.onChange(eField.deathBenefitType, eDeathType.facePlus);
            }
            costOfInsurance === eCOIType.levelToAge100 &&
              !deposit.isDurationSet &&
              this.props.onChange(eField.durationTo, this.age100, this.age100, 'deposit'); // fix check on YRT
            costOfInsurance !== eCOIType.levelToAge100 &&
              !deposit.isDurationSet &&
              this.props.onChange(eField.durationTo, this.age85, this.age85, 'deposit'); // fix check on YRT
          } else {
            !deposit.isDurationSet && this.props.onChange(eField.durationTo, null, null, 'deposit');
          }

          this.props.onChange(eField.covType, e.target.value, coverageType);
          trackUserActions(eActionEvent.selection, e.target.type, e.target.value, '', {
            product,
            coverageType: e.target.value,
            coverageOption: costOfInsurance,
            vitality: setVitalityYes(coverage),
            vitalityStatus: setActiveVitalityStatus(coverage.isVitalityPlusSelected, activeVitalityStatus),
            changeToBronze: setChangeToBronzeYes(
              product,
              coverage.isChangeToBronze,
              coverage.isVitalityPlusSelected,
              activeVitalityStatus
            ),
            coverageSolve: setCoverageSolveToYes(coverage),
            includeDepositOption: setIncludeDepositOptionToYes(activeScenario, coverage),
          });
        }}
        containerStyle={`space-right p-col-12 p-sm-12 p-md-10 p-lg-6 p-xl-5`}
        disabled={isVitalityPlusSelected || this.props.inforce}
      />
    );
  };

  deathBenefitTypeHelper = (deathType) => {
    const {
      deposit,
      coverage: { coverageType, costOfInsurance },
    } = this.props;
    if (!deposit.isDurationSet && coverageType === eCoverageType.single) {
      if (deathType === eDeathType.level && costOfInsurance === eCOIType.levelToAge100) {
        this.props.onChange(eField.durationTo, this.age85, this.age85, 'deposit');
      } else {
        if (deathType === eDeathType.accValEachDeath && costOfInsurance === eCOIType.yrtToAge85) {
          this.props.onChange(eField.durationTo, this.age100, this.age100, 'deposit');
        }
      }
    } else {
      if (!deposit.isDurationSet) {
        this.props.onChange(eField.durationTo, null, null, 'deposit');
      }
    }
  };

  renderDeathBenefitType = () => {
    const {
      intl,
      locale,
      coverage: { deathBenefitType, coverageType, costOfInsurance },
    } = this.props;
    const { deathTypes } = dropDownOptions(intl);

    const deathTypeOptions = (coverage) => {
      if (coverage === eCoverageType.single) {
        // Face Plus
        return deathTypes.filter((element) => element.value !== eDeathType.accValEachDeath);
      }
      return deathTypes;
    };

    return (
      <MWIDropdown
        name="deathBenefitType"
        label={<FormattedMessage id="coverage.mul.deathBenefitType" />}
        value={deathBenefitType}
        options={deathTypeOptions(coverageType)}
        disabled={this.props.inforce}
        onChange={(e) => {
          this.props.onChange(eField.deathBenefitType, e.target.value);
          if (e.target.value === eDeathType.level && costOfInsurance === eCOIType.levelToAge100) {
            // yrt85
            this.props.onChange(eField.costOfInsurance, eCOIType.yrtToAge85);
            this.deathBenefitTypeHelper(e.target.value);
          }
          if (e.target.value === eDeathType.accValEachDeath && costOfInsurance === eCOIType.yrtToAge85) {
            // level 100
            this.props.onChange(eField.costOfInsurance, eCOIType.levelToAge100);
            this.deathBenefitTypeHelper(e.target.value);
          }
        }}
        containerStyle="p-col-12 p-sm-12 p-md-10 p-lg-6 p-xl-4 space-right"
        helpText
        helpTextBanner="common.combinatiosAvailable"
        imageAdress={locale === eLocale.en ? enMULLearnMore : frMULLearnMore}
        intl={intl}
        locale={locale}
      />
    );
  };

  coiBasedOnDeathBenefit = (e, deathBenefitType) => {
    const checkLevelToAge100 = e.target.value === eCOIType.levelToAge100 && deathBenefitType === eDeathType.level;
    return (
      checkLevelToAge100 ||
      e.target.value === eCOIType.yrtToAge85 ||
      (eCOIType.yrt100 && deathBenefitType === eDeathType.accValEachDeath)
    );
  };
  getCOITypes = (product, inforce, inforceCoiTypes, coiTypes, ivCoiTypes) => {
    if (inforce) {
      return inforceCoiTypes;
    }
    return product === eProduct.IV ? ivCoiTypes : coiTypes;
  };

  renderCostOfInsurance = () => {
    const {
      intl,
      deposit,
      coverage: { costOfInsurance, deathBenefitType, coverageType },
      product,
      coverage,
      activeVitalityStatus,
      inforce,
      activeScenario,
    } = this.props;
    const { coiTypes, ivCoiTypes, inforceCoiTypes } = dropDownOptions(intl);

    const coiTypesOptions = this.getCOITypes(product, inforce, inforceCoiTypes, coiTypes, ivCoiTypes);

    return (
      <MWIDropdown
        name="costOfInsuranceType"
        label={<FormattedMessage id="coverage.mul.coiType" />}
        value={costOfInsurance}
        options={coiTypesOptions}
        disabled={inforce}
        onChange={(e) => {
          if (this.coiBasedOnDeathBenefit(e, deathBenefitType)) {
            this.props.onChange(eField.deathBenefitType, eDeathType.facePlus);
          }
          if (coverageType !== eCoverageType.single && !deposit.isDurationSet) {
            this.props.onChange(eField.durationTo, null, null, 'deposit');
          }
          if (coverageType === eCoverageType.single && !deposit.isDurationSet) {
            if (e.target.value === eCOIType.yrtToAge85) {
              this.props.onChange(eField.durationTo, this.age85, this.age85, 'deposit'); // fix check on YRT
            } else {
              this.props.onChange(eField.durationTo, this.age100, this.age100, 'deposit'); // fix check on YRT
            }
          }
          this.props.onChange(eField.costOfInsurance, e.target.value);
          this.props.onChange(eField.costOfInsuranceSwitchYear, false);

          trackUserActions(eActionEvent.selection, e.target.type, e.target.value, '', {
            product,
            coverageType,
            coverageOption: e.target.value,
            vitality: setVitalityYes(coverage),
            vitalityStatus: setActiveVitalityStatus(coverage.isVitalityPlusSelected, activeVitalityStatus),
            changeToBronze: setChangeToBronzeYes(
              product,
              coverage.isChangeToBronze,
              coverage.isVitalityPlusSelected,
              activeVitalityStatus
            ),
            coverageSolve: setCoverageSolveToYes(coverage),
            includeDepositOption: setIncludeDepositOptionToYes(activeScenario, coverage),
          });
        }}
        containerStyle={`p-col-12 p-sm-12 p-md-10 p-lg-6 p-xl-5 space-right`}
      />
    );
  };

  renderAmountOfInsuranceType = () => {
    const {
      intl,
      locale,
      product,
      coverage: { amountOfInsuranceType },
    } = this.props;
    const { amountOfInsuranceTypes, amountOfInsuranceIVTypes } = dropDownOptions(intl);

    return (
      <MWIDropdown
        name="InsuranceDropdown"
        label={<FormattedMessage id="coverage.par.coverageAmount" />}
        value={amountOfInsuranceType}
        options={product === eProduct.IV ? amountOfInsuranceIVTypes : amountOfInsuranceTypes}
        disabled={this.props.inforce}
        onChange={(e) => {
          this.props.onChange(eField.amountOfInsuranceType, e.target.value);
          if (e.target.value !== eInsuranceType.specified) {
            this.props.onChange(eField.depositType, eDepositType.specified, eDepositType.specified, 'deposit');
            this.props.onChange(eField.depositAdditionalDeposit, 0, 0, 'deposit');
            this.props.onChange(eField.isAdditionalDepositSelected, false, false, 'deposit');
          } else {
            this.props.onChange(eField.depositType, eDepositType.levelmin, eDepositType.levelmin, 'deposit');
          }
        }}
        helpText
        helpTextBanner="coverage.par.coverageAmount.learnmore"
        helpTextContent={
          <LearnMoreTable
            locale={locale}
            columnHeaders={LearnMore.coverageAmountLearnMoreHeader}
            tableMatrix={LearnMore.coverageAmountLearnMoreMatrix}
          />
        }
        helpTextContainer="helptext-size"
        containerStyle="p-col-4 p-lg-5 p-xl-4 p-sm-6 mwi-margin-right24"
      />
    );
  };

  renderCoverageAmount = () => {
    const {
      locale,
      coverage: { coverageAmount },
    } = this.props;

    return (
      <MWINumberField
        id={eField.coverageAmount}
        styleClass={`p-col-12 p-sm-9 p-md-4 p-lg-3 p-xl-2 mwi-margin-top24 mwi-margin-right14`}
        name={eField.coverageAmount}
        onBlur={this.props.onBlur}
        value={coverageAmount || 0}
        thousandSeparator={locale === eLocale.en ? ',' : ' '}
        prefix={locale === eLocale.en ? '$ ' : ''}
        suffix={locale === eLocale.fr ? ' $' : ''}
        minValue={coverageConfig.minAmount}
        maxValue={coverageConfig.maxAmount}
        locale={locale}
        disabled={this.props.inforce}
      />
    );
  };

  renderCOISwitch = () => {
    const {
      intl,
      coverage: { costOfInsuranceSwitchYear },
      inforce,
    } = this.props;

    if (!inforce) {
      return (
        <div className="p-col-12 p-sm-11 p-md-6 p-lg-4 p-xl-3">
          <div className="mwi-label" id="coiSwitch">
            <span>
              <FormattedMessage id="coverage.mul.coiSwitch" />
            </span>
          </div>
          <MWISelect
            name="costOfInsuranceSwitch"
            containerStyle="p-col-11"
            ariaDescribedBy="coiSelect"
            labelL={intl.formatMessage({ id: 'common.yes' })}
            labelR={intl.formatMessage({ id: 'common.no' })}
            onClickL={() => this.props.onChange(eField.costOfInsuranceSwitchYear, true)}
            onClickR={() => this.props.onChange(eField.costOfInsuranceSwitchYear, false)}
            selectedL={costOfInsuranceSwitchYear}
            selectedR={!costOfInsuranceSwitchYear}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  renderAtYear = () => {
    const {
      locale,
      coverage: { switchYear },
    } = this.props;

    return (
      <MWINumberField
        label={<FormattedMessage id="coverage.mul.coi.atYear" />}
        id={eField.switchYear}
        styleClass={`p-col-2`}
        name={eField.switchYear}
        onBlur={this.props.onBlur}
        value={switchYear || switchYearConfig.default}
        minValue={switchYearConfig.minAmount}
        maxValue={switchYearConfig.maxAmount}
        locale={locale}
      />
    );
  };

  renderCustomizeButton = () => {
    const { intl } = this.props;
    if (!this.props.inforce) {
      return (
        <MWIButton
          name={'CustomizeButton'}
          label={intl.formatMessage({ id: 'common.customize' })}
          styleClass={`p-col-12 p-sm-9 p-md-4 p-lg-3 p-xl-2 mwi-margin-right24 mwi-margin-top24`}
        />
      );
    } else {
      return null;
    }
  };

  renderSpecialQuote = () => {
    const {
      intl,
      coverage: { specialQuoteOption, costOfInsurance },
    } = this.props;
    const { specialQuoteOptionMUL } = dropDownOptions(intl);
    const specialQuoteOptions = (coiType) => {
      if (coiType === eCOIType.yrtToAge85) {
        return specialQuoteOptionMUL.filter((element) => element.value !== eSpecialQuote.S2);
      } else {
        // coiType is 100
        return specialQuoteOptionMUL;
      }
    };

    return (
      <SpecialQuote
        specialQuoteValue={specialQuoteOption}
        specialQuoteOptions={specialQuoteOptions(costOfInsurance)}
        onChange={this.props.onChange}
        inforce={this.props.inforce}
        containerStyle={`p-col-12 p-col-12 p-sm-8 p-md-5 p-lg-3 p-xl-2`}
        dropdownStyle=""
      />
    );
  };

  renderCOIAtYear = () => {
    const {
      coverage: { costOfInsuranceSwitchYear },
    } = this.props;

    return (
      <>
        {this.renderCOISwitch()}

        {costOfInsuranceSwitchYear && this.renderAtYear()}
      </>
    );
  };

  renderDeathBenefitPercentage = () => {
    const {
      locale,
      coverage: { coverageType, deathBenefitType, isCoverageSolve, deathBenefit },
    } = this.props;

    return (
      <>
        {deathBenefitType === eDeathType.accValEachDeath && coverageType === eCoverageType.jointLastPayLastDeath && (
          <MWINumberField
            name="deathBenefitPercentage"
            label={<FormattedMessage id="coverage.mul.AVDeathPercentage" />}
            onBlur={this.props.onBlur}
            value={deathBenefit}
            onValueChange={deathBenefit}
            id={eField.deathBenefit}
            disabled={isCoverageSolve || this.props.inforce}
            thousandSeparator={locale === eLocale.en ? ',' : ' '}
            suffix={' %'}
            minValue={AccValuePerDeath.minAmount}
            maxValue={AccValuePerDeath.maxAmount}
            locale={locale}
          />
        )}
      </>
    );
  };

  render = () => {
    const {
      locale,
      userType,
      coverage: { deathBenefitType, costOfInsurance, amountOfInsuranceType, coverageType },
      intl,
      product,
      effectiveDate,
      illustrationDate,
      inforce,
    } = this.props;

    return (
      <React.Fragment>
        <StatusDialog
          isOpen={this.props.showStatusDialog}
          statusMessage={this.props.statusMessage}
          contentLabel={this.props.contentLabel}
        />
        <div className="flexdisplay">
          {/* //* Product & effective date with change option */}
          <CoverageSettings
            intl={intl}
            product={product}
            locale={locale}
            effectiveDate={effectiveDate}
            illustrationDate={illustrationDate}
            userType={userType}
            isVitalityPlusDisable={coverageType === eCoverageType.jointLastPayLastDeath}
            inforce={inforce}
          />

          <div className="p-grid p-grid-no-margin-all p-col-12 mwi-margin-top18">
            {/* //* Coverage Type */}
            {this.renderCoverageType()}

            {/* Death Benefit Type  */}
            {this.renderDeathBenefitType()}

            {this.renderDeathBenefitPercentage()}
          </div>

          <div className="p-grid p-grid-no-margin-all p-col-12 mwi-margin-top18">
            {this.renderCostOfInsurance()}

            {!isFeatureFlagSet() &&
              costOfInsurance === eCOIType.yrtToAge85 &&
              deathBenefitType === eDeathType.facePlus && <>{this.renderCOIAtYear()}</>}
          </div>

          <div className="p-grid p-grid-no-margin-all p-col-12 mwi-margin-top18">
            {this.renderAmountOfInsuranceType()}

            {amountOfInsuranceType === eInsuranceType.specified && (
              <>
                {this.renderCoverageAmount()}

                {!isFeatureFlagSet() && product !== eProduct.IV && this.renderCustomizeButton()}
              </>
            )}

            {!isFeatureFlagSet() &&
              product !== eProduct.IV &&
              userType === eUserType.headOffice &&
              this.renderSpecialQuote()}
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export const mapStateToProps = ({ app, scenarioTabNavs, deposits, vitalityStatus }) => {
  const activeScenarioTabId = scenarioTabNavs.activeTabId;
  const activeScenario = scenarioTabNavs.topBars[activeScenarioTabId];
  const deposit = deposits[activeScenarioTabId];
  const activeVitalityStatus = vitalityStatus[vitalityStatus.activeTabId][0].vitalityStatus;
  const illustrationDate = app.inforceData ? app.inforceData.illustrationDate : '';
  return {
    locale: app.locale,
    userType: app.userType,
    product: activeScenario.product,
    effectiveDate: activeScenario.effectiveDate,
    showStatusDialog: app.statusDialog.show,
    statusMessage: app.statusDialog.message,
    contentLabel: app.statusDialog.label,
    inforce: app.inforcePolicy,
    activeScenario,
    deposit,
    illustrationDate,
    activeVitalityStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UniversalLifeCoverageContainer));
