import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const getSeconds = (seconds, tenSecs) => {
    if (seconds < tenSecs){
      return `0${seconds}`;
    } else {
      return seconds;
    }
  };

const checkTimeIsZero = (minutes, seconds) => minutes === 0 && seconds === 0;

const Timer = (props) => {
  Timer.propTypes = {
    seconds: PropTypes.number,
    setSeconds: PropTypes.func,
    minutes: PropTypes.number,
    setMinutes: PropTypes.func,
    displayTimer: PropTypes.bool,
  };

  const { seconds, setSeconds, minutes, setMinutes, displayTimer = true } = props;
  useEffect(() => {
    const delay = 1000;
    const secondsWait = 59;
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);

        } else {
          setMinutes(minutes - 1);
          setSeconds(secondsWait);
        }
      }
      if(checkTimeIsZero(minutes, seconds)){
        clearInterval(myInterval);
      }
    }, delay);
    return () => {
      clearInterval(myInterval);
    };
  });

  const tenSecs = 10;

  return displayTimer ? (
    <div>
      {checkTimeIsZero(minutes, seconds) && (
        <h1>
          {' '}
          {minutes}:{getSeconds(seconds, tenSecs)}
        </h1>
      )}
    </div>
  ) : null;
};

export default Timer;
