import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  eAdditionOptionDurationType,
  eAdditionOptionType,
  ePremiumFrequency,
  ePremiumOffset,
  eField,
  eWithdrawalType,
  eProduct,
} from '../../../core/configs';
import { getFormattedValue, getDuration } from '../../../core/utils';
import * as Utils from '../utils';

const getPremiumFrequencyLabel = (product, premiumFrequency, premiumOffset) => {
  if (premiumOffset === ePremiumOffset.inforce) {
    return <></>;
  }
  switch (premiumFrequency) {
    case ePremiumFrequency.annually:
      return <FormattedMessage id="summary.depositoption.annually" />;
    case ePremiumFrequency.semiannually:
      return <FormattedMessage id="common.semiannually" />;
    case ePremiumFrequency.quarterly:
      return <FormattedMessage id="common.quarterly" />;
    case ePremiumFrequency.monthly:
      return <FormattedMessage id="common.monthly" />;
    default:
      return <></>;
  }
};

const getPremiumOffsetLabel = (
  product,
  premiumOffset,
  premiumOffsetYear,
  intl,
  app,
  outOfPocketDuration,
  displayPremiumOffset = true
) => {
  const getPremiumOffsetIndicator = () => {
    if (product === eProduct.PG) {
      return intl.formatMessage({ id: 'premiumoffset.inforce' });
    } else {
      return intl.formatMessage({ id: 'premiumoffset.onpremiumoffsetindicator' });
    }
  };

  const sameAsBaseMessage = intl.formatMessage({ id: 'summary.premiumfrequency.sameasbase' });

  switch (premiumOffset) {
    case ePremiumOffset.sameaspolicy:
    case ePremiumOffset.paymentcoverpolicycosts:
    case ePremiumOffset.premiumholiday:
      return ', ' + sameAsBaseMessage;
    case ePremiumOffset.earliest:
      return ' ' + intl.formatMessage({ id: 'summary.premiumfrequency.earliest' }).replace('XX', outOfPocketDuration);
    case ePremiumOffset.startingatyear:
      return ' ' + intl.formatMessage({ id: 'summary.premiumfrequency.startingat' }).replace('XX', outOfPocketDuration);
    case ePremiumOffset.inforce:
      return displayPremiumOffset ? getPremiumOffsetIndicator() : '';
    default:
      return '';
  }
};

const additionalPaymentLabel = (
  product,
  premiumOffset,
  premiumOffsetYear,
  additionalPaymentType,
  additionalPaymentDuration,
  additionalDurationYears,
  intl,
  app,
  outOfPocketDuration
) => {
  if (
    ((Utils.isPerformax(product) && additionalPaymentType === eAdditionOptionType.specified)||
    (Utils.isSB(product) && premiumOffset === ePremiumOffset.startingatyear ) )&&
      additionalPaymentDuration === eAdditionOptionDurationType.sameasbase
  ) {
    return ', ' + intl.formatMessage({ id: 'summary.premiumfrequency.sameasbase' });
  } else if (
    ((additionalPaymentType === eAdditionOptionType.specified ||
      additionalPaymentType === eAdditionOptionType.levelmax) &&
      (((Utils.isPerformax(product) || Utils.isSB(product)) && premiumOffset === ePremiumOffset.earliest) ||
        additionalPaymentDuration === eAdditionOptionDurationType.sameasbase)) ||
    premiumOffset === ePremiumOffset.premiumholiday
  ) {
    return getPremiumOffsetLabel(product, premiumOffset, premiumOffsetYear, intl, app, outOfPocketDuration, false);
  } else if (
    (premiumOffset === ePremiumOffset.startingatyear ||
      premiumOffset === ePremiumOffset.sameaspolicy ||
      premiumOffset === ePremiumOffset.paymentcoverpolicycosts) &&
    (additionalPaymentType === eAdditionOptionType.specified || additionalPaymentType === eAdditionOptionType.levelmax)
  ) {
    return (
      ' ' + intl.formatMessage({ id: 'summary.premiumfrequency.startingat' }).replace('XX', additionalDurationYears)
    );
  } else {
    return '';
  }
};

export const PGoldPaymentSection = (props) => {
  const {
    premiumFrequency,
    premiumOffset,
    premiumOffsetYear,
    additionOptionType,
    depositOptionDurationType,
    depositOptionDuration,
    depositOptionAmount,
    withdrawals,
    intl,
    locale,
    initialModalPremium,
    depositOption,
    product,
    app,
    outOfPocketDuration,
  } = props;

  return (
    <>
      {/* planned base payment*/}
      <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
        <FormattedMessage id="summary.pgold.plannedBasePayment" />
      </div>
      <div
        className="p-grid p-grid-no-margin label no-margin refresh mwi-padding-bottom12"
        data-testid="container-plannedbasepayment"
      >
        <div className="p-col-8 label">
          {getPremiumFrequencyLabel(product, premiumFrequency, premiumOffset)}
          {getPremiumOffsetLabel(
            product,
            premiumOffset,
            premiumOffsetYear,
            intl,
            app,
            outOfPocketDuration,
            premiumFrequency === ePremiumFrequency.annually
          )}
        </div>
        {premiumOffset !== ePremiumOffset.inforce && (
          <div className="p-col-4 amount">
            <div id="plannedBasePaymentAmount">{initialModalPremium}</div>
          </div>
        )}
      </div>

      {/* additional payment*/}
      {premiumOffset !== ePremiumOffset.inforce && (
        <>
          <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
            <FormattedMessage id="summary.pgold.additionalPayments" />
          </div>
          <div
            className="p-grid p-grid-no-margin label no-margin refresh mwi-padding-bottom12"
            data-testid="container-additionalpayment"
          >
            <div className="p-col-8 label">
              {getPremiumFrequencyLabel(product, premiumFrequency, premiumOffset)}
              {additionalPaymentLabel(
                product,
                premiumOffset,
                premiumOffsetYear,
                additionOptionType,
                depositOptionDurationType,
                depositOptionDuration,
                intl,
                app,
                outOfPocketDuration
              )}
            </div>
            <div className="p-col-4 amount">
              <div id="additionalPaymentAmount">{getFormattedValue(locale, depositOptionAmount || 0)}</div>
            </div>
          </div>
        </>
      )}

      {/* {PGold Payment offset} */}
      {Utils.isPGold(product) &&
        premiumOffset !== ePremiumOffset.sameaspolicy &&
        premiumOffset !== ePremiumOffset.inforce && (
          <>
            <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
              <span role="premiumoffset">{<FormattedMessage id="summary.premiumoffset.PG" />}</span>
            </div>
            <div className="p-grid p-grid-no-margin mwi-padding-bottom12" data-testid="container-paymentoffsetyears">
              <div className="p-col-12 label">
                <span role="primary">
                  {intl.formatMessage({ id: 'summary.premiumoffsetyear.primary' }).replace('XX', premiumOffsetYear)}
                </span>
              </div>
            </div>
          </>
        )}
      {/* {PMax Premium offset} */}
      {!Utils.isPGold(product) && [ePremiumOffset.startingatyear, ePremiumOffset.earliest].includes(premiumOffset) && (
        <>
          <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
            <span role="premiumoffset">
              <FormattedMessage id="summary.premiumoffset.PMax" />
            </span>
          </div>
          <div className="p-grid p-grid-no-margin mwi-padding-bottom12" data-testid="container-paymentoffsetyears">
            <div className="p-col-12 label">
              <span role="primary">
                {intl.formatMessage({ id: 'summary.premiumoffsetyear.primary' }).replace('XX', premiumOffsetYear)}
              </span>
            </div>
          </div>
        </>
      )}

      {/* Additional 1st year deposit */}
      {depositOption.isAdditionalDepositSelected && (
        <div className="p-grid p-grid-no-margin label no-margin refresh mwi-padding-bottom12">
          <div className="p-col-8">
            <div id="additionalDeposit">
              <FormattedMessage id="deposit.additional.deposit.inforce.PG" />
            </div>
          </div>
          <div className="p-col-4 amount">
            <div id="depositFrequencyAmount">{getFormattedValue(locale, depositOption.additionalDeposit || '0')}</div>
          </div>
        </div>
      )}

      {/* withdrawals*/}
      {withdrawals[eField.withdrawalTypeOption] !== eWithdrawalType.none && (
        <span id="summary_payment_withdrawal">
          <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
            <span role="withdrawal">
              <FormattedMessage id="summary.withdrawal" />
            </span>
          </div>
          <div className="p-grid p-grid-no-margin mwi-padding-bottom12">
            <div className="p-col-8 label">
              <span role="withdrawalamount">
                {getFormattedValue(locale, withdrawals[eField.withdrawalAmountOption], 0)}{' '}
              </span>
              <span role="withdrawalfor">
                <FormattedMessage id="summary.withdrawal.for" />
              </span>
              {getDuration(withdrawals[eField.durationFrom], withdrawals[eField.durationTo])}{' '}
              <span role="withdrawalyears">
                <FormattedMessage id="common.years" />
              </span>
            </div>
          </div>
        </span>
      )}
    </>
  );
};

PGoldPaymentSection.propTypes = {
  depositOption: PropTypes.object,
  withdrawals: PropTypes.object,
  intl: PropTypes.object,
  product: PropTypes.string,
  premiumFrequency: PropTypes.string,
  premiumOffset: PropTypes.string,
  premiumOffsetYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionOptionType: PropTypes.string,
  depositOptionDurationType: PropTypes.string,
  locale: PropTypes.string,
  depositOptionDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  depositOptionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialModalPremium: PropTypes.string,
  app: PropTypes.object,
  outOfPocketDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default injectIntl(PGoldPaymentSection);
