import * as actionTypes from '../../core/actionTypes';
import {
  eDurationType,
  eField,
  eCoverageType,
  eProduct,
  eDepositOwnerResidence,
  eDepositType,
  eDepositFrequency,
  eBusinessType,
} from '../../core/configs';
import { assignDef, getClientAgeFromUdm } from '../../core/utils';
import {
  getAgeFromValue,
  getAgeToValue,
  getAgeToValueBasedOnCoi,
  getYearFromValue,
  getYearToValue,
} from './duration-logic';
import { scenarioSeq } from '../../core/sequenceUtils';
import * as reducerHelper from '../../helper/reducer-helper';

const defaultState = {
  [eField.duration]: eDurationType.age,
  [eField.durationFrom]: '',
  [eField.durationTo]: '',
  [eField.basedonClient]: 'Client.1',
  [eField.depositOwnerResidence]: eDepositOwnerResidence.select,
  [eField.depositType]: eDepositType.levelmin,
  [eField.depositFrequency]: eDepositFrequency.annual,
  [eField.depositAmount]: 0.0,
  [eField.depositAdditionalDeposit]: 0.0,
  isFolded: false,
  isAdditionalDepositSelected: false,
  isDurationSet: false,
  [eField.selectClicked]: false,
  inforce: false,
};

const initialState = {
  activeTabId: scenarioSeq(true),
  [scenarioSeq(true)]: {
    ...defaultState,
  },
  inforce: false,
};

const openSavedCase = (action) => {
  const { scenarioTabNavs, deposits } = action.payload.model;
  // This logic to assign all the missing properties from the initial/default
  // to the saved old object with default value.
  const targetState = deposits;

  if (!targetState) {
    let newState = {
      activeTabId: scenarioSeq(true),
    };
    scenarioTabNavs.tabNavs.forEach((Scenario) => {
      newState = {
        ...newState,
        [Scenario.id]: {
          ...defaultState,
          isDurationSet: true,
        },
      };
    });
    return {
      ...newState,
    };
  }

  assignDef(targetState, initialState);
  scenarioTabNavs.tabNavs.forEach((Scenario) => {
    assignDef(targetState[Scenario.id], defaultState);
  });

  return {
    ...targetState,
  };
};

const durationFromToAgeValue = (udmPolicy, illustration, state, scenarioTabId) => {
  const activeUDMCoverage = udmPolicy.coverage[0];
  const equivalentAge = activeUDMCoverage.equivalentAge;
  const clientAge = getClientAgeFromUdm(illustration, state, scenarioTabId);

  const durationFrom = getAgeFromValue(activeUDMCoverage.coverageType, equivalentAge, clientAge, clientAge + 1);
  const durationTo = getAgeToValue(
    activeUDMCoverage.coverageType,
    equivalentAge,
    clientAge,
    state[scenarioTabId][eField.durationTo],
    1
  );
  return { durationFrom, durationTo };
};

const durationFromYearValue = (udmPolicy, state, scenarioTabId) => {
  const duration =
    udmPolicy.product === eProduct.MUL ? udmPolicy.depositDuration || udmPolicy.duration : udmPolicy.duration;

  const durationFrom = getYearFromValue(duration, state[scenarioTabId][eField.durationFrom]);
  const durationTo = getYearToValue(duration, state[scenarioTabId][eField.durationTo], durationFrom);
  return { durationFrom, durationTo };
};

const loadedUdmResponse = (action, state) => {
  const { response, scenarioTabId } = action.payload;
  const { illustration } = response;
  const udmPolicy = response?.illustration?.policy;

  if (!udmPolicy || !udmPolicy.coverage) {
    return { ...state };
  }
  // Calc logic
  if (
    (udmPolicy.product === eProduct.PAR || udmPolicy.product === eProduct.MUL) &&
    (state[scenarioTabId][eField.durationFrom] || state[scenarioTabId][eField.durationTo])
  ) {
    if (state[scenarioTabId][eField.duration] === eDurationType.age) {
      const { durationFrom, durationTo } = durationFromToAgeValue(udmPolicy, illustration, state, scenarioTabId);
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          [eField.durationFrom]: durationFrom,
          [eField.durationTo]: durationTo,
        },
      };
    } else {
      if (state[scenarioTabId][eField.duration] === eDurationType.year) {
        const { durationFrom, durationTo } = durationFromYearValue(udmPolicy, state, scenarioTabId);
        return {
          ...state,
          [scenarioTabId]: {
            ...state[scenarioTabId],
            [eField.durationFrom]: durationFrom,
            [eField.durationTo]: durationTo,
          },
        };
      }
    }
  }
  return { ...state };
};

const depositReducer = (state, action) => {
  state = state === undefined ? initialState : state;

  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      return { ...initialState };
    }
    case actionTypes.OPEN_SAVEDCASE: {
      return openSavedCase(action);
    }
    case actionTypes.ADD_SCENARIO_TABNAVS: {
      return reducerHelper.getAddScenarioTabNavsState(state, action);
    }
    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return reducerHelper.getClickScenarioTabNavsState(state, action);
    }
    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      return reducerHelper.getRemoveScenarioTabNavsState(state, action);
    }
    // Check and build logic for the value in case it changed without opening the component
    // This logic should apply only if the product is PAR &
    // there is a change in one of the fields that are required to do the UI Validation/Rules
    case actionTypes.LOADED_UDM_RESPONSE: {
      return loadedUdmResponse(action, state);
    }

    default:
      return depositReducerTwo(state, action);
  }
};

const inforceOwnerResidence = (payload) => {
  const illustration = payload.policyData.illustration;
  const party = illustration.party.find((prty) => prty.addressProvince);
  if (party) {
    return party.addressProvince;
  }
  return eDepositOwnerResidence.select;
};

const depositReducerTwo = (state, action) => {
  switch (action.type) {
    case actionTypes.MWI_ON_CHANGE: {
      const { target, scenarioTabId, field, value } = action.payload;
      if (target === 'deposit') {
        return {
          ...state,
          [scenarioTabId]: {
            ...state[scenarioTabId],
            [field]: value,
          },
        };
      }
      return state;
    }
    case actionTypes.DEP_DURATION_SET: {
      const { scenarioTabId } = action.payload;
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],
          isDurationSet: true,
        },
      };
    }

    default:
      return depositReducerThree(state, action);
  }
};

const depositReducerThree = (state, action) => {
  switch (action.type) {
    case actionTypes.COVERAGE_TYPE: {
      const { oldValue, value, scenarioTabId, clients } = action.payload;
      if (oldValue !== value && value === eCoverageType.single) {
        return {
          ...state,
          [scenarioTabId]: {
            ...state[scenarioTabId],
            [eField.basedonClient]: clients[0],
          },
        };
      }
      return { ...state };
    }
    case actionTypes.DEP_DURATION_CHANGE: {
      const { valueFrom, valueTo, type, activeScenarioTabId } = action.payload;
      return {
        ...state,
        [activeScenarioTabId]: {
          ...state[activeScenarioTabId],
          [eField.duration]: type,
          [eField.durationFrom]: valueFrom,
          [eField.durationTo]: valueTo,
        },
      };
    }
    default:
      return depositReducerFour(state, action);
  }
};

const depositReducerFour = (state, action) => {
  switch (action.type) {
    case actionTypes.DEP_CLIENT_CHANGE: {
      const { clientValueFrom, clientValueTo, clientId, clientActiveScenarioTabId } = action.payload;
      return {
        ...state,
        [clientActiveScenarioTabId]: {
          ...state[clientActiveScenarioTabId],
          [eField.basedonClient]: clientId,
          [eField.durationFrom]: clientValueFrom,
          [eField.durationTo]: clientValueTo,
        },
      };
    }
    case actionTypes.DEP_DEFAULT_DURATION: {
      const { defaultValueFrom, defaultValueTo, defaultActiveScenarioTabId } = action.payload;
      return {
        ...state,
        [defaultActiveScenarioTabId]: {
          ...state[defaultActiveScenarioTabId],
          [eField.durationFrom]: defaultValueFrom,
          [eField.durationTo]: defaultValueTo,
        },
      };
    }
    case actionTypes.LOADED_INFORCE_POLICY: {
      const policy = action.payload.policyData.illustration.policy;
      const coverages = action.payload.policyData.illustration.policy.coverage;
      const baseCoverage = coverages.find(
        (cvg) => cvg.indicatorCode === eField.indicatorCode.base && cvg.status === eBusinessType.IF
      );
      let durationFrom = '';
      let durationTo = '';
      let durationPartyId = policy.ownerId;
      let currentClientAge = 0;
      if (baseCoverage) {
        const equivalentAge = baseCoverage.equivalentAge;
        currentClientAge = baseCoverage.life[0].age;
        const getValueForAgeToValue = getAgeToValueBasedOnCoi(
          baseCoverage.coverageType,
          baseCoverage.costOfInsurance?.type,
          equivalentAge,
          currentClientAge,
          false,
          1
        );
        durationFrom = getAgeFromValue(
          baseCoverage.coverageType,
          equivalentAge,
          currentClientAge,
          currentClientAge + 1
        );
        durationTo = getValueForAgeToValue;
        durationPartyId = baseCoverage.life[0].partyId;
      }
      let maximumDepositAmount = '';
      let minimumDepositAmount = '';
      let modalAmount = '';
      if (policy.depositOption) {
        maximumDepositAmount = policy.depositOption.maximumDepositAmount;
        minimumDepositAmount = policy.depositOption.minimumDepositAmount;
        modalAmount = policy.depositOption.modalAmount;
      }
      return {
        ...state,
        [state.activeTabId]: {
          ...state[state.activeTabId],
          [eField.depositType]: eDepositType.specified,
          [eField.depositFrequency]: policy.depositMode,
          [eField.depositAmount]: modalAmount,
          [eField.durationFrom]: durationFrom,
          [eField.durationTo]: durationTo,
          depositOwnerResidence: inforceOwnerResidence(action.payload),
          durationPartyId,
          maximumFirstYearAmount: maximumDepositAmount,
          minimumFirstYearAmount: minimumDepositAmount,
          currentAge: currentClientAge,
        },
        inforce: true,
      };
    }
    default:
      return { ...state };
  }
};

export default depositReducer;
