import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ProductConfig } from '../product-config';
import SideAccountInterestRate from '../../core/components/side-account-interest-rate';
import PolicyInterestRate from '../../core/components/policy-interest-rate';
import MarginalTaxRate from '../../core/components/marginal-tax-rate';
import AltPerformanceCreditRate from '../../core/components/alternate-credit-rate';
import PerformanceCreditRate from '../../core/components/performance-credit-rate';
import PropTypes from 'prop-types';
import MWIButton from '../../core/components/mwi-button';
import { eProduct } from '../../core/configs';

export const RatesTab = (props) => {
  const { product, intl } = props;
  const onClick = () => {
    const url = intl.formatMessage({ id: 'coverage.performanceCreditRateURL' });
    window.open(url);
  };

  return (
    <div>
      {product === eProduct.PG ? (
        <>
          <div className="p-grid p-grid-no-margin p-col-12">
            <div className={'p-col-4 p-lg-4 p-xl-4 p-sm-6 mt-5 item-splitter'} id="marginal-tax-wrapper">
              <PerformanceCreditRate />
            </div>
            <div className={'p-col-3 p-lg-3 p-xl-3 p-sm-5 mwi-margin-top0 mwi-margin-left20'} id="tax-rates-btn">
              <MWIButton
                label={intl.formatMessage({ id: 'coverage.performanceCreditRate' })}
                styleClass="button-width mwi-button with-height"
                onClick={onClick}
              />
            </div>
          </div>
          <div className="p-grid p-grid-no-margin-all p-col-12 ">
            <div className={'p-col-4 p-lg-4 p-xl-4 p-sm-6 mt-6 item-splitter'} id="marginal-tax-wrapper">
              <AltPerformanceCreditRate />
            </div>
            <div className={'p-col-4 p-lg-4 p-xl-4 p-sm-6 mt-6 mwi-margin-top0 mwi-margin-left20'} id="tax-rates-btn">
              <SideAccountInterestRate />
            </div>
          </div>
          <div className="p-col-4">
            <MarginalTaxRate />
          </div>
        </>
      ) : (
        <div id="rates" className="p-col-4 p-grid p-grid-no-margin flexdisplay">
          {ProductConfig[product]?.ratesSections?.policyInterestRate && <PolicyInterestRate />}
          {ProductConfig[product]?.ratesSections?.sideAccountInterestRate && <SideAccountInterestRate />}
          {ProductConfig[product]?.ratesSections?.marginalTaxRate && <MarginalTaxRate />}
        </div>
      )}
    </div>
  );
};

RatesTab.propTypes = {
  product: PropTypes.string.isRequired,
  locale: PropTypes.string,
  intl: PropTypes.object,
};

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default connect(mapStateToProps, null)(injectIntl(RatesTab));
