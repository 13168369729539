import * as actionTypes from './actionTypes';
import { eLocale, eUserTypeSAML, eUserType, eReqStatus, eUDMType } from './configs';

export const initialState = {
  id: '',
  isLoggedIn: false,
  isRequesting: false, // check if API is being called
  errorHappened: false, // system error
  isUdmIllustrationError: false, // illustration validation error occurred on spreadsheet or report
  errorMessage: '',
  locale: eLocale.en,
  userID: '007',
  ciamId: '',
  userName: '',
  userType: 'HeadOffice',
  role: '',
  channel: '',
  email: '',
  advisorList: [],
  accessible: false,
  saveCaseStarted: false,
  saveCaseFinished: false,
  saveFailure: false,
  openedCaseName: '',
  initialProductOpen: true,
  guid: '',
  isRequestingCalculation: false,
  languageChange: false,
  autoSaveCaseLoaded: false,
  warningPopUp: undefined,
  canAssignCase: false,
  closeSpreadsheet: undefined,
  statusDialog: { show: false, message: '' },
  inforceData: {},
  inforceInfo: {},
  inforcePolicy: false,
  allSellingCodes: '',
  surrogateBranches: '',
};

const getUserTypeList = (type) => (type !== '' && type !== undefined && type.length > 0 ? type.split(',') : 'ADV');

const appReducer = (state, action) => {
  state = state === undefined ? initialState : state;
  switch (action.type) {
    case actionTypes.OPEN_SAVEDCASE: {
      return {
        ...state,
        model: action.payload.model,
        saveCaseFinished: false,
        inforcePolicy: false,
        inforceData: {},
      };
    }

    case actionTypes.DELETE_SAVEDCASES: {
      if (action.payload.includes(state.guid)) {
        return {
          ...state,
          model: undefined,
          guid: '',
          openedCaseName: '',
        };
      } else {
        return state;
      }
    }

    case actionTypes.SAVE_OPEN_CASE_NAME: {
      return {
        ...state,
        openedCaseName: action.payload.name,
        autoSaveCaseLoaded: action.payload.autoSaveCaseLoaded,
      };
    }

    default:
      return appReducer2(state, action);
  }
};

const appReducer2 = (state, action) => {
  switch (action.type) {
    case actionTypes.SHOW_NOTIFICATION: {
      const { field, value } = action.payload;
      return {
        ...state,
        showNotification: true,
        [field]: value,
      };
    }

    case actionTypes.HIDE_NOTIFICATION: {
      return {
        ...state,
        showNotification: false,
        notificationMessage: null,
      };
    }

    case actionTypes.SHOW_RATES_NOTIFICATION: {
      return {
        ...state,
        showRatesNotification: true,
      };
    }

    case actionTypes.HIDE_RATES_NOTIFICATION: {
      return {
        ...state,
        showRatesNotification: false,
      };
    }

    case actionTypes.SHOW_STATUS_DIALOG: {
      return {
        ...state,
        statusDialog: { show: true, label: action.payload.value[0], message: action.payload.value[1] },
      };
    }

    case actionTypes.HIDE_STATUS_DIALOG: {
      return {
        ...state,
        statusDialog: { show: false, message: '' },
      };
    }

    case actionTypes.SAVE_CASE_STARTED: {
      return {
        ...state,
        saveCaseStarted: true,
        saveCaseFinished: false,
        saveFailure: false,
      };
    }

    case actionTypes.SAVE_CASE_ERROR: {
      return {
        ...state,
        saveCaseStarted: false,
        saveCaseFinished: false,
        saveFailure: true,
      };
    }

    case actionTypes.SAVE_CASE_FINISHED: {
      return {
        ...state,
        saveCaseStarted: false,
        saveCaseFinished: true,
      };
    }

    default:
      return appReducer3(state, action);
  }
};

const checkResponseValidations = (response) => {
  return (
    response.validations &&
    response.validations.validation.length > 0 &&
    response.validations.validation[0].id === 'MaxWithdrawal'
  );
};

const getCustomWarning = (response, warningPopUp) => {
  const checkValidationsStatus =
    response.validations &&
    response.validations.status &&
    response.validations.status.toUpperCase() === eReqStatus.warning;
  return (response && checkValidationsStatus && checkResponseValidations(response)) || warningPopUp;
};

const getUdmIllustrationError = (response) => {
  const checkResponseType =
    response.type &&
    response.validations &&
    response.validations.status &&
    response.type.toUpperCase() === eUDMType.illustration.toUpperCase();
  return (response && checkResponseType && response.validations.status.toUpperCase() === eReqStatus.error) || false;
};

function conditionalFunc(condition, caseOne, caseTwo) {
  return condition ? caseOne : caseTwo;
}

const appReducer3 = (state, action) => {
  switch (action.type) {
    case actionTypes.AUTO_SAVE_CASE: {
      const { field, value, autoSaveCaseLoadedValue } = action.payload;

      return {
        ...state,
        initialProductOpen: false,
        [field]: value,
        autoSaveCaseLoaded: autoSaveCaseLoadedValue,
      };
    }

    case actionTypes.LOADED_UDM_RESPONSE: {
      const { response } = action.payload;
      const { requestErrorMessage } = response;
      const respStatus = response && response.status;
      const customWarning = getCustomWarning(response, state.warningPopUp);
      const isUdmIllustrationError = getUdmIllustrationError(response);
      const isError =
        (respStatus &&
          (respStatus.toUpperCase() === eReqStatus.error || respStatus.toUpperCase() === eReqStatus.reqError)) ||
        response === '';
      return {
        ...state,
        isRequestingCalculation: false,
        warningPopUp: conditionalFunc(customWarning, customWarning, undefined),
        errorHappened: isError,
        errorMessage: conditionalFunc(isError, requestErrorMessage, '') || state.errorMessage,
        isUdmIllustrationError, // this is to display the error modal for illustration request
      };
    }

    case actionTypes.TOGGLE_LOCALE: {
      return {
        ...state,
        locale: action.payload,
      };
    }

    case actionTypes.TOGGLE_ACCESSIBLE: {
      return {
        ...state,
        accessible: action.payload,
      };
    }

    default:
      return appReducer4(state, action);
  }
};

const appReducer4 = (state, action) => {
  switch (action.type) {
    case actionTypes.SELECT_PRODUCT: {
      return {
        ...state,
        guid: '',
        model: '',
        openedCaseName: '',
        initialProductOpen: true,
        languageChange: false,
        autoSaveCaseLoaded: false,
      };
    }

    case actionTypes.AUTH_USER_DONE: {
      const {
        language,
        userID,
        ciamId,
        partyId,
        role,
        channel,
        type,
        firstname,
        lastname,
        email,
        advisorList,
        entitlementRole,
        surrogateBranches,
        allSellingCodes,
      } = action.payload;
      const userTypeList = getUserTypeList(type);
      return {
        ...state,
        userID,
        ciamId,
        partyId,
        role,
        channel,
        email,
        advisorList,
        userType: userTypeList.includes(eUserTypeSAML.HO) ? eUserType.headOffice : eUserType.advisor,
        userName: `${firstname} ${lastname}`,
        canAssignCase: ['MA', 'LS', 'LMA', 'AMA'].includes(entitlementRole),
        locale: language,
        surrogateBranches,
        allSellingCodes,
      };
    }

    case actionTypes.AUTH_CIAM_INFO: {
      const { securityTokenValidTo } = action.payload;
      return {
        ...state,
        securityTokenValidTo,
        isLoggedIn: true
      };
    }

    case actionTypes.LANGUAGE_CHANGE: {
      return {
        ...state,
        languageChange: action.payload.value,
      };
    }

    case actionTypes.MWI_ON_CHANGE: {
      const { target, field, value } = action.payload;

      if (target === 'app') {
        if (field === 'errorMessage') {
          return {
            ...state,
            errorHappened: !!value,
            errorMessage: value,
          };
        } else {
          return {
            ...state,
            [field]: value,
          };
        }
      }

      return state;
    }

    default:
      return appReducer5(state, action);
  }
};

const appReducer5 = (state, action) => {
  switch (action.type) {
    case actionTypes.LOADED_INFORCE_POLICY: {
      return {
        ...state,
        guid: '',
        inforceData: action.payload.policyData.illustration,
        openedCaseName: action.payload.policyData.illustration.policy.policyNumber,
        inforcePolicy: true,
      };
    }

    case actionTypes.SHOW_STATUS_DIALOG: {
      return {
        ...state,
        statusDialog: { show: true, message: action.payload.value },
      };
    }

    case actionTypes.HIDE_STATUS_DIALOG: {
      return {
        ...state,
        statusDialog: { show: false, message: '' },
      };
    }

    case actionTypes.COMMON_INITIALIZE: {
      return {
        ...state,
        inforceData: {},
        inforceInfo: {},
        inforcePolicy: false,
      };
    }

    default:
      return appReducer6(state, action);
  }
};

const appReducer6 = (state, action) => {
  switch (action.type) {
    case actionTypes.LOADING_INFORCE_REQUEST_FORM: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actionTypes.LOADED_INFORCE_REQUEST_FORM: {
      return { ...state, isLoading: false };
    }

    case actionTypes.ERROR_INFORCE_REQUEST_FORM: {
      const responseState = { ...state, errorHappened: true, errorMessage: 'common.specialRequestForm.erraor' };
      return responseState;
    }

    case actionTypes.SEARCHED_INFORCE_POLICY: {
      return {
        ...state,
        inforceInfo: action.payload.entitledPolicy,
      };
    }

    default:
      return state;
  }
};

export default appReducer;
