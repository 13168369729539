import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/components/button/Button';
import './mwi-button.css';

export class MWIButton extends PureComponent {
  static propTypes = {
    children: PropTypes.object,
    tabIndex: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.mwiButton = React.createRef();
  }

  render() {
    const { label, styleClass, disabled, onClick, tabIndex, srOnlyLabel, id, title } = this.props;

    return (
      <React.Fragment>
        <Button
          role="button"
          title={title}
          tabIndex={tabIndex}
          label={label}
          className={`mwi-widget mwi-button ${styleClass}`}
          onClick={onClick}
          disabled={disabled}
          aria-label={srOnlyLabel ? `${label} ${srOnlyLabel}` : label}
          ref={this.mwiButton}
          id={id}
        >
          {this.props.children}
        </Button>
      </React.Fragment>
    );
  }
}
export default MWIButton;

MWIButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  styleClass: PropTypes.string,
  //tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  srOnlyLabel: PropTypes.string,
};

MWIButton.defaultProps = {
  disabled: false,
  srOnlyLabel: '',
};
