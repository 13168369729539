import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';
import { bindActionCreators } from 'redux';
import { Dialog } from 'primereact/components/dialog/Dialog';
import * as AppActions from '../../core/actions';
import UDMMapper from '../../core/udm-mapper';
import ScenarioTopBar from '../scenario-topbar';
import ScenarioTabNavs from '../scenario-tab-navs';
import { configs, ePageId, isSpreadsheetView, eReportType } from '../../core/configs';
import Coverage from '../coverage';
import Summary from '../summary';
import Spreadsheet from '../../spreadsheet';
import StatusDialog from '../../core/components/status-dialog';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import './scenario-panel.css';
import { Switch } from 'react-router';
import { injectIntl } from 'react-intl';
import { START_NEW_APPLICATION_ID } from '../../core/constants';
import ViewReportButton from '../../core/components/view-report';

export class ScenarioPanel extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    udmEnabled: PropTypes.bool,
    isSpreadsheetView: PropTypes.bool,
    sidebarVisible: PropTypes.bool,
    summaryError: PropTypes.bool,
    report: PropTypes.object,
    selectedProduct: PropTypes.string,
    sceActions: PropTypes.object,
    appActions: PropTypes.object,
    location: PropTypes.object,
    intl: PropTypes.object,
    showStatusDialog: PropTypes.bool,
    statusMessage: PropTypes.string,
    contentLabel: PropTypes.string,
    ddmCaseId: PropTypes.string,
    ddmGuid: PropTypes.string,
    role: PropTypes.string,
    inforcePolicy: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.hideUDM = this.hideUDM.bind(this);
  }

  componentDidMount() {
    if (this.props.location.pathname === '/custom-mode/scenarios') {
      this.props.history.push(`${this.props.match.url}/clientcoverage`);
    }
    if (this.props.location.pathname === '/custom-mode/scenarios/') {
      this.props.history.push(`${this.props.match.url}clientcoverage`);
    }
  }

  hideUDM() {
    this.props.sceActions.toggleUDM(false);
  }

  render() {
    const { intl } = this.props;
    const currentPage =
      Object.keys(configs.routes).find((route) => configs.routes[route] === this.props.location.pathname) || '';

    return (
      <React.Fragment>
        {isSpreadsheetView(this.props.location.pathname) && (
          <ScenarioTopBar match={this.props.match} selectedProduct={this.props.selectedProduct} />
        )}

        <div className="p-grid p-grid-no-margin form-wrapper" role="container-scenariopanel">
          <div className="p-col-8 p-lg-8 p-xl-8 coverage-wrapper">
            {!isSpreadsheetView(this.props.location.pathname) && <ScenarioTabNavs />}

            <Switch>
              <Route path={`${this.props.match.url}/clientcoverage`} component={Coverage} />
            </Switch>
          </div>

          <div className="">
            {currentPage.toUpperCase() === ePageId.clientcoverage.toUpperCase() && (
              <React.Fragment>
                <Sticky top={164} innerZ={500}>
                  <div className="p-col-3 p-lg-5 p-xl-4 summary-wrapper">
                    <Summary />
                    {!this.props.inforcePolicy && (
                      <div style={{ height: '84px' }}>
                        <ViewReportButton
                          id={START_NEW_APPLICATION_ID}
                          styleClass="ddaStartNewbtn"
                          label={intl.formatMessage({ id: 'scenarioPanel.dda.startNewApp' })}
                          reportType={eReportType.base}
                          srOnlyLabel={intl.formatMessage({ id: 'sronly.pdf' })}
                          displayReportDropdown={false}
                          startNewApplication
                        />
                      </div>
                    )}
                  </div>
                </Sticky>
              </React.Fragment>
            )}
          </div>
          <div className="p-col-12">
            <div className="spreadsheet-wrapper">
              <Switch>
                <Route path={`${this.props.match.url}/spreadsheet`} component={Spreadsheet} />
              </Switch>
            </div>
          </div>
        </div>
        <Dialog
          header="UDM Viewer"
          visible={this.props.udmEnabled}
          modal={false}
          onHide={this.hideUDM}
          className="udm-viewer"
        >
          <UDMMapper />
        </Dialog>
        <StatusDialog
          isOpen={this.props.showStatusDialog}
          statusMessage={this.props.statusMessage}
          contentLabel={this.props.contentLabel}
        />
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, report, udmResponse }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const product = activeScenario.product;

  return {
    selectedProduct: product,
    udmEnabled: scenarioTabNavs.udmEnabled,
    sidebarVisible: scenarioTabNavs.sidebarVisible,
    summaryError: scenarioTabNavs.summaryError,
    report: report[scenarioTabNavs.activeTabId],
    showStatusDialog: app.statusDialog.show,
    statusMessage: app.statusDialog.message,
    contentLabel: app.statusDialog.label,
    ddmCaseId: udmResponse.illustration.illustrationId,
    ddmGuid: app.ddmGuid,
    role: app.role,
    inforcePolicy: app.inforcePolicy,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ScenarioPanel)));
