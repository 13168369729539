import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MWIButton from '../core/components/mwi-button/index';
import './unsupported-browser.css';

export default class CopyLinkToClipboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copySuccess: false
    };
  }

  copyCodeToClipboard = () => {
    const el = this.input;
    el.select();
    document.execCommand('copy');
    this.setState({ copySuccess: true });
  };

  render() {
    const { illustrationsLink, linkCopied, copyText } = this.props;

    return (
      <div>
        <input
          ref={(input) => (this.input = input)}
          value={illustrationsLink}
          readOnly
          className="column left input-for-ie"
        />
        <MWIButton
          label={copyText}
          onClick={() => this.copyCodeToClipboard()}
          styleClass="column copy-button middle"
          srOnlyLabel="Copy"
        />
        {this.state.copySuccess ? (
          <div className="column right">
            <span className="material-icons check-icon">check_circle</span>
            <span className="link-copied">{linkCopied}</span>
          </div>
        ) : null}
      </div>
    );
  }
}

CopyLinkToClipboard.propTypes = {
  linkCopied: PropTypes.string,
  copyText: PropTypes.string,
  illustrationsLink: PropTypes.string
};
