import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MWISrOnly from '../../core/components/mwi-sr-only';
import { eProduct, eReqStatus } from '../../core/configs';
import { getFormattedValue, getPolicyYear } from '../../core/utils';
import './summary.css';
import SummaryPremiumTabRateChange from './summary-premium-tab-ratechange';
import * as summaryUtils from './utils';
import moment from 'moment';

export class SummaryPremiumTab extends PureComponent {
  static propTypes = {
    productValidationError: PropTypes.bool,
    app: PropTypes.object,
    udmResponse: PropTypes.object,
    depositOption: PropTypes.object,
    intl: PropTypes.object,
    totalPremium: PropTypes.string,
    initialPremium: PropTypes.string,
    premiumFrequency: PropTypes.string,
    product: PropTypes.string,
    maxAdditionalDeposit: PropTypes.string,
    annualizedModalPayment: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.errorRef = React.createRef();
  }

  renderParAmount = () => {
    const { app, depositOption, initialPremium, udmResponse } = this.props;
    const isUDMLoading = udmResponse.isLoading;
    return (
      <React.Fragment>
        <div className="p-col-8 initial" role="heading" aria-level="2">
          <FormattedMessage id="summary.premium" />
        </div>
        {!isUDMLoading && (
          <div className="p-col-4">
            <div className="initial-amount">{initialPremium}</div>
          </div>
        )}
        <div className="p-col-8 initial" role="heading" aria-level="2">
          <FormattedMessage id="summary.depositoption" />
        </div>
        {!isUDMLoading && (
          <div className="p-col-4">
            <div className="initial-amount">
              {depositOption.isCustom ? (
                <FormattedMessage id="depositoption.custom" />
              ) : (
                getFormattedValue(app.locale, depositOption.amount || '0')
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  renderPaymentAmountsPGold = () => {
    const { annualizedModalPayment, maxAdditionalDeposit } = this.props;
    return (
      <div className="fixed-panel mwi-padding-right12" tabIndex="0">
        <div>
          <div className="mwi-padding-left12 mwi-padding-bottom12 mwi-padding-top12">
            <div className="p-grid p-grid-no-margin">
              <div className="p-col-8 label">
                <FormattedMessage id="summary.currentYearBasePayment" />
              </div>

              <div className="p-col-4 amount">
                <span>{annualizedModalPayment}</span>
              </div>
            </div>
          </div>

          <div className="mwi-padding-left12">
            <div className="p-grid p-grid-no-margin">
              <div className="p-col-8 label">
                <FormattedMessage id="summary.currentYearMaxPayment" />
              </div>
              <div className="p-col-4 amount">
                <span>{maxAdditionalDeposit}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTotalPremium = () => {
    const { udmResponse, totalPremium, premiumFrequency } = this.props;
    return (
      <>
        <div className="p-col-8 total-premium" role="heading" aria-level="2">
          <FormattedMessage id="summary.totalpremium" /> (
          <FormattedMessage id={`common.summary.${premiumFrequency.toLowerCase()}`} />)
        </div>
        {!udmResponse.isLoading && (
          <div className="p-col-4">
            <div className="total-amount total-premium">{totalPremium}</div>
          </div>
        )}
      </>
    );
  };

  renderInforcePolicyCurrentYear = (product) => {
    return (
      this.props.app.inforcePolicy &&
      [eProduct.PG, eProduct.Performax, eProduct.PAR, eProduct.SB, eProduct.FT, eProduct.BT].includes(product) && (
        <div className="fixed-panel mwi-padding-right12 mwi-padding-top12" tabIndex="0">
          <div className="mwi-padding-left12 mwi-padding-bottom5">
            <div className="p-grid p-grid-no-margin">
              <div className={`p-col-8 ${eProduct.PAR === product ? 'par-label' : 'label'}`}>
                <FormattedMessage id="summary.inforce.currentyear" />
              </div>
              <div className={`p-col-4 ${eProduct.PAR === product ? 'par-amount' : 'amount'}`}>
                <span>{getPolicyYear(moment(this.props.app.inforceData.policy.effectiveDate))}</span>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  render = () => {
    const { intl, udmResponse, productValidationError, product, app } = this.props;
    const isValidationError = udmResponse.validationStatus.toUpperCase() === eReqStatus.error;
    const isValidationWarning = udmResponse.validationWarnings && udmResponse.validationWarnings.length > 0;
    const isFtBtInforce = app.inforcePolicy && [eProduct.FT, eProduct.BT].includes(product);

    return (
      <div className="fixed-panel" tabIndex="0">
        <SummaryPremiumTabRateChange />
        {!summaryUtils.isPGold(product) &&
          !summaryUtils.isPerformax(product) &&
          !summaryUtils.isSB(product) &&
          !isFtBtInforce && (
            <div className="p-grid p-grid-no-margin sce-premium">
              {this.renderTotalPremium()}
              {summaryUtils.isPar(product) && this.renderParAmount()}
            </div>
          )}
        {[eProduct.PG, eProduct.Performax, eProduct.SB].includes(product) && this.renderPaymentAmountsPGold()}
        {this.renderInforcePolicyCurrentYear(product)}

        <div className="message-box">
          {productValidationError && (
            <div className="out-of-date message error" role="errorMessage">
              <i className="material-icons msg-icon">cancel</i>
              <FormattedMessage
                id="summary.error.vitality"
                values={{
                  vitality: (
                    <i>
                      <FormattedMessage id="common.vitality" />
                    </i>
                  ),
                }}
              />
            </div>
          )}

          {isValidationError &&
            udmResponse.validationErrors.map((error, index) => {
              return (
                <div className="message error" key={index} role="errorMessage">
                  <i aria-hidden className="material-icons msg-icon">
                    cancel
                  </i>
                  <MWISrOnly label={intl.formatMessage({ id: 'common.error' })} />
                  <div className="out-of-date">{error.message[0].text}</div>
                </div>
              );
            })}
          {isValidationWarning &&
            udmResponse.validationWarnings.map((warning, index) => {
              return (
                <div className="message warning" key={index} role="warningMessage">
                  <i aria-hidden className="material-icons msg-icon">
                    warning
                  </i>
                  <MWISrOnly label={intl.formatMessage({ id: 'common.warning' })} />
                  <div className="out-of-date">{warning.message[0].text}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };
}

export default SummaryPremiumTab;
