import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AppActions from '../../core/actions';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { eLocale, eCoverageType, eProduct, eField } from '../../core/configs';
import MWIDropdown from '../../core/components/mwi-dropdown';
import { getPerformaxTermRiderDuration, calculateMWINumberFieldValue, getPolicyYear } from '../../core/utils';
import './spousal-riders.css';
import _ from 'lodash';
import { dropDownOptions } from '../../core/dropdown-config';
import moment from 'moment';

const getSpousalRiderCoverageAmount = function (coverageAmount, locale, riderInfo) {
  return (
    <NumberFormat
      className="mwi-widget mwi-input number-data mwi-w100"
      value={coverageAmount || 0}
      disabled={this.props.inforce}
      prefix={locale === eLocale.en ? '$ ' : ''}
      suffix={locale === eLocale.fr ? ' $' : ''}
      allowNegative={false}
      decimalSeparator="false"
      thousandSeparator={locale === eLocale.en ? ',' : ' '}
      id={`spousalRiderCoverageAmount.${riderInfo.id}`}
      autoComplete="off"
      aria-required="true"
      onFocus={this.handleFocus}
    />
  );
};

export class SpousalRider extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    riderInfo: PropTypes.object.isRequired,
    clients: PropTypes.object,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    product: PropTypes.string,
    intl: PropTypes.object,
    coverageTypeOne: PropTypes.string,
    effectiveDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  constructor(props) {
    super(props);

    this.onBlurCoverageDuration = this.onBlurCoverageDuration.bind(this);
  }

  dispatchAction(field, value, target = 'spousalRider', isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
      riderId: this.props.riderInfo.id,
      clients: this.props.clients,
    });
  }

  onBlurCoverageDuration(type, value) {
    const minValue = getPolicyYear(moment(this.props.effectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));

    let maxAge = this.props.clients[this.props.riderInfo.clientId].age;
    if (this.props.riderInfo.coverageType !== eCoverageType.single) {
      this.props.clients.allClients.forEach((cId) => {
        const client = this.props.clients[cId];
        if (client.age > maxAge) {
          maxAge = client.age;
        }
      });
    }
    const maxValue = getPerformaxTermRiderDuration(maxAge, this.props.riderInfo.coverageOption);

    const newValue = calculateMWINumberFieldValue(value || 1, minValue, maxValue);
    this.dispatchAction(type, newValue);
    this.props.sceActions.toggleOutOfDate(true);
    this.props.sceActions.performManualRefresh(true);
  }

  //Select the number format value once get focused
  handleFocus = (event) => event.target.select();

  render() {
    const { locale, intl, product, riderInfo, inforce } = this.props;
    const { coverageTypeOptionsPMax, performaxTermRiderOptions } = dropDownOptions(intl);
    const { coverageType, coverageOption, coverageAmount, coverageDuration } = riderInfo;
    let coverageTypeOne = this.props.coverageTypeOne;
    const covOptions = coverageTypeOptionsPMax;

    if (inforce) {
      coverageTypeOne = riderInfo.coverageType;
    }

    const coverageTypes =
      coverageTypeOne === eCoverageType.single
        ? _.filter(covOptions, (type) => type.value === eCoverageType.single)
        : _.filter(covOptions, (type) => type.value === eCoverageType.single || type.value === eCoverageType.combined);

    return (
      <div className="p-grid p-grid-no-margin rider-info">
        <div className="p-col-12 mwi-margin-top24">
          <div className="flexdisplay">
            <MWIDropdown
              name={`${eField.covType}.${riderInfo.id}`}
              label={<FormattedMessage id="coverage.type" />}
              value={coverageType}
              options={coverageTypes}
              disabled={coverageTypes.length === 1 || inforce}
              containerStyle="p-col-4 p-lg-4 p-xl-3 p-sm-6 space-right"
            />
            <MWIDropdown
              name={`${eField.covOption}.${riderInfo.id}`}
              label={<FormattedMessage id="coverage.option" />}
              value={coverageOption}
              options={performaxTermRiderOptions}
              disabled={inforce}
              containerStyle="coverage-option-container p-col-3 p-lg-4 p-xl-3 p-sm-6 space-right"
            />

            <div className="p-col-3 p-lg-4 p-xl-3 p-sm-6 cov-option mwi-control-wrapper space-right">
              <div className="mwi-label">
                <label htmlFor={`spousalRiderCoverageAmount.${riderInfo.id}`} aria-required="true">
                  <FormattedMessage id="coverage.coverageAmount" />
                </label>
              </div>
              <div>
                <div className="ui-input">
                  {getSpousalRiderCoverageAmount.call(this, coverageAmount, locale, riderInfo)}
                </div>
              </div>
            </div>
            <div className="p-col-3 p-lg-3 p-xl-2 p-sm-6 duration-wrapper top-margin-24">
              <div className="mwi-label" id="SpousalRiderDuration">
                <span>
                  <FormattedMessage id="rider.duration" />
                </span>
              </div>
              <div className="ui-input">
                <NumberFormat
                  className="mwi-widget mwi-input number-data mwi-w100"
                  value={+coverageDuration} // allows an empty field when user presses backspace or delete
                  decimalScale={0}
                  allowNegative={false}
                  // if the user tabs off with an empty field,
                  // we calculate with a value of 1 (which will set to 10 if user is advisor)
                  onBlur={(e) => {
                    this.onBlurCoverageDuration(eField.coverageDuration, e.target.value);
                  }}
                  aria-labelledby="SpousalRiderDuration"
                  aria-required="true"
                  autoComplete="off"
                  disabled={inforce && product === eProduct.MUL}
                  onFocus={this.handleFocus}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs, clients }) => {
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const scenarioTabId = scenarioTabNavs.activeTabId;

  // make sure only applicable coverage types are available
  return {
    locale: app.locale,
    inforce: app.inforcePolicy,
    clients: clients[scenarioTabId],
    coverageTypeOne: coverage.coverageType,
    product: scenarioTabNavs.topBars[scenarioTabNavs.activeTabId].product,
    effectiveDate: scenarioTabNavs.topBars[scenarioTabNavs.activeTabId].effectiveDate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SpousalRider));
