import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { eField, appLaunchDate, appLaunchDatePay100, eUserType, eActionEvent, ePremiumDuration } from '../../configs';
import PropTypes from 'prop-types';
import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants';
import {
  trackUserActions,
  getProdCoverageOption,
  setActiveVitalityStatus,
  setChangeToBronzeYes,
  setCoverageSolveToYes,
  setIncludeDepositOptionToYes,
} from '../../../core/utils';

export class VitalityCheckbox extends PureComponent {
  static propTypes = {
    coverage: PropTypes.object,
    onChange: PropTypes.func,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    activeScenarioTabId: PropTypes.string,
    activeCoverageTabId: PropTypes.string,
    product: PropTypes.string,
    clients: PropTypes.object,
    isVitalityPlusDisable: PropTypes.bool,
    isPremiumDurationPay100: PropTypes.bool,
    intl: PropTypes.object,
    effectiveDate: PropTypes.string,
    userType: PropTypes.string,
    activeVitalityStatus: PropTypes.string,
    vitalityStatus: PropTypes.object,
    activeScenario: PropTypes.object,
  };

  dispatchAction(field, value, target, isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      scenarioTabId: this.props.activeScenarioTabId,
      coverageTabId: this.props.activeCoverageTabId,
      product: this.props.product,
      premiumDuration: this.props.coverage.premiumDuration,
      isCoverageSolve: this.props.coverage.isCoverageSolve,
      clients: this.props.clients,
      target,
      field,
      value,
    });
  }

  onChange(type, value, target = 'coverage') {
    const { effectiveDate, intl, userType, coverage, activeVitalityStatus, vitalityStatus, activeScenario } =
      this.props;
    const product = this.props.product + 'Vitality';
    let vitalityCheckBox = '';
    let currentVitalityStatus = setActiveVitalityStatus(value, activeVitalityStatus);
    if (
      userType === eUserType.headOffice &&
      value &&
      !moment(effectiveDate, DATE_FORMAT).isSameOrAfter(moment(appLaunchDate[product], DATE_FORMAT))
    ) {
      this.dispatchAction(eField.effectiveDate, appLaunchDate[product], 'scenario');
      this.dispatchNotification('showStatusDialog', null, [
        intl.formatMessage({ id: 'common.importantInformation' }),
        intl.formatMessage({ id: 'common.information.effectiveDate' }),
      ]);
    }
    if (
      userType === eUserType.headOffice &&
      value &&
      this.props.isPremiumDurationPay100 &&
      !moment(effectiveDate, DATE_FORMAT).isSameOrAfter(moment(effectiveDateChangeDate(), DATE_FORMAT))
    ) {
      this.dispatchAction(eField.effectiveDate, effectiveDateChangeDate(), 'scenario');
      this.dispatchNotification('showStatusDialog', null, [
        intl.formatMessage({ id: 'common.importantInformation' }),
        intl.formatMessage({ id: 'common.information.effectiveDate' }),
      ]);
    }
    this.dispatchAction(type, value, target);
    this.props.sceActions.toggleOutOfDate(true);
    if (value) {
      vitalityCheckBox = 'Yes';
    }
    if (value && vitalityStatus[vitalityStatus.activeTabId].length > 1) {
      currentVitalityStatus = 'Custom';
    }

    trackUserActions(eActionEvent.selection, type, value, '', {
      product: this.props.product,
      coverageType: type === eField.covType ? value : coverage.coverageType,
      coverageOption: getProdCoverageOption(this.props.product, coverage),
      vitality: vitalityCheckBox,
      vitalityStatus: currentVitalityStatus,
      changeToBronze: setChangeToBronzeYes(this.props.product, coverage.isChangeToBronze, value, currentVitalityStatus),
      coverageSolve: setCoverageSolveToYes(coverage),
      includeDepositOption: setIncludeDepositOptionToYes(activeScenario, coverage),
    });

    function effectiveDateChangeDate() {
      return vitalityCheckBox === 'Yes' ? appLaunchDatePay100.ParVitality : appLaunchDatePay100.Par;
    }
  }

  dispatchNotification(type, field, value) {
    if (this.props) {
      this.props.appActions[type]({ field, value });
    }
  }

  render() {
    const {
      coverage: { isVitalityPlusSelected },
      isVitalityPlusDisable,
    } = this.props;
    return (
      <React.Fragment>
        <input
          className="rider-checkbox focusOutline"
          type="checkbox"
          id={eField.coverageVitalityPlus}
          value={isVitalityPlusSelected}
          onChange={() => {
            this.onChange(eField.isVitalityPlusSelected, !isVitalityPlusSelected);
          }}
          checked={isVitalityPlusSelected}
          name={'vitalityPlus'}
          aria-label={'vitalityPlusCheckbox'}
          aria-describedby="coverageVitalityPlus"
          disabled={isVitalityPlusDisable}
        />
        <span className="mwi-padding-left6">
          <FormattedMessage id="coverage.with" />
        </span>
        <span className="mwi-padding-left4 mwi-bold">
          <FormattedMessage id="coverage.vitalityPlus" />
        </span>
        <span className="mwi-tm">
          <FormattedMessage id="common.superscript" />
        </span>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs, clients, vitalityStatus }) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const coverageState = coverageTabNavs[scenarioTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const activeScenario = scenarioTabNavs.topBars[scenarioTabId];
  const { product, effectiveDate } = activeScenario;
  const { userType } = app;
  const activeVitalityStatus = vitalityStatus[vitalityStatus.activeTabId][0].vitalityStatus;
  const isPremiumDurationPay100 = coverageState[coverageState.activeTabId].premiumDuration === ePremiumDuration.Pay100;
  return {
    coverage,
    product,
    effectiveDate,
    userType,
    activeVitalityStatus,
    vitalityStatus,
    isPremiumDurationPay100,
    activeScenarioTabId: scenarioTabId,
    activeCoverageTabId: coverageState.activeTabId,
    clients: clients[scenarioTabId],
    showStatusDialog: app.showStatusDialog,
    activeScenario,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VitalityCheckbox));
