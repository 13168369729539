import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import './banner.css';
import NewHeaderImage from '../../../src/assets/images/new-header-image.jpg';

export const Banner = ({ intl }) => (
  <div className="banner" role="heading" aria-level="1">
    <img
      alt={intl.formatMessage({ id: 'common.manulife.illustration' })}
      className="bg-img"
      // src={`${JUNCTION}/images/welcome-banner.jpg`}
      src={NewHeaderImage}
      aria-label={intl.formatMessage({ id: 'common.manulife.illustration' })}
    />
    {/* <div className="bg-img" alt="Illustrations banner" /> */}
    <div className="caption" aria-hidden="true">
      <h1>
        <FormattedMessage id="common.manulife.illustration" />
      </h1>
    </div>
  </div>
);
Banner.propTypes = {
  intl: PropTypes.object,
};
export default injectIntl(Banner);
