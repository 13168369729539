import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import '../mwi-accordion/mwi-accordion.css';

export class MWIPanel extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    skipTab: PropTypes.bool
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, className, contentClassName, skipTab } = this.props;

    return (
      <React.Fragment>
        <div className={`accordion ${className}`}
          tabIndex={skipTab ? "-1" : "0"}
        >
          <div className="title mwi-weight-500 short-form" role="heading" aria-level="3">
            <div className={`p-col-12 ${contentClassName}`}>
              {/* <div className="title-group p-col-10">{title}</div> */}
              {children}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MWIPanel;
