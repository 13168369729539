import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { eField } from '../../configs';
import MWICoverageEffective from '../mwi-coverage-effective';
import { validate } from './validator';

import './coverage-effective-date.css';

export class CoverageEffectiveDate extends PureComponent {
  static propTypes = {
    effectiveDate: PropTypes.string,
    errors: PropTypes.object,
    intl: PropTypes.object,
    person: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    validate: PropTypes.func,
    locale: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(type, value) {
    const error = validate(type, value, this.props.locale, this.props.intl);

    if (type === eField.effectiveDate && !value) {
      this.props.onChange(eField.effectiveDate, '', true); // reset error
    }

    if (type === eField.effectiveDate && !error && value && value.length) {
      this.props.onBlur(type, value);
    }

    this.props.onChange(type, value);
  }

  onBlur(type, value) {
    // if no error, it returns empty string so that it can reset error message
    let error = validate(type, value, this.props.locale, this.props.intl);
    // custom validator
    if (!error && this.props.validate) {
      error = this.props.validate(type, value);
    }

    this.props.onBlur(type, error, true);
  }

  render = () => {
    const { intl, effectiveDate, person } = this.props;

    return (
      <React.Fragment>
        <div className="p-col-3 p-sm-6 p-lg-3 dob-calendar trc-coverage-ef">
          <div>
            {person && (
              <MWICoverageEffective
                containerClass="coverage-effective-input"
                grid="12"
                name={eField.effectiveDate}
                labelId={`ftrc.calculatorInput.coverageEffectiveDate`}
                label={intl.formatMessage({ id: 'ftrc.calculatorInput.coverageEffectiveDate.label' })}
                value={effectiveDate}
                locale={this.props.locale}
                placeholder={intl.formatMessage({ id: 'common.date.placeholder' })}
                onBlur={this.onBlur}
                onChange={this.onChange}
                errors={person.errors}
                ariaLabel={`${intl.formatMessage({
                  id: 'ftrc.calculatorInput.coverageEffectiveDate.label'
                })} DD MM YYYY`}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export const mapStateToProps = ({ app, scenarioTabNavs }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  return {
    locale: app.locale,
    effectiveDate: activeScenario.effectiveDate,
    errors: activeScenario.errors
  };
};

export default connect(mapStateToProps)(injectIntl(CoverageEffectiveDate));
