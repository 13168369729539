import React from "react";
import PropTypes from "prop-types";

export const LifechequeCheckbox = (props) => {
    LifechequeCheckbox.propTypes = {
        onChange: PropTypes.func,
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        intl: PropTypes.object,
        field: PropTypes.string,
        message: PropTypes.string
    };

    const {field, onChange, checked, disabled, intl, message} = props;

    return <div className={`p-col-12 p-lg-12 p-xl-12 p-sm-12 mwi-margin-top18`}>
        <input
            className="mwi-margin-right14"
            type="checkbox"
            id={`checkbox-id-${field}`}
            onChange={onChange}
            checked={checked}
            aria-describedby="lifeChequeCheckboxes"
            disabled={disabled}
        />
        <label htmlFor={`checkbox-lb-${field}`}>
            {intl.formatMessage({id: message})}
        </label>
    </div>;
};