import { Button, Modal, P, H2 } from '@manulife/mux';
import React, { useEffect, useState } from 'react';
import './logout-handler.css';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Timer from '../timer';
import {logout, retrieveCookieInfo} from '../../udm-mapper/sideEffect';
import {eLocale} from "../../configs";
import {bindActionCreators} from "redux";
import * as AppActions from "../../actions";


// When to show the modal (should be before the sign-out time)
const DISPLAY_MODAL_MINUTES = 30;
const WHEN_TO_SHOW_MODAL_MINUTES = 4;
const UTC_POSTFIX = '00Z';

const onClickSignOut = async (lang) => {
  // Need to also sign them out of CIAM, so make a call to Gateway and logout
  await logout(lang);
};

const str_pad_left = (string, pad, length) => `${string}`.padStart(length, pad).slice(-length);


const getMostRecentTokenDateTime = (currentToken, lsToken) => {
  const currentTokenDate = new Date(currentToken + UTC_POSTFIX);
  const lsTokenDate = new Date(lsToken + UTC_POSTFIX);
  return lsTokenDate > currentTokenDate;
};

const dispatchCookieInfo = (securityTokenValidTo) => {
  return  {
    securityTokenValidTo,
  };
};

const ZERO = 0;
export const LogoutHandler = injectIntl((props) => {
  // If user clicks refresh, then refresh the token in Gateway
  const checkRemainingTokenTime = async () => {
    // force a save to refresh token in Gateway and retrieve the expires via the cookies already being passed to the UI
    await retrieveCookieInfo();
    const result = await retrieveCookieInfo();
    const resultSecurityTokenValidTo = await result['securityTokenValidTo'];
    props.appActions.getCiamInfo(dispatchCookieInfo(resultSecurityTokenValidTo));
    setModalOpen(false);
  };

  // Update the minutes and seconds remaining on the timer
  const getAndSetRemainingTime = (validToDateTime) => {
    const millisecondsInSecond = 1000;
    const secondsInMinutes = 60;
    const finalValidToDateTime = !validToDateTime
        ? new Date(new Date().getTime() + DISPLAY_MODAL_MINUTES * secondsInMinutes * millisecondsInSecond)
        : new Date(validToDateTime + UTC_POSTFIX);
    const currentDate = new Date();
    const diffTime = Math.abs(finalValidToDateTime - currentDate);
    const diffSeconds = Math.ceil(diffTime / millisecondsInSecond);
    const minutesRemaining = Math.floor(diffSeconds / secondsInMinutes);
    const secondsRemaining = diffSeconds - minutesRemaining * secondsInMinutes;
    setMinutes(minutesRemaining);
    setSeconds(secondsRemaining);
  };

  const { intl, securityTokenValidTo } = props;
  const locale = intl.locale;
  const length = 2;
  const [minutes, setMinutes] = useState(DISPLAY_MODAL_MINUTES);
  const [seconds, setSeconds] = useState(ZERO);
  const [ModalOpen, setModalOpen] = useState(false);
  const displayLiveTimeInModal = str_pad_left(minutes, '0', length) + ':' + str_pad_left(seconds, '0', length);

  // Need to display the modal when 25 minutes have passed from the issued at time
  useEffect(() => {
    if(minutes === ZERO && seconds === ZERO){
      setModalOpen(false);
      onClickSignOut(locale);
    }
    else if(minutes <= WHEN_TO_SHOW_MODAL_MINUTES){
      setModalOpen(true);
    }
    else{
      setModalOpen(false);
    }
  }, [seconds]);

  useEffect(() => {
    // Before you display the modal, check local storage
    // If the value there is greater than ours pass it to the getAndSetRemainingTime()
    const lsToken = localStorage.getItem("lsSecurityTokenValidTo");
    if(lsToken && getMostRecentTokenDateTime(securityTokenValidTo, lsToken)){
      getAndSetRemainingTime(lsToken);
    }
  }, [minutes]);

  useEffect(() => {
    // Add security token to local storage
    window.localStorage.setItem("lsSecurityTokenValidTo", securityTokenValidTo);
    getAndSetRemainingTime(securityTokenValidTo);
  }, [props.securityTokenValidTo]);

  const getCustomStyle = (lang) => () => {
    return lang === eLocale.fr
      ? {
          buttonStyle: {margin: '10px', width: '425px'},
        }
      : {
          buttonStyle: {},
        };
  };

  return (
    <>
      <Modal
        isOpen={ModalOpen}
        ariaLabel="Timeout Modal"
        ariaLabelledBy="heading"
        ariaDescribedBy="content"
        showCloseButton={false}
      >
        <div style={{ textAlign: 'left' }}>
          <H2 id="heading1">{<FormattedMessage id="logout.modal.heading1" />}</H2>
          <P id="content1">{<FormattedMessage id="logout.modal.content1" />}</P>
          <P id="content2">{<FormattedMessage id="logout.modal.content2" />}</P>
          <Button
            customStyle={getCustomStyle(locale)()}
            id="button_StaySignedIn"
            variant={Button.VARIANT.SECONDARY}
            onClick={() => checkRemainingTokenTime()}
          >
            {<FormattedMessage id="logout.modal.stay.signed.in" />}&nbsp;({displayLiveTimeInModal})
          </Button>
          <Button
            customStyle={getCustomStyle(locale)()}
            variant={Button.VARIANT.primary}
            onClick={() => onClickSignOut(locale)}
          >
            {<FormattedMessage id="logout.modal.sign.out" />}
          </Button>
        </div>
      </Modal>
      <Timer
        seconds={seconds}
        setSeconds={setSeconds}
        minutes={minutes}
        setMinutes={setMinutes}
        displayTimer={false}
      />
    </>
  );
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
});

const mapStateToProps = ({ app }) => {
  return {
    securityTokenValidTo: app.securityTokenValidTo,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LogoutHandler));
