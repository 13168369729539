import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import './title-bar.css';

export const TitleBar = (props) => {
  const { intl, page, messageId } = props;

  return (
    <div className="mwi-margin-bottom24" role="container-titlebar">
      {messageId !== 'common.customizereport' && <div className="block" />}
      <div className={`mwi-big-title-bar content-title-bar ${page}`} id="main-content-titlebar">
        <span role="heading" aria-level="1">
          {intl.formatMessage({ id: messageId })}
        </span>
      </div>
    </div>
  );
};

export const mapStateToProps = ({ app }) => ({
  app,
  locale: app.locale,
});

TitleBar.propTypes = {
  intl: PropTypes.object,
  page: PropTypes.string,
  top: PropTypes.number,
  messageId: PropTypes.string,
};

export default connect(mapStateToProps)(injectIntl(TitleBar));
