import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { eField } from '../../configs';
import * as ConceptActions from '../../../custom-mode/concepts/actions';
import NumberFormat from 'react-number-format';
import { eLocale } from '../../../core/configs';
import { handleFocus } from '../../utils';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';

export class PersonalDividendTaxRate extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    personalDividendTaxRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    conceptActions: PropTypes.object,
    activeScenarioTabId: PropTypes.string,
    sceActions: PropTypes.object,
  };

  onValueChange = (id, value) => {
    this.props.conceptActions.setConceptFieldValue({
      fieldName: id,
      fieldValue: value,
      scenarioTabId: this.props.activeScenarioTabId,
    });
    this.props.sceActions.toggleOutOfDate(true);
  };
  render() {
    const maxPersonalDividendTaxRate = 100;
    const { locale, personalDividendTaxRate } = this.props;
    return (
      <React.Fragment>
        <div className={`mwi-padding-right20 p-col-5 p-lg-5 p-xl-5 p-sm-6`} id="personal-dividend-wrapper">
          <div className="mwi-label" id="personalDividendTaxRateLabel">
            <span>
              <FormattedMessage id="concept.personalDividendTaxRate" />
            </span>
          </div>
          <div className="mwi-w100 ui-input">
            <NumberFormat
              className={'mwi-widget mwi-input number-data' + (locale === eLocale.fr && '')}
              value={+personalDividendTaxRate}
              decimalScale={2}
              fixedDecimalScale
              suffix=" %"
              decimalSeparator={locale === eLocale.en ? '.' : ','}
              allowNegative={false}
              isAllowed={(values) => +values.value >= 0 && +values.value <= maxPersonalDividendTaxRate}
              name={eField.personalDividendTaxRate}
              id={eField.personalDividendTaxRate}
              onValueChange={(values) => this.onValueChange(eField.personalDividendTaxRate, values.value)}
              aria-labelledby="personalDividendTaxRateLabel"
              aria-required="true"
              autoComplete="off"
              onFocus={handleFocus}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export const mapStateToProps = ({ app, conceptTabNavs, scenarioTabNavs }) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;
  return {
    locale: app.locale,
    personalDividendTaxRate: conceptTabNavs[scenarioTabId].personalDividendTaxRate,
    activeScenarioTabId: scenarioTabId,
  };
};
const mapDispatchToProps = (dispatch) => ({
  conceptActions: bindActionCreators(ConceptActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PersonalDividendTaxRate));
