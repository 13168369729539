import moment from 'moment';

export const validate = (type, value, locale) => {
  // if there is no error it should return empty string so that it can remove error message from the UI
  let errorMessage = '';

  if (type === 'dob' && value && value.length) {
    // if year is greater length of 4 then error: 12Jan1990112
    // const dobSubStr = value.substr(-5);
    // if (/^[0-9]+$/.test(dobSubStr.substring(0, 1))) {
    //   return 'Invalid date';
    // }

    moment.locale(locale);

    // Moment's parser is very forgiving so it allows some different format from DD/MMM/YYYY
    // if you want to make Moment use strict parsing, specify 'true' for the last arument like moment(value, 'DD/MMM/YYYY', true)
    // then length doens't need to be checked. Empty DOB should be fine.
    // ex: 11Jun2011, 9Jun2011, 01-Jun-2011 those cases are all valid.
    // 		 But DD and MMM shouldn't be switched so Jun012011 is not valid.
    const dobMoment = moment(value, 'DD/MM/YYYY', true);

    if (dobMoment.isAfter(moment()) || !dobMoment.isValid()) {
      errorMessage = 'Invalid date';
    }
  }

  return errorMessage;
};
