import * as actionTypes from '../../core/actionTypes';
import {
  eBusinessType,
  eCoverageType,
  eDefault,
  eDefaultRatings,
  eField,
  eProduct,
  eRider,
  eSmokingStatus,
} from '../../core/configs';
import {
  assignDef,
  getPerformaxTermRiderDuration,
  getTermRiderDuration,
  getTermRiderCoverageOption,
} from '../../core/utils';
import _ from 'lodash';
import { riderSeq, scenarioSeq } from '../../core/sequenceUtils';
import * as reducerHelper from '../../helper/reducer-helper';

const defaultRider = {
  coverageType: eDefault.coverageType,
  coverageOption: eDefault.coverageOption,
  coverageAmount: eDefault.rider[`${eRider.term}Amount`],
  coverageDuration: getTermRiderDuration(0),
  isFolded: false,
  clientIds: [],
};

const defaultState = {
  allRiders: [],
};

// TODO refactor to support for multiple scenarios
//	"Scenario.1": ...defaultState
const initialState = {
  activeTabId: scenarioSeq(true),
  [scenarioSeq(true)]: { ...defaultState },
};

const getAdjustedDuration = (age, currentDuration) => {
  const duration = getTermRiderDuration(age);
  return duration < currentDuration ? duration : currentDuration;
};

const termRiderReducer = (state, action) => {
  state = state === undefined ? initialState : state;
  switch (action.type) {
    case actionTypes.OPEN_SAVEDCASE: {
      const { scenarioTabNavs, termRiders } = action.payload.model;
      // This logic to assign all the missing propertied from
      // the initial/default to the saved old object with default value.
      const targetState = termRiders;
      assignDef(targetState, initialState);
      scenarioTabNavs.tabNavs.forEach((Scenario) => {
        assignDef(targetState[Scenario.id], defaultState);
      });
      return {
        ...targetState,
      };
    }

    case actionTypes.ADD_TERM_RIDER: {
      const newState = _.cloneDeep(state[state.activeTabId]);
      const id = riderSeq();
      const clients = action.payload;
      const allClients = clients.allClients;
      const rider = defaultRider;
      rider.coverageDuration = getTermRiderDuration(clients[allClients[0]].age);

      newState.allRiders = [...newState.allRiders, id];
      newState[id] = {
        ...rider,
        id,
        clientIds: allClients,
        clientId: allClients[0],
        ratings: {},
      };
      _.forEach(allClients, (cliId) => {
        newState[id].ratings[cliId] = { ...eDefaultRatings };
      });

      return {
        ...state,
        [state.activeTabId]: newState,
      };
    }

    case actionTypes.REMOVE_TERM_RIDER: {
      let newState = _.cloneDeep(state[state.activeTabId]);

      const deletingRiderId = action.payload;
      newState.allRiders = _.filter(newState.allRiders, (riderId) => riderId !== deletingRiderId);
      newState = _.omit(newState, [deletingRiderId]);

      return {
        ...state,
        [state.activeTabId]: newState,
      };
    }

    case actionTypes.ADD_CLIENT: {
      const newState = _.cloneDeep(state[state.activeTabId]);
      const clientId = action.payload.clientId;

      _.forEach(newState.allRiders, (riderId) => {
        const { ratings, clientIds } = newState[riderId];
        if (!clientIds.includes(clientId)) {
          clientIds.push(clientId);
          ratings[clientId] = { ...eDefaultRatings };
        }
      });

      return {
        ...state,
        [state.activeTabId]: newState,
      };
    }
    default:
      return termRiderReducer2(state, action);
  }
};

const termRiderReducer2 = (state, action) => {
  switch (action.type) {
    case actionTypes.MWI_ON_CHANGE: {
      const payload = action.payload;
      if (payload.target === 'termRider') {
        return getTermRiderState(payload, state);
      } else {
        if (payload.product === eProduct.PAR) {
          return getNewStateParProduct(state, payload);
        }
      }
      return state;
    }

    case actionTypes.COMMON_INITIALIZE: {
      return initialState;
    }
    case actionTypes.ADD_SCENARIO_TABNAVS: {
      return reducerHelper.getAddScenarioTabNavsState(state, action);
    }
    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return reducerHelper.getClickScenarioTabNavsState(state, action);
    }
    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      return reducerHelper.getRemoveScenarioTabNavsState(state, action);
    }

    default:
      return termRiderReducer3(state, action);
  }
};

const termRiderReducer3 = (state, action) => {
  switch (action.type) {
    case actionTypes.SELECT_TERM_RIDER_CLIENT: {
      const newState = _.cloneDeep(state[state.activeTabId]);

      const payload = action.payload;
      const riderId = payload.riderId;
      const rider = newState[riderId];
      rider.clientId = payload.selectedClientId;
      rider.coverageDuration = getAdjustedDuration(payload.clients[rider.clientId].age, rider.coverageDuration);

      return {
        ...state,
        [state.activeTabId]: newState,
      };
    }

    case actionTypes.REMOVE_CLIENT: {
      const newState = _.cloneDeep(state[state.activeTabId]);
      const clientId = action.payload.clientId;

      _.forEach(newState.allRiders, (riderId) => {
        const { clientIds, ratings } = newState[riderId];
        if (clientIds.includes(clientId)) {
          clientIds.pop(clientId);
          delete ratings[clientId];
        }
      });

      return {
        ...state,
        [state.activeTabId]: newState,
      };
    }

    case actionTypes.UPDATE_TERM_RIDER_RATING: {
      const { riderIdP, clientIdP, ratings } = action.payload;

      return {
        ...state,
        [state.activeTabId]: {
          ...state[state.activeTabId],
          [riderIdP]: {
            ...state[state.activeTabId][riderIdP],
            ratings: { ...state[state.activeTabId][riderIdP].ratings, [clientIdP]: { ...ratings } },
          },
        },
      };
    }

    case actionTypes.LOADED_INFORCE_POLICY: {
      const inforceIllustration = action.payload.policyData.illustration;
      const coverages = inforceIllustration.policy.coverage;
      const parties = inforceIllustration.party;
      const product = inforceIllustration.policy.product;

      const newState = loadInforceTermRiders(coverages, parties, product);

      return {
        ...newState,
      };
    }

    default: {
      return state;
    }
  }
};

const getInforceCoverageInfo = (newRider, coverage, product) => {
  const age = newRider.age ? newRider.age : 0;
  newRider.coverageId = coverage.coverageId;
  newRider.coverageOption = [eProduct.Performax, eProduct.SB].includes(product)
    ? getTermRiderCoverageOption(coverage)
    : coverage.product;
  newRider.coverageAmount = coverage.coverageAmount;
  newRider.coverageType = coverage.coverageType;
  newRider.healthstyle = coverage.healthstyle ? coverage.healthstyle : eSmokingStatus.nonsmoker;
  // todo: need to work on joint age logic for  JFTD coverage type once we get clarity from BE
  newRider.coverageDuration = [eProduct.Performax, eProduct.SB].includes(product)
    ? getPerformaxTermRiderDuration(age, newRider.coverageOption)
    : getTermRiderDuration(age);
};

const isTorOrSpr = (product) => product === eRider.tor || product === eRider.spr;
const isValidInforceTir = (coverage) => {
  return (
    coverage.indicatorCode === eField.indicatorCode.termRider &&
    !isTorOrSpr(coverage.product) &&
    coverage.status === 'Inforce'
  );
};

const loadInforceTermRiders = (coverages, parties, product) => {
  const newState = { ...initialState };
  newState[newState['activeTabId']] = { allRiders: [] };
  coverages.forEach((coverage) => {
    if (isValidInforceTir(coverage)) {
      const newRider = {};
      const id = coverage.coverageId;
      const clientId = coverage.life[0].partyId;
      if (coverage.associatedCoverageId) {
        const associatedCoverage = newState[newState.activeTabId][coverage.associatedCoverageId];
        if (associatedCoverage) {
          newState[newState.activeTabId][coverage.associatedCoverageId] = combinedInforceTermRider(
            coverage,
            associatedCoverage,
            clientId,
            product
          );
          return; // Skip to the next coverage
        }
      }
      newRider.associatedCoverageId = coverage.associatedCoverageId;
      const partyId = coverage.life[0].partyId;
      const partyIndex = parties.findIndex((pty) => pty.partyId === partyId);
      let ratings = { ...eDefaultRatings };
      newRider.age = coverage.equivalentAge;
      if (partyIndex !== -1) {
        newRider.id = partyId;
        newRider.firstName = parties[partyIndex].firstName;
        newRider.lastName = parties[partyIndex].lastName;
        newRider.sex = parties[partyIndex].gender;
      }
      if (coverage.lifeRating) {
        ratings = reducerHelper.getInforceRatingsFromCoverage(ratings, coverage, product);
      }
      getInforceCoverageInfo(newRider, coverage, product);

      newRider.isFolded = false;
      newRider.id = id;
      newRider.clientId = clientId;
      newRider.costOfInsurance = coverage.costOfInsurance && coverage.costOfInsurance.type;
      newRider['clientIds'] = [];
      newRider['clientIds'].push(clientId);
      newRider.ratings = {};
      newRider.ratings[clientId] = { ...ratings };
      if (isProductAllowThirdPartyTIR(product) || isRiderEligibleForInforce(coverages, coverage, partyId)) {
        newState[newState.activeTabId][id] = newRider;
        newState[newState.activeTabId]['allRiders'].push(id);
      }
    }
  });
  return newState;
};

const isProductAllowThirdPartyTIR = (product) => [eProduct.PG, eProduct.Performax, eProduct.SB].includes(product);

const isRiderEligibleForInforce = (coverages, coverage, partyId) => {
  let isEligible = false;

  const baseCoverages = coverages.filter(
    (cvg) => cvg.indicatorCode === eField.indicatorCode.base && cvg.status === eBusinessType.IF
  );
  baseCoverages.forEach((cvg) => {
    if (cvg.life.find((life) => life.partyId === partyId)) {
      const associatedCoverage = coverages.find((x) => x.coverageId === coverage.associatedCoverageId);
      if (
        !associatedCoverage ||
        (associatedCoverage && cvg.life.find((life) => life.partyId === associatedCoverage.life[0].partyId))
      ) {
        isEligible = true;
      }
    }
  });
  return isEligible;
};

const combinedInforceTermRider = (coverage, jointCoverage, clientId, product) => {
  const coverageDuration = getTermRiderDuration(coverage.equivalentAge ? coverage.equivalentAge : 0);
  jointCoverage.coverageType =
    coverage.coverageType === eCoverageType?.jointFirst ? coverage.coverageType : eCoverageType.combined;
  jointCoverage.coverageDuration =
    jointCoverage.coverageDuration < coverageDuration ? jointCoverage.coverageDuration : coverageDuration;
  let ratings = { ...eDefaultRatings };
  if (coverage.lifeRating) {
    ratings = reducerHelper.getInforceRatingsFromCoverage(ratings, coverage, product);
  }
  jointCoverage.ratings[clientId] = { ...ratings };
  jointCoverage['clientIds'].push(clientId);
  return jointCoverage;
};

const getNewStateClientAge = (state, payload) => {
  const newState = _.cloneDeep(state[state.activeTabId]);
  // change the Term rider duration
  // if the new age causes it to go beyond the max. age (see getTermRiderDuration)
  _.forEach(state[state.activeTabId].allRiders, (riderId) => {
    const rider = state[state.activeTabId][riderId];
    if (rider.clientIds.includes(payload.clientId)) {
      newState[riderId].coverageDuration = getAdjustedDuration(payload.value, rider.coverageDuration);
    }
  });
  return {
    ...state,
    [state.activeTabId]: newState,
  };
};

const getNewStateSingleCoverage = (state, payload) => {
  const newState = _.cloneDeep(state[state.activeTabId]);
  _.forEach(state[state.activeTabId].allRiders, (riderId) => {
    const rider = state[state.activeTabId][riderId];
    const newRider = newState[riderId];
    // if coverage type changed to single,
    // make sure rider's coverage type is single...
    if (newRider.coverageType !== eCoverageType.single) {
      newRider.coverageType = eCoverageType.single;
    }
    // ...and make sure term rider client points to single client
    if (rider.clientId !== rider.clientIds[0]) {
      newRider.clientId = rider.clientIds[0];
      // change the Term rider duration
      // if the single client's age causes it to go beyond the max. age (see getTermRiderDuration)
      newRider.coverageDuration = getAdjustedDuration(
        payload.clients[newRider.clientId].age,
        newRider.coverageDuration
      );
    }
  });
  return {
    ...state,
    [state.activeTabId]: newState,
  };
};

const getNewStateParProduct = (state, payload) => {
  if (payload.target === 'client' && payload.field === eField.age) {
    return getNewStateClientAge(state, payload);
  } else {
    if (payload.target === 'coverage' && payload.field === eField.covType && payload.value === eCoverageType.single) {
      return getNewStateSingleCoverage(state, payload);
    }
  }
  return state;
};

const getTermRiderState = (payload, state) => {
  const { riderId, data } = payload;
  const changedData = data ? data : { [payload.field]: payload.value };

  const checkCombinedCoverageType = payload.field === eField.covType && payload.value === eCoverageType.combined;
  if (payload.field === eField.coverageDuration || checkCombinedCoverageType) {
    changedData.coverageDuration =
      payload.field === eField.coverageDuration ? payload.value : state[state.activeTabId][riderId].coverageDuration;
  }
  return {
    ...state,
    [state.activeTabId]: {
      ...state[state.activeTabId],
      [riderId]: {
        ...state[state.activeTabId][riderId],
        ...changedData,
      },
    },
  };
};

export default termRiderReducer;
