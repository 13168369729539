import * as actionTypes from '../core/actionTypes';
import { eBusinessType } from '../core/configs';
import { handleInforcePolicyRetrievalError } from '../core/utils';

const initialState = {
  product: '',
  entitledProduct: '',
  policyNumber: '',
  errorStatus: '',
  businessType: '',
  isLoading: false,
  isError: false,
  errors: '',
};

export default function landingPageReducer(state, action) {
  state = state === undefined ? initialState : state;
  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      if (state.businessType === eBusinessType.IF) {
        return state;
      }
      return initialState;
    }
    case actionTypes.SELECT_PRODUCT: {
      return {
        product: action.payload,
        businessType: eBusinessType.NB,
      };
    }

    case actionTypes.SELECT_BUSINESS_TYPE: {
      return {
        ...state,
        businessType: action.payload,
        isError: false,
        errors: '',
      };
    }

    case actionTypes.MWI_ON_CHANGE: {
      if (action.payload.target === 'landingPage') {
        return { ...state, [action.payload.field]: action.payload.value };
      }
      return state;
    }

    default:
      return landingPageReducer2(state, action);
  }
}

const landingPageReducer2 = (state, action) => {
  switch (action.type) {
    case actionTypes.GET_INFORCE_POLICY: {
      return {
        ...state,
        product: action.payload.product,
        policyNumber: action.payload.policyNumber,
        businessType: eBusinessType.IF,
        isError: false,
        isLoading: true,
      };
    }
    case actionTypes.SEARCHING_INFORCE_POLICY: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        errors: '',
      };
    }
    case actionTypes.SEARCHED_INFORCE_POLICY: {
      return {
        ...state,
        isError: false,
        errors: '',
        isLoading: false,
        entitledProduct: action.payload.entitledPolicy[0].product,
      };
    }

    case actionTypes.LOADED_INFORCE_POLICY: {
      return { ...state, isError: false, errors: '', isLoading: false };
    }

    case actionTypes.ERROR_INFORCE_POLICY: {
      const errorState = handleInforcePolicyRetrievalError(action);
      const responseState = { ...state, ...errorState, isLoading: false };
      return responseState;
    }

    // TODO put into Saga to navigate to custom mode page
    case actionTypes.CLICK_PRODUCT_BTN:
    case actionTypes.CLICK_QUICK_MODE_BTN: {
      return state;
    }

    case actionTypes.AUTH_USER: {
      return { ...state, errors: '', isError: false, isLoading: false, policyNumber: '' };
    }

    default:
      return landingPageReducer3(state, action);
  }
};

const landingPageReducer3 = (state, action) => {
  switch (action.type) {
    case actionTypes.LOADING_INFORCE_REQUEST_FORM:
    case actionTypes.LOADED_INFORCE_REQUEST_FORM: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case actionTypes.ERROR_INFORCE_REQUEST_FORM: {
      const responseState = { ...state, isLoading: false };
      return responseState;
    }

    case actionTypes.RESET_INFORCE_SCREEN: {
      return {
        ...state,
        errors: '',
        isError: false,
        policyNumber: '',
        isLoading: false
      };
    }

    default:
      return state;
  }
};

export const getProduct = (state) => state.product;
