import React, { useState } from "react";
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Tabs } from '@manulife/mux';
import './dashboard.css';
import { DASH_APPLICATION_TAB_ID, DASH_APPLICATION_TCA_TAB_ID } from '../../core/constants';
import DashboardApplicationsNewTab from './dashboard-applications-tab';

const DashboardApplicationsTab = (props) => {
  const {
    intl,
    canAssignCase,
    partyId,
    role,
    dda2Toggled,
    tca2Toggled,
    handleOnDda2Toggle,
    handleOnTca2Toggle,
    subTabChange,
    selectedSubTab,
  } = props;

  const [ selectedTab, setSelectedTab ] = useState(DASH_APPLICATION_TAB_ID);
  const tabApplicationChange = (value) => {
    setSelectedTab(value);
    subTabChange(value);
    handleOnDda2Toggle(false);
    handleOnTca2Toggle(false);
  };

  return (
    <div className="content-wrapper dashboard-wrapper">
      <Tabs
        tabItems={[
          {
            id: DASH_APPLICATION_TAB_ID,
            label: intl.formatMessage({ id: 'dashboard.tab.new.applications' }),
            value: DASH_APPLICATION_TAB_ID,
          },
          {
            id: DASH_APPLICATION_TCA_TAB_ID,
            label: intl.formatMessage({ id: 'dashboard.tab.tca.applications' }),
            value: DASH_APPLICATION_TCA_TAB_ID,
          },
        ]}
        selectedTab={selectedTab}
        variant={Tabs.VARIANT.DEFAULT}
        onChange={tabApplicationChange}
        tabAlignment={Tabs.ALIGNMENT.LEFT}
        customStyle={{
          tabPanelStyle: {
            padding: '10px',
          },
          tabItemStyle: {
            margin: '0 35px 0 0px',
          },
          tabsWrapperStyle: {
            height: '50px',
          },
        }}
      >
        <Tabs.Panel>
          <DashboardApplicationsNewTab
            intl={intl}
            canAssignCase={canAssignCase}
            partyId={partyId}
            role={role}
            dda2Toggled={dda2Toggled}
            handleOnDda2Toggle={handleOnDda2Toggle}
            tca2Toggled={tca2Toggled}
            handleOnTca2Toggle={handleOnTca2Toggle}
            selectedSubTab={selectedSubTab}
          />
        </Tabs.Panel>
        <Tabs.Panel>
          <DashboardApplicationsNewTab
            intl={intl}
            canAssignCase={canAssignCase}
            partyId={partyId}
            role={role}
            dda2Toggled={dda2Toggled}
            handleOnDda2Toggle={handleOnDda2Toggle}
            tca2Toggled={tca2Toggled}
            handleOnTca2Toggle={handleOnTca2Toggle}
            selectedSubTab={selectedSubTab}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

DashboardApplicationsTab.propTypes = {
  intl: intlShape.isRequired,
  canAssignCase: PropTypes.bool,
  partyId: PropTypes.string,
  role: PropTypes.string,
  selectedSubTab: PropTypes.string,
  appActions: PropTypes.object,
  dda2Toggled: PropTypes.bool,
  tca2Toggled: PropTypes.bool,
  handleOnDda2Toggle: PropTypes.func,
  handleOnTca2Toggle: PropTypes.func,
  subTabChange: PropTypes.func,
  state: PropTypes.object,
};

export default DashboardApplicationsTab;
