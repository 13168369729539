import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';
import MWIPanel from '../../core/components/mwi-panel';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as AppActions from '../../core/actions';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import * as TermRiderActions from './actions';
import TermRider from './term-rider';
import './term-riders.css';
import { eMaxVal, eActionEvent, eField } from '../../core/configs';
import { trackUserActions } from '../../core/utils';

export class TermRiders extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    product: PropTypes.string,
    udmEnabled: PropTypes.bool,
    isTermRiderFolded: PropTypes.bool,
    termRiders: PropTypes.object.isRequired,
    clients: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    appActions: PropTypes.object,
    riderActions: PropTypes.object,
    sceActions: PropTypes.object,
    intl: PropTypes.object,
  };

  static defaultProps = {
    clients: [],
  };

  constructor(props) {
    super(props);

    this.addRider = this.addRider.bind(this);
    this.removeRider = this.removeRider.bind(this);
    this.lastRider = React.createRef();
    this.state = { newRider: false };
  }

  componentDidUpdate() {
    if (this.state.newRider && this.lastRider !== null) {
      this.setState({ newRider: false }); // react permits this when wrapped in condition to prevent infinite loop
      if (typeof this.lastRider.focus === 'function') {
        this.lastRider.focus();
      }
    }
  }

  dispatchAction(data, target = 'termRiders', isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      ...data,
    });
  }

  addRider() {
    // TODO pass scenario id for mulitiple secnarios
    trackUserActions(eActionEvent.selection, 'TermRider', 'Add');
    this.props.riderActions.addTermRider(this.props.clients);
    this.props.sceActions.toggleOutOfDate(true);
    this.setState({ newRider: true });
  }

  removeRider(riderId) {
    trackUserActions(eActionEvent.selection, 'TermRider', 'Remove');
    this.props.riderActions.removeTermRider(riderId);
    this.props.sceActions.toggleOutOfDate(true);
  }

  toggleRiderFold(value, riderId) {
    this.dispatchAction({ field: eField.isFolded, value, riderId }, 'termRider');
  }

  render() {
    const { termRiders, product } = this.props;
    const hasRider = termRiders.allRiders.length > 0;
    const showAddTermRiderPanel = termRiders.allRiders.length < eMaxVal(product).numTermRiders;
    return (
      <div className="term-riders-wrapper">
        {hasRider && (
          <div>
            {termRiders.allRiders.map((riderId, index) => (
              <div className="p-col-12" key={riderId}>
                <MWIAccordion
                  ref={(el) => (this.lastRider = el)}
                  className={`accordion mwi-reverse-margin15 mwi-lightGreen ${index === 0 ? 'first-child' : ''}`}
                  title={
                    <span>
                      <FormattedMessage id="rider.title" /> {index + 1}
                    </span>
                  }
                  removeBtn
                  folded={termRiders[riderId].isFolded}
                  onClickChange={() => this.toggleRiderFold(!termRiders[riderId].isFolded, riderId)}
                  rightContent={
                    !this.props.inforce && (
                      <div
                        className="remove-button"
                        onClick={() => this.removeRider(riderId)}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            this.removeRider(riderId);
                          }
                        }}
                        aria-label={this.props.intl.formatMessage({ id: 'common.remove' })}
                        role="button"
                        tabIndex="0"
                      >
                        <FormattedMessage id="common.remove" />
                      </div>
                    )
                  }
                >
                  {this.props.udmEnabled && <div className="udm-id">{`${riderId}`}</div>}
                  <TermRider riderInfo={termRiders[riderId]} riderIndex={index} />
                </MWIAccordion>
              </div>
            ))}
          </div>
        )}
        {showAddTermRiderPanel && !this.props.inforce && (
          <MWIPanel
            skipTab
            className={`mwi-reverse-margin15 mwi-lightGreen add-wrapper ${!hasRider ? 'first-child' : ''}`}
          >
            <div
              tabIndex="0"
              id="addTermRider"
              className="title-group"
              onClick={() => this.addRider()}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.addRider();
                }
              }}
              aria-label={this.props.intl.formatMessage({ id: 'rider.addtermrider' })}
              role="button"
            >
              <i className="material-icons add-term-rider-icon">add_circle_outline</i>
              <FormattedMessage id="rider.addtermrider" />
            </div>
          </MWIPanel>
        )}
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, termRiders, clients }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const scenarioTabId = scenarioTabNavs.activeTabId;
  return {
    locale: app.locale,
    inforce: app.inforcePolicy,
    udmEnabled: scenarioTabNavs.udmEnabled,
    isTermRiderFolded: activeScenario.isTermRiderFolded,
    product: activeScenario.product,
    termRiders: termRiders[scenarioTabId],
    clients: clients[scenarioTabId],
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  riderActions: bindActionCreators(TermRiderActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TermRiders));
