import './App.css';

import * as AppActions from './core/actions';
import * as ScenarioActions from './custom-mode/scenario-tab-navs/actions';

import { IntlProvider, addLocaleData } from 'react-intl';
import { JUNCTION, configs, logoutUrl, sessionConfig } from './core/configs';
import { MuxProvider, THEMES, Toast } from '@manulife/mux';
import React, { PureComponent } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import AppFooter from './core/components/footer';
import CustomMode from './custom-mode';
import ErrorDialog from './core/components/error-dialog';
import Header from './core/components/header';
import IdleTimer from 'react-idle-timer';
import LandingPage from './landing-page';
import Profile from './profile';
import ProgressDialog from './core/components/progress-dialog';
import PropTypes from 'prop-types';
import Report from './report';
import SideNavBar from './core/components/side-nav-bar';
import TermRenewalCalc from './term-renewal-calc';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import en from 'react-intl/locale-data/ca';
import fr from 'react-intl/locale-data/fr';
import { isDevFeatureFlagSet } from './core/utils';
import messageEN from './core/translations/en';
import messageFR from './core/translations/fr';
import myCases from './mycases';
import sharelink from './views/web-illustrations/sharelink';
import LogoutHandler from './core/components/logout-handler';

const messages = { en: messageEN, fr: messageFR };
addLocaleData([...en, ...fr]);

export class App extends PureComponent {
  static propTypes = {
    app: PropTypes.object,
    udmEnabled: PropTypes.bool,
    intl: PropTypes.object,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    timeoutValue: PropTypes.number,
    udmResponse: PropTypes.object,
    userID: PropTypes.string,
    idOfError: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onHideError = this.onHideError.bind(this);
    this.onOK = this.onOK.bind(this);
    this.logout = this.logout.bind(this);
    this.checkCustomMode = this.checkCustomMode.bind(this);
    this.checkIdle = this.checkIdle.bind(this);
  }

  componentDidMount() {
    this.loginBackend();
  }

  onHideError() {
    this.props.appActions.dispatchMWIOnChange({
      target: 'app',
      field: 'errorMessage',
      value: '',
    });
  }

  onOK() {
    this.onHideError();
  }

  loginBackend() {
    this.props.appActions.authenticateUser();
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(logoutUrl);
  }

  dispatchAction(field, value, target = 'header', isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
      userId: this.props.userID,
    });
  }

  checkCustomMode() {
    return configs.routes.customMode || configs.routes.clientCoverage;
  }

  checkIdle(timeoutValue) {
    return (
      !isDevFeatureFlagSet() && (
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          timeout={timeoutValue}
        />
      )
    );
  }

  render() {
    const { app, timeoutValue } = this.props;

    return (
      <div>
        <IntlProvider locale={app.locale} messages={messages[app.locale]}>
          <React.Fragment>
            <Router basename={JUNCTION}>
              <MuxProvider navigationProvider={{ isSignedIn: true }} themeProvider={{ theme: THEMES.canadianTheme }} notificationProvider={{ interval: 4000 }}>
                <div className="app" data-testid="app-test">
                  <React.Fragment>
                    {this.checkIdle(timeoutValue)}
                    <Header />
                    <LogoutHandler props={this.props} />
                    <div className="col- m-0 p-0 no-gutters leftSideBarLayout">
                      <SideNavBar />
                    </div>
                    <div className="col-lg col-md col-sm col-xs paddingLeft80">
                      <Switch>
                        <Route exact path="/" component={LandingPage} />
                        <Route exact path="/illustrations" component={LandingPage} />
                        <Route path={this.checkCustomMode()} component={CustomMode} />
                        <Route path={configs.routes.designReport} component={Report} />
                        <Route path={configs.routes.termRenewalCalc} component={TermRenewalCalc} />
                        <Route path={configs.routes.profile} component={Profile} />
                        <Route path={configs.routes.myCases} component={myCases} />
                        <Route path={configs.routes.shareLink} component={sharelink} />
                        <Redirect to="/illustrations" />
                      </Switch>
                    </div>

                    <AppFooter {...this.props} />
                  </React.Fragment>
                </div>
                <Toast />
              </MuxProvider>
            </Router>
            {
              // isLoading is changed in the reportSagas
              app.isRequesting && (
                <React.Fragment>
                  <div className="mwi-backdrop" />
                  <ProgressDialog
                    isOpen={app.isRequesting}
                    contentLabel="common.loading"
                    progressMessage="common.progress"
                  />
                </React.Fragment>
              )
            }
            {/* Modal for UDM Illustration errors */}
            <ErrorDialog from="app" />
          </React.Fragment>
        </IntlProvider>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, udmResponse }) => {
  return {
    app,
    udmResponse,
    timeoutValue: sessionConfig.idleTime,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
