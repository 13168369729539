import React from 'react';
import PropTypes from 'prop-types';
import './page-option-checkbox.css';

export const PageOptionCheckbox = ({ value, label, isSelected, onChange, disabled }) => (
  <div className="p-col-12 pageoption-checkbox-wrapper">
    <input
      className="pageoption-checkbox focusOutline"
      type="checkbox"
      id={`rpt-${value}`}
      value={value}
      onChange={onChange}
      checked={isSelected}
      disabled={disabled}
      aria-describedby="reportPages"
    />
    <label htmlFor={`rpt-${value}`} className={`${disabled ? 'disabled' : ''}`}>
      {label}
    </label>
  </div>
);

export default PageOptionCheckbox;

PageOptionCheckbox.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

PageOptionCheckbox.defaultProps = {
  disabled: false
};
