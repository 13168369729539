import React from 'react';
import { eProduct } from '../../core/configs';
import PropTypes from 'prop-types';
import SummaryPremiumTab from './summary-premium-tab';
import SummaryFtrcPremiumTab from './summary-ftrc-premium-tab';
import SummaryPremiumTabMUL from './summary-premium-tab-mul';

export default function SummaryPremiumSection(props) {
  const {
    product,
    depositOption,
    intl,
    clientSummary,
    udmResponse,
    app,
    totalPremium,
    initialPremium,
    premiumFrequency,
    productValidationError,
    isLevelCOI,
    isVitalityPlusSelected,
    maxAdditionalDeposit,
    annualizedModalPayment,
  } = props;
  switch (product) {
    case eProduct.MUL:
      return (
        <SummaryPremiumTabMUL
          app={app}
          udmResponse={udmResponse}
          depositOption={depositOption}
          isLevelCOI={isLevelCOI}
          isVitalityPlusSelected={isVitalityPlusSelected}
        />
      );
    case eProduct.FTRC:
      return (
        <SummaryFtrcPremiumTab
          depositOption={depositOption}
          intl={intl}
          udmResponse={udmResponse}
          clientSummary={clientSummary}
          app={app}
          totalPremium={totalPremium}
          initialPremium={initialPremium}
          premiumFrequency={premiumFrequency}
          productValidationError={productValidationError}
        />
      );
    default:
      return (
        <SummaryPremiumTab
          depositOption={depositOption}
          intl={intl}
          udmResponse={udmResponse}
          app={app}
          product={product}
          totalPremium={totalPremium}
          initialPremium={initialPremium}
          premiumFrequency={premiumFrequency}
          productValidationError={productValidationError}
          maxAdditionalDeposit={maxAdditionalDeposit}
          annualizedModalPayment={annualizedModalPayment}
        />
      );
  }
}

SummaryPremiumSection.propTypes = {
  product: PropTypes.string,
  productValidationError: PropTypes.bool,
  app: PropTypes.object,
  udmResponse: PropTypes.object,
  depositOption: PropTypes.object,
  intl: PropTypes.object,
  totalPremium: PropTypes.string,
  initialPremium: PropTypes.string,
  premiumFrequency: PropTypes.string,
  clientSummary: PropTypes.array,
  isLevelCOI: PropTypes.bool,
  isVitalityPlusSelected: PropTypes.bool,
  maxAdditionalDeposit: PropTypes.string,
  annualizedModalPayment: PropTypes.string,
};
