import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import AppFooter from './footer';
import { withRouter } from 'react-router-dom';

export const mapStateToProps = ({ app }) => ({
  locale: app.locale
});

const ConnectedFooter = withRouter(connect(mapStateToProps)(injectIntl(AppFooter)));
export default ConnectedFooter;
