import React, { PureComponent } from 'react';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import '../landing-page.css';
import PropTypes from 'prop-types';

export class ParInforceDescription extends PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    goToIRForm: PropTypes.func,
    isLoading: PropTypes.bool,
    product: PropTypes.string,
    isVitalityPlus: PropTypes.bool
  };

  render() {
    const productBullets = this.props.isVitalityPlus?
    Array.from({ length: 9 }, (v, i) => i).map((index) => (
      <li key={index}>
        <FormattedMessage id={`landing.parInforce.vp.productBullet${index}`} />
      </li>
    )):
    Array.from({ length: 8 }, (v, i) => i).map((index) => (
      <li key={index}>
        <FormattedMessage id={`landing.parInforce.productBullet${index}`} />
      </li>
    ));
    const specialRequestBullets = Array.from({ length: 3 }, (v, i) => i).map((index) => (
      <li key={index}>
        {this.props.isVitalityPlus?
        <FormattedMessage id={`landing.parInforce.vp.specialRequestBullet${index}`} />:
        <FormattedMessage id={`landing.parInforce.specialRequestBullet${index}`} />
        }
      </li>
    ));
    const limitationsBullets = this.props.isVitalityPlus?
    Array.from({ length: 3 }, (v, i) => i).map((index) => (
      <li key={index}>
        <FormattedMessage id={`landing.parInforce.vp.limitationsBullet${index}`} />
      </li>
    )):
    Array.from({ length: 4 }, (v, i) => i).map((index) => (
      <li key={index}>
        <FormattedMessage id={`landing.parInforce.limitationsBullet${index}`} />
      </li>
    ));

    return (
      <React.Fragment>
        <div className="p-col-12 product-description margin-bottom10">
          {this.props.isVitalityPlus?
            <FormattedHTMLMessage id="landing.parInforce.vp.openingParagraph" />:
            <FormattedHTMLMessage id="landing.parInforce.openingParagraph" />
          }
          <ul className="details-list">{productBullets}</ul>
        </div>
        <div className="p-col-12 product-description margin-bottom10">
          <FormattedMessage
            id="landing.parInforce.specialRequestParagraph"
            defaultMessage={`{part1}{link}{part2}`}
            values={{
              part1: <FormattedHTMLMessage id="landing.parInforce.specialRequestParagraph1" />,
              link: (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.goToIRForm();
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="url-link"
                >
                  <FormattedMessage
                    id={
                      this.props.isLoading
                        ? this.props.intl.formatMessage({ id: 'common.loading' })
                        : this.props.intl.formatMessage({ id: 'landing.parInforce.specialRequestForm' })
                    }
                  />
                </a>
              ),
              part2: <FormattedHTMLMessage id="landing.parInforce.specialRequestParagraph2" />,
            }}
          />
          <ul className="details-list">{specialRequestBullets}</ul>
        </div>
        <div className="p-col-12 product-description margin-bottom10">
          <FormattedHTMLMessage id="landing.parInforce.limitationsParagraph" />
          <ul className="details-list">{limitationsBullets}</ul>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(ParInforceDescription);
