import './mwi-dropdown.css';
import React, { PureComponent } from 'react';
import Select from 'react-select';
import SearchIconIndicator from './searchIconIndicator';
import PropTypes from 'prop-types';
import { isIOS } from '../../utils';

const selectDropDownSearch = function () {
  const {
    name,
    value,
    options,
    disabled,
    isRequired,
    dropdownStyle,
    topBarFieldStyle,
    tabIndex,
    errorState,
    placeholder,
    isPlaceholderAnOption,
    advisorProfile,
    role,
  } = this.props;

  const valueArray = options?.map((option) => option.value);
  const placeholderValue = getPlaceholderValue(placeholder, valueArray, value, options, advisorProfile);

  return (
    <div className={`mwi-dropdown-container ${dropdownStyle}`} tabIndex={tabIndex}>
      <Select
        id={`dd-${name}`}
        className={`select-dropdown-search focusOutline ${topBarFieldStyle} ${disabled ? 'disabled' : null} ${
          errorState && 'errorBorder'
        }`}
        name={`mwi-dropdown-search-${name}`}
        role={role}
        placeholder={placeholderValue}
        value={value}
        components={{ DropdownIndicator: SearchIconIndicator }}
        onChange={(e) => this.props.onChange(e)}
        disabled={disabled}
        aria-required={isRequired}
        options={options}
      >
        {placeholder && !isPlaceholderAnOption && (
          <option selected disabled hidden>
            {placeholder || 'Choose'}
          </option>
        )}
        {placeholder && isPlaceholderAnOption && <option disabled>{placeholder || 'Choose'}</option>}
        {options.map((option, index) => {
          return (
            // <option key={index} value={option.value} selected={value === option.value}>
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </Select>
    </div>
  );
};

const getPlaceholderValue = (placeholder, valueArray, value, options, advisorProfile) => {
  if (advisorProfile === null){
    return placeholder;
  }
  if (valueArray.length > 0) {
    return valueArray.includes(value) ? options.find((option) => option.value === value).label : options[0]?.label;
  }
  return placeholder;
};

// styles are defined in the theme css
export class MWIDropdownSearch extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }).isRequired
    ),
    onChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    grid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dropdownStyle: PropTypes.string,
    containerStyle: PropTypes.string,
    topBarFieldStyle: PropTypes.string,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.string,
    errorState: PropTypes.bool,
    locale: PropTypes.string,
    errorText: PropTypes.string,
    isPlaceholderAnOption: PropTypes.bool,
    role: PropTypes.string,
    appActions: PropTypes.object,
  };

  static defaultProps = {
    grid: '2',
    isRequired: true,
    disabled: false,
    dropdownStyle: '',
    containerStyle: '',
    placeholder: null,
    errorState: false,
    locale: '',
    errorText: '',
    isPlaceholderAnOption: true,
  };

  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const setTimeoutMilliSecondsDelay = 1200;
    if (isIOS() && this.props.value !== prevProps.value) {
      setTimeout(this.focus, setTimeoutMilliSecondsDelay, this.selectRef);
    }
  }

  focus(ddRef) {
    if (ddRef.current) {
      ddRef.current.focus();
    }
  }

  getDropdownProps() {
    return {
      disabled: this.props.disabled,
      placeholder: this.props.placeholder,
    };
  }

  render() {
    const { grid, containerStyle, errorState, errorText } = this.props;

    return (
      <div className={`${grid ? `p-col-${grid}` : ''} ${containerStyle}`}>
        {selectDropDownSearch.call(this)}
        {errorState && errorText && <span className="caseSave-error">{errorText}</span>}
      </div>
    );
  }
}

export default MWIDropdownSearch;
