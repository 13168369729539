import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';
import RiderList from './rider-list';
import RiderListHeader from './rider-list-header';
import UnSelectAllRiders from './unselect-all-riders';
import * as AppActions from '../../core/actions';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import * as RiderActions from './actions';
import './riders.css';
import { eProduct, eField } from '../../core/configs';
import { dropDownOptions } from '../../core/dropdown-config';

export class Riders extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    product: PropTypes.string,
    udmEnabled: PropTypes.bool,
    riders: PropTypes.object.isRequired,
    clients: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    appActions: PropTypes.object,
    riderActions: PropTypes.object,
    sceActions: PropTypes.object,
    intl: PropTypes.object,
    lcToggleClcCheckbox: PropTypes.bool,
  };

  static defaultProps = {
    clients: [],
  };

  dispatchAction(data, target = 'rider', isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      ...data,
    });
  }

  render() {
    const {
      locale,
      inforce,
      product,
      riders,
      clients,
      udmEnabled,
      intl,
      appActions,
      riderActions,
      sceActions,
      lcToggleClcCheckbox,
    } = this.props;
    const riderList = dropDownOptions(intl).riders;

    return (
      <div className="riders-wrapper">
        <MWIAccordion
          className={
            this.props.product === eProduct.PAR && !inforce
              ? 'accordion mwi-reverse-margin15 mwi-lightGreen'
              : 'accordion mwi-reverse-margin15 mwi-lightGreen first-child'
          }
          title={
            product === eProduct.LC ? (
              <FormattedMessage id="lc.riders.label.common" />
            ) : (
              <FormattedMessage id="common.riders" />
            )
          }
          folded={riders.isFolded}
          onClickChange={() => this.dispatchAction({ value: !riders.isFolded, field: eField.isFolded })}
        >
          <div className="rider-content">
            <div className="table-header">
              <RiderListHeader inforce={inforce} product={product} />
            </div>
            <RiderList
              locale={locale}
              inforce={inforce}
              product={product}
              riders={riders}
              clients={clients}
              udmEnabled={udmEnabled}
              riderList={riderList}
              appActions={appActions}
              riderActions={riderActions}
              sceActions={sceActions}
              intl={intl}
              lcToggleClcCheckbox={lcToggleClcCheckbox}
            />
          </div>
          {!this.props.inforce && (
            <UnSelectAllRiders intl={intl} riders={riders} riderActions={riderActions} sceActions={sceActions} />
          )}
        </MWIAccordion>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, riders, clients, coverageTabNavs }) => {
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  return {
    locale: app.locale,
    inforce: app.inforcePolicy,
    udmEnabled: scenarioTabNavs.udmEnabled,
    product: activeScenario.product,
    riders: riders[scenarioTabId],
    clients: clients[scenarioTabId],
    lcToggleClcCheckbox: coverageState[coverageState.activeTabId].lcToggleClcCheckbox,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  riderActions: bindActionCreators(RiderActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Riders));
