import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import CalculatorInput from '../calculator-input';
import { eLocale } from '../../core/configs';

import './calculator.css';
import MWISROnly from '../../core/components/mwi-sr-only';

export class Calculator extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    match: PropTypes.object,
    history: PropTypes.object,
    intl: PropTypes.object,
    isSpreadsheetView: PropTypes.bool,
  };

  indexOneCalculator(index, intl) {
    {
      return (
        <li key={index}>
          <FormattedMessage
            id="multipart"
            defaultMessage={`{part1}{part2}{part3}`}
            values={{
              part1: <FormattedMessage id="ftrc.calculator.header.details1A" />,
              part2: (
                <a
                  href={intl.formatMessage({ id: 'ftrc.calculator.header.details1.link' })}
                  target={'_blank'}
                  rel="noopener noreferrer"
                  className="label-link"
                >
                  {intl.formatMessage({ id: 'ftrc.calculator.header.details1.linkText' })}
                  <React.Fragment>
                    <i className="material-icons opens-in-new-tab" aria-hidden="true" style={{ color: '#2f622f' }}>
                      open_in_new
                    </i>
                    <MWISROnly label={intl.formatMessage({ id: 'sronly.newtab' })} />
                  </React.Fragment>
                </a>
              ),
              part3: <FormattedMessage id="ftrc.calculator.header.details1B" />,
            }}
          />
        </li>
      );
    }
  }

  render() {
    const { locale, intl } = this.props;
    const indexTwo = 2;
    const indexThree = 3;

    return (
      <React.Fragment>
        <div className="calculator-header-box">
          <div className="calculator-header-label">
            <FormattedMessage id="ftrc.calculator.header.label" />
          </div>
          <div style={{ marginTop: '0px' }}>
            <ul className="calculator-details-list">
              {_.map(_.range(1, indexThree, 1), (index) => {
                if (locale === eLocale.fr && index === indexTwo) {
                  return (
                    <li key={index}>
                      <FormattedMessage
                        id="multipart"
                        defaultMessage={`{part1}{part2}{part3}`}
                        values={{
                          part1: <FormattedMessage id="ftrc.calculator.header.details2A" />,
                          part2: (
                            <span className="mwi-tm">
                              <FormattedMessage id="ftrc.calculator.header.details2B" />
                            </span>
                          ),
                          part3: <FormattedMessage id="ftrc.calculator.header.details2C" />,
                        }}
                      />
                    </li>
                  );
                } else if (index === 1) {
                  return this.indexOneCalculator(index, intl);
                } else {
                  return (
                    <li key={index}>
                      <FormattedMessage id={`ftrc.calculator.header.details${index}`} />
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
        <div className="calculator-body-box">
          <CalculatorInput />
        </div>
        <div className="calculator-footer-box">
          <div className="calculator-footer-icon">
            <i className="material-icons" aria-hidden="true">
              info
            </i>
          </div>
          <div className="footer-messages">
            <div className="footer-disclaimer">
              <FormattedMessage id="ftrc.calculator.footer.message.1" />
            </div>
            <div className="footer-disclaimer">
              <FormattedMessage id="ftrc.calculator.footer.message.2" />
            </div>

            <div style={{ marginTop: '0px' }}>
              <p>
                <FormattedMessage
                  id="calcFooter"
                  defaultMessage={`{part1} {part2}`}
                  values={{
                    part1: <FormattedMessage id="ftrc.calculator.footer.message.3" />,
                    part2: (
                      <span style={{ fontFamily: 'OpenSans-Bold' }}>
                        <FormattedMessage id="ftrc.calculator.footer.message.4" />
                      </span>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(Calculator)));
