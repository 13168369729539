import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InputText } from 'primereact/components/inputtext/InputText';
import { eLocale } from '../../../core/configs';

const getInputTextField = function () {
  const {
    name,
    label,
    value,
    placeholder,
    onChange,
    onBlur,
    onComplete,
    maxLength,
    errors,
    isRequired,
    styleClass,
    labelId,
    ariaLabel,
    sizeStyle,
    disabled,
    topBarFieldStyle,
  } = this.props;
  return (
    <InputText
      id={`txt-${name}${labelId}`}
      value={value}
      className={`mwi-widget mwi-input ${topBarFieldStyle} ${styleClass || sizeStyle} ${
        errors && (!errors[name] ? '' : 'mwi-error-field')
      }`}
      placeholder={placeholder || label}
      onBlur={
        onBlur
          ? (e) => onBlur(name, e.target.value)
          : () => {
              //This is intentional
            }
      }
      onChange={
        onChange
          ? (e) => onChange(name, e.target.value)
          : () => {
              //This is intentional
            }
      }
      onComplete={
        onComplete
          ? onComplete()
          : () => {
              //This is intentional
            }
      }
      autoComplete="off"
      aria-label={ariaLabel}
      aria-required={isRequired}
      maxLength={maxLength}
      disabled={disabled}
      ref={this.input}
    />
  );
};

class MWIInput extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locale: PropTypes.string,
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    grid: PropTypes.string,
    isRequired: PropTypes.bool,
    styleClass: PropTypes.string,
    containerClass: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onComplete: PropTypes.func,
    labeId: PropTypes.string,
    ariaLabel: PropTypes.string,
    sizeStyle: PropTypes.string,
    disabled: PropTypes.bool,
    labelStyle: PropTypes.string,
    topBarFieldStyle: PropTypes.string,
    inputFieldStyle: PropTypes.string,
    maxLength: PropTypes.number,
    ariaLive: PropTypes.bool,
    labelId: PropTypes.string,
  };
  static defaultProps = {
    grid: '2',
    containerClass: '',
    locale: eLocale.en,
    isRequired: false,
    labelId: '',
    sizeStyle: 'mwi-w90',
    inputFieldStyle: 'space-right',
    disabled: false,
  };
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }
  render() {
    const { name, label, locale, grid, errors, labelId, containerClass, labelStyle, inputFieldStyle, ariaLive } =
      this.props;
    return (
      <div
        className={`${grid ? `p-col-${grid}` : ''} ${containerClass} mwi-control-wrapper ${locale}`}
        aria-live={ariaLive ? 'polite' : 'off'}
        aria-atomic={ariaLive}
      >
        <div className={`mwi-label ${labelStyle}`}>
          <label htmlFor={`txt-${name}${labelId}`}>{label}</label>
          {}
        </div>
        <div className={inputFieldStyle}>{getInputTextField.call(this)}</div>
        <div className="mwi-error-msg" role="container-mwiinputerror">
          {errors && errors[name]}
        </div>
      </div>
    );
  }
}

export default MWIInput;
