import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import './mycases.css';
import {
  dispatchMWIUpdateError,
  dispatchMWIOnChange,
  showRatesNotification,
  showStatusDialog,
  showNotification,
  hideNotification,
  deleteCaseError,
  hideRatesNotification,
  appInitialize,
  openSaveCase,
  saveOpenCaseName,
} from '../core/actions';
import { configs, rateChangeProducts, eField, eProduct } from '../core/configs';
import { DASH_ILLUSTRATION_TAB_ID, DASH_APPLICATION_TAB_ID } from '../core/constants';
import { withRouter } from 'react-router-dom';
import { deleteSavedCases, openSavedCase, retrieveSavedCases } from '../core/udm-mapper/sideEffect';
import _ from 'lodash';
import moment from 'moment';
import DocumentTitle from 'react-document-title';
import DashboardTitle from '../core/components/dashboard-title/dashboard-title';
import {Tabs, TabPanel, TAB_ALIGNMENT, TAB_VARIANT } from '@manulife/mux';
import DashboardIllustrationTab from '../core/components/dashboard-illustration-tab';
import DeleteModal from '../core/components/delete-modal';
import DashboardApplicationsTab from '../views/dashboard/';
import { performManualRefresh, requestUDMResponse } from '../custom-mode/scenario-tab-navs/actions';
import { checkToShowRatesNotification } from '../helper/rates-notification-helper';
import { checkToShowCaseConversionNotification } from '../helper/case-helper';

export class MYCases extends PureComponent {
  static propTypes = {
    advisorProfile: PropTypes.object,
    intl: PropTypes.object,
    locale: PropTypes.string,
    userID: PropTypes.string,
    history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    currentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentGuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    canAssignCase: PropTypes.bool,
    partyId: PropTypes.string,
    role: PropTypes.string,
    userType: PropTypes.string,
    dispatchMWIUpdateErrorAppAction: PropTypes.func,
    dispatchMWIOnChangeAppAction: PropTypes.func,
    showRatesNotificationAppAction: PropTypes.func,
    showStatusDialogAppAction: PropTypes.func,
    showNotificationAppAction: PropTypes.func,
    hideNotificationAppAction: PropTypes.func,
    deleteCaseErrorAppAction: PropTypes.func,
    hideRatesNotificationAppAction: PropTypes.func,
    appInitializeAppAction: PropTypes.func,
    openSaveCaseAppAction: PropTypes.func,
    saveOpenCaseNameAppAction: PropTypes.func,
    performManualRefreshSceAction: PropTypes.func,
    requestUDMResponseSceAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showCaseDel: false,
      deleteGuids: [],
      data: [],
      dateAscending: true,
      nameAscending: false,
      selectedTab: DASH_ILLUSTRATION_TAB_ID,
      selectedSubTab: DASH_APPLICATION_TAB_ID,
      checkedItems: [],
      dda2Toggled: false,
      tca2Toggled: false,
    };

    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.openCase = this.openCase.bind(this);
    this.hidemyCaseDelete = this.hidemyCaseDelete.bind(this);
    this.deleteMyCases = this.deleteMyCases.bind(this);
    this.sortDate = this.sortDate.bind(this);
    this.sortName = this.sortName.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.subTabChange = this.subTabChange.bind(this);
    this.setCheckedItems = this.setCheckedItems.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.checkToShowRatesNotification = this.checkToShowRatesNotification.bind(this);
    this.dispatchAction = this.dispatchAction.bind(this);
    this.dispatchRatesChangeNotification = this.dispatchRatesChangeNotification.bind(this);
    this.deleteSavedCases = this.deleteSavedCases.bind(this);
    this.handleOnDda2Toggle = this.handleOnDda2Toggle.bind(this);
    this.handleOnTca2Toggle = this.handleOnTca2Toggle.bind(this);
  }

  componentDidMount() {
    this.dispatchAction('isOpen', true);
    if (this.state.data.length === 0) {
      this.dispatchAction('isRequesting', true, 'app');
      this.fetchData();
    }
    if (process.env.NODE_ENV !== 'test') {
      ReactModal.setAppElement('#mwi-root');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userID !== '' && prevProps.userID !== this.props.userID) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.dispatchAction('isOpen', false);
  }

  fetchData() {
    if (this.props.role !== '') {
      retrieveSavedCases()
        .then((response) => {
          if (response.status === 'Success') {
            this.setState({ data: response.items });
          } else {
            this.dispatchAction('errorMessage', response.errorMessage || response.requestErrorMessage, 'app');
          }
        })
        .then(() => this.dispatchAction('isRequesting', false, 'app'))
        .catch((errResponse) => {
          this.dispatchAction('errorMessage', errResponse, 'app');
          this.dispatchAction('isRequesting', false, 'app');
        });
    }
  }

  dispatchAction(field, value, target = 'mycases', isError = false) {
    if (isError && this.props) {
      this.props.dispatchMWIUpdateErrorAppAction({
        target,
        field,
        value,
      });
    } else {
      if (this.props) {
        this.props.dispatchMWIOnChangeAppAction({
          target,
          field,
          value,
        });
      }
    }
  }
  dispatchNotification(type, field, value) {
    type = type.concat('AppAction');
    if (this.props) {
      this.props[type]({ field, value });
    }
  }
  tabChange = (value) => {
    this.setState({ selectedTab: value });
  };

  subTabChange = (value) => {
    this.setState({ selectedSubTab: value });
  };

  openDeleteDialog(guids) {
    this.setState({ showCaseDel: true, deleteGuids: guids });
  }

  formatDate = (date) => {
    const yearIndex = 2,
      monthIndex = 1,
      dayIndex = 0;
    const splitDate = date.split('/');
    return new Date(splitDate[yearIndex], splitDate[monthIndex] - 1, splitDate[dayIndex]).setHours(0, 0, 0, 0);
  };

  dispatchRatesChangeNotification(showRatesChangeNotification, isMUL) {
    const { intl } = this.props;
    if (showRatesChangeNotification && isMUL) {
      this.dispatchNotification('showStatusDialog', null, [
        intl.formatMessage({ id: 'common.information' }),
        intl.formatMessage({ id: 'common.rateChange' }),
      ]);
    } else {
      if (showRatesChangeNotification) {
        this.dispatchNotification('showRatesNotification');
      }
    }
  }

  checkToShowRatesNotification(loadedCase) {
    // Determine if rate change content should be displayed or not
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const lastOpenedCaseAt = new Date(loadedCase.updatedAt).setHours(0, 0, 0, 0);
    let showRatesChangeNotification = false;
    let isMUL = false;

    loadedCase.model.scenarioTabNavs.tabNavs.forEach((s) => {
      const scenario = loadedCase.model.scenarioTabNavs.topBars[s.id];
      if (!showRatesChangeNotification && rateChangeProducts[scenario.product]) {
        const lastRateChange = this.formatDate(rateChangeProducts[scenario.product]);
        const effectiveDate = this.formatDate(scenario.effectiveDate);
        if (
          lastRateChange <= currentDate &&
          (lastOpenedCaseAt < lastRateChange ||
            (this.props.userType === 'HeadOffice' && effectiveDate < lastRateChange))
        ) {
          showRatesChangeNotification = true;
          isMUL = scenario.product === eProduct.MUL;
        }
      }
    });

    this.dispatchRatesChangeNotification(showRatesChangeNotification, isMUL);
  }

  updateModel(model) {
    //update effective date in model to be current date for non-HO userType
    if (this.props.userType !== 'HeadOffice') {
      model.scenarioTabNavs.tabNavs.forEach((s) => {
        model.scenarioTabNavs.topBars[s.id].effectiveDate = moment().format('DD/MM/YYYY');
      });
    }
    return model;
  }

  updateCurrentProfileCard(model) {
    const caseCurrentProfile = model.advisorProfile?.currentProfileId;

    //if the case is saved with a profile card that exists then use that for opened case
    if (caseCurrentProfile && this.props.advisorProfile?.allProfiles?.includes(caseCurrentProfile)) {
      this.dispatchAction(eField.advisorProfile, caseCurrentProfile, 'report');
    }
  }

  openCase(guid) {
    openSavedCase(guid)
      .then((response) => {
        const loadedCase = response.items[0];
        this.dispatchAction('guid', loadedCase.guid, 'app');
        loadedCase.model = this.updateModel(loadedCase.model);
        const model = loadedCase.model;
        this.props.openSaveCaseAppAction({ model, guid, loadedCase });
        this.props.saveOpenCaseNameAppAction({ name: loadedCase.name, autoSaveCaseLoaded: loadedCase.autoSave });
        this.updateCurrentProfileCard(model);
        this.props.performManualRefreshSceAction();
        this.props.requestUDMResponseSceAction();
        checkToShowRatesNotification(loadedCase, this.props);
        checkToShowCaseConversionNotification(loadedCase, this.props);
        this.gotoIllustrations();
      })
      .catch((errResponse) => {
        this.dispatchAction('errorMessage', errResponse, 'app', true);
      });
  }

  deleteMyCases() {
    const newData = [...this.state.data];
    const hideNotificationTime = 5000;
    this.setState({ data: newData });
    this.deleteSavedCases(newData, hideNotificationTime);
    this.hidemyCaseDelete();
  }

  deleteSavedCases(newData, hideNotificationTime) {
    deleteSavedCases(this.state.deleteGuids, true)
      .then((response) => {
        if (response.status === 'Success') {
          this.setCheckedItems([]);
          this.setState({ data: newData.filter((p) => !this.state.deleteGuids.includes(p.guid)) });
          this.dispatchNotification(
            'showNotification',
            'notificationMessage',
            this.props.intl.formatMessage({ id: 'mycases.deleteIllustrationNotification' })
          );
          setTimeout(() => {
            this.dispatchNotification('hideNotification');
          }, hideNotificationTime);
        }
      })
      .catch((errResponse) => {
        this.dispatchNotification('deleteCaseError', errResponse, 'app');
      });
  }

  hidemyCaseDelete() {
    this.setState({ showCaseDel: false });
  }

  handleStartNewIllustrationClick = () => {
    if (this.state.selectedTab === DASH_ILLUSTRATION_TAB_ID) {
      this.dispatchAction('startNewIllustration', true);
      this.dispatchNotification('hideRatesNotification');
      this.props.history.push(configs.routes.home);
    } else if (
      this.state.selectedTab === DASH_APPLICATION_TAB_ID &&
      this.state.selectedSubTab === DASH_APPLICATION_TAB_ID
    ) {
      const ddaParam = this.state.dda2Toggled ? '2' : '1';
      open(`${configs.routes.ddaHome}/?dda=${ddaParam}`, '_self');
    } else {
      const ddaParam = this.state.tca2Toggled ? '2' : '1';
      open(`${configs.routes.ddaHome}/?tca=${ddaParam}`, '_self');
    }
  };

  sortDate() {
    const { dateAscending } = this.state;
    const newData = _.cloneDeep(this.state.data);
    newData.sort((a, b) => {
      const momentA = moment(a[eField.myCase.updatedAt], 'DD-MM-YYYYTHH:mm:SS');
      const momentB = moment(b[eField.myCase.updatedAt], 'DD-MM-YYYYTHH:mm:SS');
      let result = -1;
      if (
        (dateAscending && moment(momentB).isBefore(momentA)) ||
        (!dateAscending && moment(momentB).isAfter(momentA))
      ) {
        result = 1;
      }
      return result;
    });
    this.setState((prevState) => {
      return { data: newData, dateAscending: !prevState.dateAscending };
    });
  }

  sortName() {
    const { nameAscending } = this.state;
    const newData = _.cloneDeep(this.state.data);
    newData.sort((a, b) => {
      let result = -1;
      if (nameAscending) {
        if (a.name > b.name) {
          result = 1;
        }
      } else {
        if (a.name < b.name) {
          result = 1;
        }
      }
      return result;
    });
    this.setState((prevState) => {
      return { data: newData, nameAscending: !prevState.nameAscending };
    });
  }

  gotoIllustrations() {
    this.props.history.push(configs.routes.clientCoverage);
  }

  setCheckedItems(items) {
    this.setState({ checkedItems: items });
  }

  handleOnDda2Toggle = (value) => {
    this.setState(() => {
      return { dda2Toggled: value };
    });
  };

  handleOnTca2Toggle = (value) => {
    this.setState(() => {
      return { tca2Toggled: value };
    });
  };

  render() {
    const { intl } = this.props;
    const dashboardTabs = [
      {
        id: DASH_ILLUSTRATION_TAB_ID,
        label: intl.formatMessage({ id: 'dashboard.tab.illustrations' }),
        value: DASH_ILLUSTRATION_TAB_ID,
      },
    ];

    dashboardTabs.push({
      id: DASH_APPLICATION_TAB_ID,
      label: intl.formatMessage({ id: 'dashboard.tab.applications' }),
      value: DASH_APPLICATION_TAB_ID,
    });

    return (
      <div>
        <DocumentTitle
          title={`${intl.formatMessage({ id: 'common.illustrations' })} - ${intl.formatMessage({
            id: 'dashboard.title',
          })}`}
        >
          <div className="content-wrapper mycases-wrapper">
            <DashboardTitle
              selectedTab={this.state.selectedTab}
              selectedSubTab={this.state.selectedSubTab}
              handleStartNewIllustrationClick={this.handleStartNewIllustrationClick}
              dda2Toggled={this.state.dda2Toggled}
              tca2Toggled={this.state.tca2Toggled}
              {...this.props}
            />
            <div>
              <Tabs
                tabItems={dashboardTabs}
                selectedTab={this.state.selectedTab}
                variant={TAB_VARIANT.GREY}
                onChange={this.tabChange}
                tabAlignment={TAB_ALIGNMENT.LEFT}
                customStyle={{
                  tabPanelStyle: { padding: '10px' },
                  tabItemStyle: { margin: '0 35px 0 0px' },
                  tabsWrapperStyle: { height: '50px' },
                }}
              >
                <TabPanel>
                  <DashboardIllustrationTab
                    intl={intl}
                    data={this.state.data}
                    deletefunc={this.openDeleteDialog}
                    open={this.openCase}
                    checkedItems={this.state.checkedItems}
                    setCheckedItems={this.setCheckedItems}
                    canAssignCase={this.props.canAssignCase}
                  />
                </TabPanel>
                <TabPanel>
                  <DashboardApplicationsTab
                    intl={intl}
                    canAssignCase={this.props.canAssignCase}
                    partyId={this.props.partyId}
                    role={this.props.role}
                    dda2Toggled={this.state.dda2Toggled}
                    tca2Toggled={this.state.tca2Toggled}
                    selectedSubTab={this.state.selectedSubTab}
                    handleOnDda2Toggle={this.handleOnDda2Toggle}
                    handleOnTca2Toggle={this.handleOnTca2Toggle}
                    subTabChange={this.subTabChange}
                  />
                </TabPanel>
              </Tabs>
            </div>
            <DeleteModal
              title={intl.formatMessage({ id: 'mycases.deleteIllustration' })}
              message={intl.formatMessage({ id: 'mycases.dialog.delete.illustrations' })}
              deleteMyCases={this.deleteMyCases}
              isModalOpen={this.state.showCaseDel}
              modalClose={this.hidemyCaseDelete}
              intl={intl}
            />
          </div>
        </DocumentTitle>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, advisorProfile }) => {
  return {
    advisorProfile,
    locale: app.locale,
    currentGuid: app.guid,
    userID: app.userID,
    canAssignCase: app.canAssignCase,
    partyId: app.partyId,
    role: app.role,
    userType: app.userType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchMWIUpdateErrorAppAction: bindActionCreators(dispatchMWIUpdateError, dispatch),
  dispatchMWIOnChangeAppAction: bindActionCreators(dispatchMWIOnChange, dispatch),
  showRatesNotificationAppAction: bindActionCreators(showRatesNotification, dispatch),
  showStatusDialogAppAction: bindActionCreators(showStatusDialog, dispatch),
  showNotificationAppAction: bindActionCreators(showNotification, dispatch),
  hideNotificationAppAction: bindActionCreators(hideNotification, dispatch),
  deleteCaseErrorAppAction: bindActionCreators(deleteCaseError, dispatch),
  hideRatesNotificationAppAction: bindActionCreators(hideRatesNotification, dispatch),
  appInitializeAppAction: bindActionCreators(appInitialize, dispatch),
  openSaveCaseAppAction: bindActionCreators(openSaveCase, dispatch),
  saveOpenCaseNameAppAction: bindActionCreators(saveOpenCaseName, dispatch),
  performManualRefreshSceAction: bindActionCreators(performManualRefresh, dispatch),
  requestUDMResponseSceAction: bindActionCreators(requestUDMResponse, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(MYCases)));
