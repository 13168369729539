import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import MWIDropdown from '../mwi-dropdown';
import HelpQuestionMark from '../help-question-mark';

export class HealthStyle extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    selectedHealthStyle: PropTypes.string,
    healthStyles: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      }).isRequired
    ),
    index: PropTypes.number,
    uniqueId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    intl: PropTypes.object,
    grid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    containerStyle: PropTypes.string
  };

  static defaultProps = {
    grid: '1'
  };

  constructor(props) {
    super(props);

    this.help = React.createRef();
  }

  render = () => {
    const { locale, selectedHealthStyle, healthStyles, uniqueId, onChange, containerStyle } = this.props;

    return (
      <React.Fragment>
        <MWIDropdown
          name={`healthstyle.${uniqueId}`}
          label={
            <span id="dd-healthstyle-label">
              <FormattedMessage id="party.healthstyle" />
            </span>
          }
          grid={4}
          value={selectedHealthStyle}
          options={healthStyles}
          onChange={onChange}
          containerStyle={`${containerStyle} mwi-control-wrapper healthstyle ${locale} `}
        />
        <HelpQuestionMark
          containerClass={`healthstyle-trc`}
          overlayId="healthstyleTip"
          overlayContent={() => {
            return (
              <span>
                <FormattedMessage id="ftrc.calculatorInput.help.healthstyle" />
              </span>
            );
          }}
        />
      </React.Fragment>
    );
  };
}

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale
  };
};

export default connect(mapStateToProps)(injectIntl(HealthStyle));
