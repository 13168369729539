export const depositAmountLearnMoreHeader = [
  { key: "header1", id: "deposit.type.learnmore.header1" },
  { key: "header2", id: "deposit.type.learnmore.header2" },
  { key: "header3", id: "deposit.type.learnmore.header3" }
];

const learnMoreAbsolute = "learn-more-absolute";
export const depositAmountLearnMoreMatrix = [
  {
    header1: {
      class: learnMoreAbsolute,
      id: "deposit.type.learnmore.column1-1"
    },
    header2: {
      class: "",
      id: "deposit.type.learnmore.column1-2"
    },
    header3: {
      class: "",
      id: []
    }
  },
  {
    header1: {
      class: learnMoreAbsolute,
      id: "deposit.type.learnmore.column2-1"
    },
    header2: {
      class: learnMoreAbsolute,
      id: "deposit.type.learnmore.column2-2"
    },
    header3: {
      class: "",
      id: [
        "deposit.type.learnmore.column2-3-1",
        "deposit.type.learnmore.column2-3-2",
        "deposit.type.learnmore.column2-3-3",
        "deposit.type.learnmore.column2-3-4",
        "deposit.type.learnmore.column2-3-5"
      ]
    },
  },
  {
    header1: {
      class: learnMoreAbsolute,
      id: "deposit.type.learnmore.column3-1"
    },
    header2: {
      class: learnMoreAbsolute,
      id: "deposit.type.learnmore.column3-2"
    },
    header3: {
      class: "",
      id: [
        "deposit.type.learnmore.column3-3-1",
        "deposit.type.learnmore.column3-3-2",
        "deposit.type.learnmore.column3-3-3",
        "deposit.type.learnmore.column3-3-4",
        "deposit.type.learnmore.column3-3-5"
      ]
    },
  },
  {
    header1: {
      class: learnMoreAbsolute,
      id: "deposit.type.learnmore.column4-1"
    },
    header2: {
      class: learnMoreAbsolute,
      id: "deposit.type.learnmore.column4-2"
    },
    header3: {
      class: "",
      id: [
        "deposit.type.learnmore.column4-3-1",
        "deposit.type.learnmore.column4-3-2",
        "deposit.type.learnmore.column4-3-3",
        "deposit.type.learnmore.column4-3-4"
      ]
    },
  }
];

export const coverageAmountLearnMoreHeader = [
  { key: "header1", id: "coverage.par.coverageAmount.learnmore.header1" },
  { key: "header2", id: "coverage.par.coverageAmount.learnmore.header2" },
  { key: "header3", id: "coverage.par.coverageAmount.learnmore.header3" }
];

export const coverageAmountLearnMoreMatrix = [
  {
    header1: {
      class: learnMoreAbsolute,
      id: "coverage.par.coverageAmount.learnmore.column1-1"
    },
    header2: {
      class: "",
      id: "coverage.par.coverageAmount.learnmore.column1-2"
    },
    header3: {
      class: "",
      id: []
    }
  },
  {
    header1: {
      class: learnMoreAbsolute,
      id: "coverage.par.coverageAmount.learnmore.column2-1"
    },
    header2: {
      class: learnMoreAbsolute,
      id: "coverage.par.coverageAmount.learnmore.column2-2"
    },
    header3: {
      class: "",
      id: [
        "coverage.par.coverageAmount.learnmore.column2-3-1",
        "coverage.par.coverageAmount.learnmore.column2-3-2",
        "coverage.par.coverageAmount.learnmore.column2-3-3",
        "coverage.par.coverageAmount.learnmore.column2-3-4",
        "coverage.par.coverageAmount.learnmore.column2-3-5"
      ]
    },
  },
  {
    header1: {
      class: learnMoreAbsolute,
      id: "coverage.par.coverageAmount.learnmore.column3-1"
    },
    header2: {
      class: learnMoreAbsolute,
      id: "coverage.par.coverageAmount.learnmore.column3-2"
    },
    header3: {
      class: "",
      id: [
        "coverage.par.coverageAmount.learnmore.column3-3-1",
        "coverage.par.coverageAmount.learnmore.column3-3-2",
        "coverage.par.coverageAmount.learnmore.column3-3-3",
        "coverage.par.coverageAmount.learnmore.column3-3-4",
        "coverage.par.coverageAmount.learnmore.column3-3-5"
      ]
    },
  },
];

export const VitalityStatusLearnMoreMatrix = [
  {
    header1: {
      class: "",
      id: "coverage.mul.vitalityStatus.learnmore.column1-1"
    },
    header2: {
      class: "",
      id: "coverage.mul.vitalityStatus.learnmore.column1-2"
    }
  },
  {
    header1: {
      class: "",
      id: "coverage.mul.vitalityStatus.learnmore.column2-1"
    },
    header2: {
      class: "",
      id: "coverage.mul.vitalityStatus.learnmore.column2-2"
    }
  },
  {
    header1: {
      class: "",
      id: "coverage.mul.vitalityStatus.learnmore.column3-1"
    },
    header2: {
      class: "",
      id: "coverage.mul.vitalityStatus.learnmore.column3-2"
    }
  },
  {
    header1: {
      class: "",
      id: "coverage.mul.vitalityStatus.learnmore.column4-1"
    },
    header2: {
      class: "",
      id: "coverage.mul.vitalityStatus.learnmore.column4-2"
    }
  }
];