import axios from 'axios';
import polly from 'polly-js';
import {
  redirectAuth,
  urlDataToken,
  configs,
  inactiveEndpoint,
  getAdvisorPortalRegistrationEndpointNoIntl,
  getAdvisorPortalLandingPageLink,
  eLocale,
} from '../configs';
import { logger } from '../logger';

const cancelToken = axios.CancelToken;
let source;

const getAxiosOptions = (method, url, body, innerCancelToken, headers, useCreds = false) => {
  const options = {
    cancelToken: innerCancelToken,
    method,
    headers,
    url,
  };

  if (body !== undefined) {
    options.data = body;
  }

  if (useCreds) {
    options.withCredentials = true;
  }

  return options;
};

const BadRequest = 400;
const Unauthorized = 401;
const Forbidden = 403;
const NotFound = 404;
const UnprocessableEntity = 422;
const InternalServerError = 500;

const handleUnauthorizedResponse = (response) => {
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('isLoggedIn');

  if (response.headers['rejected-reason'] === 'inactive') {
    window.location = inactiveEndpoint;
    return;
  }

  if (response.status === Unauthorized && response.data.error === 'NoApId') {
    return;
  }

  // trigger a login by doing a redirect
  window.location = redirectAuth;
};

const handleRetryError = (error) => {
  // Failed after all retries
  logger().log('Final retry error occurred...');

  let resp;

  if (axios.isCancel(error)) {
    resp = { status: 'cancel', errorMessage: 'cancelled' };
    return resp;
  }

  if (error.response) {
    if (error.response.status === Unauthorized || error.response.status === Forbidden) {
      return handleUnauthorizedResponse(error.response);
    }
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    if (error.response.data !== undefined) {
      resp = error.response.data;
    } else {
      resp = { requestErrorMessage: 'Something went wrong' };
    }
  } else if (error.request) {
    // Error which has nothing to do with UDM
    // The request was made but no response was received: 404, 500 error
    logger().log('err => ', error.message);
    resp = { status: 'requestError', requestErrorMessage: error.message };
  } else {
    // Something happened in setting up the request that triggered an Error
    logger().log('Error: ', error.message);
    resp = { status: 'requestError', requestErrorMessage: error.message };
  }

  if (typeof resp === 'object') {
    resp.isError = true;
  }

  return resp;
};

const pollyWrapper = (requestOptions) => {
  const responseErrors = [BadRequest, Unauthorized, Forbidden, NotFound, UnprocessableEntity, InternalServerError];
  const delay1 = 1000,
    delay2 = 2000,
    delay3 = 3000,
    delay4 = 5000,
    delay5 = 8000;

  return polly()
    .handle((error) => {
      // Decide which errors to retry here: true to retry, false to stop
      if (error.response && error.response.status) {
        logger().log(`Error [${error.response.status}]: ${error}`);

        if (responseErrors.includes(error.response.status)) {
          if (error.response.data.error === 'AdvisorPortalError') {
            window.location = getAdvisorPortalRegistrationEndpointNoIntl();
            return false;
          }
          return error.response.status === Unauthorized && error.response.data.error === 'invalid_token';
        }
      } else if (axios.isCancel(error)) {
        // stop trying if the request is cancelled for any reason
        return false;
      } else {
        logger().log(`${error}`);
      }

      return true;
    })
    .waitAndRetry([delay1, delay2, delay3, delay4, delay5])
    .executeForPromise(function () {
      return axios.request(requestOptions);
    })
    .then((response) => response.data)
    .catch((error) => handleRetryError(error));
};

const genericRequest = (
  { method, url, urlData, body },
  isTokenRequest,
  isURLParam,
  useCreds,
  isCalcRequest,
  isAuthCall
) => {
  if (url.includes(urlDataToken)) {
    url = url.replace(urlDataToken, urlData);
  }

  let innerCancelToken;
  // only cancel calc requests
  if (isCalcRequest) {
    // cancel the previous request
    source && source.cancel('request is canceled: ', url);
    source = cancelToken.source();
    innerCancelToken = source.token;
  }

  const request = {
    headers: {},
  };

  if (isAuthCall || !isTokenRequest) {
    request.headers['X-Is-Xhr'] = true;
  } else {
    if (isURLParam) {
      request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
  }

  const options = getAxiosOptions(method, url, body, innerCancelToken, request.headers, useCreds);

  return pollyWrapper(options);
};

const makeAuthorizeRequest = (method, endPoint, isTokenRequest, isURLParam) => () =>
  genericRequest({ method, url: endPoint, urlData: null, body: null }, isTokenRequest, isURLParam, true, false, true);

const makeCalcRequest = (method, endPoint, isTokenRequest, isURLParam) => {
  return (body, link) => {
    const url = link ? link : endPoint;
    return genericRequest({ method, url, body, urlData: null }, isTokenRequest, isURLParam, true, true, false);
  };
};

const makeSpreadsheetCalcRequest = (method, endPoint, isTokenRequest, isURLParam) => {
  return (body, link) => {
    const url = link ? link : endPoint;
    return genericRequest({ method, url, body, urlData: null }, isTokenRequest, isURLParam, true, false, false);
  };
};

const makeRequestWithBody = (method, endPoint, isTokenRequest, isURLParam, useCreds) => {
  return (body, urlData, link) => {
    const url = link ? link : endPoint;
    return genericRequest({ method, url, urlData, body }, isTokenRequest, isURLParam, useCreds, false, false);
  };
};

const makeRequestNoBody = (method, endPoint, isTokenRequest, isURLParam, useCreds) => {
  return (urlData, link) => {
    const url = link ? link : endPoint;
    return genericRequest({ method, url, urlData, body: null }, isTokenRequest, isURLParam, useCreds, false, false);
  };
};

export const requestValidation = (() => makeCalcRequest('post', configs.endPoints.validation, false, false))();

export const requestSpreadsheetValidation = (() =>
  makeSpreadsheetCalcRequest('post', configs.endPoints.validation, false, false))();

export const policyLookup = (() =>
  makeRequestWithBody('post', configs.endPoints.policyLookup.retrievePolicy, false, false, true))();

export const policyEntitled = (() =>
  makeRequestWithBody('post', configs.endPoints.policyLookup.entitledPolicy, false, false, true))();

export const createInforceRequestForm = (() =>
  makeRequestWithBody('post', configs.endPoints.inforceRequestForm.create, false, false, true))();

export const getDdmIllustration = (() =>
  makeRequestNoBody('get', configs.endPoints.getDdmIllustration, false, true, true))();

export const saveDdmIllustration = (() =>
  makeRequestWithBody('post', configs.endPoints.persistence.saveDdmIllustration, false, true, true))();

export const createShareCase = (() =>
  makeRequestWithBody('post', configs.endPoints.persistence.createShareCase, false, true, true))();

export const createCase = (() =>
  makeRequestWithBody('post', configs.endPoints.persistence.createCase, false, true, true))();

export const updateCase = (() =>
  makeRequestWithBody('put', configs.endPoints.persistence.updateCase, false, true, true))();

export const createProfile = (() =>
  makeRequestWithBody('post', configs.endPoints.persistence.createProfile, false, true, true))();

export const updateProfile = (() =>
  makeRequestWithBody('put', configs.endPoints.persistence.updateProfile, false, true, true))();

export const retrieveSavedCases = (() =>
  makeRequestNoBody('get', configs.endPoints.persistence.getCases, false, true, true))();

export const deleteSavedCases = (() =>
  makeRequestWithBody('post', configs.endPoints.persistence.deleteCases, false, true, true))();

export const openSavedCase = (() =>
  makeRequestNoBody('get', configs.endPoints.persistence.openCase, false, true, true))();

export const retrieveSavedApps = (() => makeRequestNoBody('get', configs.endPoints.dda.getApps, false, true, true))();

export const retrieveSavedAppsTca = (() =>
  makeRequestNoBody('get', configs.endPoints.dda.tcaGetApps, false, true, true))();

export const retrieveCookieInfo = (() =>
  makeRequestNoBody('get', configs.endPoints.retrieveCookieInfo, false, true, true))();

export const retrieveDocuSignDoc = (() =>
  makeRequestWithBody('post', configs.endPoints.dda.getDocuSignDoc, false, true, true))();

export const retrieveDocuSignDocTca = (() =>
  makeRequestWithBody('post', configs.endPoints.dda.tcaGetDocuSignDoc, false, true, true))();

export const deleteSavedApps = (() =>
  makeRequestWithBody('post', configs.endPoints.dda.deleteApps, false, true, true))();

export const deleteSavedAppsTca = (() =>
  makeRequestWithBody('post', configs.endPoints.dda.tcaDeleteApps, false, true, true))();

export const retrieveProfiles = (() =>
  makeRequestNoBody('get', configs.endPoints.persistence.getProfiles, false, true, true))();

export const deleteProfile = (() =>
  makeRequestWithBody('post', configs.endPoints.persistence.deleteProfile, false, true, true))();

export const authorize = (() => makeAuthorizeRequest('post', configs.endPoints.authorize, true, false))();

export const heartbeat = (() => makeRequestNoBody('get', configs.endPoints.heartbeat, false, false, true))();

export const logOutCiam = (() => makeRequestNoBody('get', configs.endPoints.logout, false, true, true))();

export const logout = async (lang = eLocale.en) => {
  await logOutCiam();
  sessionStorage.clear();
  logoutWithLanguage(lang);
};

export const logoutWithLanguage = ((lang) => {
  return () => {
    window.location = getAdvisorPortalLandingPageLink(lang);
  };
})();
