import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './mwi-accordion.css';

export class MWIAccordion extends PureComponent {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    folded: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onClick: PropTypes.func,
    tabIndex: PropTypes.string,
    headingLevel: PropTypes.number
  };

  static defaultProps = {
    folded: true,
    title: '',
    className: '',
    tabIndex: '0'
  };

  constructor(props) {
    super(props);

    this.state = { switch: !props.folded };
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  toggleSwitch() {
    this.setState({
      switch: !this.state.switch
    });
    this.props.onClick && this.props.onClick();
  }

  keyPress(event) {
    if (event.key === ' ' || event.key === 'Enter') {
      this.toggleSwitch();
    }
  }

  render() {
    const { title, children, className, tabIndex, headingLevel } = this.props;

    return (
      <React.Fragment>
        <div
          className={`accordion${!this.state.switch ? ' folded' : ''}${className ? ` ${className}` : ''}`}
          tabIndex={tabIndex}
          onKeyPress={(event) => this.keyPress(event)}
          role="button"
          aria-expanded={this.state.switch}
          onClick={() => this.toggleSwitch()}
        >
          <div className="title mwi-weight-600 short-form" role="heading" aria-level={headingLevel || '2'}>
            <div className="p-col-12">
              <div className="title-group">
                <i className="material-icons" aria-hidden="true">
                  {this.state.switch ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                </i>
                {title}
              </div>
            </div>
          </div>
        </div>
        {this.state.switch && children}
      </React.Fragment>
    );
  }
}

export default MWIAccordion;
