import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { eField, eLocale, eMarginalTaxRate } from '../../../core/configs';
import MWINumberField from '../../components/mwi-calculated-number-field';

export class MarginalTaxRate extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    scenarioTabNavs: PropTypes.object,
  };

  dispatchAction(field, value, target = 'scenario', isError = false) {
    const { appActions, sceActions } = this.props;

    appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
    });
    this.props.sceActions.performManualRefresh();
    sceActions.toggleOutOfDate(true);
  }

  render() {
    const { locale, scenarioTabNavs } = this.props;
    const marginalTaxRate = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId].marginalTaxRate;
    return (
      <div
        className="p-col-12 marginal-tax-rate mwi-padding-right12 mwi-margin-bottom24"
        role="container-marginaltaxrate"
      >
        <MWINumberField
          id={eField.marginalTaxRate}
          name={eField.marginalTaxRate}
          label={<FormattedMessage id="coverage.marginaltaxrate" />}
          minValue={eMarginalTaxRate.min}
          local={locale}
          value={+marginalTaxRate}
          maxValue={eMarginalTaxRate.max}
          decimalScale={2}
          fixedDecimalScale
          onBlur={(id, val) => this.dispatchAction(id, val)}
          decimalSeparator={locale === eLocale.en ? '.' : ','}
          suffix=" %"
          allowNegative={false}
          autoComplete="off"
        />
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs }) => {
  return {
    locale: app.locale,
    scenarioTabNavs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MarginalTaxRate));
