import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import _ from 'lodash';
import './learn-more-table.css';
import { FormattedMessage, IntlProvider } from 'react-intl';

export class LearnMoreTable extends PureComponent {
  static propTypes = {
    overlayId: PropTypes.string,
    columnHeaders: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    tableMatrix: PropTypes.array.isRequired,
    locale: PropTypes.string,
    tableClass: PropTypes.string,
    cellClass: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { overlayId, columnHeaders, tableMatrix, tableClass, cellClass } = this.props;
    const cellBody = (rowData, header) => {
      return (
        <React.Fragment>
          <div className={rowData[header.field].class}>
            <span>
              {Array.isArray(rowData[header.field].id) ? (
                <ul className="learn-more-details-list">
                  {rowData[header.field].id.map((col) => (
                    <li key={col}>
                      <FormattedMessage id={col} />
                    </li>
                  ))}
                </ul>
              ) : (
                <FormattedMessage id={rowData[header.field].id} />
              )}
            </span>
          </div>
        </React.Fragment>
      );
    };
    const getColumn =
      !_.isEmpty(columnHeaders) &&
      columnHeaders.map((col) => {
        return (
          <Column
            key={col.key}
            field={col.key}
            className={cellClass ?? 'learnmore-cell'}
            header={<FormattedMessage id={col.id} />}
            body={cellBody}
          />
        );
      });
    return (
      <React.Fragment>
        <IntlProvider locale="en">
          <DataTable aria-describedby={overlayId} className={tableClass ?? 'learnmore-table'} value={tableMatrix}>
            {getColumn}
          </DataTable>
        </IntlProvider>
      </React.Fragment>
    );
  }
}

export default LearnMoreTable;
