import './mwi-dropdown.css';
import React, { PureComponent } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import HelpText from '../help-text';
import PropTypes from 'prop-types';
import { isIOS, getVitalityStatusSimulatorUrl } from '../../../core/utils';
import { eProduct } from '../../configs';
import { MWILink } from '../mwi-link';

const selectDropDown = function () {
  const {
    name,
    value,
    options,
    placeholder,
    disabled,
    isRequired,
    dropdownStyle,
    topBarFieldStyle,
    iconStyle,
    tabIndex,
    errorState,
    isPlaceholderAnOption,
    role,
  } = this.props;
  return (
    <div className={`mwi-dropdown-container ${dropdownStyle}`} tabIndex={tabIndex}>
      <select
        id={`dd-${name}`}
        className={`select-dropdown focusOutline ${topBarFieldStyle} ${disabled ? 'disabled' : null} ${
          errorState && 'errorBorder'
        }`}
        name={`mwi-dropdown-${name}`}
        value={value}
        onChange={(e) => this.props.onChange(e)}
        disabled={disabled}
        // ref={this.selectRef}
        aria-required={isRequired}
        role={role}
      >
        {/* This is the only way to have placeholders in native select elements (a disabled option) */}
        {/* For now, only insured Client has a placeholder. If multiple clients should be supported
              then need to consider how to show selected client out of all clients
              it may need to be dealt with options and selected... not by placeholder...
             */}
        {placeholder && !isPlaceholderAnOption && (
          <option selected disabled hidden>
            {placeholder || 'Choose'}
          </option>
        )}
        {placeholder && isPlaceholderAnOption && <option disabled>{placeholder || 'Choose'}</option>}
        {options.map((option, index) => {
          return (
            // <option key={index} value={option.value} selected={value === option.value}>
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>

      {
        // show dropdown arrow when disable is false
        !disabled && (
          <i className={`material-icons ${iconStyle}`} aria-hidden="true">
            keyboard_arrow_down
          </i>
        )
      }
    </div>
  );
};

// styles are defined in the theme css
export class MWIDropdown extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }).isRequired
    ),
    onChange: PropTypes.func.isRequired,
    isTM: PropTypes.bool,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    grid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dropdownStyle: PropTypes.string,
    containerStyle: PropTypes.string,
    labelStyle: PropTypes.string,
    topBarFieldStyle: PropTypes.string,
    placeholder: PropTypes.string,
    intl: PropTypes.object,
    tabIndex: PropTypes.string,
    iconStyle: PropTypes.string,
    errorState: PropTypes.bool,
    helpText: PropTypes.bool,
    helpTextBanner: PropTypes.string,
    helpTextContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    helpTextContainer: PropTypes.string,
    imageAdress: PropTypes.string,
    locale: PropTypes.string,
    errorText: PropTypes.string,
    isPlaceholderAnOption: PropTypes.bool,
    role: PropTypes.string,
    product: PropTypes.string,
    appActions: PropTypes.object,
  };

  static defaultProps = {
    grid: '2',
    isRequired: true,
    disabled: false,
    dropdownStyle: '',
    containerStyle: '',
    imageAdress: '',
    isTM: false,
    placeholder: null,
    errorState: false,
    helpText: false,
    locale: '',
    intl: {},
    errorText: '',
    isPlaceholderAnOption: true,
    product: '',
  };

  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const setTimeoutMilliSecondsDelay = 1200;
    if (isIOS() && this.props.value !== prevProps.value) {
      setTimeout(this.focus, setTimeoutMilliSecondsDelay, this.selectRef);
    }
  }

  focus(ddRef) {
    if (ddRef.current) {
      ddRef.current.focus();
    }
  }

  getDropdownProps() {
    return {
      disabled: this.props.disabled,
      placeholder: this.props.placeholder,
    };
  }

  renderParVitalityStatusLearnMore() {
    const { intl, locale } = this.props;
    return (
      <div className="label-link" style={{ paddingLeft: '14px' }}>
        <HelpText
          containerClass={`trc-help ${locale}`}
          overlayId="mulVitalityPlusTip"
          overlayClass={`vitalitystatus-helptext-size-${locale}`}
          overlayContent={() => {
            return (
              <div style={{ paddingBottom: 14, display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <FormattedMessage id="coverage.mul.vitalityStatus.learnmore.row-4" />
                  <MWILink
                    label={<FormattedHTMLMessage id="coverage.mul.vitalityStatus.learnmore.healthyLiving" />}
                    link={getVitalityStatusSimulatorUrl(locale)}
                    intl={intl}
                    openNewTab
                    styleClass="display-inline healthy-living-link"
                  />
                </div>
                <i className="material-icons" style={{ fontSize: '1em' }}>
                  clear
                </i>
              </div>
            );
          }}
        />
      </div>
    );
  }

  render() {
    const {
      name,
      label,
      isTM,
      grid,
      containerStyle,
      labelStyle,
      errorState,
      helpText,
      helpTextBanner,
      helpTextContent,
      helpTextContainer,
      imageAdress,
      intl,
      locale,
      errorText,
      product,
    } = this.props;

    return (
      <div className={`${grid ? `p-col-${grid}` : ''} ${containerStyle}`}>
        {!!label && (
          <div className={`mwi-label ${labelStyle}`} style={{ display: 'flex' }}>
            <div>
              <label tabIndex={isIOS() ? '1' : ''} ref={this.selectRef} htmlFor={`dd-${name}`}>
                {label}
              </label>
            </div>
            {isTM && (
              <span className="mwi-tm">
                <FormattedMessage id="common.superscript" />
              </span>
            )}
            {/* Learn more link with Image */}
            {helpText && product !== eProduct.PAR && (
              <div className="label-link" style={{ paddingLeft: '14px' }}>
                <HelpText
                  containerClass={`trc-help ${locale}`}
                  overlayId="coverageEffectiveDateTip"
                  overlayClass={helpTextContainer}
                  overlayContent={() => {
                    return (
                      <div>
                        <div style={{ paddingBottom: 14, display: 'flex', justifyContent: 'space-between' }}>
                          {helpTextBanner && <FormattedMessage id={helpTextBanner} />}
                          <i className="material-icons" style={{ fontSize: '1em' }}>
                            clear
                          </i>
                        </div>
                        {helpTextContent ?? (
                          <img
                            className="example-my-clients"
                            src={imageAdress}
                            alt={intl.formatMessage({ id: 'common.logo' })}
                          />
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            )}
            {helpText && product === eProduct.PAR && this.renderParVitalityStatusLearnMore()}
          </div>
        )}
        {selectDropDown.call(this)}
        {errorState && errorText && <span className="caseSave-error">{errorText}</span>}
      </div>
    );
  }
}

export default MWIDropdown;
