import {
  TOGGLE_RIDER,
  TOGGLE_RIDER_CLIENT,
  ADD_RIDER_CLIENT,
  REMOVE_RIDER_CLIENT,
  ADD_CPR_CHILD,
  REMOVE_CPR_CHILD,
  UPDATE_CHILD_RATINGS,
  UNSELECT_ALL_RIDERS,
  UPDATE_PAYOR_RATINGS
} from '../../core/actionTypes';
import { makeActionCreator } from '../../core/utils';

export const toggleRider = makeActionCreator(TOGGLE_RIDER);
export const unselectAllRiders = makeActionCreator(UNSELECT_ALL_RIDERS);
export const toggleRiderClient = makeActionCreator(TOGGLE_RIDER_CLIENT);
export const addRiderClient = makeActionCreator(ADD_RIDER_CLIENT);
export const removeRiderClient = makeActionCreator(REMOVE_RIDER_CLIENT);
export const addChild = makeActionCreator(ADD_CPR_CHILD);
export const removeChild = makeActionCreator(REMOVE_CPR_CHILD);
export const updateChildRatings = makeActionCreator(UPDATE_CHILD_RATINGS);
export const updatePayorRatings = makeActionCreator(UPDATE_PAYOR_RATINGS);
