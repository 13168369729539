import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MWISrOnly from '../../core/components/mwi-sr-only';
import MWILink from '../../core/components/mwi-link';
import { eDepositOwnerResidence, eReqStatus, eProduct, eField, eLocale } from '../../core/configs';
import './summary.css';
import { getProduct } from '../../core/utils';
import { isSummaryAmountsHidden } from './utils';
import ToastNotification from '../../core/components/mux-toast-notification';
import { TOAST_STATUS } from '@manulife/mux';
import { Info1 } from '@manulife/mux-cds-icons';
import { calcDelayNotificationTime } from '../../core/configs';

export class SummaryHeader extends PureComponent {
  static propTypes = {
    isOutOfDate: PropTypes.bool,
    showManualRefresh: PropTypes.bool,
    app: PropTypes.object,
    udmResponse: PropTypes.object,
    intl: PropTypes.object,
    switchUDM: PropTypes.func,
    reload: PropTypes.func,
    totalPremium: PropTypes.string,
    dispatchAction: PropTypes.func,
    ownerResidence: PropTypes.string,
    product: PropTypes.string,
    selectResidenceClicked: PropTypes.bool,
    isVitalityPlusSelected: PropTypes.bool,
    locale: PropTypes.string,
    inforce: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      appReady: false,
      notificationsToggle: false,
    };
  }

  componentDidMount = () => {
    if (this.props.udmResponse.isLoading) {
      this.startCalculationTimer();
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.udmResponse.isLoading !== this.props.udmResponse.isLoading) {
      this.clearCalculationTimer();
      if (this.props.udmResponse.isLoading) {
        this.startCalculationTimer();
      }
    }
  }

  componentWillUnmount = () => {
    if (this.props.udmResponse.isLoading) {
      this.clearCalculationTimer();
    }
  }

  renderProductName = () => {
    const { product, isVitalityPlusSelected, intl } = this.props;
    const isParVPFrench = product === eProduct.PAR && intl.locale === eLocale.fr && isVitalityPlusSelected;
    return (
      product !== eProduct.FTRC && (
        <div
          className={!isParVPFrench ? 'p-grid summary-header-product' : 'p-grid summary-header-product-parVPFrench'}
          id="no-margin-progress-bar"
        >
          <div className="p-col">
            <span>{intl.formatMessage({ id: `${getProduct(product)}` })}</span>
            {isVitalityPlusSelected && (
              <>
                <span className="mwi-padding-left4">
                  <FormattedMessage id="summary.with" />
                </span>
                <span className="mwi-padding-left4 mwi-bold italic">
                  <FormattedMessage id="coverage.vitalityPlus" />
                </span>
                <span className="mwi-padding-left4 mwi-tm">
                  <FormattedMessage id="common.superscript" />
                </span>
              </>
            )}
          </div>
        </div>
      )
    );
  };

  startCalculationTimer = () => {
    this.calculationTimer = setTimeout(() => {
      this.setState({ notificationsToggle: !this.state.notificationsToggle });
      this.calcNotificationInterval = setInterval(() => {
        this.setState({ notificationsToggle: !this.state.notificationsToggle });
      }, calcDelayNotificationTime.interval);
    }, calcDelayNotificationTime.timeout);
  }

  clearCalculationTimer = () => {
    if (this.calculationTimer) {
      clearTimeout(this.calculationTimer);
      this.calculationTimer = null;
    }
    if (this.calcNotificationInterval) {
      clearInterval(this.calcNotificationInterval);
      this.calcNotificationInterval = null;
    }
  }

  render = () => {
    const {
      intl,
      dispatchAction,
      isOutOfDate,
      ownerResidence,
      product,
      reload,
      selectResidenceClicked,
      showManualRefresh,
      switchUDM,
      totalPremium,
      udmResponse,
      inforce,
    } = this.props;

    const isUDMLoading = udmResponse.isLoading;
    const isUDMError = udmResponse.isError || udmResponse.status.toUpperCase() === eReqStatus.reqError;
    const isValidationError = udmResponse.validationStatus.toUpperCase() === eReqStatus.error;

    const udmUpdated = () => isUDMError || isUDMLoading;
    const recalcRequired = () =>
      !isSummaryAmountsHidden(product, inforce) &&
      (udmUpdated() || showManualRefresh || isOutOfDate || isValidationError);

    return (
      <div
        className={`main-title ${isUDMLoading ? 'loading-udm' : ''} ${
          isUDMError || isValidationError ? 'udm-error' : ''
        }`}
      >
        <div role="status" tabIndex="-1">
          {this.state.appReady && (
            <MWISrOnly
              label={`${intl.formatMessage({ id: 'summary.status' })} ${intl.formatMessage({
                id: 'summary.totalpremium',
              })} ${totalPremium}`}
            />
          )}
        </div>
        <div className="p-grid summary-header" id="no-margin-progress-bar">
          <div className="p-col">
            <span id="summaryTitle" onClick={switchUDM} role="heading" aria-level="1">
              <FormattedMessage id="summary.title" />
            </span>
          </div>

          {recalcRequired() && (!selectResidenceClicked || ownerResidence !== eDepositOwnerResidence.select) && (
            <div className="p-col">
              <span className="refresh">
                <i className={`material-icons ${isUDMLoading ? 'mwi-spin' : ''}`}>refresh</i>
              </span>
              <ToastNotification text={intl.formatMessage({ id: 'summary.status.calculationDelay' })} icon={<Info1 style={{ fontSize: '1.5rem' }}/>} status={TOAST_STATUS.INFORMATIVE} send={isUDMLoading && this.calculationTimer != null} toggle={this.state.notificationsToggle}/>
              <span className="recalculate">
                <MWILink
                  label={intl.formatMessage({ id: 'common.refresh' })}
                  onClick={() => {
                    product === eProduct.MUL && (ownerResidence === eDepositOwnerResidence.select || !ownerResidence)
                      ? dispatchAction(eField.selectClicked, true, 'deposit')
                      : reload();
                  }}
                />
              </span>
            </div>
          )}
        </div>
        {this.renderProductName()}
      </div>
    );
  };
}

export default SummaryHeader;
