import React, { Component } from 'react';
import { OverlayPanel } from 'primereact/components/overlaypanel/OverlayPanel';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export class HelpText extends Component {
  constructor() {
    super();

    this.help = React.createRef();

    this.state = { helpExpanded: false };

    this.helpToggle = this.helpToggle.bind(this);
    this.setHelpExpanded = this.setHelpExpanded.bind(this);
  }

  helpToggle() {
    this.setState((prevState) => {
      return { helpExpanded: !prevState.helpExpanded };
    });
  }

  setHelpExpanded(value) {
    this.setState({ helpExpanded: value });
  }

  render() {
    const { containerClass, overlayId, overlayContent, overlayClass } = this.props;
    return (
      <React.Fragment>
        <div
          className={containerClass}
          id="help-text"
          role="link"
          aria-label={this.props.intl.formatMessage({ id: 'common.learnMore' })}
          aria-live="polite"
          aria-describedby={overlayId}
          tabIndex={0}
          onClick={(e) => {
            this.help.toggle(e);
            this.helpToggle();
          }}
          onKeyPress={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              this.help.toggle(e);
              this.helpToggle();
            }
          }}
        >
          <FormattedMessage id="common.learnMore" />
          <OverlayPanel className={overlayClass} ref={(el) => (this.help = el)} id={overlayId}>
            {overlayContent()}
          </OverlayPanel>
        </div>
      </React.Fragment>
    );
  }
}
HelpText.propTypes = {
  containerClass: PropTypes.string,
  overlayId: PropTypes.string,
  overlayContent: PropTypes.func,
  overlayClass: PropTypes.string,
  intl: PropTypes.object
};

export default connect()(injectIntl(HelpText));
