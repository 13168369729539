import { eCoverageType, eCOIType } from '../../core/configs';
import { calculateMWINumberFieldValue } from '../../core/utils';

//AGE CALCULATION

const age100 = 100;
const age85 = 85;

export const ageFromMinimum = (selectedClientAge) => {
  const minimumOneYearAge = 1;
  return +(selectedClientAge + minimumOneYearAge);
};

export const ageFromMaximum = (coverageType, equivalentAge, selectedClientAge) => {
  const maximumAge = age100;
  return coverageType === eCoverageType.single ? +maximumAge : +(maximumAge - equivalentAge + selectedClientAge);
};

export const ageToMinimum = (ageFromValue) => +ageFromValue;

export const ageToMaximum = (coverageType, equivalentAge, selectedClientAge) => {
  const maximumAge = age100;
  return coverageType === eCoverageType.single ? +maximumAge : +(maximumAge - equivalentAge + selectedClientAge);
};

export const ageToMaximumCoi = (coverageType, equivalentAge, selectedClientAge, coiType) => {
  const maximumAge = getMaxAgeByCOIType(coiType);
  return coverageType === eCoverageType.single ? +maximumAge : +(maximumAge - equivalentAge + selectedClientAge);
};

export const getMaxAgeByCOIType = (coiType) =>
  coiType === eCOIType.yrtToAge85 || coiType === eCOIType.yrt85 ? age85 : age100;
//YEAR CALCULATION

export const yearFromMinimum = () => {
  const minimumOneYearAge = 1;
  return +minimumOneYearAge;
};

export const YearFromMaximum = (duration) => +duration;

export const yearToMinimum = (yearFromValue) => +yearFromValue;

export const yearToMaximum = (duration) => +duration;

export const isLevelFor10or20 = (costOfInsurance) => {
  if(costOfInsurance === eCOIType.levelFor10Years || costOfInsurance === eCOIType.levelFor20Years){
    return true;
  } else {
    return false;
  }
};

export const getDefaultAgeToValue = (coverageType, equivalentAge, selectedClientAge, costOfInsurance) => {
  if (costOfInsurance === eCOIType.levelToAge100 || costOfInsurance === eCOIType.yrt100 || isLevelFor10or20(costOfInsurance)) {
    const maximumAge = age100;
    return coverageType === eCoverageType.single ? +maximumAge : +(maximumAge - equivalentAge + selectedClientAge);
  } else if (costOfInsurance === eCOIType.yrtToAge85 || costOfInsurance === eCOIType.yrt85) {
    const maximumAge = age85;
    return coverageType === eCoverageType.single ? +maximumAge : +(maximumAge - equivalentAge + selectedClientAge);
  } else {
    return 0;
  }
};

export const getDefaultYearToValue = (coverageType, equivalentAge, selectedClientAge, costOfInsurance) => {
  if (costOfInsurance === eCOIType.levelToAge100 || costOfInsurance === eCOIType.yrt100) {
    const maximumAge = age100;
    return coverageType === eCoverageType.single ? +(maximumAge - selectedClientAge) : +(maximumAge - equivalentAge);
  } else if (costOfInsurance === eCOIType.yrtToAge85 || costOfInsurance === eCOIType.yrt85) {
    const maximumAge = age85;
    return coverageType === eCoverageType.single ? +(maximumAge - selectedClientAge) : +(maximumAge - equivalentAge);
  } else {
    return 0;
  }
};

export const getAgeFromValue = (coverageType, equivalentAge, selectedClientAge, value) => {
  return calculateMWINumberFieldValue(
    value,
    ageFromMinimum(selectedClientAge),
    ageFromMaximum(coverageType, equivalentAge, selectedClientAge)
  );
};

export const getAgeToValue = (coverageType, equivalentAge, selectedClientAge, value, ageFromValue) => {
  return calculateMWINumberFieldValue(
    value,
    ageToMinimum(ageFromValue),
    ageToMaximum(coverageType, equivalentAge, selectedClientAge),
    true
  );
};

export const getAgeToValueBasedOnCoi = (
  coverageType,
  coverageCoi,
  equivalentAge,
  selectedClientAge,
  value,
  ageFromValue
) => {
  return calculateMWINumberFieldValue(
    value,
    ageToMinimum(ageFromValue),
    ageToMaximumCoi(coverageType, equivalentAge, selectedClientAge, coverageCoi),
    true
  );
};

export const getYearFromValue = (duration, value) =>
  calculateMWINumberFieldValue(value, yearFromMinimum(), YearFromMaximum(duration));

export const getYearToValue = (duration, value, yearFromValue) =>
  calculateMWINumberFieldValue(value, yearToMinimum(yearFromValue), yearToMaximum(duration), true);
