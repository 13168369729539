import React from 'react';
import { BREAKPOINTS } from '../../constants';
import styled from 'styled-components';
import './index.css';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, BUTTON_VARIANT, Modal, H2, P } from '@manulife/mux';

export const ButtonWrapper = styled.div`
display: flex;
@media screen and (max-width: ${BREAKPOINTS.MAX_768}px) { flex-direction: column; }
justify-content: space-evenly;
width: 100%;
ButtonWrapper.displayName = 'ButtonWrapper'
`;

const DeleteModal = (props) => {
  const { deleteMyCases, isModalOpen, modalClose, intl, title, message } = props;
  const labelCancel = intl.formatMessage({ id: 'common.cancel' });
  const labelDelete = intl.formatMessage({ id: 'common.delete' });

  return (
    <Modal
      role="delete-model"
      id="deleteModal"
      isOpen={isModalOpen}
      onClose={modalClose}
      customStyle={{
        modalStyle: {
          height: 'auto',
          width: '500px',
          media: [
            {
              maximum: BREAKPOINTS.PHONE_PORTRAIT_MED,
              width: '85%',
            },
          ],
        },
      }}
      ariaLabel="Timeout Modal"
      ariaLabelledBy="heading"
      ariaDescribedBy="content"
    >
      <div className="modalContentWrapper" role="modal-wrapper">
        <H2 id="heading" role="heading">
          {title}
        </H2>
        <P id="content" role="content">
          {message}
        </P>
        <ButtonWrapper>
          <div className="footer" role="footer">
            <Button id="cancelButton" variant={BUTTON_VARIANT.SECONDARY} onClick={modalClose} title="cancel-btn">
              {labelCancel}
            </Button>
            <Button id="deleteButton" onClick={deleteMyCases} title="delete-btn">
              {labelDelete}
            </Button>
          </div>
        </ButtonWrapper>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  intl: intlShape.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  modalClose: PropTypes.func.isRequired,
  deleteMyCases: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default DeleteModal;
