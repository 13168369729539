import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/components/button/Button';

import './mwi-select.css';

const MWISelect = ({
  labelL,
  labelR,
  styleClassL,
  styleClassR,
  onClickL,
  onClickR,
  selectedL,
  selectedR,
  ariaDescribedBy,
  containerStyle,
  disabled
}) => (
  <div role="radiogroup" className={`${containerStyle}`}>
    <Button
      label={labelL}
      tabIndex={0}
      className={`mwi-widget mwi-button mwi-select-btn mwi-select-optionL-btn ${
        selectedL ? 'mwi-selectedOption' : ''
      } ${styleClassL}`}
      onClick={onClickL}
      role="radio"
      aria-checked={selectedL}
      aria-describedby={ariaDescribedBy}
      disabled={disabled}
    />
    <Button
      label={labelR}
      tabIndex={0}
      className={`mwi-widget mwi-button mwi-select-btn mwi-select-optionR-btn ${
        selectedR ? 'mwi-selectedOption' : ''
      } ${styleClassR}`}
      onClick={onClickR}
      role="radio"
      aria-checked={selectedR}
      aria-describedby={ariaDescribedBy}
      disabled={disabled}
    />
  </div>
);

export default MWISelect;

MWISelect.propTypes = {
  labelL: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  labelR: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  selectedL: PropTypes.bool.isRequired,
  selectedR: PropTypes.bool.isRequired,
  onClickL: PropTypes.func,
  onClickR: PropTypes.func,
  styleClassL: PropTypes.string,
  styleClassR: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
  isRequired: PropTypes.bool,
  containerStyle: PropTypes.string,
  disabled: PropTypes.bool
};

MWISelect.defaultProps = {
  isRequired: true
};
